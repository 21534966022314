import TweenLite from 'gsap/TweenLite';

import View from '../../../../../shared/Core/View';

import './video-flash-animation.scss';

export default class VideoFlashAnimation extends View {

	initialize() {
		this.isPlaying = false;
	}

	destroy() {
	}

	reset() {
		this.isPlaying = false;
		TweenLite.set(this.el, {opacity: 1, clearProps: 'transform',});
	}

	play() {
		if (! this.isPlaying) {
			this.isPlaying = true;

			TweenLite.fromTo(this.el, 0.5, {opacity: 1, scale: 1, rotation: 0}, {
				scale: 1.5,
				opacity: 0,
				rotation: (Math.random() - 0.5) * 10,
				ease: Sine.easeOut,
				onComplete: this.onPlayDone,
				onCompleteScope: this,
			});
		}
	}

	onPlayDone() {
		this.isPlaying = false;
	}
}

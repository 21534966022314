import hasClipPathSupport from './hasClipPathSupport.js';
import detectTouch from './detectTouch.js';

export default function (callback) {
	const root = document.documentElement;

	if (hasClipPathSupport()) {
		root.classList.add('has-clip-paths');
	}

	// Async detection
	detectTouch(root);

	// This shouldn't be necessary, but it is
	const isIE11 = ! ! window.MSInputMethodContext && ! ! document.documentMode;
	if (isIE11) {
		root.classList.add('is-ie11');
	}

	callback();
}

import View from 'Core/View.js';
import './account-navigation.scss';

export default class AccountNavigation extends View {

	initialize() {

	}

	destroy() {

	}

}
import View from '../../../shared/Core/View';
import {addZeros} from '../../../shared/utils/Formatting';
import './countdown.scss';
import CanvasConfetti from '../SubscriptionConfetti/CanvasConfetti';

export default class Countdown extends View {

	initialize() {
		this.startTime = parseInt(this.el.dataset.current) * 1000;
		this.targetTime =  parseInt(this.el.dataset.target) * 1000;
		this.expiredMillisecondsSinceLoad = 0;

		this.canvas = this.find('canvas');
		this.canvasConfetti = new CanvasConfetti(this.canvas, [
			'#f00',
			'#0066cc',
			'#ffff00',
			'#009933',
			'#ff9933',
		]);

		this.daysEl = this.find('.countdown__unit--days span');
		this.hoursEl = this.find('.countdown__unit--hours span');
		this.minutesEl = this.find('.countdown__unit--minutes span');
		this.secondsEl = this.find('.countdown__unit--seconds span');

		this.onTick = this.onTick.bind(this);
		this.resize = this.resize.bind(this);

		window.addEventListener('resize', this.resize);
		this.resize();

		this.tickInterval = setInterval(this.onTick, 1000);
	}

	onTick() {
		this.expiredMillisecondsSinceLoad += 1000;

		const currentTime = this.startTime + this.expiredMillisecondsSinceLoad;
		const timeDifference = Math.max(this.targetTime - currentTime, 0);

		const MS_DAYS = (24 * 3600 * 1000);
		const MS_HOURS = (3600 * 1000);
		const MS_MINUTES = (60 * 1000);
		const MS_SECONDS = 1000;

		const days = Math.floor(timeDifference / MS_DAYS);
		const hours = Math.floor((timeDifference - (days * MS_DAYS)) / MS_HOURS);
		const minutes = Math.floor((timeDifference - (days * MS_DAYS) - (hours * MS_HOURS)) / MS_MINUTES);
		const seconds = Math.floor((timeDifference - (days * MS_DAYS) - (hours * MS_HOURS) - (minutes * MS_MINUTES)) / MS_SECONDS);

		this.daysEl.innerHTML = addZeros(days);
		this.hoursEl.innerHTML = addZeros(hours);
		this.minutesEl.innerHTML = addZeros(minutes);
		this.secondsEl.innerHTML = addZeros(seconds);

		if(timeDifference <= 0) {

			this.canvasConfetti.releaseConfetti();

			clearInterval(this.tickInterval);
			this.startConfetti();
		}
	}

	startConfetti() {
		this.addConfettiInterval = setInterval(() => {
			this.canvasConfetti.releaseConfetti();
		}, 100);
	}

	resize() {
		this.canvas.width = this.el.clientWidth;
		this.canvas.height =  this.el.clientHeight;
		this.canvas.style.width =  this.el.clientWidth + 'px';
		this.canvas.style.height =  this.el.clientHeight + 'px';
	}

	destroy() {
		window.removeEventListener('resize', this.resize);

		clearInterval(this.tickInterval);
		clearInterval(this.addConfettiInterval);

		this.canvasConfetti.destroy();
		this.canvasConfetti = null;

		this.el.removeEventListener('change', this.handleChange);
	}
}

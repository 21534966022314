import Signal from 'signals';

export default {

	/**
	 * The pushState has changed, we will start loading in this content.
	 *
	 * @param {Object} route
	 * @param {{
	 *     excludeFromHistory: boolean,
	 *     preventLoading: boolean,
	 * }} options
	 */
	requested: new Signal(),

	/**
	 * Invoked when a url was requested, but instead we were redirect to another url
	 *
	 * @param {Object} route
	 */
	redirected: new Signal(),

	/**
	 * The request was not processed. Probably because the PageLoadService thought it
	 * wouldn't be necessary to navigate. Go complain to it.
	 *
	 * @param {Object} route
	 */
	requestIgnored: new Signal(),

	/**
	 * The url (pushState) has changed, we will start loading in this content.
	 *
	 * @param {Object} route
	 */
	started: new Signal(),

	/**
	 * We tried to load in new content, but it failed.
	 *
	 * @param {Element} content
	 * @param {Number} loadDuration
	 * @param {Object} route
	 * @param {String} error
	 */
	failed: new Signal(),

	/**
	 * The load was canceled
	 *
	 * @param {Number} loadDuration
	 * @param {Object} route
	 */
	canceled: new Signal(),

	/**
	 * The load has completed, but we're waiting for the content to get updated
	 *
	 * @param {Element} content
	 * @param {Number} loadDuration
	 * @param {Object} route
	 */
	completed: new Signal(),

	/**
	 * If the content contains any scripts, we add listeners to it to load
	 *
	 * @param {string} src
	 * @param {string} id
	 * @param {Object} route
	 */
	scriptLoaded: new Signal(),
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './store-search-box-erase.scss';
import SVGIcon from '../../../../../../reactComponents/SVGIcon/SVGIcon';

const StoreSearchBoxErase = (props) => {
	const classes = classNames({
		'store-search-box-erase': true,
		'store-search-box-erase--disabled': props.disabled,
		[props.className]: ! ! props.className,
	});

	return (
		<a className={classes}
			disabled={props.disabled}
			href="#"
			onClick={props.onClick}>
			<SVGIcon name="close" className="store-search-box-erase__icon"/>
		</a>
	);
};

StoreSearchBoxErase.propTypes = {
	disabled: PropTypes.bool,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default StoreSearchBoxErase;
import View from 'Core/View.js';
import ZoneField from 'components/ZoneField/ZoneField.js';

import NLPostCodeCheck from './NLPostCodeCheck';

export default class AddressForm extends View {

	initialize(options) {

		const zoneFieldEl = this.find('.address-form__zones');
		if (zoneFieldEl) {
			this.setupZones(options, zoneFieldEl);
		}

		const postalCheckURL = this.el.dataset.postalUrl;
		const prefix = this.el.dataset.prefix || '';

		if (postalCheckURL) {
			this.nlPostalCheck = new NLPostCodeCheck({
				...options,
				prefix: prefix,
				url: postalCheckURL,
			})
		}
	}

	destroy() {
		if (this.zoneFieldForCountry) {
			this.zoneFieldForCountry.destroy();
		}

		if (this.nlPostalCheck) {
			this.nlPostalCheck.destroy();
		}
	}

	setupZones(options, zoneFieldEl) {
		const countrySelect = this.find('.address-form__country-select');
		let fixedCountryId = undefined;

		// Check if we have a static country_id instead
		if (! countrySelect) {
			fixedCountryId = this.find('.address-form__country-field').value;
		}

		this.zoneFieldForCountry = new ZoneField({
			...options,
			el: zoneFieldEl,
			fixedCountryId: fixedCountryId,
			countrySelect: countrySelect
		});
	}
}
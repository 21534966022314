import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SVGIcon from '../../../../../SVGIcon/SVGIcon';

import './map-control-icon.scss';

function MapControlIcon(props) {

	const handleClick = (event) => {
		event.preventDefault();

		if (props.onClick && ! props.disabled) {
			props.onClick();
		}
	};

	const classes = classNames({
		'map-control-icon': true,
		'map-control-icon--disabled': props.disabled,
		'map-control-icon--spinning': props.spinIcon,
	});

	return (
		<a className={classes} onClick={handleClick} href="#">
			<SVGIcon name={props.icon} className="map-control-icon__icon"/>
		</a>
	);
}

MapControlIcon.propTypes = {
	onClick: PropTypes.func,
	icon: PropTypes.string,
	disabled: PropTypes.bool,
	spinIcon: PropTypes.bool,
};

MapControlIcon.defaultProps = {
	icon: 'plus',
};

export default MapControlIcon;

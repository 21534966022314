import View from 'Core/View.js';
import './toaster.scss';

let toasterCount = 0;

export default class Toaster extends View {

	initialize(options) {
		const defaults = {
			message: 'No message set',
			timeout: 3,
			container: document.body,
		};
		options = {...defaults, ...options};

		if (! options.signals) {
			throw new Error('You forgot to pass the `signals` object to the constructor of the Toaster.');
		}

		this.container = options.container;
		this.id = ++ toasterCount;

		this.handleClick = this.handleClick.bind(this);
		this.hide = this.hide.bind(this);
		this.signals.toaster.created.dispatch(this.id, this);

		// Create the toaster div
		this.el = document.createElement('div');
		this.el.className = 'toaster';
		this.el.innerHTML = options.message;
		this.el.addEventListener('click', this.handleClick);

		// Auto show
		this._show();
		this.timeoutId = setTimeout(this.hide, options.timeout * 1000);
	}

	destroy() {
		clearTimeout(this.timeoutId);
		this.el.removeEventListener('click', this.handleClick);
	}

	/**
	 * Don't call this directly, it is called from the constructor
	 *
	 * @private
	 */
	_show() {
		this.container.appendChild(this.el);

		TweenLite.fromTo(this.el, 0.3, {scale: 0, transformOrigin: 'bottom right'}, {scale: 1, ease: Back.easeOut});
	}

	hide() {
		TweenLite.to(this.el, 0.2, {
			scale: 0, onComplete: () => {
				this.container.removeChild(this.el);
				this.signals.toaster.removed.dispatch(this.id);
				this.destroy();
			}
		});
	}

	handleClick(event) {
		event.preventDefault();
		this.hide();
	}
}
import View from 'Core/View.js';

export default class ReframedSocialShare extends View {

	initialize() {
		this.facebook = this.find('.reframed__story-detail__share-option--facebook');
		this.twitter = this.find('.reframed__story-detail__share-option--twitter');
		this.whatsapp = this.find('.reframed__story-detail__share-option--whatsapp');

		this.onFacebookClicked = this.onFacebookClicked.bind(this);
		this.onTwitterClicked = this.onTwitterClicked.bind(this);
		this.onWhatsappClicked = this.onWhatsappClicked.bind(this);

		this.facebook.addEventListener('click', this.onFacebookClicked);
		this.twitter.addEventListener('click', this.onTwitterClicked);
		this.whatsapp.addEventListener('click', this.onWhatsappClicked);
	}

	destroy() {
		this.facebook.removeEventListener('click', this.onFacebookClicked);
		this.twitter.removeEventListener('click', this.onTwitterClicked);
		this.whatsapp.removeEventListener('click', this.onWhatsappClicked);
	}

	onFacebookClicked(event) {
		event.preventDefault();
		event.stopPropagation();

		const url = this.getUrl();
		const targetURL = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url);
		window.open(targetURL, '', 'height=269,width=550,resizable=1');

		this.signals.social.shareRequested.dispatch('Facebook', url);
	}

	onTwitterClicked(event) {
		event.preventDefault();

		const url = this.getUrl();

		const targetURL = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(url);
		window.open(targetURL, '', 'height=285,width=550,resizable=1');

		this.signals.social.shareRequested.dispatch('Twitter', url);
	}

	onWhatsappClicked() {
		this.signals.social.shareRequested.dispatch('Whatsapp', this.getUrl());
	}

	getUrl() {
		return location.toString();
	}
}

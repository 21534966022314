import DefaultPage from '../DefaultPage/DefaultPage.js';

import './assortment-overview.scss';

export default class AssortmentOverview extends DefaultPage {

	initialize() {
		super.initialize();

		this.items = this.findAll('.assortment-overview__category');
		this.columns = this.findAll('.assortment-overview__column');

		this.resize = this.resize.bind(this);
		this.signals.windowResized.add(this.resize);

		this.resize();
	}

	destroy() {
		super.destroy();

		this.signals.windowResized.remove(this.resize);
	}

	resize() {

		// Calculate visible columns based on available width of screen
		const aw = window.innerWidth;
		const columns = aw < 1024 ? (aw < 768 ? 1 : 2) : 3;

		// Toggle column visibility
		this.columns.forEach((column, index) => {
			if (index + 1 > columns) {
				column.classList.add('assortment-overview__column--hidden');
			} else {
				column.classList.remove('assortment-overview__column--hidden');
			}
		});

		// Distribute items over visible columns
		this.items.forEach((item, index) => {
			this.columns[index % columns].appendChild(item);
		});
	}

}
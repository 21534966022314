import View from 'Core/View.js';
import './highlight.scss';

let highlightCount = 0;
const sliderVersions = 4;

export default class HighLight extends View {
	initialize() {
		const version = highlightCount++ % sliderVersions;

		this.el.classList.add(`highlight--version-${version}`);
	}

	destroy() {

	}
}

import Signal from 'signals';

const barSignals = {

	/**
	 * @param String variantName
	 */
	preloadCompleted: new Signal(),

	/**
	 * @param String variantName
	 */
	preloadFailed: new Signal(),

	allPreloadsCompleted: new Signal(),
};

export default barSignals;

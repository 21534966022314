import DefaultPage from '../DefaultPage/DefaultPage.js';
import './verified.scss';

export default class Verified extends DefaultPage {

	initialize() {
		super.initialize();
	}

	destroy() {
		super.destroy();
	}
}

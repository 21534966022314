import TweenLite from 'gsap/TweenLite';
import Signal from 'signals';
import {Circ} from 'gsap';

import View from '../../../shared/Core/View';
import ScrollService from '../../../services/ScrollService';
import formDataToObject from '../../../shared/utils/form/formDataToObject';
import filterEmptyDuplicates from '../../../shared/utils/form/filterEmptyDuplicates';

export default class ProgressionForm extends View {

	initialize(options) {
		this.modifyDataBeforeSend = this.modifyDataBeforeSend.bind(this);
		this._handleFormChanged = this._handleFormChanged.bind(this);
		this.forceUpdate = this.forceUpdate.bind(this);

		this.signals.forms.beforeSubmit.add(this.modifyDataBeforeSend);
		this.updatedForced = new Signal();
		this.killScrollTween = null;

		this.focusChangedToInputTime = new Date();

		window.addEventListener('focusin', (event) => {
			const tagName = event.target.tagName.toLowerCase();
			if (tagName === 'input' || tagName === 'select' || tagName === 'textarea') {
				this.focusChangedToInputTime = new Date();
			}
		});

		const makeChildComponent = (Component, selector, visibleClosure) => {
			const el = this.find(selector);
			if (! el) {
				return;
			}

			return {
				component: new Component({...options, el: el, forceUpdate: this.forceUpdate}),
				visible: visibleClosure,
			};
		};

		this.sections = Object.keys(options.sections)
			.map(selector => makeChildComponent(options.sections[selector], selector))
			.filter(c => !! c);

		this.lastSection = null;

		this.form = this.find('form');
		this.form.addEventListener('change', this._handleFormChanged);
		this.form.reset();

		this.state = {};
	}

	destroy() {
		this.sections.map(child => child.component.destroy());

		this.form.removeEventListener('change', this._handleFormChanged);
	}

	forceUpdate() {
		const previousState = this.state;
		this.state = this._formToData();

		if (JSON.stringify(previousState) === JSON.stringify(this.state)) {
			return;
		}

		setTimeout(() => {
			const relevantSection = this._updateComponents(this.state, previousState);
			if (relevantSection) {
				this.scrollToSection(relevantSection);
			}

			this.updatedForced.dispatch(this.state);
		}, 1);
	}

	scrollToSection(section) {
		if (this.lastSection === section) {
			return;
		}

		const focusChangedTimeAgo = (new Date()).getTime() - this.focusChangedToInputTime.getTime();
		if (focusChangedTimeAgo < 10) {
			console.info('[TONYS] Canceled the scroll since it would probably block the focus change');
			return;
		}

		this.lastSection = section;
		this.killScrollTween = ScrollService.scrollPageToElement(section, 180);
	}

	/**
	 * @param {string} formId
	 * @param {FormData} formData
	 */
	modifyDataBeforeSend(formId, formData) {
		if (formId === this.form.id) {

			Object.keys(this.state).forEach(key => {
				formData.append(key, this.state[key]);
			});

			filterEmptyDuplicates(formData);
		}
	}

	_formToData() {
		return formDataToObject(new FormData(this.form));
	}

	_updateComponents(state, previousState) {
		let skipped = false;
		let lastSection = null;
		for (const index in this.sections) {
			const child = this.sections[index];
			if (! skipped && ! child.component.visible(state)) {
				skipped = true;
			}

			if (skipped) {
				child.component.el.style.display = 'none';
			} else {

				if (child.component.el.style.display !== 'block') {
					child.component.el.style.opacity = '0';
					child.component.el.style.display = 'block';

					TweenLite.fromTo(child.component.el, 0.3, {
						y: '50',
					}, {
						y: 0,
						delay: 0.2,
						opacity: 1,
						ease: Circ.easeOut,
					});
				}

				child.component.update({...state}, previousState);

				if (typeof child.component.targetable === 'undefined' || child.component.targetable()) {
					lastSection = child.component.el;
				}
			}
		}

		return lastSection;
	}

	_handleFormChanged() {
		this.forceUpdate();
	}
}

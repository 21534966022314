import View from '../../../shared/Core/View';

import findAncestorByClass from '../../../shared/utils/dom/findAncestorByClass';
import './custom-payment-methods.scss';

export default class CustomPaymentMethods extends View {

	initialize() {
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.deselectAll = this.deselectAll.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		const dropInEl = this.find('.adyen-drop-in', document);
		this.adyenDropIn = this.findViewForElement(dropInEl);

		if (this.adyenDropIn) {
			this.adyenDropIn.methodSelected.add(this.deselectAll);
		}

		this.submitEls = this.findAll('.custom-payment-methods__submit');
		this.inputEls = this.findAll('.custom-payment-methods__input');

		this.inputEls.forEach(inputEl => inputEl.addEventListener('change', this.handleInputChange));
		this.submitEls.forEach(submitEl => submitEl.addEventListener('click', this.handleClick));

		this.el.addEventListener('submit', this.handleSubmit);
	}

	destroy() {
		this.inputEls.forEach(inputEl => inputEl.removeEventListener('change', this.handleInputChange));
		this.submitEls.forEach(submitEl => submitEl.removeEventListener('click', this.handleClick));

		if (this.adyenDropIn) {
			this.adyenDropIn.methodSelected.remove(this.deselectAll);
		}

		this.el.removeEventListener('submit', this.handleSubmit);
	}

	handleInputChange(event) {
		this.hideAllSubmits();

		const option = findAncestorByClass(event.currentTarget, 'custom-payment-methods__option');
		const submitButton = option.querySelector('.custom-payment-methods__submit');
		if (submitButton) {
			submitButton.style.display = 'block';
		}

		if (! this.adyenDropIn) {
			return;
		}

		this.adyenDropIn.deselectPaymentMethod();
	}

	handleClick(event) {
		if (this.adyenDropIn && this.adyenDropIn.dropIn) {
			this.adyenDropIn.dropIn.setStatus('loading');
		}
	}

	handleSubmit() {
		this.signals.ecommerce.checkoutSubmitPaymentMethod.dispatch();
	}

	deselectAll() {
		this.inputEls.forEach(inputEl => inputEl.checked = false);
		this.hideAllSubmits();
	}

	hideAllSubmits() {
		this.submitEls.forEach(submitEl => submitEl.style.display = 'none');
	}
}

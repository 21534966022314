/**
 * Created by hugo on 13/07/17.
 */
import Signal from 'signals';

import pdfIcon from './pdf.svg';

export default class ContactFormImage {

	constructor(parentElement, file) {
		this.imageData = null;
		this.parentElement = parentElement;
		this.file = file;
		this.removedSignal = new Signal();

		this.render();
		this.load();
	}

	render() {
		this.el = document.createElement('div');
		const close = document.createElement('div');

		this.el.classList.add('contact-form__preview-image');

		close.classList.add('contact-form__preview-image-close-button');
		this.el.appendChild(close);

		close.addEventListener('click', this.remove.bind(this));

		this.parentElement.appendChild(this.el);
	}

	load() {
		this.fileReader = new FileReader();
		this.fileReader.onload = this.loaded.bind(this);
		this.fileReader.readAsDataURL(this.file);
	}

	loaded(event) {
		this.imageData = event.target.result;

		if (this.file.type === 'application/pdf') {
			this.setBackgroundImage(pdfIcon);
			return;
		}

		this.setBackgroundImage(event.target.result);
	}

	setBackgroundImage(url) {
		this.el.style['background-image'] = 'url("' + url + '")';
	}

	remove() {
		this.removedSignal.dispatch(this);
	}

	destroy() {

		this.parentElement.removeChild(this.el);

		if (this.fileReader) {
			this.fileReader.onload = function() { }
		}
	}

}

import View from '../../../shared/Core/View';

import './collapse.scss';


export default class Collapse extends View {

    initialize() {
		this.handleTriggerClick = this.handleTriggerClick.bind(this);

		this.content = this.find('.collapse__content');
		this.trigger = this.find('.collapse__trigger');
		this.trigger.addEventListener('click', this.handleTriggerClick)
    }

    destroy() {
		this.trigger.removeEventListener('click', this.handleTriggerClick)
    }

    collapse() {
		this.el.classList.add('collapse--collapsed');

		TweenLite.to(this.content, 0.2, {height: 0, ease: Sine.easeOut});
	}

	expand() {
		this.el.classList.remove('collapse--collapsed');

		TweenLite.set(this.content, {height: 'auto'});
		TweenLite.from(this.content, 0.2, {height: '0', ease: Sine.easeOut});
	}

	handleTriggerClick(event) {
    	event.preventDefault();

    	if (this.isCollapsed()) {
    		this.expand();
		} else {
    		this.collapse();
		}
	}

	isCollapsed() {
    	return this.el.classList.contains('collapse--collapsed');
	}
}

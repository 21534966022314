import PropTypes from 'prop-types';

import LatLng from '../../../../shared/shapes/LatLng';

export default PropTypes.shape({
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	gps: LatLng.isRequired,
	address: PropTypes.string,
	address_two: PropTypes.string,
	zipcode: PropTypes.string,
	city: PropTypes.string,
	state: PropTypes.string,
	country: PropTypes.string,
	phone: PropTypes.string,
	hours: PropTypes.string,
	formattedDistance: PropTypes.string,
	distance: PropTypes.number,
});

import View from 'Core/View';

import ContactFormPhotoUpload from './ContactFormPhotoUpload';

import './contact-form.scss';

export default class ContactForm extends View {

	initialize(options) {
		this.beforeSubmit = this.beforeSubmit.bind(this);
		this.onFormSubmitSucceeded = this.onFormSubmitSucceeded.bind(this);
		this.onOptionChanged = this.onOptionChanged.bind(this);
		this.onCategoryChanged = this.onCategoryChanged.bind(this);
		this.handlePhotosChanged = this.handlePhotosChanged.bind(this);

		this.formId = this.el.id;
		this.contactOptionSelect = this.find('.contact-form__type-select');
		this.categorySelects = this.findAll('.contact-form__category-select');
		this.complaintTexts = this.findAll('.contact-form__complaint-texts');

		this.photoUploads = this.findAll('.contact-form__photo-upload').map(el => {
			return new ContactFormPhotoUpload({...options, el: el, onChange: this.handlePhotosChanged});
		});
		this.lastUpdatedPhotos = null;

		this.signals.forms.beforeSubmit.add(this.beforeSubmit);
		this.signals.forms.succeeded.add(this.onFormSubmitSucceeded);
		this.contactOptionSelect.addEventListener('change', this.onOptionChanged);
		this.categorySelects.forEach(el => el.addEventListener('change', this.onCategoryChanged));

		this.preselectContactTypeIfHashIsSet();
	}

	destroy() {
		this.signals.forms.beforeSubmit.remove(this.beforeSubmit);
		this.signals.forms.succeeded.remove(this.onFormSubmitSucceeded);

		this.categorySelects.forEach(el => el.removeEventListener('change', this.onCategoryChanged));
		this.contactOptionSelect.removeEventListener('change', this.onOptionChanged);

		this.photoUploads.forEach(p => p.destroy());
	}

	preselectContactTypeIfHashIsSet() {
		const route = this.routes.getRoute();
		if (route.hash) {
			this.selectContactType(route.hash);
			return;
		}

		this.showSectionsFor(this.el.dataset.defaultType || '');
	}

	selectContactType(type) {
		const options = this.contactOptionSelect.querySelectorAll('option');
		options.forEach((option) => {
			option.selected = option.value.toLowerCase() === type.toLowerCase();
		});

		this.onOptionChanged();
	}

	beforeSubmit(formId, formData) {
		if (formId !== this.formId) {
			return;
		}

		if (this.lastUpdatedPhotos) {
			this.lastUpdatedPhotos.forEach((previewImage, index) => {
				formData.append('images[' + index + ']', previewImage.imageData);
			});

			formData.append('imageCount', this.lastUpdatedPhotos.length);
		}

		formData.delete('files[]');
	}

	onFormSubmitSucceeded(formId) {
		if (formId === this.formId) {
			this.el.reset();
			this.resetPhotoUploads();
		}
	}

	resetPhotoUploads() {
		this.photoUploads.forEach(pu => pu.reset());
	}

	onOptionChanged() {
		this.resetPhotoUploads();

		const contactType = this.contactOptionSelect.value;
		this.showSectionsFor(contactType);
	}

	onCategoryChanged() {
		this.onOptionChanged();
	}

	showSectionsFor(contactType) {
		const categoryInput = this.find(`[name="Category[${contactType}]"]`);
		if (! categoryInput) {
			this.toggleFieldsFor('');
			return;
		}

		if (categoryInput.tagName.toLowerCase() === 'input') {
			this.toggleFieldsFor(contactType);
			return;
		}

		const currentOption = categoryInput.options[categoryInput.selectedIndex];
		this.toggleFieldsFor(contactType, categoryInput.value);
		this.toggleComplaintTexts(currentOption && currentOption.dataset.showComplaintTexts === 'true');
	}

	toggleFieldsFor(contactType, category = 'NoCategory') {
		this.findAll('.contact-form__show-for-now').forEach(el => el.classList.remove('contact-form__show-for-now'));

		this.relevantSections = this.findAll(`.contact-form__show-for--${contactType}-${category},.contact-form__show-for--${contactType}`);
		this.relevantSections.forEach(el => el.classList.add('contact-form__show-for-now'));
	}

	toggleComplaintTexts(shouldShow) {
		this.complaintTexts.forEach(el => {
			if (shouldShow) {
				el.classList.add('contact-form__show-for-now');
			} else {
				el.classList.remove('contact-form__show-for-now');
			}
		})
	}

	handlePhotosChanged(photos) {
		this.lastUpdatedPhotos = photos;
	}
}

import DefaultPage from '../DefaultPage/DefaultPage.js';
import CartModel from '../../../shared/data/CartModel';

import './cart.scss';

export default class Cart extends DefaultPage {

	initialize() {
		super.initialize();
	}

	show(widgetsOnly = false) {
		super.show(widgetsOnly);

		this.signals.ecommerce.cartViewed.dispatch(CartModel.getProducts());
	}

	destroy() {
		super.destroy();
	}
}

import DefaultPage from '../DefaultPage/DefaultPage.js';
import TweenLite from 'gsap/TweenLite';
import './shop.scss';
import './shop-overview.scss';

const DEFAULT_SHOPPAGE_VERTICAL_DISPLACEMENT_WIDGET = 100;

export default class Shop extends DefaultPage {

	initialize() {

		// Selector for what should be animated when navigating
		this.animatablesSelector = '.shop-product-tile, .shop-content-tile, .widget-area-wrapper, h2, .shop-templates__filters';

		// Selectors for containers which should by dynamically replaces
		this.replaceableContainers = [
			'.shop-products',
			'.shop-categories',
			'.breadcrumbs',
			'.shop-overview__dynamic-top',
			'.shop-overview__dynamic-bottom',
			'.shop-related-products',
			'.shop-links',
			'h2',
		];

		this.loadableContainers = '.shop-category-wrapper';

		this.productArea = this.find('.shop-products');

		// Hijack page load when using filters/search
		this.onPageLoadStarted = this.onPageLoadStarted.bind(this);
		this.onPageLoadCompleted = this.onPageLoadCompleted.bind(this);

		// Signals have priority over Container.js
		this.signals.pageLoad.started.add(this.onPageLoadStarted, this, 2);
		this.signals.pageLoad.completed.add(this.onPageLoadCompleted, this, 2);

		// Fade out background animation element
		this.signals.backgroundAnimation.hide.dispatch();

		super.initialize();
	}

	destroy() {
		super.destroy();

		// Fade in background animation element
		this.signals.backgroundAnimation.show.dispatch();

		this.signals.pageLoad.started.remove(this.onPageLoadStarted, this);
		this.signals.pageLoad.completed.remove(this.onPageLoadCompleted, this);
	}

	show() {
		super.show();
		this.showTiles();
	}

	showTiles() {
		const tiles = this.findAll(this.animatablesSelector);

		tiles.forEach((tile) => {
			TweenLite.killTweensOf(tile);
			TweenLite.set(tile, {
				opacity: 0,
				y: DEFAULT_SHOPPAGE_VERTICAL_DISPLACEMENT_WIDGET,
				rotation: -10 + Math.random() * 20
			});
		});

		tiles.forEach((tile, index) => {
			TweenLite.to(tile, 0.3, {
				opacity: 1, y: 0, force3D: true, delay: index * 0.06, rotation: 0, ease: Cubic.easeOut
			});
		});

		//
		const listName = window.location.pathname; // Use pathname as identifier
		this.signals.ecommerce.productListPageViewed.dispatch(listName, listName, tiles.map((tile, index) => {
			return {id: tile.dataset.sku,
					price: tile.dataset.price,
					priceOriginal: tile.dataset.priceOriginal,
					name: tile.dataset.name,
					position: index,
					category:tile.dataset.category || null}
		}));
	}

	hide() {
		super.hide();
		this.hideTiles();
	}

	hideTiles() {
		const tiles = this.findAll(this.animatablesSelector);

		tiles.forEach((tile) => {
			TweenLite.killTweensOf(tile);
			TweenLite.to(tile, 0.3, {
				opacity: 0,
				y: DEFAULT_SHOPPAGE_VERTICAL_DISPLACEMENT_WIDGET,
				force3D: true,
				delay: 0,
				rotation: 0,
				ease: Cubic.easeOut
			});
		});
	}


	replaceContents(htmlNode) {
		this.replaceableContainers.forEach((selector) => {
			const currentEl = document.querySelector(selector);
			const newEl = htmlNode.querySelector(selector);

			if (currentEl) {
				currentEl.innerHTML = newEl ? newEl.innerHTML : '';
			}
		});

		// Replace page classes
		document.querySelector('.page').className = htmlNode.querySelector('.page').className;
	}

	onPageLoadStarted(nextRoute) {
		const hijack = this.hijackPageLoad(nextRoute);

		if (hijack) {
			this.hideTiles();
		}

		return ! hijack;
	}

	onPageLoadCompleted(htmlNode, loadTime, route) {

		if (this.hijackPageLoad(route)) {

			this.replaceContents(htmlNode);

			this.signals.pageContentReplaced.dispatch(this.el, route, false);

			this.showTiles();

			this.scrollToProductArea();

			return false;
		}
	}

	scrollToProductArea() {
		if(this.productArea) {
			//ScrollService.scrollPageToElement(this.productArea, window.innerWidth < 768 ? 64 : 0);
		}
	}

	hijackPageLoad(route) {
		const routesToHijack = [
			'shop.index',
			'shop.category',
			'shop.search',
		];

		return routesToHijack.indexOf(route.name) > -1 && route.isExactMatch;
	}
}

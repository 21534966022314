import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SVGIcon from '../../../../../../reactComponents/SVGIcon/SVGIcon';
import LatLng from '../../../../../../../shared/shapes/LatLng';

import StreetViewImage from './components/StreetViewImage/StreetViewImage';
import StreetViewInteractive from './components/StreetViewInteractive/StreetViewInteractive';

import './street-view.scss';

class StreetView extends Component {

	constructor(props) {
		super(props);

		this.handleEnlargeToggled = this.handleEnlargeToggled.bind(this);
	}

	render() {
		const classes = classNames({
			'street-view': true,
			'street-view--enlarged': this.props.enlarged,
		});

		return (
			<div className={classes}>
				{this.renderSizer()}

				<div className="street-view__imagery">
					{this.renderImageOrInteractive()}
				</div>

				<a href="#" className="street-view__enlarge" onClick={this.handleEnlargeToggled}>
					<SVGIcon className="street-view__enlarge__icon"
						name={this.props.enlarged ? 'exit-fullscreen' : 'fullscreen'}/>
				</a>
			</div>
		);
	}

	renderSizer() {
		if (this.isNonMobileAndEnlarged()) {
			return null;
		}

		const multiplier = this.props.enlarged ? 200 : 100;
		const style = {
			paddingTop: (this.props.heightRatio * multiplier).toString() + '%',
		};

		return (
			<div className="street-view__sizer" style={style}/>
		);
	}

	renderImageOrInteractive() {
		const multiplier = this.props.enlarged ? 2 : 1;

		if (this.props.enlarged) {
			return (
				<StreetViewInteractive panoramaId={this.props.panoramaId}
					fullScreen={this.isNonMobileAndEnlarged()}
					heightRatio={this.props.heightRatio * multiplier}/>
			);
		}

		return (
			<StreetViewImage address={this.props.address} apiKey={this.props.apiKey}
				heightRatio={this.props.heightRatio * multiplier}/>
		);
	}

	handleEnlargeToggled(event) {
		event.preventDefault();

		if (this.props.onEnlargeToggle) {
			this.props.onEnlargeToggle(! this.props.enlarged);
		}
	}

	isNonMobileAndEnlarged() {
		return this.props.enlarged && ! this.props.isMobile;
	}
}

StreetView.propTypes = {
	address: PropTypes.string.isRequired,
	latLng: LatLng.isRequired,
	panoramaId: PropTypes.string.isRequired,
	heightRatio: PropTypes.number.isRequired,
	apiKey: PropTypes.string,
	enlarged: PropTypes.bool,
	onEnlargeToggle: PropTypes.func,
	isMobile: PropTypes.bool,
};

StreetView.defaultProps = {
	heightRatio: 0.5,
};

export default StreetView;
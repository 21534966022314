import BaseSplashView from './BaseSplash';

import {TimelineMax, Power0, Power2, TweenLite, Power4, Back} from 'gsap/all';
import 'gsap/AttrPlugin';
import '../../../../../../../vendors/gsap/MorphSVGPlugin';

export default class Splash_19_3 extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.setupUI();
		this.setupTimeline();

	}

	setupUI() {

		this.ui = {
			background: this.el.querySelectorAll('.js-background'),
			titleLines: this.el.querySelectorAll('.js-title-line'),
			bodyLines: this.el.querySelectorAll('.js-body-line'),
			foldShapes: [...this.el.querySelectorAll('.js-fold-shapes')],
			foldBgs: [...this.el.querySelectorAll('.js-fold-bgs')],
			shadows: [...this.el.querySelectorAll('.js-shadow-lines')],
			guyLeft: this.el.querySelector('.js-guy-left'),
			guyRight: this.el.querySelector('.js-guy-right'),
			pageMasks: [...this.el.querySelectorAll('.js-page-mask')],
			guyLeftHead: this.el.querySelector('.js-guy-left-head'),
			guyLeftArm: this.el.querySelector('.js-guy-left-arm'),
			guyLeftLegg: this.el.querySelector('.js-guy-left-legg'),
			guyRightHead: this.el.querySelector('.js-guy-right-head'),
			guyRightWaisHand: this.el.querySelector('.js-guy-right-waist-hand'),
		}

	}

	update(options) {

		TweenLite.to(this.tl, options.duraton, {progress: options.progress});

	}

	setupTimeline() {

		this.tl = new TimelineMax({paused: true});

		const time = 0;
		this.tl.staggerFrom(this.ui.titleLines, .6, {y: -150, ease: Power2.easeOut}, 0.02, time);
		this.tl.from([this.ui.background, this.ui.foldShapes[0], this.ui.titleLines], .03, {opacity: 0, ease: Power0.easeNone}, time);
		this.tl.from(this.ui.background, .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.pageMasks[0], .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.foldShapes[0], .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.shadows[0], .5, {y: -20, opacity: 0, ease: Power2.easeOut}, 0.5);
		this.tl.from(this.ui.guyLeft, .1, {opacity: 0, ease: Power0.easeNone}, time);
		this.tl.from(this.ui.guyRight, .1, {opacity: 0, ease: Power0.easeNone}, time);

		this.ui.foldBgs.forEach((el, index) => this.tl.to(this.ui.foldBgs[0], .4, {morphSVG: el}, time + index * .4));;
		this.ui.pageMasks.forEach((el, index) => this.tl.to(this.ui.pageMasks[0], .4, {morphSVG: el}, time + index * .4));
		this.ui.foldShapes.forEach((el, index) => this.tl.to(this.ui.foldShapes[0], .4, {morphSVG: el}, time + index * .4));

		this.tl.from(this.ui.shadows[1], .6, {y: -2, opacity: 0, ease: Power2.easeOut}, '-=.2');

		this.tl.from(this.ui.guyLeft, .5, {x: 250, ease: Power2.easeOut}, '-=.1');
		this.tl.from(this.ui.guyLeftHead, .4, {x: 20, ease: Back.easeOut}, '-=.1');

		this.tl.from(this.ui.guyRight, .5, {x: -250, ease: Power2.easeOut}, '-=.3');
		this.tl.from(this.ui.guyRightHead, .3, {x: -15, ease: Back.easeOut}, '-=.1');

		this.tl.from(this.ui.guyLeftArm, .3, {rotation: -8, x: 10, transformOrigin: '78% 42%', ease: Power2.easeOut}, '-=.4');
		this.tl.from(this.ui.guyLeftLegg, .3, {rotation: -6, x: 10, transformOrigin: '74% 55%', ease: Power2.easeOut}, '-=.35');
		this.tl.from(this.ui.guyRightWaisHand, .3, {opacity: 0, x: -25, ease: Power2.easeOut}, '-=.3');


		// this.tl.staggerFrom(this.ui.arrows, .6, {opacity: 0, x: '-600', ease: Power2.easeInOut}, .125 );
		this.tl.staggerFrom(this.ui.bodyLines, .4, {opacity: 0, y: 20, ease: Power2.easeOut}, .125);

	}

}

export default class StopMotionAnimation {

	constructor(frames) {
		this.frames = frames;

		this.frameIndex = 0;
		this.currentFrameIndex = 0;
		this.currentFrame = null;
	}

	reset() {
		this.frameIndex = 0;
		this._onAnimationUpdate();
	}

	getCurrentFrame() {
		this.currentFrameIndex = Math.floor(this.frameIndex);

		return this.frames[this.currentFrameIndex];
	}

	play(duration = 2) {
		TweenLite.to(this, duration, {
			delay: 0.5,
			frameIndex: this.frames.length - 1,
			ease: Linear.easeNone,
			onUpdate: this._onAnimationUpdate,
			onUpdateScope: this,
		});
	}

	_onAnimationUpdate() {
		if (this.currentFrame) {
			const previousFrame = this.currentFrame;
			setTimeout(() => {
				previousFrame.style.zIndex = 1;
				previousFrame.style.display = 'none';
			}, 300);
		}

		const newCurrentFrame = this.getCurrentFrame();
		newCurrentFrame.style.zIndex = 2;
		newCurrentFrame.style.display = 'block';

		this.currentFrame = newCurrentFrame;
	}
}

export default function getElementPageY( element ) {
	var location = 0;
	if (element.offsetParent) {
		do {
			location += element.offsetTop;
			element = element.offsetParent;
		} while (element);
	}
	return location >= 0 ? location : 0;
}

import View from 'Core/View.js';

import './sticky-back-button.scss';

export default class StickyBackButton extends View {

	initialize(options) {
		this.options = options;
		this.onClick = this.onClick.bind(this);

		this.el.addEventListener('click', this.onClick);
	}

	destroy() {
		this.el.removeEventListener('click', this.onClick);
	}

	onClick(event) {
		event.preventDefault();
		history.back();
	}
}

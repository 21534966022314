import Dropzone from 'dropzone';
import TweenLite from 'gsap/TweenLite';

import ViewMap from 'Core/ViewMap.js';
import signalMap from './signalMap.js';
import PageloadService from './services/PageloadService.js';
import URLService from './services/URLService.js';
import RouteService from './services/RouteService.js';
import FormMessageService from './services/FormMessageService.js';
import ScrollService from './services/ScrollService.js';
import RedirectService from './services/RedirectService.js';
import AnchorScrollService from './services/AnchorScrollService.js';
import ModalDictionary from './services/ModalDictionary.js';
import TrackingService from './services/TrackingService.js';
import ImpressionTracker from './services/ImpressionTracker';
import ScrollTriggerService from './services/ScrollTriggerService';

import CartModel from 'data/CartModel';

// import signalMapAlerter from 'debug/signalMapAlerter.js';
// import signalMapLogger from 'debug/signalMapLogger.js';

class ApplicationContext {

	constructor() {
		this.windowWidth = window.innerWidth;
		this.windowHeight = window.innerHeight;

		Dropzone.autoDiscover = false;

		// Prevent double click element selection
		document.addEventListener('mousedown', function (event) {
			if (event.detail > 1) {
				event.preventDefault();
			}
		}, false);

		const routeMap = window.tonysConfig.routes;
		if (routeMap['jaarfairslag.show']) {
			routeMap['jaarfairslag.show'].page.background_color = 'white';
		}

		// this.signals = signalMapLogger(signalMap);
		this.signals = signalMap;
		this.routes = new RouteService({
			routeMap: window.tonysConfig.routes,
		});
		this.routeAtLoad = this.routes.getRoute();

		this.modalDictionary = new ModalDictionary();

		this.redirectService = new RedirectService({
			signals: this.signals,
			routes: this.routes,
			routeAtLoad: this.routeAtLoad,
		});

		this.scrollTriggerService = new ScrollTriggerService({});

		this.views = this.mapViews();
		this.signals.pageContentReplaced.add(this.onLoadedContentPlaced.bind(this));

		this.formMessageService = new FormMessageService({signals: this.signals});

		this.urlService = new URLService({
			signals: this.signals,
			routes: this.routes,
			routeAtLoad: this.routeAtLoad,
		});

		this.pageloadService = new PageloadService({
			signals: this.signals,
			routes: this.routes,
			routeAtLoad: this.routeAtLoad,
		});

		this.tracking = new TrackingService({
			signals: this.signals,
			routeAtLoad: this.routeAtLoad,
		});

		this.impressionTracker = new ImpressionTracker({
			signals: this.signals,
			routeAtLoad: this.routeAtLoad,
		});

		this.anchorScrollService = new AnchorScrollService({
			urlService: this.urlService,
			signals: this.signals,
			routeAtLoad: this.routeAtLoad,
		});

		this.signals.pageLoad.requested.add((route, options) => {
			if (options && options.preventLoading) {
				this.pageloadService.updateCurrentRoute(route);
				return;
			}

			this.pageloadService.load(route);
		});

		document.documentElement.classList.remove('no-js');

		// Debounce the resize
		let resizeDebouncer = 0;
		this.onWindowResized = this.onWindowResized.bind(this);
		window.addEventListener('resize', () => {
			clearTimeout(resizeDebouncer);
			resizeDebouncer = setTimeout(this.onWindowResized, 250);
		});

		window.onYouTubeIframeAPIReady = this.signals.youtubeAPIFrameReady.dispatch;

		// Expose certain parts of the application
		window.tonysModals = this.modalDictionary;
		window.showToaster = (message, timeout) => {
			this.signals.toaster.requested.dispatch(message, timeout);
		};

		/**
		 * Listen to spirit events
		 */
		window.addEventListener('connect-spirit', () => {
			console.info('[TONYS] Spirit connected');
			this.signals.debug.spiritConnected.dispatch();
		});

		window.addEventListener('disconnect-spirit', () => {
			console.info('[TONYS] Spirit disconnected');
			this.signals.debug.spiritDisconnected.dispatch();
		});
	}

	start() {
		this.views.build();
		this.tracking.begin();
		this.impressionTracker.begin();

		// Init cart model
		CartModel.initialize({
			signals: this.signals,
			modals: this.modalDictionary,
		});

		if (!document.documentElement.classList.contains('unsupported-browser')) {
			this.hideSiteBlocker();
		} else {
			document.body.querySelector('.unsupported-browser-notice__ignore').addEventListener('click', (event) => {
				event.preventDefault();
				this.hideSiteBlocker();
			});
		}
	}

	hideSiteBlocker() {
		const siteBlocker = document.querySelector('.site-blocker');

		// Fade out the blocker
		TweenLite.to(siteBlocker.querySelector('.site-blocker__loading'), 0.5, {
			y: -100,
			delay: 0.6,
			ease: Back.easeIn,
		});

		TweenLite.to(siteBlocker, 0.3, {
			opacity: 0,
			delay: 0.8,
			onComplete: () => {
				siteBlocker.style.display = 'none';
				document.documentElement.classList.remove('site-loading');
			}
		});
	}

	/**
	 * Map the selectors to the Views. The views will be automatically
	 * created when the selector exists on the page.
	 *
	 * @return {ViewMap}
	 * @private
	 */
	mapViews() {
		const views = new ViewMap(this);

		// Reusable components
		views.map('.arrow-link', require('./views/general/ArrowLink/ArrowLink.js'));
		views.map('.svg-button', require('./views/components/SVGButton/SVGButton.js'));
		views.map('.field', require('./views/components/Field/Field.js'));
		views.map('.checkbox', require('./views/components/Checkbox/Checkbox.js'));
		views.map('.radio', require('./views/components/RadioButton/RadioButton.js'));
		views.map('.radio-button-in-box', require('./views/components/RadioButtonInBox/RadioButtonInBox.js'));
		views.map('.select', require('./views/components/Select/Select.js'));
		views.map('.modal', require('./views/components/Modal/Modal.js'));
		views.map('.modal-trigger', require('./views/components/Modal/ModalTrigger.js'));
		views.map('.social-icons', require('./views/components/SocialIcons/SocialIcons.js')); // Direct links to fanpages
		views.map('.social-icons-share', require('./views/components/SocialIcons/SocialIconsShare.js')); // Share with popup
		views.map('.checkout-summary', require('./views/components/CheckoutSummary/CheckoutSummary.js'));
		views.map('.checkout-summary-row', require('./views/components/CheckoutSummary/CheckoutSummaryRow.js'));
		views.map('.discount-or-gift-cards', require('./views/components/DiscountOrGiftCards/DiscountOrGiftCards.js'));
		views.map('.repeat-order-warning', require('./views/components/RepeatOrderWarning/RepeatOrderWarning.js'));
		views.map('.field--vat', require('./views/components/VATField/VATField.js'));
		views.map('.spinner', require('./views/components/Spinner/Spinner.js'));
		views.map('.button', require('./views/widgets/Button/Button.js'));
		views.map('.form-response-modal', require('./views/components/FormResponseModal/FormResponseModal.js'));
		views.map('.resend-giftcard-modal', require('./views/components/ResendGiftCardModal/ResendGiftCardModal.js'));
		views.map('.consents-modal', require('./views/components/ConsentsModal/ConsentsModal.js'));
		views.map('.cookie-settings-modal', require('./views/components/CookieSettingsModal/CookieSettingsModal.js'));
		views.map('.small-counter', require('./views/components/SmallCounter/SmallCounter.js'));
		views.map('.add-postcard-modal', require('./views/pages/Checkout/CheckoutAddPostcardModal.js'));

		views.map('.wrapper-thumb', require('./views/components/WrapperThumb/WrapperThumb.js'));
		views.map('.subscription-preview', require('./views/components/SubscriptionPreview/SubscriptionPreview.js'));
		views.map('.subscription-status', require('./views/components/SubscriptionStatus/SubscriptionStatus.js'));
		views.map('.ingredients-map', require('./views/components/IngredientsMap/IngredientsMap.js'));
		views.map('.allergens-warning', require('./views/components/AllergensWarning/AllergensWarning'));
		views.map('.product-options', require('./views/components/ProductOptions/ProductOptions'));
		views.map('.product-detail-custom-wrapper', require('./views/components/ProductDetailCustomWrapper/ProductDetailCustomWrapper'));
		views.map('.lottie-animation', require('./views/components/LottieAnimation/LottieAnimation'), {
			scrollTriggerService: this.scrollTriggerService,
		});
		views.map('.drag-carousel', require('./views/components/DragCarousel/DragCarousel'));
		views.map('.classics', require('./views/components/Classics/Classics'));
		views.map('.gift-card-balance-check-mini', require('./views/components/GiftCardBalanceCheckMini/GiftCardBalanceCheckMini'));
		views.map('.text-area-with-limit', require('./views/components/TextAreaWithLimit/TextAreaWithLimit'));
		views.map('.date-picker-modal', require('./views/components/DatePickerModal/DatePickerModal'));
		views.map('.date-picker', require('./views/components/DatePicker/DatePicker'));
		views.map('.time-picker', require('./views/components/TimePicker/TimePicker'));
		views.map('.contact-fields', require('./views/components/ContactFields/ContactFields'));
		views.map('.countdown', require('./views/components/Countdown/Countdown'));
		views.map('.bottom-background', require('./views/components/BottomBackground/BottomBackground'));
		views.map('.top-background', require('./views/components/TopBackground/TopBackground.js'));
		views.map('.pro-designer', require('./views/components/ProDesigner/ProDesigner.js'));

		// Main modules
		views.map(document, require('./views/general/PageTitle/PageTitle.js'));
		views.map(document, require('./views/general/CSRFMeta/CSRFMeta.js'));
		views.map(document.body, require('./views/general/BodyClasses/BodyClasses.js'));
		views.map(document.body, require('./views/general/AnchorCatcher/AnchorCatcher.js'), {
			redirectService: this.redirectService,
		});
		views.map('.meta-cart-icon', require('./views/general/MetaCartIcon/MetaCartIcon.js'));
		views.map(document.body, require('./views/general/BodyStickyTop/BodyStickyTop.js'));
		views.map('.wrapper', require('./views/general/Wrapper/Wrapper.js'));
		views.map('.container', require('./views/general/Container/Container.js'));
		views.map('.cookie-notification', require('./views/general/CookieNotification/CookieNotification.js'));
		views.map('.cookie-notification-minimal', require('./views/general/CookieNotificationMinimal/CookieNotificationMinimal.js'));
		views.map('.shop-popup', require('./views/general/ShopPopup/ShopPopup.js'));
		views.map('.toaster-container', require('./views/general/Toaster/ToasterContainer.js'));
		views.map('.form', require('./views/general/Form/Form.js'));
		views.map('.animated-background', require('./views/general/AnimatedBackground/AnimatedBackground.js'));
		views.map('.bar', require('./views/general/Bar/Bar.js'));
		views.map('.main-titles', require('./views/general/MainTitles/MainTitles.js'));
		views.map('.loading-animation', require('./views/general/LoadingAnimation/LoadingAnimation.js'));
		views.map('.navigation', require('./views/general/Navigation/Navigation.js'));
		views.map('.header', require('./views/general/Header/Header.js'));
		views.map('.footer', require('./views/general/Footer/Footer.js'));
		views.map('.meta', require('./views/general/Meta/Meta.js'));
		views.map('.meta-mini-cart', require('./views/general/MiniCart/MetaMiniCart.js'));
		views.map('.sticky-mini-cart', require('./views/general/StickyMiniCart/StickyMiniCart.js'));
		views.map('.sticky-back-button', require('./views/general/StickyBackButton/StickyBackButton.js'));
		views.map('.sticky-menu-trigger', require('./views/general/StickyMenuTrigger/StickyMenuTrigger.js'));
		views.map('.sticky-shop-nav', require('./views/general/StickyShopNav/StickyShopNav.js'));
		views.map('.login-form', require('./views/general/LoginForm/LoginForm.js'));
		views.map('.register', require('./views/components/Register/Register.js'));
		views.map('.address-form', require('./views/components/AddressForm/AddressForm.js'));
		views.map('.telephone-field', require('./views/components/TelephoneField/TelephoneField.js'));
		views.map('.section-button', require('./views/general/SectionButton/SectionButton.js'));
		views.map('.localization-picker', require('./views/general/LocalizationPicker/LocalizationPicker.js'));
		views.map('.notification-banner', require('./views/general/NotificationBanner/NotificationBanner.js'));
		views.map('#crash-notifier', require('./views/general/CrashNotifier/CrashNotifier.js'));
		views.map('#edit-or-duplicate-unlimited', require('./views/general/EditOrDuplicateUnlimited/EditOrDuplicateUnlimited.js'));
		views.map('#edit-or-duplicate-wrapper', require('./views/general/EditOrDuplicateWrapper/EditOrDuplicateWrapper.js'));
		views.map('#order-template-modal-wrapper', require('./views/general/OrderTemplate/OrderTemplate.js'));
		views.map('#edit-gift-card', require('./views/general/EditGiftCard/EditGiftCard.js'));
		views.map('.google-search', require('./views/general/GoogleSearch/GoogleSearch.js'));
		views.map('.search-trigger', require('./views/general/SearchTrigger/SearchTrigger.js'));
		views.map('.address-card', require('./views/general/AddressCard/AddressCard.js'));
		views.map('.breadcrumbs', require('./views/general/Breadcrumbs/Breadcrumbs.js'));
		views.map('.deep-link-button', require('./views/general/DeepLinkButton/DeepLinkButton.js'));
		views.map('.progress-step-bar', require('./views/general/ProgressStepBar/ProgressStepBar.js'));

		// Optional widgets
		views.map('.video', require('./views/widgets/Video/Video.js'));
		views.map('.image-carousel', require('./views/widgets/ImageCarousel/ImageCarousel.js'));
		views.map('.impact', require('./views/widgets/Impact/Impact.js'));
		views.map('.generic-form', require('./views/widgets/GenericForm/GenericForm.js'));
		views.map('.latest-news', require('./views/widgets/LatestNews/LatestNews.js'));
		views.map('.counter', require('./views/widgets/Counter/Counter.js'));
		views.map('.form-submission-counter', require('./views/widgets/FormSubmissionCounter/FormSubmissionCounter.js'));
		views.map('.wrapper-upload', require('./views/widgets/WrapperUpload/WrapperUpload.js'));
		views.map('.contact-form', require('./views/widgets/ContactForm/ContactForm.js'));
		views.map('.manifesto', require('./views/widgets/Manifesto/Manifesto.js'));
		views.map('.instagram-feed', require('./views/widgets/InstagramFeed/InstagramFeed.js'));
		views.map('.photo-album', require('./views/widgets/PhotoAlbum/PhotoAlbum.js'));
		views.map('.collapse', require('./views/widgets/Collapse/Collapse.js'));
		views.map('.widget-slider', require('./views/widgets/Slider/Slider'));
		views.map('.highlight', require('./views/widgets/Highlight/Highlight'));
		views.map('#b-j-clouds', require('./views/general/Clouds/Clouds.js'));

		// Widgets without real js
		require('./views/widgets/Content/Content.js');
		require('./views/widgets/Image/Image.js');
		require('./views/widgets/ImageButton/ImageButton.js');
		require('./views/widgets/ButtonList/ButtonList.js');
		require('./views/widgets/SeriousFriends/SeriousFriends.js');

		// Other components
		views.map('.checkout-product', require('./views/pages/Shop/components/CheckoutProduct/CheckoutProduct.js'));
		views.map('.shop-carousel', require('./views/pages/Shop/components/ShopCarousel/ShopCarousel.js'));
		views.map('.mobile-search-trigger', require('./views/pages/Shop/components/MobileSearchTrigger/MobileSearchTrigger.js'));
		views.map('.shop-search-field', require('./views/components/ShopSearchField/ShopSearchField.js'));
		views.map('.shop-links', require('./views/components/ShopLinks/ShopLinks.js'));
		/* @deprecated */
		views.map('.product-tile', require('./views/pages/Shop/components/ProductTile/ProductTile.js'));
		/* @deprecated */
		views.map('.content-tile', require('./views/pages/Shop/components/ContentTile/ContentTile.js'));
		views.map('.shop-product-tile', require('./views/pages/Shop/components/ShopProductTile/ShopProductTile.js'));
		views.map('.content-product-tile', require('./views/pages/Shop/components/ShopContentTile/ShopContentTile.js'));
		views.map('.shop-cart-product-tile', require('./views/pages/Shop/components/ShopCartProductTile/ShopCartProductTile.js'));
		views.map('.shop-category', require('./views/pages/Shop/components/ShopCategory/ShopCategory.js'));
		views.map('.shop-themed-category', require('./views/pages/Shop/components/ShopThemedCategory/ShopThemedCategory.js'));
		views.map('.shop-category-wrapper', require('./views/pages/Shop/components/ShopCategoryWrapper/ShopCategoryWrapper.js'));
		views.map('.shop-products', require('./views/pages/Shop/components/ShopProducts/ShopProducts.js'));
		views.map('.shop-templates', require('./views/pages/Shop/components/ShopTemplates/ShopTemplates.js'));
		views.map('.shop-related-products', require('./views/pages/Shop/components/ShopRelatedProducts/ShopRelatedProducts.js'));
		views.map('.shop-category-menu', require('./views/pages/Shop/components/ShopCategoryMenu/ShopCategoryMenu.js'));
		views.map('.product-row', require('./views/pages/Shop/components/ProductRow/ProductRow.js'));
		views.map('.numeric-stepper', require('./views/components/NumericStepper/NumericStepper.js'));
		views.map('.recipient-list', require('./views/components/RecipientList/RecipientList.js'));
		views.map('.filters', require('./views/components/Filters/Filters'));
		views.map('.referral-code', require('./views/components/ReferralCode/ReferralCode'));
		views.map('.subscription-confetti', require('./views/components/SubscriptionConfetti/SubscriptionConfetti.js'));
		views.map('.tool-tile', require('./views/components/ToolTile/ToolTile'));
		views.map('.payment-methods', require('./views/components/PaymentMethods/PaymentMethods'));
		views.map('.cart-overlay', require('./views/components/CartOverlay/CartOverlay'));
		views.map('.address-item', require('./views/pages/Account/components/AddressItem/AddressItem'));
		views.map('.account-navigation', require('./views/pages/Account/components/AccountNavigation.js'));
		views.map('.wrapper-preview', require('./views/pages/Account/components/WrapperPreview.js'));
		views.map('.shop-detail-column', require('./views/pages/ShopDetail/components/ShopDetailColumn/ShopDetailColumn.js'));
		views.map('.carousel-slides', require('./views/components/CarouselSlides/CarouselSlides.js'));
		views.map('.product-detail-carousel-slide', require('./views/pages/ProductDetail/components/ProductDetailCarouselSlide.js'));
		views.map('.product-detail-column', require('./views/pages/ProductDetail/components/ProductDetailColumn.js'));
		views.map('.product-detail-price', require('./views/pages/ProductDetail/components/ProductDetailPrice.js'));
		views.map('.product-detail__product-content-info-wrapper', require('./views/pages/ProductDetail/components/ProductDetailContentMask.js'));
		views.map('.product-detail-description', require('./views/pages/ProductDetail/components/ProductDetailDescription.js'));
		views.map('.page--product-detail', require('./views/pages/ProductDetail/components/ProductDetailHighlight.js'));
		views.map('.switch', require('./views/components/Switch/Switch.js'));
		views.map('.shop-top-nav', require('./views/components/ShopTopNav/ShopTopNav.js'));
		views.map('.cart-products-summary', require('./views/components/CartProductsSummary/CartProductsSummary.js'));
	views.map('.shipping-cost-status', require('./views/components/ShippingCostStatus/ShippingCostStatus.js'));
		views.map('.adyen-drop-in', require('./views/components/AdyenDropIn/AdyenDropIn.js'));
		views.map('.custom-payment-methods', require('./views/components/CustomPaymentMethods/CustomPaymentMethods.js'));

		views.map('.add-to-basket-button-container', require('./views/components/AddToBasketButton/AddToBasketButtonContainer'));
		views.map('.go-to-checkout-button', require('./views/components/GoToCheckoutButton/GoToCheckoutButton.js'));
		views.map('.lazy-image', require('./views/components/LazyImage/LazyImage'));
		require('./views/components/SubmitIdea/SubmitIdea.js');
		require('./views/components/NutritionTable/NutritionTable.js');

		// Pages
		views.map('.unlimiteds', require('./views/pages/Unlimiteds/Unlimiteds.js'));
		views.map('.page--collaborate', require('./views/pages/Collaborate/Collaborate.js'));
		views.map('.page--shop', require('./views/pages/Shop/Shop.js'));
		views.map('.page--shop-reseller', require('./views/pages/ShopReseller/ShopReseller.js'));
		/* @deprecated */
		views.map('.page--shop-detail', require('./views/pages/ShopDetail/ShopDetail.js'));
		views.map('.page--product-detail', require('./views/pages/ProductDetail/ProductDetail.js'));
		/* @deprecated */
		views.map('.page--cart', require('./views/pages/Cart/Cart.js'));
		views.map('.page--shopping-cart', require('./views/pages/ShoppingCart/ShoppingCart.js'));
		views.map('.page--wrap-creator', require('./views/pages/WrapCreator/WrapCreator.js'));
		views.map('.page--wrapper-creator', require('./views/pages/WrapperCreator/WrapperCreator.js'));
		views.map('.page--default', require('./views/pages/DefaultPage/DefaultPage.js'));
		views.map('.page--faq', require('./views/pages/FAQ/FAQPage.js'));
		views.map('.page--authenticate', require('./views/pages/ShopAuthenticate/ShopAuthenticate.js'));
		views.map('.page--account-dashboard', require('./views/pages/Account/AccountDashboard.js'));
		views.map('.page--verified', require('./views/pages/Account/Verified.js'));
		views.map('.page--order-detail', require('./views/pages/Account/OrderDetail.js'));
		views.map('.page--checkout', require('./views/pages/Checkout/Checkout.js'));
		views.map('.page--checkout-details-guest', require('./views/pages/Checkout/CheckoutDetailsGuest.js'));
		views.map('.page--checkout-details-loggedin', require('./views/pages/Checkout/CheckoutDetailsLoggedIn.js'));
		views.map('.page--checkout-delivery', require('./views/pages/Checkout/CheckoutDelivery.js'));
		views.map('.page--checkout-payment', require('./views/pages/Checkout/CheckoutPayment.js'));
		views.map('.page--checkout-gifts', require('./views/pages/Checkout/CheckoutGifts.js'));
		views.map('.checkout__payment-method', require('./views/pages/Checkout/CheckoutPaymentMethods'));

		views.map('.page--payment-success', require('./views/pages/PaymentSuccess/PaymentSuccess.js'));
		views.map('.page--payment-failed', require('./views/pages/PaymentFailed/PaymentFailed.js'));
		views.map('.page--how-it-started', require('./views/pages/HowItStarted/HowItStarted.js'));
		views.map('.page--profile', require('./views/pages/Profile/Profile.js'));
		views.map('.page--assortment-overview', require('./views/pages/AssortmentOverview/AssortmentOverview.js'));
		views.map('.page--assortment-category', require('./views/pages/AssortmentCategory/AssortmentCategory.js'));
		views.map('.page--store-locator', require('./views/pages/StoreLocator/StoreLocatorContainer.js'));
		views.map('.page--jaarfairslag', require('./views/pages/JaarFairslag/JaarFairslag.js'));
		views.map('.styleguide', require('./views/pages/Styleguide/Styleguide.js'));
		views.map('.team', require('./views/pages/Team/Team.js'));
		views.map('.truck-locator-container', require('./views/pages/TruckLocator/TruckLocatorContainer.js'));
		views.map('.reframed', require('./views/pages/Reframed/Reframed.js'));
		views.map('.mission', require('./views/pages/Mission/Mission.js'));
		views.map('.manage-consents', require('./views/pages/ManageConsents/ManageConsents.js'));
		views.map('.page--toolbox', require('./views/pages/Toolbox/ToolboxLoader.js'));
		views.map('.page--tool', require('./views/pages/Tool/ToolLoader.js'));
		views.map('.sweet-solution-kit', require('./views/pages/SweetSolutionKit/SweetSolutionKitProductDetail.js'));
		views.map('.subscription', require('./views/pages/Subscription/Subscription.js'));
		views.map('.start-subscription', require('./views/pages/SelectSubscription/StartSubscription.js'));
		views.map('.give-subscription', require('./views/pages/SelectSubscription/GiveSubscription.js'));
		views.map('.redeem-subscription', require('./views/pages/SelectSubscription/RedeemSubscription.js'));
		views.map('.subscription-thank-you', require('./views/pages/SubscriptionThankYou/SubscriptionThankYou.js'));
		views.map('.subscription-coupon-form', require('./views/pages/SubscriptionCouponForm/SubscriptionCouponForm.js'));
		views.map('.gift-card-detail', require('./views/pages/GiftCardDetail/GiftCardDetail.js'));
		views.map('.bar-stories', require('./views/pages/BarStories/BarStories.js'));
		views.map('.giftcard-balance-check', require('./views/pages/GiftCardBalanceCheck/GiftCardBalanceCheck.js'));
		views.map('.ingredient-checker', require('./views/pages/IngredientChecker/IngredientChecker.js'));
		views.map('.payment-link', require('./views/pages/PaymentLink/PaymentLink.js'));
		views.map('.conversation-bars', require('./views/pages/ConversationBars/ConversationBars.js'));
		views.map('.product-detail-wrapper-creator', require('./views/pages/ProductDetailWrapperCreator/ProductDetailWrapperCreator.js'));
		views.map('.conversation-bars-product', require('./views/pages/ConversationBars/ConversationBarsProduct.js'));
		views.map('.news-overview', require('./views/pages/NewsOverview/NewsOverview.js'));
		views.map('.wrapper-detail', require('./views/pages/WrapperDetail/WrapperDetail.js'))

		// Page parts
		views.map('.page-order__products', require('./views/pages/Account/components/OrderProducts.js'));
		views.map('.shop-detail__add-to-basket', require('./views/components/AddToBasket/AddToBasket'));
		views.map('.subscription-change-payment-method', require('./views/pages/Subscription/components/SubscriptionChangePaymentMethod'));
		require('./views/pages/ShopReseller/components/ResellerVideoMessage/ResellerVideoMessage');

		return views;
	}

	onLoadedContentPlaced(container, route, scroll = true) {
		this.views.refresh(container);

		if (scroll) {
			ScrollService.scrollPageTo(0, 0);
		}
	}

	onWindowResized() {
		const w = window.innerWidth;
		const h = window.innerHeight;

		if (this.windowWidth === w && this.windowHeight === h) {
			return;
		}

		this.windowWidth = w;
		this.windowHeight = h;
		this.signals.windowResized.dispatch(w, h);
	}
}

export default ApplicationContext;

import View from 'Core/View.js';
import CartModel from 'data/CartModel';
import ShopCartProductAddToBasket from './ShopCartProductAddToBasket';
import getHashFromProductURL from '../../../../general/EditGiftCard/getHashFromProductURL';
import CurrencyFormatter from '../../../../../shared/utils/currency/CurrencyFormatter';

import './shop-cart-product-tile.scss';

const HAS_ITEMS = 'shop-cart-product-tile--has-items';

export default class ShopCartProductTile extends View {

	initialize() {

		this.removeFromBasket = this.removeFromBasket.bind(this);
		this.productDetailClick = this.productDetailClick.bind(this);
		this.onCartUpdated = this.onCartUpdated.bind(this);

		this.onPostcardClick = this.onPostcardClick.bind(this);

		this.id = this.el.dataset.id;
		this.wrapperId = this.el.dataset.wrapperId ? this.el.dataset.wrapperId : null;
		this.isPostcard = this.el.dataset.isPostcard;
		this.isMyoProduct = this.el.dataset.isMyoProduct;
		this.isGiftCard = this.el.dataset.isGiftCard;

		this.hash = this.el.dataset.hash ? this.el.dataset.hash : null;
		this.customerGroupId = parseInt(this.el.dataset.customerGroup) || null;
		this.image = this.find('.shop-cart-product-tile__image-wrapper');
		this.price = this.find('.shop-cart-product-tile__price');
		this.addToBasketButton = this.find('.shop-cart-product-tile__add-to-basket-button');
		this.removeButton = this.find('.shop-cart-product-tile__remove-button');
		this.duplicateUrl = this.el.dataset.duplicateUrl ? this.el.dataset.duplicateUrl : null;

		if (this.addToBasketButton) {
			this.addToBasketStepper = new ShopCartProductAddToBasket({
				el: this.addToBasketButton,
				wrapperId: this.wrapperId,
				hash: this.hash
			});
		}

		this.image.addEventListener('click', this.productDetailClick);
		this.removeButton.addEventListener('click', this.removeFromBasket);

		CartModel.updatedSignal.add(this.onCartUpdated);
		this.onCartUpdated();
	}

	destroy() {
		CartModel.updatedSignal.remove(this.onCartUpdated);
	}

	onPostcardClick(event) {
		event.preventDefault();
		const imageUrl = this.image.querySelector('img').getAttribute('src')
		this.signals.products.postcardEditRequested.dispatch(this.id, imageUrl);
	}

	onCartUpdated() {
		const productData = CartModel.getProductByHash(this.hash);
		const amount = productData ? productData.quantity : 0;

		this.el.classList.remove(HAS_ITEMS);
		if (amount > 0) {
			this.el.classList.add(HAS_ITEMS);
		}

		if (this.cartAmount) {
			this.cartAmount.innerHTML = amount;
		}

		if (this.price && productData) {
			this.price.innerText = CurrencyFormatter.format(tonysConfig.pricing.displayProductTax ? productData.priceVat : productData.price);
		}
	}

	productDetailClick(event) {
		const productURL = event.currentTarget.href;
		if (!productURL) {
			event.preventDefault();
			event.stopPropagation();
			return;
		}

		if (this.el.dataset.isUnlimited) {
			event.preventDefault();
			event.stopPropagation();
			this.signals.products.unlimitedEditRequested.dispatch(productURL, this.duplicateUrl);
		}

		if (this.el.dataset.isGiftCard) {
			event.preventDefault();
			event.stopPropagation();
			const hash = getHashFromProductURL(productURL);
			this.signals.products.giftCardEditRequested.dispatch(hash);
		}

		if (this.isPostcard) {
			this.onPostcardClick(event);
		}
	}

	removeFromBasket() {
		CartModel.updateProduct(this.id, 0, {
			wrapper_id: this.wrapperId,
			hash: this.hash,
			customer_group_id: this.customerGroupId
		});
		this.removed();
	}

	removed() {
		this.el.parentNode.removeChild(this.el);
		this.destroy();
	}
}

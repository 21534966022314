import View from '../../../shared/Core/View';

import './allergens-warning.scss';

export default class AllergensWarning extends View {

    initialize() {

    }

    destroy() {
    }
}

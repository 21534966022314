import './shop-cart-product-add-to-basket.scss';

import TweenLite, {Ease} from 'gsap/TweenLite';
import View from 'Core/View.js';
import CartModel from '../../../../../shared/data/CartModel';

const ITEMS_IN_BASKET = 'shop-cart-product-add-to-basket--items-in-basket';
const BUTTON_DISABLED_CLS = 'shop-cart-product-add-to-basket__counter__button--disabled';
const HEIGHT = 40;

const ENTER_KEY = 13;

export default class ShopCartProductAddToBasket extends View {

	initialize(options) {

		this.productId = this.el.dataset.productId;
		this.customerGroupId = parseInt(this.el.dataset.customerGroup);
		this.wrapperId = (options.wrapperId + '').trim().length > 0 ? options.wrapperId : null;
		this.hash = (options.hash + '').trim().length > 0 ? options.hash : null;
		this.maxOrderAmount = parseInt(this.el.dataset.maxOrderAmount);

		this.plus = this.plus.bind(this);
		this.minus = this.minus.bind(this);
		this.updateAmount = this.updateAmount.bind(this);
		this.inputBlur = this.inputBlur.bind(this);
		this.inputKeyDown = this.inputKeyDown.bind(this);
		this.resetAnimation = this.resetAnimation.bind(this);
		this.onCartUpdated = this.onCartUpdated.bind(this);
		this.onCartError = this.onCartError.bind(this);

		this.nextAmount = this.currentAmount = 0;
		this.animating = false;

		this.minusButton = this.find('.shop-cart-product-add-to-basket__counter__minus');
		this.plusButton = this.find('.shop-cart-product-add-to-basket__counter__plus');

		this.background = this.findAll('.shop-cart-product-add-to-basket__button__background');
		this.amountWrapper = this.find('.shop-cart-product-add-to-basket__amount-wrapper');
		this.input = this.find('.shop-cart-product-add-to-basket__amount-input input');

		this.amountTop = this.amountWrapper.querySelector('[data-position="top"]');
		this.amountMiddle = this.amountWrapper.querySelector('[data-position="middle"]');
		this.amountBottom = this.amountWrapper.querySelector('[data-position="bottom"]');

		this.minusButton.addEventListener('click', this.minus);
		this.plusButton.addEventListener('click', this.plus);
		this.input.addEventListener('blur', this.inputBlur);
		this.input.addEventListener('keydown', this.inputKeyDown);

		// Randomly rotate background blob
		TweenLite.set(this.background, {rotation: Math.random() * 360});

		// Wait for Cart to be updated and update initial amount accordingly
		CartModel.updatedSignal.addOnce(this.onCartUpdated);

		// Listen to cart errors on order to adjust the current amount
		CartModel.errorSignal.add(this.onCartError);

		//
		this.updateState();
	}

	destroy() {
		CartModel.updatedSignal.remove(this.onCartUpdated);
		CartModel.updatedSignal.remove(this.onCartError);

		this.minusButton.removeEventListener('click', this.minus);
		this.plusButton.removeEventListener('click', this.plus);
		this.input.removeEventListener('blur', this.inputBlur);
		this.input.removeEventListener('keydown', this.inputKeyDown)
	}

	onCartUpdated() {
		clearTimeout(this.updateTimeout);
		this.updateTimeout = setTimeout(() => {
			this.adjustAmount()
		}, 300);
	}

	onCartError() {
		clearTimeout(this.updateTimeout);
		this.updateTimeout = setTimeout(() => {
			this.adjustAmount()
		}, 300);
	}

	adjustAmount() {
		const productData = CartModel.getProductByHash(this.hash);
		this.updateState();
		this.updateAmount(productData ? productData.quantity : 0, false);
	}

	minus() {
		this.updateAmount(this.currentAmount - 1);
		this.shake(this.minusButton);
	}

	plus() {
		if (!this.maxOrderAmount ||
			this.maxOrderAmount === 0 ||
			(this.maxOrderAmount > 0 && this.maxOrderAmount >= this.currentAmount + 1)) {
			this.updateAmount(this.currentAmount + 1);
			this.shake(this.plusButton);
		}
	}

	inputBlur() {
		this.updateAmount(parseInt(this.input.value));
	}

	inputKeyDown(event) {
		if (event.keyCode === ENTER_KEY) {
			this.input.blur();
		}
	}

	updateAmount(nextAmount, updateCart = true) {
		if (nextAmount < 1) {
			nextAmount = 1;
		}

		this.nextAmount = nextAmount;

		if (nextAmount > this.currentAmount) {
			this.amountBottom.innerHTML = nextAmount;
			this.currentAmount = this.nextAmount;
			if (!this.animating) {
				TweenLite.to(this.amountWrapper, 0.3, {
					ease: Ease.easeInOut, y: -HEIGHT,
					onStart: () => this.animating = true,
					onComplete: this.resetAnimation,
					onCompleteParams: [updateCart]
				});
			}
		} else if (nextAmount < this.currentAmount) {
			this.amountTop.innerHTML = nextAmount;
			this.currentAmount = this.nextAmount;
			if (!this.animating) {
				TweenLite.to(this.amountWrapper, 0.3, {
					ease: Ease.easeInOut, y: HEIGHT,
					onStart: () => this.animating = true,
					onComplete: this.resetAnimation,
					onCompleteParams: [updateCart]
				});
			}
		} else {
			this.resetAnimation(updateCart);
		}

		this.updateState();
	}

	updateState() {
		this.el.classList.remove(ITEMS_IN_BASKET);
		if (this.nextAmount > 0) {
			this.el.classList.add(ITEMS_IN_BASKET);
		}

		if (this.nextAmount <= 1) {
			this.minusButton.classList.remove(BUTTON_DISABLED_CLS);
			this.minusButton.classList.add(BUTTON_DISABLED_CLS);
		} else {
			this.minusButton.classList.remove(BUTTON_DISABLED_CLS);
		}
	}

	resetAnimation(updateCart = true) {

		if (updateCart) {
			CartModel.updateProduct(this.productId, this.currentAmount, {
				wrapper_id: this.wrapperId,
				hash: this.hash,
				customer_group_id: this.customerGroupId
			});
		}

		this.nextAmount = null;
		this.amountBottom.innerHTML = '';
		this.input.value = this.amountMiddle.innerHTML = this.currentAmount;
		this.amountTop.innerHTML = '';

		TweenLite.set(this.amountWrapper, {ease: Ease.easeInOut, y: 0});

		this.animating = false;
	}

	shake(target) {
		TweenLite.killTweensOf(target);

		// Randomize rotation
		const targetRotation = (2 + Math.random() * 3) * (Math.random() > 0.5 ? -1 : 1);

		TweenLite.to(target, 0.1, {
			rotation: targetRotation, scale: 0.9, onComplete: () => {
				TweenLite.to(target, 0.1, {
					rotation: 0, scale: 1
				});
			}
		});
	}
}

import View from '../../../shared/Core/View';
import { TweenLite } from 'gsap/TweenLite.js';

import IngredientsBar from './IngredientsBar';

import './ingredients-map.scss';

export default class IngredientsMap extends View {
	initialize(options) {
		this.handleIngredientChosen = this.handleIngredientChosen.bind(this);
		this.repositionContent = this.repositionContent.bind(this);

		this.currentContent = null;
		this.sourceMapDimensions = {
			width: parseInt(this.el.dataset.mapWidth),
			height: parseInt(this.el.dataset.mapHeight),
		};

		this.map = this.find('.ingredients-map__map');
		this.contentContainerMobile = this.find(
			'.ingredients-map__content-container--mobile'
		);
		this.contentContainerDesktop = this.find(
			'.ingredients-map__content-container--desktop'
		);
		this.contentPlaceholder = this.find(
			'.ingredients-map__content-placeholder'
		);

		this.ingredients = new IngredientsBar({
			...options,
			onIngredientChosen: this.handleIngredientChosen,
			el: this.find('.ingredients-map__ingredients-bar'),
		});
		this.screen.breakpointChanged.add(this.repositionContent);
	}

	destroy() {
		this.screen.breakpointChanged.remove(this.repositionContent);
		this.ingredients.destroy();
	}

	handleIngredientChosen(ingredient) {
		const isSameAsCurrent = this._isCurrentContent(ingredient.id);
		this.removeMarkers();
		this.hideContent();

		if (isSameAsCurrent) {
			this.showPlaceholder();
			return;
		}

		this.showMarkers(ingredient.iconUrl, ingredient.locations);
		this.showContent(ingredient.id);
		this.hidePlaceholder();
	}

	showMarkers(iconUrl, locations) {
		const newMarkers = [];
		locations.forEach(location => {
			const markerWrapper = document.createElement('div');
			markerWrapper.className = 'ingredients-map__marker-wrapper';
			markerWrapper.style.top = this._percentage(
				parseFloat(location.y) / this.sourceMapDimensions.height
			);
			markerWrapper.style.left = this._percentage(
				parseFloat(location.x) / this.sourceMapDimensions.width
			);
			newMarkers.push(markerWrapper);

			const marker = document.createElement('img');
			marker.className = 'ingredients-map__marker';
			marker.src = iconUrl;
			markerWrapper.appendChild(marker);

			this.map.appendChild(markerWrapper);
		});

		const tl = new TimelineMax();
		tl.staggerFrom(
			newMarkers,
			0.15,
			{ scale: 0.6, opacity: 0, delay: 0.1, transformOrigin: '0% 0%' },
			0.1
		);
	}

	showContent(id) {
		const content = this.find(`.ingredients-map__content[data-id="${id}"]`);
		const newContent = content.cloneNode(true);

		const contentParent = this._contentContainerForBreakpoint();
		contentParent.appendChild(newContent);

		this.currentContent = newContent;

		this.initializeChildViews(this.currentContent);

		TweenLite.from(newContent, 0.15, {
			y: 20,
			opacity: 0,
			delay: 0.1,
		});
	}

	hideContent() {
		if (this.currentContent) {
			TweenLite.to(this.currentContent, 0.15, {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				y: 20,
				opacity: 0,
				onComplete: el => {
					el.parentNode.removeChild(el);
				},
				onCompleteParams: [this.currentContent],
			});

			this.destroyChildViews(this.currentContent);
			this.currentContent = null;
		}
	}

	removeMarkers() {
		const markers = this.map.querySelectorAll('img');
		const tl = new TimelineMax({
			onComplete: () => {
				markers.forEach(el => el.parentNode.removeChild(el));
			},
		});
		tl.staggerTo(markers, 0.15, { scale: 0.6, opacity: 0 }, 0.05);
	}

	showPlaceholder() {
		this.contentPlaceholder.style.display = 'block';
		TweenLite.to(this.contentPlaceholder, 0.15, {
			transformOrigin: '50% 0',
			x: '-50%',
			scale: 1,
			opacity: 1,
		});
	}

	hidePlaceholder() {
		TweenLite.to(this.contentPlaceholder, 0.15, {
			transformOrigin: '50% 0',
			x: '-50%',
			scale: 0.6,
			opacity: 0,
			onComplete: () => {
				this.contentPlaceholder.style.display = 'none';
			},
		});
	}

	repositionContent() {
		if (!this.currentContent) {
			return;
		}

		this._contentContainerForBreakpoint().appendChild(this.currentContent);
	}

	_isCurrentContent(id) {
		if (!this.currentContent) {
			return false;
		}

		return this.currentContent.dataset.id === id;
	}

	_contentContainerForBreakpoint() {
		return this.screen.isOneOf(['mobile'])
			? this.contentContainerMobile
			: this.contentContainerDesktop;
	}

	_percentage(float) {
		return (Math.round(1000 * float) / 10).toString() + '%';
	}
}

/* global PUBLIC_IMAGES_PATH */
import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Location from '../../shapes/Location';
import LatLng from '../../../../../shared/shapes/LatLng';

import FocusCatcher from './components/FocusCatcher/FocusCatcher';
import ControlledMap from '../../../../reactComponents/ControlledMap/ControlledMap';

import './store-locator-map.scss';

const MARKER_IMAGE_PATH = PUBLIC_IMAGES_PATH + 'store-locator';

const getMarkerIcon = (country) => {
	return {
		anchor: new google.maps.Point(20, 20),
		size: new google.maps.Size(80, 80),
		scaledSize: new google.maps.Size(40, 40),
		url: MARKER_IMAGE_PATH + '/' + country + '_' + 'marker.png',
	};
};

const getMarkerIconForCurrent = (country) => {
	return {
		anchor: new google.maps.Point(0, 100),
		size: new google.maps.Size(192, 200),
		scaledSize: new google.maps.Size(96, 100),
		url: MARKER_IMAGE_PATH + '/' + country + '_' + 'marker--current.png',
	};
};

const getMarkerIconForUser = () => {
	return {
		anchor: new google.maps.Point(28, 28),
		size: new google.maps.Size(110, 112),
		scaledSize: new google.maps.Size(55, 56),
		url: MARKER_IMAGE_PATH + '/your-are-here.png',
	};
};

const getClustererOptions = () => {
	return {
		gridSize: 80,
		imagePath: MARKER_IMAGE_PATH + '/cluster',
		imageExtension: 'png',
		styles: [
			{
				url: MARKER_IMAGE_PATH + '/cluster1.png',
				width: 40,
				height: 37,
				textColor: '#ffffff',
				textSize: 16
			},
			{
				url: MARKER_IMAGE_PATH + '/cluster2.png',
				width: 57,
				height: 49,
				textColor: '#ffffff',
				textSize: 16
			},
			{
				url: MARKER_IMAGE_PATH + '/cluster3.png',
				width: 57,
				height: 49,
				textColor: '#ffffff',
				textSize: 16
			},
			{
				url: MARKER_IMAGE_PATH + '/cluster4.png',
				width: 74,
				height: 46,
				textColor: '#ffffff',
				textSize: 16
			},
		]
	};
};

class StoreLocatorMap extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// UI
			hasFocus: false,

			initialZoom: props.initialZoom,
			initialCenter: props.initialCenter,
		};

		this.handleFocusCaught = this.handleFocusCaught.bind(this);
		this.handleFocusLost = this.handleFocusLost.bind(this);
		this.handleUserLocationDetected = this.handleUserLocationDetected.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.focusOnLatLng !== nextProps.focusOnLatLng) {
			this.setState({
				initialCenter: nextProps.focusOnLatLng || nextProps.initialCenter,
				initialZoom: 16,
			});
		}
	}

	render() {
		return (
			<div className="store-locator-map">
				<ControlledMap
					markers={this.props.locations}
					apiKey={this.props.apiKey}
					currentMarkerId={this.props.focusOnLocation ? this.props.focusOnLocation.id : null}
					height={this.getMapHeight()}

					minZoom={3}
					maxZoom={18}

					initialZoom={this.state.initialZoom}
					initialCenter={this.state.initialCenter}

					fixedZoom={this.props.focusOnLocation ? 16 : null}
					fixedCenter={this.props.focusOnLocation ? this.props.focusOnLocation.gps : null}

					getMarkerIcon={() => getMarkerIcon(this.props.country)}
					getMarkerIconForCurrent={() => getMarkerIconForCurrent(this.props.country)}
					getMarkerIconForUser={getMarkerIconForUser}
					getClustererOptions={getClustererOptions}

					hideControls={! ! this.props.focusOnLocation}
					hideZoomControls={this.props.isMobile}
					fitBoundsToMarkers={this.props.fitBoundsToMarkers}

					onCenterChanged={this.props.onMapTouched}
					onMarkerSelected={this.props.onLocationSelected}
					onUserLocationDetected={this.handleUserLocationDetected}
					onUserLocationBlocked={this.props.onUserLocationBlocked}
				/>

				{this.renderFocusCatcherWhenNotMobile()}
			</div>
		);
	}

	renderFocusCatcherWhenNotMobile() {
		if (! this.props.isMobile) {
			return null;
		}

		return (
			<FocusCatcher
				hasFocus={this.state.hasFocus}
				lockScreen={! ! this.props.focusOnLocation}
				onFocusLost={this.handleFocusLost}
				onFocusCaught={this.handleFocusCaught}/>
		);
	}

	getMapHeight() {
		if (! this.props.isMobile) {
			return this.props.isTablet ? 620 : 730;
		}

		if (this.state.hasFocus && ! this.props.focusOnLocation && ! this.props.isSearching) {
			return 460;
		}

		return 270;
	}

	handleUserLocationDetected(latLng) {
		if (this.props.onSearchByLatLng) {
			this.props.onSearchByLatLng(latLng);
		}
	}

	handleFocusCaught() {
		this.setState({hasFocus: true});
	}

	handleFocusLost() {
		this.setState({hasFocus: false});
	}
}

StoreLocatorMap.propTypes = {
	isMobile: PropTypes.bool,
	isTablet: PropTypes.bool,
	isSearching: PropTypes.bool,
	fitBoundsToMarkers: PropTypes.bool,
	apiKey: PropTypes.string.isRequired,
	country: PropTypes.oneOf(['default', 'US']).isRequired,

	locations: PropTypes.objectOf(Location).isRequired,
	focusOnLocation: Location,
	focusOnLatLng: LatLng,

	initialCenter: LatLng.isRequired,
	initialZoom: PropTypes.number.isRequired,

	onMapTouched: PropTypes.func,
	onLocationSelected: PropTypes.func,
	onSearchByLatLng: PropTypes.func,
	onUserLocationBlocked: PropTypes.func,
};

export default StoreLocatorMap;

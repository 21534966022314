import Flickity from 'flickity';
import 'flickity-imagesloaded';

import View from '../../../shared/Core/View';

import './carousel-slides.scss';
import './carousel-slide.scss';

const CAROUSEL_SLIDES_DRAGGING = 'carousel-slides--dragging';

export default class CarouselSlides extends View {

	initialize() {

		this.itemsContainer = this.find('.carousel-slides__items');
		this.imageWrappers = this.findAll('.carousel-slide__wrapper');
		this.dotsWrapper = this.find('.carousel-slides__dots');
		this.dots = null;

		this.flickityOptions =
			{
				draggable: true,
				wrapAround: this.el.dataset.wraparound,
				cellAlign: this.el.dataset.cellalign,
				freeScroll: this.el.dataset.freescroll,
				contain: this.el.dataset.contain,
				adaptiveHeight: false,
				imagesLoaded: true,
				pageDots: false,
				prevNextButtons: this.el.dataset.prevnextbuttons,
				autoPlay: false,
				selectedAttraction: 0.1,
				friction: 0.5,
				dragThreshold: 3,
			}

		// this.itemsContainer

		if(this.dotsWrapper) {
			this.dots = this.findAll('.carousel-slides__dot');
			this.handleFlickityChange(0);
		}

		this.previous = this.previous.bind(this);
		this.next = this.next.bind(this);

		this.handleFlickityChange = this.handleFlickityChange.bind(this);
		this.handleFlickityScroll = this.handleFlickityScroll.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);
		this.handleDragEnd = this.handleDragEnd.bind(this);

		this.lastProgress = 0;

		this.initializeFlickity(this.itemsContainer, this.el.dataset.autoPlay === 'true');
	}

	destroy() {

		this.deinitializeFlickity();

		if (this.closeButtonStick) {
			this.closeButtonStick.destroy();
		}
	}

	initializeFlickity(el, autoPlay) {
		this.flickity = new Flickity(el, this.flickityOptions);
		this.flickity.on('scroll', this.handleFlickityScroll);
		this.flickity.on('change', this.handleFlickityChange);
		this.flickity.on('dragStart', this.handleDragStart);
		this.flickity.on('dragEnd', this.handleDragEnd);

		this.flickity.reloadCells();
		this.flickity.reposition();
	}

	deinitializeFlickity() {
		if (!this.flickity) {
			return;
		}

		this.flickity.off('scroll', this.handleFlickityScroll);
		this.flickity.off('dragStart', this.handleDragStart);
		this.flickity.off('dragEnd', this.handleDragEnd);
		this.flickity.destroy();
		this.flickity = null;
	}

	previous() {
		this.flickity.previous();
	}

	next() {
		this.flickity.next();
	}

	reset() {
		this.deinitializeFlickity();
		this.initializeFlickity(this.itemsContainer, this.el.dataset.autoPlay === 'true');
		this.applySpeedTransformation(0);
	}

	applySpeedTransformation(deltaProgress) {

		const rotation = deltaProgress / 20;
		const scale = 1 - Math.abs(deltaProgress / 300);

		this.imageWrappers.forEach((image) => {
			image.style.transform = 'rotate( ' + rotation + 'deg) scale(' + scale + ')';
		});
	}

	handleFlickityScroll(event, progress) {
		if(this.dragging) {
			progress = progress ? progress : 0;

			const deltaProgress = Math.min(25, Math.max(-25, this.lastProgress - progress));
			this.applySpeedTransformation(deltaProgress);

			this.lastProgress = progress;
		}
	}

	handleFlickityChange(position) {
		if(this.dots) {
			this.dots.forEach((dotEl, index) => {
				dotEl.classList.remove('carousel-slides__dot--selected');
				if(index === position) {
					dotEl.classList.add('carousel-slides__dot--selected');
				}
			});
		}
	}

	handleDragStart() {
		clearTimeout(this.dragEndTimeout);
		this.dragging = true;

		this.el.classList.remove(CAROUSEL_SLIDES_DRAGGING);
		this.el.classList.add(CAROUSEL_SLIDES_DRAGGING);
	}

	handleDragEnd() {
		this.dragEndTimeout = setTimeout(() => {
			this.el.classList.remove(CAROUSEL_SLIDES_DRAGGING);

			this.dragging = false;
		}, 50);
	}
}

import DefaultPage from '../DefaultPage/DefaultPage.js';
import './shopping-cart.scss';
import CartModel from '../../../shared/data/CartModel';
import MiniCartImpactBeans from '../../general/MiniCart/MiniCartImpactBeans';
import CurrencyFormatter from '../../../shared/utils/currency/CurrencyFormatter';

const SHOPPING_CART_EMPTY_CLS = 'shopping-cart--empty'

export default class ShoppingCart extends DefaultPage {

	initialize(options) {
		super.initialize();

		this.update = this.update.bind(this);
		this.reload = this.reload.bind(this);
		this.onCheckout = this.onCheckout.bind(this);

		this.totalExclVat = this.find('.shopping-cart__total--excl-vat');
		this.totalInclVat = this.find('.shopping-cart__total--incl-vat');
		this.checkoutButton = this.find('.shopping-cart__checkout-button');

		if (this.checkoutButton) {
			this.checkoutButton.addEventListener('click', this.onCheckout);
		}

		this.impactBeans = new MiniCartImpactBeans({...options, el:this.find('.shopping-cart__impact-beans')});

		CartModel.updatedSignal.add(this.update);
		CartModel.newProductSignal.add(this.reload);
		this.update();
		CartModel.load();
	}

	destroy() {
		super.destroy();

		if (this.checkoutButton) {
			this.checkoutButton.removeEventListener('click', this.onCheckout);
		}

		this.impactBeans.destroy();

		CartModel.updatedSignal.remove(this.update);
		CartModel.newProductSignal.remove(this.reload);
	}

	show(widgetsOnly = false) {
		super.show(widgetsOnly);

		this.signals.ecommerce.cartViewed.dispatch(CartModel.getProducts());
	}

	hide() {
		super.hide();
	}

	update() {
		const cartMeta = CartModel.getMeta();
		const products = CartModel.getProducts();

		try {
			this.totalExclVat.innerHTML = CurrencyFormatter.format(cartMeta.grantTotalExcVat);
		} catch(e) {}

		try {
			this.totalInclVat.innerHTML = CurrencyFormatter.format(cartMeta.grantTotal);
		} catch(e) {}

		this.el.classList.remove(SHOPPING_CART_EMPTY_CLS);
		if(products.length <= 0) {
			this.el.classList.add(SHOPPING_CART_EMPTY_CLS);
		}
	}

	reload() {
		// Reload page when not in overlay, CartOverlay manages reload when in viewed in overlay
		if(!document.querySelector('.cart-overlay .cart-overlay__container .page--shopping-cart')) {
			window.location.reload();
		}
	}

	onCheckout(event) {
		const cartMeta = CartModel.getMeta();
		let message = '';

		if (cartMeta.quantityNotReached) {
			message = cartMeta.quantityNotReached.message;
			this.signals.modals.messageRequested.dispatch(cartMeta.quantityNotReached.message, '');
		}
		if (cartMeta.smallQuantityNotReached) {
			message = cartMeta.smallQuantityNotReached.message;
			this.signals.modals.messageRequested.dispatch(cartMeta.smallQuantityNotReached.message, '');
		}
		if (cartMeta.amountNotReached) {
			if (cartMeta.quantityNotReached) {
				message += '<br><br>';
			}
			message += cartMeta.amountNotReached.message;
		}

		if (message !== '') {
			event.preventDefault();
			this.signals.modals.messageRequested.dispatch(message, '');
		}
	}
}

import View from '../../../shared/Core/View';
import { SCROLLTRIGGER_EVENT_TYPES } from '../../../services/ScrollTriggerService';

const LOTTIE_PLAYER_URL = 'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.4/lottie.min.js';

const ANIMATION_TYPES = {
	DEFAULT: 'default',
	LOOP: 'loop',
	SCROLL: 'scroll',
	SCROLL_TRIGGER: 'scrolltrigger',
};

const loadLottieScript = callback => {
	if (typeof window.lottie !== 'undefined') {
		callback();
		return;
	}

	const handleLottieLoaded = () => {
		script.removeEventListener('load', handleLottieLoaded);
		callback();
	};

	const script = document.createElement('script');
	script.addEventListener('load', handleLottieLoaded);
	script.src = LOTTIE_PLAYER_URL;

	document.head.appendChild(script);
};

 const checkFontLoaded = (cb) => {
	document.fonts.ready.then(cb);
}

export default class LottieAnimation extends View {
	// PUBLIC
	initialize(options) {
		this.sts = options.scrollTriggerService;

		if(!this.el.dataset.animation) {
			console.error('no animation data set for Lottie Animation with id :: ', this.el.dataset.id);
			return;
		}

		this.settings = {
			id: this.el.dataset.id,
			animationData: JSON.parse(this.el.dataset.animation),
			animationType: this.el.dataset.animationType || ANIMATION_TYPES.DEFAULT,
			triggers: {
				top: this.el.dataset.triggerTop || null,
				bottom: this.el.dataset.triggerBottom || null,
			},
			loop: this.el.dataset.loop && this.el.dataset.loop !== 'false',
		};

		this.setupEventListeners();

		// Start by Loading script first.
		loadLottieScript(this.handleScriptLoaded);

		// Check if font is laoded to initialize lottie.
		checkFontLoaded(this.handleFontLoaded);
	}

	destroy() {
		if (this.animation) {
			this.animation.destroy();
			this.animation = null;
		}

		this.sts.removeTrigger(this.settings.id);
	}

	reset() {
		if (this.animation) {
			this.animation.stop();
		}
	}

	play() {
		if (this.animation) {
			this.animation.play();
		}
	}

	// PRIVATE
	setupEventListeners() {
		this.handleScriptLoaded = this.handleScriptLoaded.bind(this);
		this.handleFontLoaded = this.handleFontLoaded.bind(this);
		this.handleScrollUpdate = this.handleScrollUpdate.bind(this);
		this.handleScrollTriggerUpdate = this.handleScrollTriggerUpdate.bind(this);

		const { id, animationType, triggers } = this.settings;
		if (animationType === ANIMATION_TYPES.SCROLL_TRIGGER) {
			this.sts.registerScrollTrigger(
				id,
				SCROLLTRIGGER_EVENT_TYPES.TRIGGER,
				this.el,
				triggers.top,
				triggers.bottom,
				this.handleScrollTriggerUpdate
			);
		}

		if (animationType === ANIMATION_TYPES.SCROLL) {
			this.sts.registerScrollTrigger(
				id,
				SCROLLTRIGGER_EVENT_TYPES.PROGRESS,
				this.el,
				triggers.top,
				triggers.bottom,
				this.handleScrollUpdate
			);
		}
	}

	setupLottieAnimation() {
		const { animationData, animationType, loop } = this.settings;
		const autoPlay = animationType === ANIMATION_TYPES.DEFAULT || animationType === ANIMATION_TYPES.LOOP;

		this.setupPlayer(animationData, loop, autoPlay);
	}

	setupPlayer(animationData, loop, autoplay) {
		this.animation = window.lottie.loadAnimation({
			container: this.el,
			renderer: 'svg',
			loop,
			autoplay: autoplay || this.scrollTriggered,
			animationData,
		});
	}

	checkLottieInitialisation() {
		if(this.lottieInitialized && this.fontsLoaded) {
			this.setupLottieAnimation();
		}
	}

	handleScrollTriggerUpdate() {
		this.sts.removeTrigger(this.settings.id);
		if(this.animation) {
			this.animation.play();
		} else {
			this.scrollTriggered = true;
		}
	}

	handleScrollUpdate(progress) {
		if(this.animation) {
			this.animation.goToAndStop(Math.round(progress * this.animation.animationData.op), true);
		}
	}

	handleScriptLoaded() {
		this.lottieInitialized = true;
		this.checkLottieInitialisation();

	}

	handleFontLoaded(fontFaceSet) {
		if(fontFaceSet.status === 'loaded') {
			this.fontsLoaded = true;
			this.checkLottieInitialisation();
		}
	}
}

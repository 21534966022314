import View from 'Core/View.js';
import './order-products.scss';
import './order-product.scss';
import './resend-giftcard-modal.scss';

const HIDDEN_ORDER_PRODUCT_CLASS = 'order-product--hidden';

export default class OrderProducts extends View {

	initialize() {
		this.showMore = this.showMore.bind(this);

		this.showMoreButton = this.find('.page-order__products__show-more-button');
		this.showMoreButtonLabel = this.find('.page-order__products__show-more-button .button__label');
		this.orderProducts = this.findAll('.order-product');

		this.showMoreButton.addEventListener('click', this.showMore);

		this.updateShowMoreLabel();
	}

	updateShowMoreLabel() {
		const labelTemplate = this.showMoreButton.dataset.label;
		const hiddenProducts = this.findAll('.' + HIDDEN_ORDER_PRODUCT_CLASS);
		this.showMoreButtonLabel.innerHTML = labelTemplate.replace('{count}', hiddenProducts.length);

		this.showMoreButton.classList.remove('page-order__products__show-more-button--visible');
		if(hiddenProducts.length > 0) {
			this.showMoreButton.classList.add('page-order__products__show-more-button--visible');
		}
	}

	showMore() {
		this.findAll('.' + HIDDEN_ORDER_PRODUCT_CLASS).forEach((line) => {
			line.classList.remove(HIDDEN_ORDER_PRODUCT_CLASS);
		});

		this.updateShowMoreLabel();
	}

	destroy() {
		this.showMoreButton.removeEventListener('click', this.showMore);
	}
}

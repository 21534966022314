import Signal from 'signals';

/**
 * A toaster is a small notification that hides itself after a few seconds
 */
export default {

	/**
	 * Something requested a toaster to be displayed
	 *
	 * @param {string} platform
	 * @param {string} url
	 * @param {Object} route
	 */
	shareRequested: new Signal(),
};

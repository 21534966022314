import TweenLite from 'gsap/TweenLite';
import CartModel from 'data/CartModel';
import './shop-carousel-product-details.scss';

const SHOP_CAROUSEL_PRODUCT_DETAILS_MOVE_DISTANCE = 20;

export default class ShopCarouselProductDetails {
	constructor(el) {
		this.el = el;

		this.title = this.el.querySelector('.shop-carousel__product-details__title');
		this.description = this.el.querySelector('.shop-carousel__product-details__description');
		this.price = this.el.querySelector('.shop-carousel__product-details__price');
		this.link = this.el.querySelector('.shop-carousel__product-details__link');
		this.addToBasket = this.el.querySelector('.shop-carousel__product-details__add-to-basket');
		this.outOfStock = this.el.querySelector('.shop-carousel__product-details__out-of-stock ');

		this.onClick = this.onClick.bind(this);

		if (this.addToBasket) {
			this.addToBasket.addEventListener('mousedown', this.onClick);
		}
	}

	destroy() {
		if (this.addToBasket) {
			this.addToBasket.removeEventListener('mousedown', this.onClick);
		}
	}

	next(productData, direction) {
		this.nextProductData = productData;
		this.direction = direction;
		if(this.currentProductData) {
			this.hide();
		} else {
			this.update();
		}
	}

	show() {
		const offsetY = this.getOffsetY();
		TweenLite.set(this.el, {y:offsetY - SHOP_CAROUSEL_PRODUCT_DETAILS_MOVE_DISTANCE});
		TweenLite.to(this.el, 0.3, {
			opacity:1,
			y: offsetY,
			ease:Sine.easeOut});
	}

	hide() {
		TweenLite.to(this.el, 0.3, {
			opacity:0,
			y: this.getOffsetY() + SHOP_CAROUSEL_PRODUCT_DETAILS_MOVE_DISTANCE,
			ease:Sine.easeIn,
			onComplete:this.update,
			onCompleteScope:this});
	}

	getOffsetY() {
		const elHeight = this.el.clientHeight;
		return -Math.round(elHeight * 0.5); // Rounding is necessary so we don't get blurry text
	}

	onClick() {
		CartModel.addProduct(this.currentProductData.id);
	}

	update() {
		this.currentProductData = this.nextProductData;

		if(this.currentProductData) {
			this.title.innerText = this.currentProductData['name'];
			this.description.innerHTML = this.currentProductData['excerpt'] ? this.currentProductData['excerpt'] : '';

			if (this.price) {
				this.price.innerHTML = this.currentProductData['price']['currencyPriceVat'];
			}

			this.link.setAttribute('href',  this.currentProductData['url']);

			if(this.currentProductData.quantity > 0) {
				this.addToBasket && this.addToBasket.classList.remove('hidden');
				this.outOfStock && this.outOfStock.classList.add('hidden');
			} else {
				this.addToBasket && this.addToBasket.classList.add('hidden');
				this.outOfStock && this.outOfStock.classList.remove('hidden');
			}

			this.show();
		}
	}

}
export default function(queryString) {
	const query = {};
	const parts = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

	for (var i = 0; i < parts.length; i ++) {
		const keyValue = parts[i].split('=');
		query[decodeURIComponent(keyValue[0])] = decodeURIComponent(keyValue[1] || '');
	}

	return query;
}
import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

const CLASS_TOGGLED = 'localization-picker__country--toggled';

export default class LocalizationPicker extends View {

	initialize() {
		this.handleClick = this.handleClick.bind(this);
		this.resetTab = this.resetTab.bind(this);

		this.el.addEventListener('click', this.handleClick);

		this.signals.meta.tabOpenRequested.add(this.resetTab);

		// Set the initial country
		this.selectCountry(this.el.querySelector('.localization-picker__country--current'), false);
		this.initialCountry = this.currentCountry;
	}

	destroy() {
		this.el.removeEventListener('click', this.handleClick);
	}

	resetTab(tabId) {
		if (tabId === 'tab-localization') {
			this.selectCountry(this.initialCountry, false);
		}
	}

	handleClick(event) {
		const anchor = this.findAnchor(event.target);
		if (! anchor || ! anchor.classList.contains('localization-picker__country-link--multilingual')) {
			return;
		}

		event.preventDefault();

		this.selectCountry(anchor.parentNode, true);
	}

	selectCountry(countryEl, toggle) {
		if (this.currentCountry) {
			this.currentCountry.classList.remove(CLASS_TOGGLED);

			const oldLanguages = this.currentCountry.querySelector('.localization-picker__languages');
			TweenLite.to(oldLanguages, 0.2, {height: 0});
		}

		if (this.currentCountry === countryEl && toggle) {
			this.currentCountry = null;
			return;
		}

		if (! countryEl) {
			return;
		}

		this.currentCountry = countryEl;
		this.currentCountry.classList.add(CLASS_TOGGLED);

		const newLanguages = this.currentCountry.querySelector('.localization-picker__languages');
		TweenLite.set(newLanguages, {height: 'auto'});
		TweenLite.from(newLanguages, 0.2, {height: 0, immediateRender: false});
	}

	findAnchor(el) {
		if (! el) {
			return false;
		}

		if (el.tagName.toLowerCase() === 'a') {
			return el;
		}

		return this.findAnchor(el.parentNode);
	}
}

import emojiRegex from 'emoji-regex';

import View from '../../../../../shared/Core/View';

import './personal-text.scss';

const TOO_MANY = 'personal-text__char-counter__current--too-many';

const emoji = emojiRegex();

export default class PersonalText extends View {

    initialize() {
    	this.handleInputUpdate = this.handleInputUpdate.bind(this);
    	this.handleKeyDown = this.handleKeyDown.bind(this);

    	this.limit = this.el.dataset.limit ? parseInt(this.el.dataset.limit) : 150;

		this.input = this.find('.personal-text__input');
		this.input.addEventListener('input', this.handleInputUpdate);
		this.input.addEventListener('keydown', this.handleKeyDown);

		this.charCountCurrent = this.find('.personal-text__char-counter__current');
		const charCountLimit = this.find('.personal-text__char-counter__limit');
		charCountLimit.innerHTML = this.limit.toString();
    }

    destroy() {
		this.input.removeEventListener('input', this.handleInputUpdate);
		this.input.removeEventListener('keydown', this.handleKeyDown);
    }

	handleInputUpdate() {
    	let cleanedInput = this.input.value.replace(/(\r\n|\n|\r)/gm, '');
		cleanedInput = cleanedInput.replace(emoji, '');

    	if (this.input.value !== cleanedInput) {
    		this.input.value = cleanedInput;
		}

    	const count = cleanedInput.length;
		this.charCountCurrent.innerHTML = count;

		if (count > this.limit) {
			this.charCountCurrent.classList.add(TOO_MANY);
		} else {
			this.charCountCurrent.classList.remove(TOO_MANY);
		}
	}

	handleKeyDown(event) {

    	// Disallow enter
    	if (event.keyCode === 13) {
    		event.preventDefault();
		}
	}
}

import View from 'Core/View.js';
import isDescendantOf from 'utils/dom/isDescendantOf.js';
import TweenLite from 'gsap/TweenLite';

const OPEN_CLASS = 'meta-tab--open';

import './meta-tab.scss';

export default class MetaTab extends View {

	initialize() {
		this.handleMetaTabClose = this.handleMetaTabClose.bind(this);
		this.handleDocumentClick = this.handleDocumentClick.bind(this);
		this.handleMouseEnter = this.handleMouseEnter.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);

		this.tabId = this.el.getAttribute('id');

		this.closeButton = this.find('.meta-tab__close-button');

		this.closeButton.addEventListener('click', this.handleMetaTabClose);
	}

	destroy() {
		this.closeButton.removeEventListener('click', this.handleMetaTabClose);
		this.el.removeEventListener('mouseenter', this.handleMouseEnter);
		this.el.removeEventListener('mouseleave', this.handleMouseLeave);
		document.removeEventListener('click', this.handleDocumentClick);
	}

	handleMetaTabClose(event) {
		if (event) {
			event.preventDefault();
		}

		this.hide();
	}

	handleDocumentClick(event) {
		if (! isDescendantOf(event.target, this.el.parentNode)) {
			this.hide();
		}
	}

	show() {
		this.el.classList.add(OPEN_CLASS);
		this.signals.meta.tabOpenRequested.dispatch(this.tabId);

		this.el.addEventListener('mouseenter', this.handleMouseEnter);
		this.el.addEventListener('mouseleave', this.handleMouseLeave);

		document.addEventListener('click', this.handleDocumentClick);

		TweenLite.killTweensOf(this.el);
		TweenLite.fromTo(this.el, 0.25, {scale: 0, display: 'block'}, {
			scale: 1,
			ease: Back.easeOut,
			onComplete: () => {
				this.signals.meta.tabOpened.dispatch(this.tabId);
			}
		});
	}

	hide() {
		this.el.classList.remove(OPEN_CLASS);
		this.signals.meta.tabCloseRequested.dispatch(this.tabId);

		this.el.removeEventListener('mouseenter', this.handleMouseEnter);
		this.el.removeEventListener('mouseleave', this.handleMouseLeave);
		document.removeEventListener('click', this.handleDocumentClick);

		TweenLite.killTweensOf(this.el);
		TweenLite.to(this.el, 0.15, {
			scale: 0,
			onComplete: () => {
				this.el.style.display = 'none';
				this.signals.meta.tabClosed.dispatch(this.tabId);
			}
		});
	}

	handleMouseEnter() {
		this.signals.meta.mouseEnteredTab.dispatch(this.tabId);
	}

	handleMouseLeave(event) {
		this.signals.meta.mouseLeftTab.dispatch(this.tabId);
	}
}

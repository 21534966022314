import View from 'Core/View.js';
import CartModel from 'data/CartModel';
import './shop-product-tile.scss';
import ShopProductAddToBasket from './ShopProductAddToBasket';
import TweenLite from 'gsap/TweenLite';

const HAS_ITEMS = 'shop-product-tile--has-items';
const SHOW_STEPPER = 'shop-product-tile--show-stepper';
const HIDE_STEPPER_DELAY= 2000;

export default class ShopProductTile extends View {

	initialize(options) {

		this.addToBasket = this.addToBasket.bind(this);
		this.productDetailClick = this.productDetailClick.bind(this);
		this.onCartUpdated = this.onCartUpdated.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.onMouseEnter = this.onMouseEnter.bind(this);

		this.onPostcardClick = this.onPostcardClick.bind(this);

		this.id = this.el.dataset.id;
		this.hash = this.el.dataset.hash;
		this.isPostcard = this.el.dataset.isPostcard;
		this.isMyoProduct = this.el.dataset.isMyoProduct;
		this.isGiftCard = this.el.dataset.isGiftCard;

		this.image = this.find('.shop-product-tile__image-wrapper');
		this.cart = this.find('.shop-product-tile__cart');
		this.cta = this.find('.shop-product-tile__cta');
		this.addToBasketButton = this.find('.shop-product-tile__add-to-basket-button');
		this.ctaBackground = this.find('.shop-product-tile__cta__background');
		this.cartAmount = this.find('.shop-product-tile__cta__cart-amount');

		if(this.addToBasketButton) {
			this.addToBasketStepper = new ShopProductAddToBasket({el: this.addToBasketButton});
		}

		if(this.cart) {
			this.cart.addEventListener('click', this.addToBasket);
		}

		if(this.cta) {
			this.cta.addEventListener('click', this.productDetailClick);
		}

		//
		this.el.addEventListener('mouseenter', this.onMouseEnter);
		this.el.addEventListener('mouseleave', this.onMouseLeave);
		this.image.addEventListener('click', this.productDetailClick);

		// Randomly rotate background blob cart button
		if(this.ctaBackground) {
			TweenLite.set(this.ctaBackground, {rotation: Math.random() * 360});
		}

		//
		this.tracker = options.impressionTracker;
		this.tracker.add(this.el, 'Product Tile', {
			sku: this.el.dataset.sku,
			name: this.el.dataset.name,
			position: this.getPosition(),
			price: this.el.dataset.price,
			originalPrice: this.el.dataset.originalPrice,
			category: this.el.dataset.category || null,
		});

		CartModel.updatedSignal.add(this.onCartUpdated);
		this.onCartUpdated();
	}

	destroy() {
		this.tracker.remove(this.el);
		CartModel.updatedSignal.remove(this.onCartUpdated);

		if (this.addToBasketButton) {
			this.addToBasketStepper.destroy();
		}
	}

	onCartUpdated() {
		const productData = this.getProductData();
		const amount = productData ? productData.quantity : 0;

		this.el.classList.remove(HAS_ITEMS);
		if (amount > 0) {
			this.el.classList.add(HAS_ITEMS);
		}

		if (this.cartAmount) {
			this.cartAmount.innerHTML = amount;
		}
	}

	getProductData() {
		return this.hash ?
			CartModel.getProductByHash(this.hash) :
			CartModel.getProduct(this.productId);
	}

	productDetailClick(event) {

		if(this.isPostcard) {
			this.onPostcardClick(event);
			return;
		}

		const path = window.location.pathname;
		this.signals.ecommerce.productSelected.dispatch(path, path,
			[{
				sku: this.el.dataset.sku,
				name: this.el.dataset.name,
				position: this.getPosition(),
				price: this.el.dataset.price,
				priceOriginal: this.el.dataset.priceOriginal,
				category: this.el.dataset.category || null,
			}]
		)
	}

	onMouseEnter() {
		clearTimeout(this.resetViewTimeout);
	}

	onMouseLeave() {
		clearTimeout(this.resetViewTimeout);
		this.resetViewTimeout = setTimeout(() => {
			this.el.classList.remove(SHOW_STEPPER);
			if(this.addToBasketButton) {
				this.addToBasketStepper.blurInput();
			}
		}, HIDE_STEPPER_DELAY);
	}

	onPostcardClick(event) {
		event.preventDefault();
		const imageUrl = this.image.querySelector('img').getAttribute('src')
		this.signals.products.postcardEditRequested.dispatch(this.id, imageUrl);
	}

	addToBasket(event) {
		event.preventDefault();
		if (this.addToBasketStepper) {
			const productData = this.getProductData();
			const amount = productData ? productData.quantity : 0;

			// Add 1 product to cart
			if (amount <= 0) {
				this.addToBasketStepper.plus();
			}

			this.el.classList.remove(SHOW_STEPPER);
			this.el.classList.add(SHOW_STEPPER);
		}
	}

	getPosition() {
		return [].slice.call(this.el.parentNode.children).indexOf(this.el) + 1;
	}
}

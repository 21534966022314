import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';

import OptionsList from '../propTypes/OptionsList';
import Button from '../../../reactComponents/Button/Button';
import QuantityInput from './QuantityInput';

import './selected-options-list.scss';

class SelectedOptionsList extends PureComponent {
	render() {
		return (
			<div>
				{this.props.quantities
					.sort((a, b) => a.position - b.position)
					.map(quantity => {
						const quantityValue = quantity.quantity;
						const option = this.props.options.find(option => option.hash === quantity.hash);
						if (!option) {
							return null;
						}

						return (
							<Fragment key={quantity.hash}>
								<Button
									className="selected-options-list__option"
									onClick={event => {
										event.preventDefault();

										this.props.onEdit && this.props.onEdit(option);
									}}
									disabled={this.props.disabled}
								>
									<div className="selected-options-list__option__button">
										<span className="selected-options-list__option__label">{option.label}</span>
										<span className="selected-options-list__option__quantity">{quantityValue}</span>
									</div>
								</Button>
								{this.renderQuantityInput(quantity.hash, quantityValue)}
							</Fragment>
						);
					})}
			</div>
		);
	}

	/**
	 * @param {Number} optionProductId
	 * @param {Number} quantity
	 * @returns {JSX.Element|null}
	 */
	renderQuantityInput(optionProductId, quantity) {
		if (optionProductId !== this.props.currentOptionHash) {
			return null;
		}

		if (this.props.disabled) {
			return null;
		}

		return (
			<QuantityInput
				key={optionProductId}
				value={quantity}
				onChange={this.props.onQuantityChange}
				onInputFocus={this.props.onInputFocus}
				onInputBlur={this.props.onInputBlur}
			/>
		);
	}
}

SelectedOptionsList.propTypes = {
	options: OptionsList.isRequired,
	quantities: PropTypes.arrayOf(
		PropTypes.shape({
			hash: PropTypes.string.isRequired,
			productId: PropTypes.number.isRequired,
			quantity: PropTypes.number.isRequired,
			position: PropTypes.number.isRequired,
		})
	).isRequired,
	currentOptionHash: PropTypes.string,
	disabled: PropTypes.bool,
	onEdit: PropTypes.func,
	onQuantityChange: PropTypes.func,
	onInputFocus: PropTypes.func,
	onInputBlur: PropTypes.func,
};

export default SelectedOptionsList;

import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

import './lazy-image.scss';

const LOADED_CLASS = 'lazy-image--loaded';

/**
 *
 */
export default class LazyImage extends View {

	/**
	 *
	 * @param options
	 */
	initialize(options) {

		TweenLite.set(this.el, {rotation: 2 -(4 * Math.random()) + 'deg', y:50});

		this.onLoaded = this.onLoaded.bind(this);
		if(this.isLoaded()) {
			this.onLoaded();
		} else {
			this.el.addEventListener('load', this.onLoaded);
			this.el.addEventListener('lazyloaded', this.onLoaded);
			this.el.addEventListener('appear', this.onLoaded);
		}
	}

	/**
	 *
	 */
	destroy() {
		this.el.removeEventListener('load', this.onLoaded);
		this.el.removeEventListener('lazyloaded', this.onLoaded);
		this.el.removeEventListener('appear', this.onLoaded);
	}

	/**
	 *
	 */
	onLoaded() {
		this.el.classList.toggle(LOADED_CLASS, true);
		TweenLite.to(this.el, 0.3, {rotation: 0, y: 0})
	}

	/**
	 *
	 * @return {boolean}
	 */
	isLoaded() {
		// During the onload event, IE correctly identifies any images that
		// weren’t downloaded as not complete. Others should too. Gecko-based
		// browsers act like NS4 in that they report this incorrectly.
		if (!this.el.complete) {
			return false;
		}

		// However, they do have two very useful properties: naturalWidth and
		// naturalHeight. These give the true size of the image. If it failed
		// to load, either of these should be zero.
		if (typeof this.el.naturalWidth !== 'undefined' && this.el.naturalWidth === 0) {
			return false;
		}

		// No other way of checking: assume it’s ok.
		return true;
	}

}

import DefaultPage from '../DefaultPage/DefaultPage.js';

import './checkout-gifts.scss';

export default class CheckoutGifts extends DefaultPage {

	initialize(options) {
		super.initialize(options);
	}

	destroy() {
	}
}

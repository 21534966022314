import View from 'Core/View.js';
import Dropzone from 'dropzone';

import './wrapper-upload.scss';

export default class WrapperUpload extends View {

	initialize(options) {
		this.modalDictionary = options.modalDictionary;

		this.handleRetryClicked = this.handleRetryClicked.bind(this);
		this.handleOrderClicked = this.handleOrderClicked.bind(this);
		this.handle3DPreviewClicked = this.handle3DPreviewClicked.bind(this);
		this.removeFile = this.removeFile.bind(this);
		this.handleNameFormSubmit = this.handleNameFormSubmit.bind(this);

		const data = this.el.dataset || {};

		this.progressBar = this.find('.wrapper-upload__progress__bar');
		this.retryButton = this.find('.wrapper-upload__error__retry');
		this.orderButton = this.find('.wrapper-upload__done__order');
		this.threeDPreviewButton = this.find('.wrapper-upload__done__3d-preview');
		this.errorMessage = this.find('.wrapper-upload__error__message__error');
		this.previewThumbnail = this.find('.wrapper-upload__done__image');
		this.threeDPreviewContainer = this.find('.wrapper-upload__3d-preview');
		this.threeDPreviewLoading = this.find('.wrapper-upload__3d-preview__loading');
		this.deleteForm = this.find('.wrapper-upload__done__delete');
		this.nameFormModal = this.modalDictionary['wrapper-upload-name'].el;
		this.nameInput = this.find('.wrapper-upload__name');

		this.retryButton.addEventListener('click', this.handleRetryClicked);
		this.orderButton.addEventListener('click', this.handleOrderClicked);
		this.deleteForm.addEventListener('submit', this.removeFile);
		this.nameFormModal.addEventListener('submit', this.handleNameFormSubmit);

		this.onLoaded = () => this.add3DPreviewListener();
		if (this.threeDPreviewButton) {
			if (!data.jsPath) {
				this.threeDPreviewButton.classList.add('button--hidden');
			} else {
				this.loadPreviewPlugin(data.jsPath, this.onLoaded);
			}
		}

		this.uploadedFile = null;
		this.initializeDropzone();
	}

	destroy() {
		window.removeEventListener('PreviewPlugin.load', this.onLoaded);
		this.retryButton.removeEventListener('click', this.handleRetryClicked);
		this.orderButton.removeEventListener('click', this.handleOrderClicked);
		this.deleteForm.removeEventListener('submit', this.removeFile);
		this.nameFormModal.removeEventListener('submit', this.handleNameFormSubmit);

		if (this.threeDPreviewButton) {
			this.threeDPreviewButton.removeEventListener('click', this.handle3DPreviewClicked);
		}
	}

	handleRetryClicked(event) {
		event.preventDefault();
		this.el.classList.remove('wrapper-upload--error');
	}

	handleOrderClicked(event) {
		event.preventDefault();
		this.askForWrapperName();
	}

	/**
	 *
	 * @param {String} jsPath
	 * @param callback
	 */
	loadPreviewPlugin(jsPath, callback) {
		if (typeof window.render3dPreview !== 'undefined') {
			callback();
			return;
		}

		window.addEventListener('PreviewPlugin.load', callback);
		const script = document.createElement('script');
		script.src = jsPath;
		script.type = 'module';
		script.defer = true;

		document.head.appendChild(script);
	}

	add3DPreviewListener() {
		this.threeDPreviewButton.classList.remove('button--hidden');
		this.threeDPreviewButton.addEventListener('click', this.handle3DPreviewClicked);
	}

	handle3DPreviewClicked(event) {
		event.preventDefault();

		this.modalDictionary['wrapper-upload-3d-preview'].show();

		const url = event.target.href;
		setTimeout(() => {
			window.render3dPreview(url, this.threeDPreviewContainer, 500, 375, () => {
				this.threeDPreviewLoading.style.display = 'none';
			});
		}, 500);
	}

	removeFile() {
		this.dropzone.removeFile(this.uploadedFile);
		this.uploadedFile = null;

		this.el.classList.remove('wrapper-upload--success');
	}

	askForWrapperName() {
		const modal = this.modalDictionary['wrapper-upload-name'];
		modal.show();

		this.nameInput.value = '';
		this.nameInput.focus();
	}

	handleNameFormSubmit(event) {

	}

	handleSuccessfulUpload(response) {
		if (response.redirect) {
			const url = response.redirect;
			const route = this.routes.getRoute(url);

			this.signals.pageLoad.requested.dispatch(route);
		} else {
			this.el.classList.remove('wrapper-upload--uploading');
			this.el.classList.add('wrapper-upload--success');

			// Update the urls
			if (this.threeDPreviewButton) {
				this.threeDPreviewButton.href = this.threeDPreviewButton.dataset.href.replace('!!', response.uuid);
			}
			this.deleteForm.action = this.deleteForm.dataset.action.replace('!!', response.uuid);
			this.nameFormModal.action = this.nameFormModal.dataset.action.replace('!!', response.uuid);

			// Update the preview image
			this.previewThumbnail.src = response.thumbnail;
		}
	}

	updateProgress(percentageInInts) {
		this.progressBar.style.width = percentageInInts + '%';
	}

	reset() {
		this.errorMessage.innerHTML = '';
		this.updateProgress(0);
	}

	initializeDropzone() {
		this.dropzone = new Dropzone(this.find('.wrapper-upload__file-catcher'), {
			// The name that will be used to transfer the file
			paramName: 'file',
			maxFilesize: 32,
			createImageThumbnails: false,
			clickable: true,
			timeout: 180000,
			accept: (file, done) => {
				if (!file.name.match(/\.pdf$/i)) {
					done(this.el.dataset.transInvalidFiletype);
				} else {
					done();
				}
			},
			headers: {
				'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
				'X-Requested-With': 'XMLHttpRequest',
			},
			dictFileTooBig: this.el.dataset.transTooBig,
			dictInvalidFileType: this.el.dataset.transInvalidFiletype,

			dragenter: () => {
				this.el.classList.remove('wrapper-upload--error');
				this.el.classList.add('wrapper-upload--drag-over');
			},

			dragleave: () => {
				this.el.classList.remove('wrapper-upload--drag-over');
			},

			drop: () => {
				this.reset();
				this.el.classList.remove('wrapper-upload--drag-over');
				this.el.classList.add('wrapper-upload--uploading');
			},

			addedfile: (file) => {
				this.uploadedFile = file;
				this.el.classList.add('wrapper-upload--uploading');
			},

			uploadprogress: (file, percentage) => {
				this.updateProgress(percentage);
			},

			error: (file, response) => {
				this.errorMessage.innerHTML = this.getErrorMessage(response);

				this.el.classList.remove('wrapper-upload--uploading');
				this.el.classList.add('wrapper-upload--error');
			},

			success: (file, response) => {
				this.handleSuccessfulUpload(response);
			},
		});
	}

	getErrorMessage(response) {
		if (typeof response === 'string') {
			return response;
		}

		const keys = Object.keys(response);
		if (keys.length === 0) {
			return 'Unknown error';
		}

		return this.getErrorMessage(response[keys[0]]);
	}

}

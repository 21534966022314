import DefaultPage from '../DefaultPage/DefaultPage.js';
import HowItStartedSlider from '../../components/HowItStartedSlider/HowItStartedSlider.js';
import './how-it-started.scss';
import StupidPageLoadService from '../../../services/StupidPageLoadService';

const HOW_IT_STARTED_LOADING_CLASS = 'how-it-started--loading';

export default class HowItStarted extends DefaultPage {

	initialize(options) {
		super.initialize();

		this.onYearChanged = this.onYearChanged.bind(this);
		this.onResize = this.onResize.bind(this);
		this.onStartDrag = this.onStartDrag.bind(this);

		this.widgetContainerElement = this.find('.how-it-started__content-widgets');
		this.slider = new HowItStartedSlider({
			...options,
			el: this.find('.how-it-started-slider')
		});

		this.slider.yearChanged.add(this.onYearChanged);
		this.slider.startDrag.add(this.onStartDrag);
		this.screen.resized.add(this.onResize);

		this.pageLoader = new StupidPageLoadService({
			onError: this.handlePageLoadError.bind(this),
			onSuccess: this.handlePageLoaded.bind(this)
		});

		this.onYearChanged(this.slider.selected);
	}

	onResize() {
		this.slider.onResize();
	}

	onStartDrag() {
		this.hide(true);
		this.destroyChildViews(this.widgetContainerElement);
	}

	onYearChanged(yearData) {
		const url = yearData.closest.dataset.url;
		this.loadContent(url);
	}

	loadContent(url) {

		this.signals.loading.started.dispatch('how-it-started');

		this.destroyChildViews(this.widgetContainerElement);

		this.el.classList.add(HOW_IT_STARTED_LOADING_CLASS);
		this.pageLoader.load(url);
	}

	handlePageLoaded(html) {

		this.signals.loading.completed.dispatch('how-it-started');

		this.widgetContainerElement.innerHTML = html;
		this.isLoaded = true;
		this.el.classList.remove(HOW_IT_STARTED_LOADING_CLASS);

		this.initializeChildViews(this.widgetContainerElement);

		// Prepare "in" animation
		this.initializeWidgets();
		this.show(true);
	}

	handlePageLoadError(error) {

		this.signals.loading.failed.dispatch('how-it-started');

		this.el.classList.remove(HOW_IT_STARTED_LOADING_CLASS);
	}

	destroy() {
		this.slider.destroy();
		super.destroy();
	}

}
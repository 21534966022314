import Signal from 'signals';
import TweenLite from 'gsap/TweenLite';
import View from 'Core/View';
import Spritesheet from '../../components/Spritesheet/Spritesheet.js';
import StupidPageLoadService from '../../../services/StupidPageLoadService';
import ClassBasedAnimation from './ClassBasedAnimation';

const MISSION_SECTION_LOADING_CLASS = 'mission__section--loading';

export default class MissionSection extends View {

	initialize(options) {
		this.id = this.el.getAttribute('id');

		this.isFlipped = this.el.classList.contains('mission__section--flipped');
		this.isLoaded = false;

		this.expandSignal = new Signal();
		this.expandedSignal = new Signal();
		this.collapsedSignal = new Signal();

		this.graphicStartScale = 1;
		this.graphicStartOffsetX = -64;
		this.graphicStartOffsetY = 0;

		this.storyStartOffsetX = 0;//64;
		this.storyStartOffsetY = 0;

		this.viewButtonStartOffsetY = 0;
		this.titleStartOffsetY = 0;//-64;

		this.scroll = this.scroll.bind(this);
		this.resize = this.resize.bind(this);
		this.onViewMoreClick = this.onViewMoreClick.bind(this);
		this.onCloseClick = this.onCloseClick.bind(this);

		this.viewMoreButton = this.find('.mission__view-more-button');
		this.closeButton = this.find('.mission__content-close-button ');
		this.contentElement = this.find('.mission__content');
		this.contentWrapperElement = this.find('.mission__content-wrapper');
		this.graphicElement = this.find('.mission__graphic');
		this.storyContainerElement = this.find('.mission__story-container');
		this.widgetContainerElement = this.find('.mission__content-widgets');
		this.titleElement = this.find('.mission__story-title');

		const viewEl = this.find('.mission__view');
		if (viewEl) {
			this.animation = new ClassBasedAnimation({
				...options,
				el: viewEl,
				steps: viewEl.dataset.frames,
			});
		} else {
			this.animation = new Spritesheet(this.find('.mission__spritesheet'), 3, 2);
		}

		this.viewMoreButton.addEventListener('click', this.onViewMoreClick);
		this.closeButton.addEventListener('click', this.onCloseClick);

		window.addEventListener('scroll', this.scroll);
		window.addEventListener('resize', this.resize);

		this.pageLoader = new StupidPageLoadService({
			onError: this.handlePageLoadError.bind(this),
			onSuccess: this.handlePageLoaded.bind(this)
		});

		this.blur(true, 0);
		this.scroll(null, true);
	}

	destroy() {
		window.removeEventListener('scroll', this.scroll);
		window.removeEventListener('resize', this.resize);

		this.viewMoreButton.removeEventListener('click', this.onViewMoreClick);
		this.closeButton.removeEventListener('click', this.onCloseClick);

		this.destroyChildViews(this.widgetContainerElement);
	}

	focus(force = false, duration = 0.6) {
		if ((! this.isFocussed) || force) {
			this.isFocussed = true;

			TweenLite.killDelayedCallsTo(this.graphicElement);
			TweenLite.to(this.graphicElement, duration, {
				scaleX: 1,
				scaleY: 1,
				x: 0,
				y: 0,
				ease: Cubic.easeInOut,
				onComplete: () => {
				}
			});

			TweenLite.killDelayedCallsTo(this.storyContainerElement);
			TweenLite.to(this.storyContainerElement, duration, {
				x: 0,
				y: 0,
				ease: Cubic.easeInOut
			});

			TweenLite.killTweensOf(this.viewMoreButton);
			TweenLite.to(this.viewMoreButton, duration, {opacity: 1, y: 0, delay: 0.5});

			TweenLite.killTweensOf(this.titleElement);
			TweenLite.to(this.titleElement, duration, {opacity: 1, y: 0});
		}
	}

	blur(force = false, duration = 0.3) {
		if (this.isFocussed || force) {
			this.isFocussed = false;
			const isMobile = window.innerWidth < 768;

			TweenLite.killDelayedCallsTo(this.graphicElement);
			TweenLite.to(this.graphicElement, duration, {
				scaleX: this.graphicStartScale,
				scaleY: this.graphicStartScale,
				x: this.graphicStartOffsetX * (this.isFlipped ? -1 : 1) * (isMobile ? 0 : 1),
				y: this.graphicStartOffsetY,
				ease: Cubic.easeInOut,
				onComplete: () => {
				}
			});

			TweenLite.killDelayedCallsTo(this.storyContainerElement);
			TweenLite.to(this.storyContainerElement, duration, {
				x: this.storyStartOffsetX * (this.isFlipped ? -1 : 1) * (isMobile ? 0 : 1),
				y: this.storyStartOffsetY,
				ease: Cubic.easeInOut
			});

			TweenLite.killTweensOf(this.viewMoreButton);
			TweenLite.to(this.viewMoreButton, duration, {opacity: 0, y: this.viewButtonStartOffsetY});

			TweenLite.killTweensOf(this.titleElement);
			TweenLite.to(this.titleElement, duration, {y: this.titleStartOffsetY});
		}
	}

	expand() {
		if (! this.el.classList.contains('expanded')) {
			this.el.classList.add('expanded');
			const toHeight = this.getContentHeight();

			this.contentElement.style.visibility = 'visible';

			TweenLite.killTweensOf(this);
			TweenLite.set(this.contentElement, {height: 1});
			TweenLite.to(this.contentElement, 0.6, {
				height: toHeight, ease: Cubic.easeInOut,
				onComplete: this.onExpanded, onCompleteScope: this
			});

			this.expandSignal.dispatch(this);
		}
	}

	onExpanded() {
		this.contentElement.style.height = 'auto';
		this.expandedSignal.dispatch(this);
	}

	collapse() {
		if (this.el.classList.contains('expanded')) {
			this.el.classList.remove('expanded');

			TweenLite.killTweensOf(this);
			TweenLite.to(this.contentElement, 0.6, {
				height: 0, ease: Cubic.easeInOut,
				onComplete: this.onCollapsed, onCompleteScope: this
			});
		}
	}

	onCloseClick(event) {
		event.preventDefault();

		this.toggleContent();
	}

	onViewMoreClick(event) {

		if (event) {
			event.preventDefault();
		}

		if (! this.isLoaded) {
			const url = this.viewMoreButton.dataset.url;
			this.loadContent(url);
		} else {
			this.toggleContent();
		}
	}

	toggleContent() {
		if (this.el.classList.contains('expanded')) {
			this.collapse();
		} else {
			this.expand();
		}
	}

	onCollapsed() {
		this.contentElement.style['visibility'] = 'hidden';
		this.collapsedSignal.dispatch(this);
	}


	update() {
		if (this.el.classList.contains('expanded')) {
			this.expand();
		} else {
			this.collapse();
		}
	}

	getContentHeight() {
		return this.contentWrapperElement.scrollHeight;
	}

	scroll(event, force = false) {
		const rect = this.el.getBoundingClientRect();

		if (rect.bottom < 0 || rect.top > window.innerHeight) {
			this.blur(force);
		} else if (rect.top < window.innerHeight * 0.75) {
			this.focus(force);

			const min = window.innerHeight * 0.25;
			const max = window.innerHeight * 0.75;
			const val = (rect.top - min) / (max - min);
			const percentage = 1 - Math.max(val, 0);

			this.animation.setPercentage(percentage);
		}
	}

	loadContent(url) {
		this.signals.loading.started.dispatch('mission-section');

		this.viewMoreButton.classList.add('button--loading');
		this.el.classList.add(MISSION_SECTION_LOADING_CLASS);
		this.pageLoader.load(url);
	}

	handlePageLoaded(html) {
		this.signals.loading.completed.dispatch('mission-section');

		this.viewMoreButton.classList.remove('button--loading');
		this.widgetContainerElement.innerHTML = html;
		this.isLoaded = true;
		this.el.classList.remove(MISSION_SECTION_LOADING_CLASS);

		this.initializeChildViews(this.widgetContainerElement);

		this.toggleContent();
	}

	handlePageLoadError(error) {
		this.signals.loading.failed.dispatch('mission-section');

		this.viewMoreButton.classList.remove('button--loading');
		this.el.classList.remove(MISSION_SECTION_LOADING_CLASS);
	}

	resize() {
		this.scroll(true);
	}
}

import View from 'Core/View.js';

export default class CrashNotifier extends View {

	initialize(options) {
		this.modal = options.modalDictionary['crash-notifier'];
		this.handleFormCrash = this.handleFormCrash.bind(this);

		this.signals.forms.crashed.add(this.handleFormCrash);
	}

	destroy() {
		this.signals.forms.crashed.remove(this.handleFormCrash);
	}

	handleFormCrash(/*formId*/) {
		this.modal.show();
	}
}
const safelyHandleResizeObserver = callback => {
	return (entries, observer) => {
		/**
		 * We wrap it in requestAnimationFrame to avoid the
		 * "ResizeObserver loop limit exceeded" error
		 */
		window.requestAnimationFrame(() => {
			if (!Array.isArray(entries) || entries.length === 0) {
				return;
			}

			callback(entries, observer);
		});
	};
};

export default safelyHandleResizeObserver;

import View from 'Core/View';

import './subscription-status.scss';

export default class SubscriptionStatus extends View {

    initialize() {

    }

    destroy() {
    }
}

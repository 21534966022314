import View from 'Core/View.js';
import createEvent from 'utils/event/create.js';
import './address-item.scss';

export default class AddressItem extends View {

	initialize() {
		this.handleRemoveClick = this.handleRemoveClick.bind(this);

		this.removeButton = this.find('.address-item__remove');

		// The default address doesn't have a remove button
		if (this.removeButton) {
			this.removeButton.addEventListener('click', this.handleRemoveClick);

			this.removeForm = this.find('.address-item__remove-form');
		}
	}

	destroy() {
		if (this.removeButton) {
			this.removeButton.removeEventListener('click', this.handleRemoveClick);
		}
	}

	handleRemoveClick(event) {
		event.preventDefault();

		this.removeForm.dispatchEvent(createEvent('submit'));
	}
}
import View from 'Core/View.js';
import './checkbox.scss';
import Icon from '!raw-loader!img-loader!./checkbox.svg';

export default class Checkbox extends View {

	initialize() {
		this.el.classList.add('checkbox--replaced');

		this.insertSVG();
	}

	destroy() {
	}

	insertSVG() {
		const svg = document.createElement('div');
		svg.className = 'checkbox__svg';
		svg.innerHTML = Icon;

		this.el.insertBefore(svg, this.find('.checkbox__input').nextSibling);
	}
}
import 'intersection-observer';

import './jaarFairslag.scss';

import View from 'Core/View.js';
import Chapter from './components/Chapter/Chapter';
import Submenu from './components/Submenu/Submenu';
import Navigation from './components/Navigation/Navigation';
import NavigationTop from './components/NavigationTop/NavigationTop';
import ScrollManager from './components/utils/ScrollManager';
import SplashManager from './components/utils/SplashManager';
import ChapterLoadService from './components/utils/ChapterLoadService';

import { getDeeplinkDataForWindowLocation } from './components/utils/Helpers';
import ChapterImageLoader from './components/utils/ChapterImageLoader';

export default class JaarFairslag extends View {
	initialize(options) {
		this.setupUI();

		this.scrollManager = new ScrollManager(options);
		this.chapterLoadService = new ChapterLoadService();
		if (this.ui.navigation) {
			this.navigation = new Navigation({ ...options, el: this.ui.navigation });
		} else if (this.ui.navigationTop) {
			this.navigation = new NavigationTop({ ...options, el: this.ui.navigationTop });
		}

		this.splashManager = new SplashManager({ el: this.ui.chapterSplashes });

		this.chapterImageLoader = new ChapterImageLoader();

		this.setupSubmenus(options);
		this.setupEventListeners();

		this.deeplink();

		this.initializeChapters(options);
	}

	destroy() {
		this.removeEventListeners();

		this.scrollManager.destroy();
		this.chapterLoadService.destroy();
		this.splashManager.destroy();
		this.navigation.destroy();
		this.chapterImageLoader.destroy();

		this.submenus.forEach(submenu => submenu.destroy());
		this.chapters.forEach(chapter => chapter.destroy());

		if (this.resizeThrottle) window.clearTimeout(this.resizeThrottle);
		if (this.scrollThrottle) window.clearTimeout(this.scrollThrottle);
	}

	setupUI() {
		this.ui = {
			chapterSplashes: this.el.querySelector('.js-chapter-splashes'),
			navigation: this.el.querySelector('.js-navigation'),
			navigationTop: this.el.querySelector('.js-navigation-top'),
			chapters: [...this.el.querySelectorAll('.js-chapter')],
			submenus: [...this.el.querySelectorAll('.js-submenu')],
		};
	}

	setupEventListeners() {
		this.handleResize = this.handleResize.bind(this);
		this.handleSubmenuClicked = this.handleSubmenuClicked.bind(this);
		this.handleNavigationClicked = this.handleNavigationClicked.bind(this);
		this.handleScrollPositionChanged = this.handleScrollPositionChanged.bind(this);
		this.handleChapterSizeChange = this.handleChapterSizeChange.bind(this);
		this.handleDeeplinkLoadError = this.handleDeeplinkLoadError.bind(this);
		this.handleDeeplinkLoadSuccess = this.handleDeeplinkLoadSuccess.bind(this);
		this.handleHashChange = this.handleHashChange.bind(this);
		this.handlePageLoadRequested = this.handlePageLoadRequested.bind(this);

		this.signals.windowResized.add(this.handleResize);
		this.navigation.signals.navigationClicked.add(this.handleNavigationClicked);
		this.scrollManager.signals.scrolled.add(this.handleScrollPositionChanged);

		this.submenus.forEach(submenu => submenu.signals.subMenuClicked.add(this.handleSubmenuClicked));

		window.addEventListener('hashchange', this.handleHashChange);

		this.signals.pageLoad.requested.add(this.handlePageLoadRequested);
	}

	removeEventListeners() {
		this.signals.windowResized.remove(this.handleResize);
		this.navigation.signals.navigationClicked.remove(this.handleNavigationClicked);

		this.submenus.forEach(submenu => submenu.signals.subMenuClicked.remove(this.handleSubmenuClicked));
	}

	setupSubmenus(options) {
		this.submenus = [];
		this.ui.submenus.forEach(el => {
			const submenu = new Submenu({ ...options, el });
			this.submenus.push(submenu);
		});
	}

	initializeChapters(options) {
		this.chapters = new Map();
		for (let i = 0; i < this.ui.chapters.length; i++) {
			const el = this.ui.chapters[i];
			const chapterView = new Chapter({
				...options,
				el,
				id: el.dataset.chapterId,
				splashType: el.dataset.chapterSplashType,
				splashManager: this.splashManager,
				scrollManager: this.scrollManager,
				chapterLoadService: this.chapterLoadService,
				sizeChangeHandler: this.handleChapterSizeChange,
			});

			this.chapters.set(el.dataset.chapterId, chapterView);

			this.scrollManager.registerElement(el.dataset.chapterId, el, chapterView =>
				this.handleItemInView(chapterView)
			);
		}
	}

	deeplink() {
		this.deeplinkData = getDeeplinkDataForWindowLocation(window.location, this.el.dataset.baseUrl);

		if (this.deeplinkData) {
			window.history.replaceState(null, null, `${this.el.dataset.baseUrl}#${this.deeplinkData.chapterId}/${this.deeplinkData.elementId || ''}`)
			this.deeplinking = true;
			const url = this.el.dataset.chapterUrl.replace('%s', this.deeplinkData.chapterId);

			// NOTE: add the slash for the ID here
			this.chapterLoadService.load(
				`${this.deeplinkData.chapterId}`,
				url,
				this.handleDeeplinkLoadSuccess,
				this.handleDeeplinkLoadError
			);
		}
	}

	scrollToChapter(el) {
		if (el) {
			this.scrolling = true;
			const duration = Math.min(Math.abs(el.getBoundingClientRect().top / 4000), 2);
			this.scrollManager.scrollToElement(el, duration, el => this.handleScrollToEnded(el));
		}
	}

	toggleChapters() {
		if (this.scrolling || this.deeplinking) return;

		for (var [id, el] of this.chapters) {
			if (id === this.activeChapterId) {
				el.loadAndShow();
			} else {
				el.hide();
			}
		}
	}

	resize(options) {
		this.resizeThrottle = null;
		this.scrollManager.updateScollPositionsOnResize();
		this.splashManager.updateResize(options);
	}

	handleNavigationClicked(options) {
		this.currentChapter = this.chapters.get(options.chapterPath);
		this.currentChapter.load();
		this.scrollToChapter(this.currentChapter.el);
	}

	handleItemInView(chapter) {
		if (chapter.id !== this.activeChapterId) {
			this.activeChapterId = chapter.id;

			this.currentChapter = this.chapters.get(chapter.id);
			if (!this.currentChapter) return;

			this.toggleChapters();

			this.navigation.highlightChapterWithId(this.currentChapter.id);
		}

		this.splashManager.update(chapter);
	}

	// NOTE: Also called when interrupted
	handleScrollToEnded(completed) {
		this.scrolling = false;
		this.toggleChapters();
	}

	handleScrollPositionChanged(y) {
		this.splashManager.updateScrollPosition(y);
		this.navigation.updateScrollPosition(y);
	}

	handleChapterSizeChange() {
		this.scrollManager.reCalculateOffsets();
	}

	handleDeeplinkLoadSuccess() {
		const chapter = this.chapters.get(this.deeplinkData.chapterId);
		if (!chapter) {
			console.log('[TONY] Cannot find chapter by slug ' + this.deeplinkData.chapterId);
			return;
		}

		let el = chapter.el;

		if (this.deeplinkData.elementId && this.deeplinkData.elementId.length) {
			const images = el.querySelectorAll('img');
			const elementInsideChapter = chapter.el.querySelector(`#${this.deeplinkData.elementId}`);
			if (elementInsideChapter) {
                el = elementInsideChapter;
            }
			this.chapterImageLoader.loadImagesForChapter(images, el.id, () => {
				window.setTimeout(() => this.scrollToDeeplinkedEl(el), 300);
			});
			return;
		}

		this.scrollThrottle = window.setTimeout(() => this.scrollToDeeplinkedEl(el), 300);
	}

	scrollToDeeplinkedEl(scrollToElement) {
		this.scrollThrottle = null;

		this.scrollManager.scrollToElement(scrollToElement, 2, () => {
			this.deeplinking = false;
		});
	}

	handleSubmenuClicked(options) {
		const subChapter = this.el.querySelector(`[data-subchapter-id='${options.id}']`);
		if (!subChapter) {
			console.log('[TONY] Cannot find chapter by id ' + options.id);
			return;
		}

		this.scrollManager.scrollToElement(subChapter, 1);
	}

	handleDeeplinkLoadError() {
		this.deeplinking = false;
	}

	handleResize(options) {
		if (this.resizeThrottle) {
			clearTimeout(this.resizeThrottle);
		}

		// NOTE: Resizing is throttled because the menu on the top changes the scrollX position
		// However this is needed to calculate the correct offset in ScrollManager :: reCalculateOffsets();
		this.resizeThrottle = window.setTimeout(() => this.resize(options), 300);
	}

	handleHashChange() {
		this.deeplink();
	}

	handlePageLoadRequested() {
		this.deeplink();
	}
}

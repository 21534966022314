import DefaultPage from '../DefaultPage/DefaultPage.js';

import './assortment-category.scss';

const CLASS_TOGGLED = 'assortment-category__product--toggled';

export default class AssortmentCategory extends DefaultPage {

	initialize() {
		super.initialize();

		this.items = this.findAll('.assortment-category__product');
		this.columns = this.findAll('.assortment-category__column');

		this.resize = this.resize.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.currentCard = null;

		this.el.addEventListener('click', this.handleClick);
		this.signals.windowResized.add(this.resize);

		this.resize();
	}

	destroy() {
		super.destroy();

		this.el.removeEventListener('click', this.handleClick);
		this.signals.windowResized.remove(this.resize);
	}

	handleClick(event) {
		if (this.currentCard) {
			this.currentCard.classList.remove(CLASS_TOGGLED);
		}

		const newCard = this.findCard(event.target)
		if (this.currentCard === newCard) {
			this.currentCard = null;
			return;
		}

		this.currentCard = newCard;
		if (this.currentCard) {
			this.currentCard.classList.add(CLASS_TOGGLED);
		}
	}

	findCard(el) {
		while (el !== this.el) {
			if (el.classList.contains('assortment-category__product')) {
				return el;
			}

			el = el.parentNode;
		}

		return null;
	}

	resize() {

		// Calculate visible columns based on available width of screen
		const aw = window.innerWidth;
		const columns = aw < 1024 ? (aw < 768 ? 1 : 2) : 3;

		// Toggle column visibility
		this.columns.forEach((column, index) => {
			if (index + 1 > columns) {
				column.classList.add('assortment-category__column--hidden');
			} else {
				column.classList.remove('assortment-category__column--hidden');
			}
		});

		// Distribute items over visible columns
		this.items.forEach((item, index) => {
			this.columns[index % columns].appendChild(item);
		});

		// Calculate sizes of items
		this.items.forEach((item, index) => {

			item.classList.remove('assortment-categeory__product--small');
			item.classList.remove('assortment-categeory__product--large');
			item.classList.remove('assortment-categeory__product--huge');

			const iw = item.clientWidth;

			if (iw <= 360) {
				item.classList.add('assortment-categeory__product--small');
			}

			if (iw >= 500) {
				item.classList.add('assortment-categeory__product--huge');
			} else if (iw >= 440) {
				item.classList.add('assortment-categeory__product--large');
			}
		});

	}
}
import View from 'Core/View.js';

import './shop-related-products.scss';

export default class ShopRelatedProducts extends View {

	initialize(options) {
		this.options = options;
	}

	destroy() {}
}

import View from 'Core/View.js';

import StupidPageLoadService from '../../../services/StupidPageLoadService';

import NewsOverviewFilters from './NewsOverviewFilters.js';
import NewsGrid from './components/NewsGrid/NewsGrid.js';

import './news-overview.scss';

const CONTAINER_SELECTOR = '.news-overview__news';
const LOADING_CLASS = 'news-overview__news--loading';

export default class NewsOverview extends View {

	initialize(options) {
		this.updateFilters = this.updateFilters.bind(this);
		this.handlePaginatorClick = this.handlePaginatorClick.bind(this);

		this.container = this.find(CONTAINER_SELECTOR);

		this.pageLoader = new StupidPageLoadService({
			onError: this.handlePageLoadError.bind(this),
			onSuccess: this.handlePageLoaded.bind(this)
		});

		this.refreshPaginatorButtonReferences();

		this.options = options;
		this.page = 1;
		this.shouldAppendGrid = false;
		this.filters = {};

		this.overviewFilters = new NewsOverviewFilters({...options, el: this.find('.news-overview__filters')});
		this.overviewFilters.changed.add(this.updateFilters);
		this.grid = this.createGrid(this.el);
	}

	destroy() {

		if (this.paginatorButton) {
			this.paginatorButton.removeEventListener('click', this.handlePaginatorClick);
		}

		this.overviewFilters.changed.remove(this.update);
		this.overviewFilters.destroy();
		this.grid.destroy();
	}

	updateFilters(filters) {
		this.filters = filters;
		this.page = 1;
		this.update();
	}

	updatePage(page) {
		this.page = page;
		this.shouldAppendGrid = true;
		this.update();
	}

	update() {
		this.container.classList.add(LOADING_CLASS);
		this.signals.loading.started.dispatch('news');

		if (!this.shouldAppendGrid) {
			this.removeGrid();
		}

		const url = this.buildURLFromFilters(this.filters);
		this.pageLoader.load(url);
	}

	handlePageLoadError(error) {
		this.container.classList.remove(LOADING_CLASS);
		this.signals.loading.failed.dispatch('news');
		this.signals.toaster.requested.dispatch(error);
		this.shouldAppendGrid = false;
	}

	handlePageLoaded(html) {
		this.container.classList.remove(LOADING_CLASS);
		this.signals.loading.completed.dispatch('news');

		let newHtml = this.getContainerContentsFromHtml(html);
		newHtml = `<div class="news-overview__new-section">${newHtml}</div>`;

		if (this.shouldAppendGrid) {

			// Remove existing paginator
			const paginator = this.find('.paginator');
			paginator.parentNode.removeChild(paginator);

			// Append to the container
			this.container.insertAdjacentHTML('beforeend', newHtml);

		} else {
			this.container.innerHTML = newHtml;
		}

		const newSection = this.container.querySelector('.news-overview__new-section:last-child');
		this.grid = this.createGrid(newSection);

		this.refreshPaginatorButtonReferences();
		this.shouldAppendGrid = false;
	}

	handlePaginatorClick(event) {
		event.preventDefault();

		const route = this.routes.getRoute(this.paginatorButton.href);
		this.updatePage(route.query.page);
	}

	getContainerContentsFromHtml(html) {
		const div = document.createElement('div');
		div.innerHTML = html;

		const newContainer = div.querySelector(CONTAINER_SELECTOR);
		if (newContainer) {
			return newContainer.innerHTML;
		}

		return html;
	}

	buildURLFromFilters(filters) {
		const url = this.el.dataset.url + '?';

		const parts = [];
		if (filters.category) {
			parts.push('category=' + filters.category);
		}

		if (filters.period) {
			parts.push('year=' + filters.period);
		}

		if (filters.search) {
			parts.push('search=' + filters.search);
		}

		parts.push('page=' + this.page);

		return url + parts.join('&');
	}

	refreshPaginatorButtonReferences() {
		if (this.paginatorButton) {
			this.paginatorButton.removeEventListener('click', this.handlePaginatorClick);
		}

		this.paginatorButton = this.find('.paginator__more-button');
		if (this.paginatorButton) {
			this.paginatorButton.addEventListener('click', this.handlePaginatorClick);
		}
	}

	removeGrid(callback) {
		if (! this.grid) {
			if (callback) {
				callback();
			}
			return;
		}

		if (callback) {
			this.grid.hidden.addOnce(callback);
		}

		this.grid.hideTiles();
		this.grid = null;
	}

	createGrid(container) {
		if (! container) {
			return null;
		}

		return new NewsGrid({...this.options, el: container.querySelector('.news-grid')});
	}
}

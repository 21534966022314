import View from '../../../shared/Core/View';
import {allowCookies} from '../../../services/CookieConsent';

import './cookie-settings-modal.scss';

const COOKIE_DETAIL_EXPANED_CLASS = 'cookie-settings-modal__details--expanded';

export default class CookieSettingsModal extends View {

	initialize() {
		this.onAllowSelection = this.onAllowSelection.bind(this);
		this.onAllowAll = this.onAllowAll.bind(this);

		this.inputs = this.findAll('.switch input');
		this.allowSelectionButton = this.find('.cookie-settings-modal__allow-selection');
		this.allowAllButton = this.find('.cookie-settings-modal__allow-all');
		this.consentDetails = this.find('.cookie-settings-modal__consent-details');
		this.consentDetailsDate = this.find('.cookie-settings-modal__consent_date');

		this.detailSections = this.findAll('.cookie-settings-modal__details h3');

		this.updateSwitches();

		this.allowSelectionButton.addEventListener('click', this.onAllowSelection);
		this.allowAllButton.addEventListener('click', this.onAllowAll);

		this.detailSections.forEach((toggle) => {
			toggle.addEventListener('click', (event) => {
				event.currentTarget.parentElement.classList.toggle(COOKIE_DETAIL_EXPANED_CLASS);
			});
		});
	}

	onAllowAll(event) {
		event.preventDefault();

		allowCookies({
			functional: true,
			analytics: true,
			marketing: true,
		});

		window.location.reload();
	}

	onAllowSelection(event) {
		event.preventDefault();

		const allowedCookies = {};
		this.inputs.forEach((input) => {
			allowedCookies[input.value] = input.checked;
		});

		allowCookies(allowedCookies);

		window.location.reload();
	}

	updateSwitches() {
		// Don't update when there are no settings yet
		if (!window.tonysCookieConsent.functional) {
			return;
		}

		this.inputs.forEach((input) => {
			input.checked = window.tonysCookieConsent[input.value];

			// Trigger change event so Switch component picks up on change
			const event = document.createEvent('HTMLEvents');
			event.initEvent('change', false, true);
			input.dispatchEvent(event);
		});

		const consentDate = window.tonysCookieConsent.consentTimestamp;
		if (consentDate) {
			this.consentDetails.classList.add('cookie-settings-modal__consent-details--visible');
			const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

			const date = new Date(consentDate);
			this.consentDetailsDate.innerHTML = date.toLocaleDateString(window.tonysConfig.locale, options) + ', ' + date.toLocaleTimeString(window.tonysConfig.locale);
		}
	}

	destroy() {
	}
}

import View from '../../../shared/Core/View';
import createCookie from '../../../shared/utils/cookie/createCookie';
import readCookie from '../../../shared/utils/cookie/readCookie';

import isDescendantOf from '../../../shared/utils/dom/isDescendantOf';

import './pro-designer.scss';

const CLASS_DISABLED = 'pro-designer--disabled';
const CLASS_HIDDEN = 'pro-designer--hide';

export default class ProDesigner extends View {
	initialize() {
		this.handleProDesignerClick = this.handleProDesignerClick.bind(this);
		this.handleWrapperCreatedMounted = this.handleWrapperCreatedMounted.bind(this);
		this.handlePageClick = this.handlePageClick.bind(this);

		this.el.addEventListener('click', this.handleProDesignerClick);
		document.documentElement.addEventListener('click', this.handlePageClick);
		window.addEventListener('WrapperCreator.mount', this.handleWrapperCreatedMounted);

		setTimeout(this.initialShow, 1000);
	}

	destroy() {
		this.el.removeEventListener('click', this.handleProDesignerClick);
		document.documentElement.removeEventListener('click', this.handlePageClick);
		window.removeEventListener('WrapperCreator.mount', this.handleWrapperCreatedMounted);
	}

	initialShow() {
		if (!this.el) return;
		
		this.el.classList.remove(CLASS_DISABLED);

		if (readCookie('pro_designer') === 'dismissed') 
			return;
			
		if (this.screen.isOneOf(['mobile', 'tablet']))
			return;

		this.show();
	}

	show() {
		this.el.classList.remove(CLASS_HIDDEN);
	}

	hide() {
		this.el.classList.add(CLASS_HIDDEN);
		createCookie('pro_designer', 'dismissed', 30);
	}

	handleProDesignerClick() {
		this.el.classList.toggle(CLASS_HIDDEN);
	}

	handleWrapperCreatedMounted() {
		this.initialShow();
	}

	handlePageClick(event) {
		if (isDescendantOf(event.target, this.el)) {
			return;
		}

		this.hide();
	}
}

import BaseSplashView from './BaseSplash';

import {TimelineMax, Power2} from 'gsap/all';
import 'gsap/AttrPlugin';

export default class SplashDefault extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		// this.setupUI();
		// this.setupTimeline();

	}

	setupUI() {

		this.ui = {
			test: this.el.querySelector('rect')
		}

	}

	update(options) {

		// TweenLite.to(this.tl, options.duraton, {progress: options.progress});

	}

	setupTimeline() {

		this.tl = new TimelineMax({paused: true});
		this.tl.to(this.ui.test, 1, {scale: 0.3, ease: Power2.easeInOut});

	}

}

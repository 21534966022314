import createRequest from 'superagent';

import initializeTracking from '../../tracking';

import createCookie from '../shared/utils/cookie/createCookie';
import readCookie from '../shared/utils/cookie/readCookie';

const cookieName = 'tonys_cookie_consent';

export const allowCookies = permissions => {
	const parsedPermissions = {
		functional: !!permissions['functional'],
		analytics: !!permissions['analytics'],
		marketing: !!permissions['marketing'],
	};

	placeCookie(parsedPermissions);

	postCookieChoice({
		...parsedPermissions,
		choice: 'accepted',
	});

	initializeTracking();
};

export const declineCookies = () => {
	const permissions = {
		functional: true,
		analytics: false,
		marketing: false,
	};

	placeCookie(permissions);

	postCookieChoice({
		...permissions,
		choice: 'declined',
	});
};

const placeCookie = permissions => {
	permissions['consentTimestamp'] = new Date().getTime();

	window.tonysCookieConsent = permissions;

	createCookie(cookieName, JSON.stringify({ ...permissions }), 365);
};

const postCookieChoice = permissions => {
	if (!window.tonysConfig.cookieStoreUrl) {
		console.warn('[TONYS] No cookie store URL set in config. Cookie choice will not be stored.');
		return;
	}

	const request = createRequest('POST', window.tonysConfig.cookieStoreUrl);
	request.set('Accept', 'application/json');
	request.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
	request.set('X-Requested-With', 'XMLHttpRequest');
	request.send(permissions);
	request.then(() => {}, err => console.log(err));
};

const read = () => {
	const cookie = readCookie(cookieName);

	return cookie ? JSON.parse(cookie) : {};
};

window.tonysCookieConsent = read();

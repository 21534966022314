import View from '../../../shared/Core/View';
import {addZeros} from '../../../shared/utils/Formatting';

export default class DatePicker extends View {

    initialize() {
    	this.handleDateChanged = this.handleDateChanged.bind(this);

    	this.defaultLabel = this.el.dataset.label;

		this.buttonEl = this.find('.date-picker__button');
		this.labelEl = this.find('.date-picker__button__label');
		this.pickerContainer = this.find('.date-picker__date-picker-container');
		this.input = this.find('.date-picker__input');

		this.labelEl.innerHTML = this.defaultLabel;

		this.datePicker = this.findViewForElement(this.find('.date-picker__modal'), '.date-picker-modal');
		this.datePicker.onChange.add(this.handleDateChanged);

		if (this.input.value) {
			const defaultDate = new Date(this.input.value);
			this.datePicker.setDate(defaultDate);
			this.handleDateChanged(defaultDate);
		}
    }

    destroy() {
		this.datePicker.onChange.remove(this.handleDateChanged);
		this.datePicker = null;
    }

	handleDateChanged(date) {
    	if (!date) {
    		this.labelEl.innerHTML = this.defaultLabel;
			this.input.value = '';
    		return;
		}

		this.input.value = [
			date.getFullYear(),
			addZeros(date.getMonth() + 1),
			addZeros(date.getDate())
		].join('-');

		this.labelEl.innerHTML = date.toLocaleDateString(
			this.el.dataset.dateLocale ? this.el.dataset.dateLocale : 'en-US',
			{year: 'numeric', month: 'long', day: 'numeric' }
		);
	}
}

import createRequest from 'superagent';

const filterEmpty = x => !! x;
const getAddressFromEvent = (event) => {
	const parts = [
		event.address,
		event.address_two,
		event.city,
		[event.zipcode, event.state].filter(filterEmpty).join(' '),
	];

	return parts.filter(filterEmpty).join(', ');
};

const moveUTCDateToCurrentTimezone = (date) => {
	const timezoneOffsetInMinutes = date.getTimezoneOffset();

	return new Date(date.getTime() + timezoneOffsetInMinutes * 60 * 1000);
};

export default function fetchEvents(url) {
	const request = createRequest('GET', url);
	request.set('Accept', 'application/json');
	request.set('X-Requested-With', 'XMLHttpRequest');

	return request.then(response => {
		return response.body.locations.map(event => {
			const endDate = moveUTCDateToCurrentTimezone(new Date(event.end_date));

			return {
				id: event.id,
				name: event.name,
				startDate: moveUTCDateToCurrentTimezone(new Date(event.start_date)),
				endDate: endDate,
				inPast: (new Date).getTime() > endDate.getTime(),
				imageURL: event.image,
				details: event.hours,
				gps: event.gps,
				address: getAddressFromEvent(event),
				buttonLabel: event.btn_title,
				buttonURL: event.btn_url,
			};
		});
	});
}

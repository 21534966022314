import DefaultPage from '../DefaultPage/DefaultPage.js';
import './order-detail.scss';
import './components/order-product.scss';
import Sticky from 'utils/sticky/Sticky.js';

export default class OrderDetail extends DefaultPage {

	initialize() {
		super.initialize();

		this.accountNavigation = this.find('.account-navigation__wrapper');
		this.sticky = new Sticky(this.accountNavigation);

		this.update = this.update.bind(this);

		window.addEventListener('scroll', this.update);
		window.addEventListener('resize', this.update);
		this.update();
	}

	update() {
		this.sticky.update();
	}

	destroy() {
		super.destroy();

		window.removeEventListener('scroll', this.update);
		window.removeEventListener('resize', this.update);
	}
}

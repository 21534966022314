import DefaultPage from '../DefaultPage/DefaultPage.js';
import './conversation-bars-product.scss';
import ConversationBarsCloud from './components/ConversationBarsCloud';

export default class ConversationBarsProduct extends DefaultPage {

	/**
	 *
	 * @param options
	 */
	initialize(options) {

		this.cloudContainerElement = this.findAll('.conversation-bars-product__cloud-container');
		this.backgroundElement = this.find('.conversation-bars-product__background');
		this.backgroundFillElement = this.find('.conversation-bars-product__background__fill');
		this.clouds = this.findAll('.conversation-bars-product__cloud').map(el => new ConversationBarsCloud({...options, el}));

		this.resize = this.resize.bind(this);
		this.hideBackground = this.hideBackground.bind(this);

		window.addEventListener('resize', this.resize);

		this.resize();
		this.showClouds();

		this.signals.backgroundAnimation.started.add(this.hideBackground);

		super.initialize();
	}

	/**
	 *
	 */
	showClouds() {
		this.clouds.forEach((cloud) => {
			cloud.show(2 + Math.random() * 3);
		});
	}

	/**
	 *
	 */
	resize() {
		const width = this.el.clientWidth;
		const marginLeft = (this.backgroundFillElement.clientWidth - width) / 2;
		this.backgroundFillElement.style['margin-left'] = -marginLeft + 'px'
	}

	show(widgetsOnly = false) {
		super.show();

		if(!widgetsOnly) {

		}
	}

	hide(widgetsOnly = false) {
		super.hide(widgetsOnly);

		if(!widgetsOnly && !!this.backgroundElement.parentNode) {
			this.hideBackground();
		}
	}

	onShown() {
		super.onShown();
		this.backgroundElement.classList.remove('conversation-bars-product__background--initializing');
	}

	onHidden() {
		super.onHidden();
	}

	hideBackground() {
		this.backgroundElement.classList.add('conversation-bars-product__background--hiding');
	}


	/**
	 *
	 */
	destroy() {
		this.signals.backgroundAnimation.started.remove(this.hideBackground);
		super.destroy();
	}
}

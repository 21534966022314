class CurrencyFormatter {

	constructor() {
		this.settings = tonysConfig.currency;
		this.decimal = parseInt(this.settings.decimal);
	}

	format(price, noSymbols = false, useComma = false, cutDecimals = false) {
		let priceStr = '' + price;
		if (typeof price !== 'string') {
			const priceParts = priceStr.split('.');

			// No decimal part found
			if (priceParts.length <= 1) {
				priceParts.push('');
			}

			// Add or remove numbers
			var decimalNotation = '';
			var decimalPart = priceParts[1] + '';
			for (var i = 0; i < this.decimal; i ++) {
				decimalNotation += decimalPart.charAt(i) !== '' ? decimalPart.charAt(i) : '0';
			}
			priceParts[1] = decimalNotation;

			// Join parts
			if(!cutDecimals) {
				priceStr = useComma ? priceParts.join(',') : priceParts.join('.');
			} else {
				priceStr = priceParts[0];
			}
		}

		return noSymbols ? priceStr : this.settings.symbolLeft + priceStr + this.settings.symbolRight;
	}

}

const formatter = new CurrencyFormatter();
export default formatter;

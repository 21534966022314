import View from '../../../../../shared/Core/View';

import './progress-bar.scss';

const CLASS_DONE = 'subscription-progress-bar__part--done';
const CLASS_CURRENT = 'subscription-progress-bar__part--current';

export default class ProgressBar extends View {

    initialize() {
		this.parts = this.findAll('.subscription-progress-bar__part');
    }

    destroy() {
    }

	/**
	 * 0 = nothing is done, 1 is the current
	 */
	setDoneIndex(doneIndex) {
		this.parts.forEach(el => {
			el.classList.remove(CLASS_DONE);
			el.classList.remove(CLASS_CURRENT);
		});

		this.parts.forEach((el, index) => {
			if (index < doneIndex) {
				el.classList.add(CLASS_DONE);
			} else if (index === doneIndex) {
				el.classList.add(CLASS_CURRENT);
			}
		});
	}
}

import View from 'Core/View';

import Icon from '!raw-loader!img-loader!../RadioButton/radio.svg';

import './radio-button-in-box.scss';

export default class RadioButtonInBox extends View {

	initialize() {
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);

		this.contents = this.find('.radio-button-in-box__contents');
		this.input = this.find('input');

		this.el.addEventListener('mousedown', this.handleMouseDown);
		this.el.addEventListener('mouseup', this.handleMouseUp);

		this.el.classList.add('radio-button-in-box--clip-' + Math.floor(1 + Math.random() * 4));

		this.insertSVG();
	}

	destroy() {
		this.el.removeEventListener('mousedown', this.handleMouseDown);
		this.el.removeEventListener('mouseup', this.handleMouseUp);
	}

	insertSVG() {
		const svg = document.createElement('div');
		svg.className = 'radio-button-in-box__svg';
		svg.innerHTML = Icon;

		const input = this.find('.radio-button-in-box__input');
		if (input) {
			this.contents.appendChild(svg);
		} else {
			console.error(this.el, 'input element not found');
		}
	}

	handleMouseDown() {
		if (this.isDisabled()) {
			return;
		}

		this.checked = this.input.checked;
	}

	handleMouseUp() {
		if (this.isDisabled()) {
			return;
		}

		setTimeout(() => {
			this.input.checked = !this.checked;

			const event = document.createEvent('HTMLEvents');
			event.initEvent('change', true, true);
			this.input.dispatchEvent(event);
		}, 0);
	}

	isDisabled() {
		return this.el.classList.contains('radio-button-in-box--disabled');
	}
}

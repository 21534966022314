export default [
	{
		'featureType': 'administrative',
		'elementType': 'labels.text.fill',
		'stylers': [
			{
				'visibility': 'on'
			},
			{
				'color': '#828282'
			}
		]
	},
	{
		'featureType': 'administrative.locality',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'lightness': '10'
			},
			{
				'color': '#ff0000'
			}
		]
	},
	{
		'featureType': 'landscape',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'color': '#f6f6f6'
			}
		]
	},
	{
		'featureType': 'landscape.man_made',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'color': '#ededed'
			}
		]
	},
	{
		'featureType': 'poi',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'poi',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'saturation': '6'
			}
		]
	},
	{
		'featureType': 'road',
		'elementType': 'all',
		'stylers': [
			{
				'saturation': - 100
			},
			{
				'lightness': 45
			}
		]
	},
	{
		'featureType': 'road',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'color': '#d4d2d2'
			}
		]
	},
	{
		'featureType': 'road',
		'elementType': 'labels.text',
		'stylers': [
			{
				'lightness': '17'
			},
			{
				'hue': '#ff0000'
			}
		]
	},
	{
		'featureType': 'road',
		'elementType': 'labels.text.fill',
		'stylers': [
			{
				'color': '#5e5e5e'
			}
		]
	},
	{
		'featureType': 'road',
		'elementType': 'labels.text.stroke',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'road.highway',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'simplified'
			}
		]
	},
	{
		'featureType': 'road.highway',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'color': '#d4d4d4'
			}
		]
	},
	{
		'featureType': 'road.arterial',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'color': '#d4d4d4'
			}
		]
	},
	{
		'featureType': 'road.arterial',
		'elementType': 'labels.icon',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'road.local',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'color': '#d4d4d4'
			}
		]
	},
	{
		'featureType': 'transit',
		'elementType': 'all',
		'stylers': [
			{
				'visibility': 'off'
			}
		]
	},
	{
		'featureType': 'water',
		'elementType': 'all',
		'stylers': [
			{
				'color': '#48b2e0'
			},
			{
				'visibility': 'on'
			}
		]
	},
	{
		'featureType': 'water',
		'elementType': 'geometry.fill',
		'stylers': [
			{
				'color': '#48b2e0'
			}
		]
	}
];

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './store-search-box-icon.scss';

class StoreSearchBoxIcon extends Component {

	render() {
		return (
			<svg viewBox="0 0 256 256" className={classNames('store-search-box-icon', this.props.className)}>
				<polygon points="166.2,152.1 155.2,170.1 220.4,211.4 233.4,191.1"/>
				<path
					d="M150.8,75C129.9,48.4,82.6,28.8,46.5,60.1c-46.7,40.5-18.9,135.3,53.3,124.1C171.9,173.1,175.8,106.9,150.8,75z M100.3,166c-48.5,8-67.2-60.2-35.8-89.3c24.3-22.5,56.1-8.4,70.1,10.7C151.5,110.3,148.9,158,100.3,166z"/>
			</svg>
		);
	}
}

StoreSearchBoxIcon.propTypes = {
	className: PropTypes.string,
};

export default StoreSearchBoxIcon;
export function formatTime(number, forceHours = false) {
	const hours = Math.floor(number / 3600);
	const minutes = Math.floor((number - hours * 3600) / 60);
	const seconds = Math.floor((number - (hours * 3600) - (minutes * 60)));

	var timeString = '';

	if (hours || forceHours) {
		timeString += addZeros(hours, 2) + ':';
	}

	timeString += addZeros(minutes, 2) + ':';
	timeString += addZeros(seconds, 2);

	return timeString;
}

export function addZeros(value, length = 2) {
	let s = value + '';
	while (s.length < length) {
		s = '0' + s;
	}

	return s;
}

export function limit(value, min, max) {
	return Math.min(Math.max(value, min), max);
}

import createRequest from 'superagent';

export default function fetchLocations(url) {
	const request = createRequest('GET', url);
	request.set('Accept', 'application/json');
	request.set('X-Requested-With', 'XMLHttpRequest');

	return request.then(response => {
		const locationsById = {};
		response.body.locations.forEach(location => {
			locationsById[location.id] = location;
		});

		return locationsById;
	});
}
import './splashes.scss';

import View from 'Core/View.js';

export default class BaseSplash extends View {

	get active() {
		return this._isActive;
	}

	set active(value) {
		this._isActive = value;
	}

	get static() {
		return this._static;
	}

	set static(value) {
		this._static = value;
	}

	initialize(options) {

		this.active = false;

		this.static = options.el && options.el.dataset.static ? true : false;

		this.svg = options.el;
		this.container = options.elContainer;
	}

	update() {

	}

	show() {

	}

	hide() {

	}

	reset() {

		this.active = false;
		this.container.appendChild(this.svg);

	}


}

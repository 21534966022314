import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Location from '../../shapes/Location';

import ButtonList from '../../../../reactComponents/ButtonList/ButtonList';
import Button from '../../../../reactComponents/Button/Button';

import './share-location.scss';

class ShareLocation extends Component {

	constructor(props) {
		super(props);

		this.handleShareClicked = this.handleShareClicked.bind(this);
	}

	render() {
		const classes = classNames({
			'share-location': true,
			[this.props.className]: ! ! this.props.className,
		});

		const color = this.props.whiteButtons ? 'white' : 'blue';

		return (
			<div className={classes}>
				<ButtonList align="right">
					<Button iconName="share" color={color} onClick={this.handleShareClicked}>
						{this.props.translations.share}
					</Button>
					<Button iconName="arrow-right"
						color={color}
						target="_blank"
						url={this.getGoogleMapsURL(this.props.location)}>
						{this.props.translations.route}
					</Button>
				</ButtonList>
			</div>
		);
	}

	getGoogleMapsURL(location) {
		const base = 'https://www.google.com/maps/search/?api=1&';
		const query = location.address + ', ' + location.city + ', ' + location.state + ' ' + location.zipcode;
		const parameters = [
			'query=' + encodeURIComponent(query),
		];

		return base + parameters.join('&');
	};

	handleShareClicked(event) {
		event.preventDefault();

		if (typeof navigator.share !== 'undefined') {
			this.shareUsingWebShareAPI();
		} else {
			this.props.showShareModal(this.getURLForLocation());
		}
	};

	shareUsingWebShareAPI() {
		const location = this.props.location;
		const address = location.address + ', ' + location.city + ', ' + location.state + ' ' + location.zipcode;
		const trans = this.props.translations.sharing;

		const share = {
			title: trans.title.replace(':name', location.name),
			text: trans.text.replace(':address', address),
			url: this.getURLForLocation(),
		};

		navigator.share(share)
			.then(() => console.log('Successful share'))
			.catch((error) => console.log('Error sharing', error));
	};

	getURLForLocation() {
		const l = window.location;
		const location = this.props.location;
		const baseURL = l.protocol + '//' + l.host + l.pathname;
		const locationIdentifier = location.id + '-' + this.slugify(location.name);

		return baseURL + '#' + locationIdentifier;
	}

	slugify(text) {
		return text.toString().toLowerCase().trim()
			.replace(/&/g, '-and-')         // Replace & with 'and'
			.replace(/[\s\W-]+/g, '-')      // Replace spaces, non-word characters and dashes with a single dash (-)
	}
}

ShareLocation.propTypes = {
	location: Location.isRequired,
	showShareModal: PropTypes.func.isRequired,
	translations: PropTypes.object.isRequired,
	className: PropTypes.string,
	whiteButtons: PropTypes.bool,
};

export default ShareLocation;
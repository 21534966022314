import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './truck-locator-pagination.scss';

class TruckLocatorPagination extends React.PureComponent {

	constructor(props) {
		super(props);
	}

	render() {
		const classes = classNames({
			'truck-locator-pagination': true,
			[this.props.className]: !! this.props.className,
		});

		const earlierClasses = classNames({
			'truck-locator-pagination__button': true,
			'truck-locator-pagination__button--left': true,
			'truck-locator-pagination__button--disabled': ! this.props.hasPreviousPage,
		});

		const laterClasses = classNames({
			'truck-locator-pagination__button': true,
			'truck-locator-pagination__button--right': true,
			'truck-locator-pagination__button--disabled': ! this.props.hasNextPage,
		});

		return (
			<div className={classes}>
				<button className={earlierClasses} onClick={this.props.onPreviousPage}>
					{this.props.translations.earlier || '&laquo;'}
				</button>
				<button className={laterClasses} onClick={this.props.onNextPage}>
					{this.props.translations.later || '&raquo;'}
				</button>
			</div>
		);
	}
}

TruckLocatorPagination.propTypes = {
	className: PropTypes.string,
	translations: PropTypes.object.isRequired,
	onPreviousPage: PropTypes.func,
	onNextPage: PropTypes.func,
	hasPreviousPage: PropTypes.bool,
	hasNextPage: PropTypes.bool,
};

export default TruckLocatorPagination;

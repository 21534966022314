import View from 'Core/View';

import './subscription-arrow-button.scss';

export default class ArrowButton extends View {

    initialize() {
    }

    destroy() {
    }

	open() {
		this.el.classList.add('subscription-arrow-button--toggled');
	}

	close() {
		this.el.classList.remove('subscription-arrow-button--toggled');
	}
}

import View from 'Core/View.js';

import './progress-step-bar.scss';

const STEP_ACTIVE_CLS = 'progress-step-bar__step--active';
const STEP_COMPLETE_CLS = 'progress-step-bar__step--complete';

export default class ProgressStepBar extends View {

	initialize(options) {
		this.steps = this.findAll('.progress-step-bar__step');
	}

	destroy() {
	}

	setActiveStep(stepIndex = -1) {
		this.steps.forEach((step, index) => {

			step.classList.remove(STEP_COMPLETE_CLS);
			step.classList.remove(STEP_ACTIVE_CLS);

			if(index < stepIndex) {
				step.classList.add(STEP_COMPLETE_CLS);
			}
			if(index === stepIndex) {
				step.classList.add(STEP_ACTIVE_CLS);
			}
		});
	}
}

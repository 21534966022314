import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

import './section-button.scss';

const ORDER = [
	'mission',
	'collaborate',
	'shop'
];

const VISIBLE_ON_ROUTES = [
	'mission',
	'collaborate',
	'shop.index',
	'shop.category',
	'shop.search',
];

export default class SectionButton extends View {

	initialize(options) {
		this.handlePageLoadComplete = this.handlePageLoadComplete.bind(this);
		this.hide = this.hide.bind(this);
		this.handleClick = this.handleClick.bind(this);

		this.routeName = this.el.dataset.route.split('.')[0];
		this.direction = this.el.parentNode.dataset.direction === 'right' ? 1 : - 1;
		this.isHeaderButton = this.el.parentNode.dataset.area === 'header';

		this.isShown = false;
		this.update(options.routeAtLoad);

		this.signals.pageLoad.started.add(this.hide);
		this.signals.pageLoad.failed.add(this.handlePageLoadComplete);
		this.signals.pageLoad.completed.add(this.handlePageLoadComplete);

		this.el.addEventListener('click', this.handleClick);
	}

	destroy() {
		this.signals.pageLoad.started.remove(this.hide);
		this.signals.pageLoad.failed.remove(this.handlePageLoadComplete);
		this.signals.pageLoad.completed.remove(this.handlePageLoadComplete);
		this.el.removeEventListener('click', this.handleClick);
	}

	handlePageLoadComplete(node, duration, route) {
		this.update(route);
	}

	handleClick(event) {
		this.signals.sectionButtonClicked.dispatch(this.routeName, this.direction);

		if (! this.isShown) {
			event.preventDefault();
			event.stopPropagation();
		}
	}

	update(route) {
		if (this.shouldButtonShowOnRoute(route)) {
			this.show();
		} else {
			this.hide();
		}
	}

	shouldButtonShowOnRoute(route) {
		const routeName = route.name.split('.')[0];

		const direction = this.getPositionToCurrent(routeName);
		const routeIsDifferentAndDirectionMatch = routeName !== this.routeName && direction === this.direction;

		return (
			VISIBLE_ON_ROUTES.indexOf(route.name) >= 0 &&
			routeIsDifferentAndDirectionMatch
		);
	}

	hide() {
		if (! this.isShown) {
			return;
		}

		this.isShown = false;

		TweenLite.killTweensOf(this.el);
		TweenLite.to(this.el, 0.2, {
			scale: 0, ease: Circ.easeIn, onComplete: () => {
				this.el.style.display = 'none';
			}
		});
	}

	show() {
		if (this.isShown) {
			return;
		}

		this.isShown = true;

		this.el.style.display = 'block';
		TweenLite.killTweensOf(this.el);
		TweenLite.fromTo(this.el, 0.3, {scale: 0}, {scale: 1, delay: 0.6, ease: Back.easeOut});
	}

	getPositionToCurrent(current) {
		if (this.routeName === current) {
			return 0;
		}

		const buttonPos = ORDER.indexOf(this.routeName);
		const currentPos = ORDER.indexOf(current);

		// Exception for the last
		if (buttonPos === ORDER.length - 1 && currentPos === 0) {
			return - 1;
		}

		// ... and the first
		if (buttonPos === 0 && currentPos === ORDER.length - 1) {
			return 1;
		}

		return buttonPos > currentPos ? 1 : - 1;
	}
}
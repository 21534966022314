import View from 'Core/View.js';

import './product-detail-bottom-background.scss';

export default class ProductDetailBottomBackground extends View {

	initialize() {
		this.resize = this.resize.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);

		const grapesElement = document.querySelector('.product-detail__grapes-area');
		const relatedElement = document.querySelector('.related-products');
		const footerElement = document.querySelector('.footer-wrapper');

		this.target = grapesElement ? grapesElement : relatedElement;

		if(!this.target) {
			this.target = footerElement;
		}

		this.signals.pageLoad.started.add(this.hide);
		window.addEventListener('resize', this.resize);

		this.resize();
		this.show();
	}

	destroy() {
		this.signals.pageLoad.started.remove(this.hide);
		window.removeEventListener('resize', this.resize);
	}

	resize() {
		// Calculate distance from target element top, to top of page
		let top = 0;
		let element = this.target;
		do {
			top += element.offsetTop || 0;
			element = element.offsetParent;
		} while (element);

		this.el.style.height = (document.body.offsetHeight - top + 50) + 'px';
	}

	show() {
		// Start in animation
		this.el.classList.remove('product-detail-bottom-background--shown');
		this.el.classList.add('product-detail-bottom-background--shown');
	}

	hide() {
		this.el.classList.remove('product-detail-bottom-background--shown');
	}
}

import View from 'Core/View';
import CartModel from '../../../shared/data/CartModel';
import TweenLite from 'gsap/TweenLite';
import {Sine, Linear} from 'gsap';

import './mini-cart-impact-beans.scss';

const MINI_CART_HIDDEN_CLASS = 'mini-cart-impact-beans--hidden';

export default class MiniCartImpactBeans extends View {

	initialize(options) {
		this.beanCount = 0;
		this.countElement = this.find('.mini-cart-impact-beans__count span');

		this.update = this.update.bind(this);
		this.render = this.render.bind(this);

		CartModel.updatedSignal.add(this.update);
	}

	destroy() {
		CartModel.updatedSignal.remove(this.update);
	}

	update(reset = true) {
		this.beanCount = reset ? 0 : this.beanCount;
		this.render();

		const newBeanCount = CartModel.getBeanCount();

		TweenLite.killTweensOf(this);
		TweenLite.to(this, 1.5, {
			delay: 0.3,
			beanCount: newBeanCount,
			ease: Sine.easeOut,
			onUpdate:this.render});

		TweenLite.to(this.el, 0.6, {
			delay: 0.3,
			autoAlpha: newBeanCount > 0 ? 1 : 0,
			ease: Linear.ease});

		if(newBeanCount > 0) {
			this.el.classList.remove(MINI_CART_HIDDEN_CLASS);
		} else if(!this.el.classList.contains(MINI_CART_HIDDEN_CLASS)) {
			this.el.classList.add(MINI_CART_HIDDEN_CLASS);
		}
	}

	render() {
		this.countElement.innerHTML = Math.floor(this.beanCount);
	}
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './street-view-interactive.scss';

class StreetViewInteractive extends Component {

	constructor(props) {
		super(props);

		this.el = null;
		this.streetView = null;
	}

	componentDidMount() {
		this.initializeStreetView(this.props.panoramaId);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.panoramaId !== nextProps.panoramaId) {
			this.streetView.setPano(nextProps.panoramaId);
		}
	}

	render() {
		const classes = classNames({
			'street-view-interactive': true,
			'street-view-interactive--full-screen': this.props.fullScreen,
		});

		return (
			<div className={classes}>
				<div className="street-view-interactive__height-fixer"
					style={this.getFixerStyle()}/>
				<div className="street-view-interactive__container" ref={el => this.el = el}>
					<div className="street-view-interactive__loading">
						Loading..
					</div>
				</div>
			</div>
		);
	}

	getFixerStyle() {
		return {
			paddingTop: (this.props.heightRatio * 100) + '%',
		};
	}

	initializeStreetView(panoramaId) {
		this.streetView = new google.maps.StreetViewPanorama(this.el, {
			pano: panoramaId,
			disableDefaultUI: true,
		});
	}
}

StreetViewInteractive.propTypes = {
	panoramaId: PropTypes.string.isRequired,
	heightRatio: PropTypes.number.isRequired,
	fullScreen: PropTypes.bool,
};

export default StreetViewInteractive;
import View from '../../../shared/Core/View';

import './gift-card-balance-check-mini.scss';

export default class GiftCardBalanceCheckMini extends View {
	initialize() {
		this.handleResetClick = this.handleResetClick.bind(this);
		this.handleFormSubmitted = this.handleFormSubmitted.bind(this);

		this.formEl = this.find('.gift-card-balance-check-mini__form');
		this.inputEl = this.find('#gift-card-code');
		this.resultEl = this.find('.gift-card-balance-check-mini__result');
		this.resultCheckmarkEl = this.find('.gift-card-balance-check-mini__result__checkmark');
		this.resultMessageEl = this.find('.gift-card-balance-check-mini__result__message');
		this.resetEl = this.find('.gift-card-balance-check-mini__reset');

		this.signals.forms.succeeded.add(this.handleFormSubmitted);
		this.resetEl.addEventListener('click', this.handleResetClick);
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.handleFormSubmitted);
		this.resetEl.removeEventListener('click', this.handleResetClick);
	}

	handleResetClick(event) {
		event.preventDefault();

		this.reset();
	}

	handleFormSubmitted(formId, data) {
		if (formId !== 'gift-card-balance-check-mini') {
			return;
		}

		if (!data.card) {
			return;
		}

		this.formEl.style.display = 'none';
		this.resultEl.style.display = 'flex';
		this.resultCheckmarkEl.style.display = data.card.valid
			? 'block'
			: 'none';
		this.resultMessageEl.innerHTML = data.card.message;
	}

	reset() {
		this.inputEl.value = '';
		this.formEl.style.display = 'flex';
		this.resultEl.style.display = 'none';
	}
}

import View from 'Core/View.js';
import createRequest from 'superagent';

export default class ZoneField extends View {

	initialize(options) {
		this.countrySelect = options.countrySelect;
		this.currentCountryId = options.fixedCountryId ? options.fixedCountryId : null;
		this.zonesDictionary = {};

		if (this.countrySelect) {
			this.selectInput = this.find('.select__select');
			this.select = this.find('.select');

			this.checkIfWeNeedToUpdate = this.checkIfWeNeedToUpdate.bind(this);

			this.hide();
			this.checkIfWeNeedToUpdate();

			this.countrySelect.addEventListener('change', this.checkIfWeNeedToUpdate);

		} else {
			// When no select is present, we assume we're using a static country id
			this.loadZonesForCountry(this.currentCountryId);
		}
	}

	destroy() {
		if (this.countrySelect) {
			this.countrySelect.removeEventListener('change', this.checkIfWeNeedToUpdate);
		}
	}

	checkIfWeNeedToUpdate() {
		const newCountryId = this.countrySelect.options[this.countrySelect.selectedIndex].value;
		if (newCountryId === this.currentCountryId) {
			return;
		}

		this.currentCountryId = newCountryId;

		// Check if we already have loaded those zones
		if (typeof this.zonesDictionary[this.currentCountryId] !== 'undefined') {
			this.updateZones(this.zonesDictionary[this.currentCountryId]);
			return;
		}

		this.loadZonesForCountry(this.currentCountryId);
	}

	loadZonesForCountry(countryId) {
		if (! countryId) {
			this.updateZones(null);
			return;
		}

		const request = createRequest('GET', this.getURLForCountryId(countryId));
		request.set('Accept', 'application/json');

		// Send out the request
		request.then(
			(response) => {
				this.zonesDictionary[countryId] = response.body;

				// If we switched to another country while loading..
				if (this.currentCountryId !== countryId) {
					return;
				}

				this.updateZones(this.zonesDictionary[this.currentCountryId]);
			},
			(error) => {
				const errors = error.response.body;
				errors.map(errorMessage => {
					this.signals.toaster.requested.dispatch(errorMessage, 6);
				});
			}
		);
	}

	updateZones(zones) {
		if (! zones || zones.length === 0) {
			this.hide();
			return;
		}

		if (this.selectInput) {
			this.selectInput.innerHtml = Object.keys(zones).map((id) => {
				return `<option value="${id}">${zones[id]}</option>`;
			});
		}

		this.show();
	}

	getURLForCountryId(countryId) {
		return this.el.dataset.zonesUrl.replace('!!', countryId);
	}

	hide() {
		this.el.style.display = 'none';
	}

	show() {
		this.select && this.select.classList.remove('select--disabled');
		this.el.style.display = 'block';
	}

	loading() {
		this.select && this.select.classList.add('select--disabled');
	}
}
import Signal from 'signals';
import View from 'Core/View.js';

import './submenu.scss';

export default class Submenu extends View {

	initialize() {

		this.setupUI();
		this.setupEventListeners();
		this.setupSignals();

	}

	destroy() {

		this.removeEventListeners();

	}

	setupUI() {

		this.ui = {
			button: this.el.querySelector('.js-submenu-button'),
			links: [...this.el.querySelectorAll('.js-submenu-link')]
		};

	}

	setupEventListeners() {

		this.handleSubmenuItemClicked = this.handleSubmenuItemClicked.bind(this);
		this.handleButtonCLicked = this.handleButtonCLicked.bind(this);
		this.ui.button.addEventListener('click', this.handleButtonCLicked);
		this.ui.links.forEach(el => el.addEventListener('click', this.handleSubmenuItemClicked));

	}

	removeEventListeners() {

		this.ui.button.removeEventListener('click', this.handleButtonCLicked);

		this.handleButtonCLicked = null;
		this.ui = null;

	}

	setupSignals() {

		this.signals = {...this.signals, subMenuClicked: new Signal()};

	}

	toggleIsOpen() {

		if(this.el.classList.contains('is-open')) {
			this.el.classList.remove('is-open');
		} else {
			this.el.classList.add('is-open');
		}

	}

	handleButtonCLicked() {

		this.toggleIsOpen();

	}

	handleSubmenuItemClicked(e) {

		e.preventDefault();

		this.toggleIsOpen();

		this.signals.subMenuClicked.dispatch({
			id: e.currentTarget.getAttribute('href')
		});

	}


}

import View from '../../../shared/Core/View';

import './spinner.scss';

export default class Spinner extends View {

    initialize() {

    }

    destroy() {
    }
}

import createRequest from 'superagent';

import View from 'Core/View';

import AddressPicker from '../../general/AddressPicker/AddressPicker';

import './components/subscription-cancel.scss';
import './subscription.scss';

export default class Subscription extends View {

	initialize(options) {
		this.options = options;

		this.handleModalShown = this.handleModalShown.bind(this);
		this.handleModalHidden = this.handleModalHidden.bind(this);
		this.handleModalTriggered = this.handleModalTriggered.bind(this);
		this.handleAddressPicked = this.handleAddressPicked.bind(this);
		this.handleCancelReasonChanged = this.handleCancelReasonChanged.bind(this);

		this.signals.modals.shown.add(this.handleModalShown);
		this.signals.modals.triggered.add(this.handleModalTriggered);
		this.signals.modals.hidden.add(this.handleModalHidden);

		this.changeAdressUrl = this.el.dataset.changeAddressUrl;
	}

	destroy() {
		this.signals.modals.shown.remove(this.handleModalShown);
		this.signals.modals.hidden.remove(this.handleModalHidden);
		this.signals.modals.triggered.remove(this.handleModalTriggered);

		if (this.reasons) {
			this.reasons.map(el => el.removeEventListener('change', this.handleCancelReasonChanged));
		}
	}

	updateAddress(id, type) {
		const data = {address_id: id, label: type};

		this.findAll('.subscription__toggle-address-picker')
			.forEach(el => el.classList.add('button--loading'));

		const request = createRequest('POST', this.changeAdressUrl);
		request.set('Accept', 'application/json');
		request.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
		request.set('X-Requested-With', 'XMLHttpRequest');
		request.send(data);
		request.then(() => location.reload());
	}

	handleAddressPicked(id, addressHTML) {
		if (this.addressModal) {
			this.addressModal.hide();
		}

		this.updateAddress(parseInt(id), this.addressType);
	}

	handleModalShown(modalId, modal) {
		switch (modalId) {
			case 'select-address':
				this.handleChangeAddressModalShown(modal);
				break;

			case 'subscription-cancel':
				this.handleCancelSubscriptionModalShown(modal);
				break;
		}
	}

	handleModalHidden(modalId) {
		switch (modalId) {
			case 'select-address':
				this.handleChangeAddressModalHidden();
				break;

			case 'subscription-cancel':
				this.handleCancelSubscriptionModalHidden();
				break;
		}
	}

	handleModalTriggered(modalId, trigger) {
		if (modalId !== 'select-address') {
			return;
		}

		this.addressType = trigger.dataset.type;
	}

	handleChangeAddressModalShown(modal) {
		this.updateModalPosition = modal.updatePosition.bind(modal);
		this.addressModal = modal;
		this.addressPicker = new AddressPicker({
			...this.options,
			el: modal.find('.subscription__address-picker'),
			hideDefaultAddressNotes: true,
		});
		this.addressPicker.signals.picked.add(this.handleAddressPicked);
		this.addressPicker.signals.sizeUpdated.add(this.updateModalPosition);
	}

	handleCancelSubscriptionModalShown(modal) {
		this.find('form', modal.el).reset();

		this.reasons = this.findAll('[name="cancel_reason"]', modal.el);
		this.reasons.map(el => el.addEventListener('change', this.handleCancelReasonChanged));

		this.reasonTextArea = this.find('.subscription-cancel__reason', modal.el);
	}

	handleChangeAddressModalHidden() {
		this.addressPicker.signals.sizeUpdated.remove(this.updateModalPosition);
		this.addressPicker.signals.picked.remove(this.handleAddressPicked);
		this.addressPicker.clear();
		this.addressPicker = null;
	}

	handleCancelSubscriptionModalHidden() {

	}

	handleCancelReasonChanged(event) {
		this.reasonTextArea.style.display = event.target.value === 'else' ? 'block' : 'none';
	}
}

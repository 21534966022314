import View from 'Core/View.js';

import getElementPageY from '../../../shared/utils/dom/getElementPageY';
import './top-background.scss';

export default class TopBackground extends View {

	initialize() {

		this.destroyed = false;

		this.render = this.render.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);

		const selector = this.el.dataset.targetSelector;
		const targetElement = document.querySelector(selector);

		this.target = targetElement;

		if(!this.target) {
			console.error('no valid target found for ', this.el);
			return;
		}

		const targetParent = document.querySelector('.wrapper__content');
		targetParent.prepend(this.el);

		this.render();
		this.show();

		this.signals.pageLoad.started.add(this.hide);
	}

	destroy() {
		this.destroyed = true;
		this.el.remove();
		this.signals.pageLoad.started.remove(this.hide);
	}

	render() {
		if(!this.destroyed) {
			this.el.style.height = getElementPageY(this.target) + 'px';
			window.requestAnimationFrame(this.render);
		}
	}

	show() {
		// Start in animation
		this.el.classList.remove('top-background--shown');
		this.el.classList.add('top-background--shown');
	}

	hide() {
		this.el.classList.remove('top-background--shown');
		setTimeout(() => this.destroy(), 200);
	}
}

import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

export default class Header extends View {

	initialize(options) {
		this.checkPageLoadVisibility = this.checkPageLoadVisibility.bind(this);
		this.checkVisibility = this.checkVisibility.bind(this);
		this.onResize = this.onResize.bind(this);

		this.shown = this.el.classList.contains('header--visible');

		// Initially check
		this.checkVisibility(this.el, options.routeAtLoad)

		this.signals.pageLoad.requested.add(this.checkPageLoadVisibility);
		this.signals.pageContentReplaced.add(this.checkVisibility);
		this.signals.windowResized.add(this.onResize);
		this.onResize();
	}

	destroy() {
		this.signals.pageLoad.requested.remove(this.checkPageLoadVisibility);
		this.signals.pageContentReplaced.remove(this.checkVisibility);
		this.signals.windowResized.remove(this.onResize);
	}

	checkPageLoadVisibility(route) {
		if(this.isRouteWithInstantHide(route)) {
			this.hide(false, true);
		}
	}

	checkVisibility(element, route) {
		if (this.isRouteWithHeader(route) && ! this.hasNoHeaderBodyClass()) {
			this.show();
		} else {
			this.hide(false);
		}
	}

	show(force = false) {
		if (! this.shown || force) {
			this.shown = true;
			this.el.style.display = 'block';
			this.el.classList.add('header--visible');
			TweenLite.to(this.el, 0.3, {
				y: 0,
				ease: Sine.easeInOut,
				onComplete: this.onShown,
				onCompleteScope: this
			});
		}
	}

	onShown() {

	}

	hide(force = false, instant = false) {
		if (this.shown || force) {
			this.shown = false;
			const height = this.el.offsetHeight;

			TweenLite.to(this.el, instant ? 0 : 0.3, {
				y: - height,
				ease: Sine.easeInOut,
				onComplete: this.onHide,
				onCompleteScope: this
			});
		}
	}

	onHide() {
		this.el.classList.remove('header--visible');
		this.el.style.display = 'none';
	}

	onResize() {
		if (this.shown) {
			this.show(true);
		} else {
			this.hide(true);
		}
	}

	hasNoHeaderBodyClass() {
		const pageElement = document.querySelector('.page');
		if (! pageElement) {
			return false;
		}

		return pageElement.classList.contains('no-header');
	}

	isRouteWithHeader(route) {
		const routeNames = ['mission', 'collaborate'];

		return route.isExactMatch && routeNames.indexOf(route.name) >= 0;
	}

	isRouteWithInstantHide(route) {
		const routeNames = ['shop.detail'];
		return routeNames.indexOf(route.name) >= 0;
	}
}

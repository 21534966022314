import React, {Component} from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';

import Location from '../../../../shapes/Location';
import SVGIcon from '../../../../../../reactComponents/SVGIcon/SVGIcon';

import './search-results-row.scss';

class SearchResultsRow extends Component {

	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}

	render() {
		const location = this.props.location;
		const variantIndex = this.getRandomVariantIndex();
		const classes = classNames({
			'search-results-row': true,
			['search-results-row--variant-' + variantIndex]: true,
		});

		return (
			<a className={classes} href="#" onClick={this.handleClick}>
				<div className="search-results-row__label">
					<div className="search-results-row__name">
						{location.name}
					</div>
					<div className="search-results-row__address">
						{this.renderAddress(location)}
					</div>
				</div>

				{this.renderDistanceIfAvailable(location)}

				<SVGIcon name={'arrow-right-skinny-' + variantIndex}
						 className="search-results-row__icon"/>
				<SVGIcon name="arrow-right" className="search-results-row__icon-current"/>
			</a>
		);
	}

	renderAddress(location) {
		return `${location.address} ${location.city}, ${location.state} ${location.zipcode}`;
	}

	renderDistanceIfAvailable(location) {
		if (! location.formattedDistance) {
			return null;
		}

		return (
			<span className="search-results-row__distance">
				{location.formattedDistance}
			</span>
		);
	}

	handleClick(event) {
		event.preventDefault();

		if (this.props.onSelected) {
			this.props.onSelected(this.props.location);
		}
	}

	getRandomVariantIndex() {
		return Math.floor(Math.random() * 4) + 1;
	}
}

SearchResultsRow.propTypes = {
	location: Location.isRequired,
	isCurrent: PropTypes.bool,
	onSelected: PropTypes.func
};

export default SearchResultsRow;

export default class HTMLVideoPlayer {

	constructor(options) {
		this.video = options.el;
		this.signals = options.signals;
	}

	reset() {}

	load(callback) {
		this.signals.loadStarted.dispatch();

		this.playPause();

		setTimeout(() => {
			this.signals.loadCompleted.dispatch();
			callback();

			this.updateInterval = setInterval(() => {
				this.signals.progress.dispatch();
			}, 1000);

			this.video.addEventListener('ended', this.signals.ended.dispatch);
		});
	}

	destroy() {
		clearInterval(this.updateInterval);
	}

	stop() {
		this.video.pause();
	}

	playPause() {
		if (! this.isPlaying()) {
			this.play();
		} else {
			this.pause();
		}
	}

	play() {
		this.video.play();
		this.signals.playbackStarted.dispatch();
	}

	pause() {
		this.video.pause();
		this.signals.paused.dispatch();
	}

	seekTo(seconds, allowSeekAhead = false) {
		if (typeof this.video.fastSeek === 'function') {
			this.video.fastSeek(seconds);
			return;
		}

		this.video.currentTime = seconds;
	}

	setVolume(volume) {
		this.video.volume = volume;
	}

	getVolume() {
		return this.video.volume;
	}

	getDuration() {
		if (isNaN(this.video.duration)) {
			return 0;
		}

		return this.video.duration;
	}

	getCurrentTime() {
		if (isNaN(this.video.currentTime)) {
			return 0;
		}

		return this.video.currentTime;
	}

	isPlaying() {
		return ! this.video.paused;
	}
}

import View from 'Core/View.js';

import {allowCookies, declineCookies} from '../../../services/CookieConsent';

import './cookie-notification.scss';

export default class CookieNotification extends View {

	initialize() {
		this.accept = this.accept.bind(this);
		this.deny = this.deny.bind(this);
		this.customize = this.customize.bind(this);

		this.denyButton = this.find('.cookie-notification__deny');
		this.acceptButton = this.find('.cookie-notification__allow');
		this.customizeButton = this.find('.cookie-notification__customize');
		this.fullScreen = this.el.classList.contains('cookie-notification--full');

		this.denyButton.addEventListener('click', this.deny);
		this.acceptButton.addEventListener('click', this.accept);
		this.customizeButton.addEventListener('click', this.customize);

		if (this.hasCookiesSet()) {
			return;
		}

		if (this.fullScreen) {
			this.signals.pageLock.lockRequested.dispatch('cookies');
		}

		this.show();
	}

	destroy() {
		this.denyButton.removeEventListener('click', this.deny);
		this.acceptButton.removeEventListener('click', this.accept);
		this.customizeButton.removeEventListener('click', this.customize);
	}

	show() {
		// Show the parent
		this.el.classList.add('cookie-notification--visible');
	}

	hide() {
		this.el.classList.add('cookie-notification--hiding');
		setTimeout(() => {
			this.el.classList.remove('cookie-notification--hiding');
			this.el.classList.remove('cookie-notification--visible');
		}, 300);
	}

	deny(event) {
		event.preventDefault();

		declineCookies();

		window.location.reload();
	}

	accept(event) {
		event.preventDefault();

		allowCookies({
			functional: true,
			analytics: true,
			marketing: true,
		});

		window.location.reload();
	}

	customize() {
		this.hide();
	}

	hasCookiesSet() {
		return window.tonysCookieConsent.functional;
	}
}

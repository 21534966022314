import View from '../../../../shared/Core/View';

import './subscription-coupon-confirm.scss';
import findAncestorByClass from '../../../../shared/utils/dom/findAncestorByClass';

export default class SubscriptionCouponConfirmation extends View {

    initialize(options) {
		this.handleAllergensConfirmed = this.handleAllergensConfirmed.bind(this);

		this.allergensWarning = options.modalDictionary['allergens-warning'];
		this.allergensConfirmButton = this.allergensWarning.find('.allergens-warning__confirm');
		this.allergensConfirmButton.addEventListener('click', this.handleAllergensConfirmed);
    }

	destroy() {
		this.allergensConfirmButton.removeEventListener('click', this.handleAllergensConfirmed);
	}

	handleAllergensConfirmed(event) {
		event.preventDefault();

		const formEl = findAncestorByClass(this.el, 'form');
		const form = this.findViewForElement(formEl);
		form.submit();

		this.allergensWarning.hide();
	}

	update(state) {

	}

	visible(state) {
		return state.confirmed_address === 'yes';
	}
}

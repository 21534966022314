import View from '../../../shared/Core/View';
import TweenLite from 'gsap/TweenLite';

import './classics.scss';

export default class Classics extends View {
	initialize() {
		this.handleCarouselChange = this.handleCarouselChange.bind(this);

		this.carouselEl = this.find('.classics__carousel');
		this.carouselEl.addEventListener('change', this.handleCarouselChange);

		this.bars = this.findAll('.classics__image-wrapper');

		this.currentContent = null;

		const firstContentId = this.find('.classics__content').dataset.id;
		this.showContentById(firstContentId);
	}

	destroy() {
		this.carouselEl.removeEventListener(
			'change',
			this.handleCarouselChange
		);
	}

	handleCarouselChange(event) {
		const el = this.bars[event.slideIndex];
		if (!el) {
			return;
		}

		const id = el.dataset.id;
		this.showContentById(id);
	}

	showContentById(id) {
		if (this.currentContent) {
			TweenLite.to(this.currentContent, 0.15, {
				position: 'absolute',
				top: 0,
				left: 0,
				y: 20,
				opacity: 0,
				onComplete: el => {
					TweenLite.set(el, {
						position: 'relative',
						display: 'none',
						y: 0,
						opacity: 1,
					});
				},
				onCompleteParams: [this.currentContent],
			});

			this.currentContent = null;
		}

		const content = this.find(`.classics__content[data-id="${id}"]`);
		if (!content) {
			return;
		}

		TweenLite.set(content, {display: 'block', opacity: 0, y: 20})
		TweenLite.to(content, 0.15, {opacity: 1, y: 0, delay: 0.1});

		this.currentContent = content;
	}
}

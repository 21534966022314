import View from 'Core/View.js';
import './shop-content-tile.scss';

export default class ShopContentTile extends View {

	initialize(options) {
		this.id = this.el.dataset.id;
	}

	getPosition() {
		return [].slice.call(this.el.parentNode.children).indexOf(this.el) + 1;
	}

	destroy() {
	}
}

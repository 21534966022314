import View from '../../../shared/Core/View';
import CartModel from '../../../shared/data/CartModel';

class MetaMiniCart extends View {

	initialize(options) {
		this.count = this.find('.meta-cart-icon__quantity');
		this.update = this.update.bind(this);

		CartModel.updatedSignal.add(this.update);
	}

	destroy() {
		CartModel.updatedSignal.remove(this.update);
	}

	update() {
		this.count.innerHTML = CartModel.getProductCount();
	}
}

export default MetaMiniCart;

import View from 'Core/View.js';

export default class AnchorCatcher extends View {

	initialize(options) {
		this.handleBodyClick = this.handleBodyClick.bind(this);
		this.el.addEventListener('click', this.handleBodyClick);

		this.redirectService = options.redirectService;
	}

	destroy() {
		this.el.removeEventListener('click', this.handleBodyClick);
	}

	handleBodyClick(event) {
		if (event.defaultPrevented || event.metaKey || event.ctrlKey) {
			return;
		}

		const anchor = this.getParentOrSelfAnchor(event.target);
		if (! anchor) {
			return;
		}

		if (this.hasDisabledCatching(anchor)) {
			return;
		}

		if (this.hasOtherTargetSet(anchor)) {
			return;
		}

		const href = anchor.getAttribute('href');
		if (! this.routes.isLocalURI(href)) {
			return;
		}

		if (this.hasFileExtension(href)) {
			return;
		}

		if (href === '#' || href === undefined) {
			return;
		}

		const route = this.routes.getRoute(href);
		if (this.redirectService.routeNeedsReloadOnLeave(route)) {
			return;
		}

		event.preventDefault();

		// Check if the anchor prefers replace
		route.replace = this.useReplace(anchor);

		this.signals.pageLoad.requested.dispatch(route);
	}

	/**
	 * If the element is an anchor, it returns itself. But if it isn't it
	 * will try to find a parent node that is or return false if it fails.
	 *
	 * @param {Node} element
	 * @returns {Node|boolean}
	 */
	getParentOrSelfAnchor(element) {
		if (this.elementIsAnAnchor(element)) {
			return element;
		}

		let parent = element.parentNode;
		while (parent !== null) {
			if (this.elementIsAnAnchor(parent)) {
				return parent;
			}

			parent = parent.parentNode;
		}

		return false;
	}

	/**
	 * @param element
	 * @returns {bool}
	 */
	hasDisabledCatching(element) {
		return element.classList.contains('no-ajax');
	}

	/**
	 * @param element
	 * @returns {bool}
	 */
	elementIsAnAnchor(element) {
		return element && element.tagName && element.tagName.toLowerCase() === 'a';
	}

	/**
	 *
	 * @param element
	 */
	hasOtherTargetSet(element) {
		return element.target && element.target !== '_self';
	}

	/**
	 * @param {string} url
	 * @returns {boolean}
	 */
	hasFileExtension(url) {
		if (! url) {
			return false;
		}

		return ! ! url.match(/\.[a-z0-9]+$/i);
	}

	/**
	 * @param {Node} element
	 * @return {boolean}
	 */
	useReplace(element) {
		return ! ! element.dataset.replace;
	}

}

import View from 'Core/View.js';

import './social-icons.scss';

export default class SocialIcons extends View {

	initialize() {

		this.facebook = this.find('.social-icons__link--facebook');
		this.twitter = this.find('.social-icons__link--twitter');
		this.instagram = this.find('.social-icons__link--instagram');

		this.onFacebookClicked = this.onFacebookClicked.bind(this);
		this.onTwitterClicked = this.onTwitterClicked.bind(this);
		this.onInstagramClicked = this.onInstagramClicked.bind(this);

		if (this.facebook) {
			this.facebook.addEventListener('click', this.onFacebookClicked);
		}

		if (this.twitter) {
			this.twitter.addEventListener('click', this.onTwitterClicked);
		}

		if (this.instagram) {
			this.instagram.addEventListener('click', this.onInstagramClicked);
		}
	}

	destroy() {
		if (this.facebook) {
			this.facebook.removeEventListener('click', this.onFacebookClicked);
		}

		if (this.twitter) {
			this.twitter.removeEventListener('click', this.onTwitterClicked);
		}

		if (this.instagram) {
			this.instagram.removeEventListener('click', this.onInstagramClicked);
		}
	}

	onFacebookClicked() {
	}

	onTwitterClicked() {
	}

	onInstagramClicked() {
	}

}

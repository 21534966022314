import View from 'Core/View.js';

import './edit-gift-card.scss';

export default class EditGiftCard extends View {

	initialize(options) {
		this.modal = options.modalDictionary['edit-gift-card'];

		this.show = this.show.bind(this);

		this.editButton = this.find('.edit-gift-card__edit-button');
		this.editURL = this.el.dataset.editUrl;

		this.signals.products.giftCardEditRequested.add(this.show);
	}

	destroy() {
		this.signals.products.giftCardEditRequested.remove(this.show);
	}

	show(hash) {
		this.editButton.href = this.editURL.replace('%s', hash);

		this.modal.show();
	}
}

import View from 'Core/View';
import './conversation-bars-grid-tile.scss';
import ConversationBarsModalControls from './ConversationBarsModalControls';

export default class ConversationBarsTile extends View {

	/**
	 *
	 * @param options
	 */
	initialize(options) {
		this.modalDictionary = options.modalDictionary;
		this.modalTriggeredSignal = options.signals.modals.triggered;

		this.format = this.el.dataset.format && this.el.dataset.format.length > 0 ? this.el.dataset.format : 'video';
		this.type = this.el.dataset.type;
		this.slug = this.el.dataset.slug;
		this.targetX = 0;
		this.targetY = 0;
		this.parentNode = this.el.parentNode;

		this.modalIdentifier = 'modal-' + this.format + '-' + this.slug;
		this.modalElement = document.getElementById(this.modalIdentifier);

		if(!this.modalElement) {
			console.error('Conversation bar modal with id ' + this.modalIdentifier + ' not found for conversation bar tile ' + this.format + '/' + this.type + '/' + this.slug + '.')
		} else {
			this.modalControls = new ConversationBarsModalControls({...options, el: this.modalElement});
		}

		this.resize = this.resize.bind(this);
		this.handleClick = this.handleClick.bind(this);

		this.el.addEventListener('click', this.handleClick);

		this.applyClippingPath();
	}

	/**
	 *
	 */
	applyClippingPath() {
		// Determine mask to use, depends on width / height ration and content type
		const ratio = this.el.clientHeight / this.el.clientWidth;
		const isPortrait = ratio > 1;
		let possibleMasks = [];

		// Text tile, always use up mask
		switch(this.format) {
			case 'image':
				possibleMasks = ['up', 'up-2', 'up-3', 'down', 'down-2', 'down-3'];
				break;
			case 'text':
				possibleMasks = ['up', 'up-2', 'up-3', 'down', 'down-2', 'down-3'];
				break;
			case 'video':
				possibleMasks = ['none'];
				break
			default:
				possibleMasks = ['left', 'right', 'up', 'up-2', 'up-3', 'down', 'down-2', 'down-3'];
		}

		this.el.classList.add('conversation-bars__grid-tile--clip-' + possibleMasks[Math.floor(Math.random() * possibleMasks.length)]);

		if(isPortrait) {
			this.el.classList.add('conversation-bars__grid-tile--is-portrait');
		}
	}

	/**
	 *
	 */
	resize() {

	}

	/**
	 *
	 * @param type
	 */
	toggleVisibility(type) {
		if(!type || this.type === type) {
			this.show();
		} else {
			this.hide();
		}
	}

	/**
	 *
	 */
	show() {
		this.parentNode.appendChild(this.el);
	}

	/**
	 *
	 */
	hide() {
		if(this.el.parentNode) {
			this.parentNode.removeChild(this.el);
		}
	}

	/**
	 *
	 * @param event
	 */
	handleClick(event) {
		event.preventDefault();
		event.stopPropagation();

		this.modalTriggeredSignal.dispatch(this.modalIdentifier, this.el);
		this.modalDictionary[this.modalIdentifier].show();
	}


	/**
	 *
	 */
	destroy() {
		this.el.removeEventListener('click', this.handleClick);

		if(this.modalControls) {
			this.modalControls.destroy();
		}
		super.destroy();
	}
}

import View from '../../../../../../../shared/Core/View';

import './video-fullscreen-button.scss';

export default class VideoFullscreenButton extends View {

	initialize(options) {
		this.player = options.player;

		this.handleFullscreenRequested = this.handleFullscreenRequested.bind(this);

		this.el.addEventListener('click', this.handleFullscreenRequested);
	}

	destroy() {
		this.el.removeEventListener('click', this.handleFullscreenRequested);
	}

	handleFullscreenRequested(event) {
		event.preventDefault();

		this.player.signals.fullscreenToggleRequested.dispatch();
	}

}

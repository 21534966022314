import View from 'Core/View.js';
import StoreLocator from './StoreLocator';
import ShareLocationModal from './components/ShareLocationModal/ShareLocationModal';

// Import all store locator images and write them to the public folder
require.context('./images', true, /.*/);

export default class StoreLocatorContainer extends View {

	initialize(options) {
		this.renderStoreLocator = this.renderStoreLocator.bind(this);

		this.container = this.find('.store-locator-container');

		this.geoBlockedModal = options.modalDictionary['store-locator-geo-location-blocked'];

		this.shareModal = new ShareLocationModal({
			...options,
			el: this.find('.store-locator__share-modal')
		});

		// Render now and whenever we potentially switch mode
		this.screen.breakpointChanged.add(this.renderStoreLocator);
		this.renderStoreLocator();
	}

	destroy() {
		this.screen.breakpointChanged.remove(this.renderStoreLocator);
		this.shareModal.destroy();
	}

	renderStoreLocator() {
		const isMobile = this.screen.breakpoint === 'mobile';
		const isTablet = this.screen.breakpoint === 'tablet';

		this.renderReact(this.container, StoreLocator, {
			apiKey: this.container.dataset.apiKey,
			locationsURL: this.container.dataset.locationsUrl,
			searchURL: this.container.dataset.searchUrl,
			isMobile: isMobile,
			isTablet: isTablet,
			initialFocusLocation: this.getDeeplinkLocationId(),
			defaultZoom: this.getDefaultZoom(),
			defaultCenter: this.getDefaultCenter(),
			translations: JSON.parse(this.container.dataset.translations),
			showGeoBlockedModal: this.geoBlockedModal.show.bind(this.geoBlockedModal),
			showShareModal: this.shareModal.share.bind(this.shareModal),
			country: this.container.dataset.country === 'US' ? 'US' : 'default',
		});
	}

	getDefaultZoom() {
		const val = this.container.dataset.defaultZoom;
		if (!val) {
			return 4;
		}

		return parseInt(val);
	}

	getDefaultCenter() {
		const lat = this.container.dataset.defaultLat;
		const lng = this.container.dataset.defaultLng;

		if (!lat || !lng) {
			return {
				lat: 40,
				lng: -98.7058248
			};
		}

		return {lat: parseFloat(lat), lng: parseFloat(lng)};
	}

	getDeeplinkLocationId() {
		const hash = window.location.hash;
		if (!hash || hash === '#') {
			return null;
		}

		const slugParts = hash.substr(1).split('-');
		if (!/^\d+$/.test(slugParts[0])) {
			return null;
		}

		return parseInt(slugParts[0]);
	}
}

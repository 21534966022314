import TweenLite from 'gsap/TweenLite';
import MiniMasonry from 'minimasonry';

import DefaultPage from '../DefaultPage/DefaultPage.js';

import ConversationBarsTile from './components/ConversationBarsTile';
import ConversationBarsFilters from './components/ConversationBarsFilters';
import ConversationBarsSignals from './ConversationBarsSignals';
import ConversationBarsNavigation from './components/ConversationBarsNavigation';

import './conversation-bars.scss';

const DEFAULT_CONVERSATION_BARS_VERTICAL_DISPLACEMENT_WIDGET = 100;

export default class ConversationBars extends DefaultPage {

	/**
	 *
	 * @param options
	 */
	initialize(options) {

		this.filterType = null;
		this.grid = this.el.querySelector('.conversation-bars__grid');
		this.masonry = new MiniMasonry({
			container: '.conversation-bars__grid',
		});

		this.tiles = this.findAll('.conversation-bars__grid-tile').map(el => new ConversationBarsTile({...options, el}));
		this.filters = this.findAll('.conversation-bars__filters').map(el => new ConversationBarsFilters({...options, el}))[0];
		this.navigation = this.findAll('.conversation-bars__navigation').map(el => new ConversationBarsNavigation({...options, el}))[0];
		this.conversationBarsBackground = document.querySelector('.conversation-bars__background');

		// Move background element outside container
		const wrapper = document.querySelector('.wrapper');
		const wrapperContent = document.querySelector('.wrapper__content');
		wrapper.insertBefore(this.conversationBarsBackground, wrapperContent);

		this.resize = this.resize.bind(this);
		this.hideBackground = this.hideBackground.bind(this);
		this.filterTypeSelected = this.filterTypeSelected.bind(this);
		this.onTilesHidden = this.onTilesHidden.bind(this);
		this.showTiles = this.showTiles.bind(this);
		this.applyGridLayout = this.applyGridLayout.bind(this);

		window.addEventListener('resize', this.resize);

		ConversationBarsSignals.selectConversationBarFilter.add(this.filterTypeSelected);

		this.signals.backgroundAnimation.started.add(this.hideBackground);

		super.initialize();

		TweenLite.delayedCall(0.25, () => this.resize());
	}

	/**
	 *
	 */
	resize() {
		this.tiles.forEach((tile) => tile.resize());
		TweenLite.killDelayedCallsTo(this.applyGridLayout);
		TweenLite.delayedCall(0.1, this.applyGridLayout)
	}

	/**
	 *
	 * @param type
	 */
	filterTypeSelected(type) {

		document.documentElement.classList.remove('type-inequality');
		document.documentElement.classList.remove('type-inhuman');
		document.documentElement.classList.remove('type-injustice');

		if(type !== this.filterType) {
			this.filterType = type;
			document.documentElement.classList.add('type-' + type);
			this.hideTiles();
		}
	}

	/**
	 *
	 */
	applyGridLayout() {

		if(this.masonry) {
			this.masonry.destroy();
		}

		const gutterWidth = 32;
		const gutterHeight = 32;
		let columns = 4;
		let gutters = 4;

		if(window.innerWidth <= 1280) {
			columns = 3;
		}

		if(window.innerWidth <= 1008) {
			columns = 2;
		}

		this.masonry = new MiniMasonry({
			container: '.conversation-bars__grid',
			baseWidth :Math.floor((this.grid.clientWidth - (gutterWidth * gutters)) / columns),
			gutterX: gutterWidth,
			gutterY: gutterHeight,
			surroundingGutter: false
		});
	}

	/**
	 *
	 */
	showTiles() {

		// Prepare tiles which should have an 'in' animation
		this.tiles.forEach((tile) => {
			if(!this.filterType || tile.type === this.filterType) {
				const translate = this.getTranslate(tile.el);
				tile.targetX = translate[0];
				tile.targetY = translate[1];
				TweenLite.killTweensOf(tile.el);
				TweenLite.set(tile.el, {
					opacity: 0,
					x: tile.targetX,
					y: tile.targetY + DEFAULT_CONVERSATION_BARS_VERTICAL_DISPLACEMENT_WIDGET,
					rotation: -10 + Math.random() * 20
				});

			}
		});

		// Show 'in' animation
		this.tiles.forEach((tile, index) => {
			TweenLite.to(tile.el, 0.3, {
				opacity: 1, y: tile.targetY, delay: index * 0.06, rotation: 0, ease: Cubic.easeOut
			});
		});
	}

	/**
	 *
	 */
	hideTiles() {

		// Out animation for tile
		this.tiles.forEach((tile) => {
			const translate = this.getTranslate(tile.el);
			const offsetY = translate[1];
			TweenLite.killTweensOf(tile.el);
			TweenLite.to(tile.el, 0.3, {
				opacity: 0,
				y: offsetY + DEFAULT_CONVERSATION_BARS_VERTICAL_DISPLACEMENT_WIDGET,
				delay: 0,
				rotation: 0,
				ease: Cubic.easeOut
			});
		});

		TweenLite.delayedCall(0.3, this.onTilesHidden);
	}

	/**
	 *
	 */
	onTilesHidden() {

		// Display none filtered out items, display block visible items
		this.tiles.forEach((tile) => {
			tile.toggleVisibility(this.filterType);
		});

		// Re-apply grid layout
		this.applyGridLayout();

		// Animate active tiles in
		this.showTiles();
	}

	/**
	 *
	 * @param element
	 * @return {Array}
	 */
	getTranslate(element) {
		const style = window.getComputedStyle(element);
		const matrix = new WebKitCSSMatrix(style.transform);
		return [matrix.m41, matrix.m42];
	}

	show(widgetsOnly = false) {
		super.show();

		if(!widgetsOnly) {

		}
	}

	hide(widgetsOnly = false) {
		super.hide(widgetsOnly);

		if(!widgetsOnly && !!this.conversationBarsBackground.parentNode) {
			this.hideBackground();
		}
	}

	onShown() {
		super.onShown();
		this.conversationBarsBackground.classList.remove('conversation-bars__background--initializing');
	}

	onHidden() {
		super.onHidden();
	}

	hideBackground() {
		this.conversationBarsBackground.classList.add('conversation-bars__background--hiding');
	}

	/**
	 *
	 */
	destroy() {
		this.signals.backgroundAnimation.started.remove(this.hideBackground);

		document.documentElement.classList.remove('type-inequality');
		document.documentElement.classList.remove('type-inhuman');
		document.documentElement.classList.remove('type-injustice');

		window.removeEventListener('resize', this.resize);
		ConversationBarsSignals.selectConversationBarFilter.remove(this.filterTypeSelected);
		this.tiles.forEach((tile) => tile.destroy);

		if(this.conversationBarsBackground.parentNode) {
			this.conversationBarsBackground.parentNode.removeChild(this.conversationBarsBackground);
		}

		super.destroy();
	}
}

import View from '../../../../shared/Core/View';

import './mission-bar.scss';
import StopMotionAnimation from '../StopMotionAnimation';

export default class Collaborate extends View {

	initialize() {
		this.frames = this.findAll('img');
		this.animation = new StopMotionAnimation(this.frames);
	}

	destroy() {
	}

	onBeforeAppear() {
		this.animation.reset();
	}

	onAppeared() {
		this.animation.play();
	}

	preload(onComplete, onFailure) {
		let loadedCount = 0;

		const onLoad = () => {
			loadedCount++;

			if (loadedCount === this.frames.length) {
				removeListeners();
				onComplete();
			}
		};

		const onLoadFailed = () => {
			removeListeners();
			onFailure();
		};

		const removeListeners = () => {
			this.frames.forEach(img => {
				img.removeEventListener('load', onLoad);
				img.removeEventListener('error', onLoadFailed);
			});
		};

		this.frames.forEach(img => {
			img.addEventListener('load', onLoad);
			img.addEventListener('error', onLoadFailed);
			img.src = img.dataset.src;
		});
	}
}

import Signal from 'signals';

const BREAKPOINTS = {
	mobile: 0,
	tablet: 768,
	laptop: 1024,
	desktop: 1280
};

class ResponsiveHelper {

	constructor() {

		/**
		 * Dispatched whenever the window is resized
		 * @param {{w: 0, h: 0}}
		 */
		this.resized = new Signal();

		/**
		 * @param {String} breakpoint name
		 * @param {Array} All matching breakpoint names
		 */
		this.breakpointChanged = new Signal();

		this.handleResize = this.handleResize.bind(this);
		window.addEventListener('resize', this.handleResize);

		// Initial values
		this.breakpoint = this.getExactBreakpoint(window.innerWidth);
		this.matchingBreakpoints = this.getMatchingBreakpoints(window.innerWidth);
	}

	handleResize() {
		// Raw dimensions
		const dimensions = this.getWindowDimensions();

		// Breakpoints
		const breakpoint = this.getExactBreakpoint(dimensions.w);
		if (breakpoint !== this.breakpoint) {
			this.matchingBreakpoints = this.getMatchingBreakpoints(dimensions.w);
			this.breakpoint = breakpoint;

			this.breakpointChanged.dispatch(this.breakpoint, this.matchingBreakpoints);
		}

		// Dispatch this after the breakpoint was set
		this.resized.dispatch(dimensions);
	}

	getWindowDimensions() {
		return {
			w: window.innerWidth,
			h: window.innerHeight
		};
	}

	/**
	 * @param {Array} names
	 * @returns {Boolean}
	 */
	isOneOf(names) {
		return names.indexOf(this.breakpoint) >= 0;
	}

	/**
	 * @param {number} width
	 * @returns {Array}
	 */
	getMatchingBreakpoints(width) {
		return Object.keys(BREAKPOINTS).filter(name => BREAKPOINTS[name] <= width);
	}

	/**
	 * @param {number} width
	 * @returns {string}
	 */
	getExactBreakpoint(width) {
		let largestMatchingKey = null;
		Object.keys(BREAKPOINTS).forEach(name => {
			if (width >= BREAKPOINTS[name] && (BREAKPOINTS[name] > BREAKPOINTS[largestMatchingKey] || ! largestMatchingKey)) {
				largestMatchingKey = name;
			}
		});

		return largestMatchingKey;
	}

}

export default new ResponsiveHelper();

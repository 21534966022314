import View from 'Core/View';

import './subscription-plan-durations.scss';
import ArrowButton from '../components/ArrowButton';

export default class SubscriptionPlanDurations extends View {

	initialize(options) {
		this.forceUpdate = options.forceUpdate;
		this.choosePlan = this.find('.subscription-plan-durations__choose-plan');
		this.input = this.find('input[name="plan_id"]');
		this.radios = this.findAll('.subscription-plan-durations__input');

		this.arrowButtons = this.findAll('.subscription-arrow-button').map(el => {
			return new ArrowButton({...options, el: el});
		});
	}

	destroy() {
	}

	update(state, prevState) {
		if (! this.choosePlan) {
			console.warn('[TONYS] No plans found');
			return;
		}

		this.choosePlan.style.display = state.plan_type === 'fixed' ? 'flex' : 'none';

		if (state.plan_type === 'fixed' && state.fixed_plan_id !== prevState.fixed_plan_id) {
			this.updateArrows(state.fixed_plan_id);
			this.input.value = state.fixed_plan_id || '';
			this.forceUpdate();
			return;
		}

		if (state.plan_type === 'fixed' && prevState.plan_type !== 'fixed') {
			this.input.value = '';
			this.updateArrows();
			this.forceUpdate();
			return;
		}

		if (state.plan_type === 'continuously' && prevState.plan_type !== 'continuously') {
			this.radios.forEach(el => el.checked = false);
			this.input.value = this.continuousPlanId();
			this.forceUpdate();
		}
	}

	visible(state) {
		return state.skipped_intro === '1' && !! state.plan_type;
	}

	continuousPlanId() {
		return this.input.dataset.continuousId;
	}

	updateArrows(planId) {
		this.arrowButtons.forEach(arrowButton => {
			const inputValue = this.find('input', arrowButton.el).value;
			if (inputValue === planId) {
				arrowButton.open();
			} else {
				arrowButton.close();
			}
		});
	}
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import mapStyles from './utils/map-style';
import MarkerClusterer from './utils/MarkerClusterer';

import LatLng from '../../../shared/shapes/LatLng';
import loadGoogleMapsAPI from '../../../shared/requests/loadGoogleMapsAPI';

import Marker from './shapes/Marker';
import MarkerId from './shapes/MarkerId';

import './google-map.scss';

class GoogleMap extends Component {

	constructor(props) {
		super(props);

		this.resizeMap = this.resizeMap.bind(this);
		this.handleZoomChanged = this.handleZoomChanged.bind(this);
		this.handleCenterChanged = this.handleCenterChanged.bind(this);
		this.handleGoogleMapLoaded = this.handleGoogleMapLoaded.bind(this);

		this.mapEl = null;
		this.isPanning = false;

		this.markers = [];

		this.currentZoom = props.zoom;
		this.currentCenter = props.center;

		this.state = {
			googleMapsApiLoaded: false,
		};
	}

	componentDidMount() {
		if (typeof window.google === 'undefined') {
			loadGoogleMapsAPI(this.props.apiKey, this.handleGoogleMapLoaded);
		} else {
			this.handleGoogleMapLoaded();
		}
	}

	componentWillReceiveProps(nextProps) {

		// Did markers update
		if (this.props.markers !== nextProps.markers) {
			this.replaceMarkers(nextProps.markers, nextProps.fitBoundsToMarkers);
		}

		// Pan to focus location
		if (this.props.currentMarkerId !== nextProps.currentMarkerId) {
			this.setCurrentMarker(nextProps.currentMarkerId, this.props.currentMarkerId);
		}

		// Check for height update
		if (nextProps.height !== this.props.height) {

			// Wait for css transition to complete
			setTimeout(this.resizeMap, 400);
		}

		// Update zoom
		if (this.props.zoom !== nextProps.zoom && nextProps.zoom !== this.currentZoom) {
			if (this.map) {
				this.map.setZoom(nextProps.zoom);
			}
		}

		// Update the center
		if (this.props.center !== nextProps.center && nextProps.center
			&& (nextProps.center.lat !== this.currentCenter.lat || nextProps.center.lng !== this.currentCenter.lng)
		) {
			this.panTo(nextProps.center);
		}

		// Update current user location
		if (this.props.locationOfUser !== nextProps.locationOfUser) {
			this.updateUserLocationMarker(nextProps.locationOfUser);
		}

		if (this.props.maxZoom !== nextProps.maxZoom && this.map) {
			this.map.setOptions({maxZoom: nextProps.maxZoom});
		}

		if (this.props.minZoom !== nextProps.minZoom && this.map) {
			this.map.setOptions({minZoom: nextProps.minZoom});
		}

		if (nextProps.fitBoundsToMarkers && this.props.fitBoundsToMarkers !== nextProps.fitBoundsToMarkers) {
			this.replaceMarkers(nextProps.markers, nextProps.fitBoundsToMarkers);
		}
	}

	shouldComponentUpdate(nextProps) {
		return this.props.height !== nextProps.height;
	}

	render() {
		return (
			<div
				style={{height: this.getHeightProperty()}}
				className="google-map"
				ref={el => this.mapEl = el}/>
		);
	}

	getHeightProperty() {
		if (typeof this.props.height === 'string') {
			return this.props.height;
		}

		return this.props.height.toString() + 'px';
	}

	resizeMap() {
		if (! this.map) {
			return;
		}

		google.maps.event.trigger(this.map, 'resize');
	}

	initializeMap() {
		const options = {
			zoom: this.props.zoom,
			center: this.props.center,
			disableDefaultUI: true,
			styles: mapStyles,
			gestureHandling: 'greedy',
			optimized: false,
			minZoom: this.props.minZoom,
			maxZoom: this.props.maxZoom,
		};

		const map = new google.maps.Map(this.mapEl, options);

		map.addListener('zoom_changed', this.handleZoomChanged);
		map.addListener('center_changed', this.handleCenterChanged);

		this.map = map;

		this.updateUserLocationMarker(this.props.locationOfUser);
	}

	updateUserLocationMarker(latLng) {
		if (! this.props.getMarkerIconForUser || ! this.map) {
			return;
		}

		if (! latLng) {
			if (this.userMarker) {
				this.userMarker.setMap(null);
			}
			return;
		}

		if (! this.userMarker) {
			this.userMarker = new google.maps.Marker({
				position: latLng,
				icon: this.props.getMarkerIconForUser(),
				map: this.map,
			});
		} else {
			this.userMarker.setPosition(latLng);
		}
	}

	replaceMarkers(markerData, fitBounds = false) {
		this.removeAllMarkers();

		this.markers = this.generateMarkers(markerData);
		this.addMarkers(this.markers);

		if (fitBounds) {
			this.fitBoundsToMarkers(this.markers);
		}

		if (this.props.onMarkersReplaced) {
			this.props.onMarkersReplaced(this.map);
		}
	}

	addMarkers(markers) {
		if (this.props.getClustererOptions) {
			this.cluster = new MarkerClusterer(this.map, markers, this.props.getClustererOptions());
		} else {
			Object.keys(markers).forEach(id => {
				markers[id].setMap(map);
			});
		}
	}

	removeAllMarkers() {
		if (this.cluster) {
			this.cluster.clearMarkers();
		}

		if (this.markers) {
			Object.keys(this.markers).forEach(id => this.markers[id].setMap(null));
		}

		this.markers = [];
	}

	fitBoundsToMarkers(markers) {
		const bounds = new google.maps.LatLngBounds();
		Object.keys(markers).forEach(id => {
			bounds.extend(markers[id].getPosition());
		});

		this.map.fitBounds(bounds);
	}

	setCurrentMarker(markerId, previousMarkerId = null) {
		if (markerId) {
			const marker = this.markers[markerId];

			if (this.props.getMarkerIconForCurrent) {
				marker.setIcon(this.props.getMarkerIconForCurrent());
			}

			this.panTo(marker.position);
		}

		if (previousMarkerId) {
			const previousMarkerData = this.props.markers[previousMarkerId];
			const previousMarker = this.markers[previousMarkerId];

			previousMarker.setIcon(this.props.getMarkerIcon(previousMarkerData));
		}
	}

	panTo(latLng) {
		if (! this.map) {
			return;
		}

		this.isPanning = true;
		this.map.panTo(latLng);

		setTimeout(() => {
			this.isPanning = false;
		}, 600);
	}

	generateMarkers(markerData) {
		const markers = {};
		Object.keys(markerData).forEach(locationId => {
			const marker = new google.maps.Marker({
				position: markerData[locationId].gps,
				icon: this.props.getMarkerIcon(markerData[locationId]),
				locationId: locationId,
			});

			google.maps.event.addListener(marker, 'click', () => {
				this.handleMarkerClicked(markerData[locationId]);
			});

			markers[locationId] = marker;
		});

		return markers;
	}

	handleMarkerClicked(location) {
		if (this.props.onMarkerSelected) {
			this.props.onMarkerSelected(location);
		}
	}

	handleZoomChanged() {
		this.currentZoom = this.map.getZoom();

		if (this.props.onZoomChanged) {
			this.props.onZoomChanged(this.currentZoom);
		}
	}

	handleCenterChanged() {
		this.currentCenter = {
			lat: this.map.getCenter().lat(),
			lng: this.map.getCenter().lng(),
		};

		if (this.props.onCenterChanged && ! this.isPanning) {
			this.props.onCenterChanged(this.currentCenter);
		}
	}

	handleGoogleMapLoaded() {
		this.setState({googleMapsApiLoaded: true});

		this.initializeMap();
		this.replaceMarkers(this.props.markers);

		if (this.props.onMapReady) {
			this.props.onMapReady(this.map);
		}

		if (this.props.fitBoundsToMarkers) {
			this.fitBoundsToMarkers(this.markers);
		}

		if (this.props.currentMarkerId) {
			this.setCurrentMarker(this.props.currentMarkerId);
		}
	}
}

GoogleMap.propTypes = {
	apiKey: PropTypes.string.isRequired,
	markers: PropTypes.objectOf(Marker),
	locationOfUser: LatLng,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	fitBoundsToMarkers: PropTypes.bool,
	currentMarkerId: MarkerId,

	zoom: PropTypes.number.isRequired,
	maxZoom: PropTypes.number,
	minZoom: PropTypes.number,
	onZoomChanged: PropTypes.func,
	center: LatLng.isRequired,
	onCenterChanged: PropTypes.func,
	onMarkerSelected: PropTypes.func,
	onMarkersReplaced: PropTypes.func,
	onMapReady: PropTypes.func,

	getMarkerIcon: PropTypes.func.isRequired,
	// Current location gets same icon as default when not provided
	getMarkerIconForCurrent: PropTypes.func,
	// Disable current user when not provided
	getMarkerIconForUser: PropTypes.func,
	// Clustering is disabled if no options are provided
	getClustererOptions: PropTypes.func,
};

GoogleMap.defaultProps = {
	markers: [],
	height: 300,
};

export default GoogleMap;

import View from 'Core/View.js';
import './slider.scss';

import { Draggable } from 'gsap/Draggable';

// Prevent tree shaking
(() => {})([Draggable]);

export default class Slider extends View {
	initialize() {
		this.setupUI();
		this.setupEventListeners();
	}

	destroy() {
		if(this.draggable) {
			this.draggable.kill();
		}
	}

	setupUI() {
		this.ui = {
			wrapper: this.el.querySelector('.js-slider-wrapper'),
			content: this.el.querySelector('.js-slider-content'),
			columns: [...this.el.querySelectorAll('.js-slider-column')],
		};
	}

	setupEventListeners() {
		const scrollProxy = document.createElement('div');
		this.draggable = new Draggable(this.ui.wrapper, {
			type: 'scrollLeft',
			proxy: scrollProxy,
			edgeResistance: 0.9,
			lockAxis: true,
			throwProps: true,
			cursor: 'grab',
		});
	}
}

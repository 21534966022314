import DefaultPage from '../DefaultPage/DefaultPage.js';
import TweenLite from 'gsap/TweenLite';
import FAQQuestion from './FAQQuestion';
import ScrollService from '../../../services/ScrollService.js';
import Sticky from 'utils/sticky/Sticky.js';

import './faq.scss';

const FAQ_CATEGORIES_OFFSET_Y = 176;
const FAQ_CATEGORIES_OFFSET_HEIGHT_MARGIN = 8;

export default class FAQPage extends DefaultPage {

	initialize() {
		super.initialize();

		this.searchInput = this.find('.faq__search-input');
		this.clearSearchElement = this.find('.faq__clear-search');
		this.searchWrapperElement = this.find('.faq__search-input-wrapper');
		this.noResultsElement = this.find('.faq__no-results');

		this.categoriesElement = this.find('.faq__categories');
		this.categoriesWrapper = this.find('.faq__categories-wrapper');
		this.faqQuestionElements = this.findAll('.faq-question');
		this.mostAskedActiveAnswerElement = this.find('.faq__most-asked-active-answer');

		this.mostAskedWrapperElement = this.find('.faq__most-asked-wrapper');
		this.mostAskedQuestions = this.findAll('.faq__most-asked-wrapper .faq-question');

		this.categoriesTitles = this.findAll('.faq__category-title');

		this.categories = this.findAll('.faq__category-select');
		this.categories.forEach((category) => {
			category.addEventListener('click', this.onCategoryClick.bind(this));
		});

		this.questions = [];
		this.faqQuestionElements.forEach((questionElement) => {
			const question = new FAQQuestion(questionElement);

			question.expandSignal.add(this.onQuestionExpanding.bind(this));
			question.expandedSignal.add(this.onQuestionExpanded.bind(this));
			question.collapseSignal.add(this.onQuestionCollapse.bind(this));
			question.collapsedSignal.add(this.onQuestionCollapsed.bind(this));
			
			this.questions.push(question);
		});

		// Create sticky menu
		this.sticky = new Sticky(this.categoriesWrapper, FAQ_CATEGORIES_OFFSET_Y);

		this.update = this.update.bind(this);

		window.addEventListener('scroll', this.update);
		window.addEventListener('resize', this.update);

		this.searchInput.addEventListener('change', this.onSearch.bind(this));
		this.searchInput.addEventListener('keyup', this.onSearch.bind(this));

		this.clearSearchElement.addEventListener('click', this.clearSearch.bind(this));

		this.clearSearch();
		this.update();
	}

	clearSearch() {
		this.searchInput.value = '';

		this.questions.forEach((question) => {
			question.show();
		});

		this.searchWrapperElement.classList.remove('active');
		this.collapseAllQuestions();
		this.toggleNoSearch(false);
		this.checkActiveCategories();
	}

	onSearch() {
		const searchQuery = this.searchInput.value.toLowerCase();

		this.filterOnText(searchQuery);

		this.searchWrapperElement.classList.add('active');
		this.collapseAllQuestions();
	}

	filterOnText(text) {

		var resultCount = 0;
		this.questions.forEach((question) => {
			if(question.containsText(text)) {
				resultCount++;
				question.show();
			} else {
				question.hide();
			}
		});

		this.toggleNoSearch(resultCount == 0);
		this.checkActiveCategories();
	}

	toggleNoSearch(on = true) {
		if(!on) {
			this.noResultsElement.classList.add('disabled');
		} else {
			this.noResultsElement.classList.remove('disabled');
		}
	}

	checkActiveCategories() {
		this.categories.forEach((category) => {
			const categoryId = category.dataset.id;
			var hasQuestions = false;

			for(var i=0 ; i<this.questions.length ; i++) {
				const question = this.questions[i];

				if(!question.hidden && question.categoryId === categoryId) {
					hasQuestions = true;
					break;
				}
			}

			this.toggleCategory(categoryId, hasQuestions);
		});
	}

	toggleCategory(categoryId, on = true) {

		const categorySelectElement = this.find('.faq__category-select[data-id="' + categoryId + '"]');
		const categoryTitleElement = this.find('#faq-category-' + categoryId );

		if(on) {
			categorySelectElement.classList.remove('disabled');
			categoryTitleElement.classList.remove('disabled');
		} else {
			categorySelectElement.classList.add('disabled');
			categoryTitleElement.classList.add('disabled');
		}
	}

	onCategoryClick(event) {
		event.preventDefault();

		const categoryId = event.currentTarget.getAttribute('href');
		const categoryHeader = this.find(categoryId);
		ScrollService.scrollPageToElement(categoryHeader, FAQ_CATEGORIES_OFFSET_Y);
	}

	collapseAllQuestions() {
		this.collapseMostAskedAnswer();
		this.questions.forEach((question) => {
			question.collapse();
		});
	}

	collapseMostAskedAnswer() {
		TweenLite.to(this.mostAskedActiveAnswerElement, 0.3, {
			height: 0, ease: Sine.easeInOut, onComplete:() => {
				this.mostAskedActiveAnswerElement.style.display = 'none';
			}
		});
	}

	expandMostAskedAnswer() {
		if(this.nextQuestion) {

			// Set active question and reset queued
			const faqQuestion = this.nextQuestion;
			this.nextQuestion = null;
			this.currentQuestion = faqQuestion;

			// Remove current answer element
			if (this.mostAskedActiveAnswerElement.firstChild) {
				this.mostAskedActiveAnswerElement.removeChild(this.mostAskedActiveAnswerElement.firstChild);
			}

			// Remove wrapper from questions so that it doesn't mess up any calculations
			this.mostAskedWrapperElement.removeChild(this.mostAskedActiveAnswerElement);

			if(faqQuestion) {

				// Find location of question (row)
				var index = -1;
				const columns = window.innerWidth < 768 ? 1 : 3;
				this.mostAskedQuestions.forEach((question, i) => {
					question.style.opacity = 1;
					if(question == faqQuestion.el) {
						index = i;
					}
				});

				index = Math.min(index, this.mostAskedWrapperElement.children.length - 1);
				index = index + (columns - (index % columns));

				// Re-add answer element
				if(index >= this.mostAskedWrapperElement.children.length) {
					this.mostAskedWrapperElement.appendChild(this.mostAskedActiveAnswerElement);
				} else {
					this.mostAskedWrapperElement.insertBefore(this.mostAskedActiveAnswerElement, this.mostAskedWrapperElement.children[index]);
				}

				this.nextQuestion = null;

				// Insert question answer into most asked question answer element
				const answerElement = faqQuestion.getAnswer();
				this.mostAskedActiveAnswerElement.appendChild(answerElement);
				TweenLite.set(this.mostAskedActiveAnswerElement, {
					height: 0, display : 'block'
				});

				// Animate answer expanding
				TweenLite.to(this.mostAskedActiveAnswerElement, 0.3, {
					height: answerElement.offsetHeight + 32, ease: Sine.easeInOut
				});
			}
		}
	}

	onQuestionExpanding(faqQuestion) {

		// Close all questions
		this.questions.forEach((question) => {
			if(!(question == faqQuestion)) {
				question.collapse();
			}
		});

		TweenLite.killDelayedCallsTo(this.expandMostAskedAnswer);

		if(faqQuestion.isMostAsked) {
			this.nextQuestion = faqQuestion;

			if(this.currentQuestion && this.currentQuestion.isMostAsked) {
				TweenLite.delayedCall(0.3, this.expandMostAskedAnswer, null, this);
			} else {
				this.expandMostAskedAnswer();
			}
		} else {
			this.currentQuestion = faqQuestion;
		}
	}


	onQuestionExpanded(faqQuestion) {
		ScrollService.scrollPageToElement(faqQuestion.el, 100);
	}

	onQuestionCollapse(faqQuestion) {
		if(faqQuestion.isMostAsked && faqQuestion === this.currentQuestion) {
			this.collapseMostAskedAnswer();
		}
	}

	onQuestionCollapsed(faqQuestion) {
	}

	update() {



		this.checkActiveCategory();
		this.sticky.update();
	}

	checkActiveCategory() {
		var closestTitle = null;

		this.categoriesTitles.forEach((categoryTitle) => {
			const rect = categoryTitle.getBoundingClientRect();

			if(rect.top <= FAQ_CATEGORIES_OFFSET_Y + FAQ_CATEGORIES_OFFSET_HEIGHT_MARGIN) {
				closestTitle = categoryTitle;
			}
		});

		closestTitle = !closestTitle ? this.categoriesTitles[0] : closestTitle;

		const activeCategory = '#' + closestTitle.getAttribute('id');
		this.categories.forEach((category) => {
			if(category.getAttribute('href') === activeCategory) {
				category.classList.add('selected');
			} else {
				category.classList.remove('selected');
			}
		})
	}

	destroy() {
		super.destroy();

		window.removeEventListener('scroll', this.update);
		window.removeEventListener('resize', this.update);

		this.questions.forEach((question) => {
			question.destroy();
		})
	}
}
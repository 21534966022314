import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import eventToDates from '../../eventToDates';
import Event from '../../../../shapes/Event';

import './truck-locator-event.scss';

class TruckLocatorEvent extends React.PureComponent {

	constructor(props) {
		super(props);

		this.handleEventClick = this.handleEventClick.bind(this);

		this.variant = 1 + Math.floor(Math.random() * 4);
	}

	render() {
		const classes = classNames({
			'truck-locator-event': true,
			'truck-locator-event--in-past': this.props.event.inPast,
			['truck-locator-event--cm-' + this.variant]: true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<a onClick={this.handleEventClick}
			   href="#"
			   className={classes}>

				<h3 className="truck-locator-event__title">
					{this.props.event.name}
				</h3>
				<p className="truck-locator-event__date">
					{eventToDates(this.props.event, this.props.locale)}
				</p>
			</a>
		);
	}

	handleEventClick(event) {
		event.preventDefault();

		if (this.props.onClick) {
			this.props.onClick(this.props.event);
		}
	}
}

TruckLocatorEvent.propTypes = {
	event: Event.isRequired,
	locale: PropTypes.string.isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default TruckLocatorEvent;

import View from '../../../../../shared/Core/View';

import './delivery-address.scss';

export default class DeliveryAddress extends View {

    initialize() {

    }

    destroy() {
    }
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ReactContainer extends Component {

	render() {
		return (
			<div className="react-container">
				{ this.props.children }
			</div>
		);
	}
}

ReactContainer.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ReactContainer;
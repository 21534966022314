import TweenLite from 'gsap/TweenLite';

const SKEW_ANGLE = 2.5;
const MAX_EDGE_WIDTH = 0;

export default class AnimatedBackgroundSection {

	constructor(el, index, max, screen) {
		this.el = el;
		this.id = index + 2;
		this.index = index;
		this.max = max;
		this.type = this.el.dataset.type;
		this.screen = screen;

		this.x = 0;
		this.backgroundStyle = '';
		this.width = document.body.clientWidth;
	}

	startTransition(toIndex, duration) {
		const skewAngle = (this.index - toIndex) * SKEW_ANGLE;
		this.index = toIndex;
		this.width = this.calculateWidth();
		this.x = this.calculateX();
		this.el.dataset.index = null;

		TweenLite.killTweensOf(this.el, {x: true, width: true, skewX: true});
		TweenLite.to(this.el, duration, {
			x: this.x,
			background: this.backgroundStyle,
			width: this.width,
			bezier: {curviness: 0.2, values: [{skewX: 0}, {skewX: skewAngle}, {skewX: 0}]},
			skewType: 'simple',
			ease: Cubic.easeInOut,
			force3D: true,
			onComplete: this.onTransitionComplete,
			onCompleteScope: this
		});
	}

	calculateX() {
		const edgeWidth = this.calculateEdgeWidth();
		return this.index === 0 ? edgeWidth : this.index * document.body.clientWidth - edgeWidth;
	}

	calculateWidth() {
		const edgeWidth = this.calculateEdgeWidth();
		return this.index === 0 ? document.body.clientWidth - 2 * edgeWidth : document.body.clientWidth + 2 * edgeWidth;
	}

	calculateEdgeWidth() {
		if (this.screen.breakpoint === 'mobile') {
			return 0;
		}

		return MAX_EDGE_WIDTH;
	}

	applyCustomTemplate(color) {
		// only apply color, image should be applied to .container
		if (color) {
			TweenLite.killTweensOf(this.el, 'background');

			this.backgroundStyle = `${color}`;
			this.el.style.background = this.backgroundStyle;
		}
	}

	clearCustomTemplate() {
		this.backgroundStyle = '';
		this.el.style.background = '';

		TweenLite.killTweensOf(this.el, 'background');
	}

	onTransitionComplete() {

		if (this.index < - this.max * 0.5) {
			this.index += this.max;
			TweenLite.set(this.el, {x: this.calculateX(), width: this.calculateWidth(), background: this.backgroundStyle});
		}
		if (this.index > this.max * 0.5) {
			this.index -= this.max;
			TweenLite.set(this.el, {x: this.calculateX(), width: this.calculateWidth(), background: this.backgroundStyle});
		}

		this.el.dataset.index = this.index;
	}
}

import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

const DEFAULT_PAGE_VERTICAL_DISPLACEMENT = 50;
const DEFAULT_PAGE_VERTICAL_DISPLACEMENT_WIDGET = 100;

export default class DefaultPage extends View {

	initialize() {

		this.hide = this.hide.bind(this);
		this.show = this.show.bind(this);
		this.onShown = this.onShown.bind(this);
		this.onHidden = this.onHidden.bind(this);

		this.signals.backgroundAnimation.stays.addOnce(this.hide);

		TweenLite.set(this.el, {opacity: 0, y: DEFAULT_PAGE_VERTICAL_DISPLACEMENT});

		this.initializeWidgets();
		this.show();
	}

	initializeWidgets() {
		// Setup elements for 'in' animation
		this.widgets = this.findAll('.widget,.animatable');
		this.widgets.forEach((widget) => {
			TweenLite.set(widget, {opacity: 0, y: DEFAULT_PAGE_VERTICAL_DISPLACEMENT_WIDGET, rotation: - 3 + Math.random() * 6});
		});
	}

	show(widgetsOnly = false) {
		if(!widgetsOnly) {
			TweenLite.to(this.el, 0.3, {
				opacity: 1, y: 0,
				ease: Linear.easeNone, onComplete: this.onShown, onCompleteScope: this
			});
		}

		this.widgets.forEach((widget, index) => {
			const delay = 0.3 + index * 0.06;
			TweenLite.to(widget, 0.3, {
				opacity: 1, y: 0, force3D: true, delay: delay, rotation: 0,
				ease: Cubic.easeOut,
				onComplete: this.onShown
			});
		});
	}

	onShown() {
	}

	hide(widgetsOnly = false) {

		// Hide widgets
		this.widgets.forEach((widget) => {
			const rotation = - 3 + Math.random() * 6;
			TweenLite.to(widget, 0.3, {
				opacity: 0, y: DEFAULT_PAGE_VERTICAL_DISPLACEMENT_WIDGET, force3D: true, rotation: rotation,
				ease: Cubic.easeIn
			});
		});

		if(!widgetsOnly) {
			// Hide entire page
			TweenLite.to(this.el, 0.3, {
				opacity: 0, y: DEFAULT_PAGE_VERTICAL_DISPLACEMENT, delay: 0.3,
				ease: Linear.easeNone, onCompleteScope: this,
				onComplete: this.onHidden
			});
		}
	}

	onHidden() {
	}

	destroy() {
		this.signals.backgroundAnimation.stays.remove(this.hide);
	}
}
export default class ChapterImageLoader {
    constructor() {
		this.chapterImageMap = [];
	}

	// Destroy all active timeouts;
	destroy() {
		this.chapterImageMap.map(el => {
			window.clearTimeout(el.timeoutId);
		})
	}

	// helper function to get the right data.
	getDataForId(id) {
		return this.chapterImageMap.find(item => item.id === id);
	}

	// Check if callback is ready to be called
	checkImagesLoaded(chapterId) {
		const data = this.getDataForId(chapterId);
		if(data.imagesToLoad === data.imagesLoaded && !data.fallbackFired) {
			if(data.timeoutId) {
				window.clearTimeout(data.timeoutId);
			}
			data.callback();
		}
	}

    loadImagesForChapter(imageArray, chapterId, callback, fallbackDelay = 1500) {
		// Add data only the first time.
		if(!this.getDataForId(chapterId)) {
			this.chapterImageMap.push({
				id: chapterId,
				images: [...imageArray],
				loaded: false,
				imagesToLoad: imageArray.length,
				imagesLoaded: 0,
				callback,
				fallbackFired: false,
				timeoutId: null
			})
		}

		// Get data for chapter id;
		const data = this.getDataForId(chapterId);
		// Reset data when loading iamges;
		data.fallbackFired = false;
		data.imagesLoaded = 0;

		// Loop over images and create a new image element to ensure the load callback is called.
		data.images.forEach(image => {
			const tempImage = document.createElement('img');
			tempImage.addEventListener('load', () => {
				data.imagesLoaded++;
				this.checkImagesLoaded(chapterId);
			})
			tempImage.src = image.src;
		});

		// If there is a timeout id present kill the current timeout
		if(data.timeoutId) {
			window.clearTimeout(data.timeoutId);
		}

		// Set a new timeout to prevent the user from being stuck.
		data.timeoutId = window.setTimeout(() => {
			data.fallbackFired = true;
			callback();
		}, fallbackDelay);

		// Initial check if there are iamges to be loaded (if 0 callback should be fired right away).
		this.checkImagesLoaded(chapterId);

    }

}

import View from 'Core/View.js';

import './register.scss';

export default class Register extends View {

	initialize() {
		this.handleCustomerGroupChange = this.handleCustomerGroupChange.bind(this);
		this.handleCheckoutOptionChange = this.handleCheckoutOptionChange.bind(this);
		this.handleSeparateShippingAddressChange = this.handleSeparateShippingAddressChange.bind(this);
		this.handleHasNoVatChange = this.handleHasNoVatChange.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
		this.modifyDataBeforeSubmit = this.modifyDataBeforeSubmit.bind(this);

		this.formId = this.el.id;

		this.customerGroupFields = this.findAll('[data-customer-group]');
		this.passwordFields = this.find('.register__password-fields');
		this.customerGroupRadioButtons = this.findAll('input[type="radio"][name="customer_group_id"]');
		this.hiddenCustomerGroupInput = this.find('input[type="hidden"][name="customer_group_id"]');
		this.checkoutOptions = this.find('.register__checkout-options');
		this.checkoutOptionRadioButtons = this.findAll('input[name="guest_checkout_option"]');
		this.hasNoVat = this.find('.register__has-no-vat');
		this.vatField = this.find('.register__field--vat');
		this.separateShippingAddressCheckbox = this.find('.register__wants-other-shipping-address');
		this.separateShippingAddressSection = this.find('.register__shipping-address');
		this.emailInput = this.find('input[name="email"]');
		this.invoiceEmailInput = this.find('input[name="invoice_email"]');

		this.signals.forms.beforeSubmit.add(this.modifyDataBeforeSubmit);

		this.passwordsVisible = true;
		this.lastEmailValue = this.emailInput.value;

		if (this.customerGroupRadioButtons && this.customerGroupRadioButtons.length > 0) {
			this.customerGroupRadioButtons.forEach((radioButton ) => {
				radioButton.addEventListener('change', this.handleCustomerGroupChange);
				if (radioButton.checked) {
					this.toggleCustomerGroupFields(parseInt(radioButton.value));
				}
			});
		} else if (this.hiddenCustomerGroupInput) {
			this.toggleCustomerGroupFields(parseInt(this.hiddenCustomerGroupInput.value));
		} else {
			// Fallback to default customer group
			this.toggleCustomerGroupFields(1);
		}

		if (this.checkoutOptionRadioButtons.length > 0) {
			this.checkoutOptionRadioButtons.forEach((checkoutOption) => {
				checkoutOption.addEventListener('change', this.handleCheckoutOptionChange);

				if(checkoutOption.value === 'guest-account' && checkoutOption.checked) {
					this.togglePasswordFields(false);
					this.toggleBusinessGroupOption(true);
				}
				if(checkoutOption.value === 'create-account' && checkoutOption.checked) {
					this.togglePasswordFields(true);
					this.toggleBusinessGroupOption(false);
				}
			})
		}

		if (this.hasNoVat) {
			this.hasNoVat.addEventListener('change', this.handleHasNoVatChange);

			this.toggleVatField(this.hasNoVat.checked);
		}

		if (this.separateShippingAddressCheckbox) {
			this.separateShippingAddressCheckbox.addEventListener('change', this.handleSeparateShippingAddressChange);
			this.setSeparateShippingAddressChange(this.separateShippingAddressCheckbox.checked);
		}

		if (this.invoiceEmailInput) {
			this.emailInput.addEventListener('input', this.handleEmailChange);
			this.handleEmailChange();
		}
	}

	destroy() {
		this.signals.forms.beforeSubmit.remove(this.modifyDataBeforeSubmit);

		if (this.customerGroupRadioButtons && this.customerGroupRadioButtons.length > 0) {
			this.customerGroupRadioButtons.forEach((radioButton ) => {
				radioButton.removeEventListener('change', this.handleCustomerGroupChange);
			});
		}

		if (this.checkoutOptionRadioButtons.length > 0) {
			this.checkoutOptionRadioButtons.forEach((checkoutOption) => {
				checkoutOption.removeEventListener('change', this.handleCheckoutOptionChange);
			})
		}

		if (this.separateShippingAddressCheckbox) {
			this.separateShippingAddressCheckbox.removeEventListener('change', this.handleSeparateShippingAddressChange);
		}

		if (this.hasNoVat) {
			this.hasNoVat.removeEventListener('change', this.handleHasNoVatChange);
		}

		if (this.invoiceEmailInput) {
			this.emailInput.removeEventListener('input', this.handleEmailChange);
		}
	}

	handleCustomerGroupChange(event) {
		this.toggleCustomerGroupFields(parseInt(event.target.value));
	}

	handleCheckoutOptionChange(event) {
		this.togglePasswordFields(event.target.value !== 'guest-account');
		this.toggleBusinessGroupOption(event.target.value === 'guest-account');
	}

	handleHasNoVatChange(event) {
		this.toggleVatField(event.target.checked);
	}

	handleSeparateShippingAddressChange(event) {
		this.setSeparateShippingAddressChange(event.target.checked);
	}

	setSeparateShippingAddressChange(useSame) {
		this.separateShippingAddressSection.style.display = useSame ? 'block' : 'none';
	}

	toggleVatField(hidden) {
		this.vatField.style.display = hidden ? 'none' : 'block';

		if (hidden) {
			this.vatField.querySelector('input').value = '';
		}
	}

	toggleCustomerGroupFields(groupId) {
		this.customerGroupFields.forEach(el => {
			const matchingIds = el.dataset.customerGroup.split(',').map(id => parseInt(id));
			if (matchingIds.length === 0) {
				console.warn('[TONYS] No customer group ids found for field', el);
				return;
			}

			if (matchingIds.indexOf(groupId) !== -1) {
				el.classList.add('register__customer-group-field--visible');
			} else {
				el.classList.remove('register__customer-group-field--visible');
				this.clearInputFieldsOfElement(el);
			}
		});

		// Hide guest checkout options when business fields active and always show password fields
		if (this.checkoutOptions) {
			this.checkoutOptions.style.display = groupId > 1 ? 'none' : 'block';
			this.togglePasswordFields(true);
		}
	}

	togglePasswordFields(visible) {
		if (visible) {
			this.passwordFields.classList.add('register__password-fields--toggled');
		} else {
			this.passwordFields.classList.remove('register__password-fields--toggled');
		}

		this.passwordsVisible = visible;
	}

	clearInputFieldsOfElement(el) {
		const inputTypes = ['email', 'file', 'number', 'password', 'search', 'tel', 'text'];
		const query = inputTypes.map(type => 'input[type="' + type + '"]').join(',');
		const inputs = [].slice.call(el.querySelectorAll(query));
		inputs.forEach(input => input.value = '');
	}

	modifyDataBeforeSubmit(formId, formData) {

		if (formId !== this.formId) {
			return;
		}

		// Create account
		if (this.passwordsVisible) {
			formData.append('password', this.find('input[name="password"]').value);
			formData.append('password_confirmation', this.find('input[name="password_confirmation"]').value);
		// Guest checkout
		} else {
			formData.delete('password');
			formData.delete('password_confirmation');
		}
	}

	handleEmailChange() {
		/**
		 * Only copy email to invoice email field if not changed by user.
		 */
		if (this.invoiceEmailInput && (this.lastEmailValue === this.invoiceEmailInput.value || this.invoiceEmailInput.value === '')) {
			this.invoiceEmailInput.value = this.emailInput.value;
			this.invoiceEmailInput.dispatchEvent(new Event('change'));
		}

		this.lastEmailValue = this.emailInput.value;
	}

	toggleBusinessGroupOption(enabled) {
		this.customerGroupRadioButtons.forEach((radioButton) => {

			if (radioButton.dataset.accountRequired) {
				radioButton.parentElement.classList.toggle('radio--disabled', enabled)
				radioButton.disabled = enabled ? 'disabled' : '';
			}
		});
	}
}

import View from 'Core/View.js';

export default class ProductDetailContentMask extends View {
	initialize() {
		this.topThreshold = 166;
		this.bottomThreshold = 100;
		this.height = 100;
		this.disabled = !this.el.classList.contains('product-detail__product-content-info-wrapper--sticky');

		this.update = this.update.bind(this);
		this.render = this.render.bind(this);

		window.addEventListener('scroll', this.update);
		window.addEventListener('resize', this.update);

		this.render();
	}

	destroy() {
		this.destroyed = true;
		window.removeEventListener('scroll', this.update);
		window.removeEventListener('resize', this.update);
	}

	render() {
		if (this.disabled) {
			return;
		}

		this.update();
		if (!this.destroyed) {
			window.requestAnimationFrame(this.render);
		}
	}

	update(event) {
		if (this.disabled) {
			return;
		}

		const rect = this.el.getBoundingClientRect();

		let from = -rect.top + this.topThreshold;
		let to = from + this.height;
		let direction = 'to bottom';

		// Mobile, from bottom to up
		if (window.innerWidth < 768) {
			direction = 'to top';
			from = rect.bottom - window.innerHeight + this.bottomThreshold;
			to = from + this.height;
		}

		const webkitMask = `-webkit-mask-image: linear-gradient(${direction}, transparent ${from}px, black ${to}px);`;
		const mask = `mask-image: linear-gradient(${direction}, transparent ${from}px, black ${to}px)`;

		this.el.setAttribute('style', webkitMask + ';' + mask + ';');
	}
}

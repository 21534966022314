import TweenLite from 'gsap/TweenLite';

import View from '../../../shared/Core/View';
import TemplateType from '../../../shared/enums/TemplateType';

import HeaderTypeVideo from './Variants/HeaderTypeVideo';
import HeaderTypeImage from './Variants/HeaderTypeImage';
import HeaderTypeAnimation from './Variants/HeaderTypeAnimation';
import Mission from './Variants/Mission';
import Shop from './Variants/Shop';

import barSignals from './barSignals';

import './Variants/header-types.scss';
import './bar.scss';

export default class Bar extends View {

	initialize(options) {
		this._onBeforeAppear = this._onBeforeAppear.bind(this);
		this._onAppearCompleted = this._onAppearCompleted.bind(this);
		this._onPreloadCompleted = this._onPreloadCompleted.bind(this);
		this._onNavigated = this._onNavigated.bind(this);
		this._onBackgroundAnimationStarted = this._onBackgroundAnimationStarted.bind(this);
		this._onBackgroundAnimationStaysSame = this._onBackgroundAnimationStaysSame.bind(this);
		this._onBackgroundAnimationCompleted = this._onBackgroundAnimationCompleted.bind(this);

		this.barSignals = barSignals;

		const collaborateComponent = this._componentTypeFor(
			this._findHeaderByVariant(TemplateType.COLLABORATE)
		);

		this.variants = {
			[TemplateType.COLLABORATE]: this._buildVariant(options, TemplateType.COLLABORATE, collaborateComponent),
			[TemplateType.MISSION]: this._buildVariant(options, TemplateType.MISSION, Mission),
			[TemplateType.SHOP]: this._buildVariant(options, TemplateType.SHOP, Shop),
		};

		this.lastDirection = -1;
		this.nextVariantRoute = options.routeAtLoad;
		this.nextVariantName = options.routeAtLoad.template;
		this.currentVariant = null;
		this.currentVariantName = null;

		this.barSignals.preloadCompleted.add(this._onPreloadCompleted);
		this.signals.pageLoad.started.add(this._onNavigated);
		this.signals.backgroundAnimation.started.add(this._onBackgroundAnimationStarted);
		this.signals.backgroundAnimation.stays.add(this._onBackgroundAnimationStaysSame);
		this.signals.backgroundAnimation.completed.add(this._onBackgroundAnimationCompleted);

		this._startPreloading();
	}

	postBuild() {
		Object.keys(this.variants).forEach((variantName) => {
			const view = this.variants[variantName];
			if (typeof view.postBuild !== 'function') {
				return;
			}

			view.postBuild();
		});
	}

	destroy() {
	}

	switchVariant() {
		if (this.currentVariant) {
			this.disappear();
		}

		this.currentVariant = this.variants[this.nextVariantName];
		this.currentVariantName = this.nextVariantName;
		this.nextVariantName = null;
	}

	appear() {
		if(this._dontShow()) {
			return;
		}

		this._onBeforeAppear();
		this.currentVariant.el.style.display = 'flex';
		this._onAppearCompleted();
	}

	disappear() {
		this.currentVariant.el.style.display = 'none';
	}

	appearFromSide() {
		if(this._dontShow()) {
			return;
		}

		TweenLite.fromTo(this.currentVariant.el, 1,
			{
				display: 'flex',
				y: 0,
				x: document.body.clientWidth * this.lastDirection,
			},
			{
				display: 'flex',
				x: 0,
				y: 0,
				ease: Cubic.easeInOut,
				onStart: this._onBeforeAppear,
				onComplete: this._onAppearCompleted,
			},
		);
	}

	disappearToSide(direction) {
		this.lastDirection = direction;

		const current = this.currentVariant;

		TweenLite.killTweensOf(current.el);
		TweenLite.to(current.el, 1, {
			x: document.body.clientWidth * direction,
			ease: Cubic.easeInOut,
			onComplete: () => {
				if (typeof current.onAfterDisappear === 'function') {
					current.onAfterDisappear();
				}
			},
		});
	}

	_componentTypeFor(el) {
		if (!el) {
			return HeaderTypeImage;
		}

		if (el.classList.contains('header-type--animation')) {
			return HeaderTypeAnimation;
		}

		if (el.classList.contains('header-type--video')) {
			return HeaderTypeVideo;
		}

		if (el.classList.contains('header-type--image')) {
			return HeaderTypeImage;
		}

		throw new Error('Cannot detect header type for element.');
	}

	_buildVariant(options, name, Component) {
		const el = this._findHeaderByVariant(name);
		if (! el) {
			return;
		}

		return new Component({...options, el: el});
	}

	_findHeaderByVariant(variantName) {
		return this.find(`[data-variant="${variantName}"]`);
	}

	_startPreloading() {
		/**
		 * Sort it so that the variant at page load is preloaded first
		 */
		this.variantsToPreload = Object.keys(this.variants)
			.sort((a, b) => a === this.nextVariantName ? -1 : 1);

		this._preloadNextVariant();
	}

	_preloadNextVariant() {
		if (this.variantsToPreload.length === 0) {
			this.barSignals.allPreloadsCompleted.dispatch();
			return;
		}

		const variantName = this.variantsToPreload[0];
		const next = this.variants[variantName];

		/**
		 * Call preload on the variant if it exists
		 */
		if (typeof next.preload !== 'function') {
			this.barSignals.preloadCompleted.dispatch(variantName);
			return;
		}

		next.preload(
			() => this.barSignals.preloadCompleted.dispatch(variantName),
			() => this.barSignals.preloadFailed.dispatch(variantName),
		);
	}

	_onPreloadCompleted(variantName) {
		if (variantName === this.nextVariantName) {
			this.switchVariant();
			this.appear();
		}

		const index = this.variantsToPreload.indexOf(variantName);
		this.variantsToPreload.splice(index, 1);

		this._preloadNextVariant();
	}

	_onNavigated(route) {
		/**
		 * Keep track of the next variant name so we know what variant to
		 * switch to once the background animation has completed.
		 */
		this.nextVariantName = route.template;
		this.nextVariantRoute = route;
	}

	_onBackgroundAnimationStarted(direction) {
		if (! this.currentVariant) {
			return;
		}

		this.disappearToSide(direction);
	}

	_onBackgroundAnimationStaysSame() {
	}

	_onBackgroundAnimationCompleted() {
		this.switchVariant(this.nextVariantName);

		if (! this.currentVariant) {
			return;
		}

		this.appearFromSide();
	}

	_onBeforeAppear() {
		if (typeof this.currentVariant.onBeforeAppear === 'function') {
			this.currentVariant.onBeforeAppear();
		}
	}

	_onAppearCompleted() {
		if (typeof this.currentVariant.onAppeared === 'function') {
			this.currentVariant.onAppeared();
		}
	}

	_dontShow() {
		return !window.tonysConfig.shop.carouselEnabled && this.currentVariantName === TemplateType.SHOP
	}
}

import View from 'Core/View.js';

import './profile.scss';

export default class Profile extends View {

	initialize() {
		this.handleFormSucceeded = this.handleFormSucceeded.bind(this);
		this.handleFormFailed = this.handleFormFailed.bind(this);

		this.signals.forms.succeeded.add(this.handleFormSucceeded);
		this.signals.forms.failed.add(this.handleFormFailed);
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.handleFormSucceeded);
		this.signals.forms.failed.remove(this.handleFormFailed);

		this.onRecipientsReceived.removeAll();
	}

	handleFormFailed(formId) {
	}

	handleFormSucceeded(formId, response) {
		if (response.showModal) {
			const modal = window.tonysModals['consents-modal'];
			if (modal) {
				modal.show();
			}
		}
	}
}

import View from 'Core/View.js';

import './shop-products.scss';

export default class ShopProducts extends View {

	initialize(options) {
		this.signal = options.signals;

		this.setup = this.setup.bind(this);
		this.resize = this.resize.bind(this);

		this.signals.pageContentReplaced.add(this.setup, this, 2);

		this.options = options;

		window.addEventListener('resize', this.resize);
		this.setup();
	}

	destroy() {
		window.removeEventListener('resize', this.resize);
		this.signals.pageContentReplaced.remove(this.setup);
	}

	setup() {
		this.tiles = this.findAll('.shop-product-tile, .shop-content-tile');
		this.resize();
	}

	resize() {
		if(this.tiles.length > 0) {
			const tileWidth = this.tiles[0].offsetWidth;
			const availableWidth = this.el.offsetWidth;
			const tilesPerRow = Math.floor(availableWidth / tileWidth);
			const lastRowCount = this.tiles.length % tilesPerRow;

			this.el.dataset.lastrowfix = '0';

			if(tilesPerRow === 3) {
				if(lastRowCount === 2 ) {
					this.el.dataset.lastrowfix = '33';
				}
			}

			if(tilesPerRow === 4) {
				if(lastRowCount === 2 ) {
					this.el.dataset.lastrowfix = '50';
				}

				if(lastRowCount === 3 ) {
					this.el.dataset.lastrowfix = '25';
				}
			}
		}
	}
}

import View from 'Core/View.js';

import ResizeObserver from 'resize-observer-polyfill';
import Stickyfill from 'stickyfilljs';
import 'intersection-observer';

import './chapter.scss';
import { TweenLite, Power2 } from 'gsap/all';
import safelyHandleResizeObserver from '../../helpers/safelyHandleResizeObserver';

const STATE_START = 'start';
const STATE_LOADING_STARTED = 'loading-started';
const STATE_LOADING_COMPLETED = 'loading-completed';
const STATE_CONTENT_PARSED = 'content-parsed';
const STATE_CONTENT_ADDED = 'content-added';

export default class Chapter extends View {
	initialize(options) {
		this.id = options.id;
		(this.splashType = options.splashType), (this.contentUrl = this.el.dataset.url);
		this.rawTemplateString = '';
		this.splashManager = options.splashManager;
		this.scrollManager = options.scrollManager;
		this.chapterLoadService = options.chapterLoadService;
		this.sizeChangeHandler = options.sizeChangeHandler;

		this.state = STATE_START;
		this.contentAdded = false;

		this.setupUI();
		this.setupEventListeners();
		this.setupSplash();
		this.setupInterSectionObserver();

		// NOTE: use stickyfill for IE11;
		Stickyfill.addOne(this.ui.stickyContent);
	}

	destroy() {
		Stickyfill.removeOne(this.ui.stickyContent);

		this.resizeObserver.disconnect();
		this.interSectionObserver.disconnect();

		this.removeEventListeners();

		this.ui = null;
	}

	setupUI() {
		this.ui = {
			splash: this.el.querySelector('.js-chapter-splash'),
			splashVisuals: this.el.querySelector('.js-chapter-splash-visuals'),
			stickyContent: this.el.querySelectorAll('.js-sticky-content'),
			contentContainer: this.el.querySelector('.js-chapter-content'),
		};
	}

	setupSplash() {
		if (!this.ui.splashVisuals) {
			this.el.classList.add('is-static');
			return;
		}

		this.splash = this.splashManager.createSplash({
			id: this.id,
			type: this.splashType,
			el: this.ui.splashVisuals,
			elContainer: this.ui.splash,
		});

		if (this.splash.static) {
			this.el.classList.add('is-static');
		}
	}

	setupInterSectionObserver() {
		this.handleIntersection = this.handleIntersection.bind(this);
		this.interSectionObserver = new IntersectionObserver(this.handleIntersection);
	}

	setupEventListeners() {
		this.handleResizeObserverChange = this.handleResizeObserverChange.bind(this);
		this.handlePageLoadError = this.handlePageLoadError.bind(this);
		this.handlePageLoadSuccess = this.handlePageLoadSuccess.bind(this);
		this.handleResize = this.handleResize.bind(this);

		this.signals.windowResized.add(this.handleResize);

		this.chapterLoadService.signals.chapterLoaded.add(this.handlePageLoadSuccess);

		this.resizeObserver = new ResizeObserver(safelyHandleResizeObserver(this.handleResizeObserverChange));
		this.resizeObserver.observe(this.el);
	}

	removeEventListeners() {
		this.signals.windowResized.remove(this.handleResize);
		this.chapterLoadService.signals.chapterLoaded.remove(this.handlePageLoadSuccess);
	}

	loadAndShow() {
		// this.chapterIsVisible = true;

		switch (this.state) {
			case STATE_START:
				this.load();
				break;
			case STATE_LOADING_COMPLETED:
				this.parseContent();
				break;
			case STATE_CONTENT_PARSED:
				this.addContent();
				break;
			case STATE_CONTENT_ADDED:
				this.show();
				break;
		}
	}

	load() {
		if (this.state === STATE_START) {
			this.updateState(STATE_LOADING_STARTED);
			this.chapterLoadService.load(this.id, this.contentUrl);
			this.ui.contentContainer.classList.add('is-loading');
		}
	}

	parseContent() {
		const placeholder = document.createElement('div');
		placeholder.insertAdjacentHTML('afterbegin', this.rawTemplateString);

		this.template = placeholder.querySelector('.js-page-content');
		this.updateState(STATE_CONTENT_PARSED);

		// if(this.chapterIsVisible)
		this.addContent();
	}

	correctScrolling(oldBounds, newBounds) {
		if (newBounds.top < 0) {
			window.scrollTo(0, window.scrollY - (oldBounds.height - newBounds.height));
		}
	}

	addContent() {
		this.updateState(STATE_CONTENT_ADDED);

		this.ui.contentContainer.classList.remove('is-loading');
		const oldBounds = this.ui.contentContainer.getBoundingClientRect();

		this.sizeWrapper = document.createElement('div');
		this.sizeWrapper.classList.add('chapter__dynamic-content');
		this.sizeWrapper.insertAdjacentHTML('afterbegin', this.template.innerHTML);

		// Add Intersection Observer
		const widgets = [].slice.call(this.sizeWrapper.querySelectorAll('.widget'));
		widgets.forEach(w => this.interSectionObserver.observe(w));

		// emptyElement(this.ui.contentContainer);
		this.ui.contentContainer.appendChild(this.sizeWrapper);
		this.ui.contentContainer.style.height = `${this.sizeWrapper.getBoundingClientRect().height}px`;

		const newBounds = this.ui.contentContainer.getBoundingClientRect();
		this.correctScrolling(oldBounds, newBounds);

		this.resizeObserver.observe(this.sizeWrapper);

		this.initializeChildViews();

		Stickyfill.refreshAll();
	}

	removeContent() {
		this.ui.contentContainer.innerHTML = '';
	}

	show() {}

	hide() {}

	updateState(state) {
		this.state = state;
		// console.log(this.state, ' -> ', this.el.dataset.chapterId);
	}

	resize() {}

	checkEntryForIntersect(entry) {
		const offset = this.scrollManager.direction === 'UP' ? -240 : 240;

		if (entry.isIntersecting) {
			TweenLite.fromTo(
				entry.target,
				0.8,
				{ autoAlpha: 0, y: offset },
				{ autoAlpha: 1, y: 0, ease: Power2.easeOut }
			);
			this.interSectionObserver.unobserve(entry.target);
		}
	}

	handleResize() {
		this.resize();
	}

	handlePageLoadError() {}

	handlePageLoadSuccess(options) {
		if (options.id === this.id) {
			this.rawTemplateString = options.template;
			this.updateState(STATE_LOADING_COMPLETED);
			this.parseContent();
		}
	}

	handleResizeObserverChange() {
		if (this.sizeWrapper) {
			this.ui.contentContainer.style.height = `${this.sizeWrapper.getBoundingClientRect().height}px`;
		}

		if (this.sizeChangeHandler) {
			this.sizeChangeHandler.call();
		}

		Stickyfill.refreshAll();
	}

	handleIntersection(entries, observer) {
		entries.forEach(entry => this.checkEntryForIntersect(entry));
	}
}

import View from '../../../../shared/Core/View';
import fetchPrice from '../../../components/AddToBasket/requests/fetchPriceForOption';
import debounce from '../../../../shared/utils/generic/debounce';
import CartModel from '../../../../shared/data/CartModel';

export default class ProductDetailPrice extends View {

    initialize() {
		this.updatePriceEndpoint = this.el.dataset.priceEndpoint;
		this.updateProductIds = JSON.parse(this.el.dataset.productIds) || [];

		this.updatePriceDebounced = debounce(this.updatePrice.bind(this), 500);
		this._onCartUpdatedRequest = this._onCartUpdatedRequest.bind(this);

		this.priceEl = this.find('.product-detail-price__price');
		this.priceExVatEl = this.find('.product-detail-price__price-excl-vat__value');
		this.nextLadderEl = this.find('.product-detail-price__next-ladder');

		// Wait for Cart to be updated and update initial quantity accordingly
		CartModel.requestSuccessSignal.add(this._onCartUpdatedRequest);

    }

    destroy() {
		CartModel.requestSuccessSignal.remove(this._onCartUpdatedRequest);
    }

	updatePrice(data) {
		if (! data.product_id) {
			return;
		}

		const dataCopy = Object.assign({}, data);

		// Switch casing when fetching price
		dataCopy.customerGroupId = dataCopy.customer_group_id;
		dataCopy.productId = dataCopy.product_id;
		delete dataCopy.product_id;
		delete dataCopy.customer_group_id;

		if (!dataCopy.quantity) {
			dataCopy.quantity = 1;
		}

		fetchPrice(this.updatePriceEndpoint, dataCopy)
			.then((response) => {
				const firstKey = Object.keys(response.body)[0];
				if (this.updateProductIds.length <= 0 || this.updateProductIds.includes(parseInt(firstKey))) {
					this.updatePricesHtml(response.body[firstKey]);
				}
			})
			.catch((error) => {
				this.signals.toaster.requested.dispatch('Could not update price.' + error);
			});
	}

	_onCartUpdatedRequest(response) {
		this.updatePriceDebounced(response.req._data);

		clearTimeout(this.updateTimeout);
		this.updateTimeout = setTimeout(() => {
			const result = JSON.parse(response.text);
			if (result.priceLadder && result.priceLadder.nextLadder) {
				if (this.priceExVatEl) {
					this.nextLadderEl.innerHTML = result.priceLadder.nextLadderVat;
				} else {
					this.nextLadderEl.innerHTML = result.priceLadder.nextLadder;
				}
				this.nextLadderEl.classList.remove('product-detail-price__next-ladder--hidden');
			} else {
				this.nextLadderEl.classList.add('product-detail-price__next-ladder--hidden');
			}
		}, 300);
	}

	updatePricesHtml(price) {
		if (this.priceExVatEl) {
			this.priceEl.innerHTML = price.currencyPriceVat;
			this.priceExVatEl.innerHTML = price.currencyPrice;
			return;
		}

		this.priceEl.innerHTML = price.currencyPrice;
	}
}

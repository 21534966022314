import View from 'Core/View';

import './subscription-intro.scss';
import ArrowButton from '../components/ArrowButton';

export default class SubscriptionIntro extends View {

    initialize(options) {
		this.handleStartClick = this.handleStartClick.bind(this);

		this.forceUpdate = options.forceUpdate;

		this.startButton = this.find('.subscription-intro__start-button');
		this.startButton.addEventListener('click', this.handleStartClick);

		this.input = this.find('input[name="skipped_intro"]');

		this.arrowButton = new ArrowButton({
			...options,
			el: this.find('.subscription-intro__start-button-wrapper')
		});
    }

    destroy() {
		this.startButton.removeEventListener('click', this.handleStartClick);
    }

	targetable() {
		return false;
	}

	update(state) {
		this.toggleArrow();
	}

	visible() {
		return true;
	}

	handleStartClick() {
    	const isOn = this.input.value === '1';
		this.input.value = isOn ? '0' : '1';

		this.toggleArrow();

		this.forceUpdate();
	}

	toggleArrow() {
		if (this.input.value !== '1') {
			this.arrowButton.close();
		} else {
			this.arrowButton.open();
		}
	}
}

import BaseSplashView from './BaseSplash';

import {TimelineMax, Power2, TweenLite, Power0, Bounce} from 'gsap/all';
import { Back } from 'gsap';

export default class Splash_19_6 extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.setupUI();
		this.setupTimeline();

	}

	setupUI() {

		this.ui = {
			background: this.el.querySelectorAll('.js-background'),
			titleLines: this.el.querySelectorAll('.js-title-line'),
			bodyLines: this.el.querySelectorAll('.js-body-line'),
			foldShapes: [...this.el.querySelectorAll('.js-fold-shapes')],
			foldBgs: [...this.el.querySelectorAll('.js-fold-bgs')],
			shadows: [...this.el.querySelectorAll('.js-shadow-line')],
			animation: this.el.querySelector('.js-animation'),
			files: this.el.querySelector('.js-files'),
			hammer: this.el.querySelector('.js-hammer')
		}

	}

	update(options) {

		TweenLite.to(this.tl, options.duraton, {progress: options.progress});

	}

	setupTimeline() {

		this.tl = new TimelineMax({paused: true});

		const time = .6;
		this.tl.staggerFrom(this.ui.titleLines, .6, {y: -150, ease: Power2.easeOut}, 0.02, time);
		this.tl.from([this.ui.background, this.ui.foldShapes[0], this.ui.titleLines], .03, {opacity: 0, ease: Power0.easeNone}, time);
		this.tl.from(this.ui.background, .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.foldShapes[0], .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.shadows[0], .5, {y: -20, opacity: 0, ease: Power2.easeOut}, 0.5);

		this.ui.foldBgs.forEach((el, index) => this.tl.to(this.ui.foldBgs[0], .4, {morphSVG: el}, time + index * .4));;
		this.ui.foldShapes.forEach((el, index) => this.tl.to(this.ui.foldShapes[0], .4, {morphSVG: el}, time + index * .4));

		this.tl.from(this.ui.shadows[1], .6, {y: -2, opacity: 0, ease: Power2.easeOut}, '-=.2');

		this.tl.from(this.ui.files, .2, {opacity: 0, ease: Power0.easeNone}, '-=.8');
		this.tl.from(this.ui.files, .4, {scale: .5, transformOrigin: '50% 50%', ease: Back.easeOut}, '-=.8');

		this.tl.from(this.ui.hammer, .2, {opacity: 0, ease: Power0.easeNone}, '-=.7');
		this.tl.from(this.ui.hammer, .4, {rotation: '50deg', transformOrigin: '100% 34%', ease: Bounce.easeOut}, '-=.7');

		this.tl.staggerFrom(this.ui.bodyLines, .4, {opacity: 0, y: 20, ease: Power2.easeOut}, .125, '-=.4');

	}

}

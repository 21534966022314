import createRequest from 'superagent';

export default function(url, lat, lng) {
	const request = createRequest('GET', url);
	request.set('Accept', 'application/json');
	request.set('X-Requested-With', 'XMLHttpRequest');
	request.query({lat: lat, lng: lng});

	return request.then(response => response.body.locations);
}

import View from 'Core/View.js';
import CartModel from 'data/CartModel';
import CurrencyFormatter from 'utils/currency/CurrencyFormatter';

import './checkout-summary.scss';
import {CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS} from './CheckoutSummaryRow';

export default class CheckoutSummary extends View {

	initialize() {

		this.update = this.update.bind(this);

		this.vatItems = this.findAll('.checkout-summary__vat-item');
		this.itemsRow = this.find('.checkout-summary__items');
		this.discountRow = this.find('.checkout-summary__discount');
		this.giftCardRow = this.find('.checkout-summary__gift-card');

		this.volumeDiscountRow = this.find('.checkout-summary__volume-discount');
		this.volumeDiscountValue = this.find('.checkout-summary__volume-discount-amount');
		this.volumeDiscountPercentage = this.find('.checkout-summary__volume-discount-percentage');

		this.nextVolumeDiscountRow = this.find('.checkout-summary__next-volume-discount');
		this.nextVolumeDiscountLabel = this.find('.checkout-summary__next-volume-discount-label');

		CartModel.updatedSignal.add(this.update);

		this.update();
	}

	update() {
		const cartMeta = CartModel.getMeta();

		if(cartMeta) {

			// Add updated VAT lines
			const vatObj = cartMeta.vat || {};
			const vatArr = Object.keys(vatObj).map((k) => vatObj[k]);

			vatArr.forEach((vatRule, index) => {
				const vatItem = this.vatItems[index];
				vatItem.dataset.active = true;
				vatItem.querySelector('.checkout-summary__label').innerHTML = vatRule.name;
				vatItem.querySelector('.checkout-summary__value').innerHTML = CurrencyFormatter.format(vatRule.total);
			});

			this.itemsRow.querySelector('.checkout-summary__label__prefix').textContent = CartModel.getProductCount();

			// Discount
			if(CartModel.hasVolumeDiscount()) {
				this.volumeDiscountValue.textContent = '- ' + CurrencyFormatter.format(cartMeta.volumeDiscount.discountAmount);
				this.volumeDiscountPercentage.textContent = cartMeta.volumeDiscount.discountPercentage + '%';

				this.volumeDiscountRow.classList.remove(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
			}  else {
				this.volumeDiscountRow.classList.remove(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
				this.volumeDiscountRow.classList.add(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
			}

			if(CartModel.hasNextVolumeDiscount()) {
				this.nextVolumeDiscountLabel.textContent = this.nextVolumeDiscountLabel.dataset.label
					.replace(':remaining', CurrencyFormatter.format(cartMeta.nextVolumeDiscount ? cartMeta.nextVolumeDiscount.remaining : 0))
					.replace(':percentage', (cartMeta.nextVolumeDiscount ? cartMeta.nextVolumeDiscount.discountPercentage : 0) + '%');

				this.nextVolumeDiscountRow.classList.remove(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
			} else {
				this.nextVolumeDiscountRow.classList.remove(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
				this.nextVolumeDiscountRow.classList.add(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
			}

			if(CartModel.hasDiscount()) {
				this.discountRow.querySelector('.checkout-summary__value').textContent = '- ' + CurrencyFormatter.format(cartMeta.discountPrice);
				this.discountRow.classList.remove(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
			} else {
				this.discountRow.classList.remove(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
				this.discountRow.classList.add(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
			}

			if(CartModel.hasGiftCard()) {
				this.giftCardRow.querySelector('.checkout-summary__value').textContent = '- ' + CurrencyFormatter.format(cartMeta.giftCardPrice);
				this.giftCardRow.classList.remove(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
			} else {
				this.giftCardRow.classList.remove(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
				this.giftCardRow.classList.add(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
			}
		}
	}

	destroy() {
		CartModel.updatedSignal.remove(this.update);
	}
}

import View from 'Core/View.js';
import Signal from 'signals';

import AddressPicker from '../../general/AddressPicker/AddressPicker.js';

export default class CheckoutAddresses extends View {
	initialize(options) {
		this.options = options;
		this.modals = options.modalDictionary;

		// Binds
		this.handleFormSubmitted = this.handleFormSubmitted.bind(this);
		this.handleFormError = this.handleFormError.bind(this);
		this.handleSeparateInvoiceAddressChange = this.handleSeparateInvoiceAddressChange.bind(this);
		this.handleModalShown = this.handleModalShown.bind(this);
		this.handleModalHidden = this.handleModalHidden.bind(this);

		this.handleShippingAddressPicked = this.handleShippingAddressPicked.bind(this);
		this.handleInvoiceAddressPicked = this.handleInvoiceAddressPicked.bind(this);

		// Selectors
		this.shippingAddress = this.find('.checkout__address--shipping');
		this.invoiceAddress = this.find('.checkout__address--invoice');
		this.separateInvoiceAddressCheckbox = this.find('.checkout__wants-separate-invoice');
		this.separateInvoiceAddressSection = this.find('.checkout__invoice-address');
		this.invoiceAddressInput = this.find('#checkout__invoice-address');
		this.shippingAddressInput = this.find('#checkout__shipping-address');

		// Listeners
		if (this.separateInvoiceAddressCheckbox) {
			this.separateInvoiceAddressCheckbox.addEventListener('change', this.handleSeparateInvoiceAddressChange);
		}

		this.signals.modals.shown.add(this.handleModalShown);
		this.signals.modals.hidden.add(this.handleModalHidden);
		this.signals.forms.succeeded.add(this.handleFormSubmitted);
		this.signals.forms.failed.add(this.handleFormError);

		// Data
		this.signals = { ...this.signals, addressesPicked: new Signal() };

		// When refreshing, some browsers preserve the last state of the checkbox
		this.setSeparateInvoiceAddressChange(
			this.separateInvoiceAddressCheckbox ? this.separateInvoiceAddressCheckbox.checked : false
		);
	}

	destroy() {
		if (this.separateInvoiceAddressCheckbox) {
			this.separateInvoiceAddressCheckbox.removeEventListener('change', this.handleSeparateInvoiceAddressChange);
		}

		this.signals.modals.shown.remove(this.handleModalShown);
		this.signals.modals.hidden.remove(this.handleModalHidden);

		this.signals.forms.succeeded.remove(this.handleFormSubmitted);
		this.signals.forms.failed.remove(this.handleFormError);
	}

	handleFormError(formId, data) {
		if (formId === 'checkout-address' && (data.edit_url || data.edit_account_url)) {
			let modalId = data.edit_account_url ? 'incomplete-account-error' : 'incomplete-address-error';

			// Show the migration error and link to the edit form
			const button = this.modals[modalId].find('.incomplete-address-error__button');
			button.href = data.edit_account_url ? data.edit_account_url : data.edit_url;

			this.modals[modalId].show();
		}
	}

	handleFormSubmitted(formId, responseData, requestData) {
		if (formId === 'checkout-address') {
			this.signals.addressesPicked.dispatch(requestData);
			this.signals.ecommerce.checkoutSubmitShippingInfo.dispatch();
		}
	}

	handleShippingAddressPicked(id, addressHTML) {
		if (this.shippingAddressModal) {
			this.shippingAddressModal.hide();
		}

		this.setShippingAddressId(id);
		this.shippingAddress.innerHTML = addressHTML;
	}

	handleInvoiceAddressPicked(id, addressHTML) {
		if (this.invoiceAddressModal) {
			this.invoiceAddressModal.hide();
		}

		this.setInvoiceAddressId(id);
		this.invoiceAddress.innerHTML = addressHTML;
	}

	handleSeparateInvoiceAddressChange(event) {
		this.setSeparateInvoiceAddressChange(event.target.checked);
	}

	handleModalShown(modalId, modal) {
		switch (modalId) {
			case 'select-delivery-address':
				this.updateModalPosition = modal.updatePosition.bind(modal);
				this.shippingAddressModal = modal;
				this.shippingAddressPicker = new AddressPicker({
					...this.options,
					addressType: 'shipping',
					el: modal.find('.checkout__address-picker'),
				});
				this.shippingAddressPicker.signals.picked.add(this.handleShippingAddressPicked);
				this.shippingAddressPicker.signals.sizeUpdated.add(this.updateModalPosition);
				break;

			case 'select-invoice-address':
				this.updateModalPosition = modal.updatePosition.bind(modal);
				this.invoiceAddressModal = modal;
				this.invoiceAddressPicker = new AddressPicker({
					...this.options,
					addressType: 'invoice',
					el: modal.find('.checkout__address-picker'),
				});
				this.invoiceAddressPicker.signals.picked.add(this.handleInvoiceAddressPicked);
				this.invoiceAddressPicker.signals.sizeUpdated.add(this.updateModalPosition);
				break;
		}
	}

	handleModalHidden(modalId) {
		switch (modalId) {
			case 'select-delivery-address':
				this.shippingAddressPicker.signals.sizeUpdated.remove(this.updateModalPosition);
				this.shippingAddressPicker.signals.picked.remove(this.handleShippingAddressPicked);
				this.shippingAddressPicker.clear();
				this.shippingAddressPicker = null;
				break;

			case 'select-invoice-address':
				this.invoiceAddressPicker.signals.sizeUpdated.remove(this.updateModalPosition);
				this.invoiceAddressPicker.signals.picked.remove(this.handleInvoiceAddressPicked);
				this.invoiceAddressPicker.clear();
				this.invoiceAddressPicker = null;
				break;
		}
	}

	setSeparateInvoiceAddressChange(useSame) {
		if (useSame) {
			// Re-set the invoice address to sync shipping address
			this.handleInvoiceAddressPicked(this.shippingAddressInput.value, this.shippingAddress.innerHTML);
		}

		this.separateInvoiceAddressSection.style.display = !useSame ? 'block' : 'none';
		this.useSameAddressForInvoiceAndShipping = useSame;
	}

	setShippingAddressId(id) {
		this.shippingAddressInput.value = id;
		if (this.useSameAddressForInvoiceAndShipping) {
			this.setInvoiceAddressId(id);
			this.invoiceAddress.innerHTML = this.shippingAddress.innerHTML;
		}
	}

	setInvoiceAddressId(id) {
		this.invoiceAddressInput.value = id;
	}
}

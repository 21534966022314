import View from '../../../shared/Core/View';
import Hammer from 'hammerjs';
import TweenLite from 'gsap/TweenLite';

import createEvent from '../../../shared/utils/event/create.js';

import './drag-carousel.scss';

// In pixels
const dragThreshold = 100;

export default class DragCarousel extends View {
	initialize() {
		this.handleResize = this.handleResize.bind(this);
		this.handleBulletClick = this.handleBulletClick.bind(this);
		this.handleArrowLeftClick = this.handleArrowLeftClick.bind(this);
		this.handleArrowRightClick = this.handleArrowRightClick.bind(this);
		this.handleDragStart = this.handleDragStart.bind(this);
		this.handleDragging = this.handleDragging.bind(this);
		this.handleDragEnd = this.handleDragEnd.bind(this);

		this.itemsContainer = this.find('.drag-carousel__items');
		this.allItems = this.findAll('.drag-carousel__item-container');
		this.firstItem = this.allItems[0];
		this.bulletsContainer = this.find('.drag-carousel__bullets');
		this.arrowLeft = this.find('.drag-carousel__arrow--left');
		this.arrowRight = this.find('.drag-carousel__arrow--right');

		this.indexOfFirstVisibleItem = 0;
		this.numberOfElements = 1;
		this.numberOfVisibleElements = 1;
		this.singleElementWidth = 300;
		this.slideTween = null;
		this.dragStartX = 0;
		this.currentPageIndex = 0;
		this.totalPages = 1;

		if (!this.firstItem) {
			console.warn('[TONYS] Cannot run DragCarousel without any items.');
			return;
		}

		this.hammer = new Hammer(this.itemsContainer, {
			direction: Hammer.DIRECTION_HORIZONTAL,
		});
		this.hammer.add(new Hammer.Pan({ threshold: 0 }));
		this.hammer.on('panstart', this.handleDragStart);
		this.hammer.on('panmove', this.handleDragging);
		this.hammer.on('panend', this.handleDragEnd);

		this.arrowLeft.addEventListener('click', this.handleArrowLeftClick);
		this.arrowRight.addEventListener('click', this.handleArrowRightClick);

		if (this.bulletsContainer) {
			this.bulletsContainer.addEventListener(
				'click',
				this.handleBulletClick
			);
		}
		this.screen.resized.add(this.handleResize);

		this.disableElementLevelDragging();
		this.centerItems();
		this.slideToElementIndex(0);
	}

	destroy() {
		if (this.hammer) {
			this.hammer.destroy();
		}

		this.arrowLeft.removeEventListener('click', this.handleArrowLeftClick);
		this.arrowRight.removeEventListener(
			'click',
			this.handleArrowRightClick
		);
		if (this.bulletsContainer) {
			this.bulletsContainer.removeEventListener(
				'click',
				this.handleBulletClick
			);
		}
		this.screen.resized.remove(this.handleResize);
	}

	disableElementLevelDragging() {
		this.allItems.forEach(item => {
			item.ondragstart = function() {
				return false;
			};
		});
	}

	slideToElementIndex(elementIndex, fast = false) {
		if (this.slideTween) {
			this.slideTween.kill();
		}

		const duration = fast ? 0.3 : 0.6;
		const ease = fast ? Cubic.easeOut : Cubic.easeInOut;

		this.slideTween = TweenLite.to(this.itemsContainer, duration, {
			x: -elementIndex * this.singleElementWidth,
			ease: ease,
			onUpdate: () => {
				// Read the super secret `_gsTransform` property
				this.dragStartX = this.itemsContainer._gsTransform.x;
			},
		});

		this.indexOfFirstVisibleItem = elementIndex;

		// Update pages
		this.currentPageIndex = Math.floor(
			elementIndex / this.numberOfVisibleElements
		);
		this.totalPages = Math.ceil(
			this.numberOfElements / this.numberOfVisibleElements
		);
		this.updateBullets(this.currentPageIndex, this.totalPages);

		const event = createEvent('change');
		event.slideIndex = elementIndex;
		event.totalSlides = this.totalPages;

		this.el.dispatchEvent(event);
	}

	slideToPage(pageIndex, fast = false) {
		this.slideToElementIndex(
			pageIndex * this.numberOfVisibleElements,
			fast
		);
	}

	slideToNextPage(fast = false) {
		let nextIndex = this.currentPageIndex + 1;

		if (!this.thereIsANextPage()) {
			nextIndex = 0;
		}

		this.slideToPage(nextIndex, fast);
	}

	slideToPreviousPage(fast = false) {
		let nextIndex = this.currentPageIndex - 1;

		if (!this.thereIsAPreviousPage()) {
			nextIndex = this.totalPages - 1;
		}

		this.slideToPage(nextIndex, fast);
	}

	slideToDirectionOfDrag(deltaX) {
		const cancelDrag = () => {
			this.slideToElementIndex(this.indexOfFirstVisibleItem, true);
		};

		if (deltaX < 0) {
			if (this.thereIsANextPage()) {
				this.slideToNextPage(true);
			} else {
				cancelDrag();
			}
		} else {
			if (this.thereIsAPreviousPage()) {
				this.slideToPreviousPage(true);
			} else {
				cancelDrag();
			}
		}
	}

	thereIsANextPage() {
		return this.currentPageIndex + 1 < this.totalPages;
	}

	thereIsAPreviousPage() {
		return this.currentPageIndex - 1 >= 0;
	}

	centerItems() {
		const availableWidth = this.itemsContainer.clientWidth;
		this.singleElementWidth = this.firstItem.clientWidth;
		this.numberOfElements = this.allItems.length;
		this.numberOfVisibleElements = Math.max(
			1,
			Math.floor(availableWidth / this.singleElementWidth)
		);
		const restSpace =
			availableWidth -
			this.numberOfVisibleElements * this.singleElementWidth;

		// Center the items
		this.firstItem.style.marginLeft = `${restSpace / 2}px`;
	}

	updateBullets(currentPage, numberOfPages) {
		if (!this.bulletsContainer) {
			return;
		}

		const container = document.createElement('div');
		for (let i = 0; i < numberOfPages; i++) {
			let className = 'drag-carousel__bullet';

			if (i === currentPage) {
				className += ' drag-carousel__bullet--current';
			}

			const b = document.createElement('a');
			b.href = `#${i}`;
			b.className = className;
			container.appendChild(b);
		}

		// Replace the bullets
		this.bulletsContainer.innerHTML = container.innerHTML;
	}

	handleResize() {
		this.centerItems();
		this.slideToPage(
			Math.floor(
				this.indexOfFirstVisibleItem / this.numberOfVisibleElements
			)
		);
	}

	handleBulletClick(event) {
		event.preventDefault();

		if (event.target.tagName.toLowerCase() !== 'a') {
			return;
		}

		const a = event.target;
		const pageIndex = parseInt(a.href.split('#')[1]);

		this.slideToPage(pageIndex);
	}

	handleArrowLeftClick(event) {
		event.preventDefault();

		this.slideToPreviousPage();
	}

	handleArrowRightClick(event) {
		event.preventDefault();

		this.slideToNextPage();
	}

	handleDragStart() {
		if (this.slideTween) {
			this.slideTween.kill();
			this.slideTween = null;
		}
	}

	handleDragging(event) {
		TweenLite.set(this.itemsContainer, {
			x: this.dragStartX + event.deltaX,
		});
	}

	handleDragEnd(event) {
		const isSwapInsteadOfDrag = Math.abs(event.velocityX) > 0.5;
		if (isSwapInsteadOfDrag) {
			this.slideToDirectionOfDrag(event.deltaX);
			return;
		}

		this.dragStartX = this.dragStartX + event.deltaX;
		const draggedMoreThanThreshold =
			Math.abs(event.deltaX) >= dragThreshold;

		if (!draggedMoreThanThreshold) {
			this.slideToElementIndex(this.indexOfFirstVisibleItem, true);
			return;
		}

		this.slideToDirectionOfDrag(event.deltaX);
	}
}

import View from 'Core/View.js';

const CLASS_LOADING = 'reframed__video--loading';
const CLASS_LOADED = 'reframed__video--loaded';

export default class ReframedVideo extends View {

	initialize() {
		this.handlePreviewClick = this.handlePreviewClick.bind(this);
		this.handleIFrameProbablyLoaded = this.handleIFrameProbablyLoaded.bind(this);

		this.preview = this.find('.reframed__video__preview');
		this.iframe = this.find('.reframed__video__iframe');

		this.preview.addEventListener('click', this.handlePreviewClick)
	}

	destroy() {
		this.preview.removeEventListener('click', this.handlePreviewClick);

		// Stop the video playback
		this.iframe.src = 'about:blank';
	}

	handlePreviewClick(event) {
		event.preventDefault();

		this.loadIFrame();
	}

	loadIFrame() {
		this.el.classList.add(CLASS_LOADING);
		this.iframe.src = this.iframe.dataset.src;

		setTimeout(this.handleIFrameProbablyLoaded, 1000);
	}

	handleIFrameProbablyLoaded() {
		this.el.classList.remove(CLASS_LOADING);
		this.el.classList.add(CLASS_LOADED);
	}
}

import createRequest from 'superagent';

export default function(url, query) {
	const request = createRequest('GET', url);
	request.set('Accept', 'application/json');
	request.set('X-Requested-With', 'XMLHttpRequest');
	request.query({query: query});

	return request.then(response => response.body.locations);
}

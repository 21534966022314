import flatpickr from 'flatpickr';

import View from '../../../shared/Core/View';
import createEvent from '../../../shared/utils/event/create';

import './time-picker.scss';

export default class TimePicker extends View {

    initialize() {
    	const useAMPM = this.el.dataset.useAmpm === 'true';

		this.picker = flatpickr(this.el, {
			enableTime: true,
			noCalendar: true,
			dateFormat: useAMPM ? 'h:i K' : 'H:i',
			time_24hr: !useAMPM,
			onOpen: () => {
				this.el.dispatchEvent(createEvent('change'));
			},
			onClose: () => {
				this.el.dispatchEvent(createEvent('change'));
			}
		});
    }

    destroy() {
    	this.picker.destroy();
    }
}

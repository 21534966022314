import BaseSplashView from './BaseSplash';

import {TimelineMax, Power2, TweenLite} from 'gsap/all';

export default class Splash_19_7 extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.setupUI();
		this.setupTimeline();

	}

	setupUI() {

		this.ui = {
			background: this.el.querySelectorAll('.js-background'),
			titleLines: this.el.querySelectorAll('.js-title-line'),
			bodyLines: this.el.querySelectorAll('.js-body-line'),
			foldShapes: [...this.el.querySelectorAll('.js-fold-shapes')],
			foldBgs: [...this.el.querySelectorAll('.js-fold-bgs')],
			shadows: [...this.el.querySelectorAll('.js-shadow-line')],
			animation: this.el.querySelector('.js-animation'),
			pageMasks: [...this.el.querySelectorAll('.js-page-mask')],
			guyLeft: this.el.querySelector('.js-guy-left'),
			guyRight: this.el.querySelector('.js-guy-right'),
			bite: this.el.querySelector('.js-bite'),
			bar: this.el.querySelector('.js-bar'),
			movingArm: this.el.querySelector('.js-moving-arm'),
			movingArmUnder: [...this.el.querySelectorAll('.js-moving-arm-under')],
			girlHead: this.el.querySelector('.js-girl-head'),
		}

	}

	update(options) {

		TweenLite.to(this.tl, options.duraton, {progress: options.progress});

	}

	setupTimeline() {

		this.tl = new TimelineMax({paused: true});

		const time = .6;
		this.tl.staggerFrom(this.ui.titleLines, .6, {y: -150, ease: Power2.easeOut}, 0.02, time);
		this.tl.from([this.ui.background, this.ui.foldShapes[0], this.ui.titleLines], .03, {opacity: 0, ease: Power0.easeNone}, time);
		this.tl.from(this.ui.background, .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.pageMasks[0], .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.foldShapes[0], .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.shadows[0], .5, {y: -20, opacity: 0, ease: Power2.easeOut}, 0.5);

		this.ui.foldBgs.forEach((el, index) => this.tl.to(this.ui.foldBgs[0], .4, {morphSVG: el}, time + index * .4));;
		this.ui.pageMasks.forEach((el, index) => this.tl.to(this.ui.pageMasks[0], .4, {morphSVG: el}, time + index * .4));
		this.ui.foldShapes.forEach((el, index) => this.tl.to(this.ui.foldShapes[0], .4, {morphSVG: el}, time + index * .4));

		this.tl.from(this.ui.guyLeft, .5, {x: -250, ease: Power2.easeOut}, '-=.1');
		this.tl.from(this.ui.guyRight, .5, {x: 250, ease: Power2.easeOut}, '-=.1');

		this.tl.to([this.ui.movingArm, this.ui.bar], .5, {rotation: '15deg', transformOrigin: '150% 60%', ease: Power2.easeInOut}, '-=.2');
		this.tl.to(this.ui.movingArmUnder[0], .5, {morphSVG: this.ui.movingArmUnder[1], ease: Power2.easeInOut}, '-=.2');
		this.tl.to(this.ui.bar, .5, {x: 12, y: -8, ease: Power2.easeInOut}, '-=.5');
		this.tl.to(this.ui.girlHead, .5, {x: -12, rotation: '-5deg', ease: Power2.easeInOut}, '-=.5');
		this.tl.to(this.ui.bite, .05, {autoAlpha: 0, ease: Power0.easeNone});
		this.tl.to([this.ui.movingArm, this.ui.bar], .5, {rotation: '0deg', transformOrigin: '150% 60%', ease: Power2.easeInOut});
		this.tl.to(this.ui.movingArmUnder[0], .5, {morphSVG: this.ui.movingArmUnder[0], ease: Power2.easeInOut}, '-=.5');
		this.tl.to(this.ui.bar, .5, {x: 0, y: 0, ease: Power2.easeInOut}, '-=.5');
		this.tl.to(this.ui.girlHead, .5, {x: 0, rotation: '0deg', ease: Power2.easeInOut}, '-=.5');

		// this.tl.from(this.ui.animation, .5, {y: 40, opacity: 0, ease: Power2.easeOut}, '-=.1');

		// // this.tl.staggerFrom(this.ui.arrows, .6, {opacity: 0, x: '-600', ease: Power2.easeInOut}, .125 );
		this.tl.staggerFrom(this.ui.bodyLines, .4, {opacity: 0, y: 20, ease: Power2.easeOut}, .125);

	}

}

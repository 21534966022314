import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

import findAncestorByClass from '../../../shared/utils/dom/findAncestorByClass';
import BoxFitting from '../../../shared/utils/geometry/BoxFitting';

import Modal from '../../components/Modal/Modal';

import './photo-album.scss';

export default class PhotoAlbum extends View {

	initialize(options) {
		this.handleClick = this.handleClick.bind(this);
		this.handleModalHide = this.handleModalHide.bind(this);
		this.handleHiresImageLoaded = this.handleHiresImageLoaded.bind(this);
		this.handlePreviousClick = this.handlePreviousClick.bind(this);
		this.handleNextClick = this.handleNextClick.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.resize = this.resize.bind(this);

		this.container = this.find('.photo-album__container');
		this.caption = this.find('.photo-album__caption');
		this.hiresImage = this.find('.photo-album__hires');
		this.previousArrow = this.find('.photo-album__arrow--previous');
		this.nextArrow = this.find('.photo-album__arrow--next');
		this.zoomModal = new Modal({...options, el: this.container});
		this.items = this.findAll('.photo-album__item');

		this.el.addEventListener('click', this.handleClick);
		this.hiresImage.addEventListener('load', this.handleHiresImageLoaded);
		this.previousArrow.addEventListener('click', this.handlePreviousClick);
		this.nextArrow.addEventListener('click', this.handleNextClick);
		document.addEventListener('keyup', this.handleKeyUp);

		this.signals.modals.hide.add(this.handleModalHide);
		this.screen.resized.add(this.resize);
	}

	destroy() {
		this.el.removeEventListener('click', this.handleClick);
		this.hiresImage.removeEventListener('load', this.handleHiresImageLoaded);
		this.previousArrow.removeEventListener('click', this.handlePreviousClick);
		this.nextArrow.removeEventListener('click', this.handleNextClick);
		document.removeEventListener('keyup', this.handleKeyUp);

		this.signals.modals.hide.remove(this.handleModalHide);
		this.screen.resized.remove(this.resize);
	}

	showItem(item) {
		this.currentItem = item;
		this.currentImage = item.querySelector('img');

		this.currentTransformation = this.getStartTransformation(this.currentImage);
		const targetTransformation = this.getTargetTransformation(this.currentTransformation);

		this.clearHires();

		this.container.style.display = 'block';
		this.container.insertBefore(this.currentImage, this.container.firstChild);
		this.zoomModal.show();

		TweenLite.set(this.currentImage, {left: 0, position: 'static'});

		TweenLite.fromTo(this.container, 0.4, this.currentTransformation, {
			...targetTransformation,
			ease: Cubic.easeOut,
			onComplete: () => {
				this.hiresImage.src = this.currentImage.dataset.hiresSrc;
				this.caption.innerHTML = this.currentImage.dataset.caption;
			}
		});

		this.updateArrowColoring(item);
	}

	restoreCurrentItem() {
		if (! this.currentImage) {
			return;
		}

		this.caption.innerHTML = '';

		const targetTransformation = this.currentTransformation;
		TweenLite.to(this.container, 0.2, {
			...targetTransformation,
			ease: Cubic.easeOut,
			onComplete: () => {
				if (! this.currentItem) {
					return;
				}

				this.currentItem.append(this.currentImage);
				this.container.style.display = 'none';

				TweenLite.set(this.currentImage, {
					clearProps: 'all'
				});

				this.currentImage = null;
				this.currentItem = null;
			}
		});
	}

	swapItem(newItem) {

		// Clear current
		this.currentItem.append(this.currentImage);
		this.clearHires();
		TweenLite.set(this.currentImage, {clearProps: 'all'});
		this.caption.innerHTML = '';

		// Transform new one
		this.currentItem = newItem;
		this.currentImage = newItem.querySelector('img');

		const targetTransformation = this.getTargetTransformation(
			this.getStartTransformation(this.currentImage)
		);

		const lowresSrc = this.currentImage.src;
		TweenLite.set(this.currentImage, {left: 0});
		this.hiresImage.src = lowresSrc;
		this.caption.innerHTML = this.currentImage.dataset.caption;

		this.loadHires(this.currentImage.dataset.hiresSrc, (url) => {
			if (this.hiresImage.src === lowresSrc) {
				this.hiresImage.src = url;
			}
		});

		TweenLite.to(this.container, 0.4, {
			...targetTransformation,
			ease: Cubic.easeOut,
		});

		this.updateArrowColoring(newItem);
	}

	resize() {
		this.currentTransformation = this.getStartTransformation(this.currentImage);
		const targetTransformation = this.getTargetTransformation(this.currentTransformation);

		TweenLite.to(this.container, 0.2, {
			...targetTransformation,
			ease: Cubic.easeOut,
		});
	}

	updateArrowColoring(item) {
		const index = this.items.indexOf(item);
		if (index === 0) {
			this.previousArrow.classList.add('photo-album__arrow--disabled');
		} else {
			this.previousArrow.classList.remove('photo-album__arrow--disabled');
		}

		if (index >= this.items.length - 1) {
			this.nextArrow.classList.add('photo-album__arrow--disabled');
		} else {
			this.nextArrow.classList.remove('photo-album__arrow--disabled');
		}
	}

	loadHires(url, callback) {
		const image = document.createElement('img');
		image.onload = () => {
			callback(url);
		};
		image.src = url;
	}

	clearHires() {
		this.hiresImage.classList.remove('photo-album__hires--loaded');
		this.hiresImage.src = 'about:blank';
	}

	previousItem() {
		const index = this.indexOfCurrentItem();
		if (index === 0) {
			return;
		}

		this.swapItem(this.items[index - 1]);
	}

	nextItem() {
		const index = this.indexOfCurrentItem();
		if (index >= this.items.length - 1) {
			return;
		}

		this.swapItem(this.items[index + 1]);
	}

	indexOfCurrentItem() {
		return this.items.indexOf(this.currentItem);
	}

	handleClick(event) {
		const item = findAncestorByClass(event.target, 'photo-album__item');
		if (! item) {
			return;
		}

		event.preventDefault();

		this.showItem(item);
	}

	handleHiresImageLoaded() {
		this.hiresImage.classList.add('photo-album__hires--loaded');
	}

	handleModalHide(id) {
		if (id !== this.container.id) {
			return;
		}

		this.restoreCurrentItem();
	}

	handlePreviousClick(event) {
		event.preventDefault();

		this.previousItem();
	}

	handleNextClick(event) {
		event.preventDefault();

		this.nextItem();
	}

	handleKeyUp(event) {
		switch (event.keyCode) {

			case 39:
				this.nextItem();
				break;

			case 37:
				this.previousItem();
				break;
		}
	}

	getStartTransformation(image) {
		const rect = image.getBoundingClientRect();

		return {
			width: rect.width,
			height: rect.height,
			top: rect.top,
			left: rect.left,
		};
	}

	getTargetTransformation(currentBounds) {
		const padding = this.screen.isOneOf['mobile'] ? 30 : 60;

		const viewport = BoxFitting.getDocumentViewportDimensions();
		viewport.width -= padding * 2;
		viewport.height -= padding * 2 + 50;

		const box = BoxFitting.getLetterBox(currentBounds, viewport);
		box.left += padding;
		box.top += padding;

		return box;
	}
}

import emojiRegex from 'emoji-regex';

import View from '../../../shared/Core/View';
import Form from '../../general/Form/Form';
import CartModel from '../../../shared/data/CartModel';

import './checkout-add-postcard-modal.scss';

const emoji = emojiRegex();

export default class CheckoutAddPostcardModal extends View {

	initialize(options) {
		this.handleInput = this.handleInput.bind(this);
		this.handleSubmitClick = this.handleSubmitClick.bind(this);
		this.handleModalShowing = this.handleModalShowing.bind(this);
		this.handleModalHidden = this.handleModalHidden.bind(this);
		this.handleFormError = this.handleFormError.bind(this);
		this.handlePostcardEditRequested = this.handlePostcardEditRequested.bind(this);

		this.previewImage = this.find('.add-postcard-modal__preview');
		this.contents = this.findAll('.add-postcard-modal__fixed-content');
		this.postcardMessage = this.find('#postcard_message');
		this.submitButton = this.find('.add-postcard-modal__submit');
		this.textArea = this.find('.add-postcard-modal__message__input');

		this.chosenId = null;

		this.modal = options.modalDictionary[this.el.id];

		this.postcardMessage.addEventListener('input', this.handleInput);
		this.submitButton.addEventListener('click', this.handleSubmitClick);

		this.signals.modals.shown.add(this.handleModalShowing);
		this.signals.modals.hidden.add(this.handleModalHidden);
		this.signals.forms.failed.add(this.handleFormError);

		this.dummyForm = new Form(options);
		this.handleInput();

		this.signals.products.postcardEditRequested.add(this.handlePostcardEditRequested);
	}

	destroy() {
		this.signals.products.postcardEditRequested.remove(this.handlePostcardEditRequested)

		this.submitButton.removeEventListener('click', this.handleSubmitClick);
		this.postcardMessage.removeEventListener('input', this.handleInput);

		this.signals.modals.shown.remove(this.handleModalShowing);
		this.signals.modals.hidden.remove(this.handleModalHidden);
		this.signals.forms.failed.remove(this.handleFormError);

		this.dummyForm.destroy();
	}

	updatePreview(id, url) {
		this.chosenId = id;
		this.previewImage.setAttribute('src', url);
	}

	handleModalShowing(modalId) {
		if (modalId !== this.el.id) {
			return;
		}

		this.prefillForm();
		this.textArea.dispatchEvent(new Event('change'));
	}

	handleModalHidden(modalId) {
		if (modalId !== this.el.id) {
			return;
		}
	}

	handleSubmitClick(event) {
		event.preventDefault();

		CartModel.addProduct(this.chosenId, 1, {
			postcard_message: this.postcardMessage.value
		});

		// Reload cart data after add/update so on next showing it can prefille with existing message from the cart product data
		CartModel.requestSuccessSignal.addOnce(() => {
				CartModel.load();
			}
		);

		this.modal.hide();
	}

	handleInput() {
		let cleanedInput = this.postcardMessage.value;
		cleanedInput = cleanedInput.replace(emoji, '');

		if (this.postcardMessage.value !== cleanedInput) {
			this.postcardMessage.value = cleanedInput;
		}
	}

	handleFormError(formId, data) {
		// TODO: not triggered?
	}

	prefillForm() {
		const postCard = CartModel.getPostcard();

		if(postCard) {
			const messageData = postCard.options.find((option) => {
				return option.identifier === 'card_message';
			});

			this.postcardMessage.value = messageData.value;
			this.handleInput();
		}
	}

	clearForm() {
		this.postcardMessage.value = '';
	}

	handlePostcardEditRequested(id, previewImageUrl) {
		this.clearForm();
		this.updatePreview(id, previewImageUrl);
		this.modal.show();
	}
}

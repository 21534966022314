import TemplateType from 'enums/TemplateType.js';

export default class RouteService {

	constructor(options) {
		options = options || {};

		this.routes = options.routeMap;
		this.routeKeys = Object.keys(this.routes).sort(this.sortRouteKeysByValueSpecificity.bind(this));
	}

	/**
	 * @param {string} path
	 * @returns {Object|null}
	 */
	getRoute(path = null) {
		if (! path) {
			path = this.getCurrentLocation();
		}

		// Prefix the path with the current location if it's a path that starts with #
		if (path.substr(0, 1) === '#') {
			path = this.getCurrentLocation().split('#')[0] + path;
		}

		// Remove the domain
		path = this.stripDomain(path);

		// Trim leading and trailing slash
		path = this._trimLeadingAndTrailingSlash(path);

		// If it matches the prefix, clear the path
		if (path === '/' + this.getLocalePrefix()) {
			path = '';
		}

		// Extract the hash
		let hash = '';
		[path, hash] = path.split('#');

		// Extract the query string
		let queryString = '';
		[path, queryString] = path.split('?');

		// Trim leading and trailing slash again
		path = this._trimLeadingAndTrailingSlash(path);

		// Try an exact match first
		let routeKey = this.routeKeys.find(key => this._matchesPattern(this.getRoutePathByName(key), path));
		let isExactMatch = true;

		// Try to find it in one of the children
		if (! routeKey) {
			isExactMatch = false;

			// As a fallback, try to match by removing segments
			while (path.lastIndexOf('/') > 0) {
				path = path.substr(0, path.lastIndexOf('/'));

				routeKey = this.routeKeys.find(key => {
					return this._matchesPattern(this.getRoutePathByName(key), path);
				});
			}
		}

		// Follow the redirect
		if (routeKey === 'redirectHome') {
			routeKey = 'root';
		}

		// Escape hatch for when everything fails
		if (! routeKey) {
			return this.getUnknownRouteForPath(path);
		}

		routeKey = this.resolveRouteAlias(routeKey);

		let route = this.getRoutePathByName(routeKey);
		if (! route) {
			console.warn(`[TONYS] No route defined for key "${routeKey}", using root as a fallback.`);
			routeKey = 'root';
			route = this.getRoutePathByName('root');
		}

		return {
			name: routeKey,
			path: path,
			replace: false,
			hash: hash ? hash : null,
			queryString: queryString ? queryString : null,
			query: this.parseQueryString(queryString),
			url: this.buildURL(path, queryString, hash),
			isExactMatch: isExactMatch,
			template: this.getTemplateByRouteKey(routeKey),
			variables: this._getParams(route, path)
		};
	};

	/**
	 * @param {string} path
	 * @returns {Object|null}
	 */
	getRouteWithoutHash(path) {
		const route = this.getRoute(path);
		route.url = route.url.replace('#' + route.hash, '');
		route.hash = '';

		return route;
	}

	/**
	 * @param {string} routeName
	 * @param {object} parameters
	 * @param {object} query
	 * @param {string} hash
	 *
	 * @returns {string}
	 */
	getPath(routeName, parameters = null, query = {}, hash = '') {
		if (! this.routes[routeName]) {
			throw new Error(`Cannot find route with name "${routeName}".`);
		}

		let path = this.getRoutePathByName(routeName);
		const requiredParams = this._extractParamNames(path);
		const optionalParams = this._extractOptionalParamNames(path);

		// If no params are present, no need to 'compile' a path
		if (! requiredParams.length && ! optionalParams.length) {
			return this.buildURL(path, query, hash);
		}

		const missingParams = this._getMissingParams(requiredParams, parameters ? Object.keys(parameters) : []);
		if (missingParams.length) {
			throw new Error(`Missing required params "${missingParams.join(', ')}" for route "${routeName}".`);
		}

		// Merge in the required params
		path = path.replace(/{[a-zA-Z0-9]+}/g, match => parameters[match.replace(/[{}]/g, '')]);

		// Merge in the optionals
		path = path.replace(/\/{[a-zA-Z0-9]+\?}/g, match => {
			const paramName = match.substr(2, match.length - 4);
			if (! parameters || ! parameters[paramName]) {
				return '';
			}

			return `/${parameters[paramName]}`;
		});

		// Merge the optionals or remove this part of the slug
		// path = path.replace(/\/{[a-zA-Z0-9]+\?}/g;

		return this.buildURL(path, query, hash);
	};

	/**
	 * Converts something like mission.detail to nl/nl/onze-missie/detail/{id}
	 *
	 * @param {String} key
	 * @returns {String|null}
	 */
	getRoutePathByName(key) {
		const routeData = this.routes[key];

		// Some routes are not object but rather just a path
		if (typeof routeData === 'string') {
			return routeData;
		}

		return routeData ? routeData.route : null;
	}

	/**
	 * Check if the passed uri is a local path
	 * @param {string} uri
	 * @returns {boolean}
	 */
	isLocalURI(uri) {

		// Starts with http://, https:// or // and then the current host
		const isLocalDomain = `^(https?:)?\\/\\/${location.host.replace('.', '\\.')}`;

		// Does not contain a semicolon and does not start with //
		const doesNotContainProtocol = '^(?!\/\/)[^:]+$';

		return new RegExp(`(${isLocalDomain}|${doesNotContainProtocol})`, 'i').test(uri);
	}

	/**
	 * @param {string} url
	 * @returns {string}
	 */
	stripDomain(url) {
		return url.replace(this.getCurrentDomain(), '');
	}

	/**
	 * @param {string} query
	 *
	 * @return {object}
	 */
	parseQueryString(query) {
		if (! query) {
			return {};
		}

		const keys = {};
		query.split('&').forEach(keyValue => {
			const [key, value] = keyValue.split('=');
			keys[key] = value;
		});

		return keys;
	}

	/**
	 * @param {object} properties
	 *
	 * @returns {string}
	 */
	buildQueryString(properties) {
		return Object.keys(properties).map(key => {
			const value = properties[key];
			if (! value) {
				return key;
			}
			return `${key}=${value}`;
		}).join('&');
	}

	/**
	 * @param {string} path
	 * @param {object|string} [query]
	 * @param {string} [hash]
	 *
	 * @returns {string}
	 */
	buildURL(path, query, hash) {
		const queryString = typeof query === 'object' ? this.buildQueryString(query) : query;

		return this.getCurrentDomain() + '/' + path + (queryString ? '?' + queryString : '') + (hash ? '#' + hash : '');
	}

	/**
	 * @returns {string}
	 */
	getCurrentDomain() {
		return location.protocol + '//' + location.host;
	}

	/**
	 * @returns {string}
	 */
	getCurrentLocation() {
		return location.toString();
	}

	/**
	 * @param {string} path
	 * @returns {object}
	 */
	getUnknownRouteForPath(path) {
		return {
			name: 'unkown',
			isExactMatch: false,
			path: path,
			replace: false,
			hash: null,
			queryString: null,
			query: {},
			url: this.getCurrentDomain() + '/' + path,
			template: TemplateType.DEFAULT,
			variables: {},
		};
	}

	/**
	 * Some routes have aliases, but we resolve them to their
	 * most common name to hide complexity from the rest
	 * of the application
	 *
	 * @param {string} routeName
	 * @returns {string}
	 */
	resolveRouteAlias(routeName) {
		const duplicates = ['root', 'redirectHome'];

		// Check whether this is one of the duplicates
		if (duplicates.indexOf(routeName) !== - 1) {

			// Find a route that has the same url pattern
			const alternativeRouteName = this.routeKeys.find(key => {
				return this.getRoutePathByName(key) === this.getRoutePathByName(routeName)
					&& duplicates.indexOf(key) === - 1;
			});

			return alternativeRouteName ? alternativeRouteName : 'collaborate';
		}

		return routeName;
	}

	/**
	 * Maps the routes from LaraSuite with our frontend templates
	 *
	 * @param {string} routeKey
	 * @returns {TemplateType}
	 */
	getTemplateByRouteKey(routeKey) {
		const routeInfo = this.routes[routeKey];
		if (! routeInfo || ! routeInfo.page) {
			return TemplateType.DEFAULT;
		}

		switch (routeInfo.page.background_color) {
			case 'white':
				return TemplateType.SHOP;
			case 'red':
				return TemplateType.MISSION;
			case 'blue':
				return TemplateType.COLLABORATE;
		}

		return TemplateType.DEFAULT;
	}

	/**
	 * @param {string} path
	 * @returns {string}
	 */
	stripLocalePrefix(path) {
		const regex = new RegExp(`^\\/?${this.getLocalePrefix()}\\/?`);

		return path.replace(regex, '');
	};

	/**
	 * @param {string} path
	 * @returns {string}
	 */
	prefixLocale(path) {
		if (path.charAt(0) != '/') {
			path = `/${path}`;
		}

		return `/${this.getLocalePrefix()}${path}`;
	}

	/**
	 * @returns {String}
	 */
	getLocalePrefix() {
		return this.getRoutePathByName('root');
	}

	/**
	 * Count the number of slashes
	 *
	 * @param {string} a
	 * @param {string} b
	 */
	sortRouteKeysByValueSpecificity(a, b) {
		const slashesCountA = (this.getRoutePathByName(a).match(/\//g) || []).length;
		const slashesCountB = (this.getRoutePathByName(b).match(/\//g) || []).length;

		if (slashesCountA === slashesCountB) {
			return 0;
		}

		return slashesCountA > slashesCountB ? - 1 : 1;
	}

	/**
	 * @param {string} route
	 * @returns {RegExp}
	 * @private
	 */
	_routeToRegex(route) {
		const group = '(\/[^\/]+)';

		const regexString = route
			.replace(/\/{[^}]+\?}/g, group + '?')
			.replace(/\/{[^}]+}/g, group)
			.replace(/\//g, '\\/');

		return new RegExp(`^${regexString}$`, 'g');
	};

	/**
	 * @param {string} routePattern
	 * @param {string} route
	 * @return {boolean}
	 */
	_matchesPattern(routePattern, route) {
		return this._routeToRegex(routePattern).test(route);
	};

	/**
	 * @param {string} routePattern
	 * @return {Array}
	 */
	_extractParamNames(routePattern) {
		const paramNames = routePattern.match(/\/{[a-zA-Z0-9]+}/g);
		if (! paramNames || paramNames.length === 0) {
			return [];
		}

		// Remove the /{ and } parts
		return paramNames.map(n => n.substr(2).substr(0, n.length - 3));
	};

	/**
	 * @param {string} routePattern
	 * @return {Array}
	 */
	_extractOptionalParamNames(routePattern) {
		const paramNames = routePattern.match(/\/{[a-zA-Z0-9]+\?}/g);
		if (! paramNames || paramNames.length === 0) {
			return [];
		}

		// Remove the /{ and } parts
		return paramNames.map(n => n.substr(2).substr(0, n.length - 3));
	};

	/**
	 * Extract an object from the url
	 * @param {string} routePattern
	 * @param {string} route
	 * @return {Object}
	 */
	_getParams(routePattern, route) {
		const extracted = this._routeToRegex(routePattern).exec(route);
		if (! extracted) {
			return {};
		}

		const params = {};
		this._extractParamNames(routePattern).forEach((value, index) => {
			const paramValue = extracted[index + 1];

			params[value] = paramValue ? paramValue.substr(1) : null;
		});

		return params;
	};

	/**
	 * Get an array containing the missing keys
	 * @param {Array} required
	 * @param {Array} provided
	 * @return {Array}
	 * @private
	 */
	_getMissingParams(required, provided) {
		return required.filter(req => provided.indexOf(req) === - 1);
	}

	/**
	 * @param {String} str
	 * @return {String}
	 * @private
	 */
	_trimLeadingAndTrailingSlash(str) {
		return str.replace(/\/+$/, '').replace(/^\/+/, '');
	}
}

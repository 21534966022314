import View from 'Core/View.js';
import TruckLocator from './TruckLocator';

export default class TruckLocatorContainer extends View {

	initialize() {
		this.renderTruckLocator = this.renderTruckLocator.bind(this);

		// Render now and whenever we potentially switch mode
		this.screen.breakpointChanged.add(this.renderTruckLocator);
		this.renderTruckLocator();
	}

	destroy() {
		this.screen.breakpointChanged.remove(this.renderTruckLocator);
	}

	renderTruckLocator() {
		const isMobile = this.screen.breakpoint === 'mobile';

		this.renderReact(this.el, TruckLocator, {
			locale: this.el.dataset.locale,
			apiKey: this.el.dataset.apiKey,
			currentDate: new Date(this.el.dataset.now),
			eventsApiUrl: this.el.dataset.eventsApiUrl || '#',
			initialFocusLocation: this.getDeeplinkLocationId(),
			defaultZoom: this.getDefaultZoom(),
			isMobile: isMobile,
			defaultCenter: this.getDefaultCenter(),
			translations: JSON.parse(this.el.dataset.translations),
		});
	}

	getDefaultZoom() {
		const val = this.el.dataset.defaultZoom;
		if (! val) {
			return 4;
		}

		return parseInt(val);
	}

	getDefaultCenter() {
		const lat = this.el.dataset.defaultLat;
		const lng = this.el.dataset.defaultLng;

		if (! lat || ! lng) {
			return {
				lat: 40,
				lng: - 98.7058248
			};
		}

		return {lat: parseFloat(lat), lng: parseFloat(lng)};
	}

	getDeeplinkLocationId() {
		const hash = window.location.hash;
		if (! hash || hash === '#') {
			return null;
		}

		const slugParts = hash.substr(1).split('-');
		if (! /^\d+$/.test(slugParts[0])) {
			return null;
		}

		return parseInt(slugParts[0]);
	}
}

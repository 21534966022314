import View from 'Core/View.js';

import TweenLite from 'gsap/TweenLite';

import StickToMouse from '../../../components/StickToMouse/StickToMouse';

import './product-detail-image-view.scss';

const ESC_KEY = 27;

export default class ProductDetailImageView extends View {

	initialize(options) {
		this.onImageLoaded = this.onImageLoaded.bind(this);
		this.onImageClicked = this.onImageClicked.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);

		this.image = this.find('img');
		this.closeCursor = this.find('.product-detail-image-view__close');

		this.imageDeselectedSignal = options.imageDeselectedSignal;
		this.imageUpdatedSignal = options.imageUpdatedSignal;

		this.stickToMouse = new StickToMouse({el:this.closeCursor, targetEl: this.el});
		this.stickToMouse.offset = {x: 0, y: 100};

		this.image.addEventListener('load', this.onImageLoaded);
		this.el.addEventListener('click', this.onImageClicked);

	}

	destroy() {
		this.image.removeEventListener('load', this.onImageLoaded);
		this.el.removeEventListener('click', this.onImageClicked);

		if(this.stickToMouse) {
			this.stickToMouse.destroy();
		}
	}

	load(url) {
		this.image.classList.remove('lazy-image--loaded');
		TweenLite.set(this.image, {rotation: 2 -(4 * Math.random()) + 'deg', y:50});
		this.image.src = url;

		this.show();
	}

	onImageLoaded() {

		// const ratio = this.image.naturalHeight / this.image.naturalWidth;
		// this.el.style.paddingTop = (ratio * 100) + '%';

		this.imageUpdatedSignal.dispatch();
	}

	onImageClicked() {
		this.imageDeselectedSignal.dispatch();
	}

	onKeyDown(event) {
		if (event.keyCode === ESC_KEY) {
			this.imageDeselectedSignal.dispatch();
		}
	}

	show() {
		this.el.classList.remove('product-detail-image-view--hidden');

		TweenLite.killTweensOf(this.el);
		TweenLite.to(this.el, 0.15, {opacity: 1});

		document.removeEventListener('keydown', this.onKeyDown);
		document.addEventListener('keydown', this.onKeyDown);
	}

	hide() {
		document.removeEventListener('keydown', this.onKeyDown);

		this.el.classList.remove('product-detail-image-view--hidden');
		this.el.classList.add('product-detail-image-view--hidden');

		TweenLite.killTweensOf(this.el);
		TweenLite.to(this.el, 0.15, {opacity: 0});

		if(this.stickToMouse) {
			this.stickToMouse.hide(true);
		}
	}

	get height() {
		return this.el.clientHeight;
	}

	handleItemsClicked(event) {
		event.preventDefault();
	}
}

import View from 'Core/View';

import './wrapper-thumb.scss';

export default class WrapperThumb extends View {

	initialize() {
		this.handleClick = this.handleClick.bind(this);
		this.el.addEventListener('click', this.handleClick);
	}

	destroy() {
		this.el.removeEventListener('click', this.handleClick);
	}

	handleClick(event) {
		event.preventDefault();
		event.stopPropagation();

		const route = this.routes.getRoute(this.el.dataset.convertUrl);

		this.signals.pageLoad.requested.dispatch(route);
	}
}

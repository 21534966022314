import TweenLite from 'gsap/TweenLite';
import View from '../../../shared/Core/View';

import './product-detail-custom-wrapper.scss';

export default class ProductDetailCustomWrapper extends View {

	initialize() {
		this.update = this.update.bind(this);
		this.resize = this.resize.bind(this);

		this.desktopTarget = document.querySelector('.product-detail-image-gallery');
		this.mobileTarget = document.querySelector('.product-detail-carousel');

		window.addEventListener('resize', this.resize);

		this.update();
	}

	destroy() {

		window.removeEventListener('resize', this.resize);

		if (this.desktopTarget) {
			this.desktopTarget.removeEventListener('load', this.update);
		}
	}

	resize() {
		this.update();
	}

	update() {
		let target = window.innerWidth < 768 ? this.mobileTarget : this.desktopTarget;

		let rect = target.getBoundingClientRect();

		if (target === this.mobileTarget) {
			TweenLite.set(this.el, {x: rect.width, y: rect.height});
		} else {
			TweenLite.set(this.el, {x: rect.width * 0.95, y: rect.width * 0.60});
		}
	}
}

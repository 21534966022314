import View from 'Core/View.js';
import CartModel from 'data/CartModel';
import CurrencyFormatter from '../../../shared/utils/currency/CurrencyFormatter';

import './checkout-summary-row.scss';

export const CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS = 'checkout-summary-row--collapsed';

export default class CheckoutSummaryRow extends View {

	initialize() {
		this.update = this.update.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.el.addEventListener('click', this.handleClick);

		this.value = this.find('.checkout-summary__value');

		this.valueKey = this.el.dataset.value;

		CartModel.updatedSignal.add(this.update);
	}

	destroy() {
		CartModel.updatedSignal.remove(this.update);
	}

	update() {
		const meta = CartModel.getMeta();
		if(meta && meta[this.valueKey]) {
			this.value.textContent = CurrencyFormatter.format(meta[this.valueKey]);
		}
	}

	handleClick() {
		if(this.el.dataset.targets) {
			const selector = this.el.dataset.targets;
			const targets = document.querySelectorAll(selector);

			targets.forEach((el) => {
				el.classList.toggle(CHECKOUT_SUMMARY_ROW_COLLAPSED_CLS);
			});
		}
	}
}

import View from 'Core/View.js';

import CartModel from '../../../shared/data/CartModel';

import AddToBasketButton from './AddToBasketButton';

import './add-to-basket-button.scss';

export default class AddToBasketButtonContainer extends View {
	initialize() {
		this._onFormInputsChanged = this._onFormInputsChanged.bind(this);
		this._onUserInput = this._onUserInput.bind(this);
		this._onReadyForSync = this._onReadyForSync.bind(this);
		this._onCartUpdated = this._onCartUpdated.bind(this);
		this._onCartError = this._onCartError.bind(this);

		this.data = {};
		this.quantities = {};
		this.ignoreUpdateFromCartModel = false;

		// Wait for Cart to be updated and update initial quantity accordingly
		CartModel.updatedSignal.add(this._onCartUpdated);
		CartModel.errorSignal.add(this._onCartError);
		this.signals.products.addToBasketDataUpdated.add(this._onFormInputsChanged);

		/** Parse options from data attribute */
		this.options = JSON.parse(this.el.dataset.options);
		this.selectedOptionHash = this.el.dataset.selectedOption
			? this.options.find(option => option.productId === parseInt(this.el.dataset.selectedOption)).hash
			: undefined;

		/** If no options are set, we assume that the product is a single product */
		const hasOptions = this.options && this.options.length > 0;
		if (!hasOptions) {
			const product = JSON.parse(this.el.dataset.product);
			this.options = [product];
		}

		this.translations = JSON.parse(this.el.dataset.translations);
		this.mode = this.el.dataset.mode || 'regular'; // 'regular' or 'addOnly'
		this.soldOut = JSON.parse(this.el.dataset.soldOut) || false;
		this.disabled = JSON.parse(this.el.dataset.disabled) || false;

		this._render();
	}

	destroy() {
		CartModel.updatedSignal.remove(this._onCartUpdated);
		CartModel.updatedSignal.remove(this._onCartError);
		this.signals.products.addToBasketDataUpdated.remove(this._onFormInputsChanged);
	}

	_render() {
		this.renderReact(this.el, AddToBasketButton, {
			options: this.options,
			selectedOptionHash: this.selectedOptionHash,
			translations: this.translations,
			quantities: this.quantities,
			mode: this.mode,
			soldOut: this.soldOut,
			disabled: this.disabled,
			onChange: this._onUserInput,
			onReadyForSync: this._onReadyForSync,
		});
	}

	_onUserInput(quantities) {
		this.quantities = quantities;

		this._preventUpdateFromCartModelForAWhile();
		this._render();
	}

	_onReadyForSync() {
		this._syncChanges();
	}

	_preventUpdateFromCartModelForAWhile() {
		this.ignoreUpdateFromCartModel = true;
		clearTimeout(this.updateTimeout);

		this.updateTimeout = setTimeout(() => {
			this.ignoreUpdateFromCartModel = false;
		}, 300);
	}

	_onCartUpdated() {
		if (this.ignoreUpdateFromCartModel) {
			return;
		}

		this._updateQuantityFromCart();
	}

	_onCartError(errors) {
		this.signals.forms.beforeErrorsDisplay.dispatch('add-to-basket', errors);

		if (this.ignoreUpdateFromCartModel) {
			return;
		}

		this._updateQuantityFromCart();
	}

	_updateQuantityFromCart() {
		let highestPosition = this._highestNextPosition();

		this.quantities = this.options.reduce((quantities, option) => {
			const productInCart = CartModel.getProductByHash(option.hash);
			if (!productInCart) {
				return quantities;
			}

			const productInLocalState = this.quantities[option.hash];

			quantities[option.hash] = {
				hash: option.hash,
				productId: option.productId,
				quantity: productInCart.quantity,
				position: productInLocalState ? productInLocalState.position : ++highestPosition,
			};

			return quantities;
		}, {});

		this._render();
	}

	_highestNextPosition() {
		return Object.values(this.quantities).reduce((highest, { position }) => {
			return Math.max(highest, position);
		}, 0);
	}

	_syncChanges() {
		const changes = this._determineOutOfSyncProducts();
		if (!changes || changes.length === 0) {
			return;
		}

		changes.forEach(change => {
			const extraData = Object.assign({}, this.data, {
				product_id: change.productId,
				hash: change.hash,
			});

			this.signals.forms.beforeSubmit.dispatch('add-to-basket', extraData);
			CartModel.updateProduct(change.productId, change.quantity, extraData);
		});
	}

	_onFormInputsChanged(data) {
		const oldState = this.disabled;

		if (this._containsEmptyValues(data) || this._containsMissingRecipients(data)) {
			this.disabled = true;
		} else if (this.disabled) {
			this.disabled = false;
		}

		if (oldState !== this.disabled) {
			this._render();
		}

		this.data = data;
	}

	_containsMissingRecipients(data) {
		if (!data.recipients) return false;

		const recipients = JSON.parse(data.recipients);
		return recipients.some(recipient => {
			return !(recipient.name && recipient.email && /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/.test(recipient.email));
		})
	}

	_containsEmptyValues(data) {
		return Object.keys(data).some(key => !data[key] && key !== 'hash');
	}

	_determineOutOfSyncProducts() {
		// Find the difference between what's in the cart model the current quantities
		return this.options
			.map(option => {
				const productInCart = CartModel.getProductByHash(option.hash);
				const productInLocalState = this.quantities[option.hash];
				if (!productInCart) {
					// Not in cart nor in local state
					if (!productInLocalState) {
						return undefined;
					}

					// In local state but not in cart
					return productInLocalState;
				}

				// In cart but not in local state
				if (!productInLocalState) {
					return {
						hash: option.hash,
						productId: option.productId,
						quantity: productInCart.quantity,
						position: this._highestNextPosition(),
					};
				}

				// In cart and in local state
				if (productInCart.quantity === productInLocalState.quantity && productInLocalState.alwaysAdd !== true) {
					return undefined;
				}

				return productInLocalState;
			})
			.filter(x => !!x);
	}
}

import View from '../../../shared/Core/View';

import './bar-stories.scss';

export default class BarStories extends View {

    initialize() {
    }

    destroy() {
    }
}

const BoxFitting = {

	/**
	 * Retrieve the dimensions of the document viewport as an object
	 * @return {Object} Object with {width: 0, height: 0} format
	 */
	getDocumentViewportDimensions: function () {
		var w = window,
			d = document,
			e = d.documentElement,
			g = d.getElementsByTagName('body')[0],
			x = w.innerWidth || e.clientWidth || g.clientWidth,
			y = w.innerHeight || e.clientHeight || g.clientHeight;

		return {width: x, height: y};
	},

	/**
	 * Get the letterbox from the supplied Boxes
	 * @return {Object} Object in {left: 0, top: 0, width: 0, height: 0} format
	 */
	getLetterBox: function (dimensionsSource, dimensionsTarget) {
		var sourceRatio = dimensionsSource.width / dimensionsSource.height;
		var targetRatio = dimensionsTarget.width / dimensionsTarget.height;
		if (sourceRatio > targetRatio) {
			var targetheight = dimensionsTarget.width / sourceRatio;
			var targetyoffset = (dimensionsTarget.height - targetheight) / 2;
			return {left: 0, top: targetyoffset, width: dimensionsTarget.width, height: targetheight};
		}
		var targetwidth = dimensionsTarget.height * sourceRatio;
		var targetxoffset = (dimensionsTarget.width - targetwidth) / 2;

		return {left: targetxoffset, top: 0, width: targetwidth, height: dimensionsTarget.height};
	},

	/**
	 * Get the fillbox from the supplied Boxes
	 * @return {Object} Object in {left: 0, top: 0, width: 0, height: 0} format
	 */
	getFillBox: function (dimensionsSource, dimensionsTarget) {
		var sourceRatio = dimensionsSource.width / dimensionsSource.height;
		var targetRatio = dimensionsTarget.width / dimensionsTarget.height;
		if (sourceRatio < targetRatio) {
			var targetheight = dimensionsTarget.width / sourceRatio;
			var targetyoffset = (dimensionsTarget.height - targetheight) / 2;
			return {left: 0, top: targetyoffset, width: dimensionsTarget.width, height: targetheight};
		}
		var targetwidth = dimensionsTarget.height * sourceRatio;
		var targetxoffset = (dimensionsTarget.width - targetwidth) / 2;
		return {left: targetxoffset, top: 0, width: targetwidth, height: dimensionsTarget.height};
	}
};

export default BoxFitting;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

let fieldCount = 0;

class TextInput extends Component {

	constructor(props) {
		super(props);

		this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this);

		this.state = {
			hasFocus: false,

			// Only used when onChange is not defined
			value: '',
		};
	}

	componentWillMount() {
		fieldCount ++;
		this.uniqueId = 'field_' + fieldCount;
	}

	render() {
		const classes = classNames({
			'field': true,
			'field--no-label': ! this.props.label,
			'field--focus': this.state.hasFocus,
			'field--not-empty': this.isNotEmpty(),
			'field--has-error': ! ! this.props.error,
			'field--size-large': ! ! this.props.large,
			[this.props.className]: ! ! this.props.className,
		});

		const value = this.props.onChange ? this.props.value : this.state.value;

		return (
			<div className={classes}>
				<label htmlFor={this.getInputId()} className="field__label">
					{this.props.label}
				</label>

				<input
					type={this.props.type}
					name={this.props.name}
					autoComplete={this.props.autoComplete ? undefined : 'off'}
					value={value}
					id={this.getInputId()}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					onChange={this.handleChange}
					ref={input => this.input = input}/>

				{this.renderErrorWhenSet()}
			</div>
		);
	}

	renderErrorWhenSet() {
		if (! this.props.error) {
			return null;
		}

		return (
			<div className="field__error">{this.props.error}</div>
		);
	}

	handleChange(event) {
		if (this.props.onChange) {
			this.props.onChange(event.target.value);
		} else {
			this.setState({value: event.target.value});
		}
	}

	handleFocus(event) {
		this.setState({hasFocus: true});

		if (this.props.onFocus) {
			this.props.onFocus(event);
		}
	}

	handleBlur() {
		this.setState({hasFocus: false});

		if (this.props.onBlur) {
			this.props.onBlur(event);
		}
	}

	isNotEmpty() {
		return this.props.onChange ? this.props.value : this.state.value;
	}

	getInputId() {
		if (this.props.inputId) {
			return this.props.inputId;
		}

		return this.uniqueId;
	}
}

TextInput.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	className: PropTypes.string,

	error: PropTypes.string,
	type: PropTypes.string,
	autoFocus: PropTypes.bool,
	autoComplete: PropTypes.bool,
	large: PropTypes.bool,
	inputId: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
};

TextInput.defaultProps = {
	type: 'text',
};

export default TextInput;
import View from 'Core/View.js';

import createCookie from '../../../shared/utils/cookie/createCookie';
import readCookie from '../../../shared/utils/cookie/readCookie';

const MODAL_ID = 'shop-popup';
const TIMEOUT_IN_SECONDS = 30;

export default class ShopPopup extends View {

	initialize(options) {
		this.modal = options.modalDictionary[MODAL_ID];
		this.scheduleShow = this.scheduleShow.bind(this);
		this.show = this.show.bind(this);
		this.handleModalHide = this.handleModalHide.bind(this);

		this.signals.modals.hide.add(this.handleModalHide);
		this.signals.pageContentReplaced.add(this.scheduleShow);

		this.intervalId = null;

		this.scheduleShow();
	}

	destroy() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}

		this.signals.modals.hide.remove(this.handleModalHide);
		this.signals.pageContentReplaced.remove(this.scheduleShow);
	}

	scheduleShow() {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}

		// Has cookies set?
		if(! window.tonysCookieConsent.functional) {
			return;
		}

		if (this.wasDeclinedEarlier()) {
			return;
		}

		// Don't show an any page with a form like checkout, registration, contact etc.
		if (document.querySelectorAll('.wrapper__content form').length > 0) {
			return;
		}

		// Show modal after 30 seconds
		this.intervalId = setInterval(this.show, TIMEOUT_IN_SECONDS * 1000);
	}

	show() {
		if (document.body.dataset.popup !== 'true') {
			return;
		}

		clearInterval(this.intervalId);
		this.modal.show();
	}

	decline() {
		// Set the declined cookie for 30 days
		createCookie('shop_popup', 'declined', 30);

		this.modal.hide();
	}

	wasDeclinedEarlier() {
		return readCookie('shop_popup') === 'declined';
	}

	handleModalHide(modalId) {
		if (modalId === MODAL_ID) {
			this.decline();
		}
	}
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Location from '../../shapes/Location';

import StreetViewLoader from './components/StreetView/StreetViewLoader';

import './location-details.scss';

class LocationDetails extends Component {

	render() {
		const classes = classNames({
			'location-details': true,
			'location-details--full-screen': this.props.enlargedStreetView,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<div className={classes}>
				{this.renderInfoMobileOrNotFullScreen()}

				<StreetViewLoader key="streetview"
								  heightRatio={0.5625}
								  enlarged={this.props.enlargedStreetView}
								  onEnlargeToggle={this.props.onEnlargeStreetViewToggle}
								  isMobile={this.props.isMobile}
								  location={this.props.location}
								  apiKey={this.props.apiKey}/>
			</div>
		);
	}

	renderInfoMobileOrNotFullScreen() {
		if (this.props.enlargedStreetView && ! this.props.isMobile) {
			return null;
		}

		return (
			<div className="location-details__info">
				<h3 className="location-details__name">
					{this.renderTitle()}
				</h3>
				<p className="location-details__address" dangerouslySetInnerHTML={{__html: this.renderAddress()}}/>

				{this.renderPhoneNumber()}
				{this.renderOpeningHours()}

				{this.renderChildren()}
			</div>
		);
	}

	renderTitle() {
		const location = this.props.location;

		if (location.url) {
			return (
				<a href={location.url}
				   className="location-details__link"
				   target="_blank">
					{location.name}
				</a>
			);
		}

		return location.name;
	}

	renderChildren() {
		if (! this.props.children) {
			return null;
		}

		return (
			<div className="location-details__children">
				{this.props.children}
			</div>
		);
	}

	renderPhoneNumber() {
		if (! this.props.location.phone) {
			return null;
		}

		return (
			<p className="location-details__phone">
				{this.props.translations.phone} {this.props.location.phone}
			</p>
		);
	}

	renderOpeningHours() {
		if (! this.props.location.hours) {
			return null;
		}

		return (
			<div className="location-details__opening-hours"
				 dangerouslySetInnerHTML={{__html: this.props.location.hours}}/>
		);
	}

	renderAddress() {
		return [
			this.props.location.address,
			this.props.location.address_two,
			this.props.location.city,
			[this.props.location.state, this.props.location.zipcode].join(' '),
		].filter(x => ! ! x).join(', ');
	}
}

LocationDetails.propTypes = {
	location: Location.isRequired,
	apiKey: PropTypes.string.isRequired,
	translations: PropTypes.object.isRequired,
	className: PropTypes.string,
	children: PropTypes.node,
	isMobile: PropTypes.bool,
	enlargedStreetView: PropTypes.bool,
	onEnlargeStreetViewToggle: PropTypes.func,
};

export default LocationDetails;

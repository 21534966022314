import View from 'Core/View.js';
import debounce from 'utils/generic/debounce.js';

import shopRoutes from '../../../shared/shopRoutes';

import MetaTab from './components/MetaTab/MetaTab.js';

import './styles/meta.scss';

const OPEN_CLASS = 'meta--open';

export default class Meta extends View {

	initialize(options) {
		this.handlePageLock = this.handlePageLock.bind(this);
		this.handleMetaButtonClick = this.handleMetaButtonClick.bind(this);
		this.handleTabHidden = this.handleTabHidden.bind(this);
		this.handleTabShown = this.handleTabShown.bind(this);
		this.closeAll = this.closeAll.bind(this);
		this.hideTab = this.hideTab.bind(this);
		this.showTab = this.showTab.bind(this);
		this.handleMouseEnteredTab = this.handleMouseEnteredTab.bind(this);
		this.handleMouseLeftTab = this.handleMouseLeftTab.bind(this);

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.checkVisibility = this.checkVisibility.bind(this);
		this.checkPageLoadVisibility = this.checkPageLoadVisibility.bind(this);

		this.buttons = this.findAll('.meta__button--has-tab');
		this.tabElements = this.findAll('.meta__tab');
		this.buttonContainer = this.find('.meta__buttons');

		this.currentTabId = null;
		this.tabs = this.createTabs(this.tabElements);

		this.lockPage = debounce(this.signals.pageLock.lockRequested.dispatch, 100);
		this.unlockPage = debounce(this.signals.pageLock.unlockRequested.dispatch, 100);

		this.buttons.forEach(button => button.addEventListener('click', this.handleMetaButtonClick));

		this.signals.pageLoad.started.add(this.closeAll);
		this.signals.pageLock.lockRequested.add(this.handlePageLock);
		this.signals.meta.mouseEnteredTab.add(this.handleMouseEnteredTab);
		this.signals.meta.mouseLeftTab.add(this.handleMouseLeftTab);

		// Hide Meta if not in shop-top-nav element and shop top nav is shown
		if(!this.el.classList.contains('shop-top-nav__meta')) {
			this.checkVisibility = this.checkVisibility.bind(this);
			this.checkPageLoadVisibility = this.checkPageLoadVisibility.bind(this);

			this.signals.pageLoad.requested.add(this.checkPageLoadVisibility);
			this.signals.pageContentReplaced.add(this.checkVisibility);

			// Initial check
			this.checkVisibility(this.el, options.routeAtLoad);
		}
	}

	destroy() {
		this.signals.pageLoad.started.remove(this.closeAll);
		this.signals.pageLock.lockRequested.remove(this.handlePageLock);
		this.signals.meta.mouseEnteredTab.remove(this.handleMouseEnteredTab);
		this.signals.meta.mouseLeftTab.remove(this.handleMouseLeftTab);

		if (this.currentTabId) {
			this.hideTab(this.currentTabId);
		}
	}

	handleMetaButtonClick(event) {
		event.preventDefault();

		const tabId = this.getTabIdFromTrigger(event.currentTarget);
		this.switchToTab(tabId);
	}

	switchToTab(newTabId = null, delay = 250) {
		if (this.hideTimeout) {
			clearTimeout(this.hideTimeout);
		}

		if (newTabId === this.currentTabId) {
			this.hideTab(newTabId);
			return;
		}

		if (this.currentTabId && ! newTabId) {
			this.hideTimeout = setTimeout(() => {
				this.hideTab(this.currentTabId);
			}, delay);
			return;
		}

		if (this.currentTabId) {
			this.hideTab(this.currentTabId, false);
		}

		this.showTab(newTabId);
	}

	showTab(tabId) {
		const button = this.find('.meta__button[data-id="' + tabId + '"]');
		const right = this.el.clientWidth - button.offsetLeft - 73;
		this.tabs[tabId].el.style.right = right + 'px';

		this.el.classList.add(OPEN_CLASS);
		this.tabs[tabId].show();
	}

	hideTab(tabId) {
		tabId = this.getTabIdFromFQTabId(tabId);
		if (! this.tabs[tabId]) {
			return;
		}

		this.tabs[tabId].hide();
	}

	closeAll() {
		if (this.currentTabId === null) {
			return;
		}

		this.switchToTab(null);
	}

	createTabs(tabElements) {
		const tabs = {};
		tabElements.forEach(tab => {
			const newTab = new MetaTab({
				el: tab,
				signals: this.signals,
			});

			newTab.signals.meta.tabCloseRequested.add(this.handleTabHidden);
			newTab.signals.meta.tabOpenRequested.add(this.handleTabShown);

			tabs[this.getTabIdFromTab(tab)] = newTab;
		});

		return tabs;
	}

	cancelAutoHide() {
		clearTimeout(this.hideTimeout);
	}

	getTabIdFromTrigger(el) {
		return el.dataset.id;
	}

	getTabIdFromTab(el) {
		return this.getTabIdFromFQTabId(el.id);
	}

	getTabIdFromFQTabId(tabId) {
		if (! tabId) {
			return tabId;
		}

		return tabId.replace('tab-', '');
	}

	handleTabShown(tabId) {
		this.currentTabId = this.getTabIdFromFQTabId(tabId);
		this.lockPage('meta.' + tabId);
	}

	handleTabHidden(tabId) {
		this.hideTimeout = null;
		this.currentTabId = null;

		this.el.classList.remove(OPEN_CLASS);
		this.unlockPage('meta.' + tabId);
	}

	handleMouseEnteredTab(tabId) {
	}

	handleMouseLeftTab(tabId) {
	}

	handlePageLock(lockId) {
		if (lockId.indexOf('meta.') === 0) {
			return;
		}

		this.switchToTab(null, 0);
	}

	show() {
		if(this.buttonContainer) {
			this.buttonContainer.classList.remove('meta__buttons--hidden');
		}
	}

	hide() {
		if(this.buttonContainer) {
			this.buttonContainer.classList.remove('meta__buttons--hidden');
			this.buttonContainer.classList.add('meta__buttons--hidden');
		}
	}

	checkPageLoadVisibility(route) {
		this.checkVisibility(null, route);
	}

	checkVisibility(element, route) {
		if (this.isRouteWithTopNavHeader(route)) {
			this.hide();
		} else {
			this.show();
		}
	}

	isRouteWithTopNavHeader(route) {
		return route.isExactMatch && shopRoutes.indexOf(route.name) >= 0;
	}
}

import View from '../../../../shared/Core/View';
import TweenLite from 'gsap/TweenLite';

import './shop-bar.scss';

export default class Collaborate extends View {

	initialize() {
		this.image = this.find('.shop-bar__loader');
		this.shopBar = this.find('.shop-bar__image');
	}

	destroy() {
	}

	preload(onComplete, onFailure) {
		this.image.addEventListener('load', () => {
			onComplete();
			this.shopBar.style.backgroundImage = `url('${this.image.src}')`;
		});
		this.image.addEventListener('error', onFailure);
		this.image.src = this.image.dataset.src;
	}

	onAppeared() {
		TweenLite.killTweensOf(this.el);
		TweenLite.to(this.el, 0.6, {
			x: 0,
			y: -1600,
			ease: Cubic.easeIn,
			onComplete: () => {
				this.signals.barInAnimationCompleted.dispatch();
			},
		});
	}
}

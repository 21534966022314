import Signal from 'signals';

export default () => ({
	loadStarted: new Signal(),
	loadCompleted: new Signal(),
	fullscreenToggleRequested: new Signal(),

	progress: new Signal(),

	buffering: new Signal(),
	paused: new Signal(),
	playbackStarted: new Signal(),
	ended: new Signal(),
});

import View from '../../../../shared/Core/View';

import PersonalText from '../components/PersonalText/PersonalText';
import DeliveryAddress from '../components/DeliveryAddress/DeliveryAddress';
import DeliveryDate from '../components/DeliveryDate/DeliveryDate';
import ArrowButton from '../components/ArrowButton';

import './subscription-gift-options.scss';

export default class GiftOptions extends View {

	initialize(options) {
		this.forceUpdate = options.forceUpdate;
		this.handleSubmitClick = this.handleSubmitClick.bind(this);

		this.optionFields = this.findAll('.subscription-gift-options__option');

		this.fields = [
			...this.findAll('.personal-text').map(el => new PersonalText({...options, el: el})),
			...this.findAll('.delivery-address').map(el => new DeliveryAddress({...options, el: el})),
			...this.findAll('.delivery-date').map(el => new DeliveryDate({...options, el: el})),
		];

		this.completedOptionsInput = this.find('input[name="completed_options"]');

		this.arrowButton = new ArrowButton({
			...options,
			el: this.find('.subscription-gift-options__next-step-arrow')
		});

		this.nextStepButton = this.find('.subscription-gift-options__next-step');
		this.nextStepButton.addEventListener('click', this.handleSubmitClick);
	}

	destroy() {
		this.fields.forEach(field => field.destroy());
	}

	visible(state) {
		return !! state.product_id;
	}

	toggleArrow() {
		if (this.completedOptionsInput.value !== '1') {
			this.arrowButton.close();
		} else {
			this.arrowButton.open();
		}
	}

	update(state, prevState) {
		this.optionFields.forEach(el => {
			if (el.dataset.productIds.indexOf(state.product_id) >= 0) {
				el.style.display = 'flex';
			} else {
				el.style.display = 'none';
			}
		});

		// Go back if we changed something but were already at the last step
		if (state.completed_options === '1' && prevState.completed_options === '1') {
			this.completedOptionsInput.value = 0;
			this.forceUpdate();
			return;
		}

		this.toggleArrow();
	}

	handleSubmitClick(event) {
		event.preventDefault();

		const isOn = this.completedOptionsInput.value === '1';
		this.completedOptionsInput.value = isOn ? '0' : '1';

		this.toggleArrow();

		this.forceUpdate();
	}
}

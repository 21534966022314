import Signal from 'signals';
import createRequest from 'superagent';
import CartRequest from './CartRequest';

const defaultOptions = {
	dontShowMetaCart: false,
};

class CartModel {

	constructor() {
		const routes = tonysConfig.routes;
		this.ROUTES = {
			list: '/' + routes['api.cart.list'].route,
			update: '/' + routes['api.cart.cart.update'].route,
			updateMulti: '/' + routes['api.cart.cart.update-multi'].route,
			remove: '/' + routes['api.cart.cart.remove'].route,

			clearDiscount: '/' + routes['api.cart.cart.discount.remove'].route,
			removeGiftCard: '/' + routes['api.cart.cart.gift-card.remove'].route,

			setShipping: '/' + routes['api.cart.shipping'].route,
		};

		this.updatedSignal = new Signal();
		this.requestSuccessSignal = new Signal();
		this.newProductSignal = new Signal(); // triggered when server returns product not currently in products
		this.errorSignal = new Signal();

		this.onLoaded = this.onLoaded.bind(this);
		this.load = this.load.bind(this);
		this.handleSuccess = this.handleSuccess.bind(this);
		this.handleFailure = this.handleFailure.bind(this);
		this._handleConfirmReplace = this._handleConfirmReplace.bind(this);

		this.currentOptions = defaultOptions;
		this.showShipping = false;
		this.disableOrdering = window.tonysConfig.shop.disableOrdering;

		this.requestQueue = [];
		this.requestPending = false;

		this.cartButton = document.querySelector('a.sticky-mini-cart');
	}

	initialize(options) {
		this.signals = options.signals;

		this.clearCartWarningModal = options.modals['clear-cart-warning'];
		this.clearCartWarningModal.find('.clear-cart-warning__yes').addEventListener('click', this._handleConfirmReplace);

		// Initial load
		this.load();
	}

	hasLoaded() {
		return !!this.products;
	}

	/**
	 * Loads cart meta data and products
	 */
	load(extraParams = {}, options = {}) {
		if (this.disableOrdering) {
			return;
		}

		this.currentOptions = { ...defaultOptions, ...options };

		// Create and send request
		const request = createRequest('GET', this.ROUTES.list);
		request.query(extraParams);
		request.set('Accept', 'application/json');
		request.set('X-Requested-With', 'XMLHttpRequest');

		this.addCartRequest(request, this.onLoaded, this.handleFailure, true);
	}

	onLoaded(response) {
		const meta = response.body.meta;
		const products = response.body.products;
		const showShipping = response.body.showShipping;

		this.cartButton = document.querySelector('a.sticky-mini-cart');

		this.update(meta, products, showShipping);
	}

	update(meta, products, showShipping) {
		if (this.requestQueue.length > 0) {
			return;
		}

		const hasNewProducts = this._hasNewProducts(this.products, products);
		this.trackDifferences(this.products, products);

		const previousProductCount = this.getProductCount();

		this.meta = meta;
		this.products = products;
		this.showShipping = showShipping;

		if (hasNewProducts) {
			this.newProductSignal.dispatch(this.currentOptions);
		}

		this.updatedSignal.dispatch(this.products, {
			productCount: this.getProductCount(),
			previousProductCount: previousProductCount,
		});

		this.signals.ecommerce.cartUpdated.dispatch(this.products);
	}

	trackDifferences(oldProducts, newProducts = []) {

		// If oldProcucts is null or undefined, do nothing as CartModel is fetching initial state
		if (!oldProducts) {
			return;
		}

		const oldProductKeys = Object.keys(oldProducts);
		const newProductKeys = Object.keys(newProducts);

		const addedItems = [];
		const removedItems = [];

		oldProductKeys.forEach((key) => {

			// product has been removed completely
			if (!newProducts[key]) {
				removedItems.push({ item: oldProducts[key], quantity: oldProducts[key].quantity });
			} else {
				// product update, removed some
				if (oldProducts[key].quantity > newProducts[key].quantity) {
					removedItems.push({ item: oldProducts[key], quantity: oldProducts[key].quantity - newProducts[key].quantity });
					// product update, added some
				} else if (oldProducts[key].quantity < newProducts[key].quantity) {
					addedItems.push({ item: oldProducts[key], quantity: newProducts[key].quantity - oldProducts[key].quantity });
					// nothing changed for this product
				} else {

				}
			}
		});

		newProductKeys.forEach((key) => {
			// product has been added
			if (!oldProducts[key]) {
				addedItems.push({ item: newProducts[key], quantity: newProducts[key].quantity });
			}
		});

		// Dispatch ecommerce events
		if (addedItems.length > 0) {
			this.signals.ecommerce.productAddedToCart.dispatch(addedItems);
		}

		if (removedItems.length > 0) {
			this.signals.ecommerce.productRemovedFromCart.dispatch(addedItems);
		}
	}

	addProduct(product, quantity = 1, extra = {}) {

		if (this.disableOrdering) {
			return;
		}

		if (typeof (product) === 'object') {
			product = product.id;
		}

		// Check if we can add product to a request in the queue
		const cartRequest = this._findFirstUpdateRequest(product, extra);
		if (cartRequest) {
			cartRequest.request._data.quantity += quantity;
			// Else create new request
		} else {
			const productData = this.getProduct(product, extra);

			if (productData) {
				this.updateProduct(product, productData.quantity + quantity, extra);
			} else {
				this.updateProduct(product, quantity, extra);
			}
		}
	}

	updateProduct(productId, quantity = 1, extra = {}) {
		if (this.disableOrdering) {
			return;
		}

		if (typeof (productId) === 'object') {
			productId = productId.id;
		}

		// Make sure it's an integer
		productId = parseInt(productId);

		const data = {
			product_id: productId,
			...extra,
			quantity: quantity,
		};

		if (data.hash === '') {
			delete data.hash;
		}

		if (this.meta && this.meta.containsPresaleProduct && quantity > 0) {
			this.queuedUpdate = data;
			this.clearCartWarningModal.show();
			return;
		}

		const productData = this.getProduct(productId, extra);
		if (quantity === 0) {
			// Item removed
		} else if (productData) {
			// We're not adding or removing, just tweaking
		} else {
			// We're adding, but don't have product information available until the request comes back
			this.newProductSignal
		}

		// Check if we can hijack and adjust a request in the queue for the same product
		const cartRequest = this._findLastUpdateRequest(productId, extra);
		if (cartRequest) {
			cartRequest.update(data);
			// Else create new request
		} else {
			const request = createRequest('PUT', this.ROUTES.update);
			request.set('Accept', 'application/json');
			request.set('X-Requested-With', 'XMLHttpRequest');
			request.send(data);

			this.addCartRequest(request, this.handleSuccess, this.handleFailure);
		}
	}

	emptyCart() {
		this.update({}, {});
	}

	/**
	 * Expects an Array with Objects containing:
	 *        product_id
	 *        wrapper_id
	 *        quantity
	 * @param productsData
	 */
	updateProducts(productsData) {
		if (this.disableOrdering) {
			return;
		}

		// Remove any queued requests vor updateMulti first
		this._removeAllRequestsForURL(this.ROUTES.updateMulti);

		// Create new request for updateMulti and add to queue
		const request = createRequest('PUT', this.ROUTES.updateMulti);
		request.set('Accept', 'application/json');
		request.set('X-Requested-With', 'XMLHttpRequest');
		request.send({ 'products': productsData });

		this.addCartRequest(request, this.handleSuccess, this.handleFailure);
	}

	clearDiscount() {
		if (this.disableOrdering) {
			return;
		}

		const request = createRequest('DELETE', this.ROUTES.clearDiscount);
		request.set('Accept', 'application/json');
		request.set('X-Requested-With', 'XMLHttpRequest');

		// Check if we can ignore this request
		if (!this._findFirstRequest(this.ROUTES.clearDiscount)) {
			this.addCartRequest(request, this.handleSuccess, this.handleFailure);
		}
	}

	removeGiftCard(code) {
		if (this.disableOrdering) {
			return;
		}

		const request = createRequest('DELETE', this.ROUTES.removeGiftCard.replace('{code}', code));
		request.set('Accept', 'application/json');
		request.set('X-Requested-With', 'XMLHttpRequest');

		// Check if we can ignore this request
		if (!this._findFirstRequest(this.ROUTES.removeGiftCard.replace('{code}', code))) {
			this.addCartRequest(request, this.handleSuccess, this.handleFailure);
		}
	}

	setShippingMethod(id) {
		if (this.disableOrdering) {
			return;
		}

		const request = createRequest('POST', this.ROUTES.setShipping);
		request.set('Accept', 'application/json');
		request.set('X-Requested-With', 'XMLHttpRequest');
		request.send({ 'shippingMethod': id });

		// Check if we can ignore this request
		this.addCartRequest(request, this.handleSuccess, this.handleFailure);
	}

	getProduct(productId, extra = {}) {
		const productsArray = this.getProducts();
		var result = null;

		for (var i = 0; i < productsArray.length; i++) {
			const product = productsArray[i];
			if (product.id === productId) {
				if (extra && this._oldDataMatchesNew(product, extra)) {
					return product;
				} else if (!extra || Object.keys(extra).length === 0) {
					return product;
				}
			}
		}

		return result;
	}

	getProductByHash(hash) {
		if (!this.products || !hash in this.products) {
			return null;
		}

		return this.products[hash];
	}

	getProducts() {
		if (this.products) {
			const productsArray = Object.keys(this.products).map((k) => this.products[k]);
			return productsArray.reverse();
		}

		return [];
	}

	getProductCount() {
		const productData = this.getProducts();
		return productData.reduce((previousQuantity, product) => previousQuantity + product.quantity, 0);
	}

	getMeta() {
		return this.meta;
	}

	getBeanCount() {
		if (this.meta && this.meta.totalImpactBeans) {
			return this.meta.totalImpactBeans;
		}

		return 0;
	}

	hasVolumeDiscount() {

		if (this.meta && this.meta.volumeDiscount) {
			var discountString = this.meta.volumeDiscount.discountAmount + '';
			discountString = discountString.replace(',', '.');
			var discount = parseFloat(discountString);

			if (!isNaN(discount) && discount > 0) {
				return true;
			}
		}

		return false;
	}

	/**
	 * Returns postcard data if exists, undefined if n
	 */
	getPostcard() {
		const productData = this.getProducts();
		return productData.find(product => product.postcard);
	}

	hasNextVolumeDiscount() {

		if (this.meta && this.meta.nextVolumeDiscount) {
			var discountString = this.meta.nextVolumeDiscount.remaining + '';
			discountString = discountString.replace(',', '.');
			var discount = parseFloat(discountString);

			if (!isNaN(discount) && discount > 0) {
				return true;
			}
		}

		return false;
	}

	hasDiscountCode() {
		return this.meta && this.meta.discountCode && this.meta.discountCode.length > 0;
	}

	hasDiscount() {

		if (this.meta && this.meta.discountPrice) {
			var discountString = this.meta.discountPrice + '';
			discountString = discountString.replace(',', '.');
			var discount = parseFloat(discountString);

			if (!isNaN(discount) && discount > 0) {
				return true;
			}
		}

		return false;
	}

	hasGiftCard() {

		if (this.meta && this.meta.giftCardPrice) {
			var giftCardString = this.meta.giftCardPrice + '';
			giftCardString = giftCardString.replace(',', '.');
			var giftCard = parseFloat(giftCardString);

			if (!isNaN(giftCard) && giftCard > 0) {
				return true;
			}
		}

		return false;
	}

	handleSuccess(response) {
		// Contains cart + meta update?
		if (response.type === 'application/json') {
			const result = JSON.parse(response.text);
			if (result.cart) {
				let products = result.cart.products;

				this.update(result.cart.meta, products);
			}

			this.requestSuccessSignal.dispatch(response);

			this.checkForMessages(result);
			this.handleMultipleMessages(result.messages);
		}

		this.requestPending = false;
		if (this.cartButton) this.cartButton.classList.remove('sticky-mini-cart--disabled')
		this.doNextCartRequest();
	}

	handleMultipleMessages(messages) {
		if (!messages) {
			return;
		}

		for (let productId in messages) {
			const message = messages[productId];

			if (message.code !== 200) {
				this.errorSignal.dispatch(message.message);
				this.signals.toaster.requested.dispatch(message.message, 3);
			}
		}
	}

	handleFailure(error) {
		this.requestPending = false;
		if (this.cartButton) this.cartButton.classList.remove('sticky-mini-cart--disabled')

		if (error.status === 404) {
			return;
		}

		if (error.response) {
			const response = JSON.parse(error.response.text);
			this.errorSignal.dispatch(response);
			this.checkForMessages(response);

			// If we get back a cart, update the model anyway (e.g. when we get a 422 error)
			if (error.status === 422 && response.cart) {
				this.update(response.cart.meta, response.cart.products);
			}
		}

		if (this.requestQueue.length > 0) {
			this.doNextCartRequest();
			return;
		}

		// Force load to retrieve latest values for product quantities
		setTimeout(this.load, 5000);
	}

	checkForMessages(response) {
		if (response.modalMessage) {
			this.signals.modals.messageRequested.dispatch(response.modalMessage, (response.modalButton || ''));
		}

		if (response.message) {
			this.signals.toaster.requested.dispatch(response.message, 3);
		}
	}

	addCartRequest(request, success, failure, ignoreQueue = false) {
		this.cartRequest = new CartRequest(request, success, failure);

		if (!ignoreQueue) {
			this.requestQueue.push(this.cartRequest);
			this.doNextCartRequest();
		} else {
			this.cartRequest.execute();
		}
	}

	doNextCartRequest() {
		if (!this.requestPending && this.requestQueue.length > 0) {
			this.requestPending = true;
			if (this.cartButton) this.cartButton.classList.add('sticky-mini-cart--disabled')
			const cartRequest = this.requestQueue.shift();
			cartRequest.execute();
		} else if (!this.requestPending) {
			// console.log('no cart api requests pending');
		}
	}

	_removeAllRequestsForURL(url) {
		this.requestQueue = this.requestQueue.filter((request) => request.request.url !== url);
	}

	_findFirstRequest(url) {
		this.requestQueue.every((request) => {
			if (request.request.url === url) {
				return request;
			}
		});

		return false;
	}

	_findFirstUpdateRequest(productId, extra = {}) {
		for (let i = 0; i < this.requestQueue.length; i++) {
			const request = this.requestQueue[i];
			if (this._requestMatches(request, productId, extra)) {
				return request;
			}
		}

		return false;
	}

	_findLastUpdateRequest(productId, extra = {}) {
		for (let i = this.requestQueue.length - 1; i >= 0; i--) {
			const request = this.requestQueue[i];
			if (this._requestMatches(request, productId, extra)) {
				return request;
			}
		}

		return false;
	}

	_requestMatches(request, productId, extra = {}) {
		if (request.request.url !== this.ROUTES.update) {
			return false;
		}

		if (parseInt(request.request._data.product_id) !== productId) {
			return false;
		}

		return this._oldDataMatchesNew(request.request._data, extra);
	}

	_oldDataMatchesNew(oldData, newData) {
		const ignoreKeys = ['quantity'];

		for (let key in newData) {
			if (!newData.hasOwnProperty(key)) {
				continue;
			}

			if (!oldData.hasOwnProperty(key)) {
				return false;
			}

			if (!ignoreKeys.includes(key) && String(oldData[key]) !== String(newData[key])) {
				return false;
			}
		}

		return true;
	}

	_hasNewProducts(oldProducts, newProducts) {
		if (!oldProducts) {
			return false;
		}

		oldProducts = Object.keys(oldProducts).map((k) => oldProducts[k]);
		newProducts = Object.keys(newProducts).map((k) => newProducts[k]);

		return !!newProducts.find((newProduct) => {
			const matchingOldProduct = oldProducts.find((oldProduct) => {
				if (newProduct.id !== oldProduct.id) {
					return false;
				}

				return newProduct.wrapperId === oldProduct.wrapperId;
			});

			return !matchingOldProduct;
		});
	}

	_handleConfirmReplace(event) {
		event.preventDefault();

		const { product_id, quantity, ...extra } = this.queuedUpdate;

		this.meta.containsPresaleProduct = false;
		this.updateProduct(product_id, quantity, extra);
		this.queuedUpdate = null;

		this.clearCartWarningModal.hide();
	}
}

const cart = new CartModel();
export default cart;

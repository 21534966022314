import View from 'Core/View';

import './conversation-bars-filters.scss';
import TweenLite from 'gsap/TweenLite';


export default class ConversationBarsModalControls extends View {

	/**
	 *
	 * @param options
	 */
	initialize(options) {
		this.image = this.el.querySelector('img');
		if(this.image) {
			this.src = this.image.getAttribute('src');
			this.title = this.image.getAttribute('alt');
		}

		this.onWebShare = this.onWebShare.bind(this);
		this.onDownload = this.onDownload.bind(this);

		if (navigator.share) {
			TweenLite.set(options.el.querySelector('.conversation-bars-share-controls__classic'), {display: 'none'});
			this.el.querySelector('button[data-type="share"]').addEventListener('click', this.onWebShare);
		} else {
			TweenLite.set(options.el.querySelector('.conversation-bars-share-controls__webshare'), {display: 'none'});
			this.el.querySelector('button[data-type="download"]').addEventListener('click', this.onDownload);
		}
	}

	/**
	 *
	 */
	onDownload() {
		const link = document.createElement('a');
		link.href = this.src;
		link.download = this.src;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	/**
	 *
	 */
	onWebShare() {
		fetch(this.src)
			.then(res => res.blob())
			.then(blob => {
				let srcSegments = this.src.split('/');
				let name = srcSegments[srcSegments.length - 1];
				const file = new File([blob], name, {type: blob.type});

				navigator.share({ text: this.title, url: this.src, files: [file]});
			})
	}

	destroy() {
		super.destroy();
	}
}

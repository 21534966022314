import View from 'Core/View.js';
import createRequest from 'superagent';
import './shop-category-wrapper.scss';

export default class ShopCategoryWrapper extends View {

	initialize(options) {
		this.options = options;
		this.url = this.el.dataset.url;
		this.contents = this.find('.shop-category-wrapper__contents');
		this.loaded = false;
		this.loading = false;

		this.onContentsLoaded = this.onContentsLoaded.bind(this);
		this.onContentsLoadError = this.onContentsLoadError.bind(this);
		this.onScrollPositionChanged = this.onScrollPositionChanged.bind(this);

		window.addEventListener('scroll', this.onScrollPositionChanged);

		// Initial position check (should we already start loading?)
		this.onScrollPositionChanged();
	}

	destroy() {
		window.removeEventListener('scroll', this.onScrollPositionChanged);
	}

	onScrollPositionChanged() {
		// lazy load check

		if(!this.loading && !this.loaded) {
			const rect = this.contents.getBoundingClientRect();
			const margin = 100;
			if (rect.top - margin <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.bottom + margin >= 0) {
				this.loadContents();
			}
		}
	}

	loadContents() {
		if(!this.loading && !this.loaded) {
			this.loading = true;
			this.el.classList.add('shop-category-wrapper--loading');
			const request = createRequest('GET', this.url).set('Accept', window.tonysConfig.acceptHeader || '*/*');
			request.then(this.onContentsLoaded, this.onContentsLoadError);
		}
	}

	onContentsLoaded(response) {
		this.contents.innerHTML = response.text;
		this.initializeChildViews();

		this.loading = false;
		this.loaded = true;

		this.el.classList.add('shop-category-wrapper--loaded');
		this.el.classList.remove('shop-category-wrapper--loading');

		// Work is done, no need to do anything anymore
		this.destroy();
	}

	onContentsLoadError(error) {
		if (error.response) {
			const errors = error.response.body;
			errors.map(errorMessage => {
				this.signals.toaster.requested.dispatch(errorMessage, 6);
			});
		} else {
			this.signals.toaster.requested.dispatch('An error occurred while loading the page. Please try again later.', 6);
		}

		this.loading = false;
		this.loaded = true;

		this.el.classList.add('shop-category-wrapper--failed');
		this.el.classList.remove('shop-category-wrapper--loading');

		// Work is done, no need to do anything anymore
		this.destroy();
	}
}

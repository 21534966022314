import React from 'react';
import PropTypes from 'prop-types';
import LatLng from '../../../shared/shapes/LatLng';

import TruckLocatorMap from './components/TruckLocatorMap/TruckLocatorMap';
import TruckLocatorEvents from './components/TruckLocatorEvents/TruckLocatorEvents';
import TruckLocatorEventDetails from './components/TruckLocatorEventDetails/TruckLocatorEventDetails';
import TruckLocatorPagination from './components/TruckLocatorPagination/TruckLocatorPagination';

import fetchEvents from './requests/events';

import './truck-locator.scss';

const findIndexOfLastPastEvent = (events, idsInOrder, perPage) => {
	const index = idsInOrder.findIndex(id => ! events[id].inPast) - 1;
	if (index < 0) {
		return idsInOrder.length - perPage;
	}

	return index;
};

class TruckLocator extends React.PureComponent {

	constructor(props) {
		super(props);

		this.selectEvent = this.selectEvent.bind(this);
		this.deselectCurrentEvent = this.deselectCurrentEvent.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.previousPage = this.previousPage.bind(this);

		this.state = {
			currentEvent: null,
			events: null,
			eventIdsInOrder: [],

			currentEvents: null,
			defaultStartIndex: 0,
			page: 0,
			perPage: 8,
		};
	}

	componentDidMount() {
		this.updatePerPageForDevice(this.props.isMobile);
		this.loadEvents();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isMobile !== this.props.isMobile) {
			this.updatePerPageForDevice(nextProps.isMobile);
		}
	}

	render() {
		if (! this.state.events) {
			return (
				<div className="truck-locator-loading">
					{this.props.translations.loading}
				</div>
			);
		}

		return (
			<div className="truck-locator">
				<div className="truck-locator__side">
					{this.renderEventListsOrDetails()}
				</div>
				<TruckLocatorMap
					isMobile={this.props.isMobile}
					apiKey={this.props.apiKey}
					className="truck-locator__map"
					events={this.state.events}
					eventIdsInOrder={this.getEventIdsForPage(this.state.page)}
					currentDate={this.props.currentDate}
					onEventSelected={this.selectEvent}
					currentEventId={this.state.currentEvent ? this.state.currentEvent.id : null}
					initialCenter={this.props.initialCenter}
					initialZoom={this.props.initialZoom}
				/>
			</div>
		);
	}

	renderEventListsOrDetails() {
		if (this.state.currentEvent) {
			return (
				<TruckLocatorEventDetails
					translations={this.props.translations}
					event={this.state.currentEvent}
					onClose={this.deselectCurrentEvent}
					className="truck-locator__event-details"
				/>
			);
		}

		return (
			<React.Fragment>
				<TruckLocatorPagination
					translations={this.props.translations}
					onNextPage={this.nextPage}
					onPreviousPage={this.previousPage}
					hasNextPage={this.hasNextPage()}
					hasPreviousPage={this.hasPreviousPage()}
					className="truck-locator__pagination"/>

				<TruckLocatorEvents
					locale={this.props.locale}
					translations={this.props.translations}
					isMobile={this.props.isMobile}
					onEventChosen={this.selectEvent}
					className="truck-locator__events"
					events={this.state.events}
					eventIdsInOrder={this.getEventIdsForPage(this.state.page)}
				/>
			</React.Fragment>
		);
	}

	selectEvent(event) {
		if (event === this.state.currentEvent) {
			this.deselectCurrentEvent();
			return;
		}

		this.setState({currentEvent: event});
	}

	offsetForPage(page) {
		const offset = this.state.defaultStartIndex + page * this.state.perPage;

		return Math.min(Math.max(0, offset), this.state.eventIdsInOrder.length);
	}

	hasNextPage() {
		return this.offsetForPage(this.state.page + 1) < this.state.eventIdsInOrder.length;
	}

	hasPreviousPage() {
		return this.offsetForPage(this.state.page) > 0;
	}

	nextPage() {
		if (! this.hasNextPage()) {
			return;
		}

		this.setState({page: this.state.page + 1});
	}

	previousPage() {
		if (! this.hasPreviousPage()) {
			return;
		}

		this.setState({page: this.state.page - 1});
	}

	getEventIdsForPage(page) {
		const ids = this.state.eventIdsInOrder;

		return ids.slice(this.offsetForPage(page), this.offsetForPage(page + 1));
	}

	deselectCurrentEvent() {
		this.setState({currentEvent: null});
	}

	loadEvents() {
		fetchEvents(this.props.eventsApiUrl).then((events) => {
			const eventsById = {};

			events.forEach(event => {
				eventsById[event.id] = event;
			});

			const eventIdsInOrder = events.sort((a, b) => {
				return a.startDate.getTime() - b.startDate.getTime();
			}).map(event => event.id);

			this.setState({
				events: eventsById,
				eventIdsInOrder: eventIdsInOrder,
				defaultStartIndex: findIndexOfLastPastEvent(eventsById, eventIdsInOrder, this.state.perPage),
			});
		});
	}

	updatePerPageForDevice(isMobile) {
		this.setState({
			perPage: isMobile ? 3 : 8,
		});
	}
}

TruckLocator.propTypes = {
	translations: PropTypes.object.isRequired,
	apiKey: PropTypes.string.isRequired,
	eventsApiUrl: PropTypes.string.isRequired,
	currentDate: PropTypes.instanceOf(Date).isRequired,
	locale: PropTypes.string,

	isMobile: PropTypes.bool,
	initialCenter: LatLng,
	initialZoom: PropTypes.number,
	initialFocusLocation: PropTypes.number,
};

TruckLocator.defaultProps = {
	isMobile: false,
	locale: 'en-US',
	initialCenter: {
		lat: 40,
		lng: -98.7058248
	},
	initialZoom: 4,
};

export default TruckLocator;

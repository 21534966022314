import View from 'Core/View.js';

import './telephone-field.scss';

export default class TelephoneField extends View {

	initialize() {
		this.updateCountryCode = this.updateCountryCode.bind(this);

		this.input = this.find('.select__select');
		this.countryCode = this.find('.telephone-field__country-code');
		this.globe = this.find('.telephone-field__globe');

		this.input.addEventListener('change', this.updateCountryCode);
		this.updateCountryCode();
	}

	destroy() {
		this.input.removeEventListener('change', this.updateCountryCode);
	}

	updateCountryCode() {
		this.globe.style.display = this.input.value ? 'none' : 'inline-block';
		this.countryCode.innerHTML = this.input.value;
	}
}

import DefaultPage from '../DefaultPage/DefaultPage.js';
import { LOCK_ID } from '../../components/CartOverlay/CartOverlay';
import StupidPageLoadService from '../../../services/StupidPageLoadService';
import CartModel from '../../../shared/data/CartModel';

import './checkout-payment.scss';

const SECTION_LOCKED_CLASS = 'checkout-payment__pay-section--locked';

const PAYMENT_METHODS_CONTAINER_SELECTOR = '.checkout-payment__methods-list';

export default class CheckoutPayment extends DefaultPage {
	initialize(options) {
		super.initialize();

		this.openCart = this.openCart.bind(this);
		this.checkIfCanPay = this.checkIfCanPay.bind(this);
		this.reloadPaymentMethods = this.reloadPaymentMethods.bind(this);
		this._onCartUpdatedRequest = this._onCartUpdatedRequest.bind(this);
		this.orderAmountModal = options.modalDictionary['minimum-order-amount-not-reached'];
		this.smallOrderAmountModal = options.modalDictionary['small-minimum-order-amount-not-reached'];

		this.editCartButton = this.find('.checkout-payment__summary-wrapper__edit-btn');
		this.summaryWrapperEl = this.find('.checkout-payment__summary-wrapper');
		this.methodsEl = this.find('.checkout-payment__methods');
		this.paySectionEl = this.find('.checkout-payment__pay-section');
		this.detailsCorrectCheckbox = this.find('.checkout__details-correct-checkbox input');
		this.termsCheckbox = this.find('.checkout__terms-checkbox input');
		this.paymentMethodsContainer = this.find(PAYMENT_METHODS_CONTAINER_SELECTOR);

		this.editCartButton.addEventListener('click', this.openCart);

		this.signals.shop.discountCodeOrGiftCardApplied.add(this.reloadPaymentMethods);
		this.signals.shop.discountCodeOrGiftCardRemoved.add(this.reloadPaymentMethods);

		this.paymentMethodLoader = new StupidPageLoadService({
			onSuccess: html => {
				this.paymentMethodsContainer.innerHTML = '';
				this.paymentMethodsContainer.appendChild(this.getPaymentMethodsFromHtml(html));

				this.initializeChildViews();
			},
			onError: () => {
				console.log('[TONYS] Cannot reload payment methods');
			},
		});

		setTimeout(() => {
			this.summaryWrapperEl.classList.add('checkout-payment__summary-wrapper--showing');
		}, 700);

		if (
			(!this.orderAmountModal || !this.orderAmountModal.el.classList.contains('modal--auto-open')) &&
			(!this.smallOrderAmountModal || !this.smallOrderAmountModal.el.classList.contains('modal--auto-open'))
		) {
			setTimeout(() => {
				this.methodsEl.classList.add('checkout-payment__methods--showing');
			}, 1000);
		}

		if (this.detailsCorrectCheckbox) {
			this.detailsCorrectCheckbox.addEventListener('change', this.checkIfCanPay);
		}

		if (this.termsCheckbox) {
			this.termsCheckbox.addEventListener('change', this.checkIfCanPay);
			this.termsCheckbox.checked = false;
		}

		CartModel.requestSuccessSignal.add(this._onCartUpdatedRequest);

		this.checkIfCanPay();
	}

	destroy() {
		this.editCartButton.removeEventListener('click', this.openCart);

		if (this.detailsCorrectCheckbox) {
			this.detailsCorrectCheckbox.removeEventListener('change', this.checkIfCanPay);
		}

		if (this.termsCheckbox) {
			this.termsCheckbox.removeEventListener('change', this.checkIfCanPay);
		}

		this.signals.shop.discountCodeOrGiftCardApplied.remove(this.reloadPaymentMethods);
		this.signals.shop.discountCodeOrGiftCardRemoved.remove(this.reloadPaymentMethods);

		this.paymentMethodLoader.reset();
		this.paymentMethodLoader = null;

		CartModel.requestSuccessSignal.remove(this._onCartUpdatedRequest);
	}

	openCart() {
		this.signals.pageLock.lockRequested.dispatch(LOCK_ID);
	}

	checkIfCanPay() {
		if (this.hasFilledInRequiredStuff()) {
			this.paySectionEl.classList.remove(SECTION_LOCKED_CLASS);
			return;
		}

		this.paySectionEl.classList.add(SECTION_LOCKED_CLASS);
	}

	hasFilledInRequiredStuff() {
		// If 'details correct' checkbox is enabled, check if it is checked
		if (this.detailsCorrectCheckbox && !this.detailsCorrectCheckbox.checked) {
			return false;
		}

		return this.termsCheckbox.checked;
	}

	reloadPaymentMethods() {
		this.paymentMethodLoader.load(this.el.dataset.currentUrl);
	}

	getPaymentMethodsFromHtml(html) {
		const div = document.createElement('div');
		div.innerHTML = html;

		const paymentMethods = div.querySelector(PAYMENT_METHODS_CONTAINER_SELECTOR);
		if (!paymentMethods) {
			const error = document.createElement('p');
			error.innerHTML = 'Error loading payment methods';

			return error;
		}

		return paymentMethods;
	}

	_onCartUpdatedRequest() {
		const cartMeta = CartModel.getMeta();

		if (cartMeta && (cartMeta.amountNotReached || cartMeta.quantityNotReached)) {
			let content = null;
			if (cartMeta.quantityNotReached) {
				content = this.getShadowDOM(cartMeta.quantityNotReached.modalMessage);
			} else {
				content = this.getShadowDOM(cartMeta.amountNotReached.modalMessage);
			}

			if (content) {
				const innerContent = content.querySelector('.modal').innerHTML;
				this.orderAmountModal.el.innerHTML = innerContent;
				this.orderAmountModal.show();
				this.methodsEl.classList.remove('checkout-payment__methods--showing');
			}
		} else {
			this.orderAmountModal.hide();
			this.methodsEl.classList.add('checkout-payment__methods--showing');
		}

		if (cartMeta && cartMeta.smallQuantityNotReached) {
			let content = this.getShadowDOM(cartMeta.smallQuantityNotReached.modalMessage);
			if (content) {
				const innerContent = content.querySelector('.modal').innerHTML;
				this.smallOrderAmountModal.el.innerHTML = innerContent;
				this.smallOrderAmountModal.show();
				this.methodsEl.classList.remove('checkout-payment__methods--showing');
			}
		} else {
			this.smallOrderAmountModal.hide();
			this.methodsEl.classList.add('checkout-payment__methods--showing');
		}
	}

	getShadowDOM(html) {
		const div = document.createElement('div');
		div.innerHTML = html;

		return div;
	}
}

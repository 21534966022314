import View from '../../../shared/Core/View';

export const LOCK_ID = 'CartOverlay';

import StupidPageLoadService from '../../../services/StupidPageLoadService';

import './cart-overlay.scss';
import CartModel from '../../../shared/data/CartModel';

const CART_OVERLAY_LOADING_CLS = 'cart-overlay--loading';
const CART_OVERLAY_OPEN_CLS = 'cart-overlay--open';
const CART_OVERLAY_READY_CLS = 'cart-overlay--ready';

class CartOverlay extends View {

    initialize() {
		this.handleLockRequested = this.handleLockRequested.bind(this);
		this.handleUnlockRequested = this.handleUnlockRequested.bind(this);
		this.handleCloseClicked = this.handleCloseClicked.bind(this);
		this.handleCartLoaded = this.handleCartLoaded.bind(this);
		this.handleCartLoadError = this.handleCartLoadError.bind(this);
		this.handlePageLoadRequested = this.handlePageLoadRequested.bind(this);
		this.reload = this.reload.bind(this);
		this.scheduleOpen = this.scheduleOpen.bind(this);
		this.open = this.open.bind(this);

		this.signals.pageLock.lockRequested.add(this.handleLockRequested);
		this.signals.pageLock.unlockRequested.add(this.handleUnlockRequested);
		this.signals.pageLoad.requested.add(this.handlePageLoadRequested);
		this.signals.pageContentReplaced.add(this.scheduleOpen);

		this.cartUrl = this.el.dataset.cartUrl;
		this.cartLoader = new StupidPageLoadService({
			onSuccess: this.handleCartLoaded,
			onError: this.handleCartLoadError
		});

		this.cartContainer = this.find('.cart-overlay__container');
		this.isOpen = false;

		CartModel.newProductSignal.add(this.reload);

		this.scheduleOpen();
    }

    destroy() {
		this.signals.pageLock.tabOpenRequested.remove(this.handleLockRequested);
		this.signals.pageLock.unlockRequested.remove(this.handleUnlockRequested);
		this.signals.pageLoad.requested.remove(this.handlePageLoadRequested);
		this.signals.pageContentReplaced.remove(this.scheduleOpen);

		if (this.timeOutId) {
			clearTimeout(this.timeOutId);
		}

		CartModel.newProductSignal.remove(this.reload);

		this.cartLoader.reset();
    }

	scheduleOpen() {
		if (this.timeOutId) {
			clearTimeout(this.timeOutId);
		}

		if (document.body.dataset.autoOpenCart !== 'true' &&
			this.el.dataset.autoOpenCart !== 'true'
		) {
			return;
		}

		// Make sure we only open the cart once when visiting the page /cart directly & navigating away
		this.el.dataset.autoOpenCart = 'false';
		document.body.dataset.autoOpenCart = 'false';

		// Show modal after 30 seconds
		this.timeOutId = setTimeout(() => {
			this.signals.pageLock.lockRequested.dispatch(LOCK_ID);
		}, 200);
	}

	open() {
		this.isOpen = true;
		this.loadCart(true);

		// Try to start from sticky shopping basket for dramatic effect
		const stickyMiniCart = document.querySelector('.sticky-mini-cart, .meta-cart-icon');
		if(stickyMiniCart) {
			const rect = stickyMiniCart.getBoundingClientRect();
			const px = Math.round(((rect.left + rect.width * 0.5)/ window.innerWidth) * 100);
			const py = Math.round(((rect.top + rect.height * 0.5) / window.innerHeight) * 100);
			this.el.style.clipPath = `circle(0% at ${px}% ${py}%)`;
		}

		this.el.classList.remove(CART_OVERLAY_OPEN_CLS);
		this.el.classList.add(CART_OVERLAY_OPEN_CLS);

		TweenLite.to(this.el, 0.2, {
			opacity: 1,
			y: 0,
			ease: Power2.easeOut,
			onComplete: () => {
				this.ready();
			}
		});
	}

	ready() {
		this.el.classList.remove(CART_OVERLAY_READY_CLS);
		this.el.classList.add(CART_OVERLAY_READY_CLS);
	}

	close() {
		this.isOpen = false;
		this.el.classList.remove(CART_OVERLAY_READY_CLS);

		TweenLite.to(this.el, 0.6, {
			onComplete: () => {
				this.el.classList.remove(CART_OVERLAY_OPEN_CLS);
			}
		});

		this.unlock();
	}

	triggerClose() {
		if(this.closeButton) {
			this.closeButton.removeEventListener('click', this.handleCloseClicked);
		}
		this.signals.pageLock.unlockRequested.dispatch(LOCK_ID);
	}

	loadCart(clearContainer = false) {
		if(this.closeButton) {
			this.closeButton.removeEventListener('click', this.handleCloseClicked);
		}

		if(clearContainer) {
			this.cartContainer.innerHTML = '';
		}

		this.el.classList.remove(CART_OVERLAY_LOADING_CLS);
		this.el.classList.add(CART_OVERLAY_LOADING_CLS);
		this.cartLoader.load(this.cartUrl);
	}

	reload() {
		if(this.isOpened()) {
			this.loadCart();
		}
	}

	isOpened() {
		return this.el.classList.contains(CART_OVERLAY_OPEN_CLS);
	}

	isLoading() {
		return this.el.classList.contains(CART_OVERLAY_LOADING_CLS);
	}

	handleCartLoaded(html) {
		this.cartContainer.innerHTML = '';
		this.cartContainer.appendChild(this.getContainerContentsFromHtml(html));

		this.initializeChildViews();

		this.closeButton = this.find('.cart-overlay__close');
		this.closeButton.addEventListener('click', this.handleCloseClicked);

		this.el.classList.remove(CART_OVERLAY_LOADING_CLS);
	}

	handleCartLoadError() {
		this.el.classList.remove(CART_OVERLAY_LOADING_CLS);
	}

	handleCloseClicked(event) {
		event.preventDefault();
		this.triggerClose();
	}

	handleLockRequested(lockId) {
		if (LOCK_ID === lockId) {
			// Set default top
			this.cartContainer.style.top = 0 + 'px';
			this.open();

			return;
		}

		if (!this.isOpen) {
			return;
		}

		this.scrollOffset = this.getScrollOffset();

		this.cartContainer.style.transform = `translateY(${-this.scrollOffset}px)`;
		this.el.classList.add('cart-overlay--locked');
	}

	handleUnlockRequested(lockId) {
		if (LOCK_ID === lockId) {
			this.close();

			// Offset container by page scroll to prevent jump if user scrolled before
			this.cartContainer.style.top = window.scrollY + 'px';

			return;
		}

		if (!this.isOpen) {
			return;
		}

		this.unlock();

		window.scrollTo(0, this.scrollOffset);
	}

	unlock() {
		this.cartContainer.style.transform = '';
		this.el.classList.remove('cart-overlay--locked');
	}

	handlePageLoadRequested() {
		this.triggerClose();
	}

	getContainerContentsFromHtml(html) {
		const div = document.createElement('div');
		div.innerHTML = html;

		const pageContainer = div.querySelector('.page');
		if (!pageContainer) {
			const error = document.createElement('p');
			error.innerHTML = 'Error loading cart contents';

			return error;
		}

		return pageContainer;
	}

	getScrollOffset() {
		return window.pageYOffset || document.documentElement.scrollTop;
	}
}

export default CartOverlay;

import createRequest from 'superagent';

export default function (url, bar) {
	const request = createRequest('POST', url);
	request.set('Accept', 'application/json');
	request.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
	request.set('X-Requested-With', 'XMLHttpRequest');
	request.send(bar);

	return request;
}

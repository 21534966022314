import TweenLite from 'gsap/TweenLite';

import View from '../../../../../../../shared/Core/View';
import {limit} from '../../../../../../../shared/utils/Formatting';

import './video-timeline.scss';

export default class VideoTimeline extends View {

	initialize(options) {
		this.progressBarElement = this.find('.video-timeline__progress-bar');
		this.scrubberElement = this.find('.video-timeline__scrubber');

		this.onDown = this.onDown.bind(this);
		this.onMove = this.onMove.bind(this);
		this.onUp = this.onUp.bind(this);
		this.update = this.update.bind(this);

		this.topContainer = document.body;
		this.el.addEventListener('mousedown', this.onDown);

		this.player = options.player;
		this.player.signals.progress.add(this.update);

		this.updateScrubber(0);
	}

	destroy() {
		this.el.removeEventListener('mousedown', this.onDown);
		this.topContainer.removeEventListener('mouseup', this.onUp);
		this.topContainer.removeEventListener('mousemove', this.onMove);
	}

	onDown(event) {
		event.stopPropagation();

		this.topContainer.addEventListener('mouseup', this.onUp);
		this.topContainer.addEventListener('mousemove', this.onMove);
		this.onMove(event);

		this.isScrubbing = true;
	}

	onMove(event) {
		event.preventDefault();

		const rect = this.el.getBoundingClientRect();
		const w = rect.right - rect.left;
		const x = limit(event.pageX - rect.left, 0, w);

		this.updateScrubber(x / w);
	}

	onUp(event) {
		event.preventDefault();
		event.stopPropagation();

		this.topContainer.removeEventListener('mouseup', this.onUp);
		this.topContainer.removeEventListener('mousemove', this.onMove);

		const rect = this.el.getBoundingClientRect();
		const w = rect.right - rect.left;
		const x = limit(event.pageX - rect.left, 0, w);

		this.isScrubbing = false;
		this.seek(x / w);
	}

	seek(percentage) {
		const duration = this.player.getDuration();
		const time = duration * percentage;

		this.player.seekTo(time, true);
	}

	update() {
		if (! this.isScrubbing) {
			const currentTime = this.player.getCurrentTime();
			const duration = this.player.getDuration();
			const percentage = currentTime / duration;

			this.updateScrubber(percentage);
		}
	}

	updateScrubber(percentage) {
		const rect = this.el.getBoundingClientRect();
		const scrubberWidth = this.scrubberElement.clientWidth;
		const w = rect.right - rect.left - scrubberWidth;

		TweenLite.set(this.progressBarElement, {width: w * percentage});
		TweenLite.set(this.scrubberElement, {x: scrubberWidth / 2 + w * percentage});
	}

}

const DEFAULT_FORM_MESSAGE_TIMEOUT = 3;

export default class {

	constructor(options) {
		this.signals = options.signals;

		this.signals.forms.succeeded.add(this.showToasterIfAMessageIsPresent.bind(this));
	}

	showToasterIfAMessageIsPresent(formId, data) {
		if (data && data.message) {
			this.signals.toaster.requested.dispatch(data.message, DEFAULT_FORM_MESSAGE_TIMEOUT);
		}
	}
}
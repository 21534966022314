import View from 'Core/View.js';

import './manage-consents.scss';

export default class ManageConsents extends View {

	initialize(options) {
		this.handleUnsubscribeClick = this.handleUnsubscribeClick.bind(this);
		this.handleSubmitted = this.handleSubmitted.bind(this);

		this.save = this.find('.manage-consents__save');

		this.unsubscribe = this.find('.manage-consents__unsubscribe');
		this.unsubscribe.addEventListener('click', this.handleUnsubscribeClick);

		this.inputs = this.findAll('.manage-consents__checkbox');
		this.form = this.find('.manage-consents__form');
		this.form.addEventListener('submit', this.handleSubmitted);

		this.submitting = false;
	}

	destroy() {
		this.unsubscribe.removeEventListener('click', this.handleUnsubscribeClick);
		this.form.removeEventListener('submit', this.handleSubmitted);
	}

	handleSubmitted() {
		this.submitting = true;

		this.save.classList.add('button--loading');
		this.unsubscribe.classList.add('button--loading');
	}

	handleUnsubscribeClick(event) {
		event.preventDefault();
		if (this.submitting) {
			return;
		}

		this.inputs.forEach(input => {
			input.checked = false;
		});

		this.form.submit();
		this.handleSubmitted();
	}
}

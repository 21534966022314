import View from 'Core/View.js';

import './sticky-menu-trigger.scss';

export default class StickyBackButton extends View {

	initialize(options) {
		this.toggle = this.toggle.bind(this);

		this.el.addEventListener('click', this.toggle);
	}

	destroy() {
		this.el.removeEventListener('click', this.toggle);
	}

	toggle(event) {
		event.preventDefault();
		this.signals.menu.toggleMenu.dispatch();
	}
}

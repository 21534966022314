import Signal from 'signals';

/**
 * Shop related signals
 */
export default {
	searchOpen: new Signal(),
	searchQuit: new Signal(),

	/**
	 * @param {string} code
	 */
	discountCodeOrGiftCardApplied: new Signal(),
	discountCodeOrGiftCardRemoved: new Signal(),
};

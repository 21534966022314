import View from 'Core/View.js';
import './switch.scss';

const SWITCH_CHECKED_CLASS = 'switch--checked';
const SWITCH_DISABLED_CLASS = 'switch--disabled';

export default class Switch extends View {

	initialize() {

		this.onChange = this.onChange.bind(this);

		this.input = this.find('input');
		this.input.addEventListener('change', this.onChange);

		this.update();
	}

	onChange(event) {
		this.update();
	}

	update() {
		this.el.classList.remove(SWITCH_CHECKED_CLASS);
		this.el.classList.remove(SWITCH_DISABLED_CLASS);

		if(this.input.checked) {
			this.el.classList.add(SWITCH_CHECKED_CLASS);
		}

		if(this.input.disabled) {
			this.el.classList.add(SWITCH_DISABLED_CLASS);
		}
	}

	destroy() {
		this.input.removeEventListener('change', this.onChange);
	}

}

import View from 'Core/View.js';
import Signal from 'signals';
import debounce from 'utils/generic/debounce.js';

export default class NewsOverviewFilters extends View {

	initialize() {
		this.handlePeriodChanged = this.handlePeriodChanged.bind(this);
		this.handleCategoryChanged = this.handleCategoryChanged.bind(this);
		this.handleSearchChanged = this.handleSearchChanged.bind(this);

		this.periodFilter = this.find('.news-overview__period-filter select');
		this.categoryFilter = this.find('.news-overview__category-filter select');
		this.searchInput = this.find('.news-overview__search input');

		this.state = {
			period: this.periodFilter.value,
			category: this.categoryFilter.value,
			search: this.searchInput.value,
		};
		this.changed = new Signal();

		// Debounce update
		this.update = debounce(this.update.bind(this), 500);

		this.periodFilter.addEventListener('change', this.handlePeriodChanged);
		this.categoryFilter.addEventListener('change', this.handleCategoryChanged);
		this.searchInput.addEventListener('keyup', this.handleSearchChanged);
		this.searchInput.addEventListener('input', this.handleSearchChanged);
	}

	destroy() {
		this.periodFilter.removeEventListener('change', this.handlePeriodChanged);
		this.categoryFilter.removeEventListener('change', this.handleCategoryChanged);
		this.searchInput.removeEventListener('keyup', this.handleSearchChanged);
		this.searchInput.removeEventListener('input', this.handleSearchChanged);
	}

	update() {
		this.changed.dispatch(this.state);
	}

	handlePeriodChanged(event) {
		this.state.period = event.target.value;
		this.update();
	}

	handleCategoryChanged(event) {
		this.state.category = event.target.value;
		this.update();
	}

	handleSearchChanged(event) {
		this.state.search = event.target.value;
		this.update();
	}
}
import View from 'Core/View.js';

import './shop-category.scss';

export default class ShopCategory extends View {

	initialize(options) {
		this.options = options;
	}

	destroy() {}
}

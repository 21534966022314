import View from 'Core/View.js';
import { TimelineLite } from 'gsap/all';

import './deep-link-button.scss';

export default class DeepLinkButton extends View {
	initialize(options) {
		this.setupUI();
		this.setupEventListeners();
		this.setupAnimation();
		this.getData();
	}

	destroy() {

		if(this.tl) {
			this.tl.kill();
			this.tl = null;
		}

	}

	setupUI() {
		this.ui = {
			input: this.el.querySelector('.js-input'),
			panel: this.el.querySelector('.js-panel'),
			background: this.el.querySelector('.js-background'),
			buttonCopy: this.el.querySelector('.js-button-copy'),
			buttonLink: this.el.querySelector('.js-button-link'),
		}
	}

	setupEventListeners() {
		this.handleLinkButtonClicked = this.handleLinkButtonClicked.bind(this);
		this.handleCopyButtonClicked = this.handleCopyButtonClicked.bind(this);
		this.handleInputFocus = this.handleInputFocus.bind(this);

		this.ui.buttonLink.addEventListener('click', this.handleLinkButtonClicked);
		this.ui.buttonCopy.addEventListener('click', this.handleCopyButtonClicked);

		this.ui.input.addEventListener('focus', this.handleInputFocus);
	}

	setupAnimation() {
		const { panel, input, background, buttonCopy } = this.ui;

		this.tl = new TimelineLite({paused: true});
		this.tl.set(panel, {display: 'flex'});
		this.tl.fromTo(background, 0.3, {scale: 0, opacity: 0}, {scale: 1, opacity: 1, ease: Back.easeOut});
		this.tl.fromTo(input, 0.1, {x: -2, opacity: 0}, {x: 0, opacity: 1, ease: Power2.easeOut});
		this.tl.fromTo(buttonCopy, 0.2, {scale: 0}, {scale: 1, ease: Power2.easeOut}, '-=0.08');
	}

	getData() {
		this.shareUrl = this.el.dataset.copyUrl;
		this.coppiedMessage = this.el.dataset.copySuccess;
	}

	open() {
		this.el.classList.add('is-open');
		this.tl.play();
	}

	close() {
		this.el.classList.remove('is-open');
		this.tl.reverse();
	}

	handleLinkButtonClicked(e) {
		e.preventDefault();
		if(this.el.classList.contains('is-open')) {
			this.close();
		} else {
			this.open();
		}
	}

	handleCopyButtonClicked(e) {
		e.preventDefault();
		navigator.clipboard.writeText(this.shareUrl);
		this.signals.toaster.requested.dispatch(this.coppiedMessage, 3);
		this.close();
	}

	handleInputFocus(e) {
		e.currentTarget.select();
	}
}

import './how-it-started-slider.scss';
import { limit } from '../../../shared/utils/Formatting';
import TweenLite from 'gsap/TweenLite';
import Signal from 'signals';
import View from 'Core/View.js';

import {Draggable} from 'gsap/all';
import ThrowPropsPlugin from '../../../../../vendors/gsap/ThrowPropsPlugin.js';

// Prevent tree shaking
(() => {})([ThrowPropsPlugin, Draggable]);

const HIS_SLIDER_OFFSET_X = 5; //percent
const HIS_SLIDER_OFFSET_X_END = 10; //percent
const HIS_ARROW_WIDTH = 1216; //pixels
const HIS_ARROW_SLOPE = -16; //pixels full width
const HIS_ARROW_SLOPE_OFFSET_Y = 6; //pixels full width

export default class HowItStartedSlider extends View {

	/**
	 * Setup/Initialize functions
	 */

	initialize() {
		this.yearChanged = new Signal();
		this.startDrag = new Signal();

		this.arrowWrapper = this.find('.how-it-started-slider__slider-background-wrapper');
		this.scrubber = this.find('.how-it-started-slider__slider-scrubber');
		this.tooltip = this.find('.how-it-started-slider__slider-tooltip');
		this.scrubberLabel = this.find('.how-it-started-slider__slider-scrubber-year');
		this.sliderImageContainer = this.find('.how-it-started-slider__images');
		this.sliderImageWrapper = this.find('.how-it-started-slider__images-wrapper');

		this.markers = this.findAll('.how-it-started-slider__slider-marker');
		this.labels = this.findAll('.how-it-started-slider__image-block-title');
		this.images = this.findAll('.how-it-started-slider__image-block--image');
		this.arrows = this.findAll('.how-it-started-slider__image-block--arrow');

		this.bodyElement = document.body;

		this.percentage = 0;
		this.draggingImages = false;
		this.draggingScrubber = false;

		this.onScrubberStart = this.onScrubberStart.bind(this);
		this.onScrubberDrag = this.onScrubberDrag.bind(this);
		this.onScrubberEnd = this.onScrubberEnd.bind(this);
		this.onImageDragStart = this.onImageDragStart.bind(this);
		this.onImageDragEnd = this.onImageDragEnd.bind(this);
		this.onImageDrag = this.onImageDrag.bind(this);

		this.spaceMarkers();
		this.activateScrubber();
		this.activateImages();
		this.onResize();
	}

	onResize() {

		// Reset heights for labels
		this.labels.forEach((label) => {
			label.style.height = 'auto';
		});

		var maxHeight = - 1;
		this.labels.forEach((label) => {
			maxHeight = label.offsetHeight > maxHeight ? label.offsetHeight : maxHeight;
		});

		// Sets heights for labels
		this.labels.forEach((label) => {
			label.style.height = maxHeight + 'px';
		});

		// Update image slider height to push content down
		this.sliderImageContainer.style.height = this.sliderImageContainer.scrollHeight + 'px';

		//
		this.setPercentage(this.percentage);
		this.updateScrubber(this.percentage);
	}

	spaceMarkers() {
		const start = HIS_SLIDER_OFFSET_X;
		const end = HIS_SLIDER_OFFSET_X_END;
		const step = (100 - start - end) / this.markers.length;

		this.markers.forEach((marker, index) => {
			marker.style.left = (start + (index * step)) + '%';
		});
	}

	activateScrubber() {
		this.scrubberX = 0;

		this.arrowWrapper.addEventListener('mousedown', this.onScrubberStart);
		this.arrowWrapper.addEventListener('touchstart', this.onScrubberStart);

		this.setPercentage(this.percentage);
	}

	activateImages() {

		this.draggable = new Draggable(this.sliderImageWrapper, {
			type: 'x',
			edgeResistance: 0.9,
			bounds: this.sliderImageContainer,
			lockAxis: true,
			throwProps: true,
			snap: {
				x: (endValue) => {
					return this.getYearByOffset(endValue).offsetX;
				},
				y: function(endValue) {
					return 0;
				}
			}
		});

		this.draggable.addEventListener('dragstart', this.onImageDragStart);
		this.draggable.addEventListener('dragend', this.onImageDragEnd);
		this.draggable.addEventListener('drag', this.onImageDrag);
	}

	/**
	 * Interaction functions
	 */
	onScrubberStart(event) {

		this.draggingScrubber = true;

		this.scrubber.classList.add('how-it-started-slider__slider-scrubber--dragging');

		const layerX = this.getLayerPosition(event.touches && event.touches[0] ? event.touches[0] : event).x;
		const pageX = event.touches && event.touches[0] ? event.touches[0].pageX : event.pageX;

		this.hideTooltip();

		this.scrubberX = layerX - this.scrubber.offsetWidth * 0.5;
		this.currentX = pageX - this.scrubber.offsetWidth * 0.5;

		this.bodyElement.addEventListener('mousemove', this.onScrubberDrag);
		this.bodyElement.addEventListener('touchmove', this.onScrubberDrag);

		window.addEventListener('mouseup', this.onScrubberEnd);
		window.addEventListener('touchend', this.onScrubberEnd);

		this.startDrag.dispatch();

		this.setPercentage(this.getScrubberPercentage());
	}

	onScrubberDrag(event) {

		const pageX = event.touches && event.touches[0] ? event.touches[0].pageX : event.pageX;

		this.scrubberX += pageX - this.currentX - this.scrubber.offsetWidth * 0.5;
		this.currentX = pageX - this.scrubber.offsetWidth * 0.5;

		this.setPercentage(this.getScrubberPercentage());
	}

	onScrubberEnd(event) {

		this.scrubber.classList.remove('how-it-started-slider__slider-scrubber--dragging');

		this.bodyElement.removeEventListener('mousemove', this.onScrubberDrag);
		this.bodyElement.removeEventListener('mouseup', this.onScrubberEnd);

		this.bodyElement.removeEventListener('touchmove', this.onScrubberDrag);
		this.bodyElement.removeEventListener('touchend', this.onScrubberEnd);

		window.removeEventListener('mouseup', this.onScrubberEnd);
		window.removeEventListener('touchend', this.onScrubberEnd);

		this.draggingScrubber = false;
		this.setPercentage(this.getScrubberPercentage());
	}

	onImageDragStart() {

		this.draggingImages = true;

		this.hideTooltip();

		this.setPercentage(this.getImagesPercentage());

		this.isdx = this.draggable.pointerX;
		this.isdy = this.draggable.pointerY;
		this.ids = true;
	}

	onImageDrag() {

		const dx = this.draggable.pointerX - this.isdx;
		const dy = this.draggable.pointerY - this.isdy;
		const angle = Math.abs((Math.atan2(dy,dx)) / Math.PI * 180);
		const l = Math.sqrt(dx*dx + dy*dy);

		// Check if drag distance larger than 32 and if the angle is
		// on the x axis
		if(l > 32 && (angle < 45 || angle > 135)) {
			// User is dragging from left to right
			// Dispatch signal to hide current content
			if(this.ids) {
				this.ids = false;
				this.startDrag.dispatch();
			}
		}

		this.setPercentage(this.getImagesPercentage());
	}

	onImageDragEnd() {
		if(this.ids) {
			this.ids = false;
		} else {
			this.yearChanged.dispatch(this.selected);
		}

		this.draggingImages = false;
		this.setPercentage(this.getImagesPercentage());
	}

	/**
	 * Calculation functions
	 */

	getYearByOffset(positionX) {

		const offsetX = this.sliderImageContainer.offsetWidth * 0.5;
		const firstX = this.images[0].offsetLeft + (this.images[0].offsetWidth * 0.5) - offsetX;
		const lastX = this.images[this.images.length - 1].offsetLeft + (this.images[this.images.length - 1].offsetWidth * 0.5) - offsetX;

		const normalizedPositionX = -positionX - firstX;
		const percentage = normalizedPositionX / (lastX - firstX);

		return this.getYearByPercentage(percentage);
	}

	getYearByPercentage(percentage) {

		const offsetX = this.sliderImageContainer.offsetWidth * 0.5;
		const firstX = this.images[0].offsetLeft + (this.images[0].offsetWidth * 0.5) - offsetX;
		const lastX = this.images[this.images.length - 1].offsetLeft + (this.images[this.images.length - 1].offsetWidth * 0.5) - offsetX;

		var wrapperPosX = -(firstX + percentage * (lastX - firstX));

		var closest = null;
		var closestDistance = Number.MAX_VALUE;

		this.images.forEach((image) => {
			const px = image.offsetLeft + (image.offsetWidth * 0.5);
			const distance = wrapperPosX + px - offsetX;

			if (Math.abs(distance) < Math.abs(closestDistance)) {
				closest = image;
				closestDistance = distance;
			}

		});

		return new ImageSlideData(closest, wrapperPosX - closestDistance, firstX, lastX);
	}


	getScrubberPosY(percentage) {
		const sh = this.scrubber.offsetHeight;
		const np = this.getArrowWrapperWidth() / HIS_ARROW_WIDTH;
		const p = percentage * np;

		// console.log(sh, np, p);

		return (-sh * 0.5) + (p * HIS_ARROW_SLOPE) + (np * HIS_ARROW_SLOPE_OFFSET_Y);
	}

	getLayerPosition(evt) {
		var el = evt.target,
			x = 0,
			y = 0;

		while (el && ! isNaN(el.offsetLeft) && ! isNaN(el.offsetTop)) {
			x += el.offsetLeft - el.scrollLeft;
			y += el.offsetTop - el.scrollTop;
			el = el.offsetParent;
		}

		x = evt.clientX - x;
		y = evt.clientY - y;

		return {x: x, y: y};
	}

	hideTooltip() {
		this.tooltip.classList.add('how-it-started-slider__slider-tooltip--hidden');
	}

	/**
	 * Main function to determine position, and current selected year
	 *
	 * @param percentage
	 */
	setPercentage(percentage, force = false) {

		this.percentage = percentage;

		const closestData = this.getYearByPercentage(percentage);

		this.setSelected(closestData, force);

		this.updateScrubber(percentage, !this.draggingScrubber);

		if(!this.draggingImages) {
			this.updateImages();
		}
	}

	setSelected(data, force) {
		if (data && !data.closest) return;

		if (!this.selected || this.selected.closest.dataset.year != data.closest.dataset.year || force) {
			this.selected = data;

			// Change year label on scrubber
			if (data.closest) {
				this.scrubberLabel.innerText = this.selected.closest.dataset.year;
			}

			this.yearChanged.dispatch(this.selected);
			return true;
		}

		return false;
	}

	updateScrubber(percentage, animate = false) {
		const aw = this.getAvailableScrubberWidth();
		this.scrubberX = percentage * aw;
		this.scrubberY = this.getScrubberPosY(percentage);

		const duration = animate ? 0.3 : 0;

		TweenLite.killTweensOf(this.scrubber);
		TweenLite.to(this.scrubber, duration, {x: this.scrubberX, y: this.scrubberY, ease: Sine.easeInOut});
	}

	updateImages() {
		TweenLite.killTweensOf(this.sliderImageWrapper);
		TweenLite.to(this.sliderImageWrapper, 0.3, {x: this.selected.offsetX, ease: Sine.easeOut});
	}

	getImagesPercentage() {
		var percentage = 0;
		if(this.selected) {
			const dragPositionX = -this.draggable.endX;
			const totalWidth = this.selected.lastX - this.selected.firstX;
			percentage = (dragPositionX - this.selected.firstX) / totalWidth;
		}

		return limit(percentage, 0, 1);
	}

	getScrubberPercentage() {
		const aw = this.getAvailableScrubberWidth();
		return limit(this.scrubberX / aw, 0, 1);
	}

	getArrowWrapperWidth() {
		return this.arrowWrapper.offsetWidth;
	}

	getAvailableScrubberWidth() {
		return (this.arrowWrapper.offsetWidth - this.scrubber.offsetWidth) * 0.9;
	}

	destroy() {
		this.arrowWrapper.removeEventListener('mousedown', this.onScrubberStart);
		this.arrowWrapper.removeEventListener('touchstart', this.onScrubberStart);

		this.draggable.removeEventListener('dragstart', this.onImageDragStart);
		this.draggable.removeEventListener('dragend', this.onImageDragEnd);
		this.draggable.removeEventListener('drag', this.onImageDrag);

		this.bodyElement.removeEventListener('mousemove', this.onScrubberDrag);
		window.removeEventListener('mouseup', this.onScrubberEnd);

		this.bodyElement.removeEventListener('touchmove', this.onScrubberDrag);
		window.removeEventListener('touchend', this.onScrubberEnd);
	}
}

class ImageSlideData {

	constructor(closest, offsetX, firstX, lastX) {
		this.closest = closest;
		this.offsetX = offsetX;
		this.firstX = firstX;
		this.lastX = lastX;
	}
}

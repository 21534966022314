const loadScript = (src, done) => {
	var js = document.createElement('script');
	js.src = src;
	js.onload = function() {
		done();
	};
	js.onerror = function() {
		throw new Error('Failed to load script ' + src);
	};
	document.head.appendChild(js);
};

const browserSupportsAllFeatures = () => {
	if (! window.FormData) {
		return false;
	}

	const fd = new FormData();
	if (typeof fd.entries !== 'function') {
		return false;
	}

	return true;
};

export default function(callback) {
	if (browserSupportsAllFeatures()) {
		callback();
		return;
	}

	loadScript(window.tonysConfig.paths.polyfills, callback);
}

import View from 'Core/View.js';

import './shop-themed-category.scss';

export default class ShopThemedCategory extends View {

	initialize() {}

	destroy() {}
}

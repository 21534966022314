import createRequest from 'superagent';

export default function(url, recipe) {
	const request = createRequest('POST', url);
	request.set('Accept', 'application/json');
	request.set('X-Requested-With', 'XMLHttpRequest');
	request.send({recipe: recipe});

	return request.then(response => response.text);
}

import React from 'react';
import PropTypes from 'prop-types';

import './street-view-image.scss';

const getStreetViewURL = (props) => {
	const baseURL = 'https://maps.googleapis.com/maps/api/streetview';
	const location = '?location=' + encodeURIComponent(props.address);
	const options = [
		'size=640x' + Math.round(640 * props.heightRatio).toString(),
		'sensor=false',
		'key=' + props.apiKey,
	];

	return baseURL + location + '&' + options.join('&');
};

const StreetViewImage = (props) => {
	return (
		<img src={getStreetViewURL(props)} className="street-view-image"/>
	);
};

StreetViewImage.propTypes = {
	heightRatio: PropTypes.number.isRequired,
	apiKey: PropTypes.string,
	address: PropTypes.string.isRequired,
};

export default StreetViewImage;
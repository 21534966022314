import View from '../../../../../shared/Core/View';

import './mobile-search-trigger.scss';

export default class MobileSearchTrigger extends View {

    initialize() {
		this.handleClick = this.handleClick.bind(this);
		this.el.addEventListener('click', this.handleClick);
    }

    destroy() {
		this.el.removeEventListener('click', this.handleClick);
    }

	handleClick(event) {
		event.preventDefault();

		this.signals.shop.searchOpen.dispatch();
	}
}

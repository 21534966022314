import View from 'Core/View.js';

import './form-response-modal.scss';

export default class FormResponseModal extends View {

	initialize(options) {
		this.modal = options.modalDictionary[this.el.id];

		this.show = this.show.bind(this);

		this.signals.modals.messageRequested.add(this.show);

		this.messageContainer = this.find('.form-response-modal__message');
		this.buttonContainer = this.find('.form-response-modal__button');
	}

	destroy() {
		this.signals.modals.messageRequested.remove(this.show);
	}

	show(message, button = '') {
		this.messageContainer.innerHTML = message;

		if (button !== '') {
			this.buttonContainer.innerHTML = button;
		} else {
			this.buttonContainer.innerHTML = this.buttonContainer.dataset.defaultContent;
		}

		this.modal.show();
	}
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Location from '../../shapes/Location';
import SearchResultsRow from './components/SearchResultsRow/SearchResultsRow';
import SVGIcon from '../../../../reactComponents/SVGIcon/SVGIcon';

import './search-results.scss';

const defaultArrowOptions = {
	onClick: null,
	disabled: false,
	direction: 'up',
};

class SearchResults extends Component {

	constructor(props) {
		super(props);
		this.state = {
			perPage: 5,
			currentPage: 0,
			pageCount: 0,
		};

		this.handlePreviousPageClicked = this.handlePreviousPageClicked.bind(this);
		this.handleNextPageClicked = this.handleNextPageClicked.bind(this);
	}

	componentDidMount() {
		this.resetPagination(this.props.locations);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.locations !== this.props.locations) {
			this.resetPagination(nextProps.locations);
		}
	}

	render() {
		const classes = classNames({
			'search-results': true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<div className={classes}>
				{this.renderArrow({
					direction: 'up',
					disabled: this.state.currentPage === 0,
					onClick: this.handlePreviousPageClicked,
				})}

				<div className="search-results__locations">
					{this.renderLocations()}
				</div>

				{this.renderArrow({
					direction: 'down',
					disabled: this.state.currentPage >= this.state.pageCount - 1,
					onClick: this.handleNextPageClicked,
				})}
			</div>
		);
	}

	renderLocations() {
		if (this.props.locations.length === 0) {
			return (
				<div className="search-results__no-hits">
					{this.props.translations.no_results}
				</div>
			);
		}

		const start = this.state.currentPage * this.state.perPage;
		const end = Math.min(start + this.state.perPage, this.props.locations.length);

		return this.props.locations.slice(start, end).map((location) => {
			return (
				<SearchResultsRow
					key={location.id}
					location={location}
					onSelected={this.props.onLocationSelected}/>
			);
		});
	}

	renderArrow(options) {

		// Don't render when showing no results message
		if (Object.keys(this.props.locations).length === 0) {
			return null;
		}

		options = Object.assign({}, defaultArrowOptions, options);

		const classes = classNames({
			'search-results__arrow-wrapper': true,
			['search-results__arrow-wrapper--' + options.direction]: true,
		});

		const arrowClasses = classNames({
			'search-results__arrow': true,
			'search-results__arrow--disabled': options.disabled,
		});

		return (
			<div className={classes}>
				<a href="#" className={arrowClasses}
				   onClick={options.onClick}
				   disabled={options.disabled}>
					<SVGIcon name={'arrow-in-circle--' + options.direction}/>
				</a>
			</div>
		);
	}

	resetPagination(newLocations) {
		this.setState({
			currentPage: 0,
			pageCount: Math.ceil(newLocations.length / this.state.perPage),
		});
	}

	handlePreviousPageClicked(event) {
		event.preventDefault();
		this.setState({currentPage: Math.max(0, this.state.currentPage - 1)});
	}

	handleNextPageClicked(event) {
		event.preventDefault();
		this.setState({currentPage: Math.min(this.state.pageCount - 1, this.state.currentPage + 1)});
	}
}

SearchResults.propTypes = {
	translations: PropTypes.object.isRequired,
	locations: PropTypes.arrayOf(Location),
	onLocationSelected: PropTypes.func,
	className: PropTypes.string,
};

export default SearchResults;

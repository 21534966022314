import TweenLite from 'gsap/TweenLite';

const IMPACT_DESKTOP_WIDGET_STEP_1 = 'impact_desktop_widget_step_1';
const IMPACT_DESKTOP_WIDGET_STEP_2 = 'impact_desktop_widget_step_2';

export default class ImpactDesktop {

	constructor(el, data, translations) {
		this.el = el;
		this.globalSettings = data;
		this.translations = translations;

		this.FULL_WIDTH = 1276;
		this.FULL_HEIGHT = 230; // Desktop height

		this.yourBeanCount = 0;
		this.yourBeanCountTarget = 0;
		this.firstCalculation = true;


		this.impactWrapper = this.el.querySelector('.impact-desktop__wrapper');
		this.yourImpactButton = this.el.querySelector('.impact-desktop__step1-your-impact');

		this.productSelect = this.el.querySelector('.impact-desktop__select-product select');
		this.amountSelect = this.el.querySelector('.impact-desktop__select-amount select')

		this.calculateButton = this.el.querySelector('.impact-desktop__calculate-button');
		this.resetButton = this.el.querySelector('.impact-desktop__reset-button');

		this.farmerCounter = this.el.querySelector('.impact-desktop__step1-farmers .impact-desktop__counter');
		this.beanCounter = this.el.querySelector('.impact-desktop__step1-beans .impact-desktop__counter');
		this.barCounter = this.el.querySelector('.impact-desktop__step1-sales .impact-desktop__counter');
		this.bonusCounter = this.el.querySelector('.impact-desktop__step1-bonus .impact-desktop__counter');

		this.step2Counter = this.el.querySelector('.impact-desktop__step2-counter');
		this.step2Message = this.el.querySelector('.impact-desktop__step2-message');

		this.step1YourImpact = this.el.querySelector('.impact-desktop__step1-your-impact');
		this.step2YourImpact = this.el.querySelector('.impact-desktop__step2-your-impact');
		this.impactMessageDetails = this.el.querySelector('.impact-desktop__message-details');
		this.originalImpactMessageDetail = this.impactMessageDetails.innerHTML;

		this.yourBeanCounter = this.el.querySelector('.impact-desktop__bean-counter');

		this.tick = this.tick.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onResize = this.onResize.bind(this);
		this.onSelectChange = this.onSelectChange.bind(this);
		this.onCalculateClick = this.onCalculateClick.bind(this);
		this.onResetClick = this.onResetClick.bind(this);
		this.resetInput = this.resetInput.bind(this);

		this.yourImpactButton.addEventListener('click', this.onClick);
		this.calculateButton.addEventListener('click', this.onCalculateClick);
		this.resetButton.addEventListener('click', this.onResetClick);

		this.productSelect.addEventListener('change', this.onSelectChange);
		this.amountSelect.addEventListener('change', this.onSelectChange);

		this.state = IMPACT_DESKTOP_WIDGET_STEP_1;
		this.state = IMPACT_DESKTOP_WIDGET_STEP_1;
	}

	hide() {
		this.el.classList.add('impact-desktop--hidden');
	}

	show() {
		this.el.classList.remove('impact-desktop--hidden');
	}

	changeState(state, instant = false) {
		this.state = state;
		switch(state) {
			case IMPACT_DESKTOP_WIDGET_STEP_1:
				this.toStep1(instant);
				break;
			case IMPACT_DESKTOP_WIDGET_STEP_2:
				this.toStep2(instant);
				break;
		}
	}

	onClick() {
		this.changeState(IMPACT_DESKTOP_WIDGET_STEP_2);
	}

	onSelectChange() {

		if((this.amountSelect.value + '').length > 0 && (this.productSelect.value + '').length > 0) {
			this.calculateButton.disabled = false;
			this.calculateButton.classList.remove('button--disabled');
		} else {
			this.calculateButton.classList.add('button--disabled');
		}
	}

	onResize() {
		const newHeight = Math.round((this.el.offsetWidth / this.FULL_WIDTH) * this.FULL_HEIGHT);
		this.el.style.height = newHeight + 'px';

		if(this.el.offsetWidth < 1100 ) {
			this.el.classList.add('medium');
		} else {
			this.el.classList.remove('medium');
		}

		this.changeState(this.state, true);
	}

	onCalculateClick(event) {
		event.preventDefault();

		this.yourBeanCountTarget += this.productSelect.value * this.amountSelect.value;
		this.calculateButton.classList.add('button--disabled');
		this.calculateButton.classList.add('hidden');
		this.animateYourBeanCounter();
	}

	onResetClick(event) {
		event.preventDefault();

		this.calculateButton.classList.remove('hidden');
		TweenLite.killDelayedCallsTo(this.resetInput);

		this.productSelect.value = this.amountSelect.value = '';
		this.yourBeanCountTarget = 0;

		this.onSelectChange();
		this.animateYourBeanCounter();
	}

	resetInput() {
		TweenLite.delayedCall(0, () => {
			this.productSelect.value = '';
		});
		TweenLite.delayedCall(0.3, () => {
			this.amountSelect.value = '';
		});
		TweenLite.delayedCall(0.6, () => {
			this.calculateButton.classList.remove('hidden');
		});
	}

	animateYourBeanCounter() {
		this.firstCalculation = this.yourBeanCountTarget == 0;

		if(this.yourBeanCountTarget > 0) {
			this.step2Counter.classList.remove('hidden');
			this.step2Message.classList.remove('hidden');
			this.resetButton.classList.remove('hidden');
			this.calculateButton.querySelector('.button__label').innerText = this.translations.calculateMore;
		} else {
			this.step2Counter.classList.add('hidden');
			this.step2Message.classList.add('hidden');
			this.resetButton.classList.add('hidden');
			this.calculateButton.querySelector('.button__label').innerText = this.translations.calculate;
		}

		TweenLite.killTweensOf(this);
		TweenLite.to(this, 3, {yourBeanCount:this.yourBeanCountTarget, ease:Quint.easeOut,
			onUpdate:this.updateYourBeans,
			onUpdateScope:this,
			onComplete:this.updateBeansText,
			onCompleteScope:this
		});
	}

	toStep1(instant = false) {
		const duration = instant ? 0 : 0.6;
		TweenLite.to(this.impactWrapper, duration, {x:0, ease:Sine.easeInOut});
	}

	toStep2(instant = false) {
		const duration = instant ? 0 : 0.6;
		const width = this.el.offsetWidth;
		TweenLite.to(this.impactWrapper, duration, {x:-width, ease:Sine.easeInout, onComplete:this.onStep2Shown, onCompleteScope:this});
		this.step1YourImpact.classList.add('hide');
	}

	onStep2Shown() {
		this.step2YourImpact.classList.add('show');
	}

	tick() {
		const timePassed = (new Date().getTime()/1000) - this.globalSettings.startTime;
		const daysPassed = timePassed / 86400;

		const farmersCount = this.formatNumber( this.globalSettings.farmersStartCount + this.globalSettings.farmersDailyIncrease * daysPassed );
		const beansCount = this.formatNumber( this.globalSettings.beansStartCount + this.globalSettings.beansDailyIncrease * daysPassed );
		const barCount = this.formatNumber( this.globalSettings.barStartCount + this.globalSettings.barDailyIncrease * daysPassed );
		const bonusCount = this.formatNumber(  this.globalSettings.bonusStartCount + this.globalSettings.bonysDailyIncrease * daysPassed );

		this.farmerCounter.innerHTML = farmersCount;
		this.beanCounter.innerHTML = beansCount;
		this.barCounter.innerHTML = barCount;
		this.bonusCounter.innerHTML = bonusCount;
	}

	updateYourBeans() {
		this.yourBeanCounter.innerHTML = Math.round(this.yourBeanCount);
	}

	updateBeansText() {
		TweenLite.killTweensOf(this.impactMessageDetails);

		const duration = this.firstCalculation ? 0 : 0.6;

		TweenLite.to(this.impactMessageDetails, duration, {opacity:0, ease:Sine.easeInout,
			onComplete:this.beanTextIn,
			onCompleteScope:this});
	}

	beanTextIn() {
		if(this.yourBeanCount > 0) {
			this.impactMessageDetails.innerHTML = this.originalImpactMessageDetail.replace('%count%', this.yourBeanCount);
			TweenLite.to(this.impactMessageDetails, 0.6, {opacity: 1, ease: Sine.easeInout});

			TweenLite.killDelayedCallsTo(this.resetInput);
			TweenLite.delayedCall(1, this.resetInput);
		}
	}

	formatNumber(x) {
		x = Math.round(x);
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.globalSettings.thousandsSeparator||'.');
	}

	destroy() {

	}
}

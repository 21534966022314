import TweenLite from 'gsap/TweenLite';
import View from '../../../shared/Core/View';
import CartModel from '../../../shared/data/CartModel';
import CurrencyFormatter from '../../../shared/utils/currency/CurrencyFormatter';

import './shipping-costs-status.scss';

const SHIPPING_FREE = 'shipping-cost-status--free';
const SHIPPING_ALMOST_FREE = 'shipping-cost-status--almost-free';

export default class ShippingCostStatus extends View {

	initialize() {
		this.update = this.update.bind(this);

		this.statusLabel = this.find('.shipping-cost-status__label');


		this.freeLabelText = this.el.dataset.freeLabel;
		this.almostFreeLabelText = this.el.dataset.almostFreeLabel;

		TweenLite.set(this.el, {height: 0});

		CartModel.updatedSignal.add(this.update);
		this.update();
	}

	destroy() {
		CartModel.updatedSignal.remove(this.update);
	}

	reset() {
		this.el.classList.remove(SHIPPING_FREE);
		this.el.classList.remove(SHIPPING_ALMOST_FREE);
	}

	update() {
		this.reset();

		if (CartModel.meta && CartModel.meta.shippingFree !== undefined && CartModel.meta.shippingFree !== null) {
			const amountLeft = parseFloat(CartModel.meta.shippingFree);
			const formattedAmount = CurrencyFormatter.format(amountLeft)

			if(amountLeft <= 0) {
				this.el.classList.add(SHIPPING_FREE);
				this.statusLabel.innerHTML = this.freeLabelText;
			} else {
				this.el.classList.add(SHIPPING_ALMOST_FREE);
				this.statusLabel.innerHTML = this.almostFreeLabelText.replaceAll(':amount', formattedAmount);
			}

			this.show();
		} else {
			this.hide();
		}
	}

	show() {
		TweenLite.to(this.el, 0.3, {height: 'auto'});
	}

	hide() {
		TweenLite.to(this.el, 0.3, {delay:0.3, height: 0});
	}
}

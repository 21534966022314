import TweenLite from 'gsap/TweenLite';

import '../../../../../vendors/gsap/Physics2DPlugin';

import randomBetween from '../../../shared/utils/number/randomBetween';
import randomString from '../../../shared/utils/string/randomString';

const SPREAD = 50;
const GRAVITY = 1200;

export default class CanvasConfetti {

	constructor(el, colors) {
		this.renderTick = this.renderTick.bind(this);
		this.el = el;
		this.colors = colors;

		this.dpr = window.devicePixelRatio || 1;
		this.context = this.el.getContext('2d');

		this.particles = {};

		TweenLite.ticker.addEventListener('tick', this.renderTick);
	}

	destroy() {
		TweenLite.ticker.removeEventListener('tick', this.renderTick);

		Object.keys(this.particles).forEach(id => {
			TweenLite.killTweensOf(this.particles[id]);
		});

		this.context = null;
		this.el = null;
		this.particles = {};
	}

	releaseConfetti() {
		const id = randomString();
		const width = parseInt(this.el.width);

		this.particles[id] = {
			angle: 0,
			velocity: randomBetween(5, 10),
			x: randomBetween(0, width),
			y: -40,
			r: randomBetween(10, 15) * this.dpr,
			d: randomBetween(15, 25) * this.dpr,
			color: this.getRandomColor(),
			tilt: randomBetween(10, - 10),
			tiltAngleIncremental: randomBetween(0.07, 0.05),
			tiltAngle: 20,
		};

		this.tweenConfettiParticle(id);
	}

	tweenConfettiParticle(id) {
		const data = this.particles[id];
		const minAngle = data.angle - SPREAD / 2;
		const maxAngle = data.angle + SPREAD / 2;
		const d = 0;

		return TweenLite.to(data, 3.5, {
			physics2D: {
				velocity: randomBetween(data.velocity * 0.25, data.velocity),
				angle: randomBetween(minAngle, maxAngle),
				gravity: GRAVITY,
				friction: randomBetween(0.1, 0.25),
			},
			d,
			ease: Power4.easeIn,
			onComplete: () => {
				delete this.particles[id];
			},
		});
	}

	updateConfetti() {
		Object.keys(this.particles).map(id => {
			const particle = this.particles[id];

			// Draw on context
			this.context.beginPath();
			this.context.lineWidth = particle.d / 2;
			this.context.strokeStyle = particle.color;
			this.context.moveTo(particle.x + particle.tilt + particle.r, particle.y);
			this.context.lineTo(particle.x + particle.tilt, particle.y + particle.tilt + particle.r);
			this.context.stroke();

			// Update the data for this particle
			const tiltAngle = 0.0005 * particle.d;
			particle.angle += 0.01;
			particle.tiltAngle += tiltAngle;
			particle.tiltAngle += particle.tiltAngleIncremental;
			particle.tilt = (Math.sin(particle.tiltAngle - (particle.r / 2))) * particle.r * 2;
			particle.y += Math.sin(particle.angle + particle.r / 2) * 2;
			particle.x += Math.cos(particle.angle) / 2;
		});
	}

	getRandomColor() {
		const randomIndex = Math.floor(Math.random() * this.colors.length);

		return this.colors[randomIndex];
	}

	renderTick() {
		this.context.clearRect(0, 0, this.el.width, this.el.height);

		this.updateConfetti();
	}
}

import View from 'Core/View.js';

const CLASS_OPEN = 'reframed__stories__tile--open';
const CLASS_CLOSING = 'reframed__stories__tile--closing';

let openTile = null;

export default class ReframedTile extends View {

	initialize() {
		this.handleClick = this.handleClick.bind(this);

		this.closingTimeout = 0;
		this.isOpen = false;

		this.el.addEventListener('click', this.handleClick);
	}

	destroy() {
		this.el.removeEventListener('click', this.handleClick);
	}

	open() {
		clearTimeout(this.closingTimeout);
		if (openTile) {
			openTile.close();
		}

		this.el.classList.add(CLASS_OPEN);
		this.isOpen = true;
		openTile = this;
	}

	close() {
		this.isOpen = false;
		openTile = null;

		this.el.classList.remove(CLASS_OPEN);
		this.el.classList.add(CLASS_CLOSING);

		this.closingTimeout = setTimeout(() => {
			this.el.classList.remove(CLASS_CLOSING);
		}, 1000);
	}

	handleClick(event) {

		// Don't catch event when clicking the anchor
		if (event.target.tagName.toLowerCase() === 'a' || event.target.parentElement.tagName.toLowerCase() === 'a') {
			return;
		}

		event.preventDefault();

		if (! this.isOpen) {
			this.open();
		} else {
			this.close();
		}
	}
}

import View from 'Core/View';

import findAncestorByClass from '../../../../shared/utils/dom/findAncestorByClass';

import './subscription-payment.scss';

export default class SubscriptionPayment extends View {

    initialize(options) {
		this.handleAllergensConfirmed = this.handleAllergensConfirmed.bind(this);

		this.allergensWarning = options.modalDictionary['allergens-warning'];
		this.allergensConfirmButton = this.allergensWarning.find('.allergens-warning__confirm');
		this.allergensConfirmButton.addEventListener('click', this.handleAllergensConfirmed);

		this.continuousNote = this.find('.subscription-payment__continuous-note');
    }

    destroy() {
		this.allergensConfirmButton.removeEventListener('click', this.handleAllergensConfirmed);
    }

	handleAllergensConfirmed(event) {
    	event.preventDefault();

		const formEl = findAncestorByClass(this.el, 'form');
		const form = this.findViewForElement(formEl);
		form.submit();

		this.allergensWarning.hide();
	}

	update(state) {
		this.continuousNote.style.display = state.plan_type === 'continuously' ? 'block' : 'none';
	}

	visible(state) {
    	const paymentMethodComplete = () => {
    		if (!state.payment_method_id) {
    			return false;
			}

    		if (state.payment_method_id === 'ideal') {
    			return !!state.payment_method_ideal_sub;
			}

    		return true;
		}

		return !! state.shipping_address_id && state.confirmed_address === 'yes' && paymentMethodComplete();
	}
}

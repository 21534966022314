import template from 'lodash.template';
import View from '../../../shared/Core/View';
import createEvent from 'utils/event/create';
import CurrencyFormatter from 'utils/currency/CurrencyFormatter';
import ScrollService from '../../../services/ScrollService';
import CartModel from '../../../shared/data/CartModel';
import ErrorDisplay from '../../general/ErrorDisplay/ErrorDisplay';

import './gift-card-detail.scss';

const CURRENT_VALUE_CLASSNAME = 'gift-card-detail__value--current';
const TOGGLED_CUSTOM_VALUE_CLASSNAME = 'gift-card-detail__custom--toggled';

export default class GiftCardDetail extends View {

	initialize(options) {
		this.handleValueClick = this.handleValueClick.bind(this);
		this.toggleCustomValueClick = this.toggleCustomValueClick.bind(this);
		this.handleCustomValueBlur = this.handleCustomValueBlur.bind(this);
		this.handleCustomValueInput = this.handleCustomValueInput.bind(this);
		this.handleRecipientCountChange = this.handleRecipientCountChange.bind(this);
		this.handleBeforeErrorsDisplayed = this.handleBeforeErrorsDisplayed.bind(this);
		this.handleCartUpdated = this.handleCartUpdated.bind(this);
		this.handleBeforeSubmit = this.handleBeforeSubmit.bind(this);
		this.handleUpdateDisplayPrice = this.handleUpdateDisplayPrice.bind(this);

		this.updateFormValues = this.updateFormValues.bind(this);
		this.pageloadService = options.pageloadService;

		this.detailContent = this.find('.gift-card-detail__content', document);
		this.amountInput = this.find('.gift-card-detail__amount', document);
		this.values = this.findAll('.gift-card-detail__value:not(.gift-card-detail__value__custom)');
		this.custom = this.find('.gift-card-detail__custom');
		this.customValueInput = this.find('.gift-card-detail__value__custom input');
		this.customToggle = this.find('.gift-card-detail__custom__toggle');
		this.hashInput = this.find('.gift-card-detail__hash', document);
		this.giftCardUrl = this.el.dataset.giftCardUrl;

		this.recipientSummary = this.find('.gift-card-detail__recipient-summary');
		this.recipientSummaryTemplate = template(this.recipientSummary.dataset.template);

		this.priceEl = this.find('.product-detail-price__price');

		this.errors = new ErrorDisplay({...options, el: this.detailContent});

		// Listen blur/change from these fields, update fields in .product-detail__form
		this.senderNameInput = this.find('#sender_name');
		this.senderEmailInput = this.find('#sender_email');
		this.recipientListInput = this.find('#recipient_list');
		this.senderNameInput.addEventListener('blur', this.updateFormValues);
		this.senderEmailInput.addEventListener('blur', this.updateFormValues);
		this.recipientListInput.addEventListener('change', this.updateFormValues);

		this.currentValue = this.find('.gift-card-detail__value--current');
		this.values.forEach(el =>
			el.addEventListener('click', this.handleValueClick)
		);

		this.customToggle.addEventListener('click', this.toggleCustomValueClick);
		this.customValueInput.addEventListener('input', this.handleCustomValueInput);
		this.customValueInput.addEventListener('blur', this.handleCustomValueBlur);

		this.signals.forms.beforeSubmit.add(this.handleBeforeSubmit);
		this.signals.forms.beforeErrorsDisplay.add(this.handleBeforeErrorsDisplayed);
		CartModel.requestSuccessSignal.add(this.handleCartUpdated);

		this.recipientList = this.findViewForElement(this.find('.gift-card-detail__recipient-list'));
		this.recipientList.recipientCountChanged.add(this.handleRecipientCountChange);

		this.handleRecipientCountChange(this.recipientList.recipients.length);

		this.updateFormValues();
	}

	/**
	 *
	 */
	destroy() {
		this.recipientList.recipientCountChanged.remove(this.handleRecipientCountChange);
		this.recipientList = null;

		this.errors.destroy();

		this.signals.forms.beforeErrorsDisplay.remove(this.handleBeforeErrorsDisplayed);
		this.signals.forms.beforeSubmit.remove(this.handleBeforeSubmit);
		CartModel.requestSuccessSignal.remove(this.handleCartUpdated);

		this.values.forEach(el => el.removeEventListener('click', this.handleValueClick));

		this.senderNameInput.removeEventListener('blur', this.updateFormValues);
		this.senderEmailInput.removeEventListener('blur', this.updateFormValues);
		this.recipientListInput.removeEventListener('change', this.updateFormValues);
		this.customToggle.removeEventListener('click', this.toggleCustomValueClick);
		this.customValueInput.removeEventListener('input', this.handleCustomValueInput);
		this.customValueInput.removeEventListener('blur', this.handleCustomValueBlur);
	}

	updateFormValues() {
		const senderName = this.find('.gift-card-detail__sender-name', document);
		const senderEmail = this.find('.gift-card-detail__sender-email', document);
		const recipients = this.find('.gift-card-detail__recipients', document);

		senderName.value = this.senderNameInput.value;
		senderName.dispatchEvent(createEvent('change'));
		senderEmail.value = this.senderEmailInput.value;
		senderEmail.dispatchEvent(createEvent('change'));

		recipients.value = document.querySelectorAll('.recipient-list__icon--warning').length > 0 ? '' : this.recipientListInput.value;
		recipients.dispatchEvent(createEvent('change'));
	}

	handleCartUpdated(response) {
		const hash = response.body.hash;
		const products = response.body.cart.products;

		if (!hash) {
			// Check if the gift card was removed from the cart
			if (this.hashInput && this.hashInput.value && !products[hash]) {
				this.replaceUrl(this.giftCardUrl);
			}
			return;
		}

		// Verify the hash belongs to the gift card
		const product = products[hash];
		if (!product || !product.giftcard) {
			return;
		}

		this.replaceUrl(this.giftCardUrl + '?hash=' + hash);
	}

	handleBeforeSubmit(formId) {
		if (formId !== 'add-to-basket') {
			return;
		}

		this.errors.clearErrors();
	}

	/**
	 *
	 * @param formId
	 * @param errors
	 */
	handleBeforeErrorsDisplayed(formId, errors) {
		if (formId !== 'add-to-basket') {
			return;
		}

		const recipientErrors = [];

		this.errors.showErrors(errors);

		for (const key in errors) {
			if (!errors.hasOwnProperty(key)) {
				continue;
			}

			if (key.indexOf('recipients[') !== 0) {
				continue;
			}

			const regex = /^recipients\[([0-9]+)]\[([^\]]+)]$/;
			const match = regex.exec(key);

			const index = parseInt(match['1']);
			const fieldName = match['2'];

			if (!recipientErrors[index]) {
				recipientErrors[index] = {};
			}

			recipientErrors[index][fieldName] = errors[key][0];

			delete errors[key];
		}

		this.recipientList.applyErrors(recipientErrors);
	}

	handleValueClick(event) {
		event.preventDefault();

		this.custom.classList.remove(TOGGLED_CUSTOM_VALUE_CLASSNAME);
		if (this.currentValue) {
			this.currentValue.classList.remove(CURRENT_VALUE_CLASSNAME);
		}

		if (event.currentTarget === this.currentValue) {
			this.currentValue = null;
			this.amountInput.value = '';
			this.amountInput.dispatchEvent(createEvent('change'));
			return;
		}

		this.currentValue = event.currentTarget;
		this.currentValue.classList.add(CURRENT_VALUE_CLASSNAME);

		this.amountInput.value = event.currentTarget.dataset.amount;
		this.amountInput.dispatchEvent(createEvent('change'));

		this.handleUpdateDisplayPrice();
	}

	toggleCustomValueClick(event) {
		event.preventDefault();

		if (this.custom.classList.contains(TOGGLED_CUSTOM_VALUE_CLASSNAME)) {
			this.custom.classList.remove(TOGGLED_CUSTOM_VALUE_CLASSNAME);
		} else {
			this.custom.classList.add(TOGGLED_CUSTOM_VALUE_CLASSNAME);
			this.customValueInput.focus();
			ScrollService.scrollPageToElement(this.customValueInput, window.innerHeight / 3);
			this.resetValues();
		}
		
		this.handleUpdateDisplayPrice();
	}

	handleCustomValueInput(event) {
		event.target.value = this.cleanValue(event.target.value);

		event.stopPropagation();
	}

	/**
	 *
	 * @param value
	 * @return {string}
	 */
	cleanValue(value) {
		value = value.replace(/[^0-9.,]/g, '');
		const removeChar = value.lastIndexOf('.') > value.lastIndexOf(',') ? ',' : '.';
		const keepChar = value.lastIndexOf('.') > value.lastIndexOf(',') ? '.' : ',';
		value = value.replace(removeChar, '');
		const segments = value.split(keepChar);

		if (segments.length > 1) {
			const decimals = segments[segments.length - 1];
			segments[segments.length - 1] = decimals.substring(0, 2);
		}

		let result = '';
		segments.forEach((segment, index) => {
			result += index === segments.length - 1 && segments.length - 1 ? keepChar + segment : segment;
		});

		return result;
	}

	convertToNumber(value) {
		value = this.cleanValue(value);
		value = value.replace(',', '.');

		return parseFloat(value);
	}

	replaceUrl(newUrl) {
		const newRoute = this.routes.getRoute(newUrl);
		this.signals.pageLoad.requested.dispatch(newRoute);
	}

	handleCustomValueBlur(event) {
		event.stopPropagation();

		const value = this.cleanValue(event.target.value);
		let amount = this.convertToNumber(value);
		amount = isNaN(amount) ? 1 : amount;

		if (amount < 1) {
			amount = 1;
		}
		amount = Math.min(100, Math.max(amount, 1))

		event.target.value = CurrencyFormatter.format(amount, true, CurrencyFormatter.settings.code === 'EUR');

		this.amountInput.value = CurrencyFormatter.format(amount, true);
		this.amountInput.dispatchEvent(createEvent('change'));

		this.handleUpdateDisplayPrice();
	}

	resetValues() {
		if (this.currentValue) {
			this.currentValue.classList.remove(CURRENT_VALUE_CLASSNAME);
		}
	}

	handleRecipientCountChange(count) {
		this.recipientSummary.innerHTML = this.recipientSummaryTemplate({
			count: count,
		});

		this.handleUpdateDisplayPrice();
	}

	handleUpdateDisplayPrice() {
		if (!this.priceEl) return;
		this.priceEl.innerHTML = '€' + this.amountInput.value * this.recipientList.recipients.length;
	}
}

import Signal from 'signals';

export default {

	/**
	 * @param {string} tabId
	 */
	tabOpenRequested: new Signal(),

	/**
	 * @param {string} tabId
	 */
	tabOpened: new Signal(),

	/**
	 * @param {string} tabId
	 */
	mouseEnteredTab: new Signal(),

	/**
	 * @param {string} tabId
	 */
	mouseLeftTab: new Signal(),

	/**
	 * @param {string} tabId
	 */
	tabCloseRequested: new Signal(),

	/**
	 * @param {string} tabId
	 */
	tabClosed: new Signal()
};
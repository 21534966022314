import Spirit from 'spiritjs';
import TweenLite from 'gsap/TweenLite';
import TimelineMax from 'gsap/TimelineMax';

/**
 * GSAP tween instance.
 *
 * @type {TweenMax|TweenLite}
 */
Spirit.config.gsap.tween = TweenLite;

/**
 * GSAP timeline instance.
 *
 * @type {TimelineMax|TimelineLite}
 */
Spirit.config.gsap.timeline = TimelineMax;

/**
 * Use auto inject?
 *
 * @type {Boolean}
 * @default true
 */
Spirit.config.gsap.autoInject = false;

/**
 * Setup Spirit.
 * Make sure GSAP is available
 *
 * @returns {Promise}
 */
spirit.setup();

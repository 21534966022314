import View from 'Core/View.js';
import CartModel from 'data/CartModel';
import CurrencyFormatter from 'utils/currency/CurrencyFormatter';

export default class CheckoutPaymentMethods extends View {
	initialize() {

		this.submitted = this.submitted.bind(this);
		this.updateTotalPrice = this.updateTotalPrice.bind(this);

		this.totalPrice = this.find('.checkout__payment-method__total-price');

		this.signals.forms.submitted.add(this.submitted);

		if (this.totalPrice) {
			CartModel.updatedSignal.add(this.updateTotalPrice);
		}
	}

	destroy() {
		this.signals.forms.submitted.remove(this.submitted);

		if (this.totalPrice) {
			CartModel.updatedSignal.remove(this.updateTotalPrice);
		}
	}

	submitted(formId, data) {
		if (formId === 'checkout') {
			this.signals.ecommerce.checkoutSubmitPaymentMethod.dispatch();
		}
	}

	updateTotalPrice() {
		const cartMeta = CartModel.getMeta();
		const sum = CurrencyFormatter.format(cartMeta.grantTotal);

		if (this.totalPrice) {
			this.totalPrice.innerHTML = this.totalPrice.dataset.trans.replace(':sum', sum);
		}

		if (cartMeta.displayVat) {
			this.totalPrice.classList.remove('checkout__payment-method__total-price--hidden');

		} else {
			this.totalPrice.classList.remove('checkout__payment-method__total-price--hidden');
		}
	}
}

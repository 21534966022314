import View from 'Core/View.js';
import ImageCarouselImage from './ImageCarouselImage';
import './image-carousel.scss';

export default class ImageCarousel extends View {

	initialize() {
		this.index = 0;

		this.wrapperElement = this.find('.image-carousel__wrapper');
		this.imageContainerElement = this.find('.image-carousel__image-container');
		this.arrowLeftElement = this.find('.image-carousel__arrow--left');
		this.arrowRightElement = this.find('.image-carousel__arrow--right');
		this.imagesDataElements = this.findAll('.image-carousel__data img');
		this.titleElement = this.find('.image-carousel__title');

		this.carouselImages = [];
		var imageIndex = 0;
		this.imagesDataElements.forEach((image) => {
			const carouselImage = new ImageCarouselImage({el: image, index: imageIndex});
			this.carouselImages.push(carouselImage);
			imageIndex ++;
		});

		this.onImageLoaded = this.onImageLoaded.bind(this);
		this.onPreviousClicked = this.onPreviousClicked.bind(this);
		this.onNextClicked = this.onNextClicked.bind(this);
		this.onResize = this.onResize.bind(this);

		this.autoPlayTimeout = 0;

		this.onResize();
		this.load();
		this.update();

		if (this.el.dataset.autoplay) {
			this.startAutoPlay();
		}

		this.arrowLeftElement.addEventListener('click', this.onPreviousClicked);
		this.arrowRightElement.addEventListener('click', this.onNextClicked);
		this.wrapperElement.addEventListener('click', this.onNextClicked);
		this.signals.windowResized.add(this.onResize);
	}

	startAutoPlay() {
		this.autoPlayTimeout = setTimeout(() => {
			this.next();
			this.startAutoPlay();
		}, 4000);
	}

	stopAutoPlay() {
		if (this.autoPlayTimeout) {
			clearTimeout(this.autoPlayTimeout);
		}
	}

	destroy() {
		this.arrowLeftElement.removeEventListener('click', this.onPreviousClicked);
		this.arrowRightElement.removeEventListener('click', this.onNextClicked);
		this.wrapperElement.removeEventListener('click', this.onNextClicked);
		this.signals.windowResized.remove(this.onResize);
	}

	load() {
		this.carouselImages.forEach((carouselImage) => {
			carouselImage.loadedSignal.addOnce(this.onImageLoaded);
			carouselImage.load();
		});
	}

	next() {
		this.index ++;
		this.update(1);
	}

	previous() {
		this.index --;
		this.update(- 1);
	}

	update(direction = 0) {

		if (this.index > this.carouselImages.length - 1) {
			this.index = this.index % this.carouselImages.length;
		}
		if (this.index < 0) {
			this.index += this.carouselImages.length;
		}

		const carouselImage = this.carouselImages[this.index];
		const imageElement = carouselImage.getImageElement();

		const ow = carouselImage.getOriginalWidth();
		const oh = carouselImage.getOriginalHeight();
		const scale = Math.min(this.availableWidth / ow, this.availableHeight / oh);

		const nw = Math.floor(scale * ow) + 'px';
		const nh = Math.floor(scale * oh) + 'px';

		this.imageContainerElement.style.width = nw;
		this.imageContainerElement.style.height = nh;

		imageElement.style.width = nw;
		imageElement.style.height = nh;

		this.hideAll(direction);
		carouselImage.show(direction);

		this.imageContainerElement.appendChild(imageElement);
		this.updateTitle(carouselImage);
	}

	onImageLoaded() {
		this.update()
	}

	updateTitle(carouselImage) {
		const title = carouselImage.getTitle();
		this.titleElement.innerText = title;
	}

	hideAll(direction) {
		this.carouselImages.forEach((carouselImage) => {
			carouselImage.hide(direction);
		});
	}

	onResize() {
		this.availableWidth = this.wrapperElement.clientWidth;
		this.availableHeight = this.wrapperElement.clientHeight;
		this.update();
	}

	onPreviousClicked(event) {
		event.preventDefault();

		this.stopAutoPlay();
		this.previous();
	}

	onNextClicked(event) {
		event.preventDefault();

		this.stopAutoPlay();
		this.next();
	}

}
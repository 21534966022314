import View from 'Core/View';

import ArrowButton from '../components/ArrowButton';

import './subscription-gift-types.scss';

export default class SubscriptionGiftTypes extends View {

	initialize(options) {
		this.forceUpdate = options.forceUpdate;
		this.arrowButtons = this.findAll('.subscription-arrow-button').map(el => {
			return new ArrowButton({...options, el: el});
		});
	}

	destroy() {
	}

	update(state, prevState) {
		this.arrowButtons.forEach(button => {
			const el = button.el;

			if (el.dataset.planId === state.plan_id) {
				el.style.display = 'inline-block';
			} else {
				el.style.display = 'none';
			}
		});

		if (state.plan_id !== prevState.plan_id) {
			this.findAll('input').forEach(el => el.checked = false);
			this.forceUpdate();
		}

		if (state.product_id !== prevState.product_id) {
			this.updateArrows(state.product_id);
		}
	}

	visible(state) {
		return !! state.fixed_plan_id;
	}

	updateArrows(productId) {
		this.arrowButtons.forEach(arrowButton => {
			const inputValue = this.find('input', arrowButton.el).value;
			if (inputValue === productId) {
				arrowButton.open();
			} else {
				arrowButton.close();
			}
		});
	}
}

import View from '../../../shared/Core/View';
import ProgressionForm from '../../components/ProgressionForm/ProgressionForm';
import CartModel from '../../../shared/data/CartModel';

import Intro from './parts/SubscriptionIntro';
import PlanDurations from './parts/SubscriptionPlanDurations';
import GiftTypes from './parts/SubscriptionGiftTypes';
import GiftOptions from './parts/SubscriptionGiftOptions';
import GiftConclusion from './parts/SubscriptionGiftConclusion';
import ProgressBar from './components/ProgressBar/ProgressBar';

import './select-subscription.scss';

export default class GiveSubscription extends View {
	initialize(options) {
		this._handleFormUpdated = this._handleFormUpdated.bind(this);
		this.handleFormSubmitted = this.handleFormSubmitted.bind(this);
		this.signals.forms.succeeded.add(this.handleFormSubmitted);

		this.progressBar = new ProgressBar({...options, el: this.find('.subscription-progress-bar')});

		this.form = new ProgressionForm({
			...options,
			el: this.el,
			sections: {
				'.subscription-intro': Intro,
				'.subscription-plan-durations': PlanDurations,
				'.subscription-gift-types': GiftTypes,
				'.subscription-gift-options': GiftOptions,
				'.subscription-gift-conclusion': GiftConclusion,
			},
		});
		this.form.updatedForced.add(this._handleFormUpdated);
		this.form.forceUpdate();
	}

	handleFormSubmitted(formId) {
		if (formId === this.form.form.id) {
			CartModel.load();
		}
	}

	_handleFormUpdated(state) {
		this.progressBar.setDoneIndex(this._getProgressIndexFromState(state));
	}

	_getProgressIndexFromState(state) {
		if (state.skipped_intro !== '1' ) {
			return -1;
		}

		if (!state.plan_id || !state.plan_type) {
			return 0;
		}

		if (state.completed_options !== '1') {
			return 1;
		}

		return 2;
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.handleFormSubmitted);

		this.form.destroy();
	}
}

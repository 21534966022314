import BaseSplashView from './BaseSplash';

import {TimelineMax, TweenLite} from 'gsap/all';

export default class Splash_19_8 extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.setupUI();
		this.setupTimeline();

	}

	setupUI() {

		this.ui = {
			foldShapes: [...this.el.querySelectorAll('.js-fold-shapes')],
			pageMasks: [...this.el.querySelectorAll('.js-page-mask')],
			// foldBgs: [...this.el.querySelectorAll('.js-fold-bgs')],
			// shadows: [...this.el.querySelectorAll('.js-shadow-line')],
			// animation: this.el.querySelector('.js-animation'),
		}

	}

	update(options) {

		TweenLite.to(this.tl, options.duraton, {progress: options.progress});

	}

	setupTimeline() {

		this.tl = new TimelineMax({paused: true});

		const time = 0;
		this.ui.pageMasks.forEach((el, index) => this.tl.to(this.ui.pageMasks[0], .2, {morphSVG: el}, time + index * .2));
		this.ui.foldShapes.forEach((el, index) => this.tl.to(this.ui.foldShapes[0], .2, {morphSVG: el}, time + index * .2));

		this.tl.to(this.ui.pageMasks[0], .5, {y: 200}, '-=.5');
		this.tl.to(this.ui.foldShapes[0], .5, {y: 200}, '-=.5');

	}

}

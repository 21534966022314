import request from 'superagent';

export default class PageLoadingService {

	constructor(options) {
		options = options || {};

		this.routes = options.routes;
		this.signalMap = options.signals;

		this.loadStartTime = null;
		this.currentRequest = null;
		this.currentRoute = options.routeAtLoad;
		this.loading = false;

		// Binds
		this.onResponse = this.onResponse.bind(this);
	}

	updateCurrentRoute(route) {
		this.currentRoute = route;
	}

	/**
	 * @param {Object} route
	 */
	load(route) {

		// If the paths are equal, we probably want to navigate within the page
		if (this.isSamePage(route, this.currentRoute)) {
			this.currentRoute = route;
			this.signalMap.pageLoad.requestIgnored.dispatch(route);
			return;
		}

		this.currentRoute = route;

		this.reset();
		this.loading = true;
		this.loadStartTime = (new Date()).getTime();

		this.signalMap.loading.started.dispatch('pageLoad');
		this.signalMap.pageLoad.started.dispatch(route);

		this.currentRequest = request
			.get(route.url)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Accept', window.tonysConfig.acceptHeader || '*/*');
		this.currentRequest.end(this.onResponse);
	}

	onResponse(error, result) {
		if (! result) {
			return;
		}

		this.dispatchURLChangeIfRedirected(result);

		const loadTime = (new Date()).getTime() - this.loadStartTime;
		const content = this.getShadowDOM(result.text);

		if (error) {
			this.signalMap.loading.failed.dispatch('pageLoad');
			this.signalMap.pageLoad.failed.dispatch(content, loadTime, this.currentRoute, error);
			return;
		}

		this.signalMap.loading.completed.dispatch('pageLoad');
		this.signalMap.pageLoad.completed.dispatch(content, loadTime, this.currentRoute);
	}

	reset() {

		if (this.currentRequest !== null) {
			const loadTime = (new Date()).getTime() - this.loadStartTime;

			// Maybe we shouldn't call failed, but rather make a separate loadingCanceled signal?
			this.signalMap.pageLoad.canceled.dispatch(loadTime, this.currentRoute);
			this.currentRequest.xhr.abort();
			this.currentRequest = null;
		}

		this.loading = false;
		this.page = null;
	}

	isSamePage(a, b) {
		return a.path === b.path && a.queryString === b.queryString;
	}

	getShadowDOM(html) {
		const div = document.createElement('div');
		div.innerHTML = html;

		return div;
	}

	dispatchURLChangeIfRedirected(response) {
		if (! response || ! response.xhr || ! response.xhr.responseURL) {
			return;
		}

		const requestedURL = response.req.url.split('#')[0];
		const responseURL = response.xhr.responseURL.split('#')[0];

		if (requestedURL !== responseURL) {
			const newRoute = this.routes.getRoute(responseURL);
			this.signalMap.pageLoad.redirected.dispatch(newRoute);
		}
	}
}

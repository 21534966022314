export default function eventToDates(event, locale = 'en-US') {
	const dateFormat = new Intl.DateTimeFormat(locale, {
		month: 'short',
		day: 'numeric',
	});

	const start = dateFormat.format(event.startDate);
	const end = dateFormat.format(event.endDate);

	if (start === end) {
		return `${start}`;
	}
	
	return `${start} - ${end}`;
};

import BaseSplashView from './BaseSplash';

export default class Splash_20_1 extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.progress = 0;
		this.type = options.type;
		this.animationUrl = options.el.dataset.animationUrl;

	}

}

import View from 'Core/View.js';

export default class ProductDetailCarouselSlide extends View {

	initialize() {
		this.imageElement = this.find('img');
	}

	destroy() {
	}
}

import View from 'Core/View.js';

import './bottom-background.scss';

export default class BottomBackground extends View {

	initialize() {

		this.destroyed = false;

		this.render = this.render.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);

		const selector = this.el.dataset.target;
		const targetElement = document.querySelector(selector);

		this.target = targetElement;

		if(!this.target) {
			console.error('no valid target found for ', this.el);
			return;
		}

		this.render();
		this.show();
	}

	destroy() {
		this.destroyed = true;
	}

	render() {
		if(!this.destroyed) {
			const extraHeight = window.innerWidth < 768 ? 64 : 128;
			this.el.style.height = this.target.offsetHeight + extraHeight + 'px';
			window.requestAnimationFrame(this.render);
		}
	}

	show() {
		// Start in animation
		this.el.classList.remove('bottom-background--shown');
		this.el.classList.add('bottom-background--shown');
	}

	hide() {
		this.el.classList.remove('bottom-background--shown');
	}
}

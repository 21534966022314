import GoogleTagmanagerEventType from '../shared/enums/GoogleTagManagerEventType';
import CartModel from '../shared/data/CartModel';

export default class {

	constructor(options) {
		this.signals = options.signals;
		this.routeAtLoad = options.routeAtLoad;

		this.analyticsGroupDimension = window.tonysConfig.customer ? window.tonysConfig.customer.groupName : 'Not logged in';
		this.currency = window.tonysConfig.currency ? window.tonysConfig.currency.code : 'N/A';
		this.domain = window.tonysConfig.domain ? window.tonysConfig.domain : 'N/A';

		this.addEventTracking();
		this.addEcommerceTracking();
		this.addGtagEventTracking();
		this.addGtagEcommerceTracking();

		this.signals.pageContentReplaced.add((el, route) => this.trackPageView(route), this);
		this.signals.modals.shown.add((modalId) => this.trackEvent('Modals', 'show', modalId), this);

		// Workaround to get the current locale
		this.currentLocale = document.documentElement.getAttribute('lang');
	}

	begin() {
		this.trackPageView(this.routeAtLoad);
	}

	addEventTracking() {
		this.signals.forms.succeeded.add((formId) => {
			this.trackEvent('Form', 'submit', formId);
		});

		this.signals.sectionButtonClicked.add((routeName, direction) => {
			const directionLabel = direction > 0 ? 'right' : 'left';

			this.trackEvent('SectionButton', directionLabel, routeName);
		});

		this.signals.social.shareRequested.add((platform, url = '') => {
			this.trackEvent('SocialShare', platform, url);
		});
	}

	addEcommerceTracking() {
		const ec = this.signals.ecommerce;

		ec.productDetailPageViewed.add((data) => {
			this._ga('ec:addProduct', {
				id: data.id,
				name: data.name,
				category: data.category,
			});

			this._ga('ec:setAction', 'detail');
		});

		ec.cartUpdated.add((products) => {

			/**
			 * MarketingCloud
			 */
			this._mc('trackCart', {
				cart: Object.keys(products).map(function (id) {
					const lineItem = products[id];

					return {
						item: lineItem.name,
						quantity: lineItem.quantity,
						price: parseFloat(lineItem.price),
						unique_id: lineItem.sku,
					};
				}),
			});
		});

		ec.checkoutInitiated.add((data) => {

			/**
			 * Google analytics
			 */
			Object.keys(data.products).forEach(productKey => {
				const product = data.products[productKey];
				const seoCategoryTitle = product.seoCategory ? product.seoCategory.title[this.currentLocale] : null;
				this._ga('ec:addProduct', {
					id: product.sku,
					name: product.name,
					price: product.price,
					quantity: product.quantity,
					category: seoCategoryTitle,
				});
			});

			this._ga('ec:setAction', 'checkout', {step: 1});
		});

		ec.checkoutSelectedAddress.add((data) => {
			Object.keys(data.products).forEach(productKey => {
				const product = data.products[productKey];
				const seoCategoryTitle = product.seoCategory ? product.seoCategory.title[this.currentLocale] : null;
				this._ga('ec:addProduct', {
					id: product.sku,
					name: product.name,
					price: product.price,
					quantity: product.quantity,
					category: seoCategoryTitle,
				});
			});

			this._ga('ec:setAction', 'checkout', {step: 2});
		});

		ec.checkoutSelectedPaymentMethod.add((data) => {

		});

		ec.productImpression.add((type, data) => {
			this._ga('ec:addImpression', {
				id: data.sku,
				name: data.name,
				price: data.price,
				position: data.position,
				category: data.category,
			});

			this.trackEventDebounced({
				eventCategory: 'Impression Tracking',
				eventAction: 'scroll',
			});
		});

		ec.purchaseCompleted.add((transactionId, data) => {
			data.lines.forEach(line => {
				this._ga('ec:addProduct', {
					id: line.sku,
					name: line.name,
					price: line.price,
					quantity: line.quantity,
					category: line.category,
				});
			});

			this._ga('ec:setAction', 'purchase', {
				id: transactionId,
				revenue: data.revenue,
				coupon: data.coupon,
			});

			this._mc('trackConversion', {
				cart: data.lines.map(function (lineItem) {
					return {
						item: lineItem.name,
						quantity: lineItem.quantity,
						price: parseFloat(lineItem.price),
						unique_id: lineItem.sku,
					};
				}),
				order_number: transactionId,
			});
		});
	}

	/**
	 *
	 */
	addGtagEventTracking() {

		//search https://developers.google.com/analytics/devguides/collection/ga4/reference/events#search
		this.signals.search.startSearch.add((url, query) => {
			this._gtag('event', GoogleTagmanagerEventType.SEARCH, {
				search_term: query
			});
		});

		this.signals.forms.succeeded.add((formId) => {

			switch(formId) {
				//login https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
				case 'shop-authenticate-login':
				case 'login':
					this._gtag('event', GoogleTagmanagerEventType.LOGIN, {});
					break;
				default:
					//
			}
		});

		this.signals.social.shareRequested.add((type, url, route) => {

			// Track
			if(route.name === 'news.detail') {
				//share https://developers.google.com/analytics/devguides/collection/ga4/reference/events#share
				this._gtag('event', GoogleTagmanagerEventType.SHARE, {
					method: type,
					item_id: route.variables.path
				});
			}
		});
	}

	/**
	 *
	 */
	addGtagEcommerceTracking() {
		const ec = this.signals.ecommerce

		// view_list
		ec.productListPageViewed.add((listId, listName, items) => {
			this._gtag('event', GoogleTagmanagerEventType.VIEW_ITEM_LIST,
				{
					item_list_id: listId,
					item_list_name: listName,
					items: items.map((item) => {
						try {
							const price = parseFloat(item.price);
							const priceOriginal = parseFloat(item.priceOriginal);
							return {
								currency: this.currency,
								item_id: item.id,
								item_name: item.name,
								item_list_id: listId,
								item_list_name: listName,
								item_category: item.category,
								index: item.position,
								discount: priceOriginal - price,
								price: priceOriginal
							}
						} catch(e) {
							return null;
						}
					}).filter((item) => item !== null)
				});
		});

		// select_item
		ec.productSelected.add((listId, listName, items) => {
			this._gtag('event', GoogleTagmanagerEventType.SELECT_ITEM,
				{
					item_list_id: listId,
					item_list_name: listName,
					items: items.map((item) => {
						try {
							const price = parseFloat(item.price);
							const priceOriginal = parseFloat(item.priceOriginal);
							return {
								currency: this.currency,
								item_id: item.sku,
								item_name: item.name,
								item_list_id: listId,
								item_list_name: listName,
								item_category: item.category,
								index: item.position,
								discount: priceOriginal - price,
								price: priceOriginal
							}
						} catch(e) {
							return null;
						}
					}).filter((item) => item !== null)
				});
		});

		// view_item
		ec.productDetailPageViewed.add((data) => {

			const price = parseFloat(data.price);
			const priceOriginal = parseFloat(data.priceOriginal);

			this._gtag('event', GoogleTagmanagerEventType.VIEW_ITEM,
				{
					value: price,
					items:[{
						currency: this.currency,
						item_id: data.sku,
						item_name: data.name,
						item_category: data.category,
						discount: priceOriginal - price,
						price: priceOriginal
					}]
			});
		});

		// add_to_cart
		ec.productAddedToCart.add((addedItems) => {
			let value =  0;
			let items = [];

			items = addedItems.map((item) => {
				const price = parseFloat(item.item.price);
				const priceOriginal = parseFloat(item.item.priceOriginal);
				value += price * item.quantity;

				return {
					currency: this.currency,
					item_id: item.item.sku,
					item_name: item.item.name,
					discount: priceOriginal - price,
					price: priceOriginal,
					quantity: item.quantity
				}
			});

			this._gtag('event', GoogleTagmanagerEventType.ADD_TO_CART,
				{
					value: value,
					items:items
				});
		});

		// remove_from_cart
		ec.productRemovedFromCart.add((removedItems) => {
			let value =  0;
			let items = [];

			items = removedItems.map((item) => {
				const price = parseFloat(item.item.price);
				const priceOriginal = parseFloat(item.item.priceOriginal);
				value += price * item.quantity;

				return {
					currency: this.currency,
					item_id: item.item.sku,
					item_name: item.item.name,
					discount: priceOriginal - price,
					price: priceOriginal,
					quantity: item.quantity
				}
			});

			this._gtag('event', GoogleTagmanagerEventType.REMOVE_FROM_CART,
				{
					value: value,
					items:items
				});
		});

		// view_cart
		ec.cartViewed.add((cartItems) => {
			let value = 0;
			let items = [];

			items = cartItems.map((item) => {
				const price = parseFloat(item.price);
				const priceOriginal = parseFloat(item.priceOriginal);
				value += price * item.quantity;

				return {
					currency: this.currency,
					item_id: item.sku,
					item_name: item.name,
					discount: priceOriginal - price,
					price: priceOriginal,
					quantity: item.quantity
				}
			});

			this._gtag('event', GoogleTagmanagerEventType.VIEW_CART,
				{
					value: value,
					items:items
				});
		})

		// begin_checkout
		ec.checkoutShown.add((data) => {
			let value = 0;
			let items = [];
			let coupon = data.meta ? data.meta.discountCode : null;

			const productKeys = Object.keys(data.products);
			items = productKeys.map((key) => {
				const item = data.products[key];
				const price = parseFloat(item.price);
				const priceOriginal = parseFloat(item.priceOriginal);
				value += price * item.quantity;

				return {
					currency: this.currency,
					item_id: item.sku,
					item_name: item.name,
					discount: priceOriginal - price,
					price: priceOriginal,
					quantity: item.quantity,
					coupon: coupon
				}
			});

			this._gtag('event', GoogleTagmanagerEventType.BEGIN_CHECKOUT,
				{
					value: value,
					items:items,
					coupon: coupon
				});
		});

		// add_shipping_info
		ec.checkoutSubmitShippingInfo.add(() => {
			const products = CartModel.getProducts();
			const meta = CartModel.getMeta();

			let value = 0;
			let coupon = meta ? meta.discountCode : null
			let items = [];

			items = products.map((item) => {
				const price = parseFloat(item.price);
				const priceOriginal = parseFloat(item.priceOriginal);
				value += price * item.quantity;

				return {
					currency: this.currency,
					item_id: item.sku,
					item_name: item.name,
					discount: priceOriginal - price,
					price: priceOriginal,
					quantity: item.quantity,
					coupon: coupon
				}
			});

			this._gtag('event', GoogleTagmanagerEventType.ADD_SHIPPING_INFO,
				{
					value: value,
					items:items,
					coupon: coupon
				});
		});

		// add_payment_info
		ec.checkoutSubmitPaymentMethod.add(() => {
			const products = CartModel.getProducts();
			const meta = CartModel.getMeta();

			let value = 0;
			let coupon = meta ? meta.discountCode : null
			let items = [];

			items = products.map((item) => {
				const price = parseFloat(item.price);
				const priceOriginal = parseFloat(item.priceOriginal);
				value += price * item.quantity;

				return {
					currency: this.currency,
					item_id: item.sku,
					item_name: item.name,
					discount: priceOriginal - price,
					price: priceOriginal,
					quantity: item.quantity,
					coupon: coupon
				}
			});

			this._gtag('event', GoogleTagmanagerEventType.ADD_PAYMENT_INFO,
				{
					value: value,
					items:items,
					coupon: coupon
				});
		});

		// purchase and earn_virtual_currency
		ec.purchaseCompleted.add((transactionId, data) => {
			let value = 0;
			let items = [];

			items = data.lines.map((item) => {
				const price = parseFloat(item.price);
				value += price * item.quantity;

				return {
					currency: this.currency,
					item_id: item.sku,
					item_name: item.name,
					item_category: item.category,
					price: price,
					quantity: item.quantity,
					coupon: data.coupon
				}
			});

			this._gtag('event', GoogleTagmanagerEventType.PURCHASE,
				{
					value: value,
					transaction_id: transactionId,
					items:items,
					coupon: data.coupon
					// shipping?
					// tax?
				});

			this._gtag('event', GoogleTagmanagerEventType.EARN_VIRTUAL_CURRENCY, {
				virtual_currency_name: 'Impact beans',
				value: data.impact_beans
			})
		});
	}

	trackPageView(route) {
		const path = '/' + route.path;

		this._ga('send', {
			hitType: 'pageview',
			page: path,
		});

		this._gtag('event', 'page_view', {
			page_path: path
		});

		this._mc('trackPageView');
	}

	trackEventDebounced(eventData) {
		if (this.eventDebounceId) {
			clearTimeout(this.eventDebounceId);
		}

		this.eventDebounceId = setTimeout(() => {
			this.trackEvent(eventData.eventCategory, eventData.eventAction, eventData.eventLabel, eventData.eventValue);
		}, 100);
	}

	/**
	 * @param {string} eventCategory - Typically the object that was interacted with (e.g. 'Video')
	 * @param {string} eventAction - The type of interaction (e.g. 'play')
	 * @param {string|null} [eventLabel] - Useful for categorizing events (e.g. 'Fall Campaign')
	 * @param {integer|null} [eventValue] - A numeric value associated with the event (e.g. 42)
	 */
	trackEvent(eventCategory, eventAction, eventLabel = null, eventValue = null) {
		this._ga('send', {
			hitType: 'event',
			eventCategory: eventCategory,
			eventAction: eventAction,
			eventLabel: eventLabel,
			eventValue: eventValue,
		});

		this._gtag('event', eventAction, {
			'event_category': eventCategory,
			'event_label': eventLabel,
			'value': eventValue
		});
	}

	/**
	 * @private
	 */
	_ga() {
		const args = Array.prototype.slice.call(arguments);

		// Try to mix in the dimension property
		if (typeof args[1] === 'object' && this.analyticsGroupDimension) {
			args[1].dimension1 = this.analyticsGroupDimension;
		}

		if (typeof window.ga === 'undefined') {
			return;
		}

		window.ga.apply(this, args);
	}

	/**
	 * @private
	 */
	_gtag() {
		const args = Array.prototype.slice.call(arguments);

		// Try to mix in the dimension property
		if (typeof args[2] === 'object') {
			if (this.currency) {
				args[2].currency = this.currency;
			}

			if (this.analyticsGroupDimension) {
				args[2].customer_group = this.analyticsGroupDimension;
			}

			if (this.domain) {
				args[2].configuration = this.domain;
			}
		}

		window.gtag.apply(this, args);
	}

	/**
	 * Marketing cloud
	 * @private
	 */
	_mc() {
		const args = Array.prototype.slice.call(arguments);

		if (typeof window._etmc === 'undefined') {
			return;
		}

		window._etmc.push(args);
	}
}

import View from '../../../../shared/Core/View';

export default class HeaderTypeAnimation extends View {

	initialize() {
	}

	postBuild() {
		this.animation = this.findViewForElement(this.find('.lottie-animation'));
	}

	onAppeared() {
		if (this.animation) {
			this.animation.play();
		}
	}

	onAfterDisappear() {
		if (this.animation) {
			this.animation.reset();
		}
	}

	destroy() {
	}
}

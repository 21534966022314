import Signal from 'signals';

/**
 * A toaster is a small notification that hides itself after a few seconds
 */
export default {
	startSearch: new Signal(),
	showingResults: new Signal(),
	hidingResults: new Signal(),
};

import View from 'Core/View.js';

export default class ShareLocationModal extends View {

	initialize(options) {
		this.handleCopyClicked = this.handleCopyClicked.bind(this);

		this.modal = options.modalDictionary['store-locator-share-location'];
		this.input = this.modal.find('.store-locator__share-modal__url');
		this.button = this.modal.find('.store-locator__share-modal__copy-button');

		this.button.addEventListener('click', this.handleCopyClicked);
	}

	destroy() {
		this.button.removeEventListener('click', this.handleCopyClicked);
	}

	share(url) {
		this.input.value = url;
		this.modal.show();
	}

	handleCopyClicked(event) {
		event.preventDefault();

		this.input.select();
		document.execCommand('Copy');

		this.modal.hide();

		// Show a toaster
		this.signals.toaster.requested.dispatch(this.el.dataset.translationCopied, 4);
	}
}
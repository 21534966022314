import ViewMapping from './ViewMapping.js';

class ViewMap {

	/**
	 * @param {Context} context
	 */
	constructor(context) {
		this.context = context;
		this.mappings = [];
	}

	/**
	 * @param {String|Window} selector
	 * @param {View} ViewClass
	 * @param {Object} [options]
	 *
	 * @return {ViewMapping}
	 */
	map(selector, ViewClass, options) {
		if (!ViewClass) {
			return;
		}

		const mapping = new ViewMapping(this.context, selector, ViewClass, options);

		this.mappings.push(mapping);

		return mapping;
	}

	/**
	 * @param {Node} el
	 * @param {String|null} selector
	 */
	getViewForEl(el, selector = null) {
		for (let i = 0; i < this.mappings.length; i++) {
			const mapping = this.mappings[i];
			if (selector && ! mapping.isSelector(selector)) {
				continue;
			}

			const view = mapping.getViewForEl(el);
			if (view) {
				return view;
			}
		}

		return null;
	}

	/**
	 * Check if the ViewMap has any mapping for the given selector
	 * @param {String} selector
	 * @returns {boolean}
	 */
	hasMappingForSelector(selector) {
		return !!this.mappings.find(mapping => mapping.isSelector(selector));
	}

	/**
	 * Build all registered mappings
	 *
	 * @param {Element|HTMLDocument} [contextNode]
	 */
	build(contextNode = null) {
		this.mappings.forEach(mapping => mapping.build(contextNode));

		this.mappings.forEach(mapping => mapping.postBuild());
	}

	/**
	 * Destroy views that a no longer attached to the dom and create new ones
	 *
	 * @param {Element|HTMLDocument} [contextNode]
	 * @return {ViewMap}
	 */
	refresh(contextNode = null) {
		this.mappings.forEach(mapping => mapping.refresh(contextNode));
	}

	/**
	 * Destroy all views created by it's mappings. It does not however,
	 * remove the mappings themselves.
	 */
	destroy() {
		this.mappings.forEach(mapping => mapping.destroy());
	}

	/**
	 * @returns {Array|ViewMapping[]}
	 */
	getMappings() {
		return this.mappings;
	}

	/**
	 * @returns {String}
	 */
	toString() {
		return '[object ViewMap]';
	}
}

export default ViewMap;

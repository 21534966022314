import View from 'Core/View.js';

import duplicate from './requests/duplicate';

import './edit-or-duplicate-unlimited.scss';

export default class EditOrDuplicateUnlimited extends View {

	initialize(options) {
		this.modal = options.modalDictionary['edit-or-duplicate-unlimited'];

		this.show = this.show.bind(this);
		this.handleDuplicateClick = this.handleDuplicateClick.bind(this);

		this.editButton = this.find('.edit-or-duplicate-unlimited__edit-button');
		this.duplicateButton = this.find('.edit-or-duplicate-unlimited__duplicate-button');

		this.duplicateButton.addEventListener('click', this.handleDuplicateClick);

		this.signals.products.unlimitedEditRequested.add(this.show);
	}

	destroy() {
		this.signals.products.unlimitedEditRequested.remove(this.show);
		this.duplicateButton.removeEventListener('click', this.handleDuplicateClick);
	}

	show(editUrl, duplicateAndEditUrl) {
		if (this.currentError) {
			this.currentError.parentNode.removeChild(this.currentError);
			this.currentError = null;
		}

		this.editButton.href = editUrl;
		this.duplicateButton.href = duplicateAndEditUrl;

		this.modal.show();
	}

	handleDuplicateClick(event) {
		event.preventDefault();

		this.duplicateButton.classList.add('button--loading');
		if (this.currentError) {
			this.currentError.parentNode.removeChild(this.currentError);
			this.currentError = null;
		}

		duplicate(this.duplicateButton.href)
			.then((response) => {
				const url = response.body.redirect;
				const route = this.routes.getRoute(url);

				this.signals.pageLoad.requested.dispatch(route);

				this.duplicateButton.classList.remove('button--loading');
			})
			.catch(() => {
				const error = document.createElement('div');
				error.innerHTML = 'Error duplicating bar';
				error.classList.add('edit-or-duplicate-unlimited__error');

				this.duplicateButton.classList.remove('button--loading');
				this.duplicateButton.parentNode.insertBefore(error, this.duplicateButton.nextSibling);

				this.currentError = error;
			});
	}
}

import View from 'Core/View.js';

export default class PageTitle extends View {

	initialize() {
		this.handlePageLoaded = this.handlePageLoaded.bind(this);

		this.signals.pageLoad.completed.add(this.handlePageLoaded);
		this.signals.pageLoad.failed.add(this.handlePageLoaded);
	}

	destroy() {
	}

	handlePageLoaded(htmlNode) {
		const titleNode = htmlNode.querySelector('.page-title');
		if (! titleNode) {
			return;
		}

		document.title = titleNode.innerHTML;
	}
}
import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

export default class Footer extends View {

	initialize(options) {
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);

		this.signals.backgroundAnimation.started.add(this.hide);
		this.signals.backgroundAnimation.completed.add(this.show);
	}

	destroy() {
		this.signals.backgroundAnimation.started.remove(this.hide);
		this.signals.backgroundAnimation.completed.remove(this.show);
	}

	show() {
		TweenLite.to(this.el, 0.6, {opacity: 1, ease: Sine.easeInOut});
	}

	hide() {
		TweenLite.to(this.el, 0.3, {opacity: 0, ease: Sine.easeInOut});
	}
}

import Signal from 'signals';

/**
 * A toaster is a small notification that hides itself after a few seconds
 */
export default {

	/**
	 * Something requested a toaster to be displayed
	 *
	 * @param {string} message
	 * @param {number} [hideTimeout = 3]
	 */
	requested: new Signal(),

	/**
	 * A new toaster was created
	 *
	 * @param {integer} id
	 */
	created: new Signal(),

	/**
	 * An existing toaster was removed
	 *
	 * @param {integer} id
	 */
	removed: new Signal(),
};
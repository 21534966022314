import View from 'Core/View.js';

import './search-trigger.scss';

export default class SearchTrigger extends View {

	initialize() {
		this.handleClick = this.handleClick.bind(this);

		this.el.addEventListener('click', this.handleClick);
	}

	destroy() {
		this.el.removeEventListener('click', this.handleClick);
	}

	handleClick(event) {
		event.preventDefault();

		this.openSearch();
	}

	openSearch() {
		this.signals.pageLock.lockRequested.dispatch('search');
	}
}

import TweenLite from 'gsap/TweenLite';
import cssVars from 'css-vars-ponyfill';

import View from 'Core/View.js';
import TemplateType from 'enums/TemplateType.js';
import AnimatedBackgroundSection from './AnimatedBackgroundSection.js';

import './animated-background.scss';

class AnimatedBackground extends View {
	initialize(options) {
		this.bodyElement = document.body;

		this.onPageWillChange = this.onPageWillChange.bind(this);
		this.onPageLoaded = this.onPageLoaded.bind(this);
		this.onRedirected = this.onRedirected.bind(this);
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);

		// Initialize sections
		const sectionElements = this.findAll('.animated-background__section');
		let index = -Math.floor(sectionElements.length * 0.5);

		this.sections = [];
		this.previousSection = null;
		this.currentSection = null;
		sectionElements.forEach(el => {
			this.sections.push(new AnimatedBackgroundSection(el, index, sectionElements.length, this.screen));
			index++;
		});

		this.signals.pageLoad.redirected.add(this.onRedirected);
		this.signals.pageLoad.started.add(this.onPageWillChange);
		this.signals.pageLoad.completed.add(this.onPageLoaded);
		this.signals.backgroundAnimation.hide.add(this.hide);
		this.signals.backgroundAnimation.show.add(this.show);
		this.screen.resized.add(this.onWindowResized.bind(this));

		// Background is hidden by default
		this.el.style.display = 'block';

		this.waitingOnAnimation = false;

		// Start site with correct background
		this.onPageWillChange(options.routeAtLoad, true, true);

		this.setCustomTemplateIfAvailable(document.body);
	}

	destroy() {
		this.signals.pageLoad.redirected.remove(this.onRedirected);
		this.signals.pageLoad.started.remove(this.onPageWillChange);
		this.signals.pageLoad.completed.remove(this.onPageLoaded);
		this.screen.resized.remove(this.onWindowResized.bind(this));
		this.signals.backgroundAnimation.hide.remove(this.hide);
		this.signals.backgroundAnimation.show.remove(this.show);
	}

	onWindowResized() {
		this.startTransition(null, true, true);
	}

	onPageWillChange(route, instant = false, skipSignals = false) {
		this.startTransition(this.getColorByTemplate(route.template, route), instant, skipSignals);
	}

	onPageLoaded(content) {
		const bodyNode = content.querySelector('#body-classes');
		this.setCustomTemplateIfAvailable(bodyNode);
	}

	onRedirected(route) {
		this.startTransition(this.getColorByTemplate(route.template, route), false, true);
	}

	setCustomTemplateIfAvailable(node) {
		if (!node) {
			return;
		}

		const color = node.dataset.templateColor || null;
		cssVars({ variables: { 'background-color': color } });

		// This is how it is done without the ponyfill `cssVars`
		// document.documentElement.style.setProperty('--background-color', color);

		if (this.currentSection) {
			this.currentSection.applyCustomTemplate(color);
		}
	}

	getColorByTemplate(templateType, route = null) {
		if (route) {
			// Force white background as template seems to switch depending on language
			if (route.name === 'conversation-bars') {
				return AnimatedBackground.TONY_WHITE;
			}
		}

		switch (templateType) {
			case TemplateType.MISSION:
				return AnimatedBackground.TONY_RED;
			case TemplateType.SHOP:
				return AnimatedBackground.TONY_WHITE;
			case TemplateType.COLLABORATE:
				return AnimatedBackground.TONY_BLUE;
		}

		return AnimatedBackground.TONY_BLUE;
	}

	startTransition(color, instant = false, skipSignals = false) {
		/**
		 * Clear the custom template only if we actually do an animated transition
		 */
		if (this.currentSection && !instant) {
			this.currentSection.clearCustomTemplate();
		}

		let closest = null;
		let closestIndex = Number.POSITIVE_INFINITY;
		this.sections.forEach(section => {
			const sectionIndex = Math.abs(section.index);
			if (section.type === color && sectionIndex < closestIndex) {
				closest = section;
				closestIndex = sectionIndex;
			}
		});

		this.previousSection = this.currentSection;
		this.currentSection = closest;

		const duration = instant ? 0 : 1;
		const shiftIndex = closest ? -closest.index : 0;
		const direction = shiftIndex == 0 ? 0 : shiftIndex > 0 ? 1 : -1;

		this.sections.forEach(section => {
			section.startTransition(section.index + shiftIndex, duration);
		});

		const shouldSkipSignals = !(!skipSignals || this.waitingOnAnimation);

		TweenLite.killDelayedCallsTo(this.onTransitionDone);
		TweenLite.delayedCall(duration, this.onTransitionDone, [shouldSkipSignals], this);

		this.waitingOnAnimation = true;

		if (!skipSignals) {
			this.signals.backgroundAnimation.started.dispatch(direction);

			if (direction === 0) {
				this.signals.backgroundAnimation.stays.dispatch(direction);
			}

			this.bodyElement.classList.add('background-animating');
		}
	}

	onTransitionDone(skipSignals) {
		this.waitingOnAnimation = false;

		if (!skipSignals) {
			this.signals.backgroundAnimation.completed.dispatch();
			this.bodyElement.classList.remove('background-animating');
		}
	}

	hide() {
		this.el.classList.remove('animated-background--hidden');
		this.el.classList.add('animated-background--hidden');
	}

	show() {
		this.el.classList.remove('animated-background--hidden');
	}
}

AnimatedBackground.TONY_BLUE = 'blue';
AnimatedBackground.TONY_RED = 'red';
AnimatedBackground.TONY_WHITE = 'white';

export default AnimatedBackground;

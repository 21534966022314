import View from 'Core/View';

import './address-card.scss';

export default class AddressCard extends View {

    initialize() {

    }

    destroy() {
    }
}

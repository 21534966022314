import View from 'Core/View.js';
import Signal from 'signals';

import StupidPageLoadService from '../../../services/StupidPageLoadService.js';

import AddressPickerCreate from './components/AddressPickerCreate/AddressPickerCreate.js';
import './address-picker.scss';

export default class AddressPicker extends View {

	initialize(options) {
		this.options = options;
		this.handlePageLoaded = this.handlePageLoaded.bind(this);
		this.handleAddressCreated = this.handleAddressCreated.bind(this);
		this.handleAddressSelected = this.handleAddressSelected.bind(this);

		this.loader = new StupidPageLoadService({
			onSuccess: this.handlePageLoaded,
		});

		this.loadOverview();

		if (options.hideDefaultAddressNotes) {
			this.el.classList.add('address-picker--hide-default-notes');
		}

		this.signals = {
			...this.signals,
			sizeUpdated: new Signal(),
			picked: new Signal(),
		};
	}

	destroy() {
		this.findAll('.address-item').forEach(address => address.removeEventListener('click', this.handleAddressSelected));

		if (this.createForm) {
			this.createForm.created.remove(this.handleAddressCreated);
			this.createForm.shown.remove(this.sizeUpdated.dispatch);
			this.createForm.hidden.remove(this.sizeUpdated.dispatch);
			this.createForm.destroy();
		}

		this.clear();
	}

	loadOverview() {
		const addressType = this.options.addressType || 'shipping';
		const url = this.routes.getPath('account.addressbook.picker') + '?type=' + addressType;
		this.loader.load(url);
	}

	clear() {
		this.el.innerHTML = '';
	}

	addListeners() {
		this.findAll('.address-item').forEach(address => address.addEventListener('click', this.handleAddressSelected));

		this.createForm = new AddressPickerCreate({
			...this.options,
			el: this.find('.address-picker__create'),
		});
		this.createForm.shown.add(this.signals.sizeUpdated.dispatch);
		this.createForm.hidden.add(this.signals.sizeUpdated.dispatch);
		this.createForm.created.add(this.handleAddressCreated);
	}

	handleAddressCreated(id, fullAddress) {
		this.signals.picked.dispatch(id, fullAddress);
	}

	handleAddressSelected(event) {
		event.preventDefault();

		const id = event.currentTarget.dataset.id;
		if (! id) {
			return;
		}

		this.signals.picked.dispatch(id, event.currentTarget.querySelector('.address-item__address-line').innerHTML);
	}

	handlePageLoaded(html) {
		this.el.innerHTML = this.getContainerContentsFromHtml(html);
		this.addListeners();
		this.signals.sizeUpdated.dispatch();
	}

	getContainerContentsFromHtml(html) {
		const div = document.createElement('div');
		div.innerHTML = html;

		const newContainer = div.querySelector('.page');
		if (newContainer) {
			return newContainer.innerHTML;
		}

		return html;
	}
}

import View from 'Core/View.js';

import createRequest from 'superagent';
import debounce from '../../../shared/utils/generic/debounce';
import createEvent from '../../../shared/utils/event/create';

export default class NLPostCodeCheck extends View {

	initialize(options) {
		this.checkIfWeCanFetch = debounce(this.checkIfWeCanFetch.bind(this), 500);

		this.postalCheckURL = options.url;
		this.prefix = options.prefix;

		this.postCodeInput = this.find('[name="' + this.prefix + 'postcode"]');
		this.houseNumberInput = this.find('[name="' + this.prefix + 'housenumber"]');
		this.suffixInput = this.find('[name="' + this.prefix + 'suffix"]');
		this.addressInput = this.find('[name="' + this.prefix + 'address_1"]');
		this.cityInput = this.find('[name="' + this.prefix + 'city"]');

		if (! this.postCodeInput || ! this.houseNumberInput || ! this.suffixInput) {
			return;
		}

		[this.postCodeInput, this.houseNumberInput, this.suffixInput].forEach(el => {
			el.addEventListener('change', this.checkIfWeCanFetch);
			el.addEventListener('keyup', this.checkIfWeCanFetch);
		});
	}

	destroy() {
		[this.postCodeInput, this.houseNumberInput, this.suffixInput].forEach(el => {
			el.removeEventListener('change', this.checkIfWeCanFetch);
			el.removeEventListener('keyup', this.checkIfWeCanFetch);
		});
	}

	checkIfWeCanFetch() {
		const postal = this.postCodeInput.value.toUpperCase().replace(/[^0-9A-Z]*/g, '');
		if (postal.length < 6) {
			return;
		}

		const houseNumber = this.houseNumberInput.value.trim();
		if (houseNumber.length === 0) {
			return;
		}

		this.fetchAddressDetails(postal, houseNumber, this.suffixInput.value);
	}

	fetchAddressDetails(postal, housenumber, suffix) {
		const request = createRequest('POST', this.postalCheckURL);
		request.set('Accept', 'application/json');
		request.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
		request.set('X-Requested-With', 'XMLHttpRequest');
		request.send({postcode: postal, housenumber, suffix})
		request.then(
			(response) => {
				this.updateAddressFromProp6PP(response.body);
			},
			(error) => {
				const errors = error.response.body;
				errors.map(errorMessage => {
					this.signals.toaster.requested.dispatch(errorMessage, 6);
				});
			}
		);
	}

	updateAddressFromProp6PP(address) {
		if (! address) {
			return;
		}
		this.addressInput.value = address.street;
		this.cityInput.value = address.city;

		this.addressInput.dispatchEvent(createEvent('change'));
		this.cityInput.dispatchEvent(createEvent('change'));
	}
}
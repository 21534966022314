import TweenLite from 'gsap/TweenLite';

export default class Spritesheet {

	constructor(el, columns, rows) {

		this.el = el;
		this.columns = columns;
		this.rows = rows;

		this.total = this.el.dataset.frames ? this.el.dataset.frames : this.columns * this.rows;
		this.frame = 0;
		this.frameRate = 4;

		this.tick = this.tick.bind(this);
	}

	duration() {
		return this.total * (1/this.frameRate);
	}

	play(speed = 1) {
		this.stop();

		this.frame = 0;
		TweenLite.killTweensOf(this);
		TweenLite.to(this, this.total * (1/this.frameRate*speed), {frame:this.total-1, onUpdate: () => {
			this.update();
		}});
	}

	reverse(speed = 2) {
		this.stop();

		this.frame = this.total-1;
		TweenLite.killTweensOf(this);
		TweenLite.to(this, this.total * (1/this.frameRate*speed) / speed, {frame:0, onUpdate: () => {
			this.update();
		}});
	}

	setPercentage(percentage) {
		this.targetFrame = Math.floor((this.total-1) * percentage);
		this.start();
	}

	start() {
		if(!this.intervalId) {
			this.intervalId = setInterval(this.tick, 1000/this.frameRate);
		}
	}

	stop() {
		clearInterval(this.intervalId);
		this.intervalId = null;
	}

	tick() {
		if(this.targetFrame > this.frame) {
			this.frame++;
		}

		if(this.targetFrame < this.frame) {
			this.frame--;
		}

		this.update();
	}

	update() {
		const frame = Math.round(this.frame);

		const row = Math.floor(frame/this.columns);
		const col = Math.floor(frame%this.columns);

		const posX = (100 / (this.columns - 1)) * col;
		const posY = (100 / (this.rows - 1)) * row;

		this.el.style['background-position'] = `${posX}% ${posY}%`;
	}

}
import View from '../../../shared/Core/View';

import './referral-code.scss';

export default class ReferralCode extends View {
	initialize() {
		this.handleCopyClick = this.handleCopyClick.bind(this);

		this.copyButton = this.find('.referral-code__copy');
		this.codeToCopy = this.find('.referral-code__code');

		this.copiedTimeout = 0;

		if (this.copyButton) {
			this.copyButton.addEventListener('click', this.handleCopyClick);
		}
	}

	destroy() {
		if (this.copyButton) {
			this.copyButton.removeEventListener('click', this.handleCopyClick);
		}
	}

	handleCopyClick(event) {
		event.preventDefault();

		navigator.clipboard.writeText(this.codeToCopy.innerHTML).then(
			() => {
				clearTimeout(this.copiedTimeout);

				this.copyButton.classList.add('referral-code__copy--copied');

				this.copiedTimeout = setTimeout(() => {
					this.copyButton.classList.remove(
						'referral-code__copy--copied'
					);
				}, 800);
			},
			err => {
				console.error('[TONYS] Could not copy text to clipboard.', err);
			}
		);
	}
}

import TweenLite from 'gsap/TweenLite';
import Hammer from 'hammerjs';
import createRequest from 'superagent';

import isDescendantOf from 'utils/dom/isDescendantOf';
import DefaultPage from '../DefaultPage/DefaultPage.js';

import './components/Nutrients/nutrients.scss';
import './shop-detail.scss';

export default class ShopDetail extends DefaultPage {

	initialize() {
		this.onProductImageClick = this.onProductImageClick.bind(this);
		this.windowResizeDelegate = this.onResize.bind(this);
		this.handleDuplicateClick = this.handleDuplicateClick.bind(this);

		this.imageElement = this.find('.shop-detail__image');
		this.imagePickerElement = this.find('.shop-detail__image-picker');
		this.detailImageElements = this.findAll('.shop-detail__image-detail');
		this.productImageThumbElements = this.findAll('.shop-detail__image-thumb-wrapper');
		this.customWrapperLink = this.find('.shop-detail__custom-wrapper');
		this.duplicateWrapperButton = this.find('.shop-detail__duplicate-wrapper');

		this.id = this.el.dataset.id;
		this.selectedImageIndex = 0;

		// Bind click listeners to thumbs
		this.productImageThumbElements.forEach((productImageThumb, index) => {
			if (productImageThumb.getAttribute('selected')) {
				this.selectedImageIndex = index;
			}
			productImageThumb.addEventListener('click', this.onProductImageClick);
		});

		// Initialize Hammer on detail image
		if (this.hasImages()) {
			this.hammer = new Hammer(this.imageElement, {direction: Hammer.DIRECTION_ALL});
			this.hammer.on('tap', this.onTap.bind(this));
		}

		this.signals.windowResized.add(this.windowResizeDelegate);
		this.onResize();

		this.signals.ecommerce.productDetailPageViewed.dispatch({
			id: this.el.dataset.sku,
			price: this.el.dataset.price,
			priceOriginal: this.el.dataset.priceOriginal,
			name: this.find('.shop-detail__title').innerText,
			category: this.el.dataset.category || null,
		});

		if (this.duplicateWrapperButton) {
			this.duplicateWrapperButton.addEventListener('click', this.handleDuplicateClick);
		}

		super.initialize();
	}

	destroy() {
		this.signals.windowResized.remove(this.windowResizeDelegate);

		this.productImageThumbElements.forEach((productImageThumb) => {
			productImageThumb.removeEventListener('click', this.onProductImageClick);
		});

		if (this.duplicateWrapperButton) {
			this.duplicateWrapperButton.removeEventListener('click', this.handleDuplicateClick);
		}
	}

	show() {
		super.show();
		this.showDetailImage(this.selectedImageIndex);
	}

	hide() {
		super.hide();
		this.hideDetailImage(this.selectedImageIndex);
	}

	onResize() {
		if (this.hasImages()) {
			const height = this.imagePickerElement.clientHeight;
			this.imageElement.style.height = height + 'px';
		}
	}

	getIndexOf(thumb) {
		for (var i = 0; i < this.productImageThumbElements.length; i++) {
			if (this.productImageThumbElements[i] == thumb) {
				return i;
			}
		}

		return -1;
	}

	onProductImageClick(event) {
		this.update(this.getIndexOf(event.currentTarget));
	}

	onTap(event) {
		if (this.customWrapperLink && isDescendantOf(event.target, this.customWrapperLink)) {
			return;
		}

		let index = this.selectedImageIndex + 1;
		if (index < 0) {
			index += this.detailImageElements.length;
		}
		if (index >= this.detailImageElements.length) {
			index -= this.detailImageElements.length;
		}

		this.update(index);
	}

	update(toIndex) {
		if (toIndex == this.selectedImageIndex) {
			return;
		}

		this.hideDetailImage(this.selectedImageIndex);
		this.showDetailImage(toIndex);
		this.selectedImageIndex = toIndex;

		this.productImageThumbElements.forEach((productImageThumb, index) => {
			if (index === this.selectedImageIndex) {
				productImageThumb.classList.add('selected');
			} else {
				productImageThumb.classList.remove('selected');
			}
		});
	}

	showDetailImage(index) {
		if (this.hasImages()) {
			const detailImage = this.detailImageElements[index];
			if (!detailImage) {
				return;
			}

			TweenLite.set(detailImage, {opacity: 0, y: -50});
			TweenLite.to(detailImage, 0.3, {opacity: 1, y: 0, ease: Cubic.easeInOut});
		}
	}

	hideDetailImage(index) {
		if (this.hasImages()) {
			const detailImage = this.detailImageElements[index];
			if (!detailImage) {
				return;
			}

			TweenLite.to(detailImage, 0.3, {opacity: 0, y: 50, ease: Cubic.easeInOut});
		}
	}

	hasImages() {
		return !!this.imageElement;
	}

	handleDuplicateClick(event) {
		event.preventDefault();
		event.stopPropagation();

		this.duplicateWrapperButton.classList.add('button--loading');

		const request = createRequest('POST', event.target.href);
		request.set('Accept', 'application/json')
			.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content)
			.set('X-Requested-With', 'XMLHttpRequest')
			.send()

			.then((response) => {
				const url = response.body.url;
				const route = this.routes.getRoute(url);

				this.signals.pageLoad.requested.dispatch(route);

				this.duplicateWrapperButton.classList.remove('button--loading');
			})
			.catch(() => {
				const error = document.createElement('p');
				error.innerHTML = 'Error duplicating wrapper';

				this.duplicateWrapperButton.parentNode.appendChild(error);
			});
	}
}

import View from 'Core/View.js';

import Toaster from './Toaster.js';

export default class ToasterContainer extends View {

	initialize() {
		this.currentToasters = {};

		this.createToaster = this.createToaster.bind(this);
		this.addToasterToList = this.addToasterToList.bind(this);
		this.removeToasterFromList = this.removeToasterFromList.bind(this);

		this.signals.toaster.requested.add(this.createToaster);
		this.signals.toaster.created.add(this.addToasterToList);
		this.signals.toaster.removed.add(this.removeToasterFromList);
	}

	destroy() {
		this.signals.toaster.requested.remove(this.createToaster);
		this.signals.toaster.created.remove(this.addToasterToList);
		this.signals.toaster.removed.remove(this.removeToasterFromList);
	}

	createToaster(message, timeout = 3) {
		new Toaster({message, timeout, signals: this.signals, container: this.el});
	}

	addToasterToList(toasterId, toaster) {
		this.currentToasters[toasterId] = toaster;
	}

	removeToasterFromList(toasterId) {
		if (! this.currentToasters[toasterId]) {
			return;
		}

		delete this.currentToasters[toasterId];
	}
}
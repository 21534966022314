import Signal from 'signals';

export default {

	/**
	 * The background animation started due to a navigation to another section
	 */
	started: new Signal(),

	/**
	 * The background stays at the same position but different content is loaded
	 */
	stays: new Signal(),

	/**
	 * The background animation is done
	 */
	completed: new Signal(),

	/**
	 * Hide background animation element
	 */
	hide: new Signal(),

	/**
	 * Hide background animation element
	 */
	show: new Signal()
};

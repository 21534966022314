import './news-grid.scss';

import View from 'Core/View.js';
import Signal from 'signals';
import TimelineMax from 'gsap/TimelineMax';

export default class NewsGrid extends View {

	initialize() {

		this.showStarted = new Signal();
		this.shown = new Signal();
		this.hideStarted = new Signal();
		this.hidden = new Signal();

		this.showTiles();
	}

	destroy() {
	}

	showTiles() {
		this.showStarted.dispatch();

		const tiles = this.findAll('.news-grid__item');
		this.tl = new TimelineMax();
		this.tl.staggerFromTo(tiles, 0.2, {
			opacity: 0,
			y: 100,
			rotation: - 10 + Math.random() * 20
		}, {
			opacity: 1,
			y: 0,
			force3D: true,
			rotation: 0,
			ease: Cubic.easeOut,
			onComplete: this.shown.dispatch,
			onReverseComplete: this.hidden.dispatch,
		}, 0.05);

		this.tl.play();
	}

	hideTiles() {
		this.hideStarted.dispatch();

		if (this.tl) {
			this.tl.reverse();
		}
	}
}
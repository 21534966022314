import flatpickr from 'flatpickr';
import Signal from 'signals';

import View from '../../../shared/Core/View';

import './date-picker-modal.scss';

export default class DatePickerModal extends View {

    initialize(options) {
    	this.modal = options.modalDictionary[this.el.id];

		this.handleModalShown = this.handleModalShown.bind(this);
		this.handleModalHidden = this.handleModalHidden.bind(this);

		this.signals.modals.shown.add(this.handleModalShown);
		this.signals.modals.hidden.add(this.handleModalHidden);

		this.defaultDateAtOpen = null;
		this.onChange = new Signal();
    }

    destroy() {
		this.signals.modals.shown.remove(this.handleModalShown);
		this.signals.modals.hidden.remove(this.handleModalHidden);

		this.onChange.removeAll();
    }

	setDate(date) {
    	if (!this.picker) {
    		this.defaultDateAtOpen = date;
    		return;
		}

    	this.picker.setDate(date);
	}

    handleModalShown(modalId, modal) {
    	if (modalId !== this.el.id) {
    		return;
		}

		const disabledDates = JSON.parse(this.el.dataset.disabledDeliveryDates);
		const disabledDays = JSON.parse(this.el.dataset.disabledDeliveryDays);
		const disabledBefore = this.el.dataset.disabledBefore;
		const disabledAfter = this.el.dataset.disabledAfter;
		const doubleMonths = this.el.dataset.doubleMonths === 'true';

		const pickerEl = modal.find('.date-picker-modal__date-picker');
		this.picker = flatpickr(pickerEl, {
			inline: true,
			showMonths: !this.screen.isOneOf(['mobile']) && doubleMonths ? 2 : 1,
			disable: [
				...disabledDates,
				(date) => disabledDays.indexOf(date.getDay()) >= 0,
			],
			onChange: (dateRange) => {
				this.onChange.dispatch(dateRange[0]);
				this.modal.hide()
			},
			defaultDate: this.defaultDateAtOpen,
			minDate: disabledBefore,
			maxDate: disabledAfter,
			prevArrow: '<svg width="34" height="34" xmlns="http://www.w3.org/2000/svg"><path d="M21.448 33.795c7.86-.653 12.284-10.886 12.284-17.69 0-6.804-4.094-13.381-11.32-15.422C15.186-1.36-.471 5.899.01 15.424c.482 9.526 5.058 19.731 21.437 18.37"/><path class="arrow" d="M16.986 5.835L4.513 16.368l9.535 13.768 4.816-3.492-5.906-6.954 20.641.824.133-4.41-19.713-.898 7.024-5.904z"/></svg>',
			nextArrow: '<svg width="34" height="34" xmlns="http://www.w3.org/2000/svg"><path d="M 21.448 0.458 C 29.308 1.111 33.732 11.344 33.732 18.148 C 33.732 24.952 29.638 31.529 22.412 33.57 C 15.186 35.613 -0.471 28.354 0.01 18.829 C 0.492 9.303 5.068 -0.902 21.447 0.459" transform="matrix(-1, 0, 0, -1, 33.731159, 34.25275)"/><path class="arrow" d="M 12.472 30.136 L -0.001 19.603 L 9.534 5.835 L 14.35 9.327 L 8.444 16.281 L 29.085 15.457 L 29.218 19.867 L 9.505 20.765 L 16.529 26.669 Z" transform="matrix(-1, 0, 0, -1, 29.217, 35.970999)"/></svg>',
		});

	}

	handleModalHidden(modalId) {
		if (modalId !== this.el.id) {
			return;
		}

		this.picker.destroy();
		this.defaultDateAtOpen = null;
	}
}

import screen from 'Core/ResponsiveHelper.js';

export default class ImpressionTracker {

	constructor(options) {
		this.signals = options.signals;

		this.checkWhatIsInView = this.checkWhatIsInView.bind(this);
		this.elementsToMonitor = [];

		this.signals.pageContentReplaced.add(this.checkWhatIsInView);
		window.addEventListener('scroll', this.checkWhatIsInView);
	}

	begin() {
		this.checkWhatIsInView();
	}

	checkWhatIsInView() {
		const windowHeight = screen.getWindowDimensions().h;
		this.elementsToMonitor.forEach(thingToMonitor => {
			if (! thingToMonitor.el) {
				this.removeByThing(thingToMonitor);
				return;
			}

			/**
			 * 2018-02-22 - IE11 bug
			 * This is needed because IE11 crashes with an "Unspecified error" exception
			 * which seems to be triggered when an element's bounding rect is retrieved while
			 * it is no longer in the DOM.
			 */
			if (! document.body.contains(thingToMonitor.el)) {
				this.removeByThing(thingToMonitor);
				return;
			}

			if (thingToMonitor.el.getBoundingClientRect().top < windowHeight) {
				this.triggerImpression(thingToMonitor);
			}
		});
	}

	triggerImpression(thingToMonitor) {
		this.signals.ecommerce.productImpression.dispatch(thingToMonitor.type, thingToMonitor.trackingData);
		this.remove(thingToMonitor.el);
	}

	add(el, type, trackingData) {
		this.elementsToMonitor.push({
			el: el,
			type: type,
			trackingData: trackingData
		});
	}

	remove(el) {
		this.elementsToMonitor = this.elementsToMonitor.filter(m => m.el !== el);
	}

	removeByThing(thing) {
		this.elementsToMonitor = this.elementsToMonitor.filter(t => t !== thing);
	}
}
import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';
import isDescendantOf from 'utils/dom/isDescendantOf.js';
import {Sine} from 'gsap';

import findAncestorByClass from 'utils/dom/findAncestorByClass';
import ScrollManager from '../../pages/JaarFairslag/components/utils/ScrollManager';
import SubmenuSlider from './SubmenuSlider';

const OPEN_CLASS = 'navigation--open';
const TRIGGER_OPEN_CLASS = 'navigation__trigger--open';
const NO_SUBMENU_CLASS = 'navigation__item--no-submenu';
const CLASS_ITEM_CONTENT_HIDDEN = 'navigation__items--hidden';
const CURRENT_LINK_CLASS = 'navigation__link--current';
const CURRENT_SUBMENU_ITEM_CLASS = 'navigation__submenu__item--current';

import './navigation.scss';


export default class Navigation extends View {

	initialize(options) {
		this.trigger = this.find('.navigation__trigger');
		this.menu = this.find('.navigation__menu');
		this.items = this.find('.navigation__items');
		this.links = this.findAll('.navigation__link');
		this.subMenuItems = this.findAll('.navigation__submenu__item');
		this.subMenuLinks = this.findAll('.navigation__submenu__link');
		this.menuOpen = false;

		this.scrollManager = new ScrollManager(options);

		this.handleMobileTriggerClick = this.handleMobileTriggerClick.bind(this);
		this.handleSignalMenuTrigger = this.handleSignalMenuTrigger.bind(this);
		this.handleDocumentClick = this.handleDocumentClick.bind(this);
		this.closeMenu = this.closeMenu.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleLinkHover = this.handleLinkHover.bind(this);
		this.handleBreakpointChange = this.handleBreakpointChange.bind(this);
		this.handleNavigationRollOver = this.handleNavigationRollOver.bind(this);
		this.handleNavigationRollOut = this.handleNavigationRollOut.bind(this);
		this.handleSearchResultsHidden = this.handleSearchResultsHidden.bind(this);
		this.handleSearchResultsShown = this.handleSearchResultsShown.bind(this);
		this.handlePageLoadComplete = this.handlePageLoadComplete.bind(this);

		this.trigger.addEventListener('click', this.handleMobileTriggerClick);
		this.links.forEach(link => {
			link.addEventListener('mouseover', this.handleLinkHover);

			if (link.getAttribute('href') !== '#') {
				return;
			}

			const firstSubmenuLink = link.parentElement.querySelector('.navigation__submenu__link');
			if (!firstSubmenuLink) {
				return;
			}

			link.href = firstSubmenuLink.href;
		});
		this.el.addEventListener('mouseover', this.handleNavigationRollOver);
		this.el.addEventListener('mouseout', this.handleNavigationRollOut);
		this.el.addEventListener('click', this.handleClick);

		this.signals.pageLoad.completed.add(this.handlePageLoadComplete);
		// Added with higher priority. Sometimes the pageload can be canceled
		// but we still want the menu to close.

		this.signals.pageLoad.started.add(this.closeMenu, this, 3);
		this.screen.breakpointChanged.add(this.handleBreakpointChange);
		this.signals.search.hidingResults.add(this.handleSearchResultsHidden);
		this.signals.search.showingResults.add(this.handleSearchResultsShown);

		this.signals.menu.toggleMenu.add(this.handleSignalMenuTrigger);

		this.submenuSlider = new SubmenuSlider({
			...options,
			el: this.find('.navigation__submenu-slider'),
		});

		if (this.useMobileMenu()) {
			this.closeMobileMenu();
		}

		this.applyHighlight();
	}

	destroy() {
		this.trigger.removeEventListener('click', this.handleMobileTriggerClick);
		this.links.forEach(link => link.removeEventListener('mouseover', this.handleLinkHover));
		this.el.removeEventListener('click', this.handleClick);
		this.el.removeEventListener('mouseover', this.handleNavigationRollOver);
		this.el.removeEventListener('mouseout', this.handleNavigationRollOut);
		this.screen.breakpointChanged.remove(this.handleBreakpointChange);
		this.submenuSlider.destroy();
		this.signals.pageLoad.completed.remove(this.handlePageLoadComplete);
		this.scrollManager.destroy();
	}

	handleMobileTriggerClick(event) {
		event.preventDefault();

		if (this.menuOpen) {
			this.closeMenu();
		} else {
			this.openMenu();
		}
	}

	handleSignalMenuTrigger() {
		if (this.menuOpen) {
			this.closeMenu();
		} else {
			this.openMenu();
		}
	}

	handleDocumentClick(event) {
		if (this.useMobileMenu()) {
			return;
		}

		if (! isDescendantOf(event.target, this.el)) {
			this.closeMenu();
		}
	}

	handlePageLoadComplete(htmlNode) {
		const container = this.find('.container', htmlNode);

		const currentLink = this.find('.' + CURRENT_LINK_CLASS);
		if (currentLink) {
			currentLink.classList.remove(CURRENT_LINK_CLASS);
		}

		const newLink = this.find(`[data-page-menu-id="${container.dataset.pageMenuId}"]`);
		if (newLink) {
			newLink.classList.add(CURRENT_LINK_CLASS);
		}

		const currentSubmenuItem = this.find('.' + CURRENT_SUBMENU_ITEM_CLASS);
		if (currentSubmenuItem) {
			currentSubmenuItem.classList.remove(CURRENT_SUBMENU_ITEM_CLASS);
		}

		const newSubmenuItem = this.find(`[data-page-id="${container.dataset.pageId}"]`);
		if (newSubmenuItem) {
			newSubmenuItem.classList.add(CURRENT_SUBMENU_ITEM_CLASS);
		}

		this.applyHighlight();
	}

	handleSearchResultsHidden() {
		this.items.classList.remove(CLASS_ITEM_CONTENT_HIDDEN)
	}

	handleSearchResultsShown() {
		this.items.classList.add(CLASS_ITEM_CONTENT_HIDDEN)
	}

	handleClick(event) {
		const anchor = findAncestorByClass(event.target, 'navigation__anchor');
		if (!anchor) {
			return;
		}

		this.closeMenu();
	}

	handleLinkHover(event) {
		const item = findAncestorByClass(event.target, 'navigation__item');
		if (item.classList.contains(NO_SUBMENU_CLASS)) {
			this.closeMenu();
			return;
		}

		if (! this.menuOpen) {
			this.openMenu(item);
			return;
		}

		this.submenuSlider.slideToItem(item);
	}

	handleNavigationRollOver() {
		if (this.rollOverTimeout) {
			clearTimeout(this.rollOverTimeout);
			this.rollOverTimeout = null;
		}
	}

	handleNavigationRollOut() {
		if (this.useMobileMenu()) {
			return;
		}

		this.rollOverTimeout = setTimeout(() => {
			this.closeMenu();
		}, 150);
	}

	handleBreakpointChange() {
		if (! this.useMobileMenu()) {
			this.clearMobileMenuAnimation();
		}

		this.closeMenu();
	}

	useMobileMenu() {
		return this.screen.isOneOf(['mobile', 'tablet']);
	}

	openMenu(item = null) {
		if (this.useMobileMenu()) {
			this.openMobileMenu();
		} else if (item && ! item.classList.contains(NO_SUBMENU_CLASS)) {
			this.submenuSlider.appearForItem(item);
		}

		this.menuOpen = true;

		this.el.classList.add(OPEN_CLASS);
		this.trigger.classList.add(TRIGGER_OPEN_CLASS);

		document.addEventListener('click', this.handleDocumentClick);
	}

	closeMenu(route) {
		if (this.useMobileMenu()) {
			this.closeMobileMenu();
		}

		this.menuOpen = false;

		this.el.classList.remove(OPEN_CLASS);
		this.trigger.classList.remove(TRIGGER_OPEN_CLASS);

		document.removeEventListener('click', this.handleDocumentClick);

		this.applyHighlight(route);
	}

	clearMobileMenuAnimation() {
		TweenLite.killTweensOf(this.menu);
		TweenLite.set(this.menu, {y: 0, scaleY: 1, alpha: 1, display: 'block'});
	}

	openMobileMenu() {
		if (this.menuOpen) {
			TweenLite.set(this.menu, {y: 0, scaleY: 1, alpha: 1, display: 'block'});
			return;
		}

		// Lock the page
		this.signals.pageLock.lockRequested.dispatch('navigation');

		TweenLite.to(this.menu, 0.3, {
			y: 0,
			scaleY: 1,
			alpha: 1,
			transformOrigin: 'center top',
			ease: Circ.easeOut,
			onStart: () => {
				this.menu.style.display = 'block';
			},
			onComplete: () => {
				this.scrollToCurrentItem();
			}
		});
	}

	closeMobileMenu() {
		if (! this.menuOpen) {
			TweenLite.set(this.menu, {scaleY: 0, alpha: 0, y: -100});
			return;
		}

		// Unlock the page
		this.signals.pageLock.unlockRequested.dispatch('navigation');

		TweenLite.to(this.menu, 0.25, {
			y: -100,
			scaleY: 0,
			alpha: 0,
			display: 'block',
			transformOrigin: 'center top',
			ease: Sine.easeOut,
			onComplete: () => {
				TweenLite.set(this.menu, {
					display: 'none',
				});
			}
		});
	}

	applyHighlight() {

		// Main menu
		let location = this.routes.getCurrentLocation();
		this.links .forEach((item) => {
			item.parentElement.classList.remove('navigation__item--selected');

			const href = item.getAttribute('href');
			if(location === href) {
				item.parentElement.classList.add('navigation__item--selected');
			} else if(	href.split(this.routes.getLocalePrefix()).length > 1 &&
						href.split(this.routes.getLocalePrefix())[1].length > 0 &&
						location.indexOf(href) === 0) {
				item.parentElement.classList.add('navigation__item--selected');
			}
		});

		//Sub menu
		location = this.routes.stripDomain(location);
		this.subMenuLinks.forEach((item) => {
			item.parentElement.classList.remove(CURRENT_SUBMENU_ITEM_CLASS);

			const href = item.getAttribute('href');
			if(location === href) {
				item.parentElement.classList.add(CURRENT_SUBMENU_ITEM_CLASS);
			}
		});
	}

	scrollToCurrentItem() {
		this.currentItem = this.find('.navigation__submenu__item--current');

		if(this.currentItem) {
			this.scrollManager.scrollToElement(this.currentItem, 0.3, null, 100);
		}
	}
}

import DefaultPage from '../DefaultPage/DefaultPage.js';
import './payment-failed.scss';

export default class PaymentFailed extends DefaultPage {

	initialize() {
		super.initialize();
	}

	destroy() {
		super.destroy();
	}

}
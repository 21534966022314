import Splash_Introduction from '../Splashes/Introduction';
import Splash_19_1 from '../Splashes/Splash_19_1';
import Splash_19_2 from '../Splashes/Splash_19_2';
import Splash_19_3 from '../Splashes/Splash_19_3';
import Splash_19_4 from '../Splashes/Splash_19_4';
import Splash_19_5 from '../Splashes/Splash_19_5';
import Splash_19_6 from '../Splashes/Splash_19_6';
import Splash_19_7 from '../Splashes/Splash_19_7';
import Splash_19_8 from '../Splashes/Splash_19_8';
import Splash_19_9 from '../Splashes/Splash_19_9';
import Splash_20_1 from '../Splashes/Splash_20_1';
import LottieSplash from '../Splashes/LottieSplash';
import SplashDefault from '../Splashes/SplashDefault';

import TweenLite from 'gsap/TweenLite';
import loadScript from './ScriptLoader';

const splashClassMap = new Map();
splashClassMap.set('intro', Splash_Introduction);
splashClassMap.set('19_1', Splash_19_1);
splashClassMap.set('19_2', Splash_19_2);
splashClassMap.set('19_3', Splash_19_3);
splashClassMap.set('19_4', Splash_19_4);
splashClassMap.set('19_5', Splash_19_5);
splashClassMap.set('19_6', Splash_19_6);
splashClassMap.set('19_7', Splash_19_7);
splashClassMap.set('19_8', Splash_19_8);
splashClassMap.set('19_9', Splash_19_9);
splashClassMap.set('20_1', Splash_20_1);
splashClassMap.set('lottie', LottieSplash);
splashClassMap.set('default', SplashDefault);

const MOBILE_BREAKPOINT = 768;
const HEADER_MARGIN_TOP_MOBILE = 78;
const HEADER_MARGIN_TOP_DESKTOP = 160;

const LOTTIE_PLAYER_URL = 'https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.7.4/lottie.min.js';

const checkFontLoaded = (cb) => {
	document.fonts.ready.then(cb);
}
export default class SplashManager {

	constructor(options) {

		this.el = options.el;

		this.setScrollTopOffset(window.innerWidth);

		this.setupEventListeners();
		this.setupUI();
		this.setupStartValues();
		this.setSplashContainer();

		this.lottieLoadStarted = false;
		this.lottieInitialized = false;

		this.splashes = new Map();
		this.progressPerSplash = {};

		this.currentSplashProgressTween = null;
	}

	// PUBLIC
	destroy() {
	}

	updateScrollPosition(y) {

		if (y >= this.scrollTopOffset) {
			this.el.classList.add('is-fixed');
		} else {
			this.el.classList.remove('is-fixed');
		}

	}

	updateResize(width) {

		this.setScrollTopOffset(width);
		this.setSplashContainer();

	}

	// PRIVATE
	setupEventListeners() {
		this.handleFontLoaded = this.handleFontLoaded.bind(this);
	}

	setupUI() {

		this.ui = {
			content: this.el.querySelector('.js-chapter-splash-content'),
			lottieSplashContent: this.el.querySelector('.js-chapter-lottie-splash-content'),
			player: this.el.querySelector('.js-lottie-player')
		}

	}

	setupStartValues() {

		this.lastScrollOfset = 0;
		this.splashScrollHeight = 0;
		this.splashVisualHeight = 0;
		this.acviteId = -1;

		this.ui.lottieSplashContent.classList.add('is-hidden');

	}

	setScrollTopOffset(width) {

		this.scrollTopOffset = width <= MOBILE_BREAKPOINT ? HEADER_MARGIN_TOP_MOBILE : HEADER_MARGIN_TOP_DESKTOP;

	}

	setSplashContainer() {

		this.splashVisualHeight = this.ui.content.getBoundingClientRect().height;
		this.splashScrollHeight = this.splashVisualHeight * 2;

	}

	loadLottie() {
		if(!this.lottieLoadStarted) {
			this.lottieLoadStarted = true;
			loadScript(LOTTIE_PLAYER_URL).then(() => {
				this.lottieInitialized = true;
				this.checkLottieInitialisation();
			});
		}
	}

	checkLottieInitialisation() {
		if(this.lottieInitialized && this.fontsLoaded) {
			if(this.delayedLoadSplash) {
				this.showLottieSplash(this.delayedLoadSplash);
				this.delayedLoadSplash = false;
			}
		}
	}

	createSplash(options) {

		if(options.type === 'lottie') {
			this.loadLottie();
			checkFontLoaded(this.handleFontLoaded);
		}

		const splashClassId = options.el.dataset.splashId;
		const SplashClass = splashClassMap.get(splashClassId) || splashClassMap.get('default');
		const splash = new SplashClass(options);
		this.registerSplash(options.id, splash);
		return splash;

	}

	registerSplash(id, splash) {
		if(!this.firstChapter) {
			this.firstChapter = id;
		}
		this.splashes.set(id, splash);
	}

	update(chapter) {

		this.updateActiveSplash(chapter.id);
		this.setSplashProgress(chapter);

	}

	updateActiveSplash(id) {
		if (this.acviteId !== id) {
			this.hideSplashWithId(this.acviteId);
			this.showSplashWithId(id);
			this.acviteId = id;

			// NOTE: Hide white bar only for the first chapter when chapter type is lottie.
			if(id === this.firstChapter) {
				this.ui.lottieSplashContent.classList.add('is-intro')
			} else {
				this.ui.lottieSplashContent.classList.remove('is-intro')
			}
		}
	}

	updateActiveSplashContainer(splash) {

		if(splash.type === 'lottie') {
			this.ui.lottieSplashContent.classList.remove('is-hidden');
			this.ui.content.classList.add('is-hidden');
		} else {
			this.ui.content.classList.remove('is-hidden');
			this.ui.lottieSplashContent.classList.add('is-hidden');
		}

	}

	showSplashWithId(id) {

		const splash = this.splashes.get(id);
		if (!splash) {
			return;
		}

		this.updateActiveSplashContainer(splash);

		if (!splash.active && splash.type === 'lottie') {
			splash.active = true;
			this.showLottieSplash(splash);
		}

		if (!splash.active && !splash.static) {
			this.ui.content.appendChild(splash.el);
			splash.active = true;
		}

		this.activeSplash = this.splashes.get(id);
	}

	showLottieSplash(splash) {
		if(window.lottie) {
			if(this.lottieAnim) this.lottieAnim.destroy();
			this.isLoopingSplash = splash.animationType === 'loop';
			this.lottieAnim = window.lottie.loadAnimation({
				container: this.ui.player,
				renderer: 'svg',
				speed: 1,
				autoplay: this.isLoopingSplash,
				loop: this.isLoopingSplash,
				animationData: splash.animationData
			});
		} else {
			this.delayedLoadSplash = splash;
		}
	}

	hideSplashWithId(id) {
		const splash = this.splashes.get(id);
		if (splash && splash.active) {
			splash.reset();
		}
	}

	setSplashProgress(chapter) {
		if (!this.progressPerSplash[chapter.id]) {
			this.progressPerSplash[chapter.id] = 0;
		}

		const progress = Math.max(Math.min((chapter.y - chapter.posY + this.splashVisualHeight * .3) / this.splashScrollHeight, 1), 0);
		const duration = Math.abs(this.lastScrollOfset - chapter.y) * .002;

		this.lastScrollOfset = chapter.y;

		if (this.activeSplash) {
			if (this.currentSplashProgressTween) {
				this.currentSplashProgressTween.kill();
				this.currentSplashProgressTween = null;
			}

			this.currentSplashProgressTween = TweenLite.to(this.progressPerSplash, 0.8, {
				[chapter.id]: progress,
				ease: Expo.easeOut,
				onUpdate: () => {
					const progress = this.progressPerSplash[chapter.id];
					if(this.activeSplash.type === 'lottie' && this.lottieAnim) {
						if(!this.isLoopingSplash) this.lottieAnim.goToAndStop(Math.round(progress * this.lottieAnim.animationData.op), true);
					} else {
						this.activeSplash.update({progress, duration});
					}
				}
			});
		}
	}

	handleFontLoaded(fontFaceSet) {
		if(fontFaceSet.status === 'loaded') {
			this.fontsLoaded = true;
			this.checkLottieInitialisation();
		}
	}
}

import Signal from 'signals';

const conversationBarsSignals = {

	/**
	 * @param String type
	 */
	selectConversationBarFilter: new Signal()
};

export default conversationBarsSignals;

import View from 'Core/View';

import './subscription-login-or-register.scss';
import ArrowButton from '../components/ArrowButton';

export default class SubscriptionLoginOrRegister extends View {

    initialize(options) {
		this.handleChange = this.handleChange.bind(this);

        this.radios = this.findAll('.subscription-login-or-register__radio');
        this.radios.forEach(el => {
			el.addEventListener('change', this.handleChange);
			el.checked = false;
		});

		this.registerSection = this.find('.subscription-login-or-register__register');
		this.loginSection = this.find('.subscription-login-or-register__login');

		this.arrowButtons = this.findAll('.subscription-arrow-button').map(el => {
			return new ArrowButton({...options, el: el});
		});
    }

    destroy() {
    }

	update(state) {
	}

	visible(state) {
		return !! state.plan_id;
	}

	handleChange() {
    	const value = this.radios.filter(el => el.checked)[0].value;

		if (value === 'register') {
			this.registerSection.style.display = 'inline-block';
			this.loginSection.style.display = 'none';
		} else {
			this.registerSection.style.display = 'none';
			this.loginSection.style.display = 'inline-block';
		}

		this.updateArrows(value);
	}

	updateArrows(value) {
		this.arrowButtons.forEach(arrowButton => {
			const inputValue = this.find('input', arrowButton.el).value;
			if (inputValue === value) {
				arrowButton.open();
			} else {
				arrowButton.close();
			}
		});
	}
}

const shopRoutes = [
	'shop',
	'shop.cart',
	'shop.index.auto-open-cart',
	'shop.index',
	'shop.detail',
	'shop.category',
	'shop.search',
	'shop.authenticate',
	'shop.checkout',
	'shop.checkout.delivery',
	'shop.checkout.payment',
	'shop.confirm',
	'shop.error',
	'shop.gifts',
	'wrapper-creator.order.savedwrapper',
];

export default shopRoutes;

import View from 'Core/View.js';
import './radio-button.scss';
import Icon from '!raw-loader!img-loader!./radio.svg';

export default class RadioButton extends View {

	initialize() {
		this.el.classList.add('radio--replaced');

		this.insertSVG();
	}

	destroy() {
	}

	insertSVG() {
		const svg = document.createElement('div');
		svg.className = 'radio__svg';
		svg.innerHTML = Icon;

		const input = this.find('.radio__input');
		if(input) {
			this.el.insertBefore(svg, this.find('.radio__input').nextSibling);
			input.addEventListener('change', this.onChange.bind(this));
		} else {
			console.error(this.el, 'input element not found');
		}
	}

	// Necessary to fix IE11 select and render issues
	onChange(event) {
		const input = this.find('.radio__input');
		const name = input.getAttribute('name');

		if(name && name.length > 0) {
			var radioButtons = document.getElementsByName(name);
			for (var i = 0; i < radioButtons.length; i++) {
				if (radioButtons[i].checked) {
					radioButtons[i].setAttribute('checked', '');
				} else {
					radioButtons[i].removeAttribute('checked');
				}
			}
		}


	}
}
import DefaultPage from '../DefaultPage/DefaultPage.js';
import CheckoutShippingMethods from './CheckoutShippingMethods';

import './checkout-delivery.scss';
import {addZeros} from '../../../shared/utils/Formatting';
import CartModel from '../../../shared/data/CartModel';

export default class CheckoutDelivery extends DefaultPage {

	initialize(options) {
		super.initialize(options);

		this.updateDeliveryDate = this.updateDeliveryDate.bind(this);
		this.updateDeliveryTime = this.updateDeliveryTime.bind(this);

		this.deliveryDateGraphic = this.find('.checkout__delivery-date-picker__date');
		this.deliveryDateFullWrittenDate = this.find('.checkout__delivery-date-picker__full-date');
		this.deliveryDayInput = this.find('input[name="delivery_day"]');

		this.deliveryBlock = this.find('.checkout__delivery');
		this.deliveryMessage = this.find('.checkout__delivery__content');

		this.datePicker = this.findViewForElement(document.querySelector('.checkout__date-picker-modal'), '.date-picker-modal');

		this.shippingMethods = new CheckoutShippingMethods({...options, el: this.find('.checkout__shipping-wrapper')});

		if (this.datePicker) {
			const defaultValue = this.deliveryDayInput ? this.deliveryDayInput.value : undefined;

			this.datePicker.setDate(defaultValue);
			this.datePicker.onChange.add(this.updateDeliveryDate);
		}

		this.updateDeliveryTime();
	}

	destroy() {
		super.destroy();

		this.shippingMethods.destroy();
	}

	updateDeliveryTime() {
		const meta = CartModel.getMeta();
		if (! meta) {
			return;
		}

		if (! meta.deliveryTime) {
			return;
		}

		if (this.deliveryBlock) {
			this.deliveryBlock.style.display = 'block';
		}

		if (this.deliveryMessage) {
			this.deliveryMessage.innerHTML = meta.deliveryTime;
			this.deliveryMessage.style.display = 'block';
		}
	}

	updateDeliveryDate(newDate) {

		if (this.deliveryDayInput) {
			this.deliveryDayInput.value = [
				newDate.getFullYear(),
				addZeros(newDate.getMonth() + 1),
				addZeros(newDate.getDate())
			].join('-');
		}

		if (this.deliveryDateGraphic) {
			this.deliveryDateGraphic.innerHTML = addZeros(newDate.getDate());
		}

		if (this.deliveryDateFullWrittenDate) {
			this.deliveryDateFullWrittenDate.innerHTML = newDate.toLocaleDateString(
				this.el.dataset.dateLocale ? this.el.dataset.dateLocale : 'en-US',
				{year: 'numeric', month: 'long', day: 'numeric' }
			);
		}
	}

	updateShippingMethods(parsedHtml) {

		// Remember 'old' selected radio button
		const activeRadio = this.shippingOptionContainer.querySelector('input[type="radio"]:checked');

		// Remove all shipping methods from DOM
		const oldOptions = this.findAll('.checkout__shipping-method-option');
		oldOptions.forEach((option) => {
			option.parentNode.removeChild(option);
		});

		// Add fresh shipping methods to DOM
		const newOptions = [].slice.call(parsedHtml.querySelectorAll('.checkout__shipping-method-option'));
		newOptions.forEach((option) => {
			this.shippingOptionContainer.appendChild(option);
		});

		// Check if we have a 'new' or 'already' selected radio button in de fresh DOM, if not try to apply the 'old' selected value
		const newActiveRadio = this.shippingOptionContainer.querySelector('input[type="radio"]:checked');
		if (! newActiveRadio && activeRadio) {
			const matchedRadio = this.shippingOptionContainer.querySelector('input[type="radio"][value="' + activeRadio.value + '"]');
			if (matchedRadio) {
				matchedRadio.checked = true;
			}
		}

		// Reinitialize radiobuttons
		this.shippingMethods.update();
	}
}

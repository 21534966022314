import Signal from 'signals';

import forms from './signals/forms.js';
import pageLoad from './signals/pageLoad.js';
import backgroundAnimation from './signals/backgroundAnimation.js';
import loading from './signals/loading.js';
import modals from './signals/modals.js';
import products from './signals/products.js';
import pageLock from './signals/pageLock.js';
import meta from './signals/meta.js';
import toaster from './signals/toaster.js';
import ecommerce from './signals/ecommerce.js';
import social from './signals/social.js';
import debug from './signals/debug.js';
import search from './signals/search.js';
import menu from './signals/menu.js';
import shop from './signals/shop.js';

export default {

	/**
	 * These signal groups are extracted to their own files
	 */
	backgroundAnimation: backgroundAnimation,
	forms: forms,
	meta: meta,
	modals: modals,
	loading: loading,
	pageLock: pageLock,
	pageLoad: pageLoad,
	products: products,
	toaster: toaster,
	ecommerce: ecommerce,
	social: social,
	debug: debug,
	search: search,
	menu: menu,
	shop: shop,


	/**
	 * Signaled just before the content of the page will be updated
	 *
	 * @param {Element} element
	 * @param {Object} route
	 */
	beforePageContentReplaced: new Signal(),

	/**
	 * The content of the page was updated
	 * @param {Element} element
	 * @param {Object} route
	 */
	pageContentReplaced: new Signal(),

	/**
	 * The browser window dimensions have been update
	 * @param {Number} width
	 * @param {Number} height
	 */
	windowResized: new Signal(),

	/*
	 * Triggered when Youtube API Frame is ready
	 */
	youtubeAPIFrameReady: new Signal(),

	/**
	 * Bar in animation completed
	 */
	barInAnimationCompleted: new Signal(),

	/**
	 * When a section button is clicked (big button in the header)
	 */
	sectionButtonClicked: new Signal(),

	/** @param {Node} node */
	contentFocusRequested: new Signal(),
};

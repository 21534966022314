import createTemplate from 'lodash.template';

import View from '../../../../shared/Core/View';
import findAncestorByClass from '../../../../shared/utils/dom/findAncestorByClass';

import './subscription-gift-conclusion.scss';

export default class SubscriptionGiftConclusion extends View {

	initialize(options) {
		this.handleAllergensConfirmed = this.handleAllergensConfirmed.bind(this);

		this.deliveryDate = this.el.dataset.deliveryDate;
		this.gifts = JSON.parse(this.el.dataset.gifts);

		this.allergensWarning = options.modalDictionary['allergens-warning'];
		this.allergensConfirmButton = this.allergensWarning.find('.allergens-warning__confirm');
		this.allergensConfirmButton.addEventListener('click', this.handleAllergensConfirmed);

		this.templateTargets = this.findAll('[data-template]')
			.map(el => {
				const templateName = el.dataset.template;
				const template = createTemplate(
					this.el.dataset['template' + templateName.substr(0, 1).toUpperCase() + templateName.substr(1)]
				);

				return {el: el, template: template};
			});
	}

	destroy() {
		this.allergensConfirmButton.removeEventListener('click', this.handleAllergensConfirmed);
	}

	handleAllergensConfirmed(event) {
		event.preventDefault();

		const formEl = findAncestorByClass(this.el, 'form');
		const form = this.findViewForElement(formEl);
		form.submit();

		this.allergensWarning.hide();
	}

	visible(state) {
		return !! state.product_id && state.completed_options === '1';
	}

	update(state, prevState) {
		const gift = this.gifts.find(gift => gift.id === state.plan_id);
		if (!gift) {
			console.warn('[TONYS] Could not find gift by plan_id: ' + state.plan_id);
			return;
		}

		const giftType = gift.giftTypes[state.product_id];
		if (!giftType) {
			console.warn('[TONYS] Could not find gift type by product_id: ' + state.product_id);
			return;
		}

		const price = giftType.price ? giftType.price : {
			offsetCurrencyPriceVat: '€ 0,00',
			currencyPriceVat: '€ 0,00',
		};

		this.templateTargets.forEach(({el, template}) => {
			const types = el.dataset.giftTypes ? el.dataset.giftTypes.split(',') : [];

			if (types.indexOf(giftType.key) === -1) {
				el.style.display = 'none';
			} else {
				el.innerHTML = template({
					planPrice: `<strong>${gift.price.currencyPriceVat}</strong>`,
					additionalCosts: `<strong>${price.offsetCurrencyPriceVat}</strong>`,
					totalCosts: `<strong>${price.totalCurrencyPriceVat}</strong>`,
					planDuration: `<strong>${gift.key.replace('m', '')}</strong>`,
					giftTypeName: giftType.optionLabel,
					deliveryDate: this.deliveryDate,
					recipientName: state.first_name ? state.first_name : '...',
				});
				el.style.display = 'block';
			}
		});
	}
}

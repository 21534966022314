import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import TweenLite, { Ease } from 'gsap/TweenLite';

import Button from '../../../reactComponents/Button/Button';

import './quantity-input.scss';

const HEIGHT = 70;

class QuantityInput extends React.PureComponent {
	constructor(props) {
		super(props);

		this.handleMinusClick = this.handleMinusClick.bind(this);
		this.handlePlusClick = this.handlePlusClick.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);

		this.state = {
			inputValue: props.value,
			topSlot: props.value - 1,
			middleSlot: props.value,
			bottomSlot: props.value + 1,
			animating: false,
		};

		this.slotMachineRef = createRef();
	}

	componentDidUpdate(prevProps) {
		if (this.state.inputValue !== this.props.value && this.props.value !== prevProps.value) {
			this.setState({ inputValue: this.props.value });
			this.updateSlot(this.props.value);
		}
	}

	render() {
		return (
			<div className="quantity-input">
				<Button
					className="quantity-input__button"
					onClick={this.handleMinusClick}
					type="button"
					clipIndex={0}
					disabled={this.props.value === 0}
				>
					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 275 256">
						<path d="m261.6 96.3-4.6 71.1H17l-4.2-62 248.8-9z" />
					</svg>
				</Button>

				<div className="quantity-input__value">
					<div className="quantity-input__slotmachine" ref={this.slotMachineRef}>
						<span className="quantity-input__slot" data-position="top">
							{this.state.topSlot}
						</span>
						<span className="quantity-input__slot" data-position="middle">
							{this.state.middleSlot}
						</span>
						<span className="quantity-input__slot" data-position="bottom">
							{this.state.bottomSlot}
						</span>
					</div>

					<div className="quantity-input__input-wrapper">
						<input
							className="quantity-input__input"
							type="number"
							min="0"
							step="1"
							name="quantity"
							value={this.state.inputValue}
							onInput={this.handleInputChange}
							onChange={this.handleInputChange}
							onFocus={this.props.onInputFocus}
							onBlur={this.props.onInputBlur}
							onKeyDown={event => { 
								if (event.key === 'Enter') this.props.onInputBlur() 
							}}
						/>

						{this.props.value}
					</div>
				</div>

				<Button
					className="quantity-input__button"
					onClick={this.handlePlusClick}
					type="button"
					clipIndex={1}
					disabled={this.props.maxQuantity && this.props.value >= this.props.maxQuantity}
				>
					<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 256 256">
						<path d="m252.7 97.3-81.9-2.8 3.9-92.2h-78l1.7 89.6-94.1-3.2 2 64 93.2-1 1.8 99h63l4.2-99.8 82.8-.9z" />
					</svg>
				</Button>
			</div>
		);
	}

	handleMinusClick() {
		this.updateQuantity(this.props.value - 1);
	}

	handlePlusClick() {
		this.updateQuantity(this.props.value + 1);
	}

	updateQuantity(value) {
		const newValue = this.forceValueWithinBounds(value);
		this.updateSlot(newValue);

		this.props.onChange && this.props.onChange(newValue);
	}

	handleInputChange(event) {
		const input = event.currentTarget;
		const value = this.filterInputValue(input.value);

		const valueAsInt = parseInt(value, 10);
		if (Number.isNaN(valueAsInt)) {
			this.setState({ inputValue: value });
			return;
		}

		this.setState({ inputValue: this.forceValueWithinBounds(valueAsInt).toString(10) });
		this.updateQuantity(valueAsInt);
	}

	filterInputValue(value) {
		const numbersOrEmptyString = value.replace(/\D/g, '');
		if (numbersOrEmptyString === '') {
			return '';
		}

		return numbersOrEmptyString;
	}

	updateSlot(newValue) {
		const isAnimatingAlready = this.state.animating;

		this.setState({
			topSlot: newValue,
			middleSlot: this.props.value,
			bottomSlot: newValue,
			animating: true,
		});

		if (!this.slotMachineRef.current || isAnimatingAlready) {
			return;
		}

		const direction = this.props.value > newValue ? 1 : -1;

		TweenLite.to(this.slotMachineRef.current, 0.3, {
			ease: Ease.easeInOut,
			y: direction * HEIGHT,
			onComplete: this.resetAnimation,
			onCompleteScope: this,
		});
	}

	resetAnimation() {
		if (!this.slotMachineRef.current) {
			return;
		}

		this.setState({
			animating: false,
			topSlot: this.props.value,
			middleSlot: this.props.value,
			bottomSlot: this.props.value,
		});

		TweenLite.set(this.slotMachineRef.current, { y: 0 });
	}

	forceValueWithinBounds(value) {
		const newValue = Math.max(0, value);
		if (!this.props.maxQuantity) {
			return newValue;
		}

		return Math.min(this.props.maxQuantity, newValue);
	}
}

QuantityInput.propTypes = {
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func,
	maxQuantity: PropTypes.number,
	onInputFocus: PropTypes.func,
	onInputBlur: PropTypes.func,
};

export default QuantityInput;

import View from '../../../shared/Core/View';

export default class ToolboxLoader extends View {

	initialize(options) {
		this.toolbox = null;
		this.destroyed = false;

		import('./Toolbox.js').then(({default: Component}) => {
			if (! this.destroyed) {
				this.toolbox = new Component(options);
			}
		})
	}

	destroy() {
		this.destroyed = true;

		if (this.toolbox) {
			this.toolbox.destroy();
		}
	}
}

import View from 'Core/View';

import './subscription-preview.scss';

export default class SubscriptionPreview extends View {

    initialize() {

    }

    destroy() {
    }
}

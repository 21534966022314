import Signal from 'signals';

export const SCROLLTRIGGER_EVENT_TYPES = {
	PROGRESS: 'progress', // ON MOUSE
	TRIGGER: 'trigger' // TRIGGER ONCE
}

class ScrollTriggerService {

	constructor() {
		this.triggers = new Map();

		this.tickerSettings = {
			prev: 0,
			current: null,
			fps: 1000 / 25,
		};

		this.initialize();
		this.setupEventListeners();
		this.resize();
		this.startTicker();
	}

	// PUBLIC
	registerScrollTrigger(id, type, el, top, bottom, callback ) {
		this.triggers.set(id, {
			el,
			type,
			triggerTop: top,
			triggerBottom: bottom,
			callback
		})
	}

	removeTrigger(id) {
		this.triggers.delete(id);
	}


	// PRIVATE
	initialize() {
		this.ScrollTriggerSignal = new Signal();
	}


	setupEventListeners() {
		this.tick = this.tick.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleResize = this.handleResize.bind(this);

		window.addEventListener('scroll', this.handleScroll.bind(this));
		window.addEventListener('resize', this.handleResize.bind(this));
	}

	startTicker() {
		this.needsUpdate = true;
		requestAnimationFrame(this.tick);
	}

	tick(timestamp) {
		requestAnimationFrame(this.tick);

		this.tickerSettings.current = timestamp;
		const elapsed = this.tickerSettings.current - this.tickerSettings.prev;

		if(elapsed > this.tickerSettings.fps && this.needsUpdate) {
			this.checkTriggers();
			this.tickerSettings.prev = this.tickerSettings.current;
			this.needsUpdate = false;
		}
	}

	checkTriggers() {
		this.triggers.forEach(item => {
			if( item.type === SCROLLTRIGGER_EVENT_TYPES.TRIGGER) {
				this.checkScrollTriggerAnimation(item);
			} else if (item.type === SCROLLTRIGGER_EVENT_TYPES.PROGRESS) {
				this.checkScrollProgressAnimation(item);
			}

		});
	}

	checkScrollTriggerAnimation(item) {
		const { el, triggerTop, triggerBottom, callback } = item;
		const { top, bottom } = el.getBoundingClientRect();
		const bottomPx = this.viewport.height - (triggerBottom / 100 * this.viewport.height);
		const topPx = triggerTop / 100 * this.viewport.height;

		if(top < bottomPx && bottom > topPx) {
			callback();
		}
	}

	checkScrollProgressAnimation(item) {
		const { el, triggerTop, triggerBottom, callback } = item;
		const { top } = el.getBoundingClientRect();
		const bottomPx = this.viewport.height - (triggerBottom / 100 * this.viewport.height);
		const topPx = triggerTop / 100 * this.viewport.height;

		const diff = bottomPx - topPx;
		const scrollDiff = bottomPx - top;

		const progress = Math.max(Math.min(scrollDiff / diff, 1), 0);

		callback(progress);
	}

	resize() {
		this.viewport = {
			width: window.innerWidth,
			height: window.innerHeight
		}
	}

	handleResize() {
		if(this.resizeThorttle) {
			clearTimeout(this.resize);
		}

		this.resizeThorttle = setTimeout(this.resize, 300);
	}

	handleScroll() {
		this.scrollPosY = window.scrollY;

		if(this.scrollPosY !== this.lastScrollPosY) {
			this.needsUpdate = true;
			this.lastScrollPosY = this.scrollPosY;
		}
	}

}

export default ScrollTriggerService;

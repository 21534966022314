import View from 'Core/View.js';

import './shop-authenticate.scss';

const STATE_PASSWORD = 'shop-authenticate--state-password'

export default class ShopAuthenticate extends View {

	initialize(options) {
		this.handleFormSubmitted = this.handleFormSubmitted.bind(this);
		this.showDefault = this.showDefault.bind(this);
		this.showPassword = this.showPassword.bind(this);

		this.emailInput = this.find('#shop-authenticate #login_email');
		this.hiddenEmailInput = this.find('#shop-authenticate-login #login_email_hidden');
		this.userEmail = this.find('.shop-authenticate__user-email');
		this.userPassword = this.find('#login_password');
		this.userEmailEdit = this.find('.shop-authenticate__user-email-edit');

		if (this.userEmailEdit) {
			this.userEmailEdit.addEventListener('click', this.showDefault);
		}
		this.signals.forms.succeeded.add(this.handleFormSubmitted);
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.handleFormSubmitted);
		if (this.userEmailEdit) {
			this.userEmailEdit.removeEventListener('click', this.showDefault);
		}
	}

	showDefault() {
		this.el.classList.remove(STATE_PASSWORD);
		this.emailInput.focus();
	}

	showPassword() {
		this.el.classList.remove(STATE_PASSWORD);
		this.el.classList.add(STATE_PASSWORD);

		// Copy password from default step to login step
		this.userEmail.innerHTML = this.hiddenEmailInput.value = this.emailInput.value;
		this.userPassword.focus();
	}

	handleFormSubmitted(formId, data) {
		if (formId === 'shop-authenticate') {
			if(data.exists) {
				this.showPassword();
			} else {
				const path = this.routes.getPath('shop.checkout');
				this.signals.pageLoad.requested.dispatch(this.routes.getRoute(path));
			}
		}
	}
}

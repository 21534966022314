import responsive from './ResponsiveHelper.js';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactContainer from 'components/ReactContainer/ReactContainer.jsx'

class View {

	/**
	 * @param {Object} options
	 */
	constructor(options) {
		options = options || {};

		if (options.el) {
			this.el = options.el;
		}

		this.signals = options.signals;
		this.routes = options.routes;
		this.screen = responsive;
		this.refreshViewsForElement = options.refreshViewsForElement;
		this.findViewForElement = options.findViewForElement;

		if (this.initialize) {
			this.initialize(options);
		}
	}

	/**
	 * Apply ViewMap to child views
	 *
	 * @param {Element} container
	 */
	initializeChildViews(container = this.el) {
		this.refreshViewsForElement(container);
	}

	/**
	 * Destroy does the exact same thing, but for clarity we add it here.
	 *
	 * @param {Element} container
	 */
	destroyChildViews(container = this.el) {
		this.refreshViewsForElement(container);
	}

	/**
	 * @returns {String}
	 */
	toString() {
		return '[object View]';
	}

	/**
	 *
	 * @param {Element} domElement
	 * @param {ReactNode} Component
	 * @param {Object} props
	 */
	renderReact(domElement, Component, props = {}) {
		ReactDOM.render((<ReactContainer><Component {...props}/></ReactContainer>), domElement);
	}

	/**
	 * @param {String} query
	 * @param {Element|undefined} context
	 * @returns {Element}
	 */
	find(query, context = null) {
		if (! context) {
			context = this.el;
		}

		return context.querySelector(query);
	}

	/**
	 * @param {String} query
	 * @param {Element|undefined} context
	 * @returns {Array|Element[]}
	 */
	findAll(query, context = null) {
		if (! context) {
			context = this.el;
		}

		return [].slice.call(context.querySelectorAll(query));
	}
}

export default View;
//
// if (module.hot) {
// 	module.hot.accept('../../views/ReactContainer/ReactContainer.jsx', () => {
// 		console.log('accepted', lastConfig);
// 	});
// }

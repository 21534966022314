import View from 'Core/View.js';
import './login-form.scss';

export default class LoginForm extends View {

	initialize() {
	}

	destroy() {
	}
}
import View from '../../../shared/Core/View';

const CURRENT_CLASS = 'ingredients-map__ingredient--current';

export default class IngredientsBar extends View {

	initialize(options) {
		this.handleIngredientClick = this.handleIngredientClick.bind(this);

		this.onIngredientChosen = options.onIngredientChosen;
		this.current = null;

		this.anchors = this.findAll('.ingredients-map__ingredient');
		this.anchors.forEach(el =>
			el.addEventListener('click', this.handleIngredientClick)
		);
	}

	destroy() {
		this.anchors.forEach(el =>
			el.removeEventListener('click', this.handleIngredientClick)
		);
	}

	handleIngredientClick(event) {
		event.preventDefault();

		const anchor = event.currentTarget;
		const [, id] = anchor.href.split('#');
		const locations = JSON.parse(anchor.dataset.locations);
		const iconUrl = anchor.dataset.iconUrl;

		this.onIngredientChosen({
			id: id,
			iconUrl: iconUrl,
			locations: locations,
		});

		if (this.current) {
			this.current.classList.remove(CURRENT_CLASS);
		}

		if (this.current === anchor) {
			this.current = null;
			return;
		}

		anchor.classList.add(CURRENT_CLASS);
		this.current = anchor;
	}
}

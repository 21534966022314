import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TextInput from '../../../../reactComponents/TextInput/TextInput';
import StoreSearchBoxIcon from './components/StoreSearchBoxIcon/StoreSearchBoxIcon.jsx';
import StoreSearchBoxErase from './components/StoreSearchBoxErase/StoreSearchBoxErase.jsx';

import './store-search-box.scss';
import Button from '../../../../reactComponents/Button/Button';

class StoreSearchBox extends Component {

	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleQueryChange = this.handleQueryChange.bind(this);
		this.handleEraseClicked = this.handleEraseClicked.bind(this);

		this.state = {
			hasFocus: false,
		};
	}

	render() {
		const classes = classNames({
			'store-search-box': true,
			'store-search-box--focus': this.state.hasFocus || this.props.query,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<form className={classes} action="#" onSubmit={this.handleSubmit}>
				<div className="store-search-box__bar">
					<div className="store-search-box__background"/>
					<StoreSearchBoxIcon className="store-search-box__icon"/>
					<TextInput className="store-search-box__input"
						label={this.props.translations.search_placeholder || 'street, zipcode or city..'}
						name="search"
						onChange={this.handleQueryChange}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
						large
						value={this.props.query}/>

					{this.renderEraseSearch()}
				</div>

				{this.renderSubmitButton()}
			</form>
		);
	}

	renderEraseSearch() {
		if (! this.props.lastSearchQuery) {
			return null;
		}

		return (
			<StoreSearchBoxErase
				className="store-search-box__erase"
				onClick={this.handleEraseClicked}/>
		);
	}

	renderSubmitButton() {
		if (! this.props.query || this.props.isMobile || this.props.lastSearchQuery) {
			return null;
		}

		return (
			<Button type="submit" color="red" align="right" size="large" iconName="arrow-right">
				{this.props.translations.search || 'search'}
			</Button>
		);
	}

	handleSubmit(event) {
		event.preventDefault();

		if (this.props.onSearch) {
			this.props.onSearch(this.props.query)
		}
	}

	handleEraseClicked(event) {
		event.preventDefault();

		if (this.props.onChange) {
			this.props.onChange('');
		}

		if (this.props.onSearch) {
			this.props.onSearch('');
		}
	}

	handleQueryChange(value) {
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	}

	handleFocus() {
		this.setState({hasFocus: true});
	}

	handleBlur() {
		this.setState({hasFocus: false});
	}
}

StoreSearchBox.propTypes = {
	query: PropTypes.string,
	lastSearchQuery: PropTypes.string,
	isMobile: PropTypes.bool,
	onSearch: PropTypes.func,
	onChange: PropTypes.func,
	className: PropTypes.string,
	translations: PropTypes.object.isRequired,
};

export default StoreSearchBox;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Event from '../../shapes/Event';
import ButtonList from '../../../../reactComponents/ButtonList/ButtonList';
import Button from '../../../../reactComponents/Button/Button';

import './truck-locator-event-details.scss';
import eventToDates from '../TruckLocatorEvents/eventToDates';

class TruckLocatorEventDetails extends React.PureComponent {

	render() {
		const classes = classNames({
			'truck-locator-event-details': true,
			[this.props.className]: ! ! this.props.className,
		});

		const event = this.props.event;

		return (
			<div className={classes}>
				{this.renderImageIfAvailable()}

				<div className="truck-locator-event-details__content">
					<h2 className="truck-locator-event-details__title">
						{event.name}
					</h2>
					<p className="truck-locator-event-details__date">
						{eventToDates(event)}
					</p>
					<p className="truck-locator-event-details__address" dangerouslySetInnerHTML={{
						__html: event.address
					}}/>

					<div className="truck-locator-event-details__details" dangerouslySetInnerHTML={{
						__html: event.details
					}}/>

					<ButtonList align="right">
						{this.renderOptionalMoreInfoButton()}

						<Button
							type="anchor"
							target="_blank"
							className="truck-locator-event-details__maps-button"
							url={this.renderGoogleMapsURL()}
							iconName="arrow-right"
							color="white">
							{this.props.translations.mapsButtonLabel || 'open in maps'}
						</Button>
					</ButtonList>
				</div>

				<button onClick={this.props.onClose}
						className="truck-locator-event-details__close-button"/>
			</div>
		);
	}

	renderImageIfAvailable() {
		if (! this.props.event.imageURL) {
			return null;
		}

		return (
			<img src={this.props.event.imageURL} className="truck-locator-event-details__image"/>
		);
	}

	renderOptionalMoreInfoButton() {
		if (! this.props.event.buttonLabel) {
			return null;
		}

		return (
			<Button
				type="anchor"
				className="truck-locator-event-details__more-info-button"
				url={this.props.event.buttonURL}
				size="large"
				color="white">
				{this.props.event.buttonLabel}
			</Button>
		);
	}

	renderGoogleMapsURL() {
		return 'https://www.google.com/maps/dir/' + this.props.event.gps.lat + ',' + this.props.event.gps.lng;
	}
}

TruckLocatorEventDetails.propTypes = {
	event: Event.isRequired,
	translations: PropTypes.object.isRequired,
	className: PropTypes.string,
	onClose: PropTypes.func,
};

export default TruckLocatorEventDetails;

import View from '../../../shared/Core/View';
import TweenLite from 'gsap/TweenLite';
import template from 'lodash.template';

import './ingredient-checker.scss';

export default class IngredientChecker extends View {
	initialize() {
		this.backToForm = this.backToForm.bind(this);
		this.goToFinal = this.goToFinal.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);

		this.signals.forms.succeeded.add(this.handleFormSubmit);

		this.formEl = this.find('.ingredient-checker__form');
		this.textareaEl = this.find('.ingredient-checker__textarea');
		this.noGoEl = this.find('.ingredient-checker__no-ingredient');
		this.maybeEl = this.find('.ingredient-checker__maybe-ingredient');
		this.allOkEl = this.find('.ingredient-checker__all-ok');
		this.allOkIconEl = this.find('.ingredient-checker__all-ok__icon');
		this.finalEl = this.find('.ingredient-checker__final');

		this.ingredientsTemplate = template(
			document.querySelector('#ingredient-picker-ingredients').innerHTML
		);

		this.ingredientLists = this.findAll(
			'.ingredient-checker__ingredient-list'
		);

		this.toFinalButtons = this.findAll('.ingredient-checker__to-final');
		this.toFinalButtons.forEach(el =>
			el.addEventListener('click', this.goToFinal)
		);
		this.againButtons = this.findAll('.ingredient-checker__again');
		this.againButtons.forEach(el =>
			el.addEventListener('click', this.backToForm)
		);
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.handleFormSubmit);
		this.againButtons.forEach(el =>
			el.removeEventListener('click', this.backToForm)
		);
		this.toFinalButtons.forEach(el =>
			el.removeEventListener('click', this.goToFinal)
		);
	}

	handleFormSubmit(formId, response) {
		if (formId !== 'ingredient-checker') {
			return;
		}

		const ingredients = response.ingredients || [];

		const nextStep = this.determineNextStep(ingredients);

		this.textareaEl.value = '';
		this.currentStatusEl = this.statusToDiv(nextStep);
		this.swapElements(this.formEl, this.currentStatusEl);

		const ingredientListContents = this.ingredientsTemplate({
			ingredients,
		});
		this.ingredientLists.forEach(
			el => (el.innerHTML = ingredientListContents)
		);

		if (nextStep === 'all-ok') {
			TweenLite.fromTo(
				this.allOkIconEl,
				0.25,
				{ opacity: 0, scale: 0.6, rotation: -30 },
				{
					delay: 0.25,
					opacity: 1,
					scale: 1,
					rotation: 0,
				}
			);
		}
	}

	backToForm() {
		this.swapElements(this.currentStatusEl, this.formEl);

		this.currentStatusEl = null;
	}

	goToFinal() {
		this.swapElements(this.currentStatusEl, this.finalEl);
		this.currentStatusEl = this.finalEl;
	}

	swapElements(from, to) {
		TweenLite.to(from, 0.15, {
			opacity: 0,
			x: -20,
			position: 'absolute',
			top: 0,
			left: 0,
			onComplete: () => (from.style.display = 'none'),
		});

		TweenLite.fromTo(
			to,
			0.15,
			{ display: 'block', position: 'static', opacity: 0, x: 20 },
			{ opacity: 1, x: 0 }
		);
	}

	determineNextStep(ingredients) {
		if (this.containsStatus('no', ingredients)) {
			return 'no';
		}

		if (this.containsStatus('maybe', ingredients)) {
			return 'maybe';
		}

		return 'all-ok';
	}

	containsStatus(status, ingredients) {
		const match = ingredients.filter(
			ingredient => ingredient.status === status
		);

		return match.length > 0;
	}

	statusToDiv(status) {
		switch (status) {
			case 'no':
				return this.noGoEl;
			case 'maybe':
				return this.maybeEl;
			case 'all-ok':
			default:
				return this.allOkEl;
		}
	}
}

import View from '../../../shared/Core/View';

import CartModel from '../../../shared/data/CartModel';
import {LOCK_ID} from '../../components/CartOverlay/CartOverlay';

import './go-to-checkout-button.scss';

const VISIBLE_CLASS = 'go-to-checkout-button--visible';

export default class GoToCheckoutButton extends View {
	initialize() {
		this.handleClick = this.handleClick.bind(this);
		this.compareCartWithRelevantProductHashes = this.compareCartWithRelevantProductHashes.bind(this);

		this.relevantProductHashes = JSON.parse(this.el.dataset.productHashes) || [];

		CartModel.updatedSignal.add(this.compareCartWithRelevantProductHashes);

		this.compareCartWithRelevantProductHashes();

		this.el.addEventListener('click', this.handleClick);
	}

	destroy() {
		CartModel.updatedSignal.remove(this.compareCartWithRelevantProductHashes);
		this.el.removeEventListener('click', this.handleClick);
	}

	compareCartWithRelevantProductHashes() {
		if (this.hasOneOrMoreProductsInCart()) {
			this.el.classList.add(VISIBLE_CLASS);
		} else {
			this.el.classList.remove(VISIBLE_CLASS);
		}
	}

	hasOneOrMoreProductsInCart() {
		return !!this.relevantProductHashes.find(hash => !!CartModel.getProductByHash(hash));
	}

	handleClick(event) {
		event.preventDefault();

		this.signals.pageLock.lockRequested.dispatch(LOCK_ID);
	}
}

import View from '../../../shared/Core/View';

import './resend-giftcard-modal.scss';

export default class ResendGiftCardModal extends View {

	initialize() {

		this.handleFormSubmitting = this.handleFormSubmitting.bind(this);
		this.handleFormSucceeded = this.handleFormSucceeded.bind(this);
		this.handleFormFailed = this.handleFormFailed.bind(this);
		this.handleShowing = this.handleShowing.bind(this);

		this.form = this.find('form');
		this.orderLineResendTextElement = document.querySelector('.page-order__products a[data-receiver-id="' + this.el.dataset.recipientId + '"] .order-product__resent-text');

		this.signals.modals.triggered.add(this.handleShowing);

		this.signals.forms.submitted.add(this.handleFormSubmitting);
		this.signals.forms.succeeded.add(this.handleFormSucceeded);
		this.signals.forms.failed.add(this.handleFormFailed);
	}

	destroy() {

		this.signals.modals.triggered.remove(this.handleShowing);
		this.signals.forms.submitted.remove(this.handleFormSubmitting);
		this.signals.forms.succeeded.remove(this.handleFormSucceeded);
		this.signals.forms.failed.remove(this.handleFormFailed);

		this.onRecipientsReceived.removeAll();
	}

	handleShowing(modalId) {
		if (modalId !== this.el.id) {
			return;
		}
	}

	handleFormSubmitting(formId) {
	}

	handleFormFailed(formId) {
	}

	handleFormSucceeded(formId, response) {

		if(this.form.id !== formId) {
			return;
		}

		const modal = window.tonysModals[this.el.id];
		if(modal) {
			modal.hide();
		}

		// Display resent data
		if(this.orderLineResendTextElement) {
			const resendNameElement = this.orderLineResendTextElement.querySelector('.order-product__resent-name');
			const resendEmailElement = this.orderLineResendTextElement.querySelector('.order-product__resent-email');

			this.orderLineResendTextElement.classList.remove('order-product__resent-text--visible');
			this.orderLineResendTextElement.classList.toggle('order-product__resent-text--visible', true);

			resendNameElement.innerHTML = this.form.querySelector('input[name="name"]').value;
			resendEmailElement.innerHTML = this.form.querySelector('input[name="email"]').value;
		}
	}
}

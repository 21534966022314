import View from 'Core/View.js';

import './sticky-shop-nav.scss';

export default class StickyShopNav extends View {

	initialize(options) {
		this.options = options;

		this.locked = this.locked.bind(this);
		this.unlocked = this.unlocked.bind(this);

		this.container = this.find('.sticky-shop-nav__container');

		this.signals.pageLock.locked.add(this.locked);
		this.signals.pageLock.unlocked.add(this.unlocked);
	}

	destroy() {
		this.signals.pageLock.locked.remove(this.locked);
		this.signals.pageLock.unlocked.remove(this.unlocked);
	}

	locked() {
		const top = parseInt(window.getComputedStyle(this.el).top, 10);
		const parentRect = this.el.parentElement.getBoundingClientRect();
		const stickyOffsetY = Math.max(top - parentRect.top, 0);

		// Offset container by stickyOffsetY when page locks to counter wrapper transform
		this.container.style.transform = `translateY(${stickyOffsetY}px)`;
	}

	unlocked() {
		this.container.style.transform = 'none';
	}
}

import View from 'Core/View.js';
import isDescendantOf from 'utils/dom/isDescendantOf.js';

import './modal.scss';
import './edit-or-duplicate-modal.scss';

export default class Modal extends View {

	initialize(options) {
		this.modalDictionary = options.modalDictionary;
		this.modalDictionary[this.getIdentifier()] = this;

		this.isShown = false;

		// Keep this timeout to prevent instant hiding of the modal
		this.shownRequestedAt = 0;

		this.handleDocumentClicked = this.handleDocumentClicked.bind(this);
		this.handleKeyPressed = this.handleKeyPressed.bind(this);
		this.handleCloseClicked = this.handleCloseClicked.bind(this);
		this.hide = this.hide.bind(this);
		this.updatePosition = this.updatePosition.bind(this);

		this.screen.resized.add(this.updatePosition);
		this.signals.pageLoad.requested.add(this.hide);

		this.container = document.querySelector('.modal-container');

		// Open this modal automatically after a second
		if (this.shouldAutoOpen()) {
			setTimeout(this.show.bind(this), 1000);
		}
	}

	destroy() {
		delete this.modalDictionary[this.getIdentifier()];

		this.screen.resized.remove(this.updatePosition);
		this.signals.pageLoad.requested.remove(this.hide);
		this.hide();
	}

	show() {
		if (this.isShown) {
			return;
		}

		this.shownRequestedAt = (new Date()).getTime();

		if (this.isCancelable()) {
			document.addEventListener('click', this.handleDocumentClicked);
			window.addEventListener('keydown', this.handleKeyPressed, true);
		}

		this.findAll('.modal__close').forEach(close => {
			close.addEventListener('click', this.handleCloseClicked);
		});

		this.isShown = true;
		this.appendToContainer();
		this.updatePosition();

		TweenLite.fromTo(this.el, 0.3, {scale: 0.8, alpha: 0}, {
			scale: 1, alpha: 1, ease: Back.easeOut
		});

		// Dispatch to the outside world
		this.signals.modals.shown.dispatch(this.getIdentifier(), this);
		this.signals.pageLock.lockRequested.dispatch('modal.' + this.getIdentifier());
	}

	hide() {
		if (! this.isShown) {
			return;
		}

		const timeAgo = (new Date()).getTime() - this.shownRequestedAt;
		if (timeAgo < 200) {
			return;
		}

		document.removeEventListener('click', this.handleDocumentClicked);
		window.removeEventListener('keydown', this.handleKeyPressed, true);

		this.findAll('.modal__close').forEach(close => {
			close.removeEventListener('click', this.handleCloseClicked);
		});

		this.isShown = false;

		this.signals.modals.hide.dispatch(this.getIdentifier(), this);

		TweenLite.to(this.el, 0.3, {
			scale: 0.8, alpha: 0, ease: Back.easeIn, onComplete: () => {
				this.removeFromContainer();

				// Dispatch to the outside world
				this.signals.modals.hidden.dispatch(this.getIdentifier(), this);
				this.signals.pageLock.unlockRequested.dispatch('modal.' + this.getIdentifier());
			}
		});
	}

	appendToContainer() {

		// Store current state and append to the container
		this.locationBeforeShow = {
			parent: this.el.parentNode,
			index: Array.prototype.indexOf.call(this.el.parentNode.childNodes, this.el),
		};

		this.el.classList.add('modal--shown');
		this.container.classList.add('modal-container--visible');

		this.container.appendChild(this.el);
	}

	removeFromContainer() {
		this.el.classList.remove('modal--shown');
		this.container.classList.remove('modal-container--visible');

		// Restore original state
		const parent = this.locationBeforeShow.parent;
		parent.insertBefore(this.el, parent.children[this.locationBeforeShow.index]);

	}

	handleDocumentClicked(event) {
		if (event.target !== this.el && ! isDescendantOf(event.target, this.el)) {
			this.hide();
		}
	}

	handleKeyPressed(event) {

		// 27 is the escape key
		if (event.keyCode === 27) {
			this.hide();
		}
	}

	handleCloseClicked(event) {
		event.preventDefault();
		event.stopPropagation();

		this.hide();
	}

	isCancelable() {
		return ! this.el.classList.contains('modal--not-cancelable');
	}

	getIdentifier() {
		return this.el.id;
	}

	updatePosition() {
		if (! this.isShown) {
			return;
		}

		if (this.el.clientHeight > this.container.clientHeight) {
			this.el.classList.add('modal--align-top');
		} else {
			this.el.classList.remove('modal--align-top');
		}
	}

	shouldAutoOpen() {
		return this.el.classList.contains('modal--auto-open');
	}
}

import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

export default class Container extends View {

	initialize() {

		this.onPageLoadStarted = this.onPageLoadStarted.bind(this);
		this.onPageLoadCompleted = this.onPageLoadCompleted.bind(this);
		this.onBackgroundAnimationStarted = this.onBackgroundAnimationStarted.bind(this);
		this.onBackgroundAnimationDone = this.onBackgroundAnimationDone.bind(this);

		this.loadedHtml = '';
		this.lastLoadedRoute = null;
		this.waitingForContentLoad = false;
		this.waitingForBackgroundAnimation = false;

		this.signals.pageLoad.started.add(this.onPageLoadStarted);
		this.signals.pageLoad.failed.add(this.onPageLoadCompleted);
		this.signals.pageLoad.completed.add(this.onPageLoadCompleted);
		this.signals.backgroundAnimation.started.add(this.onBackgroundAnimationStarted);
		this.signals.backgroundAnimation.completed.add(this.onBackgroundAnimationDone);
	}

	destroy() {
		this.signals.pageLoad.started.remove(this.onPageLoadStarted);
		this.signals.pageLoad.failed.remove(this.onPageLoadCompleted);
		this.signals.pageLoad.completed.remove(this.onPageLoadCompleted);
		this.signals.backgroundAnimation.started.remove(this.onBackgroundAnimationStarted);
		this.signals.backgroundAnimation.completed.remove(this.onBackgroundAnimationDone);
	}

	onPageLoadStarted() {
		this.waitingForContentLoad = true;
	}

	onPageLoadCompleted(htmlNode, loadTime, route) {
		this.loadedHtml = htmlNode;
		this.waitingForContentLoad = false;
		this.lastLoadedRoute = route;
		this.checkIfWeCanShowContents();
	}

	onBackgroundAnimationStarted(direction) {
		this.waitingForBackgroundAnimation = true;

		// Already animating?
		// remove current contents already to prevent visual glitch
		if (this.containerIsAnimating) {
			this.replaceContentWithEqualHeightPlaceholder();
		}

		this.containerIsAnimating = true;

		TweenLite.killTweensOf(this.el);
		TweenLite.set(this.el, {x: 0});
		TweenLite.to(this.el, 0.9, {
			x: document.body.clientWidth * direction,
			ease: Cubic.easeInOut,
			onComplete: () => {
				this.onContainerAnimationDone();
			}
		});
	}

	onBackgroundAnimationDone() {
		this.waitingForBackgroundAnimation = false;
		this.checkIfWeCanShowContents();
	}

	onContainerAnimationDone() {
		this.containerIsAnimating = false;
		this.replaceContentWithEqualHeightPlaceholder();
		this.checkIfWeCanShowContents();
	}

	replaceContentWithEqualHeightPlaceholder() {
		/**
		 * Oct 5 2017 - Disabled as this potentially causes the in-app
		 * browser of for example the Facebook app to sometime hide the
		 * content.
		 */
		//this.el.innerHTML = `<div style="height: ${this.el.clientHeight}px;"></div>`;
	}

	checkIfWeCanShowContents() {
		if (this.containerIsAnimating || this.waitingForBackgroundAnimation || this.waitingForContentLoad) {
			return;
		}

		this.replaceContainerContents(this.loadedHtml);
	}

	replaceContainerContents(htmlNode) {
		if (! htmlNode) {
			return;
		}

		const newContainer = htmlNode.querySelector('.container');
		let html = '';
		if (newContainer) {
			html = newContainer.innerHTML;
		}

		this.el.style.transform = 'none'; // Reset transformation
		this.signals.beforePageContentReplaced.dispatch(this.el, this.lastLoadedRoute, html);
		this.el.innerHTML = html;
		this.signals.pageContentReplaced.dispatch(this.el, this.lastLoadedRoute);

		this.executeScripts();
	}

	executeScripts() {
		this.findAll('script').map(el => {
			const script = document.createElement('script');
			const currentRoute = this.lastLoadedRoute;

			script.text = el.innerHTML;
			for (let i = 0; i < el.attributes.length; i ++) {
				script.setAttribute(el.attributes[i].name, el.attributes[i].value);
			}
			script.onload = () => {
				this.signals.pageLoad.scriptLoaded.dispatch(script.src, script.id, currentRoute);
			};

			el.parentNode.replaceChild(script, el);
		});
	}
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TweenLite from 'gsap/TweenLite';

import './stores-loading.scss';

class StoresLoading extends Component {

	componentWillEnter(callback) {
		callback();
	}

	componentWillLeave(callback) {
		TweenLite.to(this.el, 1, {opacity: 0, onComplete: callback});
	}

	render() {
		return (
			<div className="stores-loading" ref={el => this.el = el}>
				<div className="stores-loading__copy">
					{this.props.translations.initializing}
				</div>
			</div>
		);
	}
}

StoresLoading.propTypes = {
	translations: PropTypes.object.isRequired,
};

export default StoresLoading;
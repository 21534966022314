import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import fetchLocations from './requests/locations';
import searchInLocations from './requests/search';
import searchInLocationsByLatLng from './requests/searchByLatLng';

import LatLng from '../../../shared/shapes/LatLng';

import SVGIcon from '../../reactComponents/SVGIcon/SVGIcon';

import StoreLocatorMap from './components/StoreLocatorMap/StoreLocatorMap';
import StoresLoading from './components/StoresLoading/StoresLoading';
import StoreSearchBox from './components/StoreSearchBox/StoreSearchBox';
import SearchResults from './components/SearchResults/SearchResults';
import LocationDetails from './components/LocationDetails/LocationDetails';
import ShareLocation from './components/ShareLocation/ShareLocation';

import './store-locator.scss';

class StoreLocator extends Component {

	constructor(props) {
		super(props);

		this.search = this.search.bind(this);
		this.searchByLatLng = this.searchByLatLng.bind(this);
		this.focusOnLocation = this.focusOnLocation.bind(this);
		this.cancelFocusOnLocationOrLatLng = this.cancelFocusOnLocationOrLatLng.bind(this);
		this.handleCancelFocusClicked = this.handleCancelFocusClicked.bind(this);
		this.handleQueryChanged = this.handleQueryChanged.bind(this);
		this.handleEnlargeStreetViewToggled = this.handleEnlargeStreetViewToggled.bind(this);
		this.handleMapTouched = this.handleMapTouched.bind(this);

		this.state = {

			// Loading
			loadError: null,
			isSearching: false,

			// Data
			lastSearchQuery: '',
			searchQuery: '',
			locations: null,
			filteredLocations: null,
			focusOnLocation: null,
			focusOnLatLng: null,

			// UI
			enlargedStreetView: false,
			userMoved: false,
		};
	}

	componentDidMount() {
		fetchLocations(this.props.locationsURL).then(
			(locations) => {
				locations = this.filterValidLocations(locations);

				this.setState({
					userMoved: false,
					locations: locations,
					filteredLocations: this.filterLocationsAndAddDistance(locations),
					focusOnLocation: this.props.initialFocusLocation ? locations[this.props.initialFocusLocation] : null,
				});
			},
			(error) => this.setState({loadError: error.response.body}));
	}

	render() {
		const locations = this.state.filteredLocations;
		const lowerClasses = classNames({
			'store-locator__lower-area': true,
			'store-locator__lower-area--full-screen': this.state.enlargedStreetView,
		});

		return (
			<div className="store-locator">
				<div className="store-locator__main-area">
					{this.renderMapWhenDataHasLoaded()}
					{this.renderSearchBoxWhenNotFocussed()}
					{this.renderMobileMapOverlays()}
				</div>

				<div className={lowerClasses}>
					{this.renderFocusLocationOrSearchResults(locations)}
				</div>

				{this.renderNonMobileMapOverlays()}
			</div>
		);
	}

	renderMapWhenDataHasLoaded() {
		if (this.state.loadError) {
			return <span>this.state.loadError</span>;
		}

		if (! this.state.locations) {
			return (
				<StoresLoading translations={this.props.translations} key="loading"/>
			);
		}

		const shouldFit = this.shouldFitMapBounds(this.state.filteredLocations);

		return (
			<StoreLocatorMap
				key="map"
				apiKey={this.props.apiKey}
				isMobile={this.props.isMobile}
				isTablet={this.props.isTablet}
				isSearching={! ! this.state.lastSearchQuery}
				locations={shouldFit ? this.state.filteredLocations : this.state.locations}
				fitBoundsToMarkers={shouldFit}
				focusOnLocation={this.state.focusOnLocation}
				focusOnLatLng={this.state.focusOnLatLng}
				initialZoom={this.props.defaultZoom}
				initialCenter={this.props.defaultCenter}
				country={this.props.country}

				onMapTouched={this.handleMapTouched}
				onLocationSelected={this.focusOnLocation}
				onSearchByLatLng={this.searchByLatLng}
				onUserLocationBlocked={this.props.showGeoBlockedModal}/>
		);
	}

	renderSearchBoxWhenNotFocussed() {
		if (this.state.focusOnLocation) {
			return null;
		}

		return (
			<div className="store-locator__search-wrapper" key="search">
				<StoreSearchBox
					query={this.state.searchQuery}
					lastSearchQuery={this.state.lastSearchQuery}
					className="store-locator__search"
					isMobile={this.props.isMobile}
					onSearch={this.search}
					onChange={this.handleQueryChanged}
					translations={this.props.translations}/>
			</div>
		);
	}

	renderMobileMapOverlays() {
		if (! this.props.isMobile) {
			return null;
		}

		return [
			this.renderCancelFocusButton(),
			this.renderShareButtons(),
		];
	}

	renderNonMobileMapOverlays() {
		if (this.props.isMobile) {
			return null;
		}

		return this.renderCancelFocusButton();
	}

	renderCancelFocusButton() {
		if (! this.state.focusOnLocation) {
			return null;
		}

		const icon = this.props.isMobile ? 'arrow-in-circle--left' : 'close-in-circle';

		return (
			<a href="#"
			   key="cancel-focus"
			   className="store-locator__cancel-focus"
			   onClick={this.handleCancelFocusClicked}>
				<SVGIcon name={icon} className="store-locator__cancel-focus__icon"/>
			</a>
		);
	}

	renderShareButtons() {
		if (! this.state.focusOnLocation) {
			return null;
		}

		return (
			<ShareLocation
				key="share"
				showShareModal={this.props.showShareModal}
				className="store-locator__share-buttons"
				translations={this.props.translations}
				whiteButtons={! this.props.isMobile}
				location={this.state.focusOnLocation}/>
		);
	}

	renderSearchResultsWhenAQueryIsSet(locations) {
		if (! this.state.lastSearchQuery || this.state.isSearching) {
			return null;
		}

		const locationsArray = Object.keys(locations).map(id => locations[id]);
		locationsArray.sort((a, b) => {
			return a.distance - b.distance;
		});

		return (
			<SearchResults
				className="store-locator__search-results"
				key="search-results"
				locations={locationsArray}
				onLocationSelected={this.focusOnLocation}
				translations={this.props.translations}/>
		);
	}

	renderFocusLocationOrSearchResults(locations) {
		if (! this.state.focusOnLocation) {
			return this.renderSearchResultsWhenAQueryIsSet(locations);
		}

		return (
			<LocationDetails
				key="details"
				apiKey={this.props.apiKey}
				translations={this.props.translations}
				location={this.state.focusOnLocation}
				isMobile={this.props.isMobile}
				enlargedStreetView={this.state.enlargedStreetView}
				onEnlargeStreetViewToggle={this.handleEnlargeStreetViewToggled}
				className="store-locator__location-details">
				{this.props.isMobile ? null : this.renderShareButtons()}
			</LocationDetails>
		);
	}

	shouldFitMapBounds(locations) {
		const hasLocations = Object.keys(locations).length > 0;
		const hasQuery = ! ! this.state.lastSearchQuery && ! ! this.state.searchQuery;

		return hasLocations && hasQuery && !this.state.userMoved;
	}

	search(query) {
		this.setState({lastSearchQuery: query});
		if (! query) {
			return;
		}

		const request = searchInLocations(this.props.searchURL, query);
		this.handleSearchRequest(request);
	}

	searchByLatLng(latLng) {
		const request = searchInLocationsByLatLng(this.props.searchURL, latLng.lat, latLng.lng);
		this.handleSearchRequest(request)
			.then((locations) => {
				if (locations.length === 0) {
					this.setState({focusOnLatLng: latLng})
				}
			});
	}

	focusOnLocation(location) {
		this.setState({focusOnLocation: location, focusOnLatLng: null});
	}

	cancelFocusOnLocationOrLatLng() {
		this.setState({focusOnLocation: null, focusOnLatLng: null});
	}

	filterLocationsAndAddDistance(allLocations, locationsWithDistance = null) {
		if (! locationsWithDistance) {
			return allLocations;
		}

		const filtered = {};
		locationsWithDistance.forEach(location => {
			filtered[location.id] = allLocations[location.id];
			filtered[location.id].formattedDistance = location.formattedDistance;
			filtered[location.id].distance = location.distance;
		});

		return filtered;
	}

	handleMapTouched() {
		this.cancelFocusOnLocationOrLatLng();

		this.setState({userMoved: true,});
	}

	handleCancelFocusClicked(event) {
		event.preventDefault();

		this.cancelFocusOnLocationOrLatLng();
	}

	handleSearchRequest(request) {
		this.setState({isSearching: true, filteredLocations: {}});

		return request.then((locationsWithDistance) => {
				// Put a reference in the state to prevent re-rendering
				this.setState({
					userMoved: false,
					filteredLocations: this.filterLocationsAndAddDistance(this.state.locations, locationsWithDistance),
					isSearching: false,
				});

				return locationsWithDistance;
			},
			(error) => this.setState({
				loadError: error.response.body,
				isSearching: false,
			})
		)
	}

	handleQueryChanged(query) {
		this.setState({
			searchQuery: query,
		});

		if (! query) {
			this.setState({
				userMoved: false,
				filteredLocations: this.state.locations,
				isSearching: false,
			});
		}
	}

	handleEnlargeStreetViewToggled(enlarged) {
		this.setState({enlargedStreetView: enlarged});
	}

	filterValidLocations(locations) {
		Object.keys(locations).forEach(id => {
			const location = locations[id];
			if (! location.gps || ! location.gps.lat || ! location.gps.lng) {
				console.error('[TONYS] Location ' + id + ' has an invalid GPS coordinate. It is removed from the results');
				delete locations[id];
			}
		});

		return locations;
	}
}

StoreLocator.propTypes = {
	apiKey: PropTypes.string.isRequired,
	locationsURL: PropTypes.string.isRequired,
	searchURL: PropTypes.string.isRequired,
	defaultCenter: LatLng,
	defaultZoom: PropTypes.number,
	initialFocusLocation: PropTypes.number,
	translations: PropTypes.object.isRequired,
	showGeoBlockedModal: PropTypes.func.isRequired,
	showShareModal: PropTypes.func.isRequired,
	isMobile: PropTypes.bool,
	isTablet: PropTypes.bool,
	country: PropTypes.oneOf(['default', 'US']),
};

StoreLocator.defaultProps = {
	defaultCenter: {
		lat: 40,
		lng: - 98.7058248
	},
	defaultZoom: 4,
	country: 'default',
};

export default StoreLocator;

import View from '../../../shared/Core/View';
import CartModel from '../../../shared/data/CartModel';
import {LOCK_ID} from '../../components/CartOverlay/CartOverlay';

import './meta-cart-icon.scss';

export default class MetaCartIcon extends View {

    initialize() {
		this.update = this.update.bind(this);
		this.handleClick = this.handleClick.bind(this);

		this.previousProductCount = 0;

		CartModel.updatedSignal.add(this.update);
		this.el.addEventListener('click', this.handleClick);
    }

    destroy() {
		CartModel.updatedSignal.remove(this.update);
		this.el.removeEventListener('click', this.handleClick);
    }

	handleClick(event) {
		event.preventDefault();

		this.openCart();
	}

	openCart() {
		this.signals.pageLock.lockRequested.dispatch(LOCK_ID);
	}

	update() {
		const productCount = CartModel.getProducts().reduce((total, product) => total + product.quantity, 0);

		// Check if needs to animate
		if (productCount !== this.previousProductCount) {
			this.shake();
		}

		this.previousProductCount = productCount;
	}

	shake() {
		TweenLite.killTweensOf(this.el);
		TweenLite.to(this.el, 0.1, {
			rotation: - 10, scale: 1.1, onComplete: () => {
				TweenLite.to(this.el, 0.1, {
					rotation: 10, scale: 0.9, onComplete: () => {
						TweenLite.to(this.el, 0.05, {rotation: 0, scale: 1});
					}
				});
			}
		});
	}
}

import View from 'Core/View.js';

import './shop-detail-column.scss';

const MAX_HEIGHT = 300;

export default class ShopDetailColumn extends View {

	initialize() {
		if (! this.el.classList.contains('shop-detail-column--collapse-if-large')) {
			return;
		}

		this.uncollapse = this.uncollapse.bind(this);
		this.detectOverflow = this.detectOverflow.bind(this);

		this.allButton = this.find('.shop-detail-column__all');

		if (this.allButton) {
			this.allButton.addEventListener('click', this.uncollapse);
		}

		window.addEventListener('resize', this.detectOverflow);
		this.detectOverflow();
	}

	destroy() {
		window.removeEventListener('resize', this.detectOverflow);

		if (this.allButton) {
			this.allButton.removeEventListener('click', this.uncollapse);
		}
	}

	detectOverflow() {
		if (this.el.clientHeight > MAX_HEIGHT) {
			this.el.style.height = `${MAX_HEIGHT}px`;
			this.el.classList.add('shop-detail-column--collapsed');
		}
	}

	uncollapse(event) {
		event.preventDefault();

		this.el.style.height = '';
		this.el.classList.remove('shop-detail-column--collapsed');
	}
}

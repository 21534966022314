import createRequest from 'superagent';

import View from 'Core/View.js';

import './wrapper-preview.scss';

export default class WrapperPreview extends View {

	initialize(options) {
		this.remove = this.remove.bind(this);
		this.success = this.success.bind(this);
		this.failure = this.failure.bind(this);
		this.confirm = this.confirm.bind(this);
		this.cancel = this.cancel.bind(this);
		this.edit = this.edit.bind(this);

		this.isUnlimited = this.el.dataset.unlimited;
		this.editUrl = this.el.dataset.editUrl;
		this.duplicateAndEditUrl = this.el.dataset.duplicateUrl;
		this.newUrl = this.el.dataset.newUrl;

		this.removeButton = this.find('.wrapper-preview__remove');
		if (this.removeButton) {
			this.removeButton.addEventListener('click', this.remove);
			this.removeEndPoint = this.removeButton.href;
		}

		this.link = this.find('.wrapper-preview__link');
		this.link.addEventListener('click', this.edit);

		this.modal = options.modalDictionary['dialog-wrapper-remove'];
	}

	destroy() {
		if (this.removeButton) {
			this.removeButton.removeEventListener('click', this.remove);
		}

		this.link.removeEventListener('click', this.edit);
	}

	remove(event) {
		event.preventDefault();

		this.el.classList.add('wrapper-preview--disabled');

		this.modal.el.querySelector('.dialog-wrapper-remove__yes').addEventListener('click', this.confirm);
		this.modal.el.querySelector('.dialog-wrapper-remove__no').addEventListener('click', this.cancel);
		this.modal.show();
	}

	confirm(event) {
		event.preventDefault();

		this.modal.hide();

		const request = createRequest('DELETE', this.removeEndPoint);
		request.set('Accept', 'application/json');
		request.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
		request.set('X-Requested-With', 'XMLHttpRequest');

		request.then(this.success, this.failure);
	}

	cancel(event) {
		event.preventDefault();

		this.el.classList.remove('wrapper-preview--disabled');
		this.modal.hide();
	}

	success(response) {
		this.el.parentNode.removeChild(this.el);
		this.checkMessages(response);
	}

	failure(error) {
		this.el.classList.remove('wrapper-preview--disabled');

		if (error.response) {
			this.checkMessages(JSON.parse(error.response.text));
		}
	}

	checkMessages(response) {
		if (response.message) {
			this.errorSignal.dispatch(response.message);
			this.signals.toaster.requested.dispatch(response.message, 3);

		} else if (response.modalMessage) {
			this.errorSignal.dispatch(response.modalMessage);
			this.signals.toaster.requested.dispatch(response.modalMessage, 3);
		}
	}

	edit(event) {
		event.preventDefault();
		event.stopPropagation();

		if (!event.currentTarget || !event.currentTarget.href) {
			return;
		}

		if (this.isUnlimited) {
			this.signals.products.unlimitedEditRequested.dispatch(this.editUrl, this.duplicateAndEditUrl);
			return;
		}

		this.signals.products.wrapperEditRequested.dispatch(this.editUrl, this.duplicateAndEditUrl, this.newUrl);
	}
}

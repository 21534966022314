import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

export default class MainTitles extends View {

	initialize() {
		this.show();
	}

	show() {
		TweenLite.set(this.el, {opacity:0});
		this.el.style.visibility = 'visible';
		TweenLite.to(this.el, 0.3, {opacity:1, ease:Linear.easeNone});
	}

	hide() {

	}

	destroy() {

	}
}
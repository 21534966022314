import View from 'Core/View.js';
import fetchProductInfo from './requests/fetchProductInfo';

export default class UnlimitedsDetails extends View {

	initialize() {
		this.url = this.el.dataset.url;
	}

	update(recipe) {
		if (! recipe.couvertures || recipe.couvertures.length === 0) {
			this.el.innerHTML = '';
			return;
		}

		fetchProductInfo(this.url, recipe).then((response) => {
			this.el.innerHTML = response;
		});
	}

	destroy() {
	}
}

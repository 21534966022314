import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Select extends React.PureComponent {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.renderOption = this.renderOption.bind(this);
	}

	render() {
		const classes = classNames({
			select: true,
			'select--replaced': true,
			'select--disabled': this.props.disabled,
			[this.props.className]: !!this.props.className,
		});

		return (
			<div className={classes}>
				<div className="select__svg">
					<svg version="1.1" viewBox="0 0 55.7 35.5">
						<polygon points="0,8.7 27.5,35.5 55.7,12.4 50.2,3.4 27.4,21.1 20.8,14.1 8.4,0 " />
					</svg>
				</div>
				<select
					defaultValue={this.props.defaultValue}
					onChange={this.handleChange}
					disabled={this.props.disabled}
					name={this.props.name}
					id={this.props.id}
					className={classNames('select__select', this.props.inputClassName)}
				>
					{this.props.options.map(this.renderOption)}
				</select>
			</div>
		);
	}

	renderOption(option) {
		if (option.options) {
			return (
				<optgroup key={option.label} label={option.label} disabled={option.disabled}>
					{option.options.map(this.renderOption)}
				</optgroup>
			);
		}

		return (
			<option key={option.value} value={option.value} disabled={option.disabled}>
				{option.label}
			</option>
		);
	}

	handleChange(event) {
		if (this.props.onChange) {
			this.props.onChange(event.target.value);
		}
	}
}

Select.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
			// Mutually exclusive with
			options: PropTypes.arrayOf(
				PropTypes.shape({
					label: PropTypes.string.isRequired,
					value: PropTypes.string.isRequired,
					disabled: PropTypes.bool,
				})
			),
		})
	),
	id: PropTypes.string,
	defaultValue: PropTypes.string,
	className: PropTypes.string,
	inputClassName: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
};

export default Select;

import View from 'Core/View';

import parseQueryString from '../../../shared/utils/url/parseQueryString';
import ProgressionForm from '../../components/ProgressionForm/ProgressionForm';

import Intro from './parts/SubscriptionIntro.js';
import PlanDurations from './parts/SubscriptionPlanDurations.js';
import PlanTypes from './parts/SubscriptionPlanTypes.js';
import LoginOrRegister from './parts/SubscriptionLoginOrRegister.js';
import AddressConfirmation from './parts/SubscriptionAddressConfirmation.js';
import PaymentMethods from './parts/SubscriptionPaymentMethods.js';
import Payment from './parts/SubscriptionPayment.js';
import ProgressBar from './components/ProgressBar/ProgressBar';

import './select-subscription.scss';

const relevantKeys = ['skipped_intro', 'plan_type', 'plan_id', 'fixed_plan_id', 'shipping_address_id', 'confirmed_address'];

export default class StartSubscription extends View {

	initialize(options) {
		this._handleFormUpdated = this._handleFormUpdated.bind(this);
		this.isLoggedIn = this.el.classList.contains('start-subscription--is-logged-in');

		this.progressBar = new ProgressBar({...options, el: this.find('.subscription-progress-bar')});

		this.form = new ProgressionForm({
			...options,
			el: this.el,
			sections: {
				'.subscription-intro': Intro,
				'.subscription-plan-types': PlanTypes,
				'.subscription-plan-durations': PlanDurations,
				'.subscription-login-or-register': LoginOrRegister,
				'.subscription-address-confirmation': AddressConfirmation,
				'.subscription-payment-methods': PaymentMethods,
				'.subscription-payment': Payment,
			},
		});
		this.form.updatedForced.add(this._handleFormUpdated);

		this.fillForm(parseQueryString(location.hash.substr(1)));

		this.form.forceUpdate();
	}

	destroy() {
		this.form.updatedForced.remove(this._handleFormUpdated);
		this.form.destroy();
	}

	_handleFormUpdated(state) {
		this.progressBar.setDoneIndex(this._getProgressIndexFromState(state));
		this._updateHash(state);
	}

	_getProgressIndexFromState(state) {
		if (state.skipped_intro !== '1') {
			return -1;
		}

		if (!state.plan_id || !state.plan_type) {
			return 0;
		}

		if (state.confirmed_address !== 'yes') {
			return 1;
		}

		return 2;
	}

	fillForm(data) {
		Object.keys(data).forEach(fieldName => {
			const inputs = this.findAll(`input[name="${fieldName}"]`);
			if (inputs.length === 0) {
				return;
			}

			inputs.forEach(input => {
				if (input.type === 'radio') {
					if (input.value === data[fieldName]) {
						input.checked = true;
					}
					return;
				}

				input.value = data[fieldName];
			});
		});
	}

	_updateHash(state) {
		if (this.isLoggedIn) {
			history.replaceState(null, null, '#');
			return;
		}

		const hash = relevantKeys
			.filter(key => !! state[key])
			.map(key => key + '=' + state[key])
			.join('&');

		history.replaceState(null, null, '#' + hash);
	}
}

const VIDEO_YOUTUBE_API_ID = 'youtube-id';

export default class YoutubePlayer {

	constructor(options) {
		this.videoId = options.id;
		this.el = options.el;
		this.apiReadySignal = options.apiReadySignal;
		this.signals = options.signals;

		this.callback = null;
		this.updateInterval = 0;
		this.readyFired = false;

		this.loadVideo = this.loadVideo.bind(this);
		this.handlePlayerStateChange = this.handlePlayerStateChange.bind(this);
	}

	destroy() {
		clearInterval(this.updateInterval);
	}

	reset() {
		this.updateInterval = 0;
		this.el.style.display = 'none';
	}

	stop() {
		if (typeof this.youtubePlayer === 'undefined') {
			return;
		}

		this.youtubePlayer.pauseVideo();
	}

	load(callback) {
		this.callback = callback;

		this.signals.loadStarted.dispatch();

		if (! document.getElementById(VIDEO_YOUTUBE_API_ID)) {
			const script = document.createElement('script');
			script.setAttribute('src', 'https://www.youtube.com/iframe_api');
			script.setAttribute('id', VIDEO_YOUTUBE_API_ID);
			document.body.appendChild(script);
		}

		if (typeof window.YT === 'undefined') {
			this.apiReadySignal.addOnce(this.loadVideo);
			return;
		}

		this.loadVideo();
	}

	loadVideo() {
		if (this.youtubePlayer) {
			this.el.style.display = 'block';
			this.youtubePlayer.seekTo(0);
			this.youtubePlayer.playVideo();
			return;
		}

		this.youtubePlayer = new YT.Player(this.el.getAttribute('id'), {
			width: 1200,
			height: 675,
			videoId: this.videoId,
			host: 'https://www.youtube-nocookie.com',
			playerVars: {
				autoplay: 0,
				controls: 0,
				rel: 0,
				showinfo: 0,
				modestbranding: 1,
				cc_load_policy: 0,
				iv_load_policy: 3,
				playsinline: 0,
			},
			events: {
				onReady: () => {
					if (this.readyFired) {
						return;
					}

					this.readyFired = true;

					this.callback();
					this.signals.loadCompleted.dispatch();
					this.updateInterval = setInterval(() => {
						this.signals.progress.dispatch();
					}, 100);

					this.youtubePlayer.playVideo();
				},
				onStateChange: this.handlePlayerStateChange,
				onError: (error) => {
					console.log(error);
				}
			}
		});
	}

	playPause() {
		switch (this.youtubePlayer.getPlayerState()) {
			case YT.PlayerState.ENDED:
			case YT.PlayerState.PAUSED:
				this.play();
				break;
			case YT.PlayerState.PLAYING:
				this.pause();
				break;
			default:
				console.log('unhandle state', playerState);
		}
	}

	play() {
		if (this.youtubePlayer) {
			this.readyFired = false;
			this.youtubePlayer.playVideo();
		}
	}

	pause() {
		if (this.youtubePlayer) {
			this.youtubePlayer.pauseVideo();
		}
	}

	seekTo(seconds, allowSeekAhead = false) {
		if (this.youtubePlayer) {
			this.youtubePlayer.seekTo(seconds, allowSeekAhead);
		}
	}

	setVolume(volume) {
		if (this.youtubePlayer) {
			this.youtubePlayer.setVolume(volume * 100);
		}
	}

	getVolume() {
		return this.youtubePlayer ? this.youtubePlayer.getVolume() / 100 : 0;
	}

	getDuration() {
		return this.youtubePlayer ? this.youtubePlayer.getDuration() : 0;
	}

	getCurrentTime() {
		return this.youtubePlayer ? this.youtubePlayer.getCurrentTime() : 0;
	}

	isPlaying() {
		return this.youtubePlayer.getPlayerState() === YT.PlayerState.PLAYING;
	}

	handlePlayerStateChange(state) {
		switch (state.data) {
			case - 1: // unstarted
				this.signals.paused.dispatch();
				break;
			case YT.PlayerState.ENDED:
				this.signals.ended.dispatch();
				break;
			case YT.PlayerState.PLAYING:
				this.signals.playbackStarted.dispatch();
				break;
			case YT.PlayerState.PAUSED:
				this.signals.paused.dispatch();
				break;
			case YT.PlayerState.BUFFERING:
				this.signals.buffering.dispatch();
				break;
		}
	}
}

import createRequest from 'superagent';
import AdyenCheckout from '@adyen/adyen-web';
import * as Sentry from '@sentry/browser';
import Signal from 'signals';

import View from '../../../shared/Core/View';

/**
 * This file is copied from node_modules as it otherwise fails on production builds.
 */
import './adyen.css';
import './adyen-drop-in.scss'

export default class AdyenDropIn extends View {

	initialize(options) {
		this.beforeSubmit = this.beforeSubmit.bind(this);

		const container = this.find('.adyen-drop-in__container');

		if (container) {
			this.initializeAdyen(container);
		}

		this.errorModal = options.modalDictionary['adyen-drop-in-payment-error'];

		this.methodSelected = new Signal();
	}

	destroy() {
		if (this.dropIn) {
			this.dropIn.remove();
		}
	}

	initializeAdyen(el) {
		const checkoutPromise = AdyenCheckout(this.compileAdyenConfiguration());
		checkoutPromise.then(checkout => {
			const dropIn = checkout.create('dropin', {
				openFirstPaymentMethod: this.el.dataset.singlePaymentMethod === 'true',
				onReady: () => this.dropIn = dropIn,
				onSelect: () => this.methodSelected.dispatch(),
			});

			dropIn.mount(el);

			this.dropIn = dropIn;
		});
	}

	deselectPaymentMethod() {
		this.dropIn.closeActivePaymentMethod();
	}

	compileAdyenConfiguration() {
		const data = this.el.dataset;

		return {
			environment: data.environment,
			clientKey: data.clientKey,
			locale: window.tonysConfig.locale || 'en-US',
			session: {
				id: data.sessionId,
				sessionData: data.sessionData,
			},
			onError: (error, component) => {
				Sentry.captureException(error, {
					extra: {
						context: 'AdyenDropIn',
						data: data,
					},
				});

				this.showErrorDialog(this.getErrorMessageByAdyenErrorType(error.name));
			},
			onPaymentCompleted: (result, component) => {
				/**
				 * TODO: Once stuff works, check whether we can do this using
				 * the default Routes to prevent a full reload.
				 */

				// Wait for the animation to complete
				setTimeout(() => {
					if (result.resultCode === 'Authorised' || result.resultCode === 'Received') {
						window.location = component.props.routes.confirm;
					} else if (result.resultCode === 'Refused') {
						window.location = component.props.routes.refused;
					}
				}, 1500);
			},
			beforeSubmit: this.beforeSubmit,
			analytics: {enabled: false},
			showPayButton: true,
			paymentMethodsConfiguration: {
				card: {
					hasHolderName: true,
					holderNameRequired: true,
				}
			},
		};
	}

	/**
	 * @param type 'NETWORK_ERROR' | 'CANCEL' | 'IMPLEMENTATION_ERROR' | 'ERROR'
	 **/
	getErrorMessageByAdyenErrorType(type) {
		switch (type) {
			case 'NETWORK_ERROR':
				return this.el.dataset.errorNetworkError;
			case 'CANCEL':
				return this.el.dataset.errorCancel;
			case 'IMPLEMENTATION_ERROR':
				return this.el.dataset.errorImplementationError;
			case 'ERROR':
			default:
				return this.el.dataset.errorGeneric;
		}
	}

	showErrorDialog(errorMessage) {
		const messageEl = this.find('.adyen-drop-in__payment-error', this.errorModal.el);
		messageEl.innerHTML = errorMessage;

		this.errorModal.show();
	}

	beforeSubmit(state, component, actions) {
		if (window.hcaptcha) {
			window.hcaptcha.execute(null, {async: true}).then(({ response, key }) => {
				this.afterBeforeSubmit(state, component, actions, response)
			}).catch(err => {
				console.error(err);
			});
		} else {
			this.afterBeforeSubmit(state, component, actions);
		}
	}

	afterBeforeSubmit(state, component, actions, captchaResponse) {
		this.signals.ecommerce.checkoutSubmitPaymentMethod.dispatch();

		const request = createRequest('PUT', this.el.dataset.beforeSubmitUrl);
		request.set('Accept', 'application/json');
		request.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
		request.set('X-Requested-With', 'XMLHttpRequest');
		request.send({
			'paymentMethod': state.paymentMethod,
			'h-captcha-response': captchaResponse || '',
		});

		return request
			.then(response => {
				const data = response.body;

				if (data.orderHash) {
					// Replace the fake payment session with the new one
					component.props.session.session = {
						id: data.session.id,
						sessionData: data.session.data
					}

					// Set the routes to be used upon completion
					component.props.routes = {
						confirm: data.routes.confirm,
						refused: data.routes.refused
					}

					actions.resolve({...state, sessionData: data.session.data});
				} else if (data.redirect) {
					component.setStatus('success');

					setTimeout(() => {
						window.location = data.redirect;
					}, 1500);
				} else {
					console.warn('[TONYS] Unhandled before submit response');
				}
			})
			.catch((error) => {
				Sentry.captureException(error, {
					extra: {
						context: 'AdyenDropIn',
						state: state,
					},
				});

				this.showErrorDialog(error);
				actions.reject();
			});
	}
}

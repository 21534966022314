import View from '../../../shared/Core/View';

import './giftcard-balance-check.scss';

export default class GiftCardBalanceCheck extends View {

	initialize() {
		this.handleFormSubmitted = this.handleFormSubmitted.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);

		this.submitButtonEl = this.find('.giftcard-balance-check__submit-button');
		this.resultEl = this.find('.giftcard-balance-check__result');
		this.resultCheckmarkEl = this.find('.giftcard-balance-check__result__checkmark');
		this.resultMessageEl = this.find('.giftcard-balance-check__result__message');
		this.inputEl = this.find('.giftcard-balance-check__input');
		this.resetEl = this.find('.giftcard-balance-check__reset');

		this.signals.forms.succeeded.add(this.handleFormSubmitted);

		if (this.el.classList.contains('giftcard-balance-check--auto-submit')) {
			const form = this.findViewForElement(this.find('form'));
			form.submit();
		}

		this.resetEl.addEventListener('click', this.handleResetClick);
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.handleFormSubmitted);
		this.resetEl.removeEventListener('click', this.handleResetClick);
	}

	handleResetClick(event) {
		event.preventDefault();

		this.reset();
	}

	handleFormSubmitted(formId, data) {
		if (formId !== 'giftcard-balance') {
			return;
		}

		if (! data.card) {
			return;
		}

		this.inputEl.readOnly = true;
		this.submitButtonEl.style.display = 'none';
		this.resultEl.style.display = 'flex';
		this.resultCheckmarkEl.style.display = data.card.valid ? 'block' : 'none';
		this.resultMessageEl.innerHTML = data.card.message;
		this.resetEl.style.display = 'block';
	}

	reset() {
		this.inputEl.readOnly = false;
		this.submitButtonEl.style.display = 'block';
		this.resultEl.style.display = 'none';
		this.resetEl.style.display = 'none';
	}
}

const GoogleTagmanagerEventType = {};

GoogleTagmanagerEventType.VIEW_ITEM_LIST = 'view_item_list';
GoogleTagmanagerEventType.VIEW_ITEM = 'view_item';
GoogleTagmanagerEventType.SELECT_ITEM = 'select_item';

// GoogleTagmanagerEventType.view_promotion = 'view_promotion';
// GoogleTagmanagerEventType.view_promotion = 'select_promotion';

GoogleTagmanagerEventType.ADD_TO_WISHLIST = 'add_to_wishlist';
GoogleTagmanagerEventType.ADD_TO_CART = 'add_to_cart';
GoogleTagmanagerEventType.REMOVE_FROM_CART = 'remove_from_cart';
GoogleTagmanagerEventType.VIEW_CART = 'view_cart';

GoogleTagmanagerEventType.BEGIN_CHECKOUT = 'begin_checkout';
GoogleTagmanagerEventType.ADD_PAYMENT_INFO = 'add_payment_info';
GoogleTagmanagerEventType.ADD_SHIPPING_INFO = 'add_shipping_info';

GoogleTagmanagerEventType.PURCHASE = 'purchase';
// GoogleTagmanagerEventType.refund = 'refund';

GoogleTagmanagerEventType.EARN_VIRTUAL_CURRENCY = 'earn_virtual_currency';
GoogleTagmanagerEventType.SHARE = 'share';
GoogleTagmanagerEventType.LOGIN = 'login';
GoogleTagmanagerEventType.SEARCH = 'search';

export default GoogleTagmanagerEventType;

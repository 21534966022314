import Signal from 'signals';
import View from 'Core/View.js';

import './navigation.scss';
import { TweenLite } from 'gsap/all';

export default class Navigation extends View {

	initialize() {

		this.setupUI();
		this.setupEventListeners();
		this.setupSignals();

		this.startHintAnimation();

	}

	destroy() {

		this.tl.kill();
		this.stopHintTimer();

		this.removeEventListeners();
		this.ui = null;

	}

	// Public
	highlightChapterWithId(id) {

		if(id !== this.activeId) {
			this.ui.menuButtons.forEach(el => el.dataset.chapterPath === id ? el.classList.add('is-selected') : el.classList.remove('is-selected'));
			this.activeId = id;
		}

	}

	updateScrollPosition(y) {

		if(this.hintShouldHide || !this.hintIsVisible) return;

		if(!this.scrollStartPosY) {
			this.scrollStartPosY = y;
		}

		if(Math.abs(y - this.scrollStartPosY) > 300) {
			this.stopHintTimer();
			this.hideHint();
		}
	}

	// Private
	setupUI() {

		this.ui = {
			menuButtons: [...this.el.querySelectorAll('.js-jaarfairslag-menu-button')],
			hint: this.el.querySelector('.js-hint')
		}

	}

	setupEventListeners() {

		this.hideHint = this.hideHint.bind(this);
		this.handleMenuButtonClicked = this.handleMenuButtonClicked.bind(this);
		this.handleTimelineComplete = this.handleTimelineComplete.bind(this);

		this.ui.menuButtons.forEach(el => el.addEventListener('click', this.handleMenuButtonClicked));

	}

	removeEventListeners() {

		this.ui.menuButtons.forEach(el => el.removeEventListener('click', this.handleMenuButtonClicked));

	}

	setupSignals() {

		this.signals = {...this.signals, navigationClicked: new Signal()};

	}

	startHintAnimation() {

		this.tl = TweenLite.from(this.ui.hint, .3, {scale: .5, opacity: 0, x: 50, ease: Back.easeOut, delay: 2.5, onComplete: this.handleTimelineComplete});

	}

	startHintTimer() {

		this.hintTimer = window.setTimeout(this.hideHint, 8000);

	}

	stopHintTimer() {

		if(this.hintTimer) {
			window.clearTimeout(this.hintTimer);
			this.hintTimer = null;
		}

	}

	hideHint() {

		if(this.ui.hint) {

			this.hintShouldHide = true;
			TweenLite.to(this.ui.hint, .4, {scale: .5, opacity: 0, x: 50, ease: Back.easeIn});

		}

	}

	handleTimelineComplete() {

		this.hintIsVisible = true;

		this.startHintTimer();

	}

	handleMenuButtonClicked(e) {

		e.preventDefault();

		const options = { chapterPath: e.currentTarget.dataset.chapterPath };
		this.signals.navigationClicked.dispatch(options);

	}

}



import ScrollService from './ScrollService';
import TweenLite from 'gsap/TweenLite';

class AnchorScrollService {

	constructor(options) {
		options.signals.pageLoad.requestIgnored.add(this.onRequestIgnored.bind(this));
		options.signals.pageLoad.requested.add(this.cancelDelayedScroll.bind(this));
		options.signals.pageContentReplaced.add(this.onContentReplaced.bind(this));

		this.urlService = options.urlService;

		// Wait for the page to fully load, so we know the position of the element
		if (options.routeAtLoad.hash) {
			window.addEventListener('load', () => {
				this.scrollToAnchorIfNeeded(options.routeAtLoad);
			});
		}
	}

	scrollToAnchorIfNeeded(route) {
		if (! route.hash) {
			return;
		}

		/**
		 * Disabled for now as we don't know for sure if this breaks
		 * things in other places.
		 */
		// if (route.hash.indexOf('/') > 0) {
		// 	this.scrollToAnchorIfNeeded({
		// 		...route,
		// 		hash: route.hash.split('/').shift(),
		// 	});
		// 	return;
		// }

		const el = this.findElementById(route.hash);
		if (! el) {
			return;
		}

		if (el.classList.contains('widget-area-wrapper--hidden')) {
			this.expandHiddenArea(el);
		} else if (el.classList.contains('widget-area-wrapper--expanded')) {
			const routeWithoutHash = Object.assign({}, route, {
				hash: '',
				url: route.url.split('#')[0],
			});

			this.urlService.updateURL(routeWithoutHash, {replace: true});

			this.collapseHiddenArea(el);
		}

		ScrollService.scrollPageToElement(el, 32);
	}

	findElementById(id) {

		// First check if we can find it by id
		let el = document.getElementById(id);
		if (el) {
			return el;
		}

		// Then try to find a named anchor
		return document.querySelector(`a[name="${id}"]`);
	}

	cancelDelayedScroll() {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
	}

	onRequestIgnored(route) {
		this.scrollToAnchorIfNeeded(route);
	}

	onContentReplaced(el, route) {
		this.timeout = setTimeout(() => {
			this.scrollToAnchorIfNeeded(route);
		}, 500);
	}

	expandHiddenArea(area) {
		area.classList.remove('widget-area-wrapper--hidden');
		area.classList.add('widget-area-wrapper--expanded');

		TweenLite.set(area, {height: 'auto'});

		const height = area.clientHeight;

		TweenLite.set(area, {height: 0, overflow: 'hidden'});
		TweenLite.to(area, 0.6, {
			delay: 0.3,
			height: height,
			ease: Cubic.easeInOut,
			onComplete: () => {
				TweenLite.set(area, {height: 'auto', overflow: 'unset'});
			},
		});
	}

	collapseHiddenArea(area) {
		area.classList.remove('widget-area-wrapper--expanded');

		TweenLite.to(area, 0.6, {
			delay: 0.3,
			height: 0,
			ease: Cubic.easeInOut,
			onComplete: () => {
				TweenLite.set(area, {overflow: 'hidden'});
				area.classList.add('widget-area-wrapper--hidden');
			},
		});
	}
}

export default AnchorScrollService;

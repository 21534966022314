import Signal from 'signals';

export default {

	/**
	 * Request a lock
	 * @param {string} lockId
	 */
	lockRequested: new Signal(),

	/**
	 * The page is about to be locked
	 */
	lockingStarted: new Signal(),

	/**
	 * The page content is locked and cannot be scrolled
	 */
	locked: new Signal(),

	/**
	 * Request an unlock
	 * @param {string} lockId
	 */
	unlockRequested: new Signal(),

	/**
	 * The page is about to be unlocked
	 */
	unlockingStarted: new Signal(),

	/**
	 * The page content is unlocked and scrolling can be resumed
	 */
	unlocked: new Signal(),
};

import TweenLite from 'gsap/TweenLite';
import { Sine } from 'gsap';
import Signal from 'signals';

import ScrollService from '../../../services/ScrollService';
import formDataToObject from '../../../shared/utils/form/formDataToObject';

import DefaultPage from '../DefaultPage/DefaultPage.js';
import ProductDetailImageGallery from './components/ProductDetailImageGallery';
import ProductDetailImageView from './components/ProductDetailImageView';
import ProductDetailBottomBackground from './components/ProductDetailBottomBackground';

import './product-detail.scss';
import './components/product-detail-price.scss';
import './components/product-detail-usps.scss';
import './components/product-detail-information.scss';
import './components/product-detail-impact.scss';
import './components/product-detail-carousel.scss';

export default class ProductDetail extends DefaultPage {
	initialize(options) {
		this.handleFormInputChanged = this.handleFormInputChanged.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);

		this.imageSelected = new Signal(); // @param: image element
		this.imageDeselected = new Signal();
		this.imageUpdated = new Signal();

		const imageGalleryEl = this.find('.product-detail-image-gallery');
		const imageViewEl = this.find('.product-detail-image-view');

		this.form = this.find('.product-detail__form');
		this.form.addEventListener('submit', this.handleFormSubmit);

		this.formInputs = this.findAll('input,textarea,select', this.form);
		if (this.formInputs.length > 0) {
			this.formInputs.forEach(el => el.addEventListener('change', this.handleFormInputChanged));
			this.formInputs.forEach(el => el.addEventListener('keyup', this.handleFormInputChanged));
			this.handleFormInputChanged();
		}

		// Components
		if (imageGalleryEl) {
			this.imageGallery = new ProductDetailImageGallery({
				...options,
				el: imageGalleryEl,
				imageSelectedSignal: this.imageSelected,
			});
		}

		if (imageViewEl) {
			this.imageView = new ProductDetailImageView({
				...options,
				el: imageViewEl,
				imageDeselectedSignal: this.imageDeselected,
				imageUpdatedSignal: this.imageUpdated,
			});
		}

		const bottomBackgroundEl = document.querySelector('.product-detail-bottom-background');
		if (bottomBackgroundEl) {
			this.productDetailBottomBackground = new ProductDetailBottomBackground({
				...options,
				el: bottomBackgroundEl,
			});
		}

		// Elements
		this.addToBasketButton = this.find('.product-detail__product-content__add-to-basket-button');
		this.addToBasketButtonContainer = this.find('.product-detail__add-to-basket-button-container');
		this.addToBasketButtonMobileContainer = this.find('.product-detail__add-to-basket-button-container-mobile');
		this.productMainContent = this.find('.product-detail__main');

		//
		this.signals.ecommerce.productDetailPageViewed.dispatch({
			id: this.el.dataset.sku,
			price: this.el.dataset.price,
			priceOriginal: this.el.dataset.priceOriginal,
			name: this.find('.product-detail__title').innerText,
			category: this.el.dataset.category || null,
		});

		//
		this.onImageSelected = this.onImageSelected.bind(this);
		this.onImageDeselected = this.onImageDeselected.bind(this);
		this.onImageUpdated = this.onImageUpdated.bind(this);
		this.onResize = this.onResize.bind(this);

		super.initialize();

		this.imageSelected.add(this.onImageSelected);
		this.imageDeselected.add(this.onImageDeselected);
		this.imageUpdated.add(this.onImageUpdated);

		window.addEventListener('resize', this.onResize);

		this.onResize();
	}

	destroy() {
		this.imageSelected.remove(this.onImageSelected);
		this.imageDeselected.remove(this.onImageDeselected);
		this.imageUpdated.remove(this.onImageUpdated);

		window.removeEventListener('resize', this.onResize);
		this.form.removeEventListener('submit', this.handleFormSubmit);

		if (this.formInputs.length > 0) {
			this.formInputs.forEach(el => el.removeEventListener('change', this.handleFormInputChanged));
			this.formInputs.forEach(el => el.removeEventListener('keyup', this.handleFormInputChanged));
		}

		this.imageGallery && this.imageGallery.destroy();
		this.imageView && this.imageView.destroy();
		this.productDetailBottomBackground && this.productDetailBottomBackground.destroy();
	}

	handleFormInputChanged() {
		const dataAsObject = formDataToObject(new FormData(this.form));
		const quantity = parseInt(dataAsObject.quantity, 10);

		const data = {
			customer_group_id: parseInt(this.el.dataset.customerGroup),
			product_id: parseInt(this.el.dataset.id),
			...dataAsObject,
			quantity: quantity >= 1 ? quantity : 1,
		};

		const hash = this.findHashForData(data);
		if (hash) {
			data.hash = hash;
		}

		this.signals.products.addToBasketDataUpdated.dispatch(data);
	}

	findHashForData(data) {
		for (const key in data) {
			const input = this.find(`[name="${key}"]`);
			if (!input || input.value !== data[key]) {
				continue;
			}

			if (input.tagName === 'SELECT') {
				const option = input.querySelector(`[value="${data[key]}"]`);
				if (!option) {
					continue;
				}

				return option.dataset.hash;
			}

			return input.dataset.hash;
		}

		return undefined;
	}

	handleFormSubmit(event) {
		event.preventDefault();
	}

	onImageSelected(url) {
		this.imageView.load(url);

		// Scroll up if image top sticks out of top of window
		if (this.imageView.el.getBoundingClientRect().top < 0) {
			ScrollService.scrollPageToElement(this.imageView.el, 225);
		}

		this.productMainContent.classList.remove('product-detail__main--hidden');
		this.productMainContent.classList.add('product-detail__main--hidden');

		TweenLite.killTweensOf(this.productMainContent);
		this.onImageUpdated();
	}

	onImageDeselected() {
		this.productMainContent.classList.remove('product-detail__main--hidden');

		this.imageView.hide();

		TweenLite.killTweensOf(this.productMainContent);
		TweenLite.to(this.productMainContent, 0.15, { opacity: 1 });
		TweenLite.to(this.productMainContent, 0.3, { height: 'auto', ease: Sine.easeInOut });
	}

	onImageUpdated() {
		TweenLite.killTweensOf(this.productMainContent);
		TweenLite.to(this.productMainContent, 0.15, { opacity: 0 });
		TweenLite.to(this.productMainContent, 0.3, { height: this.imageView.height, ease: Sine.easeInOut });
	}

	onResize() {
		if (this.addToBasketButton) {
			this.addToBasketButton.parentElement.removeChild(this.addToBasketButton);
			if (window.innerWidth < 768) {
				this.addToBasketButtonMobileContainer.append(this.addToBasketButton);
			} else {
				this.addToBasketButtonContainer.append(this.addToBasketButton);
			}
		}
	}
}

import './filter-slider.scss';

import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';
import Signal from 'signals';

import CurrencyFormatter from 'utils/currency/CurrencyFormatter';

const BASE_MIN_HEIGHT = 4;
const BASE_MAX_HEIGHT = 10;
const SLIDER_DOT_SIZE = 20;
const SLIDER_DOT_PADDING = -20; // minimal distance between dots
const SLIDER_TEXT_PADDING = 30; // minimal distance between amount texts
const SLIDER_DOT_DRAGGING_CLASS = 'filter-slider__dot--dragging';

export default class FilterSlider extends View {

	/**
	 *
	 * @param options
	 */
	initialize(options) {

		this.roundValues = options.roundValues;

		this.bodyElement = document.body;
		this.changeSignal = new Signal();

		this._minHeight = BASE_MIN_HEIGHT;
		this._maxHeight = BASE_MAX_HEIGHT;
		this._baseDotScale = 1;

		this.barWrapper = this.find('.filter-slider__bar-wrapper ');
		this.backBar = this.find('.filter-slider__bar__back');
		this.frontBar = this.find('.filter-slider__bar__front');
		this.minDot = this.find('.filter-slider__dot--min');
		this.minDotSVG = this.find('.filter-slider__dot--min .svg-embed');
		this.minDotText = this.find('.filter-slider__dot--min .filter-slider__dot__value');
		this.maxDot = this.find('.filter-slider__dot--max');
		this.maxDotSVG = this.find('.filter-slider__dot--max .svg-embed');
		this.maxDotText = this.find('.filter-slider__dot--max .filter-slider__dot__value');

		this.minDotValue = this.find('.filter-slider__dot--min .filter-slider__dot__value');
		this.maxDotValue = this.find('.filter-slider__dot--max .filter-slider__dot__value');

		this.updateSliderRange(options.minValue, options.maxValue);

		this.reset();

		this.onBarWrapperClick = this.onBarWrapperClick.bind(this);
		this.onScrubberStart = this.onScrubberStart.bind(this);
		this.onScrubberDrag = this.onScrubberDrag.bind(this);
		this.onScrubberEnd = this.onScrubberEnd.bind(this);
		this.update = this.update.bind(this);
		this.resize = this.resize.bind(this);

		this.barWrapper.addEventListener('click', this.onBarWrapperClick);
		this.minDot.addEventListener('mousedown', this.onScrubberStart);
		this.minDot.addEventListener('touchstart', this.onScrubberStart);
		this.maxDot.addEventListener('mousedown', this.onScrubberStart);
		this.maxDot.addEventListener('touchstart', this.onScrubberStart);

		window.addEventListener('resize', this.resize);

		this.resize();
	}

	/**
	 *
	 * @param minValue
	 * @param maxValue
	 */
	updateSliderRange(minValue, maxValue) {
		this.minValue = parseFloat('' + minValue);
		this.maxValue = parseFloat('' + maxValue);

		this.reset();
	}

	reset() {
		this.minX = 0;
		this.maxX = this.maxWidth;
		this.currentWidth = this.maxWidth;

		this.update(false);
	}

	onBarWrapperClick(event) {
		if(!this.draggingScrubber) {
			const px = event.touches && event.touches[0] ? event.touches[0].offsetX : event.offsetX;

			const minDifX = Math.abs(this.minX - px);
			const maxDifX = Math.abs(this.maxX - px);

			// On min left side
			if (px < this.minX) {
				this.minX = px;
				this.update()
			// On max right side
			} else if (px > this.maxX) {
				this.maxX = px;
				this.update()
			// In between, closer to min dot
			} else if(minDifX <= maxDifX) {
				this.minX = px;
				this.update()
			// In between, closer to max dot
			} else if(maxDifX < minDifX) {
				this.maxX = px;
				this.update()
			}
		}
	}

	/**
	 * Interaction functions
	 */
	onScrubberStart(event) {
		event.preventDefault();
		event.stopPropagation();

		this.draggingScrubber = true;
		this.draggingTarget = event.currentTarget;

		this.currentX = event.touches && event.touches[0] ? event.touches[0].pageX : event.pageX;
		this.currentWidth = this.maxWidth;

		this.bodyElement.addEventListener('mousemove', this.onScrubberDrag);
		this.bodyElement.addEventListener('touchmove', this.onScrubberDrag);
		window.addEventListener('mouseup', this.onScrubberEnd);
		window.addEventListener('touchend', this.onScrubberEnd);
	}

	/**
	 *
	 * @param event
	 */
	onScrubberDrag(event) {
		event.preventDefault();
		event.stopPropagation();

		const newX = event.touches && event.touches[0] ? event.touches[0].pageX : event.pageX;
		const dx = newX - this.currentX;
		this.currentX = newX;

		if(this.draggingTarget === this.minDot) {
			this.minX += dx;
			this.draggingTarget.classList.add(SLIDER_DOT_DRAGGING_CLASS);
		}

		if(this.draggingTarget === this.maxDot) {
			this.maxX += dx;
			this.draggingTarget.classList.add(SLIDER_DOT_DRAGGING_CLASS);
		}

		this.update();
	}

	/**
	 *
	 * @param event
	 */
	onScrubberEnd(event) {
		event.preventDefault();
		event.stopPropagation();

		this.bodyElement.removeEventListener('mousemove', this.onScrubberDrag);
		this.bodyElement.removeEventListener('mouseup', this.onScrubberEnd);
		this.bodyElement.removeEventListener('touchmove', this.onScrubberDrag);
		this.bodyElement.removeEventListener('touchend', this.onScrubberEnd);
		window.removeEventListener('mouseup', this.onScrubberEnd);
		window.removeEventListener('touchend', this.onScrubberEnd);

		if(this.draggingTarget)
		{
			this.draggingTarget.classList.remove(SLIDER_DOT_DRAGGING_CLASS);
		}

		// Small delay to prevent wrapper click event
		setTimeout(() => {
			this.draggingScrubber = false;
		}, 100);
	}

	/**
	 *
	 */
	resize() {
		const oldWidth = this.currentWidth;
		this.currentWidth = this.maxWidth;

		this.maxX = (this.maxX / oldWidth) * this.currentWidth;
		this.minX = (this.minX / oldWidth) * this.currentWidth;

		this.baseDotScale = this.screen.breakpoint === 'mobile' ? 1.25 : 1;
		this.minHeight = this.screen.breakpoint === 'mobile' ? BASE_MIN_HEIGHT * 1.25 : BASE_MIN_HEIGHT;
		this.maxHeight = this.screen.breakpoint === 'mobile' ? BASE_MAX_HEIGHT * 1.25 : BASE_MAX_HEIGHT;

		TweenLite.set(this.minDotSVG, {y: this.screen.breakpoint === 'mobile' ? 2 : 4});
		TweenLite.set(this.maxDotSVG, {y: this.screen.breakpoint === 'mobile' ? 2 : 4});

		this.update(false);
	}

	/**
	 *
	 */
	update(triggerChange = true) {
		// Update min
		if(this.draggingTarget !== this.maxDot) {
			this.minX = Math.max(this.minX, 0);
			this.minX = Math.min(this.minX, this.maxX - SLIDER_DOT_SIZE - SLIDER_DOT_PADDING);
			TweenLite.set(this.minDot.querySelector('svg'), {rotation: 1080 * (this.minX / this.maxWidth)});
		}

		// Update max
		if(this.draggingTarget !== this.minDot) {
			this.maxX = Math.max(this.maxX, this.minX + SLIDER_DOT_SIZE + SLIDER_DOT_PADDING);
			this.maxX = Math.min(this.maxX, this.maxWidth);
			TweenLite.set(this.maxDot.querySelector('svg'), {rotation: 1080 * (this.maxX / this.maxWidth)});
		}

		// Make sure value/text is visible and not overlapping. Push to sides if necessary
		const dotDistance = Math.min(this.maxX - this.minX - SLIDER_TEXT_PADDING - SLIDER_DOT_SIZE, 0);
		const horizontalOffset = Math.abs(dotDistance * 0.5);
		TweenLite.set(this.minDotText, {marginLeft:-horizontalOffset});
		TweenLite.set(this.maxDotText, {marginLeft:horizontalOffset});

		//
		const minPercentage = this.minX / this.maxWidth;
		const maxPercentage = this.maxX / this.maxWidth;

		const range = this.maxValue - this.minValue;

		const valueMin = Math.round(this.minValue + range * minPercentage);
		const valueMax = Math.round(this.minValue + range * maxPercentage);
		this.valueMin = valueMin;
		this.valueMax = valueMax;

		this.minDotValue.innerHTML = CurrencyFormatter.format(valueMin, false, false, true);
		this.maxDotValue.innerHTML = CurrencyFormatter.format(valueMax, false, false, true);

		TweenLite.set(this.minDot, {x:this.minX});
		TweenLite.set(this.maxDot, {x:this.maxX});
		TweenLite.set(this.minDotSVG, {scale:this.baseDotScale + minPercentage * 0.2});
		TweenLite.set(this.maxDotSVG, {scale:this.baseDotScale + maxPercentage * 0.2});

		const minps = (minPercentage * 100) + '%';
		const maxps = (maxPercentage * 100) + '%';

		const minHeight = (this._minHeight + (this.maxHeight - this.minHeight) * minPercentage) / 2;
		const maxHeight = (this._minHeight + (this.maxHeight - this.minHeight) * maxPercentage) / 2;

		const clipPath = 'polygon(' + minps + ' calc(50% - ' + minHeight + 'px), ' + maxps + ' calc(50% - ' + maxHeight + 'px), ' + maxps + ' calc(50% + ' + maxHeight + 'px), ' + minps + ' calc(50% + ' + minHeight + 'px))';
		this.frontBar.style['-webkit-clip-path'] = this.frontBar.style['clip-path'] = clipPath;

		const clipPathBack = 'polygon(0% calc(50% - ' + (this.minHeight/2) + 'px), 100% calc(50% - ' + (this.maxHeight / 1) + 'px), 100% calc(50% + ' + (this.maxHeight / 2) + 'px), 0%  calc(50% + ' + (this.minHeight / 2) + 'px))';
		this.backBar.style['-webkit-clip-path'] = this.backBar.style['clip-path'] = clipPathBack;

		this.barWrapper.style.height = this.maxHeight + 'px';
		TweenLite.set(this.backBar, {y: (BASE_MAX_HEIGHT - this.maxHeight) / 2});
		TweenLite.set(this.frontBar, {y: (BASE_MAX_HEIGHT - this.maxHeight) / 2});

		if(triggerChange) {
			this.changeSignal.dispatch(this.valueMin, this.valueMax);
		}
	}

	get baseDotScale() {
		return this._baseDotScale;
	}

	set baseDotScale(value) {
		this._baseDotScale = value;
	}

	get minHeight() {
		return this._minHeight;
	}

	set minHeight(value) {
		this._minHeight = value;
	}

	get maxHeight() {
		return this._maxHeight;
	}

	set maxHeight(value) {
		this._maxHeight = value;
	}

	/**
	 *
	 * @return {*}
	 */
	get maxWidth() {
		return this.backBar.clientWidth - SLIDER_DOT_SIZE;
	}

	/**
	 *
	 */
	destroy() {
		this.bodyElement.removeEventListener('mousemove', this.onScrubberDrag);
		this.bodyElement.removeEventListener('mouseup', this.onScrubberEnd);

		this.bodyElement.removeEventListener('touchmove', this.onScrubberDrag);
		this.bodyElement.removeEventListener('touchend', this.onScrubberEnd);

		window.removeEventListener('mouseup', this.onScrubberEnd);
		window.removeEventListener('touchend', this.onScrubberEnd);

		window.removeEventListener('resize', this.resize);
	}
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LatLng from '../../../shared/shapes/LatLng';

import GoogleMap from '../GoogleMap/GoogleMap';
import Marker from '../GoogleMap/shapes/Marker';
import MarkerId from '../GoogleMap/shapes/MarkerId';

import MapControls from './components/MapControls/MapControls';

import './controlled-map.scss';

class ControlledMap extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			zoom: props.initialZoom,
			center: props.initialCenter,

			fetchingUserLocation: false,
			failedToFetchUserLocation: false,
			userLocation: null,
		};

		this.zoomIn = this.zoomIn.bind(this);
		this.zoomOut = this.zoomOut.bind(this);
		this.fetchCurrentLocation = this.fetchCurrentLocation.bind(this);

		this.handleZoomChanged = this.handleZoomChanged.bind(this);
		this.handleCenterChanged = this.handleCenterChanged.bind(this);
		this.handleGeoLocationFetched = this.handleGeoLocationFetched.bind(this);
		this.handleGeoLocationFailed = this.handleGeoLocationFailed.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.initialCenter.lat !== nextProps.initialCenter.lat ||
			this.props.initialCenter.lng !== nextProps.initialCenter.lng) {
			this.setState({center: nextProps.initialCenter});
		}

		if (this.props.initialZoom !== nextProps.initialZoom) {
			this.setState({zoom: nextProps.initialZoom});
		}
	}

	render() {
		const classes = classNames({
			'controlled-map': true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<div className={classes}>
				<GoogleMap
					apiKey={this.props.apiKey}
					markers={this.props.markers}
					height={this.props.height}
					zoom={this.props.fixedZoom ? this.props.fixedZoom : this.state.zoom}
					center={this.props.fixedCenter ? this.props.fixedCenter : this.state.center}
					minZoom={this.props.minZoom}
					maxZoom={this.props.maxZoom}
					locationOfUser={this.state.userLocation}
					currentMarkerId={this.props.currentMarkerId}
					fitBoundsToMarkers={this.props.fitBoundsToMarkers}

					getMarkerIcon={this.props.getMarkerIcon}
					getMarkerIconForCurrent={this.props.getMarkerIconForCurrent}
					getMarkerIconForUser={this.props.getMarkerIconForUser}
					getClustererOptions={this.props.getClustererOptions}

					onMapReady={this.props.onMapReady}
					onMarkerSelected={this.props.onMarkerSelected}
					onMarkersReplaced={this.props.onMarkersReplaced}
					onZoomChanged={this.handleZoomChanged}
					onCenterChanged={this.handleCenterChanged}
				/>
				{this.renderControlsIfNotDisabled()}
			</div>
		);
	}

	renderControlsIfNotDisabled() {
		if (this.props.hideControls) {
			return null;
		}

		return (
			<MapControls
				className="controlled-map__controls"
				zoomInDisabled={this.props.maxZoom <= this.state.zoom}
				zoomOutDisabled={this.props.minZoom >= this.state.zoom}
				hideZoomControls={this.props.hideZoomControls}
				hideUserLocationControls={this.props.hideUserLocationControls}

				fetchUserLocationDisabled={this.state.fetchingUserLocation || this.state.failedToFetchUserLocation}
				fetchingUserLocation={this.state.fetchingUserLocation}

				onZoomInClick={this.zoomIn}
				onZoomOutClick={this.zoomOut}
				onFetchLocationClick={this.fetchCurrentLocation}
			/>
		);
	}

	zoomIn() {
		this.setState({zoom: Math.min(this.props.maxZoom, this.state.zoom + 1)});
	}

	zoomOut() {
		this.setState({zoom: Math.max(this.props.minZoom, this.state.zoom - 1)});
	}

	fetchCurrentLocation() {
		if (this.state.fetchingUserLocation) {
			return;
		}

		this.setState({fetchingUserLocation: true});

		navigator.geolocation.getCurrentPosition(this.handleGeoLocationFetched, this.handleGeoLocationFailed);
	}

	handleZoomChanged(zoom) {
		if (! this.props.fixedZoom) {
			this.setState({zoom: zoom});
		}

		if (this.props.onZoomChanged) {
			this.props.onZoomChanged(zoom);
		}
	}

	handleCenterChanged(center) {
		if (! this.props.fixedCenter) {
			this.setState({center: center});
		}

		if (this.props.onCenterChanged) {
			this.props.onCenterChanged(center);
		}
	}

	handleGeoLocationFetched(position) {
		const userLocation = {
			lat: position.coords.latitude,
			lng: position.coords.longitude
		};

		this.setState({
			fetchingUserLocation: false,
			userLocation: userLocation
		});

		if (this.props.onUserLocationDetected) {
			this.props.onUserLocationDetected(userLocation);
		}
	}

	handleGeoLocationFailed(error) {
		this.setState({fetchingUserLocation: false, failedToFetchUserLocation: true});

		if (this.props.onUserLocationBlocked) {
			this.props.onUserLocationBlocked(error);
		}
	}
}

ControlledMap.propTypes = {
	className: PropTypes.string,
	apiKey: PropTypes.string.isRequired,

	markers: PropTypes.objectOf(Marker),
	currentMarkerId: MarkerId,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	hideControls: PropTypes.bool,
	hideZoomControls: PropTypes.bool,
	hideUserLocationControls: PropTypes.bool,
	fitBoundsToMarkers: PropTypes.bool,

	initialZoom: PropTypes.number.isRequired,
	initialCenter: LatLng.isRequired,

	fixedZoom: PropTypes.number,
	fixedCenter: LatLng,

	minZoom: PropTypes.number,
	maxZoom: PropTypes.number,

	onCenterChanged: PropTypes.func,
	onZoomChanged: PropTypes.func,
	onMarkerSelected: PropTypes.func,
	onUserLocationDetected: PropTypes.func,
	onUserLocationBlocked: PropTypes.func,
	onMapReady: PropTypes.func,
	onMarkersReplaced: PropTypes.func,

	getMarkerIcon: PropTypes.func.isRequired,
	// Current location gets same icon as default when not provided
	getMarkerIconForCurrent: PropTypes.func,
	// Disable current user when not provided
	getMarkerIconForUser: PropTypes.func,
	// Clustering is disabled if no options are provided
	getClustererOptions: PropTypes.func,
};

export default ControlledMap;

import View from '../../../shared/Core/View';
import ScrollService from '../../../services/ScrollService';

import './shop-search-field.scss';

const HAS_CONTENT_CLASS = 'shop-search-field--has-content';

export default class ShopSearchField extends View {

    initialize() {
		this.checkSearchInput = this.checkSearchInput.bind(this);
		this.onSearchSubmit = this.onSearchSubmit.bind(this);
		this.onSearchInputChanged = this.onSearchInputChanged.bind(this);
		this.onSearchIconClick = this.onSearchIconClick.bind(this);
		this.handleSearchToggled = this.handleSearchToggled.bind(this);

		this.searchForm = this.find('.shop-search-field__form');
		this.searchInput = this.find('.shop-search-field__input');
		this.searchField = this.find('.shop-search-field__search-field');
		this.searchGhostButton = this.find('.shop-search-field__icon-ghost-button');

		this.searchForm.addEventListener('submit', this.onSearchSubmit);

		this.searchInput.addEventListener('blur', this.checkSearchInput);
		this.searchInput.addEventListener('input', this.onSearchInputChanged);
		this.searchInput.addEventListener('keyup', this.onSearchInputChanged);

		this.searchField.addEventListener('mousedown', this.checkSearchInput);
		this.searchGhostButton.addEventListener('mousedown', this.onSearchIconClick);

		this.signals.shop.searchOpen.add(this.handleSearchToggled);

		this.checkSearchInput();
    }

    destroy() {
		this.searchForm.removeEventListener('submit', this.onSearchSubmit);

		this.searchInput.removeEventListener('blur', this.checkSearchInput);
		this.searchInput.removeEventListener('input', this.onSearchInputChanged);
		this.searchInput.removeEventListener('keyup', this.onSearchInputChanged);

		this.searchField.removeEventListener('mousedown', this.checkSearchInput);
		this.searchGhostButton.removeEventListener('mousedown', this.onSearchIconClick);

		this.signals.shop.searchOpen.remove(this.handleSearchToggled);
    }

	checkSearchInput(event = null) {
		if (event && event.target === this.searchField) {
			if (document.activeElement !== this.searchInput) {
				// Focus
				event.preventDefault();

				const end = this.searchInput.value.length
				this.searchInput.setSelectionRange(end, end)
				this.searchInput.focus();
			}
		}

		if (this.searchInput && this.searchInput.value !== '') {
			this.el.classList.add(HAS_CONTENT_CLASS);
		} else {
			this.el.classList.remove(HAS_CONTENT_CLASS);
		}
	}

	onSearchSubmit(event) {
		if (event) {
			event.preventDefault();
		}

		const searchQuery = ('' + this.searchInput.value).trim();

		// Only execute if we have a query
		if (searchQuery.length > 0) {
			const url = this.routes.getPath('shop.search', {
				query: searchQuery,
				category: this.el.dataset.categorySlug,
			});
			const route = this.routes.getRoute(url);
			this.signals.pageLoad.requested.dispatch(route);
		}
	}

	onSearchInputChanged() {
		this.checkSearchInput();
		this.onSearchSubmit();
	}

	onSearchIconClick() {
		if (! this.el.classList.contains('shop-search-field--has-content')) {
			return;
		}

		this.searchInput.value = '';

		this.signals.shop.searchQuit.dispatch();
	}

	handleSearchToggled() {
		if (!this.searchInput) {
			return;
		}

		ScrollService.scrollPageTo(0, 0.3);

		this.searchInput.focus();
	}
}

import View from 'Core/View.js';

import './product-detail-highlight.scss';

/**
 *         'detail_highlight_background_mobile',
 *         'detail_highlight_background_tablet',
 *         'detail_highlight_background_desktop',
 *         'detail_highlight_background_color',
 *         'detail_highlight_font_color',
 */
export default class ProductDetailHighlight extends View {

	initialize(options) {

		this.resize = this.resize.bind(this);

		this.hasHighlight = this.el.dataset.highlight.trim().length > 0;

		if(this.hasHighlight) {
			// Fade out background animation element
			this.signals.backgroundAnimation.hide.dispatch();

			this.data = JSON.parse(this.el.dataset.highlight);

			this.mainContainer = this.el;
			this.productDetailContainer = this.find('.product-detail__product-detail')
			this.wrapperContainer = document.querySelector('#top.wrapper');
			this.siteWidthContainer = document.querySelector('.container.site-width ');

			this.wrapperContainer.style.backgroundColor = this.data.detail_highlight_background_color;
			this.productDetailContainer.style.color = this.data.detail_highlight_font_color;

			window.addEventListener('resize', this.resize);
			this.resize();
		}
	}

	destroy() {

		if(this.hasHighlight) {
			// Fade in background animation element
			this.signals.backgroundAnimation.show.dispatch();

			// Reset all styles
			this.productDetailContainer.style.color = null;
			this.wrapperContainer.style.backgroundColor = null;
			this.wrapperContainer.style.backgroundImage = null;
			this.wrapperContainer.style.backgroundSize = null;
			this.wrapperContainer.style.backgroundPosition = null;
			this.wrapperContainer.style.backgroundRepeat = null;
			this.wrapperContainer.removeAttribute('aria-label');
			this.wrapperContainer.removeAttribute('role');

			window.removeEventListener('resize', this.resize);
		}
	}

	resize() {
		let backgroundGraphic = 'detail_highlight_background_desktop';

		backgroundGraphic = window.innerWidth < 1024 ? 'detail_highlight_background_tablet' : backgroundGraphic;
		backgroundGraphic = window.innerWidth < 768 ? 'detail_highlight_background_mobile' : backgroundGraphic;

		let alternativeText = backgroundGraphic + '_alt';

		if(this.data[backgroundGraphic]) {
			// Apply styles
			const url = this.data[backgroundGraphic];
			this.wrapperContainer.style.backgroundImage = `url('${url}')`;
			this.wrapperContainer.style.backgroundSize = 'contain';
			this.wrapperContainer.style.backgroundPosition = 'center top';
			this.wrapperContainer.style.backgroundRepeat = 'no-repeat';

			this.wrapperContainer.setAttribute('role', 'img')
			if (this.data[alternativeText]) {
				this.wrapperContainer.setAttribute('aria-label', this.data[alternativeText]);
			}
		}
	}
}

import Signal from 'signals';

export default {

	/**
	 * @param {{
	 *   id          text      The product ID or SKU (e.g. P67890). *Either this field or name must be set.
	 *   name        text      The name of the product (e.g. Android T-Shirt). *Either this field or id must be set.
	 *   list        text      The list or collection to which the product belongs (e.g. Search Results)
	 *   brand       text      The brand associated with the product (e.g. Google).
	 *   category    text      The category to which the product belongs (e.g. Apparel). Use / as a delimiter to specify up to 5-levels of hierarchy (e.g. Apparel/Men/T-Shirts).
	 *   variant     text      The variant of the product (e.g. Black).
	 *   position    integer   The product's position in a list or collection (e.g. 2).
	 *   price       currency  The price of a product (e.g. 29.20).
	 * }}
	 */
	productImpression: new Signal(),

	/**
	 *
	 */
	productListPageViewed: new Signal(),

	/**
	 *
	 */
	productSelected: new Signal(),

	/**
	 * @param {{
	 *   id     {string}
	 *   name   {string}
	 * }}
	 */
	productDetailPageViewed: new Signal(),

	/**
	 * @param items {Array<{item:Object, quantity: number}>}
	 */
	productAddedToCart: new Signal(),

	/**
	 * @param items {Array<{item:Object, quantity: number}>}
	 */
	productRemovedFromCart: new Signal(),

	/**
	 * @param {{<key>: {
	 * 		amount
	 * 		discount
	 * 		id
	 * 		image
	 * 		logisticPartnerId
	 * 		name
	 * 		options
	 * 		preOrder
	 * 		price
	 * 		priceOriginal
	 * 		priceVat
	 * 		quantity
	 * 		seoCategory
	 * 		sku
	 * 		subName
	 * 		tax
	 * 		total
	 * 		totalVat
	 * 		unlimited
	 * 		url
	 * 		wrapperId
	 * 		wrapperType
	 * }}}
	 */
	cartUpdated: new Signal(),

	/**
	 *
	 */
	cartViewed: new Signal(),

	/**
	 * @param {{
	 *   products: [
	 *     id       {number}
	 *     name     {string}
	 *     price    {string}
	 *   ]
	 * }}
	 */
	checkoutInitiated: new Signal(),

	/**
	 *
	 */
	checkoutSelectedAddress: new Signal(),

	/**
	 *
	 */
	checkoutSelectedPaymentMethod: new Signal(),

	/**
	 *
	 */
	checkoutShown: new Signal(),

	/**
	 *
	 */
	checkoutSubmitShippingInfo: new Signal(),

	/**
	 *
	 */
	checkoutSubmitPaymentMethod: new Signal(),

	/**
	 * @param {{
	 *   id  {string}
	 *   revenue  {string}
	 *   tax  {string}
	 *   shipping  {string}
	 *   coupon  {string}
	 *   lines: [
	 *      id {string}
	 *      name {string}
	 *      price {string}
	 *      quantity {number}
	 *   ]
	 * }} order
	 */
	purchaseCompleted: new Signal(),
};

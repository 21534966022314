import View from 'Core/View.js';
import TimelineMax from 'gsap/TimelineMax';
import '../../../../../vendors/gsap/MorphSVGPlugin';

const CLASS_HOVER_DEFAULT_FRAME = 'svg-button__default';
const CLASS_HOVER_ANIMATION_FRAME = 'svg-button__hover-animation-frame';

import './svg-button.scss';

export default class SVGButton extends View {

	initialize() {
		this.useFrameAnimation = this.hasAnimationFrames();
		this.appendClickCatcher();

		if (! this.useFrameAnimation && ! this.useSmoothAnimations()) {
			return;
		}

		this.handleMouseOver = this.handleMouseOver.bind(this);
		this.handleMouseOut = this.handleMouseOut.bind(this);
		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.removeMouseListeners = this.removeMouseListeners.bind(this);

		this.anchor = this.el.parentNode;
		this.mouseIsOnButton = false;

		this.defaultFrames = this.findAll('.' + CLASS_HOVER_DEFAULT_FRAME);
		if (! this.defaultFrames || this.defaultFrames.length === 0) {
			return;
		}

		const rootHasClass = className => document.documentElement.classList.contains(className);
		if (! rootHasClass('touch')) {
			this.anchor.addEventListener('mouseover', this.handleMouseOver);
			this.anchor.addEventListener('mouseout', this.handleMouseOut);
			this.anchor.addEventListener('mousedown', this.handleMouseDown);
			window.addEventListener('touchstart', this.removeMouseListeners);
		}

		if (this.useFrameAnimation) {
			this.currentHoverFrame = 0;

			this.svgElement = this.find('svg');
			this.hideDefaultOnHover = ! ! (this.svgElement.dataset && this.svgElement.dataset.hideDefaultOnHover);

			this.hoverFrames = this.findAll('.' + CLASS_HOVER_ANIMATION_FRAME);
			return;
		}

		// Running in smooth mode
		this.defaultLayer = this.defaultFrames[0];
		this.hoverLayer = this.find('.svg-button__hover');
		this.downLayer = this.find('.svg-button__down');
		this.cloneLayer = this.defaultLayer.cloneNode(true);

		this.cloneLayer.setAttribute('class', 'svg-button__clone');
		this.defaultLayer.parentNode.insertBefore(this.cloneLayer, this.defaultLayer);
	}

	destroy() {
		if (! this.anchor) {
			return;
		}

		this.stopHoverAnimation();
		this.removeMouseListeners();
	}

	removeMouseListeners() {
		this.anchor.removeEventListener('mouseover', this.handleMouseOver);
		this.anchor.removeEventListener('mouseout', this.handleMouseOut);
		this.anchor.removeEventListener('mousedown', this.handleMouseDown);
		document.body.removeEventListener('mouseup', this.handleMouseUp);
		window.removeEventListener('touchstart', this.removeMouseListeners);
	}

	hasAnimationFrames() {
		return ! ! this.find('.svg-button__hover-animation-frame');
	}

	useSmoothAnimations() {
		return this.el.classList.contains('svg-button--smooth');
	}

	handleMouseOver() {
		if (this.useFrameAnimation) {
			this.startHoverAnimation();
			return;
		}

		this.mouseIsOnButton = true;
		this.smoothlyMorphToLayer(this.hoverLayer, 0.2, Circ.easeOut);
	}

	handleMouseOut() {
		if (this.useFrameAnimation) {
			this.stopHoverAnimation();
			return;
		}

		this.mouseIsOnButton = false;
		this.smoothlyMorphToLayer(this.defaultLayer, 0.15);
	}

	handleMouseDown() {
		document.body.addEventListener('mouseup', this.handleMouseUp);

		if (this.useFrameAnimation) {
			return;
		}

		this.smoothlyMorphToLayer(this.downLayer, 0.08);
	}

	handleMouseUp() {
		document.body.removeEventListener('mouseup', this.handleMouseUp);

		if (this.useFrameAnimation) {
			return;
		}

		const targetLayer = this.mouseIsOnButton ? this.hoverLayer : this.defaultLayer;
		this.smoothlyMorphToLayer(targetLayer, 0.05);
	}

	smoothlyMorphToLayer(layer, duration = 0.2, ease = Sine.easeOut) {
		if (! this.cloneLayer) {
			return;
		}

		const tl = new TimelineMax({});
		const children = [].slice.call(this.cloneLayer.childNodes);
		children.forEach((svgElement, index) => {
			const target = layer.childNodes[index];

			switch (svgElement.tagName.toLowerCase()) {
				case 'polygon':
				case 'path':
					tl.to(svgElement, duration, {morphSVG: target, ease: ease}, 0);
					break;

				case 'text':
					const transform = target.getAttribute('transform') || '';
					const transformWithCommas = transform.replace(/,\s+/g, ',').replace(/\s/g, ',');
					tl.to(svgElement, duration, {transform: transformWithCommas, ease: ease}, 0);
					break;
			}
		});
	}

	startHoverAnimation() {
		if (this.hideDefaultOnHover) {
			this.defaultFrames.forEach(frame => frame.style.visibility = 'hidden');
		}

		TweenLite.fromTo(this, 0.4, {currentHoverFrame: 0}, {
			ease: Linear.easeNone,
			currentHoverFrame: this.getAvailableFrames() - 1,
			onStart: () => {
				this.updateHoverFrame(Math.floor(this.currentHoverFrame));
			},
			onUpdate: () => {
				this.updateHoverFrame(Math.floor(this.currentHoverFrame));
			},
			onComplete: this.startHoverAnimation.bind(this)
		});
	}

	stopHoverAnimation() {
		TweenLite.killTweensOf(this);

		this.hoverFrames.forEach(frame => frame.style.visibility = 'hidden');

		if (this.hideDefaultOnHover) {
			this.defaultFrames.forEach(frame => frame.style.visibility = 'visible');
		}
	}

	updateHoverFrame(frameIndex) {
		this.hoverFrames.forEach((frame, index) => {
			frame.style.visibility = index === frameIndex ? 'visible' : 'hidden';
		});
	}

	getAvailableFrames() {
		return this.hoverFrames.length;
	}

	appendClickCatcher() {
		const catcher = document.createElement('div');
		catcher.className = 'svg-button__catcher';

		this.el.appendChild(catcher);
	}

}

import View from 'Core/View';

import CanvasConfetti from './CanvasConfetti';

import './subscription-confetti.scss';

export default class SubscriptionConfetti extends View {

	initialize() {
		this.resize = this.resize.bind(this);

		this.canvasConfetti = new CanvasConfetti(this.el, [
			'#f00',
			'#0066cc',
			'#ffff00',
			'#009933',
			'#ff9933',
		]);

		window.addEventListener('resize', this.resize);
		this.resize();

		this.addConfettiInterval = setInterval(() => {
			if (this.isPageHidden()) {
				return;
			}

			this.canvasConfetti.releaseConfetti();
		}, 100);
	}

	destroy() {
		clearInterval(this.addConfettiInterval);
		window.removeEventListener('resize', this.resize);

		this.canvasConfetti.destroy();
		this.canvasConfetti = null;
	}

	isPageHidden() {
		return document.hidden || document.msHidden || document.webkitHidden || document.mozHidden;
	}

	resize() {
		this.el.width = window.innerWidth;
		this.el.height = window.innerHeight;
		this.el.style.width = window.innerWidth + 'px';
		this.el.style.height = window.innerHeight + 'px';
	}
}

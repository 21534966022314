import BaseSplashView from './BaseSplash';

import {TimelineMax, Power0, Power2, TweenLite, Power4} from 'gsap/all';
import 'gsap/AttrPlugin';
import '../../../../../../../vendors/gsap/MorphSVGPlugin';

export default class Splash_19_4 extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.setupUI();
		this.setupTimeline();

	}

	setupUI() {

		this.ui = {
			background: this.el.querySelectorAll('.js-background'),
			titleLines: this.el.querySelectorAll('.js-title-line'),
			bodyLines: this.el.querySelectorAll('.js-body-line'),
			foldShapes: [...this.el.querySelectorAll('.js-fold-shapes')],
			foldBgs: [...this.el.querySelectorAll('.js-fold-bgs')],
			shadows: [...this.el.querySelectorAll('.js-shadow-lines')],
			buildingMain: this.el.querySelector('.js-building-main'),
			buildingRoof: this.el.querySelector('.js-building-roof'),
			buildingPipe: this.el.querySelector('.js-building-pipe'),
			buildingSmoke: this.el.querySelector('.js-building-smoke')
		}

	}

	update(options) {

		TweenLite.to(this.tl, options.duraton, {progress: options.progress});

	}

	setupTimeline() {

		this.tl = new TimelineMax({paused: true});

		const time = .6;
		this.tl.staggerFrom(this.ui.titleLines, .6, {y: -150, ease: Power2.easeOut}, 0.02, time);
		this.tl.from([this.ui.background, this.ui.foldShapes[0], this.ui.titleLines], .03, {
			opacity: 0,
			ease: Power0.easeNone
		}, time);
		this.tl.from(this.ui.background, .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.foldShapes[0], .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.shadows[0], .5, {y: -20, opacity: 0, ease: Power2.easeOut}, 0.5);

		this.ui.foldBgs.forEach((el, index) => this.tl.to(this.ui.foldBgs[0], .4, {morphSVG: el}, time + index * .4));
		this.ui.foldShapes.forEach((el, index) => this.tl.to(this.ui.foldShapes[0], .4, {morphSVG: el}, time + index * .4));

		this.tl.from(this.ui.buildingMain, 0.2, { opacity: 0 }, '-=.1');
		this.tl.from(this.ui.buildingMain, .5, {scale: .4, y: '10%', transformOrigin: '50% 85%', ease: Power2.easeOut }, '-=.1');

		this.tl.from(this.ui.buildingRoof, 0.2, { opacity: 0 }, '-=.2');
		this.tl.from(this.ui.buildingRoof, .5, {scale: .4, rotation: '20deg', y: '-300%', x: '100%', transformOrigin: '46% 85%', ease: Power2.easeOut }, '-=.2');

		this.tl.from(this.ui.buildingPipe, 0.2, { opacity: 0 }, '-=.2');
		this.tl.from(this.ui.buildingPipe, .5, {scale: .4, rotation: '-20deg', x: '-500%', transformOrigin: '50% 75%', ease: Power2.easeOut }, '-=.2');

		this.tl.from(this.ui.buildingSmoke, 0.2, { opacity: 0 });
		this.tl.from(this.ui.buildingSmoke, .5, {scale: .2, x: '-36%', transformOrigin: '47% 72%', ease: Power2.easeOut }, '-=.1');

		// // this.tl.staggerFrom(this.ui.arrows, .6, {opacity: 0, x: '-600', ease: Power2.easeInOut}, .125 );
		this.tl.staggerFrom(this.ui.bodyLines, .4, {opacity: 0, y: 20, ease: Power2.easeOut}, .125);

	}

}

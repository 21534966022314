import Signal from 'signals';

export default {

	/**
	 * This signals works the other way around. Multiple sources can trigger
	 * the dispatch method on it, passing an identifier. Once the loadingCompleted
	 * or loadingFailed signal with the same identifier has been called, the
	 * animation will be hidden again.
	 *
	 * @param {string} identifier
	 */
	started: new Signal(),
	completed: new Signal(),
	failed: new Signal(),
};
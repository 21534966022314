import View from 'Core/View.js';

import ContactFormImage from './ContactFormImage';

export default class ContactFormPhotoUpload extends View {

	initialize(options) {
		this.onPhotoClick = this.onPhotoClick.bind(this);
		this.onFileSelectUpdate = this.onFileSelectUpdate.bind(this);
		this.removePreviewImage = this.removePreviewImage.bind(this);

		this.photoPreview = this.find('.contact-form__input-photo-preview');
		this.photoButton = this.find('.contact-form__input-photo');
		this.fileUpload = this.find('.contact-form__input-photo-upload');

		this.previewImages = [];

		this.onPhotosUpdated = options.onChange;
		this.photoButton.addEventListener('click', this.onPhotoClick);
		this.fileUpload.addEventListener('change', this.onFileSelectUpdate);
	}

	destroy() {
		this.reset();
		this.photoButton.removeEventListener('click', this.onPhotoClick);
		this.fileUpload.removeEventListener('change', this.onFileSelectUpdate);
	}

	reset() {
		this.previewImages.forEach((item) => {
			this.destroyImage(item);
		});
		this.previewImages = [];

		this.onPhotosUpdated(this.previewImages);
	}

	onPhotoClick(event) {
		event.preventDefault();
		this.fileUpload.click();
	}

	onFileSelectUpdate() {
		// Read each file image and create a ContactImage
		const files = this.fileUpload.files;
		if (files) {
			for (let i = 0; i < files.length; i ++) {
				this.addPreviewImage(new ContactFormImage(this.photoPreview, files[i]));
			}
		}

		// Reset the file upload input
		this.fileUpload.value = null;
	}

	addPreviewImage(previewImage) {
		previewImage.removedSignal.add(this.removePreviewImage);
		this.previewImages.push(previewImage);
		this.onPhotosUpdated(this.previewImages);
	}

	removePreviewImage(previewImage) {
		this.previewImages.forEach((item, index, array) => {
			if (item === previewImage) {
				array.splice(index, 1);
				this.destroyImage(previewImage);
			}
		});

		this.onPhotosUpdated(this.previewImages);
	}

	destroyImage(previewImage) {
		previewImage.removedSignal.remove(this.removePreviewImage);
		previewImage.destroy();
	}

}

import TweenLite from 'gsap/TweenLite';

class ScrollService {

	constructor() {
		window.addEventListener('scroll', this.onScroll.bind(this));
	}

	onScroll() {

	}

	scrollPageTo(toY = 0, duration = 0.3) {
		this.scrollY = window.scrollY;
		this.isScrolling = true;

		const tween = TweenLite.to(this, duration, {
			scrollY: toY, ease: Sine.easeInOut,
			onUpdate: this.onUpdate, onUpdateScope: this,
			onComplete: this.onComplete, onCompleteScope: this
		});

		return () => tween.kill();
	}

	scrollPageToElement(el, offsetY = 0, duration = 0.3) {
		let element = el;
		let top = 0;
		do {
			top += element.offsetTop || 0;
			element = element.offsetParent;
		} while (element);

		const target = top - offsetY;
		let killTween = null;
		setTimeout(() => {
			killTween = this.scrollPageTo(target, duration);
		}, 1);

		return () => killTween && killTween();
	}

	onComplete() {
		this.isScrolling = false;
	}

	onUpdate() {
		window.scrollTo(0, this.scrollY);
	}
}

const scrollService = new ScrollService();
export default scrollService;

import View from 'Core/View.js';

import './styleguide.scss';

export default class Styleguide extends View {

	initialize() {
	}

	destroy() {
	}
}
import View from '../../../shared/Core/View';

import './wrapper-creator.scss';

const BEFORE_NAVIGATE_MODAL_ID = 'wrapper-creator-before-navigate-modal';

export default class WrapperCreator extends View {

    initialize(options) {
		this.handlePageLoadRequested = this.handlePageLoadRequested.bind(this);
		this.handleBeforeNavigateModalHidden = this.handleBeforeNavigateModalHidden.bind(this);
		this.handleConfirmBeforeNavigate = this.handleConfirmBeforeNavigate.bind(this);
		this.handleWrapperSaved = this.handleWrapperSaved.bind(this);

		this.containerEl = this.find('.wrapper-creator__container');
		this.modalsEl = this.find('.wrapper-creator__modals');
		this.beforeNavigateModalOkayEl = this.find('.wrapper-creator__before-navigate__ok');
		this.beforeNavigateModal = options.modalDictionary[BEFORE_NAVIGATE_MODAL_ID];

		const data = this.el.dataset || {};
		if (!data.cssPaths || !data.jsPath) {
			alert('Cannot load wrapper creator; missing asset paths');
			return;
		}

		if (!data.mountOptions) {
			alert('No mount options provided')
			return;
		}

		this.beforeNavigateModalOkayEl.addEventListener('click', this.handleConfirmBeforeNavigate);

		const mountOptions = JSON.parse(data.mountOptions);
		mountOptions.onWrapperSaved = this.handleWrapperSaved;

		this.onLoaded = () => this.handleWrapperCreatorLoaded(mountOptions);
		this.loadWrapperCreator(data.jsPath, JSON.parse(data.cssPaths), this.onLoaded);

		this.signals.pageLoad.requested.add(this.handlePageLoadRequested, this, 1);
		this.signals.modals.hide.add(this.handleBeforeNavigateModalHidden);
    }

    destroy() {
		window.removeEventListener('WrapperCreator.load', this.onLoaded);
		this.beforeNavigateModalOkayEl.removeEventListener('click', this.handleConfirmBeforeNavigate);

		this.signals.pageLoad.requested.remove(this.handlePageLoadRequested, this);
		this.signals.modals.hide.remove(this.handleBeforeNavigateModalHidden);

		if (typeof window.WrapperCreator === 'undefined') {
			return;
		}

		window.WrapperCreator.unmount(this.containerEl);
    }

	/**
	 *
	 * @param {String} jsPath
	 * @param {String[]} cssPaths
	 * @param callback
	 */
	loadWrapperCreator(jsPath, cssPaths, callback) {
		if (typeof window.WrapperCreator !== 'undefined') {
			callback();
			return;
		}

		cssPaths.map(path => {
			const css = document.createElement('link');
			css.rel = 'stylesheet';
			css.href = path;
			document.head.appendChild(css);
		});

		window.addEventListener('WrapperCreator.load', callback);
		const script = document.createElement('script');
		script.src = jsPath;
		script.type = 'module';
		script.defer = true;

		document.head.appendChild(script);
	}

	handlePageLoadRequested() {
		if (this.haltedPageLoadArguments) {
			return;
		}

		this.haltedPageLoadArguments = arguments;
		this.beforeNavigateModal.show();

		// Stop propagation so the user doesn't leave the page
		return false;
	}

	handleWrapperCreatorLoaded(options) {
		window.WrapperCreator.mount({
			el: this.containerEl,
			modalsEl: this.modalsEl,
			...options
		});
	}

	handleBeforeNavigateModalHidden(modalId) {
		if (modalId !== BEFORE_NAVIGATE_MODAL_ID) {
			return;
		}

		this.haltedPageLoadArguments = null;
	}

	handleConfirmBeforeNavigate(event) {
		event.preventDefault();

		const args = this.haltedPageLoadArguments;
		this.signals.pageLoad.requested.dispatch(...args);

		this.beforeNavigateModal.hide();
	}

	handleWrapperSaved(url) {
		if (! this.routes.isLocalURI(url)) {
			window.location.href = url;
			return;
		}

		// No longer prevent navigation, just navigate
		this.signals.pageLoad.requested.remove(this.handlePageLoadRequested, this);

		const route = this.routes.getRoute(url);
		this.signals.pageLoad.requested.dispatch(route);
	}
}

import View from '../../../shared/Core/View';

import './text-area-with-limit.scss';

const TOO_MANY = 'text-area-with-limit__current--too-many';

export default class TextAreaWithLimit extends View {

	initialize() {
		this._handleInputUpdate = this._handleInputUpdate.bind(this);
		this._handleKeyDown = this._handleKeyDown.bind(this);

		this.limit = this.el.dataset.limit ? parseInt(this.el.dataset.limit) : 150;
		this.disableNewLines = this.el.dataset.disableNewLines === 'true';
		this.newLinePenalty = parseInt(this.el.dataset.newLinePenalty) || 35;

		this.input = this.find('.text-area-with-limit__input');
		this.input.addEventListener('change', this._handleInputUpdate);
		this.input.addEventListener('cut', this._handleInputUpdate);
		this.input.addEventListener('paste', this._handleInputUpdate);
		this.input.addEventListener('keyup', this._handleInputUpdate);
		this.input.addEventListener('keydown', this._handleKeyDown);

		this.charCountCurrent = this.find('.text-area-with-limit__current');
		const charCountLimit = this.find('.text-area-with-limit__limit');
		charCountLimit.innerHTML = this.limit.toString();

		this._handleInputUpdate();
	}

	destroy() {
		this.input.removeEventListener('change', this._handleInputUpdate);
		this.input.removeEventListener('cut', this._handleInputUpdate);
		this.input.removeEventListener('paste', this._handleInputUpdate);
		this.input.removeEventListener('keyup', this._handleInputUpdate);
		this.input.removeEventListener('keydown', this._handleKeyDown);
	}

	_calculateCharacters(string) {
		const count = string.length;

		const newLineCount = (string.match(/\n/g) || []).length;

		return count - newLineCount + (newLineCount * this.newLinePenalty);
	}

	_handleInputUpdate() {
		if (this.disableNewLines) {
			const strippedLineBreaks = this.input.value.replace(/(\r\n|\n|\r)/gm, '');
			if (this.input.value !== strippedLineBreaks) {
				this.input.value = strippedLineBreaks;
			}
		}

		const count = this._calculateCharacters(this.input.value);
		this.charCountCurrent.innerHTML = count;

		if (count > this.limit) {
			this.charCountCurrent.classList.add(TOO_MANY);
		} else {
			this.charCountCurrent.classList.remove(TOO_MANY);
		}
	}

	_handleKeyDown(event) {

		// Disallow enter
		if (this.disableNewLines && event.keyCode === 13) {
			event.preventDefault();
		}
	}
}

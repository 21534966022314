import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MapControlIcon from './components/MapControlIcon/MapControlIcon';

import './map-controls.scss';

class MapControls extends React.PureComponent {

	render() {
		const classes = classNames({
			'map-controls': true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<div className={classes}>
				{this.renderGeoLocationControl()}
				{this.renderZoomControlsWhenNotHidden()}
			</div>
		);
	}

	renderZoomControlsWhenNotHidden() {
		if (this.props.hideZoomControls) {
			return null;
		}

		return [
			<MapControlIcon
				key="zoom-in"
				disabled={this.props.zoomInDisabled}
				onClick={this.props.onZoomInClick}
				icon="plus"/>,
			<MapControlIcon
				key="zoom-out"
				disabled={this.props.zoomOutDisabled}
				onClick={this.props.onZoomOutClick}
				icon="minus"/>
		];
	}

	renderGeoLocationControl() {
		if (! navigator.geolocation || this.props.hideUserLocationControls) {
			return null;
		}

		return (
			<MapControlIcon
				disabled={this.props.fetchUserLocationDisabled}
				spinIcon={this.props.fetchingUserLocation}
				onClick={this.props.onFetchLocationClick}
				icon="current-location"/>
		);
	}

}

MapControls.propTypes = {
	className: PropTypes.string,

	zoomInDisabled: PropTypes.bool,
	zoomOutDisabled: PropTypes.bool,
	fetchUserLocationDisabled: PropTypes.bool,
	fetchingUserLocation: PropTypes.bool,
	hideZoomControls: PropTypes.bool,
	hideUserLocationControls: PropTypes.bool,

	onZoomInClick: PropTypes.func,
	onZoomOutClick: PropTypes.func,
	onFetchLocationClick: PropTypes.func,
};

export default MapControls;

import View from 'Core/View.js';
import template from 'lodash.template';
import './cart-products-summary.scss';
import './cart-summary-product.scss';

import CurrencyFormatter from '../../../shared/utils/currency/CurrencyFormatter';
import CartModel from '../../../shared/data/CartModel';

export default class CartProductsSummary extends View {

	initialize() {
		this.productsContainer = this.find('.cart-products-summary__products');
		this.productTemplate = template(this.find('.cart-products-summary__product-template').innerHTML.replaceAll('&lt;', '<').replaceAll('&gt;', '>'));

		this.update = this.update.bind(this);

		CartModel.updatedSignal.add(this.update);
		this.update();
	}

	destroy() {
		CartModel.updatedSignal.remove(this.update);
	}

	update() {
		this.productsContainer.textContent = '';

		const products = CartModel.getProducts();

		let html = '';
		products.map((product) => {
			html += this.productTemplate({...product, total: CurrencyFormatter.format(product.total, false, CurrencyFormatter.settings.code === 'EUR')});
		});

		this.productsContainer.innerHTML = html;

	}
}

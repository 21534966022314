export default (element, parent) => {
	let parentNode = element.parentNode;
	while (parentNode !== null) {
		if (parentNode === parent) {
			return true;
		}

		parentNode = parentNode.parentNode;
	}

	return false;
}
import request from 'superagent';

export default class StupidPageLoadService {

	constructor(options) {
		const noop = () => {
		};
		this.onError = options.onError || noop;
		this.onSuccess = options.onSuccess || noop;

		this.onResponse = this.onResponse.bind(this);
	}

	load(href) {
		this.reset();

		this.currentRequest = request
			.get(href)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Accept', window.tonysConfig.acceptHeader || '*/*');
		this.currentRequest.end(this.onResponse);
	}

	onResponse(error, result) {
		if (error) {
			this.onError(error);
			return;
		}

		this.onSuccess(result.text);
	}

	reset() {
		if (this.currentRequest) {
			this.currentRequest.xhr.abort();
			this.currentRequest = null;
		}
	}
}

import View from 'Core/View.js';
import CartModel from 'data/CartModel.js';
import duplicate from './requests/duplicate';


export default class EditOrDuplicateCustomWrapper extends View {

	initialize(options) {
		this.modal = options.modalDictionary['order-template-modal-wrapper'];

		this.show = this.show.bind(this);

		this.handleDuplicateClick = this.handleDuplicateClick.bind(this);
		this.handleOrderClick = this.handleOrderClick.bind(this);
		this._onCartUpdatedRequest = this._onCartUpdatedRequest.bind(this);

        this.quantityInput = this.find('.shop-detail__amount .numeric-stepper__input', document.body);

		this.duplicateButton = this.find('.duplicate-button');
		this.duplicateButton.addEventListener('click', this.handleDuplicateClick);

		this.orderButton = this.find('.order-button');
        this.orderButton.addEventListener('click', this.handleOrderClick);

		this.signals.products.orderTemplateRequested.add(this.show);

		CartModel.requestSuccessSignal.add(this._onCartUpdatedRequest);
	}

	destroy() {
		this.signals.products.orderTemplateRequested.remove(this.show);
		this.duplicateButton.removeEventListener('click', this.handleDuplicateClick);
		this.orderButton.removeEventListener('click', this.handleOrderClick);
	}

	show(duplicateUrl, productId, optionSelect) {
		if (this.currentError) {
			this.currentError.parentNode.removeChild(this.currentError);
			this.currentError = null;
		}

        this.duplicateUrl = duplicateUrl
        this.productId = productId;
		this.optionsSelect = optionSelect;

		this.modal.show();
	}

	handleDuplicateClick(event) {
		event.preventDefault();
        event.stopPropagation();

		this.orderButton.classList.add('button--loading', 'button--disabled');
		this.duplicateButton.classList.add('button--loading', 'button--disabled');

		if (this.currentError) {
			this.currentError.parentNode.removeChild(this.currentError);
			this.currentError = null;
		}

		duplicate(this.duplicateUrl)
			.then((response) => {
				const url = response.body.url;
				const route = this.routes.getRoute(url);

				this.signals.pageLoad.requested.dispatch(route);

				this.orderButton.classList.remove('button--loading', 'button--disabled');
				this.duplicateButton.classList.remove('button--loading', 'button--disabled');
			})
			.catch(() => {
				const error = document.createElement('div');
				error.innerHTML = 'Error duplicating bar';
				error.classList.add('edit-or-duplicate-wrapper__error');

				this.orderButton.classList.remove('button--loading', 'button--disabled');
				this.duplicateButton.classList.remove('button--loading', 'button--disabled');
				event.currentTarget.parentNode.insertBefore(error, event.currentTarget.nextSibling);

				this.currentError = error;
			});
	}

    handleOrderClick(event) {
        event.preventDefault();
        event.stopPropagation();

		this.orderButton.classList.add('button--loading', 'button--disabled');
		this.duplicateButton.classList.add('button--loading', 'button--disabled');

		this._requestHash();
    }

	_requestHash() {
		const data = this._gatherAddToCartData();
		delete data.productId;

		const templateAddButton = document.querySelector('.template-cart-button');
		const firstProductId = templateAddButton.getAttribute('data-product-id');
		CartModel.addProduct(firstProductId, 0, data);
	}

	_findRelevantInputs() {
		return this.findAll('.product-detail__form input:not(.input-ignore), .product-detail__form select, .product-detail__form textarea', document.body);
	}

	_gatherAddToCartData() {
		const data = {
			productId: this.productId,
		};

		if (this.optionsSelect) {
			const optionId = parseInt(this.optionsSelect.value);
			data.productId = isNaN(optionId) ? data.productId : optionId;
		}

		const inputs = this._findRelevantInputs();
		inputs.forEach(el => {
			if (el.name === 'quantity') {
				return;
			}

			data[el.name] = el.value;
		});

		return data;
	}

	_onCartUpdatedRequest(response) {
		const result = JSON.parse(response.text);
		if(result.redirectToWrapper) {
			const url = result.redirectToWrapper;
			const route = this.routes.getRoute(url);

			this.signals.pageLoad.requested.dispatch(route);

			this.orderButton.classList.remove('button--loading', 'button--disabled');
			this.duplicateButton.classList.remove('button--loading', 'button--disabled');
		}
	}
}

import './filters-extra.scss';

import View from 'Core/View.js';
import FilterSlider from './FilterSlider';
import Signal from 'signals';

export default class FiltersExtra extends View {

	initialize() {
		this.changeSignal = new Signal();

		this.filterSlider = new FilterSlider({el:this.find('.filter-slider'), minValue:this.el.dataset.lowest, maxValue:this.el.dataset.highest, roundValues: true});
		this.inStockCheckbox = document.querySelector('input[name="in-stock"]');
		this.filterExtraData = new FiltersExtraData();

		this.onSliderChange = this.onSliderChange.bind(this);
		this.onInStockChange = this.onInStockChange.bind(this);

		// this.signals.pageLoad.requested.add(this.onPageRequested, this, 1);
		this.filterSlider.changeSignal.add(this.onSliderChange);
		this.inStockCheckbox.addEventListener('change', this.onInStockChange);
	}

	onInStockChange(event) {
		this.filterExtraData.in_stock = event.currentTarget.checked;
		this.update();
	}

	onSliderChange(minValue, maxValue) {
		this.filterExtraData.min = minValue;
		this.filterExtraData.max = maxValue;
		this.update();
	}

	changeSliderRange(minValue, maxValue) {
		this.filterSlider.updateSliderRange(minValue, maxValue);
	}

	update() {
		this.changeSignal.dispatch(this.filterExtraData);
	}

	destroy() {
	}
}

export class FiltersExtraData {
	constructor() {
		this.min = null;
		this.max = null;
		this.in_stock = false;
	}
}

import Signal from 'signals';

export default {

	/**
	 * The Spirit IDE connected
	 */
	spiritConnected: new Signal(),

	/**
	 * The Spirit IDE disconnected from the site
	 */
	spiritDisconnected: new Signal(),
};

import View from 'Core/View';
import isDescendantOf from 'utils/dom/isDescendantOf';

import createOrder from './requests/createOrder';
import updateOrder from './requests/updateOrder';
import UnlimitedsDetails from './components/UnlimitedsDetails/UnlimitedsDetails';

import './components/BarBreakdown/BarBreakdown';
import './components/UnlimitedTemplate/UnlimitedTemplate';

import './unlimiteds.scss';

export default class Unlimiteds extends View {

	initialize(options) {
		this.handleReadyForOrdering = this.handleReadyForOrdering.bind(this);
		this.handleNoLongerReadyForOrdering = this.handleNoLongerReadyForOrdering.bind(this);
		this.handleAddToCartClick = this.handleAddToCartClick.bind(this);
		this.handleBarUpdate = this.handleBarUpdate.bind(this);
		this.injectMyoAppOnceLoaded = this.injectMyoAppOnceLoaded.bind(this);
		this.showAllergensModal = this.showAllergensModal.bind(this);
		this.handleMyoPathChange = this.handleMyoPathChange.bind(this);

		this.content = this.find('.unlimiteds__content');
		this.addToBasketButton = this.find('.unlimiteds__add-to-basket');
		this.templateContent = this.find('.unlimiteds__template-content');
		this.wrapperIdField = this.find('[name="wrapper_id"]');
		this.allergensModal = options.modalDictionary['unlimiteds-allergens-warning'];

		this.barConfiguration = null;
		this.barUuid = this.content.dataset.barUuid;
		this.useBarDataUri = this.content.dataset.useBarDataUri === 'true';
		this.editMode = !! this.barUuid;
		this.loadedOutsideBarData = false;

		this.createEndpoint = this.content.dataset.createEndpoint;
		this.updateEndpoint = this.content.dataset.updateEndpoint;
		this.baseURL = this.content.dataset.baseUrl;

		this.injectMyoApp(this.content);
		this.disableAddToCartButton();

		if (this.addToBasketButton) {
			this.addToBasketButton.addEventListener('click', this.handleAddToCartClick);
		}

		this.productInfo = new UnlimitedsDetails({...options, el: this.find('.unlimiteds__details')});

		window.onbeforeunload = this.getBeforeUnloadMessage();
	}

	destroy() {
		this.signals.pageLoad.scriptLoaded.remove(this.injectMyoAppOnceLoaded);

		if (this.addToBasketButton) {
			this.addToBasketButton.removeEventListener('click', this.handleAddToCartClick);
		}

		this.removeMyoApp();
		window.onbeforeunload = null;
		this.productInfo.destroy();
	}

	injectMyoApp(container) {
		if (!window.loadUnlimiteds) {
			console.warn('[TONYS] MYO app could not be loaded!');
			this.signals.pageLoad.scriptLoaded.add(this.injectMyoAppOnceLoaded);
			return;
		}

		// Once we finally found it, stop listening
		this.signals.pageLoad.scriptLoaded.remove(this.injectMyoAppOnceLoaded);

		const options = {
			mode: 'chocoshop',
			assetsPrefix: container.dataset.assetsPrefix,
			apiPrefix: container.dataset.apiPrefix,
			container: container,
			locale: document.documentElement.getAttribute('lang'),
			onBarUpdate: this.handleBarUpdate,
			onBarReady: this.handleReadyForOrdering,
			onBarNoLongerReady: this.handleNoLongerReadyForOrdering,
		};

		// Preload the MYO app with either a bar data URI or an actual object
		const urlBarData = this.getBarDataFromURL();
		if (this.useBarDataUri && urlBarData) {
			options.barDataURI = urlBarData;

		} else if (container.dataset.barData) {
			options.barData = JSON.parse(container.dataset.barData);
			this.loadedOutsideBarData = true;
		}

		options.onPathChanged = this.handleMyoPathChange;

		window.loadUnlimiteds(options);
	}

	getBarDataFromURL() {
		const urlBarData = window.location.toString().replace(this.baseURL, '');
		if (! urlBarData || urlBarData === '/') {
			return null;
		}

		return urlBarData;
	}

	injectMyoAppOnceLoaded(scriptSrc, scriptID) {
		if (scriptID === 'unlimiteds-loader') {
			this.injectMyoApp(this.content);
		}
	}

	removeMyoApp() {
		window.unloadUnlimiteds && window.unloadUnlimiteds();
	}

	handleBarUpdate(bar) {
		this.productInfo.update(bar.recipe);
	}

	handleReadyForOrdering(bar) {
		this.barConfiguration = bar;
		this.enableAddToCartButton();

		if (this.loadedOutsideBarData && ! this.initialBarDataUri) {
			this.initialBarDataUri = bar.uri;
		}

		if (this.initialBarDataUri !== bar.uri) {
			this.updateURLWithBarDataURI(bar.uri)
		}
	}

	handleNoLongerReadyForOrdering() {
		this.barConfiguration = null;
		this.disableAddToCartButton();
	}

	handleAddToCartClick(event) {
		event.beforeAdding = new Promise((resolve, reject) => {
			if (this.editMode) {
				const endpoint = this.updateEndpoint.replace('%s', this.barUuid);
				updateOrder(endpoint, this.barConfiguration)
					.then(resolve)
					.catch(reject);
				return;
			}

			const allergensPromise = new Promise(this.showAllergensModal);
			return allergensPromise
				.then(() => {
					return createOrder(this.createEndpoint, this.barConfiguration)
						.then((response) => {
							this.barUuid = response.body.wrapper.uuid;
							this.wrapperIdField.value = response.body.wrapper.id;
						})
						.then(resolve)
						.catch(reject);
				})
				.catch(reject);
		});
	}

	showAllergensModal(onConfirm, onCancel) {
		const cancelButton = this.allergensModal.find('.unlimiteds__allergens__cancel');
		const confirmButton = this.allergensModal.find('.unlimiteds__allergens__confirm');

		const handleClick = (event) => {
			if (isDescendantOf(event.target, cancelButton) || event.target === cancelButton) {
				onCancel();
			} else if (isDescendantOf(event.target, confirmButton) || event.target === confirmButton) {
				onConfirm();
			} else {
				return;
			}

			event.preventDefault();
			this.allergensModal.el.removeEventListener('click', handleClick);
			this.allergensModal.hide();
		};

		// Show the modal
		this.allergensModal.el.addEventListener('click', handleClick);
		this.allergensModal.show();
	}

	updateURLWithBarDataURI(uri) {
		const baseUrl = this.routes.getPath('shop.unlimited');
		const url = baseUrl + '/' + uri;

		history.replaceState(null, null, url);
	}

	disableAddToCartButton() {
		if (! this.addToBasketButton) {
			return;
		}

		this.addToBasketButton.classList.add('button--disabled');
	}

	enableAddToCartButton() {
		if (! this.addToBasketButton) {
			return;
		}

		this.addToBasketButton.classList.remove('button--disabled');
	}

	handleMyoPathChange(path) {
		if (! this.templateContent) {
			return;
		}

		if (path === 'summary') {
			this.templateContent.style.display = 'block';
			return;
		}

		this.templateContent.style.display = 'none';
	}

	getBeforeUnloadMessage() {
		return this.content.dataset.beforeLeaveMessage;
	}

}

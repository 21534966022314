import View from '../../../shared/Core/View';
import findAncestorByClass from '../../../shared/utils/dom/findAncestorByClass';

export default class ContactFields extends View {

    initialize() {
		this.handleChange = this.handleChange.bind(this);

    	this.fieldsWithCondition = this.findAll('.contact-fields__field[data-condition]');

		this.el.addEventListener('change', this.handleChange);

		this.formEl = findAncestorByClass(this.el, 'form');
    }

    destroy() {
		this.el.removeEventListener('change', this.handleChange);
    }

	/**
	 * @param {FormData} data
	 */
	reevaluateFieldsWithConditions(data) {
		this.fieldsWithCondition.forEach(el => {
			el.style.display = this.displayField(data, el.dataset.condition) ? 'block' : 'none';
		});
	}

	displayField(formData, condition) {
		if (condition.indexOf('&') >= 0) {
			return condition
				.split('&')
				.every(singleCondition => this.displayField(formData, singleCondition));
		}

		const [name, value] = condition.split(':');
		if (! formData.has(name)) {
			return value === 'false';
		}

		return value.split(',').map(x => x.trim()).indexOf(formData.get(name)) >= 0;
	}

	handleChange(event) {
    	const data = new FormData(this.formEl);

		this.reevaluateFieldsWithConditions(data);
	}
}

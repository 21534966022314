import DefaultPage from '../DefaultPage/DefaultPage.js';

import './team.scss';

const CLASS_TOGGLED = 'team__person--toggled';

export default class Team extends DefaultPage {

	initialize() {
		super.initialize();

		this.handleClick = this.handleClick.bind(this);
		this.currentCard = null;

		this.el.addEventListener('click', this.handleClick);
	}

	destroy() {
		super.destroy();

		this.el.removeEventListener('click', this.handleClick);
	}

	handleClick(event) {
		if (this.currentCard) {
			this.currentCard.classList.remove(CLASS_TOGGLED);
		}

		const newCard = this.findCard(event.target)
		if (this.currentCard === newCard) {
			this.currentCard = null;
			return;
		}

		this.currentCard = newCard;
		if (this.currentCard) {
			this.currentCard.classList.add(CLASS_TOGGLED);
		}
	}

	findCard(el) {
		while (el !== this.el) {
			if (el.classList.contains('team__person')) {
				return el;
			}

			el = el.parentNode;
		}

		return null;
	}
}
import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../reactComponents/Button/Button';

import ProductOptionsSelect from './components/ProductOptionsSelect';
import OptionsList from './propTypes/OptionsList';
import QuantityInput from './components/QuantityInput';
import SelectedOptionsList from './components/SelectedOptionsList';

class AddToBasketButton extends React.PureComponent {
	constructor(props) {
		super(props);

		this.handleNewOptionProductHashChange = this.handleNewOptionProductHashChange.bind(this);
		this.handleEditOptionClick = this.handleEditOptionClick.bind(this);
		this.addProduct = this.addProduct.bind(this);

		this.clearSelectedTimeout = 0;
		this.hasMultipleOptions = props.options && props.options.length > 1;

		const firstHash = props.options && props.options.length > 0 ? props.options[0].hash : undefined;

		/**
		 * @property {number | undefined} editingProductHash - hash of the product being added
		 * @property {number | undefined} newOptionProductHash - hash of the product that is currently being edited
		 */
		this.state = {
			newOptionProductHash: undefined,
			editingProductHash: this.hasMultipleOptions ? undefined : firstHash,
			quantityInputHasFocus: false,
		};
	}

	render() {
		const optionsNotYetChosen = this.hasMultipleOptions
			? this.props.options.filter(option => !this.props.quantities[option.hash])
			: this.props.options;
		const hasOptionsLeft = optionsNotYetChosen.length > 0;
		const sortedQuantities = Object.values(this.props.quantities).sort((a, b) => a.position - b.position);
		const hasAddedProducts = Object.keys(this.props.quantities).length > 0;

		return (
			<div className="add-to-basket-button">
				{this.hasMultipleOptions && (
					<>
						<SelectedOptionsList
							options={this.props.options}
							quantities={sortedQuantities}
							onEdit={this.handleEditOptionClick}
							onQuantityChange={quantity => {
								this.setQuantityForOption(this.state.editingProductHash, quantity);
							}}
							currentOptionHash={this.state.editingProductHash}
							onInputFocus={() => {
								this.setState({ quantityInputHasFocus: true });
							}}
							onInputBlur={() => {
								this.setState({ quantityInputHasFocus: false });
								this.resetClearEditingOptionProductHashTimeout();
							}}
							disabled={this.props.disabled}
						/>

						<div className="add-to-basket-button__spacer" />
					</>
				)}

				{this.hasMultipleOptions && hasOptionsLeft && !this.state.editingProductHash && (
					<>
						<ProductOptionsSelect
							options={optionsNotYetChosen}
							placeholder={
								hasAddedProducts
									? this.props.translations.addAnotherToBasket
									: this.props.translations.pickOption
							}
							outOfStockMessage={this.props.translations.soldOut}
							name="hash"
							disabled={this.props.soldOut || this.props.disabled}
							onChange={hasAddedProducts ? this.addProduct : this.handleNewOptionProductHashChange}
							defaultValue={this.props.selectedOptionHash}
						/>
						{!hasAddedProducts && this.renderAddOptionButtonOrQuantityInput()}
					</>
				)}

				{!this.hasMultipleOptions && hasOptionsLeft && this.renderAddButtonOrQuantityInput()}

				{this.props.soldOut && (
					<div className="add-to-basket-button__sold-out">{this.props.translations.soldOut}</div>
				)}
			</div>
		);
	}

	renderAddOptionButtonOrQuantityInput() {
		return (
			<Button
				color="red"
				className="add-to-basket-button__button"
				disabled={this.props.soldOut || this.props.disabled || !this.state.newOptionProductHash}
				type="button"
				onClick={() => {
					const newQuantities = this.calculateNewQuantities(this.state.newOptionProductHash, 1);

					this.setState({
						newOptionProductHash: undefined,
						editingProductHash: this.state.newOptionProductHash,
					});

					this.props.onChange && this.props.onChange(newQuantities);

					this.resetClearEditingOptionProductHashTimeout();
				}}
			>
				{this.props.translations.addToBasket}
			</Button>
		);
	}

	renderAddButtonOrQuantityInput() {
		const quantityRecord = this.props.quantities[this.state.editingProductHash];
		const quantity = quantityRecord ? quantityRecord.quantity : 0;
		const addOnly = this.props.mode === 'addOnly';
		if (!addOnly && quantity > 0) {
			return (
				<QuantityInput
					onChange={quantity => {
						this.setQuantityForOption(this.state.editingProductHash, quantity);
					}}
					value={quantity}
				/>
			);
		}

		return (
			<Button
				color="red"
				type="button"
				size="large"
				className="add-to-basket-button__button"
				disabled={this.props.soldOut || this.props.disabled}
				onClick={() => {
					this.addProduct(this.state.editingProductHash);
				}}
			>
				{addOnly && quantity > 0 ? this.props.translations.updateInBasket : this.props.translations.addToBasket}
			</Button>
		);
	}

	setQuantityForOption(hash, quantity) {
		this.resetClearEditingOptionProductHashTimeout();

		const newQuantities = this.calculateNewQuantities(hash, quantity);

		this.props.onChange && this.props.onChange(newQuantities);
	}

	handleNewOptionProductHashChange(hash) {
		this.setState({ newOptionProductHash: hash });
	}

	/**
	 *
	 * @param {Option} option
	 */
	handleEditOptionClick(option) {
		this.setState({ editingProductHash: option.hash });

		this.resetClearEditingOptionProductHashTimeout();
	}

	addProduct(productHash) {
		const newQuantities = this.calculateNewQuantities(productHash, 1);

		this.props.onChange && this.props.onChange(newQuantities);

		this.setState({ editingProductHash: productHash });

		this.resetClearEditingOptionProductHashTimeout();
	}

	resetClearEditingOptionProductHashTimeout() {
		clearTimeout(this.clearSelectedTimeout);

		this.clearSelectedTimeout = setTimeout(() => {
			if (this.state.quantityInputHasFocus) {
				return;
			}

			if (this.hasMultipleOptions) {
				this.setState({ editingProductHash: undefined });
			}

			this.props.onReadyForSync && this.props.onReadyForSync();
		}, 2000);
	}

	calculateNewQuantities(hash, quantity) {
		const option = this.props.options.find(o => o.hash === hash);
		const existing = this.props.quantities[hash];
		let updatedEntry = {
			hash: hash,
			productId: option.productId,
			quantity: quantity,
			position: existing ? existing.position : this.highestPosition() + 1,
		};

		if (this.props.mode === 'addOnly') {
			updatedEntry.alwaysAdd = true;
		}

		return {
			...this.props.quantities,
			[hash]: updatedEntry,
		};
	}

	highestPosition() {
		return Object.values(this.props.quantities).reduce((highest, { position }) => {
			return Math.max(highest, position);
		}, 0);
	}
}

AddToBasketButton.propTypes = {
	options: OptionsList,
	translations: PropTypes.shape({
		addToBasket: PropTypes.string.isRequired,
		updateInBasket: PropTypes.string.isRequired,
		addAnotherToBasket: PropTypes.string.isRequired,
		pickOption: PropTypes.string.isRequired,
		soldOut: PropTypes.string.isRequired,
	}).isRequired,
	quantities: PropTypes.objectOf(
		PropTypes.shape({
			hash: PropTypes.string.isRequired,
			productId: PropTypes.number.isRequired,
			quantity: PropTypes.number.isRequired,
			position: PropTypes.number.isRequired,
		})
	).isRequired,
	selectedOptionHash: PropTypes.string,
	onChange: PropTypes.func,
	onReadyForSync: PropTypes.func,
	mode: PropTypes.oneOf(['regular', 'addOnly']),
	soldOut: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default AddToBasketButton;

import View from '../../../../shared/Core/View';

export default class HeaderTypeImage extends View {

	initialize(options) {
		this.image = this.find('img');
	}

	destroy() {
	}

	preload(onComplete, onFailure) {
		if (!this.image.dataset.src) {
			onComplete();

			return;
		}

		this.image.addEventListener('load', onComplete);
		this.image.addEventListener('error', onFailure);
		this.image.src = this.image.dataset.src;
	}
}

import View from '../../../shared/Core/View';

import './subscription-thank-you.scss';

export default class SubscriptionThankYou extends View {

    initialize() {

    }

    destroy() {
    }
}

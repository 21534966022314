import React, { Component } from 'react';
import PropTypes from 'prop-types';
import createRequest from 'superagent';

import Location from '../../../../shapes/Location';

import StreetView from './StreetView';

class StreetViewLoader extends Component {

	constructor(props) {
		super(props);

		this.currentRequest = null;
		this.state = {
			streetViewAvailable: false,
			isVerifyingIfAvailable: true,
			panoramaId: null,
		};
	}

	componentDidMount() {
		this.verifyAvailability(this.props.location);
	}

	componentWillReceiveProps(nextProps) {
		this.verifyAvailability(nextProps.location);
	}

	render() {
		if (this.state.isVerifyingIfAvailable) {
			const style = {
				paddingTop: (this.props.heightRatio * 100).toString() + '%',
			};

			return (
				<div className="street-view__sizer" style={style}/>
			);
		}

		if (! this.state.streetViewAvailable) {
			return null;
		}

		return (
			<StreetView heightRatio={this.props.heightRatio}
				isMobile={this.props.isMobile}
				address={this.getAddressLine(this.props.location)}
				latLng={this.props.location.gps}
				enlarged={this.props.enlarged}
				onEnlargeToggle={this.props.onEnlargeToggle}
				panoramaId={this.state.panoramaId}
				apiKey={this.props.apiKey}/>
		);
	}

	verifyAvailability(location) {
		this.setState({streetViewAvailable: false, isVerifyingIfAvailable: true});

		if (this.currentRequest) {
			this.currentRequest.abort();
		}

		this.currentRequest = createRequest('GET', this.getStreetViewMetaURL(location));
		this.currentRequest.set('Accept', 'application/json');
		this.currentRequest.then(response => {
			this.setState({
				streetViewAvailable: response.body.status === 'OK',
				panoramaId: response.body.pano_id,
				isVerifyingIfAvailable: false,
			});
			this.currentRequest = null;
		});
	}

	getAddressLine(location) {
		return location.address + ', ' + location.city + ', ' + location.state + ' ' + location.zipcode;
	};

	getStreetViewMetaURL(location) {
		const address = encodeURIComponent(this.getAddressLine(location));
		const baseURL = 'https://maps.googleapis.com/maps/api/streetview/metadata';
		const locationString = '?location=' + address;
		const options = [
			'size=640x' + Math.round(640 * this.props.heightRatio).toString(),
			'sensor=false',
			'key=' + this.props.apiKey,
		];

		return baseURL + locationString + '&' + options.join('&');
	};
}

StreetViewLoader.propTypes = {
	heightRatio: PropTypes.number,
	apiKey: PropTypes.string,
	location: Location.isRequired,
	isMobile: PropTypes.bool,
	enlarged: PropTypes.bool,
	onEnlargeToggle: PropTypes.func,
};

export default StreetViewLoader;
import View from 'Core/View.js';
import MissionSection from './MissionSection.js';
import ScrollService from '../../../services/ScrollService.js';

import './mission.scss';

const MISSION_EXPAND_PADDING_TOP = 64;

export default class Mission extends View {

	initialize(options) {

		// Wrap Section Elements
		this.sectionElements = this.findAll('.mission__section');
		this.sections = [];
		this.sectionElements.forEach((section) => {
			const missionSection = new MissionSection({
				...options,
				el: section,
				mission: this,
			});
			this.sections.push(missionSection);
			missionSection.expandSignal.add(this.onMissionSectionExpanding.bind(this));
			missionSection.expandedSignal.add(this.onMissionSectionExpanded.bind(this));
			missionSection.collapsedSignal.add(this.onMissionSectionCollapsed.bind(this));
		});

		this.signals.windowResized.add(this.onResize.bind(this));

		// Check deeplink with delay
		TweenLite.delayedCall(1, this.checkHashChange.bind(this));

		// Activate subtitle click -> scroll page down
		this.onTitleClick = this.onTitleClick.bind(this);
		this.mainSubTitle = document.querySelector('.main-titles__sub');
		this.mainSubTitle.addEventListener('click', this.onTitleClick);
	}

	onTitleClick(event) {
		ScrollService.scrollPageToElement(this.el, 64);
	}

	checkHashChange() {
		// Auto expand when hash is in route on initialize
		const route = this.routes.getRoute();
		if(route.hash) {
			const section = this.findSectionWithId(route.hash);
			if(section) {
				section.onViewMoreClick(null);
			}
		}
	}

	findSectionWithId(id) {
		var result = null;
		this.sections.forEach((section) => {
			if(section.id == id) {
				result = section;
			}
		});

		return result;
	}

	onMissionSectionExpanding(section) {
		this.currentSection = section;

		this.sections.forEach((missionSection) => {
			if(!(section == missionSection)) {
				missionSection.collapse();
			}
		});
	}

	onMissionSectionExpanded(section) {
		ScrollService.scrollPageToElement(section.contentElement, MISSION_EXPAND_PADDING_TOP);

		// TODO: This should be removed in the future
		const w = window.innerWidth;
		const h = window.innerHeight;
		this.signals.windowResized.dispatch(w, h);
	}

	onMissionSectionCollapsed(section) {
		this.sections.forEach((missionSection) => {
			if(!(section == missionSection)) {
				missionSection.scroll();
			}
		});
	}

	onResize() {
		this.sections.forEach((missionSection) => {
			missionSection.update();
		});
	}

	destroy() {
		this.mainSubTitle.removeEventListener('click', this.onTitleClick);
		this.sections.forEach((missionSection) => {
			missionSection.destroy();
		});
	}
}

import View from 'Core/View.js';

export default class CSRFMeta extends View {

	initialize() {
		this.signals.pageLoad.completed.add(this.handlePageLoaded.bind(this));
	}

	destroy() {
	}

	handlePageLoaded(htmlNode) {
		const csrfInput = htmlNode.querySelector('.ajax-csrf-update');
		if (! csrfInput) {
			return;
		}

		document.querySelector('meta[name="csrf-token"]').setAttribute('content', csrfInput.value);
	}
}
import View from 'Core/View.js';
import TimelineMax from 'gsap/TimelineMax';
import TweenLite from 'gsap/TweenLite';

import './loading-animation.scss';

export default class LoadingAnimation extends View {

	initialize() {
		this.onLoadingStarted = this.onLoadingStarted.bind(this);
		this.onLoadingCompleted = this.onLoadingCompleted.bind(this);

		this.signals.loading.started.add(this.onLoadingStarted);
		this.signals.loading.completed.add(this.onLoadingCompleted);
		this.signals.loading.failed.add(this.onLoadingCompleted);

		this.waitingFor = [];
		this.visible = false;

		this.timeline = this.createTimeline();
	}

	destroy() {
		this.signals.loading.started.remove(this.onLoadingStarted);
		this.signals.loading.completed.remove(this.onLoadingCompleted);
		this.signals.loading.failed.remove(this.onLoadingCompleted);
	}

	onLoadingStarted(identifier) {
		this.waitingFor.push(identifier);

		// Filter array for doubles
		this.waitingFor = this.waitingFor.filter((identifier, pos, array) => array.indexOf(identifier) === pos);
		this.toggleVisibility();
	}

	onLoadingCompleted(identifier) {
		const index = this.waitingFor.indexOf(identifier);
		if (index < 0) {
			return;
		}

		this.waitingFor.splice(index, 1);
		this.toggleVisibility();
	}

	toggleVisibility() {
		if (this.waitingFor.length === 0) {
			this.hide();
			return;
		}

		this.show();
	}

	hide() {
		if (! this.visible) {
			return;
		}

		this.visible = false;

		TweenLite.killTweensOf(this.el);
		TweenLite.to(this.el, 0.4, {
			scale: 0, rotation: '-30deg', ease: Back.easeIn,
			onComplete: () => {
				this.el.style.display = 'none';
				this.timeline.stop();
			}
		});
	}

	show() {
		if (this.visible) {
			return;
		}

		this.visible = true;

		TweenLite.fromTo(this.el, 0.3, {scale: 0, rotation: '-30deg'}, {
			scale: 1,
			delay: 1,
			rotation: '0deg',
			ease: Back.easeOut,
			onStart: () => {
				this.el.style.display = 'block';
			}
		});

		this.timeline.timeScale(1);
		this.timeline.restart(true);
	}

	createTimeline() {
		const barParts = this.findAll('.loading-bar__parts path');

		const tl = new TimelineMax({
			paused: true,
			delay: 1,
			repeat: - 1,
			repeatDelay: 0.5,

			align: 'normal',
		});

		tl.staggerFromTo(barParts, 0.3,
			{rotation: '-20deg', scale: 0, transformOrigin: '70% 30%'},
			{rotation: '0deg', scale: 1, ease: Back.easeOut},
			0.04);

		return tl;
	}
}
import {TweenLite, Power2, ScrollToPlugin} from 'gsap/all';

import Signal from 'signals';

// eslint-disable-next-line no-unused-vars
const plugins = [ ScrollToPlugin ];

const UP = 'UP';
const DOWN = 'DOWN';

export default class ScrollManager {

	get offset() {
		return this._offset;
	}

	set offset(value) {
		this._offset = value;
	}

	get viewport() {
		return this._viewport;
	}

	set viewport(value) {
		this._viewport = value;
	}

	get direction() {
		return this._direction;
	}

	set direction(value) {
		this._direction = value;
	}

	constructor(options) {

		this.options = options.signals;
		this.direction = UP;
		this.elementMap = new Map();
		this.emptyCallback = () => {};

		this.setupEventListeners();
		this.setupSignals();
		this.updateScrollPosition();
		this.updateViewport();

	}

	destroy() {

		this.removeEventListeners();

	}

	registerElement(id, el, callbackInView, callbackOutView) {

		this.elementMap.set(id, {
			el: el,
			callbackInView,
			callbackOutView
		});

		this.reCalculateOffsets();
		this.checkElements();

	}

	unRegisterElement(id) {

		this.elementMap.delete(id);

	}

	reCalculateOffsets() {
		this.elementMap.forEach(item => {
			const { top, height} = item.el.getBoundingClientRect();
			item.posY = top + this.offset.y;
			item.height = height;
		});

	}

	scrollToElement(el, duration, callback, offsetY = 0) {

		callback = callback || this.emptyCallback;

		if (typeof el === 'string') {
			el = document.querySelector(`#${el}`);
		}

		if (! el) {
			callback();
			return;
		}

		TweenLite.to(window, duration,
			{
				scrollTo: {
					y: el,
					offsetY: offsetY,
					autoKill: false,
					onAutoKill: () => callback(false)
				},
				ease: Power2.easeInOut,
				onComplete: () => callback(true)
			}
		);

	}

	setupEventListeners() {
		this.handleScroll = this.handleScroll.bind(this);

		window.addEventListener('scroll', this.handleScroll);

	}

	removeEventListeners() {

		window.removeEventListener('scroll', this.handleScroll);

		this.handleScroll = null;

	}

	setupSignals() {

		this.signals = {...this.signals, scrolled: new Signal()};

	}

	updateScollPositionsOnResize() {
		this.updateScrollPosition();
		this.updateViewport();
		this.reCalculateOffsets();
		this.checkElements();
	}

	updateDirection() {

		this.direction = this.offset.y < window.pageYOffset ? DOWN : UP;

	}

	updateScrollPosition() {

		this.offset = {
			x: window.pageXOffset,
			y: window.pageYOffset
		}

	}

	updateViewport() {
		const ratio = window.innerWidth > 768 ? 0.5625 : 127; // NOTE: matches padding-bottom of .splashes__sizer
		const ratioHeight = window.innerWidth * ratio;
		const ratioForSwitching = Math.min(ratioHeight / window.innerHeight, 1) * 1.1;

		this.viewport = {
			w: window.innerWidth,
			h: window.innerHeight,
			hh: window.innerHeight * ratioForSwitching
		}

	}

	checkElements() {

		for (let [id, el] of this.elementMap) {
			if (
				this.offset.y + this.viewport.hh > el.posY &&
				this.offset.y + this.viewport.hh < el.posY + el.height
			) {
				el.callbackInView({id: id, y: this.offset.y, posY: el.posY, height: el.height});
			}
		}

	}

	handleScroll(e) {

		this.updateDirection();
		this.updateScrollPosition();
		this.checkElements();
		this.signals.scrolled.dispatch(this.offset.y);

	}

}

import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';
import Hammer from 'hammerjs';
import {limit} from 'utils/Formatting.js';
import './shop-carousel.scss';
import ShopCarouselProductDetails from './components/ShopCarouselProductDetails/ShopCarouselProductDetails.js';

const SHOP_CAROUSEL_FRAMERATE = 60;
const SHOP_CAROUSEL_FRICTION = 0.95;
const SHOP_CAROUSEL_VELOCITY_THRESHOLD = 10;

export default class ShopCarousel extends View {

	initialize() {

		// Can use motionblur filter

		/**
		 * 01-10-2018 Disable because we dont' we check for Chrome/Safari
		 * and Safari is too slow for this stuff. Also, the difference is
		 * hard to notice and the performance gain is considerable.
		 * @type {boolean}
		 */
		this.canUserSVGFilter = false;//typeof SVGFEColorMatrixElement !== undefined && SVGFEColorMatrixElement.SVG_FECOLORMATRIX_TYPE_SATURATE==2;;
		this.imageHeight = 240; // Default height
		this.velocity = 0;
		this.padding = 10;
		this.positionY = 0;
		this.ignoreLoop = true;
		this.barIsHidden = false;

		this.checkStartConditionsTimeout = 0;

		// Parse Image Data set in sho-carousel.twig
		if (! this.parseCarouselData()) {
			this.isEmpty = true;
			this.el.parentNode.removeChild(this.el);
			return;
		}

		// Necessary to lift carousel out of main element
		this.moveElement();

		// Delegates
		this.barInAnimationComplete = this.barInAnimationComplete.bind(this);
		this.onResize = this.onResize.bind(this);
		this.hide = this.hide.bind(this);
		this.onImageLoaded = this.onImageLoaded.bind(this);
		this.checkStartConditions = this.checkStartConditions.bind(this);

		// Elements
		this.motionBlurFilter = this.find('#shop-carousel-blur').firstElementChild;
		this.wrapper = this.find('.shop-carousel__wrapper');
		this.images = this.findAll('.shop-carousel__wrapper img');

		// Objects
		this.productDetails = new ShopCarouselProductDetails(this.find('.shop-carousel__product-details'));

		// Disable drag for images and start loading
		this.imagesLoaded = 0;
		this.images.forEach((image) => {
			image.ondragstart = function () {
				return false;
			};
			image.addEventListener('load', this.onImageLoaded);
			image.setAttribute('src', image.dataset.src);
		});

		// Signal Listeners
		this.signals.windowResized.add(this.onResize);
		this.signals.backgroundAnimation.started.addOnce(this.hide);
		this.barHiddenTimeout = setTimeout(this.barInAnimationComplete, 500);

		this.onResize();
	}

	moveElement() {
		const headerElement = document.querySelector('.header');
		headerElement.insertBefore(this.el, headerElement.firstChild);
	}

	parseCarouselData() {
		try {
			this.productData = JSON.parse(this.el.dataset.products);
			if (this.productData && this.productData.length > 0) {
				return true;
			}
		} catch (e) {

		}

		return false;
	}

	onImageLoaded() {
		this.imagesLoaded ++;
		this.scheduleCheckForStartConditions();
	}

	scheduleCheckForStartConditions() {
		clearTimeout(this.checkStartConditionsTimeout);
		this.checkStartConditionsTimeout = setTimeout(this.checkStartConditions, 200);
	}

	checkStartConditions() {
		const hasLoadedAllImages = this.images.length === this.imagesLoaded;

		if (! this.hiding && this.barIsHidden && hasLoadedAllImages) {
			this.calculatePositions();
			this.previousPositionY = this.positionY = - this.totalHeight * 1.1;
			this.startInAnimation();
		}
	}

	startInAnimation() {

		TweenLite.killTweensOf(this);
		TweenLite.to(this, 1.5, {
			delay: 0.25, positionY: this.totalHeight, ease: Sine.easeOut,
			onStart: () => {
				TweenLite.set(this.el, {opacity: 1});
			},
			onUpdate: this.updateInAnimation,
			onUpdateScope: this,
			onComplete: this.onInAnimationDone,
			onCompleteScope: this
		});
	}

	onInAnimationDone() {
		// Ignore if already animation out
		if (this.hiding) return;

		this.activate();

		this.stop();
		const closest = this.findClosest();
		if (closest) {
			this.setSelected(closest);
			this.snapTo(closest);
		}
	}

	updateInAnimation() {

		// Start looping as soon as no more products can be shown
		if (this.positionY >= 0) {
			this.ignoreLoop = false;
		}

		this.update();
	}

	activate() {
		this.onResize();

		// Initialize Hammer on wrapper
		this.hammer = new Hammer(this.wrapper, {direction: Hammer.DIRECTION_VERTICAL});
		this.hammer.add(new Hammer.Pan({threshold: 0}));

		// Add interaction event listeners
		// Touch/Drag input Listeners
		this.wrapper.addEventListener('mousedown', this.stop.bind(this));
		this.wrapper.addEventListener('touchstart', this.stop.bind(this));

		const isFireFox = /Firefox/i.test(navigator.userAgent);
		const scrollWheelEvent = isFireFox ? 'DOMMouseScroll' : 'mousewheel';
		this.wrapper.addEventListener(scrollWheelEvent, this.onScroll.bind(this));

		// Apply blur filter
		if (this.canUserSVGFilter) {
			this.wrapper.classList.add('shop-carousel__wrapper--use-blur-filter');
		}

		this.hammer.on('panstart', this.onPanStart.bind(this));
		this.hammer.on('panmove', this.onPan.bind(this));
		this.hammer.on('panend', this.onPanEnd.bind(this));
		this.hammer.on('tap', this.onTap.bind(this));
		this.hammer.on('press', this.onTap.bind(this));
	}

	onResize() {
		this.calculatePositions();
		this.update();
		this.forceRedraw();

		this.productDetails.update();
	}

	onPanStart(event) {
		this.stop();

		this.startPanY = this.positionY;
		this.velocity = event.velocityY * 1000;
		this.updateVelocity();
	}

	onPan(event) {
		this.resetSelected(); // Make sure elements move back

		this.positionY = this.startPanY + event.deltaY;
		this.velocity = event.velocityY * 1000;
		this.updateVelocity();
		this.update();
	}

	onPanEnd(event) {

		this.stop();

		this.velocity = event.velocityY * 1000;// Velocity Y/second
		this.start();
	}

	onScroll(event) {
		var delta = ((event.deltaY || - event.wheelDelta || event.detail * 10) || 1);

		delta = limit(delta, - 50, 50);

		this.resetSelected();

		TweenLite.killTweensOf(this);
		event.preventDefault();
		this.velocity = delta * SHOP_CAROUSEL_FRAMERATE;
		this.start();
	}

	onTap(event) {

		if (event.target.dataset && event.target.dataset.productId) {
			this.snapTo(event.target);
			this.setSelected(event.target);
		} else {
			const target = this.findClosest();
			this.snapTo(target);
			this.setSelected(target);
		}
	}

	hide(direction) {

		this.hiding = true;

		// Remove listeners to make sure they are not fired while animating out
		this.signals.backgroundAnimation.started.remove(this.hide);
		this.signals.windowResized.remove(this.onResize);

		TweenLite.to(this.el, 0.9, {
			opacity: 0,
			x: document.body.clientWidth * direction,
			ease: Cubic.easeInOut,
			onComplete: this.destroy,
			onCompleteScope: this
		});
	}

	barInAnimationComplete() {
		this.barIsHidden = true;

		this.checkStartConditions();
	}

	destroy() {

		if (this.isEmpty) {
			return;
		}

		if (this.el.parentElement) {
			this.el.parentElement.removeChild(this.el);
		}

		this.stop();
		if (this.hammer) {
			this.hammer.destroy();
		}

		this.productDetails.destroy();

		this.signals.backgroundAnimation.started.remove(this.hide);
		this.signals.windowResized.remove(this.onResize);

		clearTimeout(this.barHiddenTimeout);
	}

	step() {
		this.updateVelocity();
		this.positionY += this.velocity / SHOP_CAROUSEL_FRAMERATE;
		this.velocity *= SHOP_CAROUSEL_FRICTION;
		this.update();

		if (Math.abs(this.velocity) <= SHOP_CAROUSEL_VELOCITY_THRESHOLD) {
			this.stop();
			const closest = this.findClosest();
			this.snapTo(closest);
			this.setSelected(closest);
		}

	}

	updateVelocity() {
		var adjustedVelocity = Math.abs(this.velocity) / 250;
		if (adjustedVelocity < 10) {
			adjustedVelocity = 0;
		}

		if (this.canUserSVGFilter) {
			this.motionBlurFilter.setAttribute('stdDeviation', `0,${adjustedVelocity}`);
		}
	}

	forceRedraw() {
		this.wrapper.style.display = 'none';
		this.wrapper.offsetHeight; // no need to store this anywhere, the reference is enough
		this.wrapper.style.display = 'block';
	}

	start() {
		clearInterval(this.stepInterval);
		this.stepInterval = setInterval(this.step.bind(this), 1000 / SHOP_CAROUSEL_FRAMERATE);
		this.step();
	}

	stop() {
		TweenLite.killTweensOf(this);
		clearInterval(this.stepInterval);
		this.ignoreLoop = false;
		this.velocity = 0;
		this.updateVelocity();
		this.forceRedraw();
	}

	findClosest() {
		const offsetY = this.wrapper.clientHeight / 2;
		var moveDistance = this.totalHeight;
		var closest = null;
		this.images.forEach((image) => {
			let y = offsetY - image.dataset.y - image.clientHeight * 0.5;
			if (Math.abs(y) < Math.abs(moveDistance)) {
				moveDistance = y;
				closest = image;
			}
		});

		return closest;
	}

	snapTo(image) {
		if (image) {
			const offsetY = this.wrapper.clientHeight / 2;
			const moveDistance = offsetY - image.dataset.y - image.clientHeight * 0.5;
			var newPositionY = this.positionY + moveDistance;
			TweenLite.killTweensOf(this);
			TweenLite.to(this, 0.3, {
				delay: 0.1,
				positionY: newPositionY,
				ease: Sine.easeInOut,
				onUpdate: this.update,
				onUpdateScope: this
			});
		}
	}

	resetSelected() {
		this.images.forEach((img) => {
			if (img.classList.contains('shop-carousel__image--selected')) {
				img.classList.remove('shop-carousel__image--selected');

				TweenLite.killTweensOf(img);
				TweenLite.to(img, 0.3, {x: 0, ease: Sine.easeInOut});

				this.productDetails.next(null);
			}
		});

		this.selected = null;
	}

	setSelected(image) {
		if (this.selected === image || ! this.productData) {
			return;
		}

		this.resetSelected();
		this.selected = image;

		TweenLite.killTweensOf(image);
		TweenLite.to(image, 0.3, {x: 20, delay: 0.1, ease: Sine.easeInOut});

		image.classList.add('shop-carousel__image--selected');

		const productId = parseInt(image.dataset.productId);
		this.productData.forEach((data) => {
			if (data.id === productId) {
				this.productDetails.next(data, this.lastDirection)
			}
		});
	}

	calculatePositions() {
		let offsetY = 0;
		this.images.forEach((image) => {
			const height = (image.offsetHeight + this.padding);
			image.dataset.y = offsetY + this.positionY;
			offsetY += height;

			this.imageHeight = Math.max(this.imageHeight, height);
		});

		this.totalHeight = offsetY;
	}

	update() {
		var dif = this.positionY - this.previousPositionY;

		this.lastDirection = dif >= 0 ? 1 : - 1;
		this.calculatePositions();
		this.loop();
		this.render();

		this.previousPositionY = this.positionY;
	}

	loop() {

		const viewportHeight = this.wrapper.clientHeight;

		this.images.forEach((image) => {
			let y = parseInt(image.dataset.y);
			y = isNaN(y) ? 0 : y;

			if (! this.ignoreLoop) {
				while (y > viewportHeight) {
					y -= this.totalHeight;
				}
				image.dataset.y = y;

				while (y <= - this.imageHeight) {
					y += this.totalHeight;
				}
				image.dataset.y = y;
			}
		});
	}

	render() {
		this.images.forEach((image) => {
			TweenLite.set(image, {y: image.dataset.y});
		});
	}
}

import template from 'lodash.template';
import Signal from 'signals';
import View from '../../../shared/Core/View';

import './excel-addresses-upload-modal.scss';

export default class ExcelAddressesUploadModal extends View {

	initialize() {

		/**
		 * This is the main signal other components can hook into
		 *
		 * @param Array<{
		 *     name?: String;
		 *     email?: String;
		 *     incomplete: Boolean;
		 * }> recipients
		 **/
		this.onRecipientsReceived = new Signal();

		this.handleConfirmWithWarnings = this.handleConfirmWithWarnings.bind(this);
		this.handleFileInputChanged = this.handleFileInputChanged.bind(this);
		this.handleFormSubmitting = this.handleFormSubmitting.bind(this);
		this.handleFormSucceeded = this.handleFormSucceeded.bind(this);
		this.handleFormFailed = this.handleFormFailed.bind(this);
		this.handleShowing = this.handleShowing.bind(this);

		this.templateSuccess = template(this.find('#template-success').innerHTML);
		this.templateWarning = template(this.find('#template-warning').innerHTML);

		this.sections = {
			form: this.find('.excel-addresses-upload-modal__form'),
			submitting: this.find('.excel-addresses-upload-modal__submitting'),
			success: this.find('.excel-addresses-upload-modal__success'),
			warning: this.find('.excel-addresses-upload-modal__warning'),
		};

		this.excelUpload = this.find('#upload-excel-modal .excel-addresses-upload-modal__upload');
		this.excelUploadRetry = this.find('#upload-excel-retry .excel-addresses-upload-modal__upload');
		this.uploadButton = this.find('.excel-addresses-upload-modal__submit');
		this.confirmWithWarningsButton = this.find('.excel-addresses-upload-modal__warning__confirm');

		this.excelUpload.addEventListener('change', this.handleFileInputChanged);
		this.excelUploadRetry.addEventListener('change', this.handleFileInputChanged);
		this.confirmWithWarningsButton.addEventListener('click', this.handleConfirmWithWarnings);

		this.signals.modals.triggered.add(this.handleShowing);
		this.signals.forms.submitted.add(this.handleFormSubmitting);
		this.signals.forms.succeeded.add(this.handleFormSucceeded);
		this.signals.forms.failed.add(this.handleFormFailed);
	}

	destroy() {
		this.excelUpload.removeEventListener('change', this.handleFileInputChanged);
		this.excelUploadRetry.addEventListener('change', this.handleFileInputChanged);

		this.signals.modals.triggered.remove(this.handleShowing);
		this.signals.forms.submitted.remove(this.handleFormSubmitting);
		this.signals.forms.succeeded.remove(this.handleFormSucceeded);
		this.signals.forms.failed.remove(this.handleFormFailed);

		this.onRecipientsReceived.removeAll();
	}

	resetUploadFields() {
		this.excelUpload.value = '';
		this.excelUploadRetry.value = '';
	}

	handleFileInputChanged(event) {
		if (event.target.value) {
			const form = this.findViewForElement(event.target.form);
			setTimeout(() => form.submit(), 100);
		}
	}

	handleShowing(modalId) {
		if (modalId !== this.el.id) {
			return;
		}

		this.showSection('form');
	}

	showSection(sectionName) {
		if (! this.sections[sectionName]) {
			console.warn('[TONYS] Section does not exist');
			return;
		}

		Object.keys(this.sections).forEach(key => {
			this.sections[key].style.display = key === sectionName ? 'block' : 'none';
		});
	}

	handleFormSubmitting(formId) {
		if (formId !== 'upload-excel' && formId !== 'upload-excel-retry') {
			return;
		}

		this.recipients = null;
		this.showSection('submitting');
	}

	handleFormFailed(formId) {
		if (formId !== 'upload-excel' && formId !== 'upload-excel-retry') {
			return;
		}

		this.resetUploadFields();

		this.showSection('form');
	}

	handleFormSucceeded(formId, response) {
		if (formId !== 'upload-excel' && formId !== 'upload-excel-retry') {
			return;
		}

		this.resetUploadFields();

		const errorsForRecipients = (response.errors && response.errors.recipients) || {};
		this.recipients = response.recipients.map((r, index) => {
			r.errors = errorsForRecipients[index] || null;

			return r;
		});

		const recipientCount = Object.keys(this.recipients).length;

		if (response && response.errors) {
			const sectionContent = this.find('.excel-addresses-upload-modal__warning .excel-addresses-upload-modal__section-content');
			sectionContent.innerHTML = this.templateWarning({
				count: recipientCount,
				failedCount: 2,
			});

			this.showSection('warning');

			return;
		}

		const sectionContent = this.find('.excel-addresses-upload-modal__success .excel-addresses-upload-modal__section-content');
		sectionContent.innerHTML = this.templateSuccess({count: recipientCount});

		this.showSection('success');

		this.onRecipientsReceived.dispatch(this.recipients);
	}

	handleConfirmWithWarnings() {
		this.onRecipientsReceived.dispatch(this.recipients);
	}
}

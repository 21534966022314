import DefaultPage from '../DefaultPage/DefaultPage.js';
import './payment-success.scss';

export default class Success extends DefaultPage {

	initialize() {
		// const beanCount = this.el.dataset.beanCount;
		const order = JSON.parse(this.el.dataset.order);

		super.initialize();

		if (! order.tracked) {
			this.signals.ecommerce.purchaseCompleted.dispatch(
				order.id,
				this.transformOrderToTrackingObject(order)
			);
		}
	}

	destroy() {
		super.destroy();
	}

	transformOrderToTrackingObject(order) {
		const lines = order.lines.map(line => ({
			id: line.id,
			sku: line.sku,
			name: line.name,
			price: line.price,
			category: line.seo_category,
			quantity: line.quantity,
		}));

		return {
			revenue: order.total,
			coupon: order.discount_code,
			impact_beans: order.impact_beans,
			lines: lines,
		};
	}

}

import Signal from 'signals';

import StupidPageLoadService from '../../../../../services/StupidPageLoadService';

export default class ChapterLoadService {
    constructor() {

		this.loadQueue = [];
        this.loadedChapters = new Map();
		this.emptyCallback = () => {};
		this.setupPageloadService();
		this.setupSignals()

	}

	destroy() {

		this.clearQueue();

	}

    load(id, url, success, error) {

		this.loadQueue.push({id, url, success, error});
		if(!this.loading) this.loadFromQueue();

    }

    setupPageloadService() {

        this.pageLoader = new StupidPageLoadService({
            onError: this.handlePageLoadError.bind(this),
            onSuccess: this.handlePageLoadSuccess.bind(this)
        });

	}

	setupSignals() {

		this.signals = {...this.signals, chapterLoaded: new Signal()};

	}

	loadFromQueue() {

		if(!this.loadQueue.length) return;

		const chapterToLoad = this.loadQueue.pop();
		const chapterHtml = this.loadedChapters.get(chapterToLoad.id);

		this.successHandler = chapterToLoad.success || this.emptyCallback;
		this.errorHandler = chapterToLoad.error || this.emptyCallback;

		if(chapterHtml) {
			this.successHandler(chapterHtml);
		} else {
			this.pageLoader.load(chapterToLoad.url);
			this.loadingId = chapterToLoad.id;
			this.loading = true;
		}

	}

	clearQueue() {

		this.loadQueue.splice(0, this.loadQueue.length);

	}

    handlePageLoadError() {

		if(this.errorHandler) this.errorHandler();
		this.handleLoadingCompleted();

    }

    handlePageLoadSuccess(html) {

		this.loadedChapters.set(this.loadingId, html);

		this.signals.chapterLoaded.dispatch({
			id: this.loadingId,
			template: html
		});

		if(this.successHandler) this.successHandler(html);

		this.handleLoadingCompleted();

	}

	handleLoadingCompleted() {

		this.loading = false;
		this.loadFromQueue();

	}

}

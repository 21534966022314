import View from 'Core/View.js';
import './product-row.scss';
import CartModel from 'data/CartModel';

export default class ProductRow extends View {

	initialize() {

		this.blur = this.blur.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.update = this.update.bind(this);
		this.sync = this.sync.bind(this);

		this.options = this.findAll('.product-row__option');
		this.options.forEach((option) => {
			const input = option.querySelector('.product-row__option-input');
			input.addEventListener('blur', this.blur);
			input.addEventListener('change', this.blur);
			input.addEventListener('keypress', this.onKeyPress);
		});

		CartModel.updatedSignal.add(this.update);
		this.signals.products.sync.add(this.sync);

		this.update();
	}

	onKeyPress(event) {
		var key = event.which || event.keyCode;
		if (key === 13) { // 13 = enter
			event.target.blur();
		}
	}

	blur(event) {
		const target = event.target;
		const productId = target.parentNode.dataset.id;
		const quantity = target.value;

		this.signals.products.sync.dispatch(this, productId, quantity);
	}

	update() {
		for(var i=0 ; i<this.options.length ; i++) {
			const option = this.options[i];
			const product = CartModel.getProduct(option.dataset.id);
			if (product) {
				const input = option.querySelector('.product-row__option-input');
				if (!input.disabled && input !== document.activeElement) {
					input.value = product.quantity;
				}
			}
		}
	}

	sync(source, productId, quantity) {
		if(source != this) {
			for(var i=0 ; i<this.options.length ; i++) {
				const option = this.options[i];
				if (productId == option.dataset.id) {
					const input = option.querySelector('.product-row__option-input');
					if (!input.disabled) {
						input.value = quantity;
					}
				}
			}
		}
	}

	destroy() {
		CartModel.updatedSignal.remove(this.update);
	}


}

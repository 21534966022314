import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

export default class SubmenuSlider extends View {

	initialize() {
		this.contentWrapper = this.find('.navigation__submenu-slider__inner');

		this.lastItem = null;
		this.currentColorClass = null;
		this.currentSubmenu = null;
	}

	destroy() {

	}

	appearForItem(item) {
		if (this.currentSubmenu) {
			this.currentSubmenu.parentNode.removeChild(this.currentSubmenu);
			this.currentSubmenu = null;
		}

		this._applyColorForItem(item);
		this._slideInNextSubmenuForItem(0, item);

		const bounds = this._getBoundsForItem(item);
		TweenLite.fromTo(this.el, 0.3, {
			x: bounds.x,
			y: -20,
			width: bounds.width,
			height: bounds.height,
			ease: Quad.easeInOut,
		}, {y: 0});

		this.lastItem = item;
	}

	slideToItem(item) {
		if (this.lastItem === item) {
			return;
		}

		const direction = this._isItemBeforeLast(item) ? 1 : -1;

		this._applyColorForItem(item);
		this._slideOutCurrentSubmenu(-1 * direction);
		this._slideInNextSubmenuForItem(direction, item);

		const bounds = this._getBoundsForItem(item);
		TweenLite.to(this.el, 0.3, {
			x: bounds.x,
			width: bounds.width,
			height: bounds.height,
			ease: Cubic.easeOut
		});

		this.lastItem = item;
	}

	/**
	 * @param {number} direction | -1 = left, 0 = down, 1 = right
	 * @param callback
	 * @private
	 */
	_slideOutCurrentSubmenu(direction, callback = null) {
		if (! this.currentSubmenu) {
			callback && callback();
			return;
		}

		const el = this.currentSubmenu;
		this.currentSubmenu = null;

		TweenLite.fromTo(el, 0.1, {
			y: direction === 0 ? -30 : 0,
		}, {
			x: 50 * direction,
			y: 0,
			alpha: 0,
			ease: Sine.easeOut,
			onComplete: () => {
				el.parentNode.removeChild(el);

				callback && callback();
			}
		});
	}

	_slideInNextSubmenuForItem(direction, item) {
		this.currentSubmenu = this._insertSubmenuOf(item);

		TweenLite.fromTo(
			this.currentSubmenu,
			0.3,
			{x: direction * 50, alpha: 0},
			{x: 0, alpha: 1, ease: Cubic.easeOut},
		);
	}

	_insertSubmenuOf(item) {
		const submenu = item.querySelector('.navigation__submenu-container');

		const content = document.createElement('div');
		content.className = 'navigation__submenu-slider__content';
		content.innerHTML = submenu ? submenu.innerHTML : '';

		this.contentWrapper.appendChild(content);

		return content;
	}

	_isItemBeforeLast(item) {
		return this.lastItem ? this.lastItem.offsetLeft < item.offsetLeft : false;
	}

	_applyColorForItem(item) {
		this.el.classList.remove(this.currentColorClass);

		// Construct the new color class
		const color = ['red', 'blue', 'white', 'gray'].find(color => {
			return item.className.indexOf('--' + color) > 0
		});
		this.currentColorClass = 'navigation__submenu-slider--' + color;

		this.el.classList.add(this.currentColorClass);
	}

	_getBoundsForItem(item) {
		const itemX = item.parentNode.offsetLeft + item.offsetLeft;
		const centerOfItem = itemX + item.offsetWidth / 2;
		const focusSubmenu = item.querySelector('.navigation__submenu-container');

		return {
			x: centerOfItem - focusSubmenu.clientWidth / 2,
			width: focusSubmenu.offsetWidth,
			height: focusSubmenu.offsetHeight + 32,
		}
	}
}

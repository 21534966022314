import BaseSplashView from './BaseSplash';

export default class Introduction extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.setupUI();
		this.setupTimeline();

		this.tl.play();

	}

	destroy() {


	}

	setupUI() {

		this.ui = {
			titleLines: this.el.querySelectorAll('.js-title-line'),
			stamp: this.el.querySelector('.js-stamp'),
		}

	}

	update(options) {

	}

	setupTimeline() {

		this.tl = new TimelineMax({paused: true, delay: 1});
		this.tl.staggerFrom(this.ui.titleLines, .2, {opacity: 0, ease: Power0.easeNone}, .125, 0);
		this.tl.staggerFrom(this.ui.titleLines, .8, {y: 100, ease: Power2.easeOut}, .125, 0);
		this.tl.from(this.ui.stamp, .1, {opacity: 0, ease: Power0.easeNone}, .8);
		this.tl.from(this.ui.stamp, .3, {scale: 1.2, transformOrigin: '50% 50%' , ease: Expo.easeOut}, .8);
		this.tl.from(this.ui.stamp, .4, {rotation: '-25deg', ease: Power2.easeOut}, .85);

	}

}

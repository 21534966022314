import View from 'Core/View';

import ArrowButton from '../components/ArrowButton';

import './subscription-payment-methods.scss';

export default class SubscriptionPaymentMethods extends View {

    initialize(options) {
		this.arrowButtons = this.findAll('.subscription-arrow-button').map(el => {
			return new ArrowButton({...options, el: el});
		});

		this.issuers = this.findAll('.subscription-payment-methods__issuers');
    }

    destroy() {
    }

	update(state) {
    	this.updateArrows(state.payment_method_id);
    	this.updateIssuers(state.payment_method_id);
	}

	visible(state) {
		return !! state.shipping_address_id && state.confirmed_address === 'yes';
	}

	updateIssuers(paymentMethodId) {
		this.issuers.forEach(el => {
			if (el.dataset.forMethod === paymentMethodId) {
				el.style.display = 'block';
			} else {
				el.style.display = 'none';
			}
		});
	}

	updateArrows(paymentMethodId) {
		this.arrowButtons.forEach(arrowButton => {
			const inputValue = this.find('input', arrowButton.el).value;
			if (inputValue === paymentMethodId) {
				arrowButton.open();
			} else {
				arrowButton.close();
			}
		});
	}
}

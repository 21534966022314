import DefaultPage from '../DefaultPage/DefaultPage.js';
import TweenLite from 'gsap/TweenLite';
import ScrollService from '../../../services/ScrollService.js';
import CartModel from 'data/CartModel';
import './shop-reseller.scss';

import Sticky from 'utils/sticky/Sticky.js';

const DEFAULT_PAGE_VERTICAL_DISPLACEMENT = 50;

const SHOP_RESELLER_CATEGORIES_OFFSET_Y = 176;
const SHOP_RESELLER_CATEGORIES_HEIGHT_MARGIN = 8;

const SHOP_RESELLER_STICKY_MENU_OFFSET_Y = 0;

export default class ShopReseller extends DefaultPage {

	initialize() {

		this.elementsOutsidePage = [document.querySelector('.shop-reseller__title'), document.querySelector('.shop-reseller__sub-title')];
		this.elementsOutsidePage.forEach((element) => {
			TweenLite.set(element, {opacity: 0, y: DEFAULT_PAGE_VERTICAL_DISPLACEMENT});
		});

		super.initialize();

		this.categoriesWrapper = this.find('.shop-reseller__categories-wrapper');
		this.stickyMenuWrapper = this.find('.shop-reseller__sticky-menu-wrapper ');
		this.stickyMenu = this.find('.shop-reseller__sticky-menu');
		this.addToBasketButton = this.find('.shop-reseller__add-to-basket-button');
		this.productRows = this.findAll('.product-row');
		this.categorySelectMobile = this.find('.shop-reseller__category-select-mobile select');
		this.categorySelectOptions = this.findAll('.shop-reseller__category-select-mobile select option');
		this.inputFields = this.findAll('.product-row__option-input');

		this.categoriesTitles = this.findAll('.shop-reseller__category');
		this.categories = this.findAll('.shop-reseller__category-select');
		this.categories.forEach((category) => {
			category.addEventListener('click', this.onCategoryClick.bind(this));
		});

		this.addToBasketButton.addEventListener('click', this.updateBasket.bind(this));

		// Create sticky menu
		this.sticky = new Sticky(this.categoriesWrapper, SHOP_RESELLER_CATEGORIES_OFFSET_Y);

		this.update = this.update.bind(this);
		this.onInputBlur = this.onInputBlur.bind(this);
		this.onCategorySelectChange = this.onCategorySelectChange.bind(this);
		this.resetActiveCategoryCheck = this.resetActiveCategoryCheck.bind(this);

		window.addEventListener('scroll', this.update);
		window.addEventListener('resize', this.update);

		this.categorySelectMobile.addEventListener('change', this.onCategorySelectChange);
		this.inputFields.forEach((input) => {
			input.addEventListener('blur', this.onInputBlur);
		})

		this.addBasketTracking();
		this.update();
	}

	show() {
		super.show();
		this.elementsOutsidePage.forEach((element) => {
			TweenLite.to(element, 0.3, {
				opacity: 1, y: 0,
				ease: Linear.easeNone
			});
		});
	}

	hide() {
		super.hide();
		this.elementsOutsidePage.forEach((element) => {
			TweenLite.to(element, 0.3, {
				opacity: 0, y: DEFAULT_PAGE_VERTICAL_DISPLACEMENT, delay: 0.3,
				ease: Linear.easeNone
			});
		});
	}

	update() {
		this.checkActiveCategory();
		this.sticky.update();
		this.checkStickyFooter();
	}

	onInputBlur() {
		this.updateBasket();
	}

	onCategoryClick(event) {
		event.preventDefault();
		const categoryId = event.currentTarget.getAttribute('href');
		const categoryHeader = this.find(categoryId);
		ScrollService.scrollPageToElement(categoryHeader, SHOP_RESELLER_CATEGORIES_OFFSET_Y);
	}

	onCategorySelectChange(event) {
		const categoryId = this.categorySelectMobile.value;
		const categoryHeader = this.find('#category-' + categoryId);

		ScrollService.scrollPageToElement(categoryHeader, SHOP_RESELLER_CATEGORIES_OFFSET_Y);

		this.ignoreActiveCategoryCheck = true;
		TweenLite.killDelayedCallsTo(this.resetActiveCategoryCheck);
		TweenLite.delayedCall(0.3, this.resetActiveCategoryCheck);
	}

	resetActiveCategoryCheck() {
		this.ignoreActiveCategoryCheck = false;
	}

	updateBasket() {
		CartModel.updateProducts(this.generateBasketData());
	}

	generateBasketData() {

		const productsData = [];

		// use regular for loop because IE11 crashes on forEach
		for(var i=0; i < this.productRows.length ; i++) {
			const row = this.productRows[i];
			const options = row.querySelectorAll('.product-row__option');

			for(var j=0; j < options.length ; j++) {
				const option = options[j];
				const input = option.querySelector('.product-row__option-input');
				const quantity = input.value;

				const data = {};
				data.product_id = option.dataset.id;
				data.wrapper_id = null;
				data.quantity = quantity;

				productsData.push(data);
			}
		}

		return productsData;
	}

	checkActiveCategory() {
		if(!this.ignoreActiveCategoryCheck) {
			var closestTitle = null;

			this.categoriesTitles.forEach((categoryTitle) => {
				const rect = categoryTitle.getBoundingClientRect();
				if (rect.top <= SHOP_RESELLER_CATEGORIES_OFFSET_Y + SHOP_RESELLER_CATEGORIES_HEIGHT_MARGIN) {
					closestTitle = categoryTitle;
				}
			});

			closestTitle = !closestTitle ? this.categoriesTitles[0] : closestTitle;

			const activeCategory = '#' + closestTitle.getAttribute('id');
			this.categories.forEach((category) => {
				if (category.getAttribute('href') === activeCategory) {
					category.classList.add('selected');
				} else {
					category.classList.remove('selected');
				}
			});

			// Update select
			const categoryId = closestTitle.getAttribute('id').split('-')[1];
			this.categorySelectOptions.forEach((option) => {
				option.selected = option.value == categoryId;
			});
		}
	}

	checkStickyFooter() {
		const rect = this.stickyMenuWrapper.getBoundingClientRect();
		const pageHeight = window.innerHeight;

		var isSticky = this.stickyMenu.classList.contains('sticky');
		if(rect.bottom > (pageHeight - SHOP_RESELLER_STICKY_MENU_OFFSET_Y) && !isSticky) {
			this.stickyMenu.classList.add('sticky');
			document.querySelector('main.container').appendChild(this.stickyMenu);
			isSticky = true;
		}

		if(rect.bottom < (pageHeight - SHOP_RESELLER_STICKY_MENU_OFFSET_Y) && isSticky) {
			this.stickyMenu.classList.remove('sticky');
			this.stickyMenuWrapper.appendChild(this.stickyMenu);
			isSticky = false;
		}

		if(isSticky) {
			TweenLite.set(this.stickyMenu, {width:rect.width, x:rect.left});
		} else {
			this.stickyMenu.style.width = 'auto';
			this.stickyMenu.style.transform = 'none';
		}
	}

	addBasketTracking() {
		const ec = this.signals.ecommerce;

		ec.cartUpdated.add((products) => {
			Object.values(products).forEach(product => {
				document.querySelectorAll(`.product-row[data-id="${product.id}"] input`)
				.forEach(input => input.value = product.quantity);
			})
		});
	}

	destroy() {
		super.destroy();

		window.removeEventListener('scroll', this.update);
		window.removeEventListener('resize', this.update);
	}
}

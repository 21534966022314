import Signal from 'signals';

export default {

	/**
	 * Sync product values while bypassing the CartModel, useful if a product is displayed
	 * multiple times on one page and therefore has multiple input fields to manage the quantity
	 * (e.g. reseller overview page)
	 *
	 * @param {Object} source
	 * @param {Number} productId
	 * @param {Number} quantity
	 */
	sync: new Signal(),

	/**
	 * Triggered when the options or other extra fields of a product have changed.
	 *
	 * @param {Object} data
	 */
	addToBasketDataUpdated: new Signal(),

	/**
	 * Show the unlimited edit/clone/new modal
	 *
	 * @param {String} uuid
	 */
	unlimitedEditRequested: new Signal(),

	/**
	 * Show the wrapper edit/clone/new modal
	 *
	 * @param {String} editUrl
	 * @param {String} duplicateAndEditUrl
	 */
	wrapperEditRequested: new Signal(),

	/**
	 * Show the wrapper order template
	 */
	orderTemplateRequested: new Signal(),

	/**
	 * Show the gift card edit/new modal
	 */
	giftCardEditRequested: new Signal(),

	/**
	 * Show add/edit postcard modal
	 */
	postcardEditRequested: new Signal()
};

import BaseSplashView from './BaseSplash';

import {TimelineMax, Power2, TweenLite} from 'gsap/all';

export default class Splash_19_9 extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.setupUI();
		this.setupTimeline();

	}

	setupUI() {

		this.ui = {
			titleLines: this.el.querySelectorAll('.js-title-line'),
			hand: this.el.querySelector('.js-hand'),
			fingers: [...this.el.querySelectorAll('.js-fingers')]
		}

	}

	update(options) {

		TweenLite.to(this.tl, options.duraton, {progress: options.progress});

	}

	setupTimeline() {

		this.tl = new TimelineMax({paused: true});
		this.tl.staggerFrom(this.ui.titleLines, 1, {y: '-300', ease: Power2.easeInOut}, .125, 0);
		this.tl.from(this.ui.hand, 1, {y: '600', ease: Power2.easeInOut}, .125, .8);
		this.tl.staggerTo(this.ui.fingers, .3, {y: -12, ease: Power2.easeInOut}, .125, 1.2);
		this.tl.staggerTo(this.ui.fingers, .3, {y: 0, ease: Power2.easeInOut}, .125, 1.5);

	}

}

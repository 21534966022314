import View from 'Core/View.js';

import './cookie-notification-minimal.scss';
import { allowCookies } from '../../../services/CookieConsent';

export default class CookieNotificationMinimal extends View {
	initialize() {
		this.handleAcceptClicked = this.handleAcceptClicked.bind(this);

		this.button = this.find('.cookie-notification-minimal__accept-button');
		this.button.addEventListener('click', this.handleAcceptClicked);

		if (this.wasApprovedEarlier()) {
			return;
		}

		// By default, allow everything. Users can opt out in the cookie settings.
		allowCookies({
			functional: true,
			analytics: true,
			marketing: true,
		});

		this.show();
		this.signals.modals.shown.add(this.handleModalShown, this);
	}

	destroy() {
		this.signals.modals.shown.remove(this.handleModalShown, this);
		this.button.removeEventListener('click', this.handleAcceptClicked);
	}

	show() {
		this.el.classList.add('cookie-notification-minimal--visible');
	}

	hide() {
		this.el.classList.remove('cookie-notification-minimal--visible');
	}

	wasApprovedEarlier() {
		return window.tonysCookieConsent.functional;
	}

	handleAcceptClicked(event) {
		event.preventDefault();

		this.hide();
	}

	handleModalShown(modalId) {
		if (modalId === 'cookie-settings') {
			this.hide();
		}
	}
}

import View from 'Core/View.js';
import readCookie from '../../../shared/utils/cookie/readCookie';
import createCookie from '../../../shared/utils/cookie/createCookie';

import './notification-banner.scss';

const DISMISS_COOKIE_NAME = 'notificationBannerDismissed';
const container = document.body.querySelector('.notification-banner-container');

export default class NotificationBanner extends View {

	initialize() {
		this.handleCloseClick = this.handleCloseClick.bind(this);
		this.hide = this.hide.bind(this);
		this.show = this.show.bind(this);
		this.destroy = this.destroy.bind(this);

		// Listen to close
		this.closeButton = this.find('.notification-banner__close');
		this.closeButton.addEventListener('click', this.handleCloseClick);

		this.rememberDismiss = this.el.dataset.rememberDismiss === 'true';
		this.signals.pageLoad.started.add(this.hide);

		// Store the first 40 characters in json stringify format
		this.text = JSON.stringify(this.find('.notification-banner__text').innerHTML.substr(0, 40));
		if (this.hasDimissed(this.text)) {
			this.destroy();
			return;
		}

		this.showTimeout = setTimeout(this.show, 200);
		container.appendChild(this.el);
	}

	destroy() {
		if (! this.el.parentNode) {
			// Already destroyed
			return;
		}

		this.signals.pageLoad.started.remove(this.hide);
		this.closeButton.removeEventListener('click', this.handleCloseClick);
		this.el.parentNode.removeChild(this.el);
	}

	handleCloseClick(event) {
		event.preventDefault();
		this.hide();

		if (this.rememberDismiss) {
			createCookie(DISMISS_COOKIE_NAME, this.text, 1);
		}
	}

	show() {
		this.el.classList.remove('notification-banner--hibbem');
	}

	hide() {
		clearTimeout(this.showTimeout);
		this.el.classList.add('notification-banner--hibbem');
		setTimeout(this.destroy, 500);
	}

	hasDimissed(text) {
		return readCookie(DISMISS_COOKIE_NAME) === text;
	}
}

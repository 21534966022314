import Signal from 'signals';

export default {

	/**
	 * Triggered before a form is submitted
	 *
	 * @param {string} formId
	 * @param {FormData} data
	 */
	beforeSubmit: new Signal(),

	/**
	 * Triggered when a form is submitted
	 *
	 * @param {string} formId
	 * @param {object} requestData
	 */
	submitted: new Signal(),

	/**
	 * Triggered when a form submission failed
	 *
	 * @param {string} formId
	 * @param {object} errors
	 * @param {object} requestData
	 */
	failed: new Signal(),

	/**
	 * Triggered when a form is submitted successfully
	 *
	 * @param {string} formId
	 * @param {object} responseData
	 * @param {object} requestData
	 */
	succeeded: new Signal(),

	/**
	 * When a form fails, but no errors are returned, the response was
	 * probably not valid JSON due to an HTTP 500 or something. We can
	 * then assume the submission crashed and we should probably show
	 * an error to the user.
	 *
	 * @param {string} formId
	 * @param {object} requestData
	 */
	crashed: new Signal(),

	/**
	 * Triggered when the errors are about to be displayed
	 *
	 * @param {string} formId
	 * @param {object} errors
	 */
	beforeErrorsDisplay: new Signal(),

	/**
	 * Triggered after the errors are displayed
	 *
	 * @param {string} formId
	 * @param {object} errors
	 */
	afterErrorsDisplay: new Signal(),
};

import View from 'Core/View.js';

export default class ClassBasedAnimation extends View {

	initialize(options) {
		this.lastStepClass = null;
		this.steps = options.steps;
	}

	destroy() {

	}

	setPercentage(percentage) {
		const index = 1 + Math.floor(percentage * (this.steps - 1));

		this.el.classList.remove(this.lastStepClass);

		this.lastStepClass = 'class-animation--step-' + index;

		this.el.classList.add(this.lastStepClass);
	}
}

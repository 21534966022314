export default function(apiKey, callback) {
	if (typeof google === 'object' && typeof google.maps === 'object') {
		callback();
		return;
	}

	window.onGoogleMapsAPIReady = function() {
		callback();
		window.onGoogleMapsAPIReady = null;
	};

	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = 'https://maps.googleapis.com/maps/api/js?key=%s&callback=onGoogleMapsAPIReady&libraries=geometry'.replace('%s', apiKey);
	document.body.appendChild(script);
}

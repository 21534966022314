/**
 * Created by hugo on 03/08/17.
 */

export default class CartRequest {

	constructor(request, success, failure ) {
		this.request = request;
		this.success = success;
		this.failure = failure;
	}

	update(data) {
		this.request._data = data;
	}

	execute() {
		this.request.then(this.success, this.failure);
	}

}
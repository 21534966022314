import View from 'Core/View.js';

import ReframedSocialShare from './components/ReframedSocialShare';
import ReframedTile from './components/ReframedTile';
import ReframedVideo from './components/ReframedVideo';

import './fonts.scss';
import './reframed.scss';

export default class Reframed extends View {

	initialize(options) {
		this.handleAllStoriesClicked = this.handleAllStoriesClicked.bind(this);

		this.shares = this.findAll('.reframed__story-detail__social').map(el => new ReframedSocialShare({
			...options,
			el: el
		}));
		this.tiles = this.findAll('.reframed__stories__tile').map(el => new ReframedTile({...options, el}));
		this.videos = this.findAll('.reframed__video').map(el => new ReframedVideo({...options, el}));

		this.allStoriesButton = this.find('.reframed__stories__all');
		this.allStoriesButton.addEventListener('click', this.handleAllStoriesClicked);

		if (this.isDeeplinkToStories(options.routeAtLoad)) {
			options.modalDictionary[options.routeAtLoad.hash].show();
		}
	}

	destroy() {
		this.shares.forEach(share => share.destroy());
		this.tiles.forEach(tile => tile.destroy());
		this.videos.forEach(video => video.destroy());

		this.allStoriesButton.removeEventListener('click', this.handleAllStoriesClicked);
	}

	handleAllStoriesClicked(event) {
		event.preventDefault();

		this.el.classList.add('reframed__stories--show-all');
	}

	isDeeplinkToStories(route) {
		return route.hash && route.hash.indexOf('stories/') === 0;
	}
}

import View from 'Core/View.js';

import './generic-form.scss';

export default class GenericForm extends View {

	initialize() {
		this.handleFormSubmit = this.handleFormSubmit.bind(this);

		this.signals.forms.succeeded.add(this.handleFormSubmit);
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.handleFormSubmit);
	}

	handleFormSubmit(formId) {
		if (formId === this.el.id) {
			this.el.reset();
		}
	}
}
import Signal from 'signals';
import TweenLite from 'gsap/TweenLite';
import './faq-question.scss';

export default class FAQQuestion {

	constructor(el) {
		this.el = el;

		this.expandSignal = new Signal();
		this.expandedSignal = new Signal();
		this.collapseSignal = new Signal();
		this.collapsedSignal = new Signal();

		this.question = this.el.querySelector('.faq-question__question');
		this.answer = this.el.querySelector('.faq-question__answer');
		this.answerWrapper = this.el.querySelector('.faq-question__answer-wrapper');

		this.categoryId = this.el.dataset.categoryId;
		this.hidden = false;
		this.isMostAsked = this.el.dataset.type === 'most-asked';
		this.searchText = (this.question.textContent + ' ' + this.answer.textContent).toLowerCase();


		this.question.addEventListener('click', this.toggle.bind(this));

		this.collapse();
		this.update();
	}

	destroy() {
	}

	toggle() {
		if (this.el.classList.contains('expanded')) {
			this.collapse();
		} else {
			this.expand();
		}
	}

	containsText(query) {
		const contains = this.searchText.indexOf(query) >= 0;
		return contains
	}

	getAnswer() {
		return this.answer;
	}

	show() {
		if(this.hidden) {
			this.hidden = false;
			this.el.classList.remove('hidden');
		}
	}

	hide() {
		if(!this.hidden) {
			this.hidden = true;
			this.el.classList.add('hidden');
		}
	}

	update() {
	}

	expand() {
		this.el.classList.add('expanded');
		TweenLite.to(this.answerWrapper, 0.3, {
			height: this.getContentHeight(), ease: Sine.easeInOut,
			onComplete: this.onExpanded,
			onCompleteScope: this
		});

		this.expandSignal.dispatch(this);
	}

	onExpanded() {
		this.expandedSignal.dispatch(this);
	}

	collapse() {
		this.collapseSignal.dispatch(this);

		this.el.classList.remove('expanded');
		TweenLite.to(this.answerWrapper, 0.3, {
			height: 0, ease: Sine.easeInOut,
			onComplete: this.onCollapsed,
			onCompleteScope: this
		});
	}

	onCollapsed() {
		this.collapsedSignal.dispatch(this);
	}

	getContentHeight() {
		return this.answer.offsetHeight;
	}
}
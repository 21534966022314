import View from 'Core/View.js';

import './product-detail-column.scss';

export default class ProductDetailColumn extends View {

	initialize() {

		this.expand = this.expand.bind(this);
		this.collapse = this.collapse.bind(this);
		this.toggle = this.toggle.bind(this);
		this.update = this.update.bind(this);

		this.toggleButton = this.find('.product-detail-column__toggle');
		this.content = this.find('.product-detail-column__content');
		this.contentWrapper = this.find('.product-detail-column__content-wrapper');

		if (this.toggleButton) {
			this.toggleButton.addEventListener('click', this.toggle);
		}

		window.addEventListener('resize', this.update);
	}

	destroy() {
		if (this.toggleButton) {
			this.toggleButton.removeEventListener('click', this.toggle);
		}

		window.removeEventListener('resize', this.update);
	}

	toggle(event) {
		event.preventDefault();
		if(this.el.classList.contains('product-detail-column--collapsed')) {
			this.expand();
		} else {
			this.collapse();
		}
	}

	collapse() {
		this.contentWrapper.style.height = '';
		this.el.classList.remove('product-detail-column--collapsed');
		this.el.classList.add('product-detail-column--collapsed');
	}

	expand() {
		this.contentWrapper.style.height = this.content.clientHeight + 'px';
		this.el.classList.remove('product-detail-column--collapsed');
	}

	update() {
		if(this.el.classList.contains('product-detail-column--collapsed')) {
			this.collapse();
		} else {
			this.expand();
		}
	}
}

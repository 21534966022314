import View from 'Core/View.js';
import Icon from '!raw-loader!img-loader!./arrow.svg';

import './select.scss';

export default class Select extends View {

	initialize() {
		this.el.classList.add('select--replaced');

		this.insertSVG();
	}

	destroy() {
	}

	insertSVG() {
		const svg = document.createElement('div');
		svg.className = 'select__svg';
		svg.innerHTML = Icon;

		this.el.insertBefore(svg, this.find('.select__select').nextSibling);
	}
}

import View from 'Core/View.js';
import CartModel from 'data/CartModel';

export default class RepeatOrderWarning extends View {

	initialize(options) {
		this.modal = options.modalDictionary[this.el.id];
		this.onSubmitted = this.onSubmitted.bind(this);
		this.signals.forms.succeeded.add(this.onSubmitted);
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.onSubmitted);
	}

	onSubmitted(formId, data) {
		if (formId !== 'repeat-order-form') {
			return;
		}

		this.showWarningsIfAny(data);
		CartModel.load();
	}

	showWarningsIfAny(data) {
		if (! data.notices || data.notices.length === 0) {
			return;
		}

		const notices = data.notices.map(message => '<p>' + message + '</p>').join();

		this.find('.repeat-order-warning__notices').innerHTML = notices;
		this.modal.show();
	}

}
import View from 'Core/View.js';

import './clouds.scss';

export default class Clouds extends View {

	initialize(options) {
		this.widgetArea = this.find('.widget-area');
		this.clouds = [];

		this.cloudsContainer = document.createElement('div');
		this.cloudsContainer.classList.add('clouds-container');

		this.widgetArea.classList.add('clouds');
		this.widgetArea.insertAdjacentElement('afterbegin', this.cloudsContainer);

		this.addClouds();
	}

	destroy() {
		this.cloudsContainer.remove();
		this.widgetArea.classList.remove('clouds');
		// this.cloudsContainer.forEach((cloud) => {
		// 	this.widgetArea.removeChild(cloud);
		// });
	}

	createCloud() {
		const cloud = document.createElement('div');
		cloud.classList.add('clouds__cloud');

		const size = Math.random() * 150 + 150;
		const speed = ((Math.random() * 4 + 1) /100)/(1/window.innerWidth);
		const variant = Math.round(Math.random() * 4 + 1);

		cloud.classList.add(`clouds__cloud--${variant}`);

		cloud.style.width = size + 'px';
		cloud.style.height = size + 'px';

		cloud.style.top = Math.random() * 100 - 20 + '%';
		cloud.style.animationDuration = speed + 's';
		cloud.style.animationDelay = -Math.random() * speed + 's';

		this.clouds.push(cloud);
		this.cloudsContainer.insertAdjacentElement('afterbegin', cloud);
	}

	addClouds() {
		const numberOfClouds = Math.floor(window.innerWidth / 150) + 2
		for (let i = 0; i < numberOfClouds; i++ ) {
			this.createCloud();
		}
	}
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './focus-catcher.scss';

class FocusCatcher extends Component {

	constructor(props) {
		super(props);

		this.parent = null;

		this.handleDocumentClicked = this.handleDocumentClicked.bind(this);
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleDocumentClicked);
		document.addEventListener('touchstart', this.handleDocumentClicked);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleDocumentClicked);
		document.removeEventListener('touchstart', this.handleDocumentClicked);
	}

	render() {
		const classes = classNames({
			'focus-catcher': true,
			'focus-catcher--lock-screen': this.props.lockScreen,
		});

		return (
			<span ref={el => el ? this.parent = el.parentNode : null} className={classes}/>
		);
	}

	handleDocumentClicked(event) {
		if (this.props.lockScreen) {
			return;
		}

		if (this.isDescendantOf(event.target, this.parent)) {
			if (this.props.onFocusCaught && ! this.props.hasFocus) {
				this.props.onFocusCaught();
			}
			return;
		}

		if (this.props.onFocusLost && this.props.hasFocus) {
			this.props.onFocusLost();
		}
	}

	isDescendantOf(element, parent) {
		let parentNode = element.parentNode;
		while (parentNode !== null) {
			if (parentNode === parent) {
				return true;
			}

			parentNode = parentNode.parentNode;
		}

		return false;
	}
}

FocusCatcher.propTypes = {
	hasFocus: PropTypes.bool,
	lockScreen: PropTypes.bool,
	onFocusCaught: PropTypes.func,
	onFocusLost: PropTypes.func,
};

FocusCatcher.defaultProps = {
	hasFocus: false,
	lockScreen: false,
};

export default FocusCatcher;
import View from '../../../shared/Core/View';
import ScrollService from '../../../services/ScrollService';

import './shop-links.scss';

export default class ShopLinks extends View {

    initialize() {
		this.handleToTopClick = this.handleToTopClick.bind(this);

		this.toTop = this.find('.shop-links__to-top');

		if (this.toTop) {
			this.toTop.addEventListener('click', this.handleToTopClick);
		}
    }

    destroy() {
		if (this.toTop) {
			this.toTop.removeEventListener('click', this.handleToTopClick);
		}
    }

	handleToTopClick(event) {
		event.preventDefault();

		ScrollService.scrollPageTo(0);
	}
}

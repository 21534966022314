import View from 'Core/View.js';

export default class ModalTrigger extends View {

	initialize(options) {
		this.modalDictionary = options.modalDictionary;

		this.handleClick = this.handleClick.bind(this);

		if (this.changeHashWhenShowingAndHiding()) {
			this.handleModalHide = this.handleModalHide.bind(this);

			this.routes = options.routes;
			this.loadRoute = options.signals.pageLoad.requested.dispatch;
			this.hideModalSignal = options.signals.modals.hide;

			this.hideModalSignal.add(this.handleModalHide);
		}

		this.modalTriggeredSignal = options.signals.modals.triggered;

		this.el.addEventListener('click', this.handleClick);
	}

	destroy() {
		if (this.hideModalSignal) {
			this.hideModalSignal.add(this.handleModalHide);
		}
		this.el.removeEventListener('click', this.handleClick);
	}

	handleClick(event) {
		if (! this.changeHashWhenShowingAndHiding()) {
			event.preventDefault();
			event.stopPropagation();
		}

		this.modalTriggeredSignal.dispatch(this.getModalIdentifier(), this.el);
		this.modalDictionary[this.getModalIdentifier()].show();
	}

	handleModalHide(modalId) {
		if (! this.changeHashWhenShowingAndHiding()) {
			return;
		}

		if (modalId !== this.getModalIdentifier()) {
			return;
		}

		const route = this.routes.getRouteWithoutHash(this.routes.getCurrentLocation());
		route.replace = true;

		this.loadRoute(route);
	}

	getModalIdentifier() {
		if (this.el.dataset.modal) {
			return this.el.dataset.modal;
		}

		if (this.el.hasAttribute('href')) {
			return this.el.getAttribute('href').substr(1);
		}

		throw new Error('[TONYS] Could not find modal identifier');
	}

	changeHashWhenShowingAndHiding() {
		return ! ! this.el.dataset.updateHash;
	}
}

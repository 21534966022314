import React from 'react';
import PropTypes from 'prop-types';

import OptionsList from '../propTypes/OptionsList';
import Select from '../../../reactComponents/Select/Select';

import './product-options-select.scss';

class ProductOptionsSelect extends React.PureComponent {
	render() {
		const options = this.props.options
			.sort((a, b) => a.position - b.position)
			.map(option => {
				const isOutOfStock = option.maxQuantity === 0;

				return {
					value: option.hash,
					label: isOutOfStock ? `${option.label} (${this.props.outOfStockMessage})` : option.label,
					disabled: isOutOfStock,
				};
			});

		if (this.props.placeholder) {
			options.unshift({
				value: '',
				label: this.props.placeholder,
			});
		}

		return (
			<Select
				className="product-options-select"
				inputClassName="product-options-select__input"
				name={this.props.name || 'option'}
				options={options}
				defaultValue={this.props.defaultValue}
				onChange={this.props.onChange}
				disabled={this.props.disabled}
			/>
		);
	}
}

ProductOptionsSelect.propTypes = {
	name: PropTypes.string.isRequired,
	options: OptionsList.isRequired,
	outOfStockMessage: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
};

export default ProductOptionsSelect;

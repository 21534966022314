import View from 'Core/View';
import ConversationBarsFilter from './ConversationBarsFilter';

import './conversation-bars-filters.scss';

export default class ConversationBarsFilters extends View {

	initialize(options) {
		this.filters = this.findAll('.conversation-bars__filter').map(el => new ConversationBarsFilter({...options, el}))[0];
	}

	destroy() {
		this.filters.forEach((filter) => filter.destroy());
		super.destroy();
	}
}

import View from 'Core/View.js';

import './graphics/left.svg';
import './graphics/right.svg';
import './graphics/plus.svg';

import './product-detail-image-gallery.scss';
import './product-detail-image-gallery-preview.scss';

export default class ProductDetailImageGallery extends View {

	initialize(options) {
		this.itemsContainer = this.el;
		this.imageWrappers = this.findAll('img');

		this.imageSelectedSignal = options.imageSelectedSignal;

		this.mainImageLoaded = this.mainImageLoaded.bind(this);
		this.handleItemsClicked = this.handleItemsClicked.bind(this);
		this.el.addEventListener('click', this.handleItemsClicked);
		if(this.imageWrappers[0]) {
			this.imageWrappers[0].addEventListener('load', this.mainImageLoaded);
			this.mainImageLoaded(null, this.imageWrappers[0]);
		}

		this.lastProgress = 0;

	}

	destroy() {
		if(this.imageWrappers[0]) {
			this.imageWrappers[0].removeEventListener('load', this.mainImageLoaded);
		}
		this.itemsContainer.removeEventListener('click', this.handleItemsClicked);
	}

	handleItemsClicked(event) {
		event.preventDefault();
		const imageElement = event.target;
		const url = imageElement.dataset.original;
		this.imageSelectedSignal.dispatch(url);
	}

	mainImageLoaded(event, targetImage) {
		targetImage = event ? event.target : targetImage;

		if(targetImage.naturalHeight <= 0 || targetImage.naturalWidth <= 0) {
			return;
		}

		const percentage = Math.round(Math.min(targetImage.naturalHeight / targetImage.naturalWidth, 1) * 100);
		targetImage.parentElement.style.paddingTop = percentage + '%';
	}
}

import {Circ} from 'gsap';
import TimelineMax from 'gsap/TimelineMax';
import View from 'Core/View.js';

import CartModel from '../../../shared/data/CartModel';
import {LOCK_ID} from '../../components/CartOverlay/CartOverlay';
import debounce from '../../../shared/utils/generic/debounce';

import './sticky-mini-cart.scss';

export default class StickyMiniCart extends View {

	initialize() {
		this.handleCartUpdated = this.handleCartUpdated.bind(this);
		this.handleNewProductAdded = this.handleNewProductAdded.bind(this);
		this.handleCartError = this.handleCartError.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.updateCounter = this.updateCounter.bind(this);
		this.throttledAdjustAmount = debounce(this.showTotalQuantityUpdatedAnimation.bind(this), 100, false);

		this.productImageEl = this.find('.sticky-mini-cart__product-image');
		this.cartIconEl = this.find('.sticky-mini-cart__cart-icon');
		this.counterEl = this.find('.sticky-mini-cart__counter');

		this.justAddedNewProduct = false;

		this.counter = this.findViewForElement(this.counterEl);

		CartModel.updatedSignal.add(this.handleCartUpdated);
		CartModel.newProductSignal.add(this.handleNewProductAdded);
		CartModel.errorSignal.add(this.handleCartError);
		CartModel.load();

		this.el.addEventListener('click', this.handleClick);

		this.updateCounter();
	}

	destroy() {
		CartModel.updatedSignal.remove(this.handleCartUpdated);
		CartModel.newProductSignal.remove(this.handleNewProductAdded);
		CartModel.errorSignal.remove(this.handleCartError);

		this.el.removeEventListener('click', this.handleClick);

		this.counter = null;
	}

	/**
	 * Wait for Cart to be updated and update initial amount accordingly
	 */
	handleCartUpdated(products, details) {
		if (details.previousProductCount === details.productCount) {
			return;
		}

		this.throttledAdjustAmount();
	}

	handleNewProductAdded() {
		const justAddedProduct = CartModel.getProducts()[0];

		this.productImageEl.src = justAddedProduct.image;
		this.productImageEl.style.display = justAddedProduct.image ? 'block' : 'none';

		this.justAddedNewProduct = true;

		const tl = new TimelineMax();
		tl.fromTo(this.productImageEl, 0.4, {
			scale: 0.6,
			opacity: 0.6,
		}, {
			opacity: 1,
			scale: 1,
			transformOrigin: '50% 50%',
			ease: Circ.easeOut,
		});
		tl.to(this.cartIconEl, 0.4, {scale: 1.2, rotation: '7deg', y: 30, ease: Circ.easeOut}, 0);
		tl.addCallback(this.updateCounter);
		tl.to(this.cartIconEl, 0.35, {scale: 1, rotation: 0, y: 0, ease: Circ.easeInOut});
		tl.to(this.productImageEl, 0.35, {scale: 0.6, opacity: 0.6, ease: Circ.easeInOut}, '-=0.35');
		tl.set(this.productImageEl, {opacity: 0});
		tl.addCallback(() => this.justAddedNewProduct = false);

		return tl;
	}

	/**
	 * Listen to cart errors on order to adjust the current amount
	 */
	handleCartError() {
		this.throttledAdjustAmount();
	}

	showTotalQuantityUpdatedAnimation() {
		if (!this.counter) {
			console.error('[TONYS] Cannot find counter to update');
			return;
		}

		if (this.justAddedNewProduct) {
			return;
		}

		const tl = new TimelineMax();
		tl.to(this.cartIconEl, 0.3, {scale: 1.2, rotation: '-5deg', ease: Circ.easeOut}, 0);
		tl.addCallback(this.updateCounter);
		tl.to(this.cartIconEl, 0.35, {scale: 1, rotation: 0, ease: Circ.easeInOut});
	}

	handleClick(event) {
		event.preventDefault();

		this.openCart();
	}

	openCart() {
		this.signals.pageLock.lockRequested.dispatch(LOCK_ID);
	}

	updateCounter() {
		this.counter.updateAmount(CartModel.getProductCount());
	}
}

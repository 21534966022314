import TweenLite from 'gsap/TweenLite';

export default class Sticky {

	constructor(target, minOffsetY = 176) {
		this.target = target;
		this.container = target.parentNode;
		this.target.style.position = 'absolute';
		this.minOffsetY = minOffsetY;

		this.update();
	}

	update() {
		const containerBounds = this.container.getBoundingClientRect();
		const targetBounds = this.target.getBoundingClientRect();

		const maxY = containerBounds.height - targetBounds.height;

		const offsetY = Math.max(this.minOffsetY - containerBounds.top, 0);
		const y = Math.max(0, Math.min(maxY, offsetY));

		TweenLite.killTweensOf(this.target);
		TweenLite.to(this.target, 0.3, {y:y, delay:0.1, ease:Sine.easeInOut});
	}
}
import View from 'Core/View';

import './subscription-plan-types.scss';
import ArrowButton from '../components/ArrowButton';

export default class SubscriptionPlanTypes extends View {

	initialize(options) {
		this.arrowButtons = this.findAll('.subscription-arrow-button').map(el => {
			return new ArrowButton({...options, el: el});
		});
	}

	destroy() {
	}

	update(state, prevState) {
		if (state.plan_type !== prevState.plan_type) {
			this.updateArrows(state.plan_type);
		}
	}

	visible(state) {
		return state.skipped_intro === '1';
	}

	updateArrows(planType) {
		this.arrowButtons.forEach(arrowButton => {
			const inputValue = this.find('input', arrowButton.el).value;
			if (inputValue === planType) {
				arrowButton.open();
			} else {
				arrowButton.close();
			}
		});
	}
}

import View from 'Core/View.js';
import ImpactDesktop from './ImpactDesktop';
import ImpactMobile from './ImpactMobile';

import './styles/impact-desktop.scss';
import './styles/impact-mobile.scss';

export default class Impact extends View {

	initialize() {

		// Necessary for Microsoft Edge
		this.el.parentElement.style.overflow = 'hidden';

		this.globalSettings = JSON.parse(this.el.dataset['settings']);
		this.translations = JSON.parse(this.el.dataset['translations']);

		this.impactDesktopElement = this.find('.impact-desktop');
		this.impactMobileElement = this.find('.impact-mobile');

		this.impactDesktop = new ImpactDesktop(this.impactDesktopElement, this.globalSettings, this.translations);
		this.impactMobile = new ImpactMobile(this.impactMobileElement, this.globalSettings, this.translations);

		this.meters = [
			this.impactDesktop,
			this.impactMobile
		];

		this.onResize = this.onResize.bind(this);
		this.tick = this.tick.bind(this);

		this.signals.windowResized.add(this.onResize);
		this.start();

		TweenLite.delayedCall(1, this.onResize);
	}

	start() {
		this.tickInterval = setInterval(this.tick, 1000);
		this.tick();
		this.onResize();
	}

	stop() {
		clearInterval(this.tickInterval);
	}

	onResize() {

		const elementWidth = this.el.offsetWidth;
		if(elementWidth < 768) {
			this.impactMobile.show();
			this.impactDesktop.hide();
		} else {
			this.impactMobile.hide();
			this.impactDesktop.show();
		}

		this.meters.forEach((meter) => {
			meter.onResize();
		});
	}

	tick() {
		this.meters.forEach((meter) => {
			meter.tick();
		});
	}

	destroy() {
		this.meters.forEach((meter) => {
			meter.destroy();
		});

		this.signals.windowResized.remove(this.onResize);
		this.stop();
	}
}
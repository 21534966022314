import View from '../../../shared/Core/View';

import './payment-link.scss';

export default class PaymentLink extends View {

    initialize() {

    }

    destroy() {
    }
}

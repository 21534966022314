import template from 'lodash.template';

import View from '../../../shared/Core/View';
import CartModel from '../../../shared/data/CartModel';
import createEvent from 'utils/event/create.js';

import './discount-or-gift-cards.scss';

const COLLAPSED_CLS = 'discount-or-gift-cards--collapsed'

export default class DiscountOrGiftCards extends View {

	initialize() {
		this.showForm = this.showForm.bind(this);
		this.hideForm = this.hideForm.bind(this);
		this.toggleForm = this.toggleForm.bind(this);

		this.handleCartUpdated = this.handleCartUpdated.bind(this);
		this.handleDiscountApplied = this.handleDiscountApplied.bind(this);

		this.transDiscount = template(this.el.dataset.transDiscount);
		this.transGiftCard = template(this.el.dataset.transGiftCard);
		this.transGiftCardPartial = template(this.el.dataset.transGiftCardPartial);
		this.appliedDiscountTemplate = template(document.querySelector('#discount-or-gift-cards-applied').innerHTML);

		this.toggleButton = this.find('.discount-or-gift-cards__toggle');
		this.codeInputEl = this.find('.discount-or-gift-cards__input');
		this.appliedDiscountsEl = this.find('.discount-or-gift-cards__applied');

		this.signals.forms.succeeded.add(this.handleDiscountApplied);
		CartModel.updatedSignal.add(this.handleCartUpdated);

		this.toggleButton.addEventListener('click', this.toggleForm);

		this.renderDiscountsAndGiftCards();
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.handleDiscountApplied);
		CartModel.updatedSignal.remove(this.handleCartUpdated);
	}

	handleDiscountApplied(formId, data) {
		if (formId !== 'discount-or-gift') {
			return;
		}

		this.codeInputEl.value = '';
		this.codeInputEl.dispatchEvent(createEvent('change'));

		CartModel.load(true);

		this.signals.shop.discountCodeOrGiftCardApplied.dispatch(data.cart.meta.discountCode);
	}

	showForm() {
		this.el.classList.remove(COLLAPSED_CLS);
	}

	hideForm() {
		this.el.classList.remove(COLLAPSED_CLS);
		this.el.classList.add(COLLAPSED_CLS);
	}

	toggleForm() {
		if(this.el.classList.contains(COLLAPSED_CLS)) {
			this.showForm();
		} else {
			this.hideForm();
		}
	}

	handleCartUpdated() {
		this.renderDiscountsAndGiftCards();
	}

	renderDiscountsAndGiftCards() {
		const meta = CartModel.getMeta();
		if (!meta) {
			return;
		}

		const applied = [];
		if (meta.discountCode) {
			applied.push({
				label: this.transDiscount({
					code: meta.discountCode,
					amount: meta.formattedDiscountPrice,
				}),
				onRemove: () => this.removeDiscountCode(),
			});
		}

		if (meta.validGiftCards) {
			meta.validGiftCards.forEach(giftCard => {
				if (parseInt(giftCard.used_amount) === 0) {
					applied.push({
						label: this.transGiftCard({
							code: giftCard.code,
							amount: giftCard.formatted_remaining_amount,
						}),
						onRemove: () => this.removeGiftCard(giftCard.code),
					});
				} else {
					applied.push({
						label: this.transGiftCardPartial({
							code: giftCard.code,
							originalAmount: giftCard.formatted_remaining_amount,
							remainingAmount: giftCard.formatted_new_amount,
						}),
						onRemove: () => this.removeGiftCard(giftCard.code),
					});
				}
			});
		}

		this.replaceApplied(applied);
	}

	replaceApplied(applied) {
		const newAppliedNode = document.createElement('ul');
		newAppliedNode.className = this.appliedDiscountsEl.className;

		applied.forEach(discount => {
			const node = document.createElement('li');
			node.innerHTML = this.appliedDiscountTemplate({label: discount.label});
			node.querySelector('a').addEventListener('click', (event) => {
				event.preventDefault();

				node.classList.add('discount-or-gift-cards__applied-code--removing');

				discount.onRemove();
			});

			newAppliedNode.appendChild(node);
		});


		this.appliedDiscountsEl.parentNode.replaceChild(newAppliedNode, this.appliedDiscountsEl)
		this.appliedDiscountsEl = newAppliedNode;
	}

	removeGiftCard(giftCardCode) {
		CartModel.requestSuccessSignal.addOnce(() => {
			this.signals.shop.discountCodeOrGiftCardRemoved.dispatch();
		});

		CartModel.removeGiftCard(giftCardCode);
	}

	removeDiscountCode() {
		CartModel.requestSuccessSignal.addOnce(() => {
			this.signals.shop.discountCodeOrGiftCardRemoved.dispatch();
		});

		CartModel.clearDiscount();
	}
}

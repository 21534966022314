import View from 'Core/View.js';

import './manifesto.scss';

export default class Manifesto extends View {


	constructor(options) {
		super(options);

		this.resize = this.resize.bind(this);
		this.update = this.update.bind(this);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);

		this.index = 0;
		this.items = this.findAll('.manifesto__item');
		this.navigation = this.find('.manifesto__navigation');
		this.arrowUp = this.find('.manifesto__arrow-up');
		this.arrowDown = this.find('.manifesto__arrow-down');

		this.indexElement = this.find('.manifesto__index');
		this.totalElement = this.find('.manifesto__total');

		this.arrowDown.addEventListener('click', this.next);
		this.arrowUp.addEventListener('click', this.previous);

		window.addEventListener('resize', this.resize);
		this.resize();
	}

	initialize() {

	}

	destroy() {
		window.removeEventListener('resize', this.resize);
	}

	resize() {
		let maxHeight = -1;
		this.items.forEach((item) => {
			const h = item.clientHeight;
			maxHeight = h > maxHeight ? h : maxHeight;
		});

		this.el.style.height = maxHeight + 'px';

		this.update();
	}

	next() {
		this.index++;
		this.update(1);
	}

	previous() {
		this.index--;
		this.update(-1);
	}

	update(direction = 0) {
		this.index = this.index < 0 ? 0 : this.index;
		this.index = this.index > this.items.length-1 ? this.items.length-1 : this.index;

		this.indexElement.innerHTML = this.index + 1;

		this.arrowDown.classList.remove('manifesto__arrow--disabled');
		this.arrowUp.classList.remove('manifesto__arrow--disabled');

		if(this.index === 0) {
			this.arrowUp.classList.add('manifesto__arrow--disabled');
		}
		if(this.index >= this.items.length - 1) {
			this.arrowDown.classList.add('manifesto__arrow--disabled');
		}


		this.items.forEach((item, i) => {
			if(i === this.index) {
				item.classList.remove('manifesto__item-hidden');
				item.classList.remove('manifesto__item-hidden-up');
				item.classList.remove('manifesto__item-hidden-down');
			} else {
				const transition = (i-this.index) > 0 ? 'manifesto__item-hidden-down' : 'manifesto__item-hidden-up';
				item.classList.add('manifesto__item-hidden');
				item.classList.add(transition);
			}
		});

	}
}

import View from 'Core/View.js';
import Scrollbar from '../../components/Scrollbar/Scrollbar';
import ScrollService from '../../../services/ScrollService.js';

const DECORATIONLIST_MAX_SCROLL_HEIGHT = 450;
const FONTLIST_MAX_SCROLL_HEIGHT = 200;

export default class WrapCreator extends View {

	initialize(options) {
		this.modals = options.modalDictionary;

        this.previousScrollPosition = window.pageYOffset;

		// Example to listen to a signal
		this.signals.modals.hidden.add(function(modalId) {

			// Empty preview modal when closed
			if (modalId === 'wc-preview') {
				let modal = document.getElementById(modalId);

				for (let i = 0; i < modal.childNodes.length; i ++) {
					if (modal.childNodes[i].className === 'preview') {
						let preview = modal.childNodes[i];
						while (preview.firstChild) {
							preview.removeChild(preview.firstChild);
						}
					}
				}
			} else if (modalId === 'wc-mobile-tab') {
			    let modal = document.getElementById(modalId),
                    holder = modal.querySelector('.holder'),
                    tablist = document.querySelector('.wc__tabs'),
                    tab = holder.firstElementChild;
			    // Reset tab content
                if (tab && tablist) {
                    tablist.append(tab);
                }
            }
		});

		this.ScrollService = ScrollService;
        this.decorationTabLinks = this.findAll('.wc__tab__link');

        this.decorationScrollbarElement = this.find('.wc__decoration__scrollbar');
        this.decorationsContainer = this.find('.wc__decoration__container');
        this.decorationsWrapper = this.find('.wc__decoration__wrapper');
        this.decorationScrollbar = new Scrollbar(this.decorationScrollbarElement);
        this.decorationScrollbar.activate(this.decorationsWrapper, this.decorationsContainer);

        this.fontScrollbarElement = this.find('.wc__font__dropdown-menu__scrollbar');
        this.fontsContainer = this.find('.wc__font__dropdown-menu__container');
        this.fontsWrapper = this.find('.wc__font__dropdown-menu__wrapper');
        this.fontScrollbar = new Scrollbar(this.fontScrollbarElement);
        this.fontScrollbar.activate(this.fontsWrapper, this.fontsContainer);

        this.update = this.update.bind(this);
        window.WrapCreatorView = this;

        this.contentContainer = this.find('.wrap-creator__content');
        this.creator_row = this.find('.wc__creator__row');
        this.creator_col = this.find('.wc__creator__col');
        this.elHasStickyClass = false;
        this.navigation = document.querySelector('.navigation');

        window.addEventListener('scroll', this.scroll.bind(this));
        this.scroll();

        window.addEventListener('creatorRefreshed', this.creatorRefreshed.bind(this));

        // Example to trigger a modal
		// this.modals['my-modal-id'].show();
        // this.decorationTabLinks.forEach((decorationTabLink) => {
         //    decorationTabLink.addEventListener('click', this.update.bind(this));
        // });
    }

    update(event) {
        const maxHeight = Math.min(this.decorationsWrapper.offsetHeight, DECORATIONLIST_MAX_SCROLL_HEIGHT);
        this.decorationScrollbar.update(maxHeight);

        const maxHeightFonts = Math.min(this.fontsWrapper.offsetHeight, FONTLIST_MAX_SCROLL_HEIGHT);
        this.fontScrollbar.update(maxHeightFonts);
    }

    scroll() {
	    // Only check when mobile display is on
        if (this.creator_col.style.display === 'block') {
            const scrollTop = window.pageYOffset;
            // Check if we've moved down more than the threshold
            let size = this.getSizePlusTopOffsetOfCreator();
            let maxSize = this.getSizePlusTopOffsetPlusHeightOfCreator();

            if (scrollTop > size && scrollTop < maxSize) {
                this.stick();
            } else {
                this.unstick();
            }
        }
    }

    stick() {
        if (this.elHasStickyClass) {
            return;
        }

        this.elHasStickyClass = true;
        this.contentContainer.classList.add('sticky-nav');
    }

    unstick() {
        if (! this.elHasStickyClass) {
            return;
        }

        this.elHasStickyClass = false;
        this.contentContainer.classList.remove('sticky-nav');
    }

    getSizePlusTopOffsetOfCreator() {
        return this.contentContainer.offsetTop - 35; // Added margin for buttons
    }

    getSizePlusTopOffsetPlusHeightOfCreator() {
        return (this.creator_col.offsetTop + this.creator_col.offsetHeight) - 45; // -40 for height of buttons + margin
    }

    disable() {
        this.decorationScrollbar.deactivate();
        this.fontScrollbar.deactivate();
    }

	destroy() {
        window.removeEventListener('scroll', this.scroll);
    }

	creatorRefreshed() {
		this.initializeChildViews(this.el);
	}
}

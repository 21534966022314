import View from '../../../../shared/Core/View';

export default class HeaderTypeVideo extends View {

    initialize() {
    }

	postBuild() {
		this.video = this.findViewForElement(this.find('.video'));
	}

	onAfterDisappear() {
    	if (this.video) {
			this.video.reset();
		}
	}

    destroy() {
    }
}

const isArrayKey = key => key.slice(- 2) === '[]';

export default function(formData) {
	const data = {};

	for (let entry of formData.entries()) {
		let [key, value] = entry;

		// Some keys are array keys containing multiple values (checkboxes)
		if (isArrayKey(key)) {
			key = key.substr(0, key.length - 2);

			if (typeof data[key] === 'undefined') {
				data[key] = [];
			}
			data[key].push(value);

		} else {
			data[key] = value;
		}
	}

	return data;
}
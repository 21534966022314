import View from 'Core/View.js';
import CartModel from 'data/CartModel';

export default class CheckoutShippingMethods extends View {

	initialize() {
		this.shippingMethods = [];
		this.onShippingMethodChanged = this.onShippingMethodChanged.bind(this);
		this.update();

		this.currentAmountLabel = document.querySelector('.checkout__current-shipping-amount')
	}

	update() {
		this.shippingMethods.forEach((shippingMethod) => {
			shippingMethod.removeEventListener('change', this.onShippingMethodChanged);
		});

		this.shippingMethods = this.findAll('[name="shipping_method_id"]');
		this.shippingMethods.forEach((shippingMethod) => {
			shippingMethod.addEventListener('change', this.onShippingMethodChanged);
		});
	}

	onShippingMethodChanged(event) {
		const shippingMethodId = event.target.value;
		const amount = event.target.dataset.amountLabel;

		if(this.currentAmountLabel) {
			this.currentAmountLabel.innerHTML = amount;
		}

		CartModel.setShippingMethod(shippingMethodId);
	}

	destroy() {
		this.shippingMethods.forEach((shippingMethod) => {
			shippingMethod.removeEventListener('change', this.onShippingMethodChanged);
		});
	}

}

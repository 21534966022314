import View from '../../../shared/Core/View';

import './payment-methods.scss';

export default class PaymentMethods extends View {

    initialize() {
		this.handleInputChange = this.handleInputChange.bind(this);

		this.methodInputs = this.findAll('.payment-methods__method-input');
		this.methods = this.findAll('.payment-methods__method');

		this.methodInputs.forEach(
			el => el.addEventListener('change', this.handleInputChange)
		);
    }

    destroy() {
		this.methodInputs.forEach(
			el => el.removeEventListener('change', this.handleInputChange)
		);
    }

    handleInputChange(event) {
		this.selectPaymentMethod(event.target.value);
	}

	selectPaymentMethod(value) {
		this.methodInputs.forEach(el => {
			if (el.value === value) {
				el.parentNode.classList.add('payment-methods__method--current');
			} else {
				el.parentNode.classList.remove('payment-methods__method--current');
			}
		})
	}
}

import TweenLite from 'gsap/TweenLite';
import View from 'Core/View.js';

import './product-detail-description.scss';
import {Sine} from 'gsap';

const COLLAPSED_HEIGHT = 60;

export default class ProductDetailDescription extends View {

	initialize(options) {

		this.onResize = this.onResize.bind(this);
		this.expand = this.expand.bind(this);

		this.wrapper = this.find('.product-detail-description__wrapper');
		this.content = this.find('.product-detail-description__content');
		this.readMore = this.find('.product-detail-description__read-more');

		window.addEventListener('resize', this.onResize);
		this.readMore.addEventListener('click', this.expand);

		this.onResize();
	}

	destroy() {
		window.removeEventListener('resize', this.onResize);
	}

	onResize() {

		if(this.content.offsetHeight > COLLAPSED_HEIGHT ) {
			TweenLite.set(this.wrapper, {height: COLLAPSED_HEIGHT});
			this.el.classList.remove('product-detail-description--collapsed');
			this.el.classList.add('product-detail-description--collapsed');
		}
	}

	expand(event) {
		event.preventDefault();

		if(!this.el.classList.contains('product-detail-description--expanded')) {

			this.el.classList.remove('product-detail-description--expanding');
			this.el.classList.add('product-detail-description--expanding');

			TweenLite.to(this.wrapper, 0.3, {
				ease: Sine.easeInOut, height: this.content.offsetHeight, onComplete: () => {
					this.el.classList.remove('product-detail-description--expanding');
					this.el.classList.add('product-detail-description--expanded');
				}
			});
		}
	}
}

export function getDeeplinkDataForWindowLocation (location, baseUrl) {

	// NOTE: Regex to check for '/' and
	// returns an array all chars between the '/'s
	const regex = new RegExp(/[^\/]+(?:\/\/[^\/]*)*/g);
	let hashArray = null;

	if(location.hash) {
		const hashRaw = location.hash;
		const hash = hashRaw.substring(0, 1) === '#' ? hashRaw.slice(1, hashRaw.length) : hashRaw;
		hashArray = hash.match(regex);
	} else if(baseUrl) {
		const path = location.href.replace(baseUrl, '');
		hashArray = path.match(regex);
	}

	if(hashArray) {
		if(!hashArray) return false;

		const deeplinkData = {
			chapterId: hashArray[0],
			elementId: hashArray[1]
		}

		return deeplinkData;
	}

	return false;
}

export function emptyElement(el){

	if(el && el.childNodes) {
		var i = el.childNodes.length;
		while(i--){
		  el.removeChild(el.lastChild);
		}
	}

  }


import createRequest from 'superagent';

import View from '../../../shared/Core/View';
import duplicate from './requests/duplicate';
import './WrapperDetail.scss';

export default class WrapperDetail extends View {

    initialize() {
        const color = this.el.getAttribute('data-background-color')
        const backgroundHSL = this.dimmedBackground(color);
        document.documentElement.style.setProperty('--header-background-color', backgroundHSL);


        this.editButtons = [].slice.call(document.body.querySelectorAll('.wrapper-detail__edit-button'));
        this.templateAddButton = this.find('.template-cart-button')
        this.handleEditButtonClicked = this.handleEditButtonClicked.bind(this);
        this.handleOrderButtonClicked = this.handleOrderButtonClicked.bind(this);
        this.handleCartUpdated = this.handleCartUpdated.bind(this);

        this.editButtons.forEach((button) => {
            button.addEventListener('click', this.handleEditButtonClicked);
        })

        this.signals.ecommerce.cartUpdated.add(this.handleCartUpdated);

        if (this.templateAddButton) {
            // ¯\_(ツ)_/¯ for some reason I can not get this button to be the last in the container with twig
            // The add to basket button will always be last unless we do something like this.
            this.find('.product-detail__add-to-basket-button-container').append(this.templateAddButton);
            this.templateAddButton.addEventListener('click', this.handleOrderButtonClicked)

        }

        this.testWebP().then(hasWebP => {
            if (hasWebP) {
                const noWebP = this.el.querySelectorAll('.no-webp');
                noWebP.forEach(e => e.remove());
                return;
            }

            const webPImages = this.el.querySelectorAll('.product-detail__visuals .no-mobile, .product-detail__visuals .mobile-only')
            webPImages.forEach(e => e.remove());

            const noWebP = this.el.querySelectorAll('.no-webp');
            noWebP.forEach(e => e.classList.remove('hidden'));
        })
    }

    destroy() {
        this.editButtons.forEach((button) => {
            button.removeEventListener('click', this.handleEditButtonClicked);
        })

        if (this.templateAddButton) this.templateAddButton.removeEventListener('click', this.handleOrderButtonClicked)
        this.signals.ecommerce.cartUpdated.remove(this.handleCartUpdated);
    }

    handleEditButtonClicked(event) {
        event.preventDefault();
        event.stopPropagation();

        if (!event.currentTarget || !event.currentTarget.href || !event.currentTarget.dataset.editUrl) {
            return;
        }

        if (event.currentTarget.dataset.isTemplate) {
            this.handleDuplicateClick(event);
            return;
        }

        const editUrl = event.currentTarget.dataset.editUrl;
        const duplicateAndEditUrl = event.currentTarget.dataset.duplicateUrl;
        const newUrl = event.currentTarget.dataset.newUrl;

        this.signals.products.wrapperEditRequested.dispatch(editUrl, duplicateAndEditUrl, newUrl);
    }

    handleDuplicateClick(event) {
        event.preventDefault();

        this.editButtons.forEach(e => e.classList.add('wrapper-detail__edit-button--loading'));
        if (this.currentError) {
            this.currentError.parentNode.removeChild(this.currentError);
            this.currentError = null;
        }

        duplicate(event.currentTarget.dataset.duplicateUrl)
            .then((response) => {
                const url = response.body.url;
                const route = this.routes.getRoute(url);

                this.signals.pageLoad.requested.dispatch(route);

                this.editButtons.forEach(e => e.classList.remove('wrapper-detail__edit-button--loading'));
            })
            .catch(() => {
                const error = document.createElement('div');
                error.innerHTML = 'Error duplicating bar';
                error.classList.add('edit-or-duplicate-wrapper__error');

                this.editButtons.forEach(e => e.classList.remove('wrapper-detail__edit-button--loading'));
                event.currentTarget.parentNode.insertBefore(error, event.currentTarget.nextSibling);

                this.currentError = error;
            });
    }

    handleOrderButtonClicked(event) {
        event.preventDefault();
        event.stopPropagation();

        const firstEditButton = this.editButtons[0];
        const optionsSelect = this.find('.product-options select', document.body);

        this.signals.products.orderTemplateRequested.dispatch(firstEditButton.dataset.duplicateUrl, firstEditButton.dataset.productId, optionsSelect);
    }

    handleCartUpdated(products) {
        const wrapperUuid = this.find('#wrapper-id', document.body).value || null;
        const productId = this.find('#product-id', document.body).value || null;
        const infoElement = this.find('#product-detail-information-content', document.body);
        const nutritionsElement = this.find('#product-detail-nutritional-content', document.body);

        if (!wrapperUuid || !infoElement || !nutritionsElement) return;

        const request = createRequest('GET', '/' + tonysConfig.routes['api.cart.wrapper-info'].route.replace('{productId?}', productId) + '?uuid=' + wrapperUuid);
        request.set('Accept', 'application/json');
        request.set('X-Requested-With', 'XMLHttpRequest');

        request.send().then((response) => {
            const responseObject = JSON.parse(response.text);
            infoElement.innerHTML = responseObject['product-detail-information-content'];
            nutritionsElement.innerHTML = responseObject['product-detail-nutritional-content'];
            infoElement.parentElement.style.height = null;
            nutritionsElement.parentElement.style.height = null;
        })
    }

    dimmedBackground(referenceColor) {
        let { h, s, l } = this.rgbHexToHsl(referenceColor);

        if (l > 0.5 || l < 0.15) {
            s = Math.min(s, 0.1);
        }

        l = this.backgroundLightness(l);

        const hue = Math.round(h * 360);
        const saturation = `${s * 100}%`;
        const lightness = `${l * 100}%`;

        return `hsl(${hue} ${saturation} ${lightness})`;
    };

    backgroundLightness(l) {
        if (l > 0.8) {
            return l - 0.3;
        }

        if (l > 0.2) {
            return l - 0.15;
        }

        return Math.min(0.5, l + 0.2);
    };

    rgbHexToHsl(hex) {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const r = parseInt(result[1], 16) / 255;
        const g = parseInt(result[2], 16) / 255;
        const b = parseInt(result[3], 16) / 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);

        const l = (max + min) / 2;
        if (max === min) {
            return { h: 0, s: 0, l };
        }

        let h = 0;
        const d = max - min;
        const s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;

        return { h, s, l };
    };

    testWebP() {
        return new Promise(res => {
            const webP = new Image();
            webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
            webP.onload = webP.onerror = () => {
                res(webP.height === 2);
            };
        })
    };

}

import View from 'Core/View.js';
import jsvat from 'jsvat';

export default class VATField extends View {

	initialize() {
		this.country = this.el.dataset.country || 'nl';

		if (! this.canCountryVATBeValidated(this.country)) {
			console.warn('Cannot validate VAT for country "' + this.country + '"');
			return;
		}

		this.handleChange = this.handleChange.bind(this);
		this.handleBlur = this.handleBlur.bind(this);

		this.input = this.el.querySelector('input');
		this.input.addEventListener('keydown', this.handleChange);
		this.input.addEventListener('blur', this.handleBlur);
	}

	destroy() {
		if (this.input) {
			this.input.removeEventListener('keydown', this.handleChange);
			this.input.removeEventListener('blur', this.handleBlur);
		}
	}

	handleChange() {
		this.el.classList.remove('field--has-error');
	}

	handleBlur() {
		let value = this.input.value;
		if (! value) {
			return;
		}

		if (this.country === 'nl' && ! value.match(/[a-zA-Z]{2}.*/)) {
			value = 'NL' + value;
		}

		const code = this.validateAndFormatVAT(value, this.country);
		if (code === false) {
			this.el.classList.add('field--has-error');
			return;
		}

		this.input.value = code;
	}

	/**
	 * @param {String} vat
	 * @param {String} country
	 * @return {Boolean|String} false if invalid, formatted number if valid.
	 */
	validateAndFormatVAT(vat, country) {
		switch (country) {

			case 'us':
				// Basically, we don't validate US codes
				return vat;
				break;

			// Default is an EU country, validated in `canCountryVATBeValidated()`
			default:
				const result = jsvat.checkVAT(vat);
				if (result.isValid) {
					return result.value;
				}
		}

		return false;
	}

	canCountryVATBeValidated(countryCode) {
		const countryCodeUC = countryCode.toUpperCase();

		// Check if US
		if (countryCodeUC === 'US') {
			return true;
		}

		// Check if EU country
		const countriesArray = Object.keys(jsvat.countries).map(key => jsvat.countries[key]);
		const country = countriesArray.find(country => country.codes.indexOf(countryCodeUC) >= 0);
		if (country) {
			return true;
		}

		return false;
	}

}

import BaseSplashView from './BaseSplash';

export default class LottieSplash extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.progress = 0;
		this.type = options.type;
		this.animationData = JSON.parse(options.el.dataset.animationData);
		this.animationType = options.el.dataset.animationType;
	}

}

import Signal from 'signals';
import View from 'Core/View.js';
// import gsap from 'gsap/all';

import { Draggable } from 'gsap/Draggable';
import './navigation-top.scss';

// Prevent tree shaking
(() => {})([Draggable]);

const SCROLL_OFFSET_DESKTOP = 600;

export default class NavigationTop extends View {
	initialize(options) {
		this.currentY = 0;

		this.setupUI();
		this.setupEventListeners();
		this.setupSignals();

		this.setupInitialState();
		this.setupDraggable();
		this.updateMenuIsOpenState(false);
	}

	destroy() {
		this.removeEventListeners();
		if(this.draggable) this.draggable.kill();
		this.ui = null;

	}

	// Public
	highlightChapterWithId(id) {
		if (id !== this.activeId) {
			this.ui.menuButtons.forEach(el =>
				el.dataset.chapterPath === id ? el.classList.add('is-selected') : el.classList.remove('is-selected')
			);
			this.activeId = id;
		}
	}

	updateScrollPosition(y) {
		this.currentY = y;

		if (y > SCROLL_OFFSET_DESKTOP) {
			if (this.openYPos && Math.abs(this.currentY - this.openYPos) > 10) {
				this.closeMenu();
			}
		}
	}

	// Private
	setupUI() {
		this.ui = {
			content: this.el.querySelector('.js-navigation-top-content'),
			menuButtons: [...this.el.querySelectorAll('.js-jaarfairslag-menu-button')],
			buttonOpenNavigation: this.el.querySelector('.js-button-open'),
			buttonOpenNavigationMobile: this.el.querySelector('.js-button-open-mobile'),
			buttonCloseNavigation: this.el.querySelector('.js-button-close'),
			buttonCloseNavigationMobile: this.el.querySelector('.js-button-close-mobile'),
			slider: this.el.querySelector('.js-navigation-top-slider'),
			list: this.el.querySelector('.js-navigation-top-list'),
		};
	}

	setupEventListeners() {
		this.handleMenuOpenButtonClicked = this.handleMenuOpenButtonClicked.bind(this);
		this.handleMenuCloseButtonClicked = this.handleMenuCloseButtonClicked.bind(this);
		this.handleMenuButtonClicked = this.handleMenuButtonClicked.bind(this);

		this.ui.buttonOpenNavigation.addEventListener('click', this.handleMenuOpenButtonClicked);
		this.ui.buttonOpenNavigationMobile.addEventListener('click', this.handleMenuOpenButtonClicked);
		this.ui.buttonCloseNavigation.addEventListener('click', this.handleMenuCloseButtonClicked);
		this.ui.buttonCloseNavigationMobile.addEventListener('click', this.handleMenuCloseButtonClicked);
		this.ui.menuButtons.forEach(el => el.addEventListener('click', this.handleMenuButtonClicked));
	}

	removeEventListeners() {
		this.ui.buttonOpenNavigation.removeEventListener('click', this.handleMenuOpenButtonClicked);
		this.ui.buttonOpenNavigationMobile.removeEventListener('click', this.handleMenuOpenButtonClicked);
		this.ui.buttonCloseNavigation.removeEventListener('click', this.handleMenuCloseButtonClicked);
		this.ui.buttonCloseNavigationMobile.removeEventListener('click', this.handleMenuCloseButtonClicked);
		this.ui.menuButtons.forEach(el => el.removeEventListener('click', this.handleMenuButtonClicked));
	}

	setupSignals() {
		this.signals = { ...this.signals, navigationClicked: new Signal() };
	}

	setupInitialState() {
		this.updateScrollPosition(window.pageYOffset);
	}

	setupDraggable() {
		const scrollProxy = document.createElement('div');
		this.draggable = new Draggable(this.ui.list, {
			type: 'scrollLeft',
			proxy: scrollProxy,
			edgeResistance: 0.9,
			lockAxis: true,
			throwProps: true,
			cursor: 'grab'
		});
	}

	updateMenuIsOpenState(state) {
		this.menuIsOpened = state;

		if (this.menuIsOpened) {
			this.ui.buttonOpenNavigation.classList.add('is-hidden');
			this.ui.buttonOpenNavigationMobile.classList.add('is-hidden');
			this.ui.buttonCloseNavigation.classList.remove('is-hidden');
			this.ui.buttonCloseNavigationMobile.classList.remove('is-hidden');
		} else {
			this.ui.buttonOpenNavigation.classList.remove('is-hidden');
			this.ui.buttonOpenNavigationMobile.classList.remove('is-hidden');
			this.ui.buttonCloseNavigation.classList.add('is-hidden');
			this.ui.buttonCloseNavigationMobile.classList.add('is-hidden');
		}
	}

	openMenu() {
		this.openYPos = this.currentY;
		this.updateMenuIsOpenState(true);
		this.ui.content.classList.add('is-open');
	}

	closeMenu() {
		this.openYPos = null;
		this.updateMenuIsOpenState(false);
		this.ui.content.classList.remove('is-open');
	}

	handleMenuOpenButtonClicked() {
		this.openMenu();
	}

	handleMenuCloseButtonClicked() {
		this.closeMenu();
	}

	handleMenuButtonClicked(e) {
		e.preventDefault();

		this.closeMenu();

		const options = { chapterPath: e.currentTarget.dataset.chapterPath };
		this.signals.navigationClicked.dispatch(options);
	}
}

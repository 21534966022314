import TweenLite, {Ease} from 'gsap/TweenLite';
import View from '../../../shared/Core/View';

import './small-counter.scss';

export default class SmallCounter extends View {

	initialize() {
		this.handleWindowResized = this.handleWindowResized.bind(this);

		this.nextAmount = 0;
		this.currentAmount = 0;
		this.animating = false;

		this.digits = this.find('.small-counter__digits');

		this.digitTop = this.digits.querySelector('[data-position="top"]');
		this.digitMiddle = this.digits.querySelector('[data-position="middle"]');
		this.digitBottom = this.digits.querySelector('[data-position="bottom"]');

		window.addEventListener('resize', this.handleWindowResized);

		this.resetAnimation();
	}

	destroy() {
		window.removeEventListener('resize', this.handleWindowResized);
	}

	updateAmount(nextAmount) {
		this.nextAmount = Math.max(nextAmount, 0);

		const elementHeight = this.el.clientHeight;

		if (nextAmount > this.currentAmount) {
			this.digitTop.innerHTML = nextAmount;
			this.currentAmount = this.nextAmount;
			this.animateTo(elementHeight);

		} else if (nextAmount < this.currentAmount) {
			this.digitBottom.innerHTML = nextAmount;
			this.currentAmount = this.nextAmount;
			this.animateTo(-elementHeight);

		} else {
			this.resetAnimation();
		}
	}

	animateTo(y) {
		if (this.animating) {
			return;
		}

		TweenLite.to(this.digits, 0.3, {
			ease: Ease.easeInOut,
			y: y,
			onStart: () => this.animating = true,
			onComplete: this.resetAnimation,
			onCompleteScope: this,
		});
	}

	resetAnimation() {
		this.nextAmount = null;
		this.digitBottom.innerHTML = '&nbsp;';
		this.digitMiddle.innerHTML = this.currentAmount;
		this.digitTop.innerHTML = '&nbsp;';

		TweenLite.set(this.digits, {y: 0});

		this.animating = false;
	}

	handleWindowResized() {
		this.resetAnimation();
	}
}

import DefaultPage from '../DefaultPage/DefaultPage.js';
import ScrollService from '../../../services/ScrollService.js';
import Sticky from 'utils/sticky/Sticky.js';
import createRequest from 'superagent';

import './account-dashboard.scss';
import './components/order-item-row.scss';
import './components/subscription-order.scss';
import './components/wrapper-preview.scss';

const ACCOUNT_DASHBOARD_NAVIGATION_OFFSET_Y = 176;
const ACCOUNT_DASHBOARD_NAVIGATION_HEIGHT_MARGIN = 8;

export default class AccountDashboard extends DefaultPage {

	initialize() {
		super.initialize();

		this.update = this.update.bind(this);

		this.categoriesTitles = this.findAll('.account-dashboard__section-title');
		this.categories = this.findAll('.account-navigation__internal');
		this.categories.forEach((category) => {
			category.addEventListener('click', this.onCategoryClick.bind(this));
		});

		// Paginate some things
		this.xhrPaginate(this.find('.account-dashboard__orders'), 10);
		this.paginate(this.find('.account-dashboard__personal-wrappers'), 8);
		this.paginate(this.find('.account-dashboard__unlimiteds'), 8);

		// Create sticky menu
		this.accountNavigation = this.find('.account-navigation__wrapper');
		this.sticky = new Sticky(this.accountNavigation, ACCOUNT_DASHBOARD_NAVIGATION_OFFSET_Y);

		window.addEventListener('scroll', this.update);
		window.addEventListener('resize', this.update);

		this.update();
	}

	destroy() {
		super.destroy();

		window.removeEventListener('scroll', this.update);
		window.removeEventListener('resize', this.update);
	}

	update() {
		this.checkActiveCategory();
		this.sticky.update();
	}

	paginate(el, maxItemsWithoutPagination) {
		if (! el) {
			return;
		}

		const items = el.querySelectorAll('.account-dashboard__pagination-item');
		const showMoreButton = el.querySelector('.account-dashboard__pagination-show-more');

		if (items.length <= maxItemsWithoutPagination) {
			if (showMoreButton) {
				showMoreButton.classList.add('button--hidden');
			}
			return;
		}

		items.forEach((item, index) => {
			if (index >= maxItemsWithoutPagination) {
				item.classList.add('account-dashboard__pagination-item--hidden');
			}
		});

		showMoreButton.addEventListener('click', (event) => {
			event.preventDefault();

			items.forEach((item) => {
				item.classList.remove('account-dashboard__pagination-item--hidden');
			});

			showMoreButton.classList.add('button--hidden');
		});
	}

	xhrPaginate(el, maxItemsWithoutPagination) {
		if (! el) {
			return;
		}

		const route = el.dataset.url;
		const count = el.dataset.count;
		const showMoreButton = el.querySelector('.account-dashboard__pagination-show-more');

		if (count <= maxItemsWithoutPagination) {
			if (showMoreButton) {
				showMoreButton.classList.add('button--hidden');
			}
			return;
		}

		showMoreButton.addEventListener('click', (event) => {
			event.preventDefault();

			// Increase page number
			el.dataset.page = parseInt((el.dataset.page || 0)) + 1;

			// Do call to backend to increase page nr
			const request = createRequest('GET', route);
			request.set('X-Requested-With', 'XMLHttpRequest');
			request.query({page: el.dataset.page});

			return request.then(response => {
				showMoreButton.insertAdjacentHTML('beforebegin', response.text);

				let items = el.querySelectorAll('.account-dashboard__pagination-item');

				if (items.length >= count) {
					showMoreButton.classList.add('button--hidden');
				}
			});
		});
	}

	checkActiveCategory() {
		let closestTitle = null;

		this.categoriesTitles.forEach((categoryTitle) => {
			const rect = categoryTitle.getBoundingClientRect();

			if (rect.top <= ACCOUNT_DASHBOARD_NAVIGATION_OFFSET_Y + ACCOUNT_DASHBOARD_NAVIGATION_HEIGHT_MARGIN) {
				closestTitle = categoryTitle;
			}
		});

		closestTitle = ! closestTitle ? this.categoriesTitles[0] : closestTitle;

		const activeCategory = closestTitle.getAttribute('id');
		this.categories.forEach((category) => {
			if (category.getAttribute('href').split('#')[1] == activeCategory) {
				category.classList.add('selected');
			} else {
				category.classList.remove('selected');
			}
		})
	}

	onCategoryClick(event) {
		event.preventDefault();

		const categoryId = '#' + event.currentTarget.getAttribute('href').split('#')[1];
		const categoryHeader = this.find(categoryId);
		ScrollService.scrollPageToElement(categoryHeader, ACCOUNT_DASHBOARD_NAVIGATION_OFFSET_Y);
	}
}

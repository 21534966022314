const TemplateType = {};

TemplateType.DEFAULT = 'DEFAULT';
TemplateType.MISSION = 'MISSION';
TemplateType.COLLABORATE = 'COLLABORATE';
TemplateType.SHOP = 'SHOP';

TemplateType.ALL = [
	TemplateType.DEFAULT,
	TemplateType.MISSION,
	TemplateType.COLLABORATE,
	TemplateType.SHOP,
];

TemplateType.isValid = (type) => TemplateType.ALL.indexOf(type) >= 0;

export default TemplateType;
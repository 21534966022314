import View from '../../../shared/Core/View';
import ScrollService from '../../../services/ScrollService';

const CLASS_HAS_ERROR = 'field--has-error';
const CLASS_ERROR_MESSAGE = 'field__error';

export default class ErrorDisplay extends View {

    initialize() {
		this.refresh();
    }

    destroy() {
    }

    refresh() {
		this.fields = this.gatherFieldsByInputName();
		this.clearErrors();
	}

	showErrors(errors) {
		Object.keys(errors).forEach(fieldName => {
			this.showError(fieldName, errors[fieldName]);
		});

		this.scrollFirstErrorIntoView();
	}

	clearErrors() {
		Object.keys(this.fields).forEach(fieldName => {
			this.removeError(fieldName);
		});
	}

	showError(fieldName, errorMessage = '') {
		const fieldsWithName = this.fields[fieldName];
		if (! fieldsWithName) {
			console.error(`Received an error for field "${fieldName}", but the field was not present in the form.`);
			if (['edit_url', 'edit_account_url', 'modalMessage'].includes(fieldName) === false && (typeof errorMessage === 'string' || errorMessage instanceof Array)) {
				this.signals.toaster.requested.dispatch(errorMessage, 6);
			}
			return;
		}

		fieldsWithName.forEach((el) => {
			el.classList.add(CLASS_HAS_ERROR)

			if (errorMessage) {
				const errorNode = document.createElement('div');
				errorNode.className = CLASS_ERROR_MESSAGE;
				errorNode.innerHTML = '* ' + errorMessage;
				el.appendChild(errorNode);
			}
		});
	}

	removeError(fieldName) {
		const fieldsWithName = this.fields[fieldName];
		if (!fieldsWithName) {
			return;
		}

		fieldsWithName.forEach((el) => {
			if (el.classList.contains(CLASS_HAS_ERROR)) {
				el.classList.remove(CLASS_HAS_ERROR)
			}

			const errorNode = el.querySelector('.' + CLASS_ERROR_MESSAGE);
			if (errorNode) {
				el.removeChild(errorNode);
			}
		});
	}

	scrollFirstErrorIntoView() {
		const errorFields = this.findAll('.' + CLASS_HAS_ERROR);
		let boundsWithSmallestTop = null;
		let firstFieldWithError = null;

		errorFields.forEach(field => {
			const bounds = field.getBoundingClientRect();
			if (! boundsWithSmallestTop || bounds.top < boundsWithSmallestTop.top) {
				boundsWithSmallestTop = bounds;
				firstFieldWithError = field;
			}
		});

		const firstErrorPadding = 50;
		if (firstFieldWithError && boundsWithSmallestTop.top < firstErrorPadding) {
			ScrollService.scrollPageToElement(firstFieldWithError, firstErrorPadding, 0.5);
		}
	}

	gatherFieldsByInputName() {
		const fieldElements = this.findAll('.field');
		const fields = {};
		fieldElements.forEach(field => {
			// Split querying input,textarea and select because of error issue with telephone.twig
			// (grabs select for some reason and can't show error)
			let input = field.querySelector('input,textarea');
			if(!input) {
				input = field.querySelector('select');
			}

			if (! input) {
				return;
			}

			const name = input.getAttribute('name');
			if (! fields[name]) {
				fields[name] = [];
			}

			fields[name].push(field);
		});

		return fields;
	}
}

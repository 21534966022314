import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Event from '../../shapes/Event';
import Select from '../../../../reactComponents/Select/Select';
import eventToDates from './eventToDates';

import TruckLocatorEvent from './components/TruckLocatorEvent/TruckLocatorEvent';

import './truck-locator-events.scss';

class TruckLocatorEvents extends React.PureComponent {

	render() {
		this.handleSelectChanged = this.handleSelectChanged.bind(this);

		const classes = classNames({
			'truck-locator-events': true,
			[this.props.className]: !! this.props.className,
		});

		return this.renderList(classes);
	}

	renderSelect(classes) {
		return (
			<Select
				className={classes}
				name="events"
				onChange={this.handleSelectChanged}
				options={this.eventsToOptions()}/>
		);
	}

	renderList(classes) {
		return (
			<div className={classes}>
				{this.props.eventIdsInOrder.map((id) => {
					return (
						<TruckLocatorEvent
							key={id}
							locale={this.props.locale}
							className="truck-locator-events__event"
							event={this.props.events[id.toString()]}
							onClick={this.props.onEventChosen}
						/>
					);
				})}
			</div>
		);
	}

	eventsToOptions() {
		const pastOptions = [];
		const futureOptions = [];

		this.props.eventIdsInOrder.forEach(id => {
			const event = this.props.events[id.toString()];
			const optionsArray = event.inPast ? pastOptions : futureOptions;

			optionsArray.push({
				value: id.toString(),
				label: `${event.name} (${eventToDates(event, this.props.locale)})`,
			});
		});

		return [
			{
				label: this.props.translations.selectLabel,
				value: '',
			},
			{
				label: this.props.translations.pastLocationsLabel,
				options: pastOptions,
			},
			{
				label: this.props.translations.futureLocationsLabel,
				options: futureOptions,
			},
		];
	}

	handleSelectChanged(id) {
		if (this.props.onEventChosen) {
			this.props.onEventChosen(this.props.events[id]);
		}
	}
}

TruckLocatorEvents.propTypes = {
	translations: PropTypes.object.isRequired,
	locale: PropTypes.string.isRequired,
	className: PropTypes.string,
	isMobile: PropTypes.bool,
	events: PropTypes.objectOf(Event).isRequired,
	eventIdsInOrder: PropTypes.arrayOf(PropTypes.number).isRequired,
	onEventChosen: PropTypes.func,
};

TruckLocatorEvents.defaultProps = {
	events: {},
};

export default TruckLocatorEvents;

import View from 'Core/View';
import ConversationBarsSignals from '../ConversationBarsSignals';

import './conversation-bars-filter.scss';

export default class ConversationBarsFilter extends View {

	initialize(options) {
		this.type = this.el.dataset.type;
		this.selectedType = null;

		this.click = this.click.bind(this);
		this.activate = this.activate.bind(this);
		this.deactivate = this.deactivate.bind(this);
		this.filterTypeSelected = this.filterTypeSelected.bind(this);

		this.el.addEventListener('click', this.click);
		ConversationBarsSignals.selectConversationBarFilter.add(this.filterTypeSelected);
	}

	click(event) {
		// Reset
		if(this.selectedType === this.type) {
			ConversationBarsSignals.selectConversationBarFilter.dispatch(null);
		// Select
		} else {
			ConversationBarsSignals.selectConversationBarFilter.dispatch(this.type);
		}
	}

	activate() {
		this.el.classList.remove('conversation-bars__filter--deselected');
		this.el.classList.toggle('conversation-bars__filter--selected', true);
	}

	deactivate() {
		this.el.classList.remove('conversation-bars__filter--selected');
		this.el.classList.remove('conversation-bars__filter--deselected');
		if(this.selectedType !== null) {
			this.el.classList.add('conversation-bars__filter--deselected');
		}
	}

	filterTypeSelected(type) {
		this.selectedType = type;
		if(this.selectedType === this.type) {
			this.activate();
		} else {
			this.deactivate();
		}
	}

	destroy() {
		this.el.removeEventListener('click', this.click);
		ConversationBarsSignals.selectConversationBarFilter.remove(this.filterTypeSelected);
		super.destroy();
	}
}

import View from 'Core/View.js';
import TimelineMax from 'gsap/TimelineMax';
import createRequest from 'superagent';

import './form-submission-counter.scss';

export default class FormSubmissionCounter extends View {

	initialize() {
		this.fetchLatestDataAndUpdate = this.fetchLatestDataAndUpdate.bind(this);

		this.value = 0;
		this.lastTargetValue = 0;
		this.updateInterval = this.el.dataset.updateInterval || 10;

		this.animateToValue(this.el.dataset.count);

		if (this.el.dataset.updateUrl) {
			this.scheduleUpdate();
		}
	}

	destroy() {
		clearTimeout(this.scheduledDataUpdate);
	}

	scheduleUpdate() {
		this.scheduledDataUpdate = setTimeout(this.fetchLatestDataAndUpdate, this.updateInterval * 1000)
	}

	fetchLatestDataAndUpdate() {
		const request = createRequest('GET', this.el.dataset.updateUrl);
		request.set('Accept', 'application/json');
		request.set('X-Requested-With', 'XMLHttpRequest');

		request.then((response) => {
			this.scheduleUpdate();

			if (!response.body || !response.body.count) {
				return;
			}

			this.animateToValue(response.body.count);
		});
	}

	animateToValue(value) {
		if (value === this.lastTargetValue) {
			return;
		}

		this.lastTargetValue = value;

		const tl = new TimelineMax({});
		tl.to(this, 3.5, {
			delay: 1,
			ease: Circ.easeInOut,
			value: value,
			onUpdate: () => {
				this.el.innerHTML = Math.floor(this.value);
			}
		});
		tl.to(this.el, 0.4, {
			ease: Circ.easeInOut,
			scale: 1.6,
		});
		tl.to(this.el, 0.3, {
			ease: Circ.easeIn,
			scale: 1,
		});
	}
}

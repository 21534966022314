import DefaultPage from '../DefaultPage/DefaultPage.js';

import './checkout-details-guest.scss';

export default class CheckoutDetailsGuest extends DefaultPage {
	initialize(options) {
		super.initialize();

		this.signals.forms.succeeded.add(this.handleFormSubmitted, this);
	}

	destroy() {
		super.destroy();

		this.signals.forms.succeeded.remove(this.handleFormSubmitted, this);
	}

	handleFormSubmitted(formId) {
		if (formId !== 'checkout-register') {
			return;
		}

		this.signals.ecommerce.checkoutSubmitShippingInfo.dispatch();
	}
}

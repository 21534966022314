import View from 'Core/View.js';
import CartModel from 'data/CartModel.js';

import fetchPrice from './requests/fetchPriceForOption';
import debounce from '../../../shared/utils/generic/debounce';
import ErrorDisplay from '../../general/ErrorDisplay/ErrorDisplay';

export default class AddToBasket extends View {

	initialize(options) {
		this.handleAddToCartClick = this.handleAddToCartClick.bind(this);
		this._addToCart = this._addToCart.bind(this);
		this._updateCart = this._updateCart.bind(this);
		this.startButtonLoadingState = this.startButtonLoadingState.bind(this);
		this.cancelButtonLoadingState = this.cancelButtonLoadingState.bind(this);
		this.handleOptionsChange = this.handleOptionsChange.bind(this);
		this.handleAddToCartError = this.handleAddToCartError.bind(this);
		this.updatePriceDebounced = debounce(this.updatePrice.bind(this), 500);
		this._onCartUpdatedRequest = this._onCartUpdatedRequest.bind(this);

		this.addButton = this.find('.shop-detail__add-to-basket-button');
		this.quantityInput = this.find('.shop-detail__amount .numeric-stepper__input');
		this.optionsSelect = this.find('.shop-detail__options select');
		this.priceEl = this.find('.shop-detail__price');
		this.priceExVatEl = this.find('.shop-detail__price-excl-vat__value');
		this.extraInputs = this._findRelevantInputs();

		this.errors = new ErrorDisplay({...options, el: this.find('.shop-detail__main', document.body)});
		this.updateInsteadOfAdd = this.addButton ? this.addButton.dataset.update === 'true' : false;
		this.productId = parseInt(this.el.dataset.productId);
		this.updatePriceEndpoint = this.el.dataset.priceEndpoint;
		this.customerGroupId = parseInt(this.el.dataset.customerGroup || 1);

		if (this.addButton) {
			this.addButton.addEventListener('click', this.handleAddToCartClick);
		}

		if (this.optionsSelect) {
			this.optionsSelect.addEventListener('change', this.handleOptionsChange);
			this.handleOptionsChange();
		}

		if (this.extraInputs.length > 0) {
			this.extraInputs.forEach(el => el.addEventListener('change', this.updatePriceDebounced));
			this.extraInputs.forEach(el => el.addEventListener('keyup', this.updatePriceDebounced));
			this.updatePriceDebounced();
		}

		if (this.quantityInput) {
			this.quantityInput.addEventListener('change', this.updatePriceDebounced);
			this.quantityInput.addEventListener('keyup', this.updatePriceDebounced);
		}

		CartModel.updatedSignal.add(this.cancelButtonLoadingState);
		CartModel.errorSignal.add(this.handleAddToCartError);

		// Wait for Cart to be updated and update price accordingly
		CartModel.requestSuccessSignal.add(this._onCartUpdatedRequest);
	}

	destroy() {
		if (this.addButton) {
			this.addButton.removeEventListener('click', this.handleAddToCartClick);
		}

		if (this.optionsSelect) {
			this.optionsSelect.removeEventListener('change', this.handleOptionsChange);
		}

		if (this.extraInputs.length > 0) {
			this.extraInputs.forEach(el => el.removeEventListener('change', this.updatePriceDebounced));
			this.extraInputs.forEach(el => el.removeEventListener('keyup', this.updatePriceDebounced));
		}

		if (this.quantityInput) {
			this.quantityInput.removeEventListener('change', this.updatePriceDebounced);
			this.quantityInput.removeEventListener('keyup', this.updatePriceDebounced);
		}

		if (this.errors) {
			this.errors.destroy();
		}

		CartModel.updatedSignal.remove(this.cancelButtonLoadingState);
		CartModel.errorSignal.remove(this.handleAddToCartError);
	}

	_addToCart() {
		const {productId, ...extra} = this._gatherAddToCartData();

		const quantity = this.quantityInput ? parseInt(this.quantityInput.value) : 1;
		if (quantity && quantity > 0) {
			CartModel.addProduct(productId, quantity, extra);
		}

		if (this.quantityInput) {
			this.quantityInput.value = 1;
		}
	}

	_onCartUpdatedRequest() {
		this.updatePriceDebounced();
	}

	_findRelevantInputs() {
		return this.findAll('.shop-detail__main input:not(.input-ignore), .shop-detail__main select, .shop-detail__main textarea', document.body);
	}

	_updateCart() {
		CartModel.load();
	}

	_gatherAddToCartData() {
		const data = {
			productId: this.productId,
		};

		if (this.optionsSelect) {
			const optionId = parseInt(this.optionsSelect.value);
			data.productId = isNaN(optionId) ? data.productId : optionId;
		}

		const inputs = this._findRelevantInputs();
		inputs.forEach(el => {
			if (el.name === 'quantity') {
				return;
			}

			data[el.name] = el.value;
		});

		return data;
	}

	startButtonLoadingState() {
		if (!this.addButton) {
			return;
		}

		this.addButton.classList.add('button--loading');
	}

	cancelButtonLoadingState() {
		if (!this.addButton) {
			return;
		}

		this.addButton.classList.remove('button--loading');
	}

	updatePrices(price) {
		if (this.priceExVatEl) {
			this.priceEl.innerHTML = price.currencyPriceVat;
			this.priceExVatEl.innerHTML = price.currencyPrice;
			return;
		}

		this.priceEl.innerHTML = price.currencyPrice;
	}

	updatePrice() {
		const quantity = this.quantityInput ? parseInt(this.quantityInput.value) || 1 : 1;
		const data = this._gatherAddToCartData();

		const options = {
			customerGroupId: this.customerGroupId,
			quantity: quantity,
			...data
		};

		if (! options.productId) {
			return;
		}

		fetchPrice(this.updatePriceEndpoint, options)
			.then((response) => {
				const firstKey = Object.keys(response.body)[0];
				this.updatePrices(response.body[firstKey]);
			})
			.catch((error) => {
				this.signals.toaster.requested.dispatch('Could not update price.' + error);
			});
	}

	handleAddToCartClick(event) {
		event.preventDefault();

		this.startButtonLoadingState();
		this.errors.refresh();

		const updateOrAdd = this.updateInsteadOfAdd ? this._updateCart : this._addToCart;

		if (typeof event.beforeAdding === 'undefined') {
			updateOrAdd();
			return;
		}

		event.beforeAdding
			.then(updateOrAdd)
			.catch(this.cancelButtonLoadingState);
	}

	handleAddToCartError(errors) {
		this.cancelButtonLoadingState();

		this.signals.forms.beforeErrorsDisplay.dispatch('add-to-basket', errors);

		this.errors.showErrors(errors);
	}

	handleOptionsChange() {
		const value = parseInt(this.optionsSelect.value);
		if (!value) {
			this.addButton.classList.add('button--disabled');
			return;
		}

		this.addButton.classList.remove('button--disabled');

		this.updatePriceDebounced();
	}
}

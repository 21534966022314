import TweenLite from 'gsap/TweenLite';

import View from 'Core/View.js';

export default class StickToMouse extends View {

	initialize(options) {
		if (document.documentElement.classList.contains('touch')) {
			return;
		}

		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);

		this.hasMoved = false;

		this.offset = options.offset ? options.offset : {x: 0, y: 0};

		this.targetEl = options.targetEl ? options.targetEl : document.body;
		this.targetEl.addEventListener('mousemove', this.handleMouseMove);
		this.targetEl.addEventListener('mouseleave', this.handleMouseLeave);

		TweenLite.set(this.el, {position: 'fixed', top: 0, left: 0, opacity: 0, transitionDuration: 0});
	}

	destroy() {
		if(this.targetEl) {
			this.targetEl.removeEventListener('mousemove', this.handleMouseMove);
		}
		this.hide();
	}

	_update(x, y) {
		this.el.style.transform = `translate3d(${x + this.offset.x}px, ${y + this.offset.y}px, 0)`;
	}

	handleMouseMove(event) {
		const coords =	this.targetEl !== document.body ? {x: event.layerX, y: event.layerY} : {x: event.pageX, y:event.pageY};
		this._update(coords.x, coords.y);

		if (! this.hasMoved) {
			this.hasMoved = true;
			TweenLite.to(this.el, 0.3, {opacity: 1});
		}
	}

	handleMouseLeave(event) {
		if(this.hasMoved) {
			this.hasMoved = false;
			TweenLite.to(this.el, 0.3, {opacity: 0});
		}
	}

	hide(reset = false) {
		TweenLite.to(this.el, 0.15, {opacity: 0, onComplete: () => {
			if(reset) {
				this.hasMoved = false;
			}
		}});
	}
}

import View from 'Core/View.js';

import './counter.scss';

import { TweenLite, Power2 } from 'gsap/all';

import 'intersection-observer';

export default class Chapter extends View {

	initialize(options){

		this.template = this.el.querySelector('.js-counter-template').innerHTML;

		this.digitCount = this.el.dataset.endValue.length;
		this.counterValue = parseInt(this.el.dataset.endValue);
		this.fromCounterValue = this.el.dataset.startValue || Math.floor(this.counterValue * 0.95);

		this.addDigitsToDom();
		this.setupUI();
		this.setStartingNumber();
		this.setupInterSectionObserver();

	}

	destroy() {

		this.tempalte = null;
		this.removeInterSectionObserver();

	}

	setupInterSectionObserver() {

		this.handleIntersection = this.handleIntersection.bind(this);
		this.observer = new IntersectionObserver(this.handleIntersection, {threshold: 0.5});
		this.observer.observe(this.el);

	}

	removeInterSectionObserver() {

		if(this.observer) {
			this.observer.disconnect();
			this.observer = null;
		}

	}

	addDigitsToDom() {

		for (let i = 0; i < this.digitCount; i++) {
			if( this.digitCount > 3 && ((i - this.digitCount) % 3 === 0)) {
				this.el.insertAdjacentHTML('beforeend', '<div class="counter-number-dot-wrapper"><div class="counter-number-dot"></div></div>');
			}
			this.el.insertAdjacentHTML('beforeend', this.template);
		}

	}

	setupUI() {

		this.ui = {
			digits: [...this.el.querySelectorAll('.js-counter-digits')]
		}

	}

	setStartingNumber() {

		for (let i = 0; i < this.ui.digits.length; i++) {
			const el = this.ui.digits[i];
			const correctionValue = Math.pow(10, this.digitCount - i - 1);
			this.setValueForDigit(el, Math.floor(this.fromCounterValue / correctionValue));
		}

	}

	setValueForDigit(el, value) {
		el.setAttribute('transform', `translate(0, ${(value * 60) % 600})`);
	}

	animate() {

		for (let i = 0; i < this.ui.digits.length; i++) {

			const el = this.ui.digits[i];
			const correctionValue = Math.pow(10, this.digitCount - i - 1);

			const animObj = { value: Math.floor(this.fromCounterValue / correctionValue) % 100 };
			const toValue = Math.floor(this.counterValue / correctionValue) % 100;

			const duration = (1 + (1 / this.digitCount * i));

			TweenLite.to(animObj, duration, {value: toValue, ease: Power2.easeInOut, onUpdate: () => {
				this.setValueForDigit(el, animObj.value);
			}});

		}

	}

	startCounter() {

		this.counterStarted = true;
		this.animate();

	}

	checkEntryForIntersect(entry) {

		if(entry.isIntersecting && !this.counterStarted) {
			this.startCounter();
			this.removeInterSectionObserver();
		}

	}

	handleIntersection(entries, observer) {

		entries.forEach(entry => this.checkEntryForIntersect(entry));

	}

}

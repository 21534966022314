import Signal from 'signals';

export default {

	/**
	 * A modal triggered by a modal trigger
	 *
	 * @param {string} modalId
	 * @param {Element} el
	 */
	triggered: new Signal(),

	/**
	 * A modal window is shown
	 *
	 * @param {string} modalId
	 * @param {Modal} modal
	 */
	shown: new Signal(),

	/**
	 * A modal window is starting to hide
	 *
	 * @param {string} modalId
	 * @param {Modal} modal
	 */
	hide: new Signal(),

	/**
	 * A modal window is hidden again
	 *
	 * @param {string} modalId
	 * @param {Modal} modal
	 */
	hidden: new Signal(),

	/**
	 * A special default message modal is requested
	 *
	 * @param {string} message
	 * @param {string | undefined} buttonLabel
	 */
	messageRequested: new Signal(),
};

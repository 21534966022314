const ROUTES_THAT_SHOULDNT_USE_PUSH_STATE = [
	'payment.pay',
	'account.logout',
	'creator.*',
];

const REFRESH_AFTER_LEAVING_THESE_ROUTES = [
	'creator.*',
];

const EXCLUDED_FORM_IDS = [
	'address-picker-create'
];

export default class {

	constructor(options) {
		this.signals = options.signals;
		this.routes = options.routes;
		this.currentRoute = options.routeAtLoad;

		this.redirectOnFormSubmit = this.redirectOnFormSubmit.bind(this);
		this.checkIfPageLoadShouldBeIntercepted = this.checkIfPageLoadShouldBeIntercepted.bind(this);

		this.signals.forms.succeeded.add(this.redirectOnFormSubmit);
		this.signals.pageLoad.requested.add(this.checkIfPageLoadShouldBeIntercepted);
	}

	redirectOnFormSubmit(formId, data) {
		if (! data || ! data.redirect || EXCLUDED_FORM_IDS.indexOf(formId) >= 0) {
			return;
		}

		if (this.formNeedsReloadAfterSubmit(data)) {
			this.redirectToUrl(data.redirect);
			return;
		}

		const route = this.routes.getRoute(data.redirect);
		const isLocal = this.routes.isLocalURI(data.redirect);
		if (isLocal) {
			// By default, tell the page service to load this url
			this.signals.pageLoad.requested.dispatch(route);
			return;
		}

		window.location = data.redirect;
	}

	checkIfPageLoadShouldBeIntercepted(route) {
		if (this.routeNeedsReloadOnEnter(route) || this.routeNeedsReloadOnLeave(route)) {

			// Prevent other parts of the application from responding
			this.signals.pageLoad.requested.halt();

			this.redirectToRoute(route);
		}

		this.currentRoute = route;
	}

	redirectToRoute(route) {
		if (window.location.pathname === '/' + route.path) {
			window.location.reload(true);
			return;
		}

		window.location = route.url;
	}

	redirectToUrl(url) {
		const locationBeforeHash = window.location.href.split('#')[0];
		const urlBeforeHash = url.split('#')[0];

		if (locationBeforeHash === urlBeforeHash) {
			window.location.reload(true);
			return;
		}

		window.location = url;
	}

	routeNeedsReloadOnEnter(requestedRoute) {
		if (this.currentRoute && requestedRoute.name === this.currentRoute.name) {
			return false;
		}

		return this.routesArrayContainsRouteName(ROUTES_THAT_SHOULDNT_USE_PUSH_STATE, requestedRoute.name);
	}

	routeNeedsReloadOnLeave(newRoute = null) {
		const route = this.currentRoute;

		/**
		 * If the new route matches the old one, the new route must have a hash.
		 * This is used when loading the wikkelcreator twice (navigating to it
		 * twice) while keeping the internal hash (#info) links working.
		 */
		if (newRoute && route.name === newRoute.name && newRoute.hash) {
			return false;
		}

		return this.routesArrayContainsRouteName(REFRESH_AFTER_LEAVING_THESE_ROUTES, route.name);
	}

	routesArrayContainsRouteName(routesArray, routeName) {
		for (let i = 0; i < routesArray.length; i ++) {
			let name = routesArray[i];
			name = name.replace('\.\*', '(\.([^.]+))?');
			name = name.replace('\.', '\\.');
			const re = new RegExp('^' + name + '$');
			if (re.test(routeName)) {
				return true;
			}
		}

		return false;
	}

	formNeedsReloadAfterSubmit(data) {
		return data.shouldReload;
	}
}

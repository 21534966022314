import DefaultPage from '../DefaultPage/DefaultPage.js';
import ScrollService from '../../../services/ScrollService.js';

export default class Collaborate extends DefaultPage {

	initialize() {
		super.initialize()

		// Activate subtitle click -> scroll page down
		this.onTitleClick = this.onTitleClick.bind(this);
		this.mainSubTitle = document.querySelector('.main-titles__sub');
		this.mainSubTitle.addEventListener('click', this.onTitleClick);
	}

	onTitleClick(event) {
		ScrollService.scrollPageToElement(this.el);
	}

	destroy() {
		this.mainSubTitle.removeEventListener('click', this.onTitleClick);
		super.destroy();
	}
}
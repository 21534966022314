import View from '../../../../shared/Core/View';

import ArrowButton from '../../SelectSubscription/components/ArrowButton';

import './subscription-change-payment-method.scss';

export default class SubscriptionChangePaymentMethod extends View {
	initialize(options) {
		this.handleChange = this.handleChange.bind(this);

		this.arrowButtons = this.findAll('.subscription-arrow-button').map(el => {
			return new ArrowButton({...options, el: el});
		});

		this.dataForMethod = this.findAll('[data-for-method]');

		this.findAll('[name="payment_method_id"]').forEach(el => el.addEventListener('change', this.handleChange));
	}

	destroy() {
		this.findAll('[name="payment_method_id"]').forEach(el => el.removeEventListener('change', this.handleChange));
		this.arrowButtons.forEach(ab => ab.destroy());
	}

	handleChange(event) {
		this.updateArrows(event.target.checked && event.target.value);
		this.updateIssuers(event.target.checked && event.target.value);
	}

	updateArrows(paymentMethodId) {
		this.arrowButtons.forEach(arrowButton => {
			const inputValue = this.find('input', arrowButton.el).value;
			if (inputValue === paymentMethodId) {
				arrowButton.open();
			} else {
				arrowButton.close();
			}
		});
	}

	updateIssuers(paymentMethodId) {
		this.dataForMethod.forEach(el => {
			if (el.dataset.forMethod === paymentMethodId) {
				el.style.display = 'block';
			} else {
				el.style.display = 'none';
			}
		});
	}
}

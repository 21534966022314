import View from 'Core/View.js';
import './checkout-product.scss';

export default class CheckoutProduct extends View {

	initialize() {

	}

	destroy() {
	}

}
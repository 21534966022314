import View from 'Core/View.js';

import Signal from 'signals';

export default class AddressPickerCreate extends View {

	initialize() {
		this.handleToggleCreateClicked = this.handleToggleCreateClicked.bind(this);
		this.handleFormSubmitted = this.handleFormSubmitted.bind(this);

		this.isToggled = false;
		this.shown = new Signal();
		this.hidden = new Signal();
		this.created = new Signal();

		this.toggleCreateButton = this.find('.address-picker__create__button');
		this.createSection = this.find('.address-picker__create__form');

		this.signals.forms.succeeded.add(this.handleFormSubmitted);
		this.toggleCreateButton.addEventListener('click', this.handleToggleCreateClicked);

		this.initializeChildViews(this.el);
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.handleFormSubmitted);
		this.toggleCreateButton.removeEventListener('click', this.handleToggleCreateClicked);

		this.destroyChildViews(this.el);
	}

	handleToggleCreateClicked(event) {
		event.preventDefault();

		this.isToggled = ! this.isToggled;

		if (this.isToggled) {
			this.shown.dispatch();
			this.createSection.classList.add('address-picker__create__form--toggled');
		} else {
			this.hidden.dispatch();
			this.createSection.classList.remove('address-picker__create__form--toggled');
		}
	}

	handleFormSubmitted(formId, data) {
		if (formId === 'address-picker-create') {
			this.created.dispatch(data.id, data.fullAddress);
		}
	}
}
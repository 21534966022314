import createRequest from 'superagent';
import * as Sentry from '@sentry/browser';

import View from 'Core/View.js';
import ErrorDisplay from '../ErrorDisplay/ErrorDisplay';

const CLASS_SUBMIT_BUTTON_LOADING = 'button--loading';

export default class Form extends View {

	initialize(options) {
		this.isSubmitting = false;
		this.isDisabled = false;
		this.requestData = {};

		this.submitButton = this.find('.button[type="submit"], [data-disable-while-submitting="true"]');
		this.errors = new ErrorDisplay({...options, el: this.el});

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputInvalid = this.handleInputInvalid.bind(this);
		this.handleInputValid = this.handleInputValid.bind(this);

		// Besides backend errors, we can also do front-end validation. The field (not the input)
		// should then dispatch an invalid event.
		this.el.addEventListener('invalid', this.handleInputInvalid);
		this.el.addEventListener('valid', this.handleInputValid);
		this.el.addEventListener('submit', this.handleSubmit);
	}

	destroy() {
		this.el.removeEventListener('invalid', this.handleInputInvalid);
		this.el.removeEventListener('valid', this.handleInputValid);
		this.el.removeEventListener('submit', this.handleSubmit);

		if (this.errors) {
			this.errors.destroy();
		}
	}

	handleSubmit(event) {
		event.preventDefault();

		if (window.hcaptcha && document.querySelector('.h-captcha')) {
			window.hcaptcha.execute(null, {async: true}).then(({ response, key }) => {
				this.submit();
			}).catch(err => {
				window.hcaptcha.render(document.querySelector('.h-captcha'));
				this.handleSubmit(event);
				console.error(err);
			});
		} else {
			this.submit();
		}
	}

	handleInputInvalid(event) {
		event.stopPropagation();

		this.errors.showError(event.target.name, '');
	}

	handleInputValid(event) {
		event.stopPropagation();

		this.errors.removeError(event.target.name);
	}

	clearErrors() {
		this.errors.refresh();
	}

	submit() {
		if (! this.canSubmit()) {
			return;
		}

		this.errors.refresh();

		const method = this.getFormMethod();
		const formData = this.getFormData();

		this.requestData = null;
		this.isSubmitting = true;
		this.disableSubmitButton();

		// Create and send request
		const request = createRequest(method, this.el.action);
		request.set('Accept', 'application/json');
		request.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
		request.set('X-Requested-With', 'XMLHttpRequest');

		// Allow others to hook into this event
		this.signals.forms.beforeSubmit.dispatch(this.getIdentifier(), formData);

		// Convert FormData do object
		this.requestData = formData;

		if (window.hcaptcha) {
			this.requestData.append('h-captcha-response', window.hcaptcha.getResponse());
		}

		if (method === 'GET') {
			request.query(this.requestData);
		} else {
			request.send(this.requestData);
		}

		// Send out the request
		const promise = request
			.retry(1, (error, response) => {
				const data = response.body
				if (!data || ! data.csrfIssue) {
					return false;
				}

				response.req.set('X-CSRF-TOKEN', data.token);
				document.querySelector('meta[name="csrf-token"]').content = data.token;

				return true;
			})
			.then(this.handleSuccess.bind(this), this.handleFailure.bind(this))
			.then((data) => {
				this.isSubmitting = false;
				if (typeof hcaptcha !== 'undefined') {
					hcaptcha.reset();
				}

				return data;
			});

		// Allow others to hook into this event
		this.signals.forms.submitted.dispatch(this.getIdentifier(), this.requestData, promise);
	}

	handleSuccess(response) {
		if (! this.el.classList.contains('form--dont-reenable-submit')) {
			this.enableSubmitButton();
		}

		const data = response.body || {};
		this.signals.forms.succeeded.dispatch(this.getIdentifier(), data, this.requestData);

		if (data.modalMessage) {
			this.signals.modals.messageRequested.dispatch(data.modalMessage, (data.modalButton || ''));
		}

		return data;
	}

	handleFailure(error) {
		const formId = this.getIdentifier();

		this.enableSubmitButton();

		let errors = error.response.body;
		this.signals.forms.failed.dispatch(formId, errors, this.requestData);

		if (! errors) {
			Sentry.captureException(error);
			this.signals.forms.crashed.dispatch(formId, this.requestData);
			return;
		}

		if (error.response.text && error.response.type === 'application/json') {
			errors = JSON.parse(error.response.text);
		}

		this.signals.forms.beforeErrorsDisplay.dispatch(formId, errors);
		this.errors.showErrors(errors);
		this.signals.forms.afterErrorsDisplay.dispatch(formId, errors);

		if (errors.modalMessage) {
			this.signals.modals.messageRequested.dispatch(errors.modalMessage, (errors.modalButton || ''));
		}

		delete errors.modalMessage;

		if (errors.csrfIssue) {
			delete errors.csrfIssue;
			delete errors.token;
		}

		return error;
	}

	disableSubmitButton() {
		if (! this.submitButton) {
			return;
		}

		if (this.submitButton.dataset.labelLoading) {
			this.oldSubmitLabel = this.getSubmitLabel();
			this.setSubmitLabel(this.submitButton.dataset.labelLoading);
		}

		this.submitButton.classList.add(CLASS_SUBMIT_BUTTON_LOADING);
	}

	enableSubmitButton() {
		if (! this.submitButton) {
			return;
		}

		this.submitButton.classList.remove(CLASS_SUBMIT_BUTTON_LOADING);

		if (this.submitButton.dataset.labelLoading) {
			this.setSubmitLabel(this.oldSubmitLabel);
		}
	}

	getFormData() {
		const formData = new FormData(this.el);

		// Force values for selects (IE11 bug)
		this.findAll('select').forEach((select) => {
			formData.set(select.name, select.value);
		});

		return formData;
	}

	getFormMethod() {

		// Give priority to the hidden _method input
		const methodField = this.find('input[name="_method"]');
		if (methodField) {
			return methodField.value.toUpperCase();
		}

		// Then check for data-method
		if (this.el.dataset.method) {
			return this.el.dataset.method.toUpperCase();
		}

		// Fallback to default form method
		return this.el.method.toUpperCase();
	}

	canSubmit() {
		return ! this.isDisabled && ! this.isSubmitting;
	}

	getIdentifier() {
		if (this.el.id) {
			return this.el.id;
		}

		if (this.el.dataset.id) {
			return this.el.dataset.id;
		}

		return this.el.className.replace(' ', '');
	}

	setSubmitLabel(label) {
		const labelNode = this.submitButton.querySelector('.button__label');
		if (! labelNode) {
			throw new Error('When using data-label-loading, the label must be wrapped in a span.button__label.');
		}

		return labelNode.innerHTML = label;
	}

	getSubmitLabel() {
		const labelNode = this.submitButton.querySelector('.button__label');
		if (! labelNode) {
			throw new Error('When using data-label-loading, the label must be wrapped in a span.button__label.');
		}

		return labelNode.innerHTML;
	}
}

import View from 'Core/View.js';

import shopRoutes from '../../../shared/shopRoutes';

import './shop-top-nav.scss';

const SHOWING_CLASS = 'shop-top-nav--showing';

export default class ShopTopNav extends View {

	initialize(options) {
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.checkVisibility = this.checkVisibility.bind(this);
		this.checkPageLoadVisibility = this.checkPageLoadVisibility.bind(this);

		// Initial check
		this.checkVisibility(this.el, options.routeAtLoad)

		this.signals.pageLoad.requested.add(this.checkPageLoadVisibility);
		this.signals.pageContentReplaced.add(this.checkVisibility);
	}

	destroy() {
		this.signals.pageLoad.requested.remove(this.checkPageLoadVisibility);
		this.signals.pageContentReplaced.remove(this.checkVisibility);
	}

	show() {
		document.body.classList.remove(SHOWING_CLASS);
		document.body.classList.add(SHOWING_CLASS);
	}

	hide() {
		document.body.classList.remove(SHOWING_CLASS);
	}

	checkPageLoadVisibility(route) {
		this.checkVisibility(null, route);
	}

	checkVisibility(element, route) {
		if (this.isRouteWithTopNavHeader(route)) {
			this.show();
		} else {
			this.hide(false);
		}
	}

	isRouteWithTopNavHeader(route) {
		return route.isExactMatch && shopRoutes.indexOf(route.name) >= 0;
	}
}

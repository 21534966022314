import View from '../../../shared/Core/View';

import findAncestorByClass from '../../../shared/utils/dom/findAncestorByClass';

const CLASS_OPEN = 'tool-tile--open';
const CLASS_CLOSING = 'tool-tile--closing';

import './tool-tile.scss';

let openTile = null;

export default class ToolTile extends View {

	initialize(options) {
		this.handleClick = this.handleClick.bind(this);

		this.lockedTileModal = options.modalDictionary['toolbox__locked-tile-modal'];

		this.closingTimeout = 0;
		this.isOpen = false;
		this.isLocked = this.el.classList.contains('tool-tile--locked');

		this.el.addEventListener('click', this.handleClick);
	}

	destroy() {
		this.el.removeEventListener('click', this.handleClick);
	}

	open() {
		clearTimeout(this.closingTimeout);
		if (openTile) {
			openTile.close();
		}

		if (this.el.classList.contains('tool-tile--locked')) {
			return;
		}

		this.el.classList.add(CLASS_OPEN);
		this.isOpen = true;
		openTile = this;
	}

	close() {
		this.isOpen = false;
		openTile = null;

		this.el.classList.remove(CLASS_OPEN);
		this.el.classList.add(CLASS_CLOSING);

		this.closingTimeout = setTimeout(() => {
			this.el.classList.remove(CLASS_CLOSING);
		}, 1000);
	}

	handleClick(event) {

		// Don't catch event when clicking the anchor
		let anchor = event.target.tagName.toLowerCase() === 'a' ? event.target : null;
		if (! anchor) {
			anchor = findAncestorByClass(event.target, 'tool-tile__inside');
		}

		if (anchor) {
			this.close();
			return;
		}

		event.preventDefault();

		if (this.isLocked) {
			this.lockedTileModal.show();
			return;
		}

		if (! this.isOpen) {
			this.open();
		} else {
			this.close();
		}
	}
}

import View from '../../../shared/Core/View';

export default class ToolLoader extends View {

	initialize(options) {
		this.tool = null;
		this.destroyed = false;

		import('./Tool.js').then(({default: Tool}) => {
			if (! this.destroyed) {
				this.tool = new Tool(options);
			}
		});

		import('../Toolbox/Toolbox.js').then(() => console.log('[TC] Toolbox available'));
	}

	destroy() {
		this.destroyed = true;

		if (this.tool) {
			this.tool.destroy();
		}
	}
}

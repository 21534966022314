import View from '../../../shared/Core/View';

import './breadcrumb.scss'

export default class Breadcrumbs extends View {

    initialize() {
    	this.hide = this.hide.bind(this);
    	this.show = this.show.bind(this);

		this.signals.pageLoad.started.add(this.hide);

		this.show();
    }

    destroy() {
		this.signals.pageLoad.started.remove(this.hide);
    }

	hide() {
    	TweenLite.to(this.el, 0.2, {y: -20, autoAlpha: 0});
	}

	show() {
		TweenLite.fromTo(this.el, 0.2, {y: 20, autoAlpha: 0}, {y: 0, autoAlpha: 1, delay: 0.2});
	}
}

import './button.scss';

import View from 'Core/View.js';
// import RectangleBackgroundAnimation from 'components/GeneratedSVG/RectangleBackgroundAnimation.js';

export default class Button extends View {

	initialize() {
		this.forceUpdate = this.forceUpdate.bind(this);

		// const label = this.find('.button__label');
		// if (label) {
		// 	this.background = new RectangleBackgroundAnimation({
		// 		color: this.getColor(),
		// 		deviation: 1,
		// 		el: this.el,
		// 		beforeNode: label
		// 	});
		//
		// 	this.signals.windowResized.add(this.forceUpdate);
		// 	this.signals.modals.shown.add(this.forceUpdate);
		// }
		this.el.classList.add(this.getRandomClipPathClass(3));
	}

	destroy() {
		if (this.background) {
			this.background.destroy();

			this.signals.windowResized.remove(this.forceUpdate);
			this.signals.modals.shown.remove(this.forceUpdate);
		}
	}

	forceUpdate() {
		this.background.forceUpdate();
	}

	getColor() {
		if (this.el.classList.contains('button--color-red')) {
			return 'red';
		}

		if (this.el.classList.contains('button--color-white')) {
			return 'white';
		}

		return 'blue';
	}

	getRandomClipPathClass(max) {
		const index = Math.floor(Math.random() * max);

		return 'button--clip-' + index;
	}
}
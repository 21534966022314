import View from 'Core/View.js';

import './arrow-link.scss';

export default class ArrowLink extends View {

	initialize(options) {
	}

	destroy() {

	}
}

/* global PUBLIC_IMAGES_PATH */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LatLng from '../../../../../shared/shapes/LatLng';
import ControlledMap from '../../../../reactComponents/ControlledMap/ControlledMap';
import EventShape from '../../shapes/Event';

import futureMarker from './graphics/marker.svg';
import pastMarker from './graphics/marker--past.svg';
import truck from './graphics/truck.png';
import currentMarker from './graphics/marker--current.svg';

const arrowPart = {
	path: 'M -0.742 0.066 L 1.692 0.03 L 1.692 5.428 L -0.848 5.146 L -0.742 0.066 Z',
	strokeWeight: 0,
	fillOpacity: 1,
	fillColor: '#0066cc',
	scale: 2,
};

const arrowHead = {
	path: 'M -13.234 -7.295 L 1.565 8.765 L 13.387 0.301 L 9.923 -3.449 L 2.73 1.582 L 2.856 -9.149 L -1.603 -9.17 L -1.602 0.131 L -10.617 -8.937',
	strokeWeight: 0,
	fillColor: '#0066cc',
	fillOpacity: 1,
	scale: 1.3,
};

const MARKER_IMAGE_PATH = PUBLIC_IMAGES_PATH + 'store-locator/markers';

const getClustererOptions = () => {
	return {
		gridSize: 20,
		imagePath: MARKER_IMAGE_PATH + '/cluster',
		imageExtension: 'svg',
		styles: [
			{
				url: futureMarker,
				width: 32,
				height: 32,
				textColor: '#ffffff',
				textSize: 16
			},
		]
	};
};

import './truck-locator-map.scss';

class TruckLocatorMap extends React.PureComponent {

	constructor(props) {
		super(props);

		this.handleMapReady = this.handleMapReady.bind(this);
		this.handleMarkersReplaced = this.handleMarkersReplaced.bind(this);
	}

	render() {
		const classes = classNames({
			'truck-locator-map': true,
			[this.props.className]: !! this.props.className,
		});

		const currentEvent = this.props.currentEventId ? this.props.events[this.props.currentEventId] : null;

		return (
			<ControlledMap
				apiKey={this.props.apiKey}
				className={classes}
				markers={this.getEvents()}
				initialCenter={this.props.initialCenter}
				initialZoom={this.props.initialZoom}
				minZoom={3}
				maxZoom={18}
				height={this.props.isMobile ? 200 : '100%'}
				fixedZoom={currentEvent ? 10 : null}
				fixedCenter={currentEvent ? currentEvent.gps : null}
				fitBoundsToMarkers
				currentMarkerId={this.props.currentEventId}
				hideControls={this.props.isMobile || !! currentEvent}
				hideUserLocationControls

				onMapReady={this.handleMapReady}
				onMarkerSelected={this.props.onEventSelected}
				onMarkersReplaced={this.handleMarkersReplaced}
				onCenterChanged={this.props.onUserInteracted}

				getClustererOptions={getClustererOptions}
				getMarkerIcon={this.getMarkerIcon}
				getMarkerIconForCurrent={this.getCurrentMarkerIcon}
			/>
		);
	}

	handleMarkersReplaced(map) {
		this.drawArrowsBetweenEvents(map);
	}

	handleMapReady(map) {
		this.drawArrowsBetweenEvents(map);
		this.addTruckToMap(map);
	}

	addTruckToMap(map) {
		const locationOfTheTruck = this.calculateTruckPosition();
		if (! locationOfTheTruck) {
			return;
		}

		if (this.truck) {
			this.truck.setPosition(locationOfTheTruck);
			return;
		}

		this.truck = new google.maps.Marker({
			position: locationOfTheTruck,
			icon: this.getTruckIcon(),
			map: map,
			clickable: false,
			zIndex: 1000,
		});
	}

	drawArrowsBetweenEvents(map) {
		this.removeArrows(map);

		const eventIds = this.props.eventIdsInOrder;
		const arrows = [];

		for (let i = 0; i < eventIds.length; i++) {
			if (i >= eventIds.length - 1) {
				continue;
			}

			const event = this.props.events[eventIds[i].toString()];
			const nextEvent = this.props.events[eventIds[i + 1].toString()];
			const nextIsBigger = nextEvent.id === this.props.currentEventId;

			arrows.push(new google.maps.Polyline({
				path: [nextEvent.gps, event.gps],
				strokeOpacity: 0,
				clickable: false,
				icons: [
					{icon: arrowPart, offset: '0', repeat: '20px'},
					{icon: arrowHead, offset: nextIsBigger ? '40px' : '24px'}
				],
				map: map
			}));
		}

		this.arrows = arrows;
	}

	removeArrows() {
		if (this.arrows) {
			this.arrows.forEach(arrow => arrow.setMap(null));
		}
	}

	calculateTruckPosition() {
		const eventIds = this.props.eventIdsInOrder;
		const now = this.props.currentDate.getTime();

		for (let i = 0; i < eventIds.length; i++) {
			const event = this.props.events[eventIds[i].toString()];

			const start = event.startDate.getTime();
			const end = event.endDate.getTime();
			const isAtCurrentEvent = now >= start && now < end;
			if (isAtCurrentEvent) {
				return event.gps;
			}

			if (i >= eventIds.length - 1) {
				continue;
			}

			const nextEvent = this.props.events[eventIds[i + 1].toString()];
			const nextStart = nextEvent.startDate.getTime();
			const isBetweenEvents = now >= end && now < nextStart;
			if (isBetweenEvents) {
				const tripProgress = (now - end) / (nextStart - end);

				return google.maps.geometry.spherical.interpolate(
					new google.maps.LatLng(event.gps),
					new google.maps.LatLng(nextEvent.gps),
					tripProgress
				);
			}
		}

		return null;
	}

	getTruckIcon() {
		return {
			scaledSize: new google.maps.Size(67, 34),
			origin: new google.maps.Point(0, 0),
			url: truck,
			anchor: new google.maps.Point(33, 17),
		};
	}

	getMarkerIcon(event) {
		return {
			scaledSize: new google.maps.Size(32, 32),
			origin: new google.maps.Point(0, 0),
			url: event.inPast ? pastMarker : futureMarker,
			anchor: new google.maps.Point(16, 16)
		};
	}

	getCurrentMarkerIcon() {
		return {
			scaledSize: new google.maps.Size(64, 64),
			origin: new google.maps.Point(0, 0),
			url: currentMarker,
			anchor: new google.maps.Point(32, 32)
		};
	}

	getEvents() {
		const events = {};

		this.props.eventIdsInOrder.forEach(eventId => {
			events[eventId] = this.props.events[eventId];
		});

		return events;
	}
}

TruckLocatorMap.propTypes = {
	className: PropTypes.string,
	apiKey: PropTypes.string.isRequired,
	events: PropTypes.objectOf(EventShape).isRequired,
	eventIdsInOrder: PropTypes.arrayOf(PropTypes.number).isRequired,
	currentDate: PropTypes.instanceOf(Date).isRequired,
	currentEventId: PropTypes.number,

	isMobile: PropTypes.bool,
	initialZoom: PropTypes.number.isRequired,
	initialCenter: LatLng.isRequired,
	onEventSelected: PropTypes.func,
	onUserInteracted: PropTypes.func,
};

export default TruckLocatorMap;

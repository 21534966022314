import View from 'Core/View';

import AddressPicker from '../../../general/AddressPicker/AddressPicker';
import ArrowButton from '../components/ArrowButton';

import './subscription-address-confirmation.scss';

export default class SubscriptionAddressConfirmation extends View {

	initialize(options) {
		this.options = options;
		this.forceUpdate = options.forceUpdate;

		this.handleChange = this.handleChange.bind(this);

		this.radios = this.findAll('.subscription-address-confirmation__input');
		this.radios.map(el => el.addEventListener('change', this.handleChange));
		this.hiddenInput = this.find('input[name="shipping_address_id"]');
		this.addressDisplay = this.find('.subscription-address-confirmation__address');
		this.arrowButton = new ArrowButton({...options, el: this.find('.subscription-arrow-button')});

		this.handleModalShown = this.handleModalShown.bind(this);
		this.handleModalHidden = this.handleModalHidden.bind(this);
		this.handleModalTriggered = this.handleModalTriggered.bind(this);
		this.handleAddressPicked = this.handleAddressPicked.bind(this);

		this.signals.modals.shown.add(this.handleModalShown);
		this.signals.modals.triggered.add(this.handleModalTriggered);
		this.signals.modals.hidden.add(this.handleModalHidden);

		this.changeAdressUrl = this.el.dataset.changeAddressUrl;
	}

	update(state) {
		this.updateArrows(state.confirmed_address === 'yes');
	}

	visible(state) {
		return !! state.plan_id;
	}

	destroy() {
		this.signals.modals.shown.remove(this.handleModalShown);
		this.signals.modals.hidden.remove(this.handleModalHidden);
		this.signals.modals.triggered.remove(this.handleModalTriggered);

		this.radios.map(el => el.removeEventListener('change', this.handleChange));
	}

	handleChange() {
		const selectedRadio = this.radios.filter(el => el.checked)[0];
		if (selectedRadio.value === 'yes') {
			return;
		}

		this.uncheckAll();
	}

	handleAddressPicked(id, addressHTML) {
		if (this.addressModal) {
			this.addressModal.hide();
		}

		this.hiddenInput.value = id;
		this.addressDisplay.innerHTML = addressHTML;
	}

	handleModalShown(modalId, modal) {
		if (modalId !== 'select-address') {
			return;
		}

		this.uncheckAll();

		this.updateModalPosition = modal.updatePosition.bind(modal);
		this.addressModal = modal;
		this.addressPicker = new AddressPicker({
			...this.options,
			el: modal.find('.subscription__address-picker'),
			hideDefaultAddressNotes: true,
		});
		this.addressPicker.signals.picked.add(this.handleAddressPicked);
		this.addressPicker.signals.sizeUpdated.add(this.updateModalPosition);
	}

	handleModalHidden(modalId) {
		if (modalId !== 'select-address') {
			return;
		}

		this.addressPicker.signals.sizeUpdated.remove(this.updateModalPosition);
		this.addressPicker.signals.picked.remove(this.handleAddressPicked);
		this.addressPicker.clear();
		this.addressPicker = null;
	}

	handleModalTriggered(modalId, trigger) {
		if (modalId !== 'select-address') {
			return;
		}

		this.addressType = trigger.dataset.type;
	}

	uncheckAll() {
		this.radios.forEach(el => el.checked = false);
		this.forceUpdate();
	}

	updateArrows(confirmedAddress) {
		if (confirmedAddress) {
			this.arrowButton.open();
		} else {
			this.arrowButton.close();
		}
	}
}

/* global SENTRY_LARAVEL_DSN */
/* global SENTRY_TRACES_SAMPLE_RATE */
/* global SENTRY_ENVIRONMENT */
/* global SENTRY_RELEASE */

import './scripts/services/CookieConsent';
import initializeTracking from './tracking';

import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

initializeTracking();

const customer = window.tonysConfig.customer;
const user = customer ? {
		id: parseInt(customer.id),
		email: customer.email
	} : {ip_address: '{{auto}}'};

Sentry.init({
	dsn: SENTRY_LARAVEL_DSN,
	release: `tonys-chocolonely@${SENTRY_RELEASE}`,
	integrations: [new BrowserTracing()],
	environment: SENTRY_ENVIRONMENT,
	tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
	initialScope: {user: user}
});

/**
 * Browser support detected early. We used bowser before, but it doesn't
 * make any sense, so we just detect IE10 and below.
 */
const isIE10OrBelow = navigator.userAgent.indexOf('MSIE') >= 0;
if (isIE10OrBelow) {
	document.documentElement.className = 'unsupported-browser';
}

import './fonts/fonts.scss';
import './styles/app.scss';

// These are gsap globals and not really used by a specific module
import {TimelineMax, CSSPlugin, BezierPlugin} from 'gsap/all';
import * as Eases from 'gsap/EasePack';

// Without this line, CSSPlugin and AttrPlugin may get dropped by webpack
(() => {})([TimelineMax, CSSPlugin, BezierPlugin, Eases]);

import './scripts/libraries/spirit';
import './scripts/libraries/flatpickr';

import loadPolyfills from './scripts/loadPolyfills.js';
import ApplicationContext from './scripts/ApplicationContext.js';
import featureDetection from './scripts/featureDetection/all.js';

// Import all images and write them to the public folder
require.context('./images', true, /.*/);

// Start the app
loadPolyfills(() => {
	featureDetection(() => {
		const context = new ApplicationContext();
		context.start();

		window.tonysContext = context;
	});
});

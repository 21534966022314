import View from 'Core/View.js';

import './latest-news.scss';

export default class LatestNews extends View {

	initialize() {
		
	}

	destroy() {
	}
}
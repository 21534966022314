import Signal from 'signals';
import View from 'Core/View.js';
import TweenLite from 'gsap/TweenLite';

export default class ImageCarouselImage extends View {

	initialize() {
		this.src = this.el.dataset.src;
		this.title = this.el.dataset.title;
		this.originalWidth = this.el.dataset.width;
		this.originalHeight = this.el.dataset.height;

		this.loadedSignal = new Signal();

		this.image = new Image();
		this.image.addEventListener('load', this.onLoaded.bind(this));
		this.image.addEventListener('onerror', this.onError.bind(this));
		this.load();
	}

	load() {
		this.image.src = this.src;
		this.image.classList.add('loading');
	}

	onLoaded() {
		this.originalWidth = this.image.naturalWidth;
		this.originalHeight = this.image.naturalHeight;

		this.loaded = true;
		this.image.classList.remove('loading');

		this.loadedSignal.dispatch();
	}

	onError() {

	}

	getOriginalWidth() {
		return this.originalWidth;
	}

	getOriginalHeight() {
		return this.originalHeight;
	}

	getImageElement() {
		return this.image;
	}

	getTitle() {
		return this.title;
	}

	reset() {
		this.image.classList.remove('hidden');
		TweenLite.killDelayedCallsTo(this.onHidden);
	}

	show(direction) {

		this.reset();

		switch (direction) {
			case - 1: // previous
				TweenLite.set(this.image, {x: - 100, y: '-50%', opacity: 0});
				break;
			case 1: // next
				TweenLite.set(this.image, {x: 100, y: '-50%', opacity: 0});
			default:
				// Don't do anything
				break;
		}

		TweenLite.killTweensOf(this.image, {x: true, y: '-50%', opacity: true});
		TweenLite.to(this.image, 0.3, {x: 0, opacity: 1, force3D: true});

		this.image.classList.add('animate-in');
	}

	hide(direction) {

		this.reset();

		TweenLite.killTweensOf(this.image, {x: true, y: '-50%', opacity: true});

		switch (direction) {
			case - 1: // previous
				TweenLite.to(this.image, 0.3, {x: 100, y: '-50%', opacity: 0, force3D: true});
				break;
			case 1: // next
				TweenLite.to(this.image, 0.3, {x: - 100, y: '-50%', opacity: 0, force3D: true});
				break;
		}

	}

	onHidden() {
		this.image.classList.add('hidden');
	}

	destroy() {
	}
}

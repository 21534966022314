import View from 'Core/View.js';
import createRequest from 'superagent';
import TweenLite from 'gsap/TweenLite';

import './shop-templates.scss';

const ITEM_ACTIVE_CLS = 'template-navigation__link--active';
const SHOP_TEMPLATES_VERTICAL_DISPLACEMENT_WIDGET = 100;

export default class ShopTemplates extends View {

	initialize(options) {
		this.signal = options.signals;

		// Selector for what should be animated when navigating
		this.animatablesSelector = '.shop-templates__wrapper';

		// Selectors for containers which should by dynamically replaces
		this.replaceableContainers = [
			'.shop-templates__wrapper',
		];

		this.onItemClick = this.onItemClick.bind(this);
		this.loadContents = this.loadContents.bind(this);
		this.onContentsLoaded = this.onContentsLoaded.bind(this);
		this.onContentsLoadError = this.onContentsLoadError.bind(this);
		this.replaceContents = this.replaceContents.bind(this);
		this.updateMenu = this.updateMenu.bind(this);
		this.showTiles = this.showTiles.bind(this);
		this.hideTiles = this.hideTiles.bind(this);

		this.items = this.findAll('.template-navigation__link');
		this.items.forEach((item) => {
			item.addEventListener('click', this.onItemClick);
		});

		this.updateMenu();

		this.options = options;
	}

	destroy() {
		this.items.forEach((item) => {
			item.removeEventListener('click', this.onItemClick);
		});
	}

	onItemClick(event) {
		event.preventDefault();
		this.loadContents(event.currentTarget.href);
	}

	loadContents(url) {
		this.hideTiles();
		const request = createRequest('GET', url);
		request.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
		request.set('X-Requested-With', 'XMLHttpRequest');
		request.set('X-WRAPPER-TEMPLATES-CATEGORY', true);
		request.then(this.onContentsLoaded, this.onContentsLoadError);
	}

	onContentsLoaded(response) {
		const div = document.createElement('div');
		div.innerHTML = response.text;

		// @TODO should we do it like this, or use the pageload service somehow?
		history.pushState(null, null, response.req.url);

		this.replaceContents(div);

		this.updateMenu();

		this.initializeChildViews();

		this.showTiles();
	}

	onContentsLoadError(error) {
		const errors = error.response.body;
		errors.map(errorMessage => {
			this.signals.toaster.requested.dispatch(errorMessage, 6);
		});
	}

	replaceContents(htmlNode) {
		this.replaceableContainers.forEach((selector) => {
			const currentEl = document.querySelector(selector);
			const newEl = htmlNode.querySelector(selector);

			if (currentEl) {
				currentEl.innerHTML = newEl ? newEl.innerHTML : '';
			}
		});
	}

	updateMenu() {
		// Update item states based on url
		const currentRoute = this.routes.getRoute();

		this.items.forEach((item) => {
			const itemRoute = this.routes.getRoute(item.getAttribute('href'));
			if (itemRoute.path === currentRoute.path) {
				item.classList.add(ITEM_ACTIVE_CLS);
			} else {
				item.classList.remove(ITEM_ACTIVE_CLS);
			}
		});
	}

	showTiles() {
		const tiles = this.findAll(this.animatablesSelector);

		tiles.forEach((tile) => {
			TweenLite.killTweensOf(tile);
			TweenLite.set(tile, {
				opacity: 0,
				y: SHOP_TEMPLATES_VERTICAL_DISPLACEMENT_WIDGET,
				rotation: -10 + Math.random() * 20
			});
		});

		tiles.forEach((tile, index) => {
			TweenLite.to(tile, 0.3, {
				opacity: 1, y: 0, force3D: true, delay: index * 0.06, rotation: 0, ease: Cubic.easeOut
			});
		});

	}

	hideTiles() {
		const tiles = this.findAll(this.animatablesSelector);

		tiles.forEach((tile) => {
			TweenLite.killTweensOf(tile);
			TweenLite.to(tile, 0.3, {
				opacity: 0,
				y: SHOP_TEMPLATES_VERTICAL_DISPLACEMENT_WIDGET,
				force3D: true,
				delay: 0,
				rotation: 0,
				ease: Cubic.easeOut
			});
		});
	}
}

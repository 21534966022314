import createRequest from 'superagent';

import View from '../../../shared/Core/View';

import './product-detail-wrapper-creator.scss';

export default class ProductDetailWrapperCreator extends View {

	initialize() {
		this.handleDuplicateClick = this.handleDuplicateClick.bind(this);
		this.handleCartUpdated = this.handleCartUpdated.bind(this);

		this.signals.ecommerce.cartUpdated.add(this.handleCartUpdated);

		this.duplicateWrapperButton = this.find('.product-detail-wrapper-creator__duplicate-wrapper');

		if (this.duplicateWrapperButton) {
			this.duplicateWrapperButton.addEventListener('click', this.handleDuplicateClick);
		}
	}

	destroy() {
		if (this.duplicateWrapperButton) {
			this.duplicateWrapperButton.removeEventListener('click', this.handleDuplicateClick);
		}
	}

	handleDuplicateClick(event) {
		event.preventDefault();
		event.stopPropagation();

		this.duplicateWrapperButton.classList.add('button--loading');

		const request = createRequest('POST', event.target.href);
		request.set('Accept', 'application/json')
			.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content)
			.set('X-Requested-With', 'XMLHttpRequest')
			.send()
			.then((response) => {
				const url = response.body.url;
				const route = this.routes.getRoute(url);

				this.signals.pageLoad.requested.dispatch(route);

				this.duplicateWrapperButton.classList.remove('button--loading');
			})
			.catch(() => {
				const error = document.createElement('p');
				error.innerHTML = 'Error duplicating wrapper';

				this.duplicateWrapperButton.parentNode.appendChild(error);
			});
	}

	handleCartUpdated(products) {
		const wrapperUuid = this.find('#wrapper-uuid', document.body).value || null;
        const productId = this.find('#product-id', document.body).value || null;
		const infoElement = this.find('#product-detail-information-content', document.body);
		const nutritionsElement = this.find('#product-detail-nutritional-content', document.body);

		if (!wrapperUuid || !infoElement || !nutritionsElement) return;

        const request = createRequest('GET', '/' + tonysConfig.routes['api.cart.wrapper-info'].route.replace('{productId?}', productId) + '?uuid=' + wrapperUuid);
		request.set('Accept', 'application/json');
		request.set('X-Requested-With', 'XMLHttpRequest');

		request.send().then((response) => {
			const responseObject = JSON.parse(response.text);
			infoElement.innerHTML = responseObject['product-detail-information-content'];
			nutritionsElement.innerHTML = responseObject['product-detail-nutritional-content'];
			infoElement.parentElement.style.height = null;
			nutritionsElement.parentElement.style.height = null;
		})
	}
}

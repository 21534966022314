import View from '../../../shared/Core/View';
import isDescendantOf from '../../../shared/utils/dom/isDescendantOf';

import VideoControls from './components/VideoControls/VideoControls.js';
import VideoFlashAnimation from './components/VideoFlashAnimation/VideoFlashAnimation.js';

import YoutubePlayer from './players/YoutubePlayer';
import HTMLVideoPlayer from './players/HTMLVideoPlayer';
import createPlayerSignals from './players/createPlayerSignals';

import './video.scss';

const VIDEO_CONTROLS_IDLE_TIME = 3;

const CLASS_VIDEO_SIZE_SMALL = 'video--small';
const CLASS_VIDEO_SIZE_TINY = 'video--tiny';
const CLASS_VIDEO_SIZE_MICRO = 'video--microscopic';

const CLASS_VIDEO_STATE_INITIALIZED = 'video--initialized';
const CLASS_VIDEO_STATE_RESET = 'video--reset';
const CLASS_VIDEO_STATE_PAUSED = 'video--paused';
const CLASS_VIDEO_STATE_ENDED = 'video--ended';
const CLASS_VIDEO_STATE_PLAYING = 'video--playing';
const CLASS_VIDEO_STATE_BUFFERING = 'video--buffering';

const TYPE_YOUTUBE = 'youtube';
const TYPE_HTMLVIDEO = 'htmlvideo';

export default class Video extends View {

	initialize(options) {

		this.playerSignals = createPlayerSignals();
		this.idleTimeout = 0;

		this.handleResize = this.handleResize.bind(this);
		this.handleModalHide = this.handleModalHide.bind(this);
		this.handleModalShown = this.handleModalShown.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handlePlayClick = this.handlePlayClick.bind(this);
		this.handlePlayerLoaded = this.handlePlayerLoaded.bind(this);

		this.togglePlayPause = this.togglePlayPause.bind(this);
		this.toggleFullScreen = this.toggleFullScreen.bind(this);

		this.handleLoadStarted = this.handleLoadStarted.bind(this);
		this.handleLoadCompleted = this.handleLoadCompleted.bind(this);

		this.handleBufferingStateEntered = this.handleBufferingStateEntered.bind(this);
		this.handlePlaybackStateEntered = this.handlePlaybackStateEntered.bind(this);
		this.handlePausedStateEntered = this.handlePausedStateEntered.bind(this);
		this.handleEndedStateEntered = this.handleEndedStateEntered.bind(this);

		/**
		 * Create a player for the currenty player type
		 */
		this.player = (this.getPlayerType() === TYPE_YOUTUBE
				? new YoutubePlayer({
					id: this.el.dataset.videoId,
					el: this.find('.video__container'),
					apiReadySignal: this.signals.youtubeAPIFrameReady,
					signals: this.playerSignals,
				})
				: new HTMLVideoPlayer({
					el: this.find('.video__video'),
					signals: this.playerSignals,
				})
		);

		/**
		 * Is the player ready to be controlled?
		 */
		this.playerInitialized = false;

		/**
		 *
		 * @type {boolean}
		 */
		this.pausedDueToModalHide = false;

		/**
		 * Init the controls if they're available
		 */
		this.videoControlsElement = this.find('.video-controls');
		if (this.videoControlsElement) {
			this.videoControls = new VideoControls({
				...options,
				el: this.videoControlsElement,
				player: this.player,
			});

			// Reset idle timer on mousemove
			this.el.addEventListener('mousemove', this.handleMouseMove);
			this.handleMouseMove();
		}

		this.autoFullScreen = this.el.dataset.autoFullscreen === '1';

		/**
		 * Init the play pause overlay unless we're auto playing
		 */
		this.playAnimationElement = this.find('.video__play-animation');
		this.useAutoplay = this.el.dataset.autoplay === '1';
		if (! this.useAutoplay) {
			this.playAnimation = new VideoFlashAnimation({
				...options,
				el: this.find('.video__play'),
			});
			this.pauseAnimation = new VideoFlashAnimation({
				...options,
				el: this.find('.video__pause-animation'),
			});

			this.playButtonElement = this.find('.video__play');
			this.playButtonElement.addEventListener('click', this.handlePlayClick);

			this.interactionCaptureElement = this.find('.video__interaction-capture');
			if (this.interactionCaptureElement) {
				this.interactionCaptureElement.addEventListener('click', this.togglePlayPause);
			}
		}

		this.playerSignals.loadStarted.add(this.handleLoadStarted);
		this.playerSignals.loadCompleted.add(this.handleLoadCompleted);

		this.playerSignals.playbackStarted.add(this.handlePlaybackStateEntered);
		this.playerSignals.paused.add(this.handlePausedStateEntered);
		this.playerSignals.ended.add(this.handleEndedStateEntered);
		this.playerSignals.buffering.add(this.handleBufferingStateEntered);

		this.playerSignals.fullscreenToggleRequested.add(this.toggleFullScreen);

		this.signals.windowResized.add(this.handleResize);
		this.signals.modals.hide.add(this.handleModalHide);
		this.signals.modals.shown.add(this.handleModalShown);

		this.handleResize();

		if (this.useAutoplay) {
			this.loadAndPlay();
			this.handlePlaybackStateEntered();
		}
	}

	reset() {
		if (! this.player) {
			return;
		}

		this.player.stop();

		this.el.classList.remove(CLASS_VIDEO_STATE_INITIALIZED);
		this.replaceStateClass(CLASS_VIDEO_STATE_RESET);

		this.playerInitialized = false;
		this.player.reset();

		if (this.videoControls) {
			this.videoControls.show();
		}

		if (this.playAnimation) {
			this.playAnimation.reset();
		}

		if (this.pauseAnimation) {
			this.pauseAnimation.reset();
		}
	}

	destroy() {
		this.player.destroy();

		if (! this.useAutoplay) {
			this.playAnimation.destroy();
			this.pauseAnimation.destroy();
			this.playButtonElement.removeEventListener('click', this.togglePlayPause);
		}

		if (this.interactionCaptureElement) {
			this.interactionCaptureElement.removeEventListener('click', this.togglePlayPause);
		}

		if (this.videoControls) {
			this.videoControls.destroy();
		}

		this.playerSignals.loadStarted.remove(this.handleLoadStarted);
		this.playerSignals.loadCompleted.remove(this.handleLoadCompleted);

		this.playerSignals.playbackStarted.remove(this.handlePlaybackStateEntered);
		this.playerSignals.paused.remove(this.handlePausedStateEntered);
		this.playerSignals.ended.remove(this.handleEndedStateEntered);
		this.playerSignals.buffering.remove(this.handleBufferingStateEntered);

		this.playerSignals.fullscreenToggleRequested.remove(this.toggleFullScreen);
		this.signals.windowResized.remove(this.handleResize);

		this.el.removeEventListener('mousemove', this.handleMouseMove);
	}

	getPlayerType() {
		if (this.el.dataset.videoId) {
			return TYPE_YOUTUBE;
		}

		return TYPE_HTMLVIDEO;
	}

	togglePlayPause() {
		if (this.playerInitialized) {
			this.player.playPause();
		} else {
			this.handlePlayClick();
		}
	}

	toggleFullScreen() {
		if (this.isFullScreen()) {
			if (this.el.requestFullscreen) {
				this.el.requestFullscreen();
			} else if (this.el.msRequestFullscreen) {
				this.el.msRequestFullscreen();
			} else if (this.el.mozRequestFullScreen) {
				this.el.mozRequestFullScreen();
			} else if (this.el.webkitRequestFullscreen) {
				this.el.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		} else {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen();
			}
		}
	}

	loadAndPlay() {
		this.el.classList.remove(CLASS_VIDEO_STATE_RESET);
		this.player.load(this.handlePlayerLoaded);
	}

	handlePlayClick() {
		this.loadAndPlay();
	}

	handlePlayerLoaded() {
	}

	handleMouseMove() {
		clearTimeout(this.idleTimeout);

		if (! this.videoControls) {
			return;
		}

		if (! this.playerInitialized) {
			return;
		}

		if (! this.player.isPlaying()) {
			this.videoControls.show();
			return;
		}

		this.idleTimeout = setTimeout(() => {
			this.videoControls.hide();
		}, VIDEO_CONTROLS_IDLE_TIME * 1000);

		this.videoControls.show();
	}

	handleResize() {
		const width = this.el.offsetWidth;

		if (width < 768 && width >= 480) {
			this.el.classList.add(CLASS_VIDEO_SIZE_SMALL);
		} else {
			this.el.classList.remove(CLASS_VIDEO_SIZE_SMALL);
		}

		if (width < 480) {
			this.el.classList.add(CLASS_VIDEO_SIZE_TINY);
		} else {
			this.el.classList.remove(CLASS_VIDEO_SIZE_TINY);
		}

		if (width < 270) {
			this.el.classList.add(CLASS_VIDEO_SIZE_MICRO);
		} else {
			this.el.classList.remove(CLASS_VIDEO_SIZE_MICRO);
		}
	}

	handleModalHide(identifier, modal) {
		const videoIsInsideModal = isDescendantOf(this.el, modal.el);
		if (videoIsInsideModal) {
			this.pausedDueToModalHide = true;
			this.player.pause();
		}
	}

	handleModalShown(identifier, modal) {
		const videoIsInsideModal = isDescendantOf(this.el, modal.el);
		if (videoIsInsideModal && this.pausedDueToModalHide) {
			this.player.play();
		}
	}

	handleLoadStarted() {
		if (! this.useAutoplay) {
			this.playAnimation.play();
		}
	}

	handleLoadCompleted() {
		this.el.classList.add(CLASS_VIDEO_STATE_INITIALIZED);
		this.playerInitialized = true;

		if (this.useAutoplay) {
			this.player.setVolume(0);
			this.player.play();
		}

		if (this.videoControls) {
			this.videoControls.show();
		}
	}

	handlePlaybackStateEntered() {
		this.replaceStateClass(CLASS_VIDEO_STATE_PLAYING);

		if (this.autoFullScreen) {
			this.toggleFullScreen();
		}
	}

	handlePausedStateEntered() {
		this.replaceStateClass(CLASS_VIDEO_STATE_PAUSED);

		if (! this.useAutoplay) {
			this.pauseAnimation.play();
		}

		if (this.videoControls && ! this.autoFullScreen) {
			this.videoControls.show();
		}
	}

	handleBufferingStateEntered() {
		this.replaceStateClass(CLASS_VIDEO_STATE_BUFFERING);
	}

	handleEndedStateEntered() {
		this.replaceStateClass(CLASS_VIDEO_STATE_ENDED);
	}

	replaceStateClass(newStateClass) {
		this.el.classList.remove(CLASS_VIDEO_STATE_BUFFERING);
		this.el.classList.remove(CLASS_VIDEO_STATE_PAUSED);
		this.el.classList.remove(CLASS_VIDEO_STATE_PLAYING);
		this.el.classList.remove(CLASS_VIDEO_STATE_ENDED);

		this.el.classList.add(newStateClass);
	}

	isFullScreen() {
		return (
			! document.fullscreenElement &&
			! document.mozFullScreenElement &&
			! document.webkitFullscreenElement &&
			! document.msFullscreenElement
		);
	}

}

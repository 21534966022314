import View from '../../../shared/Core/View';

import './product-options.scss';

export default class ProductOptions extends View {

    initialize() {

    }

    destroy() {
    }
}

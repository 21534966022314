import BaseSplashView from './BaseSplash';

import {TimelineMax, Power2, TweenLite} from 'gsap/all';

export default class Splash_19_1 extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.setupUI();
		this.setupTimeline();

	}

	setupUI() {

		this.ui = {
			titleLines: this.el.querySelectorAll('.js-title-line')
		}

	}

	update(options) {

		TweenLite.to(this.tl, options.duraton, {progress: options.progress});

	}

	setupTimeline() {

		this.tl = new TimelineMax({paused: true});
		this.tl.staggerFrom(this.ui.titleLines, 1, {opacity: 1, y: '-1200', ease: Power2.easeInOut}, .125, 0);

	}

}

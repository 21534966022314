import View from 'Core/View.js';

const SCROLL_BACK_THRESHOLD = 60;
const NAVIGATION_OFFSET = 50;

export default class BodyStickyTop extends View {

	initialize() {
		this.update = this.update.bind(this);

		this.bodyHasStickyClass = false;
		this.bodyHasTemporaryStickyClass = false;

		this.previousScrollPosition = window.pageYOffset;
		this.lastDirectionSwitchAt = window.pageYOffset;
		this.direction = 1;
		this.currentDirection = 1;
		this.preventUpdates = false;

		this.signals.pageLock.lockingStarted.add(() => {
			this.preventUpdates = true;
		});
		this.signals.pageLock.unlocked.add(() => {
			this.preventUpdates = false;
		});

		window.addEventListener('scroll', this.update);
		this.update();
	}

	destroy() {
		window.removeEventListener('scroll', this.update);
	}

	update() {
		if (this.preventUpdates) {
			return;
		}

		const scrollTop = window.pageYOffset;

		// Detect a change of direction
		const newDirection = this.previousScrollPosition < scrollTop ? 1 : -1;
		if (newDirection != this.currentDirection) {
			this.currentDirection = newDirection;
			this.lastDirectionSwitchAt = scrollTop;
		}

		// Check if we've moved up more than the threshold
		if ((this.lastDirectionSwitchAt - scrollTop) > SCROLL_BACK_THRESHOLD || this.lastDirectionSwitchAt < scrollTop) {
			this.direction = this.currentDirection;
		}

		// Set the classes accordingly
		const goingDown = this.direction === 1;
		if (scrollTop > NAVIGATION_OFFSET) {
			this.stick();
			if (! goingDown) {
				this.temporaryUnstick();
			} else {
				this.cancelTemporaryUnstick();
			}
		} else {
			this.unstick(goingDown);
		}

		this.previousScrollPosition = scrollTop;
	}

	stick() {
		if (this.bodyHasStickyClass) {
			return;
		}

		this.bodyHasStickyClass = true;
		this.el.classList.add('sticky-nav');
	}

	unstick() {
		if (! this.bodyHasStickyClass) {
			return;
		}

		this.cancelTemporaryUnstick();

		this.bodyHasStickyClass = false;
		this.el.classList.remove('sticky-nav');
	}

	temporaryUnstick() {
		if (this.bodyHasTemporaryStickyClass) {
			return;
		}

		this.bodyHasTemporaryStickyClass = true;
		this.el.classList.add('sticky-nav--going-up');
	}

	cancelTemporaryUnstick() {
		if (! this.bodyHasTemporaryStickyClass) {
			return;
		}

		this.bodyHasTemporaryStickyClass = false;
		this.el.classList.remove('sticky-nav--going-up');
	}
}

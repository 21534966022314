import View from '../../../../../shared/Core/View';

import {formatTime} from '../../../../../shared/utils/Formatting';

import VideoTimeline from './components/VideoTimeline/VideoTimeline';
import VideoVolume from './components/VideoVolume/VideoVolume';

import './video-controls.scss';
import VideoFullscreenButton from './components/VideoFullscreenButton/VideoFullscreenButton';

const CLASS_SHOW = 'video-controls--show';

export default class VideoControls extends View {

	initialize(options) {
		this.togglePause = this.togglePause.bind(this);
		this.updateProgress = this.updateProgress.bind(this);

		this.currentTimeElement = this.find('.video-controls__current-time');
		this.durationElement = this.find('.video-controls__duration');
		this.pausePlayToggleElement = this.find('.video-controls__pause-play-toggle');

		this.player = options.player;
		this.playerSignals = this.player.signals;
		this.playerSignals.progress.add(this.updateProgress);

		this.videoTimeLine = new VideoTimeline({
			...options,
			el: this.find('.video-controls__timeline'),
		});
		this.videoVolume = new VideoVolume({
			...options,
			el: this.find('.video-controls__volume'),
		});

		this.fullscreen = new VideoFullscreenButton({
			...options,
			el: this.find('.video-controls__fullscreen'),
		});

		this.pausePlayToggleElement.addEventListener('click', this.togglePause);

		this.updateProgress();
	}

	destroy() {
		this.videoTimeLine.destroy();
		this.videoVolume.destroy();
		this.fullscreen.destroy();

		this.pausePlayToggleElement.removeEventListener('click', this.togglePause);
	}

	updateProgress() {
		this.currentTimeElement.innerText = formatTime(this.player.getCurrentTime());
		this.durationElement.innerText = formatTime(this.player.getDuration());
	}

	show() {
		if (! this.shown) {
			this.shown = true;
			this.el.classList.add(CLASS_SHOW);
		}
	}

	hide() {
		if (this.shown) {
			this.shown = false;
			this.el.classList.remove(CLASS_SHOW);
		}
	}

	togglePause() {
		this.player.playPause();
	}
}

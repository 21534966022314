import View from '../../../shared/Core/View';
import TweenLite from 'gsap/TweenLite';

import './sweet-solution-kit.scss'

export default class SweetSolutionKitProductDetail extends View {

	initialize() {
		this.newPageLoaded = this.newPageLoaded.bind(this);

		this.wrapperContent = this.find('.wrapper__content', document.body);

		this.background = document.createElement('div');
		this.background.className = 'sweet-solution-kit__header-background';

		this.wrapperContent.insertBefore(this.background, this.wrapperContent.firstChild);

		this.signals.pageLoad.started.add(this.newPageLoaded);
	}

	destroy() {
		this.signals.pageLoad.started.remove(this.newPageLoaded);

		if (this.background) {
			this.wrapperContent.removeChild(this.background);
			this.background = null;
		}
	}

	newPageLoaded() {
		TweenLite.to(this.background, 0.15, {
			scale: 0.8, opacity: 0, transformOrigin: '50% 0', onComplete: () => {
				this.wrapperContent.removeChild(this.background);
				this.background = null;
			}
		});
	}
}

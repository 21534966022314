import TweenLite from 'gsap/TweenLite';
import './scrollbar.scss';

export default class Scrollbar {

	constructor(el) {

		this.el = el;

		const isFireFox = /Firefox/i.test(navigator.userAgent);

		this.thumb = this.el.querySelector('.scrollbar__thumb');
		this.bodyElement = document.body;
		this.scrollWheelEvent = isFireFox ? 'DOMMouseScroll' : 'mousewheel';

		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseWheel = this.onMouseWheel.bind(this);
		this.onMouseMove = this.onMouseMove.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
	}

	/**
	 *
	 * @param targetElement The element that needs to be scrolled
	 * @param eventElement The element listening to events (usually the container around the targetElement)
	 */
	activate(targetElement, eventElement) {

		this.removeEvents();

		this.target = targetElement;
		this.eventContainer = eventElement;
		this.el.classList.add('active');
		this.offsetY = 0;
		this.addEvents();
	}

	deactivate() {
		this.removeEvents();
		this.el.classList.remove('active');
	}

	addEvents() {
		this.eventContainer.addEventListener('mousedown', this.onMouseDown);
		this.eventContainer.addEventListener('touchstart', this.onMouseDown);

		this.thumb.addEventListener('mousedown', this.onMouseDown);
		this.thumb.addEventListener('touchstart', this.onMouseDown);

		this.eventContainer.addEventListener(this.scrollWheelEvent, this.onMouseWheel);
	}

	removeEvents() {
		if (this.target) {
			this.thumb.removeEventListener('mousedown', this.onMouseDown);
			this.thumb.removeEventListener('touchstart', this.onMouseDown);
		}

		if (this.eventContainer) {
			this.eventContainer.removeEventListener('mousedown', this.onMouseDown);
			this.eventContainer.removeEventListener('touchstart', this.onMouseDown);
			this.eventContainer.removeEventListener(this.scrollWheelEvent, this.onMouseWheel);
		}
	}

	hide() {
		this.el.classList.add('hidden');
	}

	show() {
		this.el.classList.remove('hidden');
	}

	update(maxHeight = null) {
		if (!this.eventContainer) {
			return;
		}

		if (!maxHeight) {
			maxHeight = this.eventContainer.offsetHeight;
		} else {
			TweenLite.set(this.eventContainer, {height: maxHeight});
		}

		this.maxHeight = maxHeight;

		const height = this.el.offsetHeight;
		const targetHeight = this.target.offsetHeight;

		var visiblePercentage = maxHeight / targetHeight; // check visible area

		var newHeight = visiblePercentage * height;
		newHeight = isNaN(newHeight) || newHeight < 0 ? 0 : newHeight;

		TweenLite.set(this.thumb, {height: newHeight}); // height of thumb
		this.maxY = (1 - visiblePercentage) * height; // maximum y displacement for thumb

		this.updateLayout();
	}

	onMouseDown(event) {

		event.stopImmediatePropagation();
		event.preventDefault();

		this.inverted = event.currentTarget == this.eventContainer;
		this.currentY = event.pageY;

		this.bodyElement.addEventListener('mousemove', this.onMouseMove);
		this.bodyElement.addEventListener('touchmove', this.onMouseMove);

		this.bodyElement.addEventListener('touchend', this.onMouseUp);
		this.bodyElement.addEventListener('mouseup', this.onMouseUp);
		this.bodyElement.addEventListener('mouseleave', this.onMouseUp);
	}

	onMouseUp(event) {
		event.stopImmediatePropagation();
		event.preventDefault();

		this.bodyElement.removeEventListener('mousemove', this.onMouseMove);
		this.bodyElement.removeEventListener('touchmove', this.onMouseMove);

		this.bodyElement.removeEventListener('touchend', this.onMouseUp);
		this.bodyElement.removeEventListener('mouseup', this.onMouseUp);
		this.bodyElement.removeEventListener('mouseleave', this.onMouseUp);
	}

	onMouseMove(event) {
		event.preventDefault();

		const dy = event.pageY - this.currentY;
		this.currentY = event.pageY;

		if (this.inverted) {
			this.offsetY -= dy;
		} else {
			this.offsetY += dy;
		}

		this.offsetY = Math.max(Math.min(this.offsetY, this.maxY), 0);
		this.updateLayout();
	}

	onMouseWheel(event) {
		if (event) {
			event.preventDefault();

			const delta = ((event.deltaY || - event.wheelDelta || event.detail * 10) || 1);
			this.offsetY += delta;
			this.offsetY = Math.max(Math.min(this.offsetY, this.maxY), 0);
			this.updateLayout();
		}
	}

	updateLayout() {
		const targetHeight = this.target.offsetHeight;

		const percentage = this.offsetY / this.maxY;
		const dy = (this.maxHeight - targetHeight) * percentage;
		TweenLite.set(this.target, {y: dy});
		TweenLite.set(this.thumb, {y: this.offsetY});

		if (targetHeight <= this.maxHeight) {
			TweenLite.set(this.target, {y: 0});
			TweenLite.set(this.thumb, {y: 0});
			this.hide();
		} else {
			this.show();
		}
	}
}

export default class URLService {

	constructor(options) {
		this.routes = options.routes;
		this.signals = options.signals;
		this.currentRoute = options.routeAtLoad;

		this.handleRequest = this.handleRequest.bind(this);
		this.handleRedirect = this.handleRedirect.bind(this);
		this.handlePopState = this.handlePopState.bind(this);

		this.signals.pageLoad.requested.add(this.handleRequest);
		this.signals.pageLoad.redirected.add(this.handleRedirect);

		// Listen to popstate events
		window.onpopstate = this.handlePopState;
	}

	handleRequest(route, options) {
		this.updateURL(route, {
			ignoreHistory: (options && options.excludeFromHistory) || false,
			replace: route.replace,
		});
	}

	handleRedirect(route) {
		this.updateURL(route, {replace: true});
	}

	handlePopState() {
		this.signals.pageLoad.requested.dispatch(this.routes.getRoute(), {
			excludeFromHistory: true,
		});
	}

	updateURL(route, options) {
		options = {
			ignoreHistory: false,
			replace: false,
			...options
		};

		if (this.routesAreIdentical(route, this.currentRoute)) {
			return;
		}

		this.currentRoute = route;

		if (options.ignoreHistory) {
			return;
		}

		if (options.replace) {
			history.replaceState(null, null, route.url);
			return;
		}

		history.pushState(null, null, route.url);
	}

	routesAreIdentical(a, b) {
		return a.url === b.url;
	}
}

class ViewMapping {

	/**
	 * @param {Context} context
	 * @param {String} selector
	 * @param {View} ViewClass
	 * @param {Object} [options]
	 */
	constructor(context, selector, ViewClass, options) {

		// Make ES6 compatible
		if (typeof ViewClass == 'object' && ViewClass.default) {
			ViewClass = ViewClass.default;
		}

		// Store params
		this.context = context;
		this.selector = selector;
		this.ViewClass = ViewClass;
		this.options = options || {};

		// Init book keeping arrays
		this.views = [];
	}

	/**
	 * Run the mapping, creating the view if necessary
	 *
	 * @param {Element|HTMLDocument} [contextNode]
	 */
	build(contextNode = null) {
		const elements = this.getDOMElementsFor(this.selector, contextNode);
		elements.forEach(this._createView.bind(this));
	}

	/**
	 * Triggered when all views are built
	 */
	postBuild() {
		if (typeof this.ViewClass.prototype.postBuild !== 'function') {
			return;
		}

		this.views.forEach(view => view.postBuild());
	}

	/**
	 * Destroy views that a no longer attached to the dom and create new ones
	 *
	 * @param {Element|HTMLDocument} [contextNode]
	 */
	refresh(contextNode = null) {

		// Remove views that no longer have a node in the DOM
		this.views.forEach(view => {
			if (document.body.contains(view.el)) {
				return;
			}

			this._destroyView(view);
		});

		// Create views for new elements
		const newElements = this.getNewElementsForSelector(this.selector, contextNode);
		newElements.forEach(this._createView.bind(this));
	}

	/**
	 * Destroy all views that may have been created by this mapping.
	 */
	destroy() {
		this.views.forEach(view => this._destroyView(view));

		this.views = [];
	}

	/**
	 *
	 * @param {Node} el
	 *
	 * @return {View}
	 */
	getViewForEl(el) {
		return this.views.find(view => view.el === el);
	}

	/**
	 * Check whether this mapping is for the supplied selector
	 * @param {String} selector
	 */
	isSelector(selector) {
		return this.selector === selector;
	}

	/**
	 * @param {Node} el
	 * @private
	 */
	_createView(el) {
		if (typeof this.ViewClass !== 'function') {
			console.warn('[TONYS] Cannot create View for selector ' + this.selector);
			return;
		}

		// Create the view
		const view = new this.ViewClass({
			el: el,
			signals: this.context.signals,
			refreshViewsForElement: this.context.views.refresh.bind(this.context.views),
			findViewForElement: this.context.views.getViewForEl.bind(this.context.views),
			routeAtLoad: this.context.routeAtLoad,
			routes: this.context.routes,
			impressionTracker: this.context.impressionTracker,
			modalDictionary: this.context.modalDictionary,
			scrollTriggerService: this.context.scrollTriggerService,
			...this.options
		});
		this.views.push(view);

		if (typeof view.destroy !== 'function') {
			throw new Error('View "' + this.getFunctionName(this.ViewClass) + '" for "' + this.selector + '" element has not defined a destroy method.');
		}
	}

	/**
	 * @param view
	 * @private
	 */
	_destroyView(view) {

		// Remove view from views
		this.views.splice(this.views.indexOf(view), 1);

		// If it has a destroy method, call that
		if (view.destroy) {
			view.destroy();
			return;
		}

		// Otherwise, just remove it from the dom
		if (view.el.parentNode) {
			view.el.parentNode.removeChild(view.el);
		}
	}

	/**
	 * @param {String|Node} selector
	 * @param @param {Element|HTMLDocument} [contextNode]
	 * @returns {Array}
	 */
	getDOMElementsFor(selector, contextNode = null) {

		// Default to document context
		if (!contextNode) {
			contextNode = document;
		}

		if (typeof selector === 'string') {
			return [...contextNode.querySelectorAll(selector)];
		}

		return [selector];
	}

	/**
	 * @param {String|Node} selector
	 * @param @param {Element|HTMLDocument} [contextNode]
	 * @returns {Array}
	 */
	getNewElementsForSelector(selector, contextNode) {
		const elementsInDOM = this.getDOMElementsFor(selector, contextNode);
		const elementsWithView = this.views.map(view => view.el);

		return elementsInDOM.filter(el => elementsWithView.indexOf(el) < 0);
	}

	/**
	 * @returns {String}
	 */
	toString() {
		return '[object ViewMapping]';
	}

	/**
	 * @see https://stackoverflow.com/questions/2648293/javascript-get-function-name
	 * @param {Function} func
	 * @returns {string}
	 */
	getFunctionName(func) {
		// Match:
		// - ^          the beginning of the string
		// - function   the word 'function'
		// - \s+        at least some white space
		// - ([\w\$]+)  capture one or more valid JavaScript identifier characters
		// - \s*        optionally followed by white space (in theory there won't be any here,
		//              so if performance is an issue this can be omitted[1]
		// - \(         followed by an opening brace
		//
		var result = /^function\s+([\w\$]+)\s*\(/.exec(func.toString())

		return result ? result[1] : '' // for an anonymous function there won't be a match
	}
}

export default ViewMapping;

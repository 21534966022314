import TimelineMax from 'gsap/TimelineMax';
import createRequest from 'superagent';
import template from 'lodash.template';

import View from 'Core/View.js';

import isDescendantOf from '../../../shared/utils/dom/isDescendantOf';
import debounce from '../../../shared/utils/generic/debounce';

import './google-search.scss';

const PAGE_LOCK_NAME = 'search';
const CLASS_OPEN = 'google-search--open';
const CLASS_SEARCHING = 'google-search--searching';
const CLASS_HAS_QUERY = 'google-search--has-query';
const CLASS_HAS_RESULTS = 'google-search--has-results';

export default class GoogleSearch extends View {

	initialize() {
		this.handleDocumentClick = this.handleDocumentClick.bind(this);
		this.handleLockRequested = this.handleLockRequested.bind(this);
		this.handleUnlockRequested = this.handleUnlockRequested.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleEraseClick = this.handleEraseClick.bind(this);
		this.handleNavigate = this.handleNavigate.bind(this);
		this.debouncedSearch = debounce(this.search, 350, false);

		this.url = this.el.dataset.url;
		this.lastSearch = '';
		this.canSubmit = false;
		this.embeddedMode = false;
		this.resultTemplate = template(document.querySelector('.google-search__result-template').innerHTML);

		this.background = this.find('.google-search__background');
		this.content = this.find('.google-search__content');
		this.input = this.find('.google-search__input');
		this.form = this.find('.google-search__query');
		this.results = this.find('.google-search__results');
		this.noResults = this.find('.google-search__no-results');
		this.erase = this.find('.google-search__field__erase');

		this.input.value = '';
		this.background.style.backgroundColor = this.getBackgroundColorOfFirstMenuSection();

		this.erase.addEventListener('click', this.handleEraseClick);
		this.form.addEventListener('submit', this.handleFormSubmit);
		this.signals.pageLoad.requested.add(this.handleNavigate);
		this.signals.pageLock.lockRequested.add(this.handleLockRequested);
		this.signals.pageLock.unlockRequested.add(this.handleUnlockRequested);

		this.tl = new TimelineMax({
			onReverseComplete: () => {
				this.onClosed();
			},
		});
		this.tl.fromTo(this.background, 0.3, {opacity: 0}, {opacity: 1});
		this.tl.fromTo(this.results, 0.2, {opacity: 0, y: -30}, {opacity: 1, y: 0}, '-=0.2');
		this.tl.pause();
	}

	destroy() {
		this.erase.removeEventListener('click', this.handleEraseClick);
		this.form.removeEventListener('submit', this.handleFormSubmit);

		this.signals.pageLoad.requested.remove(this.handleNavigate);
		this.signals.pageLock.lockRequested.remove(this.handleLockRequested);
		this.signals.pageLock.unlockRequested.remove(this.handleUnlockRequested);
	}

	search() {
		const query = this.input.value.trim();

		if (this.lastSearch === query) {
			return;
		}

		this.showNoResults();

		if (query === '') {
			return;
		}

		this.lastSearch = query;
		this.el.classList.add(CLASS_SEARCHING);

		this.signals.search.startSearch.dispatch(this.url, query);

		createRequest('GET', this.url)
			.set('Accept', 'application/json')
			.set('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content)
			.set('X-Requested-With', 'XMLHttpRequest')
			.query({q: query})
			.then((response) => {
				this.el.classList.remove(CLASS_SEARCHING);

				if (! this.isResponseToLastSearch(response)) {
					console.log('[TONYS] Skipping search response. Out of date.');
					return;
				}

				if (! response.body.items) {
					this.showResultsContainer(this.noResults);
					return;
				}

				this.showResults(response.body.items);
			})
			.catch(() => {
				this.el.classList.remove(CLASS_SEARCHING);
				alert(this.el.dataset.error);
			});
	}

	showNoResults() {
		this.el.classList.remove(CLASS_HAS_RESULTS);
		this.noResults.style.display = 'none';
		this.results.style.display = 'none';
		this.results.innerHTML = '';

		this.signals.search.hidingResults.dispatch();
	}

	showResults(items) {
		this.showResultsContainer(this.results);

		this.results.innerHTML = items.map(item => {
			return this.resultTemplate({
				title: item.title,
				description: item.snippet,
				thumbUrl: this.imageFromSearchResultItem(item),
				url: item.link,
			});
		}).join('');

		const results = this.findAll('.google-search__result', this.results);
		const tl = new TimelineMax({delay: 0.2});
		tl.staggerFrom(results, 0.2, {opacity: 0, x: -40, ease: 'Sine.easeOut'}, 0.05);
	}

	showResultsContainer(el) {
		this.showNoResults();

		el.style.display = 'block';
		this.el.classList.add(CLASS_HAS_RESULTS);

		this.signals.search.showingResults.dispatch();
	}

	imageFromSearchResultItem(item) {
		if (! item.pagemap || ! item.pagemap.cse_thumbnail || item.pagemap.cse_thumbnail.length < 1) {
			return null;
		}

		return item.pagemap.cse_thumbnail[0].src;
	}

	isResponseToLastSearch(response) {
		if (! response || ! response.body || ! response.body.queries || ! response.body.queries.request) {
			return false;
		}

		const request = response.body.queries.request;
		if (request.length < 1) {
			return false;
		}

		return request[0].searchTerms === this.lastSearch;
	}

	open() {
		this.reset();

		this.el.classList.add(CLASS_OPEN);
		this.tl.play();

		this.canSubmit = true;

		setTimeout(() => {
			document.addEventListener('click', this.handleDocumentClick);
			this.input.addEventListener('keyup', this.handleInputChange);
			this.input.addEventListener('change', this.handleInputChange);
		}, 300);

		if (! this.embeddedMode) {
			this.input.focus();
		}
	}

	close() {
		this.tl.reverse();

		this.canSubmit = false;
		this.embeddedMode = false;

		this.signals.search.hidingResults.dispatch();
	}

	onClosed() {
		this.el.classList.remove(CLASS_OPEN);
		this.signals.pageLock.unlockRequested.dispatch(PAGE_LOCK_NAME);

		document.removeEventListener('click', this.handleDocumentClick);
		this.input.removeEventListener('keyup', this.handleInputChange);
		this.input.removeEventListener('change', this.handleInputChange);
	}

	reset() {
		this.lastSearch = '';
		this.input.value = '';
		this.showNoResults();
		this.toggleErase();
	}

	toggleErase() {
		if (this.input.value === '') {
			this.el.classList.remove(CLASS_HAS_QUERY);
		} else {
			this.el.classList.add(CLASS_HAS_QUERY);
		}
	}

	getBackgroundColorOfFirstMenuSection() {
		const section = document.querySelector('.navigation__item:nth-child(2)');
		if (!section) {
			return '#0066cc';
		}

		return window.getComputedStyle(section).backgroundColor;
	}

	handleInputChange() {
		this.toggleErase();

		this.debouncedSearch();
	}

	handleEraseClick(event) {
		event.preventDefault();
		this.reset();
		this.input.focus();
	}

	handleFormSubmit(event) {
		event.preventDefault();

		if (! this.canSubmit) {
			return;
		}

		this.lastSearch = '';
		this.handleInputChange();
	}

	handleNavigate() {
		this.close();
	}

	handleLockRequested(name) {
		if (name === 'navigation') {
			this.embeddedMode = true;
		}

		if (name !== PAGE_LOCK_NAME && ! this.embeddedMode) {
			return;
		}

		this.open();
	}

	handleUnlockRequested(name) {
		if (name === PAGE_LOCK_NAME) {
			return;
		}

		if (this.embeddedMode) {
			this.onClosed();
		}
	}

	handleDocumentClick(event) {
		if (isDescendantOf(event.target, this.content) || this.embeddedMode) {
			return;
		}

		if (this.embeddedMode) {
			this.reset();
		} else {
			this.close();
		}
	}
}

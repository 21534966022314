import View from '../../../shared/Core/View';

import './consents-modal.scss';

export default class ConsentsModal extends View {

	initialize() {
		this.handleFormSucceeded = this.handleFormSucceeded.bind(this);
		this.handleFormFailed = this.handleFormFailed.bind(this);

		this.signals.forms.succeeded.add(this.handleFormSucceeded);
		this.signals.forms.failed.add(this.handleFormFailed);
	}

	destroy() {
		this.signals.forms.succeeded.remove(this.handleFormSucceeded);
		this.signals.forms.failed.remove(this.handleFormFailed);
	}

	handleFormFailed(formId) {
	}

	handleFormSucceeded(formId, response) {
		const modal = window.tonysModals['consents-modal'];
		if(modal) {
			modal.hide();
		}
	}
}

import template from 'lodash.template';
import TweenLite from 'gsap/TweenLite';
import Signal from 'signals';

import View from '../../../shared/Core/View';
import ExcelAddressesUploadModal from '../ExcelAddressesUploadModal/ExcelAddressesUploadModal';
import throttle from '../../../shared/utils/generic/throttle';
import createEvent from '../../../shared/utils/event/create';
import findAncestorByClass from '../../../shared/utils/dom/findAncestorByClass';
import ErrorDisplay from '../../general/ErrorDisplay/ErrorDisplay';

const RECIPIENT_LIMIT = 5;
const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

import './recipient-list.scss';

export default class RecipientList extends View {
	initialize(options) {
		this.handleRecipientsImported = this.handleRecipientsImported.bind(this);
		this.handleAddRecipientClick = this.handleAddRecipientClick.bind(this);
		this.handleRecipientClick = this.handleRecipientClick.bind(this);
		this.handleFieldChange = this.handleFieldChange.bind(this);
		this.handleRemoveClick = this.handleRemoveClick.bind(this);
		this.handleDisableLimitClick = this.handleDisableLimitClick.bind(this);
		this.sortRecipients = this.sortRecipients.bind(this);
		this.throttledRender = throttle(() => {
			const focus = document.activeElement;

			this.render();
			this.placeForm();

			focus.focus();
		}, 1000, {leading: false});
		this.throttledReloadForm = throttle(() => this.loadForm(this.currentRecipientIndex), 500, {leading: false});

		this.recipientCountChanged = new Signal();
		this.useRecipientErrors = false;

		this.screen.resized.add(this.throttledReloadForm);

		this.addButton = this.find('.recipient-list__add-button');
		this.container = this.find('.recipient-list__list-container');
		this.form = this.find('.recipient-list__form');
		this.formFieldsContainer = this.find('.recipient-list__form-fields');
		this.formFieldInputs = this.findAll('.recipient-list__field input, .recipient-list__field textarea');
		this.disableLimitButton = this.find('.recipient-list__disable-limit');
		this.removeButton = this.find('.recipient-list__remove');
		this.valueInput = this.find('.recipient-list__value-input');
		this.template = template(this.find('#recipient-list-template').innerHTML);

		this.limitList = true;
		this.disableLimitButton.style.display = 'none';
		this.disableLimitButtonTemplate = template(this.disableLimitButton.dataset.template);

		this.errors = new ErrorDisplay({...options, el: this.formFieldsContainer});

		this.uploadModal = new ExcelAddressesUploadModal({
			...options,
			el: this.find('.excel-addresses-upload-modal'),
		});

		this.uploadModal.onRecipientsReceived.add(this.handleRecipientsImported);
		this.disableLimitButton.addEventListener('click', this.handleDisableLimitClick);
		this.container.addEventListener('click', this.handleRecipientClick);
		this.addButton.addEventListener('click', this.handleAddRecipientClick);
		this.removeButton.addEventListener('click', this.handleRemoveClick);

		this.formFieldInputs.forEach(el => {
			el.addEventListener('change', this.handleFieldChange);
			el.addEventListener('keyup', this.handleFieldChange);
		});

		this.currentRecipientIndex = 0;
		this.recipients = JSON.parse(this.el.dataset.initial) || [];
		this.recipientCountChanged.dispatch(this.recipients.length);

		this.loadForm(0);
	}

	destroy() {
		this.screen.resized.remove(this.throttledReloadForm);

		this.uploadModal.onRecipientsReceived.remove(this.handleRecipientsImported);
		this.disableLimitButton.removeEventListener('click', this.handleDisableLimitClick);
		this.container.removeEventListener('click', this.handleRecipientClick);
		this.addButton.removeEventListener('click', this.handleAddRecipientClick);
		this.removeButton.removeEventListener('click', this.handleRemoveClick);

		this.formFieldInputs.forEach(el => {
			el.removeEventListener('change', this.handleFieldChange);
			el.removeEventListener('keyup', this.handleFieldChange);
		});

		this.uploadModal.destroy();
	}

	render() {
		const recipientsToDisplay = this.limitList ? this.recipients.slice(0, RECIPIENT_LIMIT) : this.recipients;

		this.container.innerHTML = this.template({
			recipients: recipientsToDisplay,
			current: this.currentRecipientIndex,
		});

		this.disableLimitButton.innerHTML = this.disableLimitButtonTemplate({count: this.recipients.length - RECIPIENT_LIMIT});

		this.valueInput.value = JSON.stringify(this.recipients.map(r => {
			const {errors, ...rest} = r; // eslint-disable-line

			return rest;
		}));

		this.valueInput.dispatchEvent(createEvent('change'));

		if (this.recipients.length > RECIPIENT_LIMIT && this.limitList) {
			this.disableLimitButton.style.display = 'inline-block';
		} else {
			this.disableLimitButton.style.display = 'none';
		}
	}

	placeForm() {

		if (this.screen.isOneOf(['mobile', 'tablet'])) {
			const list = this.find('.recipient-list__list');
			const node = list.children[this.currentRecipientIndex];

			list.insertBefore(this.formFieldsContainer, node.nextSibling);

			return;
		}

		this.form.appendChild(this.formFieldsContainer);
	}

	applyErrors(errors) {
		this.recipients.forEach((recipient, index) => {
			recipient.errors = errors[index];
		});

		this.sortRecipients();
		this.loadForm(this.currentRecipientIndex);
	}

	loadForm(recipientIndex) {
		const previousRecipientIndex = this.currentRecipientIndex;
		this.currentRecipientIndex = recipientIndex;

		this.render();

		const updateFields = () => {
			const recipient = this.recipients[recipientIndex];

			this.useRecipientErrors = true;
			this.formFieldInputs.forEach(el => {
				el.value = recipient[el.name] || '';

				el.dispatchEvent(createEvent('change'));
			});
			this.useRecipientErrors = false;

			this.errors.refresh();

			if (!recipient.errors) {
				return;
			}

			this.errors.showErrors(recipient.errors);
		};

		this.removeButton.style.display = this.recipients.length > 1 ? 'inline-block' : 'none';

		this.placeForm();

		if (this.screen.isOneOf(['mobile', 'tablet'])) {
			updateFields();

		} else {
			if (previousRecipientIndex !== recipientIndex) {
				TweenLite.to(this.formFieldsContainer, 0.15, {
					y: 20,
					opacity: 0,
					ease: Circ.easeOut,
					onComplete: () => {
						updateFields();

						TweenLite.fromTo(this.formFieldsContainer, 0.15, {
							y: -20,
							opacity: 0,
						}, {
							y: 0,
							opacity: 1,
							ease: Circ.easeOut,
						});
					}
				});
			} else {
				updateFields();
			}
		}
	}

	handleDisableLimitClick(event) {
		event.preventDefault();

		this.limitList = false;
		this.disableLimitButton.style.display = 'none';

		this.render();
	}

	handleRemoveClick() {
		if (this.recipients.length <= 1) {
			return;
		}

		this.recipients.splice(this.currentRecipientIndex, 1);

		this.recipientCountChanged.dispatch(this.recipients.length);

		this.loadForm(0);
	}

	handleFieldChange(event) {
		const recipient = this.recipients[this.currentRecipientIndex];
		const field = event.target.name
		recipient[field] = event.target.value;

		if (this.useRecipientErrors && typeof(event) == 'UIEvent') {
			this.useRecipientErrors = false;
		}

		if (!this.useRecipientErrors && recipient.errors && this.validateRecipientField(recipient, field)) {
			this.errors.removeError(field)

			if(recipient.errors[field]) delete this.recipients[this.currentRecipientIndex].errors[field]
		}

		this.throttledRender();
	}

	validateRecipientField(recipient, field) {
		switch (field) {
			case 'name':
				if(!recipient.name || recipient.name.length <= 0 || recipient.name.length > 32) return false;
				break;
			case 'email':
				if(!recipient.email || !emailRegex.test(recipient.email)) return false;
				break;
			case 'message':
				if(recipient.message && recipient.message.length > 200) return false;
				break
			default:
				break;
		}

		return true;
	}

	handleRecipientsImported(recipients) {
		this.recipients = this.recipients
			.concat(recipients)
			.filter(r => r.name || r.email);

		this.sortRecipients();
		this.loadForm(0);
	}

	handleAddRecipientClick(event) {
		event.preventDefault();

		this.recipients = [{
			name: '',
			email: '',
			message: '',
			errors: {},
		}].concat(this.recipients);

		this.recipientCountChanged.dispatch(this.recipients.length);

		// this.sortRecipients();
		this.loadForm(0);
	}

	handleRecipientClick(event) {
		const li = findAncestorByClass(event.target, 'recipient-list__list-item');
		if (! li) {
			return;
		}

		event.preventDefault();

		const index = [...this.findAll('li', li.parentElement)].indexOf(li);

		this.loadForm(index);
	}

	sortRecipients() {
		this.recipients[this.currentRecipientIndex].current = true;

		this.recipients.sort((a, b) => {
			if (a.errors) {
				return -1;
			}

			if (b.errors) {
				return 1;
			}

			return a.name > b.name ? 1 : -1;
		});

		this.currentRecipientIndex = this.recipients.findIndex(r => r.current);

		this.recipients[this.currentRecipientIndex].current = false;
	}
}

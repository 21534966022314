import View from 'Core/View.js';
import TemplateType from 'enums/TemplateType.js';

export default class BodyClasses extends View {
	initialize(options) {
		this.onBackgroundShouldChange = this.onBackgroundShouldChange.bind(this);
		this.onNavigate = this.onNavigate.bind(this);
		this.onPageLoaded = this.onPageLoaded.bind(this);

		this.signals.backgroundAnimation.started.add(this.onBackgroundShouldChange);
		this.signals.pageLoad.started.add(this.onNavigate);
		this.signals.pageLoad.failed.add(this.onPageLoaded, this, 100);
		this.signals.pageLoad.completed.add(this.onPageLoaded, this, 100);

		this.currentTemplate = options.routeAtLoad.template;
		this.templateChanged = false;

		/** @type array **/
		this.lastAddedClasses = this.el
			.getAttribute('class')
			.split(' ')
			.map(c => c.trim())
			.filter(className => !!className);

		this.updateCustomTemplateClass(!!this.el.dataset.templateImage);
	}

	destroy() {
		this.signals.backgroundAnimation.started.remove(this.onBackgroundShouldChange);
		this.signals.backgroundAnimation.started.remove(this.onNavigate);
		this.signals.pageLoad.failed.remove(this.onPageLoaded);
		this.signals.pageLoad.completed.remove(this.onPageLoaded);
	}

	/**
	 * @param {TemplateType} template
	 */
	setTemplate(template) {
		if (!TemplateType.isValid(template)) {
			throw new Error('Tried to set an invalid template type on the body');
		}

		if (template == this.currentTemplate) {
			return false;
		}

		this.templateChanged = true;
		this.currentTemplate = template;
	}

	onNavigate(route) {
		this.setTemplate(route.template);
	}

	onBackgroundShouldChange() {
		if (!this.templateChanged) {
			return;
		}

		this.templateChanged = false;
	}

	onPageLoaded(element) {
		const bodyClasses = element.querySelector('#body-classes');
		if (!bodyClasses) {
			return;
		}

		const newClasses = bodyClasses.getAttribute('class').split(' ');
		this.updateClassesFromPageLoad(newClasses);
		this.updateAttributesFromPageLoad(bodyClasses.attributes);
		this.updateCustomTemplateClass(!!bodyClasses.dataset.templateImage);
	}

	updateClassesFromPageLoad(newClasses) {
		this.lastAddedClasses.forEach(className => {
			if (className) {
				this.el.classList.remove(className);
			}
		});

		this.lastAddedClasses = newClasses
			.map(c => c.trim())
			.filter(className => !!className)
			.map(className => {
				this.el.classList.add(className);

				return className;
			});
	}

	updateAttributesFromPageLoad(attributes) {
		const currentAttributes = this.el.attributes;
		for (let i = currentAttributes.length - 1; i >= 0; i--) {
			if (currentAttributes[i].name.indexOf('data-') !== 0) {
				continue;
			}

			this.el.removeAttribute(currentAttributes[i].name);
		}

		for (let i = attributes.length - 1; i >= 0; i--) {
			if (attributes[i].name.indexOf('data-') !== 0) {
				continue;
			}

			this.el.setAttribute(attributes[i].name, attributes[i].value);
		}
	}

	updateCustomTemplateClass(pageUsesCustomTemplate) {
		if (pageUsesCustomTemplate) {
			this.el.classList.add('body--template');
		} else {
			this.el.classList.remove('body--template');
		}
	}

	/**
	 * @param {TemplateType} template
	 */
	getColorFromTemplate(template) {
		switch (template) {
			case TemplateType.MISSION:
				return 'red';

			case TemplateType.COLLABORATE:
				return 'blue';

			case TemplateType.SHOP:
				return 'white';
		}

		return 'blue';
	}
}

import DefaultPage from '../DefaultPage/DefaultPage.js';
import CheckoutAddresses from './CheckoutAddresses';

import './checkout-details-loggedin.scss';

export default class CheckoutDetailsLoggedIn extends DefaultPage {

	initialize(options) {
		super.initialize();

		const addresses = this.find('.checkout__addresses');
		if (addresses) {
			this.addresses = new CheckoutAddresses({...options, el: addresses});
		}
	}
}

import View from 'Core/View.js';

import createEvent from 'utils/event/create.js';

import './numeric-stepper.scss';

export default class NumericStepper extends View {

	initialize() {
		this.doMin = this.doMin.bind(this);
		this.doPlus = this.doPlus.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChange = this.onChange.bind(this);

		this.minusElement = this.find('.numeric-stepper__minus');
		this.plusElement = this.find('.numeric-stepper__plus');
		this.inputElement = this.find('.numeric-stepper__input');

		this.color = this.el.dataset.color;
		if (this.color) {
			this.minusElement.style['background-color'] = this.color;
			this.plusElement.style['background-color'] = this.color;

			if (this.color.toLowerCase() === '#ff0000' || this.color.toLowerCase() === '#f00') {
				this.el.classList.add('numeric-stepper--invert');
			}
		}

		this.stepValue = parseFloat(this.inputElement.getAttribute('step') ? this.inputElement.getAttribute('step') : 1);
		this.minValue = parseFloat(this.inputElement.getAttribute('min') ? this.inputElement.getAttribute('min') : 1);
		this.maxValue = parseFloat(this.inputElement.getAttribute('max') ? this.inputElement.getAttribute('max') : 1000000);

		this.minusElement.addEventListener('click', this.doMin);
		this.plusElement.addEventListener('click', this.doPlus);
		this.inputElement.addEventListener('focus', this.onFocus);
		this.inputElement.addEventListener('blur', this.onBlur);
		this.inputElement.addEventListener('change', this.onChange);
	}

	destroy() {
		this.minusElement.removeEventListener('click', this.doMin);
		this.plusElement.removeEventListener('click', this.doPlus);
		this.inputElement.removeEventListener('focus', this.onFocus);
		this.inputElement.removeEventListener('blur', this.onBlur);
		this.inputElement.removeEventListener('change', this.onChange);
	}

	getValue() {
		return parseFloat(this.inputElement.value);
	}

	setValue(value) {
		value = Math.max(Math.min(value, this.maxValue), this.minValue);
		this.inputElement.value = value;
		this.inputElement.dispatchEvent(createEvent('change'));
	}

	doMin() {
		const value = this.getValue() - this.stepValue;
		this.setValue(value);
	}

	doPlus() {
		const value = this.getValue() + this.stepValue;
		this.setValue(value);
	}

	onFocus() {
		this.el.classList.add('active');
	}

	onBlur() {
		this.el.classList.remove('active');
	}

	onChange() {

	}
}

import TweenLite from 'gsap/TweenLite';

import View from '../../../../../../../shared/Core/View';
import {limit} from '../../../../../../../shared/utils/Formatting';

import './video-volume.scss';

export default class VideoVolume extends View {

	initialize(options) {
		this.player = options.player;

		this.isDragging = false;

		this.handleElement = this.find('.video-volume__handle');
		this.iconElement = this.find('.video-volume__icon');

		this.handleMouseDown = this.handleMouseDown.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.update = this.update.bind(this);
		this.handleVolumeUpdate = this.handleVolumeUpdate.bind(this);

		this.topContainer = document.body;
		this.el.addEventListener('mousedown', this.handleMouseDown);

		this.player.signals.progress.add(this.handleVolumeUpdate);
		this.update(this.player.getVolume());
	}

	destroy() {
		this.el.removeEventListener('mousedown', this.handleMouseDown);
		this.topContainer.removeEventListener('mouseup', this.handleMouseUp);
		this.topContainer.removeEventListener('mousemove', this.handleMouseMove);
	}

	handleMouseDown(event) {
		event.stopPropagation();

		this.isDragging = true;

		this.topContainer.addEventListener('mouseup', this.handleMouseUp);
		this.topContainer.addEventListener('mousemove', this.handleMouseMove);
		this.handleMouseMove(event);
	}

	handleMouseMove(event) {
		event.preventDefault();

		const rect = this.el.getBoundingClientRect();
		const w = rect.right - rect.left;
		const x = event.pageX - rect.left;
		const percentage = limit(x / w, 0, 1);

		this.player.setVolume(percentage);

		this.update(percentage)
	}

	handleMouseUp(event) {
		event.preventDefault();
		event.stopPropagation();

		this.isDragging = false;

		this.topContainer.removeEventListener('mouseup', this.handleMouseUp);
		this.topContainer.removeEventListener('mousemove', this.handleMouseMove);
	}

	handleVolumeUpdate() {
		if (this.isDragging) {
			return;
		}

		this.update(this.player.getVolume());
	}

	update(percentage) {
		const rect = this.el.getBoundingClientRect();
		const w = rect.right - rect.left - this.handleElement.clientWidth;

		TweenLite.set(this.handleElement, {x: w * percentage});
		TweenLite.set(this.iconElement, {scale: 0.5 + percentage * 0.5});
	}
}

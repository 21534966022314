import PropTypes from 'prop-types';

import LatLng from '../../../../shared/shapes/LatLng';

export default PropTypes.shape({
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	startDate: PropTypes.instanceOf(Date).isRequired,
	endDate: PropTypes.instanceOf(Date).isRequired,
	inPast: PropTypes.bool.isRequired,
	imageURL: PropTypes.string,
	details: PropTypes.string,
	gps: LatLng.isRequired,
	address: PropTypes.string,
	buttonLabel: PropTypes.string,
	buttonURL: PropTypes.string,
});

import { Power2, TweenLite } from 'gsap/all';

import View from 'Core/View.js';

import './wrapper.scss';

export default class Wrapper extends View {
	initialize() {
		this.locks = [];

		this.signals.backgroundAnimation.started.add(this.handleBackgroundAnimationStarted, this);
		this.signals.backgroundAnimation.completed.add(this.handleBackgroundAnimationCompleted, this);
		this.signals.pageLock.lockRequested.add(this.lock, this);
		this.signals.pageLock.unlockRequested.add(this.unlock, this);
		this.signals.pageLoad.started.add(this.handlePageLoadStarted, this);
		this.signals.pageLoad.completed.add(this.handlePageLoadCompleted, this);

		this.loadedHtml = '';
		this.animatingBackground = false;

		this.customBackgroundEl = document.createElement('div');
		this.customBackgroundEl.className = 'wrapper__custom-background';
		this.el.insertBefore(this.customBackgroundEl, this.el.children[0]);

		this.content = this.find('.wrapper__content');
		this.beforeContent = this.find('.wrapper__before-content');
		this.screen.breakpointChanged.add(this.applyCustomTemplateBackground, this);

		this.bodyNode = document.body;
		this.applyCustomTemplateBackground();
	}

	destroy() {
		this.signals.backgroundAnimation.started.remove(this.handleBackgroundAnimationStarted, this);
		this.signals.backgroundAnimation.completed.remove(this.handleBackgroundAnimationCompleted, this);
		this.signals.pageLock.lockRequested.remove(this.lock, this);
		this.signals.pageLock.unlockRequested.remove(this.unlock, this);
		this.signals.pageLoad.started.remove(this.handlePageLoadStarted, this);
		this.signals.pageLoad.completed.remove(this.handlePageLoadCompleted, this);

		this.screen.breakpointChanged.remove(this.applyCustomTemplateBackground, this);
	}

	handleBackgroundAnimationStarted() {
		this.lock('background');
		this.animatingBackground = true;
	}

	handleBackgroundAnimationCompleted() {
		this.unlock('background');

		this.animatingBackground = false;

		// To ensure that the background is applied after the source node has been updated
		this.applyCustomTemplateBackground();
	}

	handlePageLoadStarted() {
		this.bodyNode = null;
		this.clearCustomTemplateBackground();
	}

	handlePageLoadCompleted(htmlNode) {
		this.loadedHtml = htmlNode;
		this.clearBeforeContent();

		this.bodyNode = htmlNode.querySelector('#body-classes');

		if (!this.animatingBackground) {
			this.applyCustomTemplateBackground();
		}
	}

	clearBeforeContent() {
		const htmlNode = this.loadedHtml;
		if (!htmlNode || !this.beforeContent) {
			return;
		}

		const newContainer = htmlNode.querySelector('#before-content');
		let html = '';
		if (newContainer) {
			html = newContainer.innerHTML;
		}
		this.beforeContent.innerHTML = html;
	}

	lock(id) {
		this.locks.push(id);

		if (this.locks.length > 1) {
			return;
		}

		this.signals.pageLock.lockingStarted.dispatch();

		this.scrollOffset = this.getScrollOffset();
		window.scrollTo(0, 0);

		this.content.style.transform = `translateY(${-this.scrollOffset}px)`;
		this.el.classList.add('wrapper--locked');
		this.el.setAttribute('aria-hidden', 'true');

		const focusElements = 'a[href], button, input, textarea, select, details, canvas, [tabindex]';
		const focususableElementsWrapper = this.findAll(focusElements);
		let focususableElements = [
			...focususableElementsWrapper,
			...this.findAll(focusElements, this.find('.navigation', document)),
			...this.findAll(focusElements, this.find('.meta', document)),
			...this.findAll(focusElements, this.find('.shop-top-nav', document)),
		];

		focususableElements.forEach(el => {
			if (el.hasAttribute('tabindex')) {
				el.setAttribute('data-old-tabindex', el.getAttribute('tabindex'));
			}

			el.setAttribute('data-modal-disabled', true);
			el.setAttribute('tabindex', '-1');
		});

		this.signals.pageLock.locked.dispatch();
	}

	unlock(id) {
		this.locks = this.locks.filter(lockId => id !== lockId);

		if (this.locks.length > 0) {
			return;
		}

		this.signals.pageLock.unlockingStarted.dispatch();

		this.content.style.transform = '';
		this.el.classList.remove('wrapper--locked');
		this.el.removeAttribute('aria-hidden');

		const focususableElementsWrapper = this.findAll('[data-modal-disabled]');
		let focususableElements = [
			...focususableElementsWrapper,
			...this.findAll(
				'.navigation [data-modal-disabled], ' +
					'.meta [data-modal-disabled], ' +
					'.shop-top-nav [data-modal-disabled]',
				document
			),
		];

		focususableElements.forEach(el => {
			if (el.hasAttribute('data-old-tabindex')) {
				el.setAttribute('tabindex', el.getAttribute('old-tabindex'));
				el.removeAttribute('data-old-tabindex');
			} else {
				el.removeAttribute('tabindex');
			}
			el.removeAttribute('data-modal-disabled');
		});

		window.scrollTo(0, this.scrollOffset);

		this.signals.pageLock.unlocked.dispatch();
	}

	getScrollOffset() {
		return window.pageYOffset || document.documentElement.scrollTop;
	}

	applyCustomTemplateBackground() {
		if (!this.bodyNode) {
			return;
		}

		const sourceNode = this.bodyNode;

		const isMobile = this.screen.isOneOf(['mobile', 'tablet']);
		const dataset = sourceNode.dataset;

		const background = isMobile ? dataset.templateImageMobile : dataset.templateImage;

		if (background) {
			this.customBackgroundEl.style.background = `url('${background}') no-repeat top center / 100%`;

			TweenLite.fromTo(
				this.customBackgroundEl,
				0.3,
				{ autoAlpha: 0, y: '-50px' },
				{ autoAlpha: 1, y: 0, ease: Power2.easeOut, delay: 0.2 }
			);
		}

		const offset = isMobile ? dataset.templateOffsetMobile : dataset.templateOffset;
		this.el.style.transitionDelay = '';

		if (typeof offset !== 'undefined') {
			this.el.style.paddingTop = `${offset}vw`;
		}
	}

	clearCustomTemplateBackground() {
		this.el.style.transitionDelay = '0.5s';
		this.el.style.paddingTop = '';

		TweenLite.to(this.customBackgroundEl, 0.2, {
			autoAlpha: 0,
			y: -50,
			ease: Power2.easeOut,
			onComplete: function() {
				this.customBackgroundEl.style.background = '';
			},
			onCompleteScope: this,
		});
	}
}

import View from 'Core/View.js';
import Flickity from 'flickity';

import './shop-category-menu.scss';

const ITEM_ACTIVE_CLS = 'shop-category-menu__item--active';
const SEARCH_ACTIVE_CLS = 'shop-category-menu__search--toggled';

const flickitySettings = {
	draggable: true,
	freeScroll: true,
	wrapAround: false,
	cellAlign: 'left',
	contain: true,
	adaptiveHeight: false,
	imagesLoaded: false,
	pageDots: false,
	prevNextButtons: false,
	autoPlay: false,
	selectedAttraction: 0.1,
	friction: 0.5,
	dragThreshold: 3,
};

export default class ShopCategoryMenu extends View {

	initialize(options) {
		this.options = options;

		this.navigateToShopOverview = this.navigateToShopOverview.bind(this);
		this.toggleSearch = this.toggleSearch.bind(this);
		this.handleSearchToggled = this.handleSearchToggled.bind(this);
		this.handleSearchQuit = this.handleSearchQuit.bind(this);
		this.onItemClick = this.onItemClick.bind(this);
		this.resize = this.resize.bind(this);
		this.update = this.update.bind(this);
		this.realign = this.realign.bind(this);

		this.wrapper = this.find('.shop-category-menu__wrapper');
		this.list = this.find('.shop-category-menu__list');
		this.searchItem = this.find('.shop-category-menu__search');
		this.allButton = this.find('.shop-category-menu__all');
		this.items = this.findAll('.shop-category-menu__item');
		this.homeItem = this.find('.shop-category-menu__all');
		this.itemsWithAllButton = [this.allButton, ...this.items];

		/**
		 * Create a clone of the wrapper copy to reliably fetch the width
		 * of the list without Flickity interfering with it.
		 */
		this.wrapperCopy = this.wrapper.cloneNode(true);
		this.wrapperCopy.style.width = '100%';
		this.el.appendChild(this.wrapperCopy);
		this.listCopy = this.find('.shop-category-menu__list', this.wrapperCopy);
		this.listCopy.style.overflow = 'hidden';

		this.items.forEach((item) => {
			item.addEventListener('click', this.onItemClick);
		});

		if (this.searchItem) {
			this.searchItem.addEventListener('click', this.toggleSearch);
		}

		this.signals.pageContentReplaced.add(this.update);
		this.signals.shop.searchOpen.add(this.handleSearchToggled);
		this.signals.shop.searchQuit.add(this.handleSearchQuit);

		this.flickityOptionsMobile = {
			...flickitySettings,
			cellAlign: 'left',
			on: {
				ready: () => this.resize()
			}
		};

		this.flickityOptionsDesktop = {
			...flickitySettings,
			cellAlign: 'center',
			on: {
				ready: () => this.resize()
			}
		};

		this.update();
		this.resize();

		this.realignInterval = setInterval(this.realign, 500);
	}

	destroy() {
		this.items.forEach((item) => {
			item.removeEventListener('click', this.onItemClick);
		});

		if (this.searchItem) {
			this.searchItem.removeEventListener('click', this.toggleSearch);
		}

		this.deinitializeFlickity();

		this.signals.pageContentReplaced.remove(this.update);
		this.signals.shop.searchOpen.remove(this.handleSearchToggled);
		this.signals.shop.searchQuit.remove(this.handleSearchQuit);

		clearInterval(this.realignInterval);
	}

	resize() {
		this.deinitializeFlickity();

		/**
		 * Copy the list size of the wrapper clone
		 */
		this.wrapperCopy.style.display = 'flex';
		this.list.style.width = `${this.listCopy.clientWidth}px`;
		this.wrapperCopy.style.display = 'none';

		if (window.innerWidth < 768) {
			this.initializeFlickity(this.list, this.flickityOptionsMobile);
		} else {
			this.initializeFlickity(this.list, this.flickityOptionsDesktop);
		}
	}

	initializeFlickity(el, options) {
		this.flickity = new Flickity(el, options);
		this.flickity.reloadCells();
		this.flickity.resize();
	}

	update() {
		// Update item states based on url
		const currentRoute = this.routes.getRoute();
		let itemActive = false;
		this.homeItem.classList.remove(ITEM_ACTIVE_CLS);

		this.itemsWithAllButton.forEach((item) => {
			const itemRoute = this.routes.getRoute(item.getAttribute('href'));
			const children = Array.prototype.map.call(
				JSON.parse(item.dataset.children || '[]') || [],
					r => this.routes.getRoute(r)
			);
			const childPaths = children.map(c => c.path);
			if (itemRoute.path === currentRoute.path || childPaths.includes(currentRoute.path)) {
				item.classList.add(ITEM_ACTIVE_CLS);
				itemActive = true;
			} else {
				item.classList.remove(ITEM_ACTIVE_CLS);
			}
		});

		// Overview active
		if(!itemActive && !this.searchItem.classList.contains(SEARCH_ACTIVE_CLS)) {
			this.homeItem.classList.add(ITEM_ACTIVE_CLS);
		}
	}

	deinitializeFlickity() {
		if (!this.flickity) {
			return;
		}

		this.flickity.destroy();
		this.flickity = null;
	}

	navigateToShopOverview(event) {
		if (event) {
			event.preventDefault();
		}

		const url = this.routes.getPath('shop.index');
		const route = this.routes.getRoute(url);

		if (route) {
			this.signals.pageLoad.requested.dispatch(route);
		} else {
			console.error('No route to shop.index found', route);
		}
	}

	toggleSearch(event) {
		event.preventDefault();

		if (this.searchItem.classList.contains(SEARCH_ACTIVE_CLS)) {
			this.signals.shop.searchQuit.dispatch();
		} else {
			this.signals.shop.searchOpen.dispatch();
		}
	}

	onItemClick(event) {
		if (event.currentTarget.classList.contains(ITEM_ACTIVE_CLS)) {
			event.preventDefault();
			this.navigateToShopOverview();
		}
	}

	handleSearchToggled() {
		this.searchItem.classList.add(SEARCH_ACTIVE_CLS)
	}

	handleSearchQuit() {
		this.searchItem.classList.remove(SEARCH_ACTIVE_CLS)
	}

	realign() {
		this.flickity && this.flickity.resize();
	}
}

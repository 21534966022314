import View from 'Core/View.js';

import './social-icons-share.scss';

export default class SocialIconsShare extends View {

	initialize() {

		this.url = this.el.dataset.url + '';
		this.url = this.url.length > 0 ? this.url : location.href;

		this.facebook = this.find('.social-icons-share__link--facebook');
		this.twitter = this.find('.social-icons-share__link--twitter');
		this.linkedin = this.find('.social-icons-share__link--linkedin');
		this.whatsapp = this.find('.social-icons-share__link--whatsapp');
		this.mail = this.find('.social-icons-share__link--mail');

		this.onFacebookClicked = this.onFacebookClicked.bind(this);
		this.onTwitterClicked = this.onTwitterClicked.bind(this);
		this.onLinkedinClicked = this.onLinkedinClicked.bind(this);
		this.onWhatsappClicked = this.onWhatsappClicked.bind(this);
		this.onMailClicked = this.onMailClicked.bind(this);

		this.facebook.addEventListener('click', this.onFacebookClicked);
		this.twitter.addEventListener('click', this.onTwitterClicked);
		this.linkedin.addEventListener('click', this.onLinkedinClicked);
		this.whatsapp.addEventListener('click', this.onWhatsappClicked);
		this.mail.addEventListener('click', this.onMailClicked);
	}

	destroy() {
		this.facebook.removeEventListener('click', this.onFacebookClicked);
		this.twitter.removeEventListener('click', this.onTwitterClicked);
	}

	onFacebookClicked(event) {
		event.preventDefault();

		const targetURL = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.url);
		window.open(targetURL, '', 'height=269,width=550,resizable=1');

		this.signals.social.shareRequested.dispatch('Facebook', this.url, this.routes.getRoute(window.location.path));
	}

	onTwitterClicked(event) {
		event.preventDefault();

		const targetURL = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(this.url);
		window.open(targetURL, '', 'height=285,width=550,resizable=1');

		this.signals.social.shareRequested.dispatch('Twitter', this.url, this.routes.getRoute(window.location.path));
	}

	onLinkedinClicked(event) {
		event.preventDefault();

		const targetURL = 'https://www.linkedin.com/cws/share?url=' + encodeURIComponent(this.url);
		window.open(targetURL, '', 'height=430,width=620,resizable=0');

		this.signals.social.shareRequested.dispatch('LinkedIn', this.url, this.routes.getRoute(window.location.path));
	}

	onWhatsappClicked() {
		this.signals.social.shareRequested.dispatch('Whatsapp', this.url, this.routes.getRoute(window.location.path));
	}

	onMailClicked() {
		this.signals.social.shareRequested.dispatch('Mail', this.url, this.routes.getRoute(window.location.path));
	}

	onFBMessengerClicked() {
		event.preventDefault();

		const targetURL = 'fb-messenger://share?link=' + encodeURIComponent(this.url) + '&app_id=' + encodeURIComponent(app_id);
		window.open(targetURL, '', 'height=285,width=550,resizable=1');

		this.signals.social.shareRequested.dispatch('LinkedIn', this.url, this.routes.getRoute(window.location.path));
	}

}

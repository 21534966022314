import BaseSplashView from './BaseSplash';

import {TimelineMax, Power2, TweenLite} from 'gsap/all';

export default class Splash_19_5 extends BaseSplashView {

	initialize(options) {
		BaseSplashView.prototype.initialize.call(this, options);

		this.setupUI();
		this.setupTimeline();

	}

	setupUI() {

		this.ui = {
			background: this.el.querySelectorAll('.js-background'),
			titleLines: this.el.querySelectorAll('.js-title-line'),
			bodyLines: this.el.querySelectorAll('.js-body-line'),
			foldShapes: [...this.el.querySelectorAll('.js-fold-shapes')],
			foldBgs: [...this.el.querySelectorAll('.js-fold-bgs')],
			shadows: [...this.el.querySelectorAll('.js-shadow-line')],
			basket: this.el.querySelector('.js-basket'),
			bar1: this.el.querySelector('.js-basket-bar-1')
		};

	}

	update(options) {

		TweenLite.to(this.tl, options.duraton, {progress: options.progress});

	}

	setupTimeline() {

		this.tl = new TimelineMax({paused: true});

		const time = .6;
		this.tl.staggerFrom(this.ui.titleLines, .6, {y: -150, ease: Power2.easeOut}, 0.02, time);
		this.tl.from([this.ui.background, this.ui.foldShapes[0], this.ui.titleLines], .03, {opacity: 0, ease: Power0.easeNone}, time);
		this.tl.from(this.ui.background, .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.foldShapes[0], .6, {y: -150, ease: Power4.easeOut}, time);
		this.tl.from(this.ui.shadows[0], .5, {y: -20, opacity: 0, ease: Power2.easeOut}, 0.5);

		this.ui.foldBgs.forEach((el, index) => this.tl.to(this.ui.foldBgs[0], .4, {morphSVG: el}, time + index * .4));;
		this.ui.foldShapes.forEach((el, index) => this.tl.to(this.ui.foldShapes[0], .4, {morphSVG: el}, time + index * .4));

		this.tl.from(this.ui.shadows[1], .6, {y: -2, opacity: 0, ease: Power2.easeOut}, '-=.2');

		this.tl.from(this.ui.basket, .2, {opacity: 0, ease: Power0.easeNone }, '-=.1');
		this.tl.fromTo(this.ui.basket, .5, {x: '100%'}, {x: '-10%', ease: Power2.easeInOut}, '-=.15');
		this.tl.to(this.ui.basket, .5, {rotation: '-20deg', transformOrigin: '34% 75%', ease: Power2.easeOut}, '-=.25');
		this.tl.to(this.ui.bar1, .25, {y: '-20%', ease: Power2.easeInOut}, '-=.15');
		this.tl.to(this.ui.basket, .3, {rotation: '0', ease: Power2.easeOut});
		this.tl.to(this.ui.bar1, .25, {y: '0%', ease: Power2.easeInOut}, '-=.2');
		this.tl.to(this.ui.basket, .3, {x: '0%', ease: Power2.easeOut}, '-=.3' );

		// // this.tl.staggerFrom(this.ui.arrows, .6, {opacity: 0, x: '-600', ease: Power2.easeInOut}, .125 );
		this.tl.staggerFrom(this.ui.bodyLines, .4, {opacity: 0, y: 20, ease: Power2.easeOut}, .125);

	}

}

export default function(root) {
	/**
	 * Touch cannot really be detected, but we can set the feature
	 * class when people actually use touch.
	 * @see https://medium.com/@david.gilbertson/the-only-way-to-detect-touch-with-javascript-7791a3346685
	 */
	window.addEventListener('touchstart', function() {
		root.classList.remove('no-touch');
		root.classList.add('touch');
	});
}
import View from 'Core/View.js';
import CartModel from 'data/CartModel';
import './product-tile.scss';

export default class ProductTile extends View {

	initialize(options) {

		this.onAddedToBasket = this.onAddedToBasket.bind(this);
		this.onProductDetailClick = this.onProductDetailClick.bind(this);

		this.id = this.el.dataset.id;

		this.addToBasket = this.find('.product-tile__add-to-basket');
		this.productDetailAnchor = this.find('a.product-tile__image');
		if (this.addToBasket) {
			this.addToBasket.addEventListener('click', this.onAddedToBasket);
		}

		if(this.productDetailAnchor) {
			this.productDetailAnchor.addEventListener('click', this.onProductDetailClick);
		}

		this.tracker = options.impressionTracker;
		this.tracker.add(this.el, 'Product Tile', {
			sku: this.el.dataset.sku,
			name: this.find('.product-tile__title').innerText,
			position: this.getPosition(),
			price: this.el.dataset.price,
			originalPrice: this.el.dataset.originalPrice,
			category: this.el.dataset.category || null,
		});
	}

	onProductDetailClick(event) {
		const path = window.location.pathname;
		this.signals.ecommerce.productSelected.dispatch(path, path,
			[{
				sku: this.el.dataset.sku,
				name: this.find('.product-tile__title').innerText,
				position: this.getPosition(),
				price: this.el.dataset.price,
				priceOriginal: this.el.dataset.priceOriginal,
				category: this.el.dataset.category || null,
			}]
		)
	}

	onAddedToBasket(event) {
		event.preventDefault();
		CartModel.addProduct(this.id, 1);
	}

	destroy() {
		this.tracker.remove(this.el);
	}

	getPosition() {
		return [].slice.call(this.el.parentNode.children).indexOf(this.el) + 1;
	}

}

import View from 'Core/View';
import './conversation-bars-cloud.scss';
import TweenLite from 'gsap/TweenLite';

export default class ConversationBarsCloud extends View {

	/**
	 *
	 * @param options
	 */
	initialize(options) {
		this.texts = this.findAll('.conversation-bars-product__cloud__text');

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);
		this.swap = this.swap.bind(this);
		this.onShowing = this.onShowing.bind(this);
		this.onHidden = this.onHidden.bind(this);

		this.pickText();
	}

	/**
	 *
	 */
	show(delay = 0.4) {
		TweenLite.delayedCall(delay, this.onShowing);
	}

	/**
	 *
	 */
	hide() {
		this.el.classList.remove('conversation-bars-product__cloud--visible');
		TweenLite.delayedCall(1, this.onHidden);
	}

	/**
	 *
	 */
	swap() {
		this.pickText();
		this.show();
	}

	/**
	 *
	 */
	onShowing() {
		this.el.classList.add('conversation-bars-product__cloud--visible');
		TweenLite.delayedCall(Math.random() * 5 + 5, this.hide);
	}

	/**
	 *
	 */
	onHidden() {
		this.swap();
	}

	/**
	 *
	 */
	pickText() {
		this.texts.forEach((text) => {
			TweenLite.set(text, {display: 'none'});
		})
		const newIndex = Math.floor(Math.random() * this.texts.length);
		TweenLite.set(this.texts[newIndex], {display: 'block'});
	}

	/**
	 *
	 */
	destroy() {
		super.destroy();

		TweenLite.killDelayedCallsTo(this.show);
		TweenLite.killDelayedCallsTo(this.hide);
		TweenLite.killDelayedCallsTo(this.swap);
		TweenLite.killDelayedCallsTo(this.onShowing);
		TweenLite.killDelayedCallsTo(this.onHidden);
	}
}

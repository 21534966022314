/**
 * @param {FormData} formData
 */
export default function filterEmptyDuplicates(formData) {

	// Gather non-empty values
	const filledFields = {};
	formData.forEach((value, key) => {
		if (value) {
			filledFields[key] = value;
		}
	});

	// Put them back into the form data
	Object.keys(filledFields).forEach(key => {
		formData.set(key, filledFields[key]);
	});
}

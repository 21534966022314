import View from 'Core/View.js';

const CLASS_FOCUS = 'field--focus';
const CLASS_NOT_EMPTY = 'field--not-empty';

export default class Field extends View {

	initialize() {
		this.input = this.find('input,textarea,select');
		this.label = this.find('label');

		this.handleFocus = this.handleFocus.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleChange = this.handleChange.bind(this);

		if (this.input) {
			this.input.addEventListener('focus', this.handleFocus);
			this.input.addEventListener('blur', this.handleBlur);
			this.input.addEventListener('change', this.handleChange);

			// Needed for when content is prefilled
			setTimeout(this.handleChange, 1);
		} else {
			console.error(this.el, 'no form element found');
		}
	}

	destroy() {
		if(this.input) {
			this.input.removeEventListener('focus', this.handleFocus);
			this.input.removeEventListener('blur', this.handleBlur);
			this.input.removeEventListener('change', this.handleChange);
		}
	}

	handleFocus() {
		this.el.classList.add(CLASS_FOCUS);
	}

	handleBlur() {
		this.el.classList.remove(CLASS_FOCUS);
	}

	handleChange() {
		if (this.input.value !== '') {
			this.el.classList.add(CLASS_NOT_EMPTY);
		} else {
			this.el.classList.remove(CLASS_NOT_EMPTY);
		}
	}
}

/**
 * The datalayer should be present at all times. Event when gtag won't be loaded.
 */
window.dataLayer = window.dataLayer || [];
window.gtag = function() {
	window.dataLayer.push(arguments);
};

export default function initializeTracking() {

	// Google tag manager
	if (tonysConfig.googletagmanager.length > 0 && window.tonysCookieConsent.analytics) {

		const gtms = document.createElement('script');
		gtms.async = true;
		gtms.src = '//www.googletagmanager.com/gtm.js?id=' + tonysConfig.googletagmanager;
		document.head.appendChild(gtms);

		window.gtag('js', new Date());

		// Analytics all advertising feature gtag, https://support.google.com/analytics/answer/9050852?hl=nl
		if (! window.tonysCookieConsent.marketing) {
			window.gtag('set', 'allow_google_signals', false);
		}

		window.gtag('config', tonysConfig.googletagmanager, {
			'custom_map': {'dimension1': 'customer_group', 'dimension2': 'configuration'}
		});
	} else {
		console.log('[TONYS] Google tag manager not loaded');
	}

	// Google analytics
	if (tonysConfig.googleanalytics.length > 0 && window.tonysCookieConsent.analytics) {
		window.ga = window.ga || function () {
			(ga.q = ga.q || []).push(arguments)
		};
		window.ga.l = +new Date;
		window.ga('create', tonysConfig.googleanalytics, 'auto');

		if (!window.tonysCookieConsent.marketing) {
			window.ga('set', 'allowAdFeatures', false);
		}

		// Require enhanced e-commerce plugin
		window.ga('require', 'ec');
		window.ga('set', 'currencyCode', tonysConfig.currency.code);

		const gas = document.createElement('script');
		gas.async = true;
		gas.src = '//www.google-analytics.com/analytics.js';
		document.head.appendChild(gas);
	} else {
		console.log('[TONYS] Google Analytics not loaded');
	}

	// Marketing cloud
	if (tonysConfig.marketingcloud.length > 0 && window.tonysCookieConsent.marketing) {
		const mas = document.createElement('script');
		mas.async = true;
		mas.onload = function () {
			if (typeof window._etmc !== 'undefined') {
				_etmc.push(['setOrgId', tonysConfig.marketingcloud]);
				_etmc.push(['trackPageView']);

				if (tonysConfig.customer) {
					_etmc.push(['setUserInfo', {email: tonysConfig.customer.email}]);
				}
			}
		};

		mas.src = 'https://' + tonysConfig.marketingcloud + '.collect.igodigital.com/collect.js';
		document.body.appendChild(mas);
	} else {
		console.log('[TONYS] MarketingCloud tracking not loaded');
	}
}

const loadScript = url => {
	return new Promise((resolve, reject) => {

		const script = document.createElement('script');
		script.src = url;

		script.addEventListener('load', () => {
			resolve(true);
		});

		document.head.appendChild(script);
	})
}

export default loadScript;

import View from 'Core/View';

import ProgressionForm from '../../components/ProgressionForm/ProgressionForm';

import LoginOrRegister from './parts/SubscriptionLoginOrRegister.js';
import AddressConfirmation from './parts/SubscriptionAddressConfirmation.js';
import SubscriptionCouponConfirmation from './parts/SubscriptionCouponConfirmation';

import './redeem-subscription.scss';

export default class RedeemSubscription extends View {

	initialize(options) {
		this.isLoggedIn = this.el.classList.contains('redeem-subscription--is-logged-in');

		this.form = new ProgressionForm({
			...options,
			el: this.el,
			sections: {
				'.subscription-login-or-register': LoginOrRegister,
				'.subscription-address-confirmation': AddressConfirmation,
				'.subscription-coupon-confirm': SubscriptionCouponConfirmation,
			},
		});

		this.form.forceUpdate();
	}

	destroy() {
		this.form.destroy();
	}
}

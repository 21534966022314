import View from 'Core/View';
import safelyHandleResizeObserver from '../../JaarFairslag/helpers/safelyHandleResizeObserver';

export default class ConversationBarsNavigation extends View {
	initialize() {
		this.resizeObserver = new ResizeObserver(
			safelyHandleResizeObserver(entries => {
				for (let entry of entries) {
					if (!entry.contentBoxSize) {
						continue;
					}

					// Firefox implements `contentBoxSize` as a single content rect, rather than an array
					const contentBoxSize = Array.isArray(entry.contentBoxSize)
						? entry.contentBoxSize[0]
						: entry.contentBoxSize;
					this.resizeTo(contentBoxSize.blockSize);
				}
			})
		);

		this.conversationBarsBackground = document.querySelector('.conversation-bars__background');
		this.resizeObserver.observe(this.el);
	}

	resizeTo(offsetHeight) {
		const height = offsetHeight + (this.screen.isOneOf(['mobile']) ? 160 : 208); // paddin of main container
		this.conversationBarsBackground.style.height = height + 'px';
	}

	destroy() {
		this.resizeObserver.unobserve(this.el);

		super.destroy();
	}
}

import View from 'Core/View.js';

import debounce from '../../../shared/utils/generic/debounce';

import FiltersExtra from './FiltersExtra';

import './filters.scss';

const SHOW_SEARCH_CLS = 'filters--show-search';

export default class Filters extends View {

	initialize(options) {
		this.onToggleSearch = this.onToggleSearch.bind(this);
		this.handleSearchQuit = this.handleSearchQuit.bind(this);
		this.onPageLoadCompleted = this.onPageLoadCompleted.bind(this);
		this.onFiltersExtraChange = debounce(this.onFiltersExtraChange.bind(this), 250);

		this.currentRoute = options.routeAtLoad;

		const filtersExtraElement = this.find('.filters-extra');
		if (filtersExtraElement) {
			this.filtersExtra = new FiltersExtra({el: filtersExtraElement, signals: this.signals});
		}

		this.selected = this.find('.filters__filter--selected');

		if (this.filtersExtra) {
			this.filtersExtra.changeSignal.add(this.onFiltersExtraChange);
		}

		// Priority 3 to trigger before Shop.js hijacks the signal
		this.signals.pageLoad.completed.add(this.onPageLoadCompleted, this, 3); // Make sure page load is handled before shop onPageLoadCompleted hijack
		this.signals.shop.searchOpen.add(this.onToggleSearch, this, 100);
		this.signals.shop.searchQuit.add(this.handleSearchQuit);

		/**
		 * When loading the page and a search query is supplied, trigger
		 * the search toggle signal.
		 */
		if (this.el.dataset.showSearch === 'true') {
			this.signals.shop.searchOpen.dispatch();
		}
	}

	destroy() {
		this.signals.pageLoad.completed.remove(this.onPageLoadCompleted);
		this.signals.shop.searchOpen.remove(this.onToggleSearch);
		this.signals.shop.searchQuit.remove(this.handleSearchQuit);
	}

	onToggleSearch() {
		this.el.classList.add(SHOW_SEARCH_CLS);
	}

	/**
	 * Update range for extra filters when different base path as currentRoute
	 *
	 * @param htmlNode
	 * @param loadTime
	 * @param route
	 */
	onPageLoadCompleted(htmlNode, loadTime, route) {
		const shouldHijackPage = this.hijackPageLoad(route);

		// Filter visibility check, is it hidden on the new page? hide itself
		if (shouldHijackPage) {
			const filtersElement = htmlNode.querySelector('.filters');
			this.el.classList.remove('filters--hidden');
			if (filtersElement && filtersElement.classList.contains('filters--hidden')) {
				this.el.classList.add('filters--hidden');
			}
		}

		// Range slider update
		if (shouldHijackPage && this.filtersExtra && this.currentRoute.path !== route.path) {
			const filtersExtraEl = htmlNode.querySelector('.filters-extra');

			if (filtersExtraEl) {
				const lowest = parseInt(filtersExtraEl.dataset.lowest);
				const highest = parseInt(filtersExtraEl.dataset.highest);
				this.filtersExtra.changeSliderRange(lowest, highest);
			}

			this.currentRoute = route;
		}
	}

	/**
	 * When extra filters change, trigger page reload with current route, add extra filter params and request a page load
	 *
	 * @param filtersExtraData
	 */
	onFiltersExtraChange(filtersExtraData) {
		const extraDataKeys = Object.keys(filtersExtraData);
		let queryParams = '';

		extraDataKeys.forEach((key) => {
			if (filtersExtraData[key] !== undefined && filtersExtraData[key] !== null) {
				if (queryParams.length > 0) {
					queryParams += '&' + key + '=' + filtersExtraData[key];
				} else {
					queryParams += '?' + key + '=' + filtersExtraData[key];
				}
			}
		});

		const currentRoute = this.routes.getRoute(window.location.path);
		this.currentRoute = this.routes.getRoute(currentRoute.path + queryParams);
		this.signals.pageLoad.requested.dispatch(this.currentRoute);
	}

	hijackPageLoad(route) {
		const routesToHijack = [
			'shop.index',
			'shop.category',
			'shop.search',
		];

		return routesToHijack.indexOf(route.name) > -1 && route.isExactMatch;
	}

	handleSearchQuit() {
		this.el.classList.remove(SHOW_SEARCH_CLS);

		if (this.el.dataset.quitSearchingUrl) {
			const route = this.routes.getRoute(this.el.dataset.quitSearchingUrl);
			this.signals.pageLoad.requested.dispatch(route);
		}
	}
}
